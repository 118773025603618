import React, { useEffect, useState } from 'react';
import { useCore } from '../../../../../hooks';
import Dialog from '../../../components/Dialog/Dialog';
import TableManager from '../../../components/tableManager/TableManager';
import { formatDate } from '../../Utils';
import { useLocation } from "react-router-dom";


const columns = [
	{
		Header: 'Ngày chứng từ',
		accessor: 'ngay_ct',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'TK nợ',
		accessor: 'tk_no',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'TK có',
		accessor: 'tk_co',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tiền ngoại tệ',
		accessor: 'tien_nt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Mã ngoại tệ',
		accessor: 'ma_nt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tỷ giá',
		accessor: 'ty_gia',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tiền quy đổi VND',
		accessor: 'tien',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Nhân viên',
		accessor: 'ma_nv',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Bộ phận',
		accessor: 'ma_bp',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Vụ việc',
		accessor: 'ma_dt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Hợp đồng',
		accessor: 'ma_hd',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Doanh thu/Chi phí',
		accessor: 'ma_cp',
		width: 250,
		minWidth: 200,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Khách hàng nợ',
		accessor: 'ma_kh_no',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Khách hàng có',
		accessor: 'ma_kh_co',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
];

function ViewSoCai({ dataVoucher, open, handleClose }) {
	const core = useCore();
	const [isLoading, setIsLoading] = useState(false);
	const [isData, setIsData] = useState([]);
	const location = useLocation()
	const [path,setPath]=useState("")
	

	useEffect(() => {
		if (open) {
			getDataSoCai();
		}
		let pathname = location.pathname
		let index = pathname.lastIndexOf("/");
		setPath(pathname.slice(index+1, pathname.length))

	}, [open]);
	const skipResetRef = React.useRef(false);
	
	const getDataSoCai = async () => {
		try {
			setIsLoading(true);
			const [data] = await Promise.all([
				core.apiCall({
					endpoint: `${path}/socai/${dataVoucher._id}`,
					// ...params,
					header: {
						'X-Access-Token': core.token,
					},
				}),
			]);
		
			setIsData(data);
		} catch (error) {
			console.log('getDataProduct error', error);
		} finally {
			core.setProgressStatus(false);
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			loading={isLoading}
			title={`Xem sổ cái phiếu nhập ${dataVoucher.so_ct} ngày ${formatDate(new Date(dataVoucher.ngay_ct))}`}
			position="center"
			classNameContent={'dialog__content_edit_voucher_body'}
			width={'90vw'}
		>
			{isData.data && isData.data.length > 0 ? (
				<TableManager
					idTable={'tableSoCaiPN'}
					columns={columns}
					data={isData.data || []}
					skipReset={skipResetRef.current}
					settingRow={false}
					selectRow={false}
					readonly={true}
					fixedHeadercrollHeight={255}
					pagination={false}
				/>
			) : (
				<div>Không có dữ liệu</div>
			)}
		</Dialog>
	);
}

export default ViewSoCai;
