import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import UnitBaseFilter from './UnitBaseFilter';
import FormUnitBaseDialog from './dialog/FormUnitBaseDialog';

const ListUnitBase = () => {
	const columns = [
		{
			name: 'Mã đơn vị cơ sở',
			selector: (row) => row.ma_dvcs,
			width: '200px',
		},
		{
			name: 'Tên đơn vị cơ sở',
			selector: (row) => row.ten_dvcs,
		},
	];

	return (
		<ListBase
			api='dvcs'
			title='Danh sách đơn vị cơ sở'
			columns={columns}
			FilterComponent={UnitBaseFilter}
			FormDialog={FormUnitBaseDialog}
		/>
	);
};

export default withContainer(ListUnitBase);
