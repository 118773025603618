function formatNumberWithSpaces(number) {
	if (isNaN(number)) {
		return 0;
	}

	var options = { minimumFractionDigits: 2, useGrouping: true };

	// Kiểm tra nếu số bằng 0 thì không hiển thị hai số thập phân phía sau
	if (number === 0) {
		options.minimumFractionDigits = 0;
	}

	return number.toLocaleString('en-US', options).replace(/,/g, ' ');
}

function convertStringToNumber(str) {
	if (typeof str !== 'string') return str;
	return Number(str.replace(/[^0-9.-]+/g, ''));
}

function formatDate(date) {
	// Xử lý định dạng ngày tháng ở đây (VD: 01/01/2021)
	return date.toLocaleDateString('en-GB');
}

export { formatNumberWithSpaces, formatDate, convertStringToNumber };
