import React from 'react';
import Dialog from '../components/Dialog/Dialog';
import { Grid, FormControlLabel } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FieldInput from '../components/Dialog/FieldInput';
import { Controller, useForm } from 'react-hook-form';
import FieldSelectApi from '../components/Dialog/FieldSelectApi';
import { CancelRounded, SaveRounded } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';

/**
 * Thiếu các trường: số seri, tên thẻ, tên thẻ 2, nhóm thẻ, loại thẻ, khách hàng, phiếu bán lẻ, ghi chú
 *
 *
 */
function EvoucherForm({ open, onClose, data, setData, onSubmit }) {
	const theme = useTheme();
	const {
		control,
		reset,
		register,
		handleSubmit,
		formState: { isSubmitting },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			ma: '',
			ten: '',
			hieu_luc_tu: '',
			hieu_luc_den: '',
			so_tien: '',
			ty_le: '',
			ghi_chu: '',
			so_lan_sd: '',
			status: false,
		},
	});
	const [isEdit, setIsEdit] = React.useState(false);
	// genarate data post
	const generateDataPost = (values) => {
		return {
			...data,
			ma: values.ma,
			ten: values.ten,
			hieu_luc_tu: values.hieu_luc_tu,
			hieu_luc_den: values.hieu_luc_den,
			so_tien: values.so_tien,
			ty_le: values.ty_le,
			ghi_chu: values.ghi_chu,
			so_lan_sd: values.so_lan_sd,
			status: values.status,
		};
	};
	// save
	const handleSave = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost).then(onClose);
	};
	// handle save copy
	const handleSaveCopy = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost);
	};
	// save and add new
	const handleSaveAddNew = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost).then(() => {
			setIsEdit(false);
			setData({});
		});
	};

	React.useEffect(() => {
		if (open) {
			if (!data || isEmpty(data)) {
				setIsEdit(false);
				reset({
					ma: '',
					ten: '',
					hieu_luc_tu: '',
					hieu_luc_den: '',
					so_tien: '',
					ty_le: '',
					ghi_chu: '',
					so_lan_sd: '',
					status: false,
				});
			} else {
				setIsEdit(true);
				reset({
					ma: data.ma || '',
					ten: data.ten || '',
					hieu_luc_tu: moment(data.hieu_luc_tu).format('YYYY-MM-DD') || '',
					hieu_luc_den: moment(data.hieu_luc_den).format('YYYY-MM-DD') || '',
					so_tien: data.so_tien || '',
					ty_le: data.ty_le || '',
					ghi_chu: data.ghi_chu || '',
					so_lan_sd: data.so_lan_sd || '',
					status: data.status,
				});
			}
		}
	}, [data, open]);

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={isEdit ? 'Sửa thẻ giảm giá' : 'Thêm thẻ giảm giá'}
			width="750px"
			loading={isSubmitting}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(handleSave)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveAddNew)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveCopy)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					<CancelRounded />
					Hủy
				</button>,
			]}
		>
			<Grid container style={{ alignContent: 'flex-start' }} spacing={2}>
				<Grid item xs={12} md={6}>
					<FieldInput title="Mã thẻ" placeholder="Mã thẻ" inputProps={{ ...register('ma') }} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput title="Số seri" placeholder="Số seri" />
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput title="Tên thẻ" placeholder="Tên thẻ" inputProps={{ ...register('ten') }} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput title="Tên thẻ 2" placeholder="Tên thẻ 2" />
				</Grid>
				<Grid item xs={12} md={6}>
					<Controller
						control={control}
						name="nhom_the"
						render={({ field: { onChange, value } }) => (
							<FieldSelectApi
								api="dmnhomthe"
								title="Nhóm thẻ"
								placeholder="Nhóm thẻ"
								value={value ? { value: value.ma_kho, label: value.ten_kho } : null}
								onChange={onChange}
								valueField="ma_kho"
								labelField="ten_kho"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Controller
						control={control}
						name="loai_the"
						render={({ field: { onChange, value } }) => (
							<FieldSelectApi
								api="dmloaithe"
								title="Loại thẻ"
								placeholder="Loại thẻ"
								value={value ? { value: value.ma_kho, label: value.ten_kho } : null}
								onChange={onChange}
								valueField="ma_kho"
								labelField="ten_kho"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput inputProps={{ ...register('hieu_luc_tu') }} title="Từ ngày" type="date" />
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput inputProps={{ ...register('hieu_luc_den') }} title="Đến ngày" type="date" />
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput
						inputProps={{ ...register('so_tien') }}
						title="Tiền giảm giá"
						type="number"
						placeholder="Tiền giảm giá"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput
						inputProps={{ ...register('ty_le') }}
						title="Tỷ lệ giảm giá"
						placeholder="Tỷ lệ giảm giá"
						type="number"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Controller
						control={control}
						name="khach_hang"
						render={({ field: { onChange, value } }) => (
							<FieldSelectApi
								api="customer"
								title="Khách hàng"
								placeholder="Khách hàng"
								value={value ? { value: value.ma_kh, label: value.ten_kh } : null}
								onChange={onChange}
								valueField="ma_kh"
								labelField="ten_kh"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Controller
						control={control}
						name="pbl"
						render={({ field: { onChange, value } }) => (
							<FieldSelectApi
								api="pbl"
								title="Phiếu bán lẻ"
								placeholder="Phiếu bán lẻ"
								value={value ? { value: value.so_ct, label: value.so_ct } : null}
								onChange={onChange}
								valueField="so_ct"
								labelField="so_ct"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput inputProps={{ ...register('ghi_chu') }} title="Ghi chú" placeholder="Ghi chú" />
				</Grid>
				<Grid item xs={12} md={6}>
					<FieldInput
						inputProps={{ ...register('so_lan_sd') }}
						title="Số lần sử dụng"
						placeholder="Số lần sử dụng"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldInput title="Kênh áp dụng" placeholder="Kênh áp dụng" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldInput title="Nhóm chương trình" placeholder="Nhóm chương trình" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldInput title="Hạng khách hàng" placeholder="Hạng khách hàng" />
				</Grid>
				<Grid item xs={6}>
					<FormControlLabel
						style={{
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
							paddingLeft: '10px',
						}}
						label="Còn sử dụng"
						control={
							<input
								style={{ accentColor: theme.palette.primary.main }}
								type="checkbox"
								{...register('status')}
							/>
						}
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
}

export default EvoucherForm;
