import React from 'react';
import { SaleContext } from '../SaleContainer';
import { MdPayment } from 'react-icons/md';
import { TbDiscount } from 'react-icons/tb';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { cloneDeep } from 'lodash';
import Moment from 'moment';
import { Box } from '@material-ui/core';
import {
	ButtonCheckout,
	ButtonPrint,
	PaymentBottom,
	PaymentContainer,
	PaymentContent,
	PaymentTop,
	PriceLine,
	PriceText,
	PriceNumber,
	PriceInput,
	ListItem,
	ContentWrapper,
	SectionTitle,
	SectionTitleText,
	SectionIcon,
	ItemOption,
	PriceModes,
	Mode,
	CodeLine,
	CodeLineText,
	CodeLineInput,
	CodeLineGroup,
	CodeLineWrapper,
} from './paymentComponent';
import { formatCurrencyDisplay, numeralCustom } from '../../../../../utils/helpers';
import ButtonBase from '../components/button/ButtonBase';
import MenuChannel from '../components/menu/MenuChannel';
import MenuNhanVien from '../components/menu/MenuNhanVien';
import { useTheme } from '@material-ui/core/styles';
import SearchCustomer from '../main/products/SearchCustomer';
import BoardPrice from '../main/products/BoardPrice';

const achiveCurrencies = [1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000];

function Payment({ handleClose = () => {} }) {
	const theme = useTheme();
	const context = React.useContext(DefaultContext);
	const { voucher, handleSavePBL, print, handleSavePayment, handleEvouchersChange, handleChietkhauChange } =
		React.useContext(SaleContext);
	const currentField = 'tien_thu';
	const [paymentMethods, setPaymentMethods] = React.useState([]);
	const [mode, setMode] = React.useState(1); // 1: VND; 2: %
	const [currentVoucher, setCurrentVoucher] = React.useState(null);
	const [evoucherCode, setEvoucherCode] = React.useState('');
	const [chietKhauCode, setChietKhauCode] = React.useState('');
	const [customer, setCustomer] = React.useState({ ten_kh: 'Khách lẻ' });

	// calcConNo
	const calcConNo = (voucher) => {
		voucher.tien_evoucher = (voucher.evouchers || [])
			.map((e) => {
				let so_tien = e.ty_le ? Math.roundBy((voucher.t_tien_nt * e.ty_le) / 100, 0) : e.so_tien;
				if (e.so_tien_max && so_tien > e.so_tien_max) so_tien = e.so_tien_max;
				e.so_tien_giam = so_tien;
				return so_tien;
			})
			.reduce((a, b) => a + b, 0);
		voucher.t_ck_nt =
			voucher.details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
			(voucher.tien_ck_hd || 0) +
			(voucher.tien_evoucher || 0);
		voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
		voucher.con_no = voucher.t_tt_nt - voucher.tien_thu - voucher.tien_thu2 - (voucher.tien_paymentByPoints || 0);
		if (voucher.con_no < 0) {
			voucher.phai_tra = Math.abs(voucher.con_no);
			voucher.con_no = 0;
		} else {
			voucher.phai_tra = 0;
		}
	};
	// handle select method
	const handleSelectMethod = (method) => {
		const voucherCopy = cloneDeep(currentVoucher);
		voucherCopy.pt_thanh_toan = method._id;
		setCurrentVoucher(voucherCopy);
	};
	// handle add
	const add = async (number) => {
		const voucherCopy = cloneDeep(currentVoucher);
		if (currentField) {
			let value = voucherCopy[currentField] + number;
			let valueChange = { [currentField]: value };
			handleValueChanged(valueChange);
		}
	};
	// handle value change
	const handleValueChanged = (data) => {
		const voucherCopy = cloneDeep(currentVoucher);
		for (let key in data) {
			voucherCopy[key] = data[key];
		}
		// eslint-disable-next-line no-unused-expressions
		if (voucherCopy.tien_thu === 0) voucherCopy.tien_thu2 === 0;
		//tinh tien con no va phai tra
		calcConNo(voucherCopy);
		setCurrentVoucher(voucherCopy);
	};
	// handle tien chiet khau change
	const handleTienChietKhauChange = (tien_ck_hd) => {
		const voucher = cloneDeep(currentVoucher);
		const t_ck_sp = voucher.details.reduce((acc, d) => {
			return acc + d.tien_ck_nt;
		}, 0);
		const t_tien_hd = voucher.t_tien_nt - t_ck_sp;
		if (tien_ck_hd > t_tien_hd) {
			tien_ck_hd = t_tien_hd;
		}
		voucher.tien_ck_hd = Number(tien_ck_hd);
		voucher.ty_le_ck_hd = Math.round((tien_ck_hd / t_tien_hd) * 100, 0);
		voucher.t_ck_nt =
			voucher.tien_ck_hd + (voucher.details || []).map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0);

		voucher.t_ck_nt = t_ck_sp + (voucher.tien_ck_hd || 0) + (voucher.tien_evoucher || 0);
		voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
		calcConNo(voucher);
		setCurrentVoucher(voucher);
	};
	// handle ty le chiet khau change
	const handleTyLeChietKhauChange = (ty_le_ck) => {
		if (ty_le_ck > 100) {
			ty_le_ck = 100;
		}
		const voucher = cloneDeep(currentVoucher);
		const t_ck_sp = voucher.details.reduce((acc, d) => {
			return acc + d.tien_ck_nt;
		}, 0);
		const t_tien_hd = voucher.t_tien_nt - t_ck_sp;

		voucher.ty_le_ck_hd = ty_le_ck;
		voucher.tien_ck_hd = Math.round((t_tien_hd * voucher.ty_le_ck_hd) / 100, 0);
		voucher.t_ck_nt = voucher.tien_ck_hd + t_ck_sp;
		voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
		calcConNo(voucher);
		setCurrentVoucher(voucher);
	};
	// handle change mode
	const handleChangeMode = (modeValue) => {
		setMode(modeValue);
	};
	// payment
	const payment = async () => {
		const voucherCopy = cloneDeep(currentVoucher);
		if (!voucherCopy.pt_thanh_toan) {
			return context.alert(context.apis.getLabel('Hãy chọn một phương thức thanh toán'));
		}
		if (voucherCopy.con_no) {
			return context.alert(context.apis.getLabel('Số tiền nhận chưa đủ'));
		} else {
			voucherCopy.trang_thai = '6';
			voucherCopy.cashier = context.userInfo.email;
			voucherCopy.payment_time = new Date();
			handleSavePayment(voucherCopy, 1);
			handleClose();
		}
	};
	// load payment method
	const loadPaymentMethod = async () => {
		let ptthanhtoan = [];
		let condition = {};
		condition.status = true;
		try {
			let resp = await context.apis.asyncGetList(context.userInfo.token, 'ptthanhtoan', {
				condition,
				limit: 1000,
			});
			if (resp && resp.length > 0) {
				resp.map((item) => {
					if (item.ds_ma_kho.length == 0) {
						ptthanhtoan.push(item);
					} else {
						item.ds_ma_kho.map((a) => {
							if (a == voucher.ma_kho) {
								ptthanhtoan.push(item);
							}
						});
					}
				}).map((a, b) => a?.stt - b?.stt);
			}
			setPaymentMethods(ptthanhtoan);
		} catch (e) {
			context.alert(e);
			console.error(e.message);
		}
	};
	// handle add evoucher
	const handleAddEvoucher = async () => {
		if (!evoucherCode) return context.alert(context.apis.getLabel('Bạn chưa nhập mã thẻ giảm giá'));
		let condition = { ma: evoucherCode };
		context.setProgressStatus(true);
		try {
			let evoucher = await context.apis.asyncGetData(context.userInfo.token, 'evoucher', condition);
			console.log('evoucher => ', evoucher);
			if (!evoucher) {
				return context.alert(context.apis.getLabel('Mã thẻ giảm giá này không có giá trị'));
			}
			if (evoucher.id_ct_used) {
				return context.alert(context.apis.getLabel('Mã thẻ giảm giá này đã được sử dụng'));
			}
			let hieu_luc_tu = Moment(evoucher.hieu_luc_tu).startOf('date').toDate().getTime();
			let hieu_luc_den = Moment(evoucher.hieu_luc_den).endOf('date').toDate().getTime();
			let now = Moment().toDate().getTime();
			if (now < hieu_luc_tu || now > hieu_luc_den) {
				return context.alert(context.apis.getLabel('Mã thẻ giảm giá này đã hết hạn sử dụng'));
			}
			if (evoucher.user) {
				if (!currentVoucher.ma_kh) {
					return context.alert(
						context.apis.getLabel('Mã thẻ giảm giá này không có giá trị với khách hàng này')
					);
				}
				let of_user = await context.apis.asyncGetData(
					context.userInfo.token,
					'customer',
					{ ma_kh: currentVoucher.ma_kh },
					'of_user'
				);

				if (evoucher.user != of_user) {
					return context.alert(
						context.apis.getLabel('Mã thẻ giảm giá này không có giá trị với khách hàng này')
					);
				}
			}
			context.setProgressStatus(false);
			// update evoucher
			const voucherCopy = cloneDeep(currentVoucher);
			let evouchers = cloneDeep(voucherCopy.evouchers);
			if (evouchers.find((e) => e.ma === evoucher.ma)) return;
			evouchers.push(evoucher);

			voucherCopy.evouchers = evouchers;
			calcConNo(voucherCopy);
			setCurrentVoucher(voucherCopy);
			handleEvouchersChange(evouchers);
		} catch (e) {
			context.alert(e.message || 'Không thể kết nối với máy chủ');
		}
	};
	// handle add chiet khau code
	const handleAddChietKhau = async () => {
		if (!chietKhauCode) return context.alert(context.apis.getLabel('Bạn chưa nhập mã thẻ giảm giá'));
		let condition = { ma_chietkhau: chietKhauCode, status: true };
		context.setProgressStatus(true);
		try {
			let evoucher = await context.apis.asyncGetData(context.userInfo.token, 'dmchietkhau', condition);
			if (!evoucher) {
				return context.alert(context.apis.getLabel('Mã giảm giá này không có giá trị'));
			}

			let hieu_luc_tu = Moment(evoucher.hieu_luc_tu).startOf('date').toDate().getTime();
			let hieu_luc_den = Moment(evoucher.hieu_luc_den).endOf('date').toDate().getTime();
			let now = Moment().toDate().getTime();
			if (now < hieu_luc_tu || now > hieu_luc_den) {
				return context.alert(context.apis.getLabel('Mã giảm giá này đã hết hạn sử dụng'));
			}
			if (evoucher.user) {
				if (!currentVoucher.ma_kh) {
					return context.alert(context.apis.getLabel('Mã giảm giá này không có giá trị với khách hàng này'));
				}
				let of_user = await context.apis.asyncGetData(
					context.userInfo.token,
					'customer',
					{ ma_kh: currentVoucher.ma_kh },
					'of_user'
				);
				if (evoucher.user != of_user) {
					return context.alert(context.apis.getLabel('Mã giảm giá này không có giá trị với khách hàng này'));
				}
			}
			if (evoucher.exfields.tong_hd !== true) {
				return context.alert(context.apis.getLabel('Mã giảm giá này không áp dụng cho tổng hóa đơn'));
			}
			if (evoucher.ma_vt) {
				if (currentVoucher.details.length === 0) {
					return context.alert(context.apis.getLabel('Mã giảm giá này không áp dụng khi chưa có sản phẩm'));
				} else {
					let kt = currentVoucher.details.find((a) => a.ma_vt === evoucher.ma_vt);

					if (!kt) {
						return context.alert(
							context.apis.getLabel('Mã giảm giá này không áp dụng với sản phẩm trong đơn hàng')
						);
					} else {
						if (kt.sl_xuat < evoucher.sl_tu) {
							return context.alert(context.apis.getLabel('Số lượng để áp dụng chưa đủ'));
						}
					}
				}
			}
			if (evoucher.exfields.ma_nvt && evoucher.exfields.ma_nvt.length !== 0) {
				if (currentVoucher.details.length === 0) {
					return context.alert(context.apis.getLabel('Mã giảm giá này không áp dụng khi chưa có sản phẩm'));
				} else {
					let kt;
					evoucher.exfields.ma_nvt.map((i) => {
						kt = currentVoucher.details.find((a) => a.ma_tt1 === i);
					});

					if (!kt) {
						return context.alert(
							context.apis.getLabel('Mã giảm giá này không áp dụng với nhóm sản phẩm trong đơn hàng')
						);
					} else {
						if (kt.sl_xuat < evoucher.sl_tu) {
							return context.alert(context.apis.getLabel('Số lượng để áp dụng chưa đủ'));
						}
					}
				}
			}
			if (evoucher.exfields.nhom_size) {
				if (currentVoucher.details.length === 0) {
					return context.alert(context.apis.getLabel('Mã giảm giá này không áp dụng khi chưa có sản phẩm'));
				} else {
					let kt = currentVoucher.details.filter((a) => a.ghi_chu === evoucher.exfields.nhom_size);

					if (kt.length === 0) {
						return context.alert(
							context.apis.getLabel('Mã giảm giá này không áp dụng với size sản phẩm trong đơn hàng')
						);
					} else {
						let dem = 0;
						kt.map((i) => {
							dem = dem + i.sl_xuat;
						});
						if (dem < evoucher.sl_tu) {
							return context.alert(context.apis.getLabel('Số lượng để áp dụng chưa đủ'));
						}
					}
				}
			}
			context.setProgressStatus(false);

			let voucherCopy = cloneDeep(currentVoucher);
			voucherCopy.tien_ck_hd = evoucher.tien_ck;
			voucherCopy.ty_le_ck_hd = evoucher.ty_le_ck;
			voucherCopy.exfields.chiet_khau = evoucher.ma_chietkhau;
			// setCurrentVoucher(data)
			if (voucherCopy.ty_le_ck_hd > 0 && voucherCopy.tien_ck_hd === 0) {
				voucherCopy.tien_ck_hd = Math.round((voucherCopy.t_tien * voucherCopy.ty_le_ck_hd) / 100);
			}
			calcConNo(voucherCopy);
			setCurrentVoucher(voucherCopy);
			handleChietkhauChange(voucherCopy);
		} catch (e) {
			context.alert('Không thể kết nối máy chủ');
		}
	};
	// get customer
	const getCustomer = async () => {
		try {
			const resp = await context.apis.asyncGetData(context.userInfo.token, 'customer', { ma_kh: voucher.ma_kh });
			if (resp) {
				setCustomer(resp);
			} else {
				setCustomer({ ten_kh: 'Khách lẻ' });
			}
		} catch (error) {
			context.alert(error || 'Không thể kết nối với máy chủ');
		}
	};
	// tong san pham co chiet khau
	const numberDetailChietKhau = React.useMemo(() => {
		if (currentVoucher) {
			return currentVoucher.details.reduce((acc, detail) => {
				if (detail.tien_ck_nt) {
					return acc + 1;
				} else {
					return acc;
				}
			}, 0);
		} else {
			return 0;
		}
	}, [currentVoucher]);
	const currencies = React.useMemo(() => {
		const rounded = Math.ceil(currentVoucher?.t_tt_nt / 10000) * 10000;
		const result = [];
		if (rounded !== currentVoucher?.t_tt_nt) {
			result.push(rounded);
		}
		achiveCurrencies.forEach((c) => {
			if (c > rounded) {
				result.push(c);
			}
		});
		return result;
	}, [currentVoucher?.t_tt_nt]);
	React.useEffect(() => {
		if (context && voucher) {
			loadPaymentMethod();
		}
	}, [context, voucher]);

	React.useEffect(() => {
		if (voucher) {
			getCustomer();
			const voucherCopy = cloneDeep(voucher);
			voucherCopy.tien_thu = 0;
			voucherCopy.tien_thu2 = 0;
			calcConNo(voucherCopy);
			voucherCopy.phai_tra = 0;
			setCurrentVoucher(voucherCopy);
		}
	}, [voucher]);

	return (
		<PaymentContainer>
			<PaymentTop>
				<MenuNhanVien />
				<MenuChannel />
				<BoardPrice />
			</PaymentTop>
			<PaymentContent className="hidden-scroll-y">
				<ContentWrapper
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: '10px',
						alignItems: 'center',
					}}
				>
					<SearchCustomer voucher={voucher} handleSavePBL={handleSavePBL} />
				</ContentWrapper>
				<ContentWrapper>
					<SectionTitle>
						<SectionIcon color={theme.palette.primary.main}>
							<TbDiscount fontSize="16px" />
						</SectionIcon>
						<SectionTitleText textColor={theme.palette.primary.main}>Mã giảm giá</SectionTitleText>
					</SectionTitle>
					<CodeLineWrapper>
						<CodeLineGroup>
							<CodeLineText>Evoucher</CodeLineText>
							<CodeLine>
								<CodeLineInput
									borderColor={theme.palette.primary.main}
									value={evoucherCode}
									onChange={(e) => setEvoucherCode(e.target.value)}
								/>
								<ButtonBase variant="contained" onClick={handleAddEvoucher}>
									Áp dụng
								</ButtonBase>
							</CodeLine>
						</CodeLineGroup>
						<CodeLineGroup disabled>
							<CodeLineText>Chiết khấu tùy chỉnh</CodeLineText>
							<CodeLine>
								<CodeLineInput
									borderColor={theme.palette.primary.main}
									// value={chietKhauCode}
									// onChange={(e) => setChietKhauCode(e.target.value)}
								/>
								<ButtonBase variant="contained" onClick={handleAddChietKhau}>
									Áp dụng
								</ButtonBase>
							</CodeLine>
						</CodeLineGroup>
						<CodeLineGroup>
							<CodeLineText>Chiết khấu, giảm giá</CodeLineText>
							<CodeLine>
								<CodeLineInput
									borderColor={theme.palette.primary.main}
									value={chietKhauCode}
									onChange={(e) => setChietKhauCode(e.target.value)}
								/>
								<ButtonBase variant="contained" onClick={handleAddChietKhau}>
									Áp dụng
								</ButtonBase>
							</CodeLine>
						</CodeLineGroup>
						<CodeLineGroup disabled>
							<CodeLineText>Thẻ dịch vụ</CodeLineText>
							<CodeLine>
								<CodeLineInput
									borderColor={theme.palette.primary.main}
									// value={chietKhauCode}
									// onChange={(e) => setChietKhauCode(e.target.value)}
								/>
								<ButtonBase variant="contained" onClick={handleAddChietKhau}>
									Áp dụng
								</ButtonBase>
							</CodeLine>
						</CodeLineGroup>
					</CodeLineWrapper>
				</ContentWrapper>
				<ContentWrapper>
					<PriceLine>
						<PriceText>Tổng tiền hàng</PriceText>
						<PriceNumber>{currentVoucher?.details?.length || 0}</PriceNumber>
						<PriceInput value={formatCurrencyDisplay(currentVoucher?.t_tien_nt)} readOnly />
					</PriceLine>
					<PriceLine>
						<PriceText>Chiết khấu sản phẩm</PriceText>
						<PriceNumber>{numberDetailChietKhau}</PriceNumber>
						<PriceInput
							value={formatCurrencyDisplay(currentVoucher?.t_ck_nt - currentVoucher?.tien_ck_hd)}
							readOnly
						/>
					</PriceLine>
					<PriceLine>
						<PriceText>Chiết khấu hóa đơn</PriceText>
						<PriceModes>
							<Mode
								bgColor={theme.palette.primary.main}
								active={mode === 1}
								onClick={() => handleChangeMode(1)}
							>
								VND
							</Mode>
							<Mode
								bgColor={theme.palette.primary.main}
								active={mode === 2}
								onClick={() => handleChangeMode(2)}
							>
								%
							</Mode>
						</PriceModes>
						<PriceInput
							border
							value={
								mode === 1
									? numeralCustom(currentVoucher?.tien_ck_hd).format('0,0[.]00')
									: currentVoucher?.ty_le_ck_hd
							}
							onChange={(e) =>
								mode === 1
									? handleTienChietKhauChange(numeralCustom(e.target.value).value())
									: handleTyLeChietKhauChange(numeralCustom(e.target.value).value())
							}
						/>
					</PriceLine>
					<PriceLine>
						<PriceText active>Khách cần trả</PriceText>
						<PriceInput active value={formatCurrencyDisplay(currentVoucher?.t_tt_nt)} readOnly />
					</PriceLine>
					<PriceLine>
						<PriceText>Tiền thu</PriceText>
						<PriceInput
							border
							value={numeralCustom(currentVoucher?.tien_thu).format('0,0[.]00')}
							onChange={(e) =>
								handleValueChanged({
									tien_thu: numeralCustom(e.target.value).value(),
								})
							}
						/>
					</PriceLine>
					{!!currentVoucher?.phai_tra && (
						<PriceLine>
							<PriceText>Phải trả lại</PriceText>
							<PriceInput value={formatCurrencyDisplay(currentVoucher?.phai_tra)} readOnly />
						</PriceLine>
					)}
				</ContentWrapper>
				<ContentWrapper>
					<SectionTitle>
						<SectionIcon color={theme.palette.primary.main}>
							<HiOutlineCurrencyDollar fontSize="16px" />
						</SectionIcon>
						<SectionTitleText textColor={theme.palette.primary.main}>Mệnh giá</SectionTitleText>
					</SectionTitle>
					<ListItem>
						{currencies.map((currency) => (
							<ItemOption
								bgColor={theme.palette.primary.main}
								key={currency}
								onClick={() => add(currency)}
							>
								{formatCurrencyDisplay(currency)}
							</ItemOption>
						))}
						<ItemOption
							bgColor={theme.palette.primary.main}
							active
							onClick={() => add(currentVoucher?.t_tt_nt)}
						>
							{formatCurrencyDisplay(currentVoucher?.t_tt_nt)}
						</ItemOption>
						{!!currentVoucher?.con_no && currentVoucher?.con_no !== currentVoucher?.t_tt_nt && (
							<ItemOption
								bgColor={theme.palette.primary.main}
								onClick={() => add(currentVoucher?.con_no)}
							>
								{formatCurrencyDisplay(currentVoucher?.con_no)}
							</ItemOption>
						)}
					</ListItem>
				</ContentWrapper>
				<ContentWrapper>
					<SectionTitle>
						<SectionIcon color={theme.palette.primary.main}>
							<MdPayment fontSize="16px" />
						</SectionIcon>
						<SectionTitleText textColor={theme.palette.primary.main}>
							Phương thức thanh toán
						</SectionTitleText>
					</SectionTitle>
					<ListItem>
						{paymentMethods?.length > 0 &&
							paymentMethods.map((method) => (
								<Box
									key={method._id}
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '5px',
										padding: '5px 8px',
										fontSize: '13px',
									}}
								>
									<input
										id={method._id}
										type="radio"
										name="payment-method"
										checked={currentVoucher?.pt_thanh_toan === method?._id}
										onClick={() => handleSelectMethod(method)}
									/>
									<label htmlFor={method._id}>{method.ten}</label>
								</Box>
							))}
					</ListItem>
				</ContentWrapper>
			</PaymentContent>
			<PaymentBottom>
				<ButtonPrint onClick={() => print('2')}>IN</ButtonPrint>
				<ButtonCheckout
					disabled={currentVoucher?.details.length === 0}
					onClick={payment}
					bgColor={theme.palette.primary.main}
				>
					THANH TOÁN
				</ButtonCheckout>
			</PaymentBottom>
		</PaymentContainer>
	);
}

export default Payment;
