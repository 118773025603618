import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCore } from '../../../../hooks/useCore';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import TabContainer from '../../components/tab/TabContainer';
import AttachmentsTab from './form/AttachmentsTab';
import InfoTab from './form/InfoTab';

export default function FormUnitDialog({ open, onClose, data, onSubmit }) {
	const { userInfo } = useCore();
	const defaultValues = {
		phu_trach: userInfo?.email,
		progress: 0,
	};

	const hookForm = useForm({ defaultValues });

	const { handleSubmit, reset, watch } = hookForm;

	useEffect(() => {
		if (open) reset({ ...defaultValues, ...data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data, { defaultValues })));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa vụ việc/dự án' : 'Thêm vụ việc/dự án'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className='btn btn-grey' onClick={onClose}>
					Hủy
				</button>,
			]}
			width={1000}
		>
			<TabContainer
				tabs={[
					{ label: 'Thông tin chung', component: <InfoTab hookForm={hookForm} /> },
					{ label: 'Đính kèm', component: <AttachmentsTab hookForm={hookForm} /> },
				]}
			/>
		</Dialog>
	);
}
