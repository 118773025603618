import React from "react";
import ListBase from "../ListBase/ListBase";
import { formatDateDisplay, numeralCustom } from "../../../utils/helpers";
import withContainer from "../withContainer";
import DiscountFilter from "./DiscountFilter";
import DiscountForm from "./DiscountForm";

const columns = [
  {
    name: "Mã chiết khấu",
    selector: (row) => row.ma_chietkhau,
    sortable: true,
    minWidth: "140px",
    center: true,
  },
  {
    name: "Tên chiết khấu",
    selector: (row) => row.ten_chietkhau,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Tên sản phẩm",
    selector: (row) => row.ten_vt,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Tên khách hàng",
    selector: (row) => row.ten_kh,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Nhóm khách hàng",
    selector: (row) => row.nh_kh,
    sortable: true,
    minWidth: "200px",
  },
  {
    name: "Tiền chiết khấu",
    selector: (row) => row.tien_ck,
    sortable: true,
    minWidth: "135px",
    center: true,
    format: (row) => numeralCustom(row.tien_ck).format(),
  },
  {
    name: "Tỷ lệ chiết khấu",
    selector: (row) => row.ty_le_ck,
    sortable: true,
    minWidth: "135px",
    center: true,
  },
  {
    name: "Hiệu lực từ",
    selector: (row) => row.hieu_luc_tu,
    sortable: true,
    minWidth: "120px",
    format: (row) => formatDateDisplay(row.hieu_luc_tu),
  },
  {
    name: "Hiệu lực đến",
    selector: (row) => row.hieu_luc_den,
    sortable: true,
    minWidth: "120px",
    format: (row) => formatDateDisplay(row.hieu_luc_den),
  },
];

function ListDiscount(props) {
  return (
    <ListBase
      api="dmchietkhau"
      title="Danh sách chiết khấu"
      columns={columns}
      FilterComponent={DiscountFilter}
      FormDialog={DiscountForm}
    />
  );
}

export default withContainer(ListDiscount);
