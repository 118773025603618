import React from 'react';
import FieldInput from '../../components/Dialog/FieldInput';
import { Grid } from '@material-ui/core';
import { formatDateDisplay } from '../../../../utils/helpers';
import FieldBox from '../../components/Dialog/FieldBox';
import { useCore } from '../../../../hooks';
import styles from '../AppConfig.module.css';
import { Controller } from 'react-hook-form';

export default function InfoTab({ hookForm }) {
	const core = useCore();
	const { register, control } = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<FieldInput title='Tên công ty' inputProps={{ readOnly: true, ...register('name') }} />
				<FieldInput title='Tên viết tắt' inputProps={{ readOnly: true, ...register('short_name') }} />
				<FieldInput title='ID' inputProps={{ readOnly: true, ...register('_id') }} />
				<FieldInput title='Địa chỉ' inputProps={{ readOnly: true, ...register('address') }} />
				<FieldInput title='Điện thoại' inputProps={{ readOnly: true, ...register('phone') }} />
				<FieldInput title='Email' inputProps={{ readOnly: true, ...register('email') }} />
			</Grid>

			<Grid item xs={6}>
				<Controller
					name='ngay_dn'
					control={control}
					render={({ field: { value } }) => (
						<FieldInput
							title='Ngày đầu năm tài chính'
							value={formatDateDisplay(value)}
							inputProps={{ readOnly: true }}
						/>
					)}
				/>
				<Controller
					name='ngay_ky1'
					control={control}
					render={({ field: { value } }) => (
						<FieldInput
							title='Ngày sử dụng chương trình'
							value={formatDateDisplay(value)}
							inputProps={{ readOnly: true }}
						/>
					)}
				/>
				<Controller
					name='ngay_ks'
					control={control}
					render={({ field: { value } }) => (
						<FieldInput
							title='Ngày khoá sổ'
							value={formatDateDisplay(value)}
							inputProps={{ readOnly: true }}
						/>
					)}
				/>
				<FieldInput title='Mã số thuế' inputProps={{ readOnly: true, ...register('ma_so_thue') }} />
				<FieldBox
					title='Logo'
					titleProps={{ style: { alignSelf: 'flex-start' } }}
					contentProps={{ style: { jusifySelf: 'flex-start' } }}
				>
					<Controller
						name='logo'
						control={control}
						render={({ field: { value } }) => {
							const logoSrc = core.getFileUrl(value);
							return <img className={styles.logoImage} src={logoSrc} alt='Ảnh logo' />;
						}}
					/>
				</FieldBox>
			</Grid>
		</Grid>
	);
}
