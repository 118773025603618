import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { MdClose } from "react-icons/md";
import { SaleContext } from "../SaleContainer";
import DefaultContext from "flexbiz-core/components/auth/Context";
import { useTheme } from "@material-ui/core/styles";

const ScannerStyled = styled(Box)`
  width: 300px;
  height: 34px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0 5px;
  &:focus-within {
    border-color: ${(props) => props.borderColor};
    border-width: 2px;
  }
`;
const SearchInput = styled.input`
  flex-grow: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 13px;
  border-radius: 6px;
  padding: 0 5px;
  &::placeholder {
    color: #c4c4c4;
  }
`;
const Icon = styled(Box)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  cursor: ${(props) => (props.action ? "pointer" : "default")};
  border-radius: ${(props) => (props.action ? "50%" : "0px")};
  background-color: ${(props) => (props.action ? "#ededed" : "")};
  &:hover {
    filter: ${(props) => (props.action ? "brightness(95%)" : "")};
  }
`;

function Scanner({ handleClose, handleOpen }) {
  const theme = useTheme();
  const inputRef = React.useRef();
  const timerRef = React.useRef();
  const { add } = React.useContext(SaleContext);
  const context = React.useContext(DefaultContext);

  const [searchText, setSearchText] = React.useState("");
  const [search, setSearch] = React.useState("");

  const handleScanBarcode = async () => {
    try {
      const resp = await context.apis.asyncGetData(
        context?.userInfo?.token,
        "dmvt",
        { ma_vt: search }
      );
      if (resp) {
        add(resp);
        inputRef.current.focus();
      } else {
        context.alert(
          <div>
            <p>Không tìm thấy mã vật tư '{search}'</p>
            <br />
            <p style={{ textAlign: "center" }}>Thoát chế độ quét mã vạch ?</p>
          </div>,
          (isConfirm) => {
            if (isConfirm) {
              handleClose();
            } else {
              handleOpen();
            }
          }
        );
      }
      setSearchText("");
    } catch (error) {
      context.alert(error);
    }
  };

  React.useEffect(() => {
    if (search) {
      handleScanBarcode();
    }
  }, [search]);
  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText);
    }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);
  return (
    <ScannerStyled borderColor={theme.palette.primary.main}>
      <SearchInput
        ref={inputRef}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onBlur={handleClose}
        placeholder="Quét mã vạch"
      />
      {searchText && (
        <Icon action onClick={() => setSearchText("")}>
          <MdClose fontSize="14px" />
        </Icon>
      )}
    </ScannerStyled>
  );
}

export default Scanner;
