import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Controller } from 'react-hook-form';
import { GrAddCircle } from 'react-icons/gr';
import FieldInput from '../../../../components/Dialog/FieldInput';
import FieldNumberInput from '../../../../components/Dialog/FieldNumberInput';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import InputContainer from '../../../../components/Dialog/InputContainer';
import { goodsType } from '../../../data';
import { getTypeGoods } from '../../../goods.util';
import GroupInput from './components/GroupInput';

export default function InfoTab({ hookForm }) {
	const {
		register,
		control,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = hookForm;

	const type = getTypeGoods(getValues());
	const isGoods = type === goodsType.GOODS.value;
	const isService = type === goodsType.SERVICE.value;
	const isPackage = type === goodsType.PACKAGE.value;
	const isCard = type === goodsType.CARD.value;

	return (
		<Grid container spacing={2}>
			<Grid item xs={8}>
				<FieldInput
					title="Mã hàng hóa"
					placeholder="Mã hàng hoá tự động"
					inputProps={{ ...register('ma_vt') }}
				/>
			</Grid>
			{(isGoods || isService) && (
				<Grid item xs={4}>
					<Controller
						name="gia_mua"
						control={control}
						render={({ field }) => <FieldNumberInput title="Giá vốn" placeholder="Giá vốn" {...field} />}
					/>
				</Grid>
			)}
			{isCard && (
				<Grid item xs={4}>
					<Controller
						name="cost"
						control={control}
						render={({ field }) => <FieldNumberInput title="Mệnh giá" placeholder="Mệnh giá" {...field} />}
					/>
				</Grid>
			)}

			<Grid item xs={8}>
				<FieldInput
					title="Tên hàng hóa"
					placeholder="Tên hàng hóa"
					check
					inputProps={{
						...register('ten_vt', { required: 'Vui lòng nhập tên hàng hóa' }),
					}}
					errorMsg={errors.ten_vt?.message}
				/>
			</Grid>

			<Grid item xs={4}>
				<Controller
					name="gia_ban_le"
					control={control}
					render={({ field }) => <FieldNumberInput title="Giá bán" placeholder="Giá bán" {...field} />}
				/>
			</Grid>

			<Grid item xs={8}>
				<FieldInput title="Tên viết tắt" placeholder="Tên viết tắt" inputProps={{ ...register('ten_vt_en') }} />
			</Grid>

			{isGoods && (
				<Grid item xs={4}>
					<Controller
						name="ton_kho_mac_dinh"
						control={control}
						render={({ field }) => <FieldNumberInput title="Tồn kho" {...field} />}
					/>
				</Grid>
			)}

			<Grid item xs={8}>
				<Controller
					name="ma_thue"
					control={control}
					render={({ field: { onChange, value } }) => {
						const item = value ? { value, label: getValues('ten_thue') } : null;
						const handleChange = (item) => {
							onChange(item?.value || null);
							setValue('ten_thue', item?.label || '');
							console.log(item);
						};

						return (
							<FieldSelectApi
								title="Nhóm thuế GTGT"
								placeholder="Nhóm thuế GTGT"
								api="vat"
								valueField="ma_thue"
								labelField="ten_thue"
								value={item}
								onChange={handleChange}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={2}>
				<Controller
					name="thue_suat_nk"
					control={control}
					render={({ field }) => <FieldNumberInput placeholder="Thuế GTGT" {...field} />}
				/>
			</Grid>

			<Grid item xs={2}>
				<Controller
					control={control}
					name="exfields.vat"
					render={({ field: { onChange, value } }) => (
						<FormControlLabel
							control={
								<Checkbox size="small" checked={!!value} onChange={(e) => onChange(e.target.checked)} />
							}
							label="VAT"
						/>
					)}
				/>
			</Grid>

			{(isPackage || isCard) && (
				<Grid item xs={8}>
					<FieldSelect
						// TODO: change field name
						title="Lịch trình sử dụng"
						actions={[<GrAddCircle />]}
						items={[
							{
								label: 'Sử dụng tự do',
								value: 'free',
							},
							{
								label: 'Theo ngày',
								value: 'day',
							},
							{
								label: 'Theo tuần',
								value: 'week',
							},
							{
								label: 'Theo tháng',
								value: 'month',
							},
						]}
						onChange={(item) => {
							setValue('schedule', item.value);
						}}
						defaultValue="free"
						disableClearable
					/>
				</Grid>
			)}

			{(isPackage || isCard) && (
				<Grid item xs={4}>
					<Controller
						control={control}
						name="exfields.expiration"
						render={({ field: { onChange, value } }) => (
							<FieldSelect
								title="HSD"
								placeholder="Hạn sử dụng"
								items={[
									{
										label: 'Vô thời hạn',
										value: 'infinite',
									},
									{
										label: 'Ngày cụ thể',
										value: 'specific',
									},
									{
										label: 'Khoảng thời gian',
										value: 'range',
									},
								]}
								onChange={(item) => onChange(item.value)}
								defaultValue={value || 'infinite'}
								disableClearable
							/>
						)}
					/>

					{watch('exfields.expiration') === 'specific' && (
						<FieldInput
							title="Ngày hết hạn"
							placeholder="Ngày hết hạn"
							type="date"
							inputProps={{
								...register('expiration_date', {
									required: 'Ngày hết hạn không được để trống',
									valueAsDate: true,
								}),
							}}
							check
							errorMsg={errors?.expiration_date?.message}
						/>
					)}

					{watch('exfields.expiration') === 'range' && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Controller
									name="expiration_value"
									control={control}
									render={({ field }) => (
										<FieldNumberInput
											placeholder="Thời gian"
											check
											errorMsg={errors?.expiration_value?.message}
											{...field}
										/>
									)}
								/>
							</Grid>

							<Grid item xs={6}>
								<Controller
									control={control}
									name="exfields.type"
									render={({ field: { onChange, value } }) => (
										<FieldSelect
											placeholder="Đơn vị"
											items={[
												{
													label: 'Ngày',
													value: 'day',
												},
												{
													label: 'Tuần',
													value: 'week',
												},
												{
													label: 'Tháng',
													value: 'month',
												},
												{
													label: 'Năm',
													value: 'year',
												},
											]}
											onChange={(item) => onChange(item.value)}
											defaultValue={value || 'day'}
											disableClearable
										/>
									)}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			)}

			{isGoods && (
				// TODO: change field name
				<Grid item xs={8}>
					<FieldInput title="Vị trí" placeholder="Vị trí" inputProps={{ ...register('location') }} />
				</Grid>
			)}

			{(isGoods || isService) && (
				// TODO: change field name
				<Grid item xs={4}>
					<FieldInput title="Hoa hồng" placeholder="Hoa hồng" inputProps={{ ...register('commission') }} />
				</Grid>
			)}

			{isService && (
				// TODO: change field name
				<Grid item xs={8}>
					<FieldInput
						title="Thời lượng"
						placeholder="Thời lượng"
						inputProps={{ ...register('service_duration') }}
						type="number"
					/>
				</Grid>
			)}

			{(isGoods || isService) && (
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Grid item xs={4}>
						<Controller
							name="sold_on_pos"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={!!value}
											onChange={(e) => onChange(e.target.checked)}
											size="small"
										/>
									}
									label="Bán trên POS"
								/>
							)}
						/>
					</Grid>

					<Grid item xs={4}>
						<Controller
							name="sold_on_app"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={!!value}
											onChange={(e) => onChange(e.target.checked)}
											size="small"
										/>
									}
									label="Bán trên App"
								/>
							)}
						/>
					</Grid>

					<Grid item xs={4}>
						<Controller
							name="sold_on_web"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={!!value}
											onChange={(e) => onChange(e.target.checked)}
											size="small"
										/>
									}
									label="Bán trên Web"
								/>
							)}
						/>
					</Grid>
				</Grid>
			)}

			<Grid item xs={12} container>
				<Grid item xs={4}>
					<Controller
						name="shared"
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
								control={<Checkbox size="small" />}
								label="Chia sẻ"
							/>
						)}
					/>
				</Grid>

				<Grid item xs={4}>
					<Controller
						name="status"
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
								control={<Checkbox size="small" />}
								label="Còn sử dụng"
							/>
						)}
					/>
				</Grid>

				<Grid item xs={4}>
					<Controller
						name="tg_tk"
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
								control={<Checkbox size="small" />}
								label="Theo dõi tồn kho"
							/>
						)}
					/>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<GroupInput hookForm={hookForm} />
			</Grid>

			{isGoods && (
				<Grid item xs={12}>
					<InputContainer title="Định mức tồn" defaultShow={true}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FieldInput title="Tồn tối thiểu" />
							</Grid>

							<Grid item xs={6}>
								<FieldInput title="Tồn tối đa" />
							</Grid>
						</Grid>
					</InputContainer>
				</Grid>
			)}
		</Grid>
	);
}
