import React from 'react';
import { GrEdit } from 'react-icons/gr';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';

export default function ListStaffItemDialog({ open, handleClose, className, data }) {
	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			title={'Thêm nhóm'}
			className={className}
			actions={[
				<button className='btn btn-fill' onClick={handleClose}>
					<div>Lưu</div>
				</button>,
				<button className='btn btn-grey'>
					<div>Bỏ qua</div>
				</button>,
			]}
		>
			<div>
				<FieldInput title='Tên nhóm' onChange={console.log} tooltip='Tên nhóm không được để trống' />
				<FieldInput title='Ghi chú' onChange={console.log} icon={<GrEdit style={{ margin: '0 4px' }} />} />
			</div>
		</Dialog>
	);
}
