import makeStyles from '@material-ui/core/styles/makeStyles';
import AssignmentReturnedRoundedIcon from '@material-ui/icons/AssignmentReturnedRounded';
import React from 'react';
import TableContainer from '../../../../components/table/TableContainer';

const columns = [
	{
		name: 'Mã chứng từ',
		selector: (row) => row.code,
		sortable: true,
	},
	{
		name: 'Phương thức',
		selector: (row) => row.method,
		sortable: true,
	},
	{
		name: 'Thời gian',
		selector: (row) => row.time,
		sortable: true,
	},
	{
		name: 'Giá vốn',
		selector: (row) => row.cost,
		sortable: true,
	},
	{
		name: 'Số lượng',
		selector: (row) => row.quantity,
		sortable: true,
	},
	{
		name: 'Tồn cuối',
		selector: (row) => row.end,
		sortable: true,
	},
];

const data = [
	// {
	// 	code: 'PNK0001',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 10,
	// },
	// {
	// 	code: 'PNK0002',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 20,
	// },
	// {
	// 	code: 'PNK0003',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 30,
	// },
	// {
	// 	code: 'PNK0004',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 40,
	// },
	// {
	// 	code: 'PNK0005',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 50,
	// },
	// {
	// 	code: 'PNK0006',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 60,
	// },
	// {
	// 	code: 'PNK0007',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 70,
	// },
	// {
	// 	code: 'PNK0008',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 80,
	// },
	// {
	// 	code: 'PNK0009',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 90,
	// },
	// {
	// 	code: 'PNK0010',
	// 	method: 'Nhập kho',
	// 	time: '2021-01-01',
	// 	cost: 100000,
	// 	quantity: 10,
	// 	end: 100,
	// },
];

export default function InventorySheetTab() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<TableContainer columns={columns} data={data} />

			<div className={classes.footer}>
				<button className='btn btn-grey'>
					<AssignmentReturnedRoundedIcon />
					Xuất Excel
				</button>
			</div>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	root: {},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: 16,
		'& > button': {
			marginLeft: 8,
		},
	},
}));
