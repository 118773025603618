import { Col, Form, Radio, Row } from 'antd';
import React from 'react';
import { authorizationOptions } from '../../../../../utils/data';
import VSelect from '../../../VComponent/VSelect';

export default function AuthorizationInput() {
	const form = Form.useFormInstance();

	const visibleTo = Form.useWatch('visible_to', form);
	const dataViewTo = Form.useWatch('dataview_to', form);

	return (
		<Row>
			<Col span={24}>
				<Form.Item label="Visible to" name="visible_to" wrapperCol={{ span: 21 }} labelCol={{ span: 3 }}>
					<Radio.Group options={authorizationOptions} />
				</Form.Item>

				{visibleTo === 2 && (
					<Form.Item
						name="visible_to_users"
						label="Visible to users"
						rules={[
							{
								required: true,
								message: 'This field is required!',
							},
						]}
						wrapperCol={{ span: 21 }}
						labelCol={{ span: 3 }}
					>
						<VSelect.API
							mode="multiple"
							api="participant"
							field={{ value: 'email', getLabel: (p) => `${p.name} - ${p.email}` }}
							allowClear
						/>
					</Form.Item>
				)}

				{visibleTo === 5 && (
					<Form.Item
						name="visible_to_groups"
						label="Visible to groups"
						rules={[
							{
								required: true,
								message: 'This field is required!',
							},
						]}
						wrapperCol={{ span: 21 }}
						labelCol={{ span: 3 }}
					>
						<VSelect.API
							mode="multiple"
							api="dmnhkh"
							field={{ value: '_id', label: 'group_name' }}
							allowClear
						/>
					</Form.Item>
				)}
			</Col>

			<Col span={24}>
				<Form.Item label="Data view to" name="dataview_to" wrapperCol={{ span: 21 }} labelCol={{ span: 3 }}>
					<Radio.Group options={authorizationOptions} />
				</Form.Item>

				{dataViewTo === 2 && (
					<Form.Item
						name="dataview_to_users"
						label="Visible to users"
						rules={[
							{
								required: true,
								message: 'This field is required!',
							},
						]}
						wrapperCol={{ span: 21 }}
						labelCol={{ span: 3 }}
					>
						<VSelect.API
							mode="multiple"
							api="participant"
							field={{ value: 'email', getLabel: (p) => `${p.name} - ${p.email}` }}
							allowClear
						/>
					</Form.Item>
				)}

				{dataViewTo === 5 && (
					<Form.Item
						name="dataview_to_groups"
						label="Visible to groups"
						rules={[
							{
								required: true,
								message: 'This field is required!',
							},
						]}
						wrapperCol={{ span: 21 }}
						labelCol={{ span: 3 }}
					>
						<VSelect.API
							mode="multiple"
							api="dmnhkh"
							field={{ value: '_id', label: 'group_name' }}
							allowClear
						/>
					</Form.Item>
				)}
			</Col>
		</Row>
	);
}
