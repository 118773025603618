import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import UnitFilter from './UnitFilter';
import FormUnitDialog from './dialog/FormUnitDialog';

const ListUnit = () => {
	const columns = [
		{
			name: 'Mã đơn vị tính',
			selector: (row) => row.ma_dvt,
			width: '200px',
		},
		{
			name: 'Tên đơn vị tính',
			selector: (row) => row.ten_dvt,
		},
	];

	return (
		<ListBase
			api='dmdvt'
			title='Danh sách đơn vị tính'
			columns={columns}
			FilterComponent={UnitFilter}
			FormDialog={FormUnitDialog}
		/>
	);
};

export default withContainer(ListUnit);
