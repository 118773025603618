import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import AuthContext from "flexbiz-core/components/auth/Context";
import StoreIcon from '@material-ui/icons/Store';
import {titleColor} from '../../../../config'
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import Moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const a=[
  {c:1,d:2},
  {c:2,d:3}
]


class Dsbook extends React.Component{
  constructor(props){
    super(props);
    this.changeStore = this.changeStore.bind(this)
    this.changeServing = this.changeServing.bind(this)
    this.changeWaiting = this.changeWaiting.bind(this)
    this.state={
      listBook: [],
      listStore: [],
      branchId: "ALL",
      branchName: "Tất cả cửa hàng",
      waiting: 1,
      serving: 1,
      ngay: new Date()
    }
  }
  async componentDidMount() {
    this.context.setProgressStatus(true);
    this.loadShops();
    try {
      
      let dmkho = await this.context.apis.asyncGetList(this.context.userInfo.token,"dmkho",{limit:1000,condition:{status:true},cache:true});
      this.context.setProgressStatus(false);
      this.setState({listStore:dmkho})
    } catch (error) {
      this.context.alert(error?.message);
    }
     
  }
  showListBook  (ListBook, flag) {
    //flag = 1: xe đang phục vụ
    //flag = 2: xe đang chờ
    let ListBook1;//đang phục vụ
    let ListBook2;//dang chờ
    let result = "";
    if (flag == 1) {
      //Sắp xếp 
      if (this.state.serving == 1) {
        ListBook1 = ListBook.sort((a, b) => {
          return ((a.gio * 60 + a.phut) - (b.gio * 60 + a.phut));
        })
      } else {
        ListBook1 = ListBook.sort((a, b) => {
          return ((b.gio * 60 + b.phut) - (a.gio * 60 + a.phut));
        })
      }


      result = ListBook1.map((book, index) => {
        if ((book.gio * 60 + book.phut) >= (5 * 60 + new Date().getMinutes())) {
          if (this.state.branchId != "ALL") {
            if (book.chi_nhanh == this.state.branchId && book.dien_giai == "Đang phục vụ" && book.dien_giai) {

              return (
                <tr>
                <td>
                  <h6 >{book.bien_so}</h6>
                </td>
                <td>
                  <h6 >{book.time_book}</h6>
                </td>
                <td>
                  <h6>{book.details}</h6>
                </td>
                <td>
                  <h6 >{book.so_ct}</h6>
                </td>
              </tr>
              );
            }
          }
          else {
            if (book.dien_giai == "Đang phục vụ" && book.dien_giai) {
              return (
                <tr >
                <td>
                  <h6 >{book.bien_so}</h6>
                </td>
                <td>
                  <h6 >{book.time_book}</h6>
                </td>
                <td>
                  <h6>{book.details}</h6>
                </td>
                <td>
                  <h6 >{book.so_ct}</h6>
                </td>
              </tr>
              );
            }
          }
        }
      });
      return (
        <div className="card-block">
          <table className="table" style={{ backgroundColor: "#21437D", color: "white", borderColor: "white" }} border="1" rule="cols" frame="void">
            <thead style={{ fontSize: "16px" }}>
              <tr>
                <th style={{padding:8}}>Biển số</th>
                <th style={{padding:8}}>Timebook</th>
                <th style={{padding:8}}>Dịch vụ</th>
                <th style={{padding:8}}>Số chứng từ</th>
              </tr>
            </thead>
            <tbody>
              {result}
            </tbody>
          </table>
        </div>)
    }
    else {
      //Sắp xếp 
      if (this.state.waiting == 1) {
        ListBook2 = ListBook.sort((a, b) => {
          return ((a.gio * 60 + a.phut) - (b.gio * 60 + b.phut));
        })
      } else {
        ListBook2 = ListBook.sort((a, b) => {
          return ((b.gio * 60 + b.phut) - (a.gio * 60 + a.phut))
        })
      }
      result = ListBook2.map((book, index) => {
        if ((book.gio * 60 + book.phut) >= (5 * 60 + new Date().getMinutes())) {
          if (this.state.branchId != "ALL") {
            if (book.chi_nhanh == this.state.branchId) {
              if ((book.dien_giai == "Đang chờ" || book.dien_giai == "" || !book.dien_giai)) {
                return (
                  <tr  >
                <td>
                  <h6 >{book.bien_so}</h6>
                </td>
                <td>
                  <h6 >{book.time_book}</h6>
                </td>
                <td>
                  <h6>{book.details}</h6>
                </td>
                <td>
                  <h6 >{book.so_ct}</h6>
                </td>
              </tr>
                );
              }
            }
          }
          else {
            if ((book.dien_giai == "Đang chờ" || book.dien_giai == "" || !book.dien_giai)) {
              return (
                <tr  >
                <td>
                  <h6 >{book.bien_so}</h6>
                </td>
                <td>
                  <h6 >{book.time_book}</h6>
                </td>
                <td>
                  <h6>{book.details}</h6>
                </td>
                <td>
                  <h6 >{book.so_ct}</h6>
                </td>
              </tr>
              );
            }
          }
        }
      });
      return (
        <div className="card-block">
          <table className="table" style={{ backgroundColor: "#D6A377", color: "white", borderColor: "white" }} border="1" rule="cols" frame="void">
            <thead style={{ fontSize: "16px" }}>
              <tr>
                <th style={{padding:8}}>Biển số</th>
                <th style={{padding:8}}>Timebook</th>
                <th style={{padding:8}}>Dịch vụ</th>
                <th style={{padding:8}}>Số chứng từ</th>
              </tr>
            </thead>
            <tbody>
              {result}
            </tbody>
          </table>
        </div>)
    }
  }
  showOptionStore (ListStore) {
    let result = ListStore.map((store, index) => {
      return (
        <option key={index} value={store.ma_kho}>
          {store.ten_kho}
        </option>
      );
    }
    );
    return result;
  }
  async changeStore (e){
    this.setState({
      branchId:e.target.value,
      branchName:e.target.options[e.target.selectedIndex].text
    })
    
 console.log(e.target.options[e.target.selectedIndex].text)
  }
  async changeWaiting(e) {
   this.setState({
      waiting: e.target.value
    });
  }
  async changeServing (e){
     this.setState({
      serving: e.target.value
    });
  }
  openShop(shop){
    this.props.history.push(`/shop/${shop.ma_kho.toLowerCase()}`);
  }
  async loadShops(){
    this.context.setProgressStatus(true);
    try{
        let rows = await this.context.apis.asyncGetList(this.context.userInfo.token,"tt_book",{limit:1000,condition:{date_book:{ $gte: Moment(this.state.ngay).startOf("date"), $lte: Moment(this.state.ngay).endOf("date") }},cache:true});
        this.context.setProgressStatus(false);
       
            this.setState({listBook:rows})
      
        
    }catch(e){
        this.context.alert(e.message);
    }
  }
  
  renderlist(){
      return(
<TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Biển số</TableCell>
            <TableCell align="right">Thời gian</TableCell>
            <TableCell align="right">Dịch vụ</TableCell>
            <TableCell align="right">Đơn hàng</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.bien_so}
              </TableCell>
              <TableCell align="right">{row.time_book}</TableCell>
              <TableCell align="right">{row.details}</TableCell>
              <TableCell align="right">{row.so_Ct}</TableCell>
            
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
       
      )
  }
   

  render() {
    let ListBook = this.state.listBook;
    let ListStore = this.state.listStore;
    let date = new Date();
    let realDate = date.toISOString().slice(0, 10);
    return (

      <div style={{ backgroundColor:"rgb(244 244 246 / 1)",width:"100%",height:"100vh", }}>
                  <div style={{padding:20,display:"flex",justifyContent:"center",flexDirection:"column"}}>
                    <div style={{flexDirection:"row",alignItems:"center",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                  
                      <select style={{ fontWeight:"bold", fontSize:32,padding:15,borderRadius:10}} name={this.state.branchId} class="form-control" onChange={this.changeStore}>
                        <option value="ALL">Tất cả cửa hàng</option>
                        {this.showOptionStore(ListStore)}
                      </select>
                    </div>
                    <div style={{flexDirection:"row",alignItems:"center",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <h1 style={{ marginBottom: "20px",display:"inline-block",fontWeight:"bold"}}>{realDate.slice(8, 10)}/{realDate.slice(5, 7)}/{realDate.slice(0, 4)}</h1>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
                        <div className="card" style={{boxShadow:"0px 0px 5px 1px #aaaaaa",backgroundColor:"white",padding:15,borderRadius:10}}>
                          <div className="card-header">
                            <h3 style={{ fontSize: "20px",margin:"8px 10px",display:"inline-block", color: 'red' }}><b>Xe đang phục vụ</b></h3>
                            <select style={{ width: "100px", left: "1072px", top: "23px",borderRadius:10, padding: 2 }} onChange={this.changeServing}>
                              <option value="1">Tăng dần</option>
                              <option value="-1">Giảm dần</option>
                            </select>
                          </div>
                          <div style={{marginTop:10}}>
                          {this.showListBook(ListBook, 1)}
                          </div>
                        </div>
                        <div className="card" style={{boxShadow:"0px 0px 5px 1px #aaaaaa",backgroundColor:"white",padding:15,borderRadius:10}}>
                          <div className="card-header">
                            <h3 style={{ fontSize: "20px",margin:"8px 10px",display:"inline-block"}}><b>Xe đang chờ</b></h3>
                            <select style={{ width: "100px", left: "1072px", top: "23px",borderRadius:10, padding: 2 }} onChange={this.changeWaiting}>
                              <option value="1">Tăng dần</option>
                              <option value="-1">Giảm dần</option>
                            </select>
                          </div>
                          <div style={{marginTop:10}}>
                          {this.showListBook(ListBook, 2)}
                          </div>
                         
                        </div>
                      </div>

                   
                      </div>
                      </div>
               
    );
  }
}
Dsbook.contextType = AuthContext;
Dsbook.propTypes={
  mediaQueryMatches: PropTypes.bool,
  history:PropTypes.any
}
export default Dsbook;