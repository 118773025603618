import React, { useState, useEffect, createContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CssBaseline } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import { server_url_report } from '../../../../../config';

import styled from 'styled-components';
import SaleHeader from './header/SaleHeader';
import SaleMain from './main/SaleMain';
import SaleFooter from './footer/SaleFooter';
import { useCore } from '../../../../hooks/useCore';
import ReturnedSaleMain from './main/ReturnedSaleMain';

export const SaleContext = createContext();

const SaleContainerStyled = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	height: 100%;
	padding: 10px;
`;

function SaleContainer() {
	const theme = useTheme();
	const params = useParams();
	const { context, token, asyncGetList, setProgressStatus, asyncPostList, contextAlert } = useCore();

	const [billMode, setBillMode] = React.useState(1);
	// 1: hóa đơn, 2
	const [saleMode, setSaleMode] = React.useState(() => {
		return Number(localStorage.getItem('saleMode') || 1);
	}); // 1: bán thường, 2: bán nhanh
	const [search, setSearch] = React.useState('');
	const [vouchers, setVouchers] = useState([]);
	const [returnedVouchers, setReturnedVouchers] = useState([]);
	const [kho, setKho] = useState();
	const [load, setLoad] = useState(0);
	const [ma_ban, setMaBan] = useState();
	const [ma_kho, setMaKho] = useState();
	const [indexVoucher, setIndexVoucher] = useState(0);
	const [voucher, setVoucher] = React.useState(vouchers?.[indexVoucher]);
	const [PrintFrame, setPrintFrame] = React.useState(null);

	useEffect(() => {
		if (params?.ma_ban) {
			setMaBan(params.ma_ban);
		}
		if (params?.ma_kho) {
			setMaKho(params.ma_kho);
		}
	}, [params]);
	const getVoucher = async () => {
		// lấy mã kho và mã bàn từ URL
		// const ma_kho = params.ma_kho.toUpperCase();
		// const ma_ban = params.ma_ban.toUpperCase();
		// khởi tạo voucher
		let initVouchers = [];
		// điều kiện để lọc phiếu bán lẻ
		let condition = {
			trang_thai: { $in: ['1', '3'] },
			// ma_ban: ma_ban,
			ma_kho: ma_kho,
		};
		context.setProgressStatus(true);
		try {
			// lấy ra kho hiện tại theo mã kho
			const kho = await context.apis.asyncGetData(
				context.userInfo.token,
				'dmkho',
				{ ma_kho: ma_kho },
				null,
				true
			);
			// lấy ra danh sách phiếu bán lẻ với điều kiện bên trên
			let rows = await context.apis.asyncGetList(context.userInfo.token, 'pbl', {
				page: 1,
				limit: 500,
				condition: condition,
			});
			let conditionHd7 = {
				trang_thai: 'LCT',
				ma_kho: ma_kho,
			};
			let hd7Rows = await asyncGetList(token, 'hd7', {
				page: 1,
				limit: 500,
				condition: conditionHd7,
			});
			// nếu có phiếu bán lẻ từ api thì gán phiếu đó cho voucher đã khai báo trước đó
			if (rows.length > 0) {
				initVouchers = rows;
			} else {
				// nếu không có pbl trong api thì tự tạo voucher với các trường cơ bản
				const voucher = {
					ma_kho: ma_kho,
					ma_ban: ma_ban,
					so_ct: 'PBL',
					ma_ct:'pbl',
					trang_thai: '1',
					so_khach: 1,
					details: [],
					tien_ck_hd: 0,
					ty_le_ck_hd: 0,
					exfields: {
						dmckhd: [],
					},
				};
				initVouchers.push(voucher);
			}
			// lưu voucher, kho vào state để quản lý
			initVouchers = initVouchers.concat(hd7Rows);
			setVouchers(initVouchers);
			if (!initVouchers[indexVoucher]) {
				setIndexVoucher(indexVoucher - 1);
			}
			setKho(kho);
			setLoad(load + 1);
			context.setProgressStatus(false);
		} catch (e) {
			context.alert(e.message);
		}
	};
	const getVoucherHd7 = async () => {
		let condition = {
			trang_thai: 'LCT',
			ma_kho: ma_kho,
		};
		let rows = await asyncGetList(token, 'hd7', {
			page: 1,
			limit: 500,
			condition: condition,
		});
		if (rows && rows.length > 0) {
			setReturnedVouchers(rows);
		}
	};
	// print default
	const printDefault = (voucher) => {
		context.setProgressStatus(true);
		let stopRunning = () => {
			context.setProgressStatus(false);
		};
		let domain = window.location.origin;
		let urlPrintDefault = `${domain}/#/print/${voucher.id_app}/${context.userInfo.token}/${voucher._id}`;
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await setPrintFrame(null);
			let printFrame = (
				<iframe
					onLoad={() => {
						setTimeout(() => {
							let fr = window.frames['printframe'];
							fr.focus();
							stopRunning();
							fr.print();
							resolve();
						}, 3000);
					}}
					name="printframe"
					style={{ display: 'none', width: '100%', height: '100%' }}
					src={urlPrintDefault}
				></iframe>
			);
			setPrintFrame(printFrame);
		});
	};
	const printLocal = async (url) => {
		context.setProgressStatus(true);
		let stopRunning = () => {
			context.setProgressStatus(false);
		};
		let content;
		try {
			content = await context.apis.asyncGet(url);
		} catch (e) {
			return context.alert(e.message);
		}
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await setPrintFrame(null);
			let printFrame = (
				<iframe
					onLoad={() => {
						let fr = window.frames['printframe'];
						fr.focus();
						stopRunning();
						fr.print();
						setTimeout(() => {
							resolve();
						}, 10);
					}}
					name="printframe"
					style={{ display: 'none', width: '100%', height: '100%' }}
					srcDoc={content}
				></iframe>
			);
			setPrintFrame(printFrame);
		});
	};
	const print = async (loai_bill, onSuccess, onError, useDefaultTempleteIfNotFound = true) => {
		context.setProgressStatus(true);

		let printers = ((kho || {}).printers || []).filter(
			(printer) => printer.id_mau_in && (printer.loai_bill == undefined || printer.loai_bill == loai_bill)
		);
		if (printers.length > 0) {
			await Promise.all(
				printers.map((printer) => {
					let url =
						server_url_report +
						'/api/' +
						voucher.id_app +
						'/pbl/excel/' +
						printer.id_mau_in +
						'?_id=' +
						voucher._id +
						`&print=1&access_token=` +
						context.userInfo.token;
					const print_service_url = printer.dia_chi_may_in;

					return (async () => {
						if (print_service_url) {
							let url_print = `${print_service_url}?url=${btoa(url)}&printer=${
								printer.ten_may_in
							}&width=${printer.do_rong_kho_giay || 0}&height=${printer.chieu_dai_kho_giay || 0}`;
							try {
								await context.apis.asyncGet(url_print);
							} catch (e) {
								await printLocal(url);
							}
						} else {
							await printLocal(url);
						}
					})();
				})
			);
			context.setProgressStatus(false);
			if (onSuccess) onSuccess();
		} else {
			//default template print
			if (useDefaultTempleteIfNotFound) {
				try {
					await printDefault(voucher);
					if (onSuccess) onSuccess();
				} catch (e) {
					if (onError) {
						onError(context.apis.getLabel(e.message || e));
					} else {
						context.alert(context.apis.getLabel(e.message || e));
					}
				}
			} else {
				if (onError) {
					onError(context.apis.getLabel('Không tìm thấy mẫu in phù hợp'));
				} else {
					if (onSuccess) onSuccess();
				}
			}
		}
	};
	const tables = () => {
		return;
	};
	// handle save PBL
	const handleSavePBL = async (data) => {
		let _data_for_save = cloneDeep(data);
		// context.setProgressStatus(true);
		// this.timeSave = new Date().getTime();
		if (!_data_for_save._id) {
			let condition = {
				trang_thai: '1',
				ma_ban: _data_for_save.ma_ban,
				ma_kho: _data_for_save.ma_kho,
			};
			let voucher = await context.apis.asyncGetData(context.userInfo.token, 'pbl', condition);
			if (voucher) {
				let details = voucher.details;
				let vs_merge = [_data_for_save];
				vs_merge
					.map((v) => v.details)
					.reduce((a, b) => [...a, ...b], [])
					.forEach((p) => {
						let item = details.find((d) => d.ma_vt === p.ma_vt && d.ty_le_ck === p.ty_le_ck);
						if (!item) {
							item = { ...p };
							details.push(item);
						} else {
							item.sl_xuat += p.sl_xuat;
							item.sl_order += p.sl_order;
							item.sl_gui_bep += p.sl_gui_bep;
							item.sl_gui_bartender += p.sl_gui_bartender;
							item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
							item.tien_ck_nt += item.tien_ck_nt;
							item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
						}
					});
				voucher.tien_ck_hd =
					(voucher.tien_ck_hd || 0) + vs_merge.map((v) => v.tien_ck_hd || 0).reduce((a, b) => a + b, 0);
				voucher.tien_evoucher =
					(voucher.tien_evoucher || 0) + vs_merge.map((v) => v.tien_evoucher || 0).reduce((a, b) => a + b, 0);
				voucher.evouchers = [
					...voucher.evouchers,
					...vs_merge.map((v) => v.evouchers || []).reduce((a, b) => a.concat(b), []),
				];

				voucher.t_tien_nt = details.map((d) => d.tien_hang_nt).reduce((a, b) => a + b, 0);
				voucher.t_ck_nt =
					details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
					(voucher.tien_ck_hd || 0) +
					(voucher.tien_evoucher || 0);
				voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
				_data_for_save = voucher;
			}
		}
		let rs = await context.apis.asyncPostList(context.userInfo.token, 'pbl', _data_for_save);
		getVoucher();
		// context.setProgressStatus(false);
		return rs;
	};
	// handle bill change
	const handleBillChange = async (details, _voucher) => {
		let voucher_for_save = cloneDeep(voucher);
		if (_voucher) {
			voucher_for_save = { ...voucher_for_save, ..._voucher };
		}
		if (details) {
			voucher_for_save.details = details;
		}
		try {
			// cần hàm savePBL
			let voucher_saved = await handleSavePBL(voucher_for_save);
			setVoucher(voucher_saved);
			if (voucher_saved.ma_ban !== voucher_for_save.ma_ban && kho.theo_doi_sl_ht) {
				//gui thong bao doi ban cho bep va batender
				// context.alert(
				//   context.apis.getLabel("Bạn có muốn in phiếu đổi bàn không?"),
				//   () => {
				//     //cần ham print
				//     print(
				//       "5",
				//       () => {},
				//       (error) => {
				//         context.alert(error);
				//       },
				//       false
				//     );
				//   },
				//   "blue"
				// );
			}
		} catch (e) {
			context.alert(e || context.apis.getLabel('Không thể kết nối với máy chủ'));
		}
	};
	// handle select product
	const add = async (p, sl = 1, plus = true) => {
		setProgressStatus(true);
		let voucherCopy = cloneDeep(voucher);
		let details = voucherCopy.details;
		let item;
		if (voucherCopy.ma_ct.toLowerCase() === 'hd7') {
			const newDetail = {
				...p,
				id_hd: voucherCopy._id,
				sl_nhap: 1,
				gia_ban: p.gia_ban_le,
				tien_hang: p.gia_ban_le,
			};
			details.push(newDetail);
			handleReturnedBillChange(voucherCopy);
		} else {
			if (p.km_yn_for) {
				item = details.find((d) => d.ma_vt === p.ma_vt && d.km_yn_for == p.km_yn_for);
			} else {
				if (kho && kho.theo_doi_sl_ht) {
					item = details.find((d) => d.ma_vt === p.ma_vt && d.line === p.line && !d.km_yn_for);
				} else {
					item = details.find((d) => d.ma_vt === p.ma_vt && !d.km_yn_for);
				}
				if (details.length > 0 && !item) {
					details.map((items) => {
						if (items.ma_vt == p.ma_vt && !items.km_yn_for) {
							item = items;
						}
					});
				}
			}
			if (!item) {
				item = { ...p, sl_xuat: 0 };
				item.gia_ban_nt = item.gia_ban_le || 0;
				item.order_time = new Date();
				item.sl_ht = 0;
				item.sl_gui_bep = 0;
				item.sl_gui_bartender = 0;
				item.sl_order = 0;
				item.line = new Date().getTime();
				item.ma_tt1 = p.ma_nvt;
				item.ghi_chu = p.ma_nvt2;
				details.push(item);
			}
			item.sl_order = item.sl_order || 0;
			if (plus) {
				item.sl_order += sl;
			} else {
				item.sl_order = sl;
			}
			// if (sl !== 1 && sl !== -1) {
			//   item.sl_order = sl;
			// } else {
			//   item.sl_order += sl;
			// }
			//fix old voucher
			item.sl_gui_bartender = item.sl_gui_bartender || 0;
			item.sl_gui_bep = item.sl_gui_bep || 0;
			//neu khong theo doi sl ht thi sl_xuat luon bang sl_order
			if (kho || kho.theo_doi_sl_ht || (!item.sp_yeu_cau_pha_che && !item.sp_yeu_cau_che_bien)) {
				item.sl_xuat = item.sl_order;
			}
			//tinh chiet khau
			item.ty_le_ck = item.ty_le_ck || 0;
			item.tien_ck_nt = item.tien_ck = item.tien_ck_nt || 0;
			//
			if (item.sl_xuat >= item.sl_order) {
				if (!item.finish_time || sl) item.finish_time = new Date();
			} else {
				item.finish_time = null;
			}
			//
			if (!item.sp_yeu_cau_che_bien && !item.sp_yeu_cau_pha_che) {
				item.finish_time = item.order_time;
			}
			if (item.sl_order > 0) {
				item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
				if (item.ty_le_ck) {
					item.tien_ck_nt = Math.round((item.tien_hang_nt * item.ty_le_ck) / 100);
				}
				item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
			} else {
				if (kho && kho.theo_doi_sl_ht) {
					details = details.filter((vt) => !(vt.ma_vt === item.ma_vt && vt.line === item.line));
				} else {
					details = details.filter((vt) => vt.ma_vt !== p.ma_vt);
				}
			}
			voucherCopy.details = details;
			voucherCopy.exfields.dmckhd = [];
			voucherCopy.t_tien_nt = details.map((d) => d.tien_hang_nt).reduce((a, b) => a + b, 0);
			voucherCopy.t_ck_nt =
				details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
				(voucherCopy.tien_ck_hd || 0) +
				(voucherCopy.tien_evoucher || 0);
			voucherCopy.t_tt_nt = voucherCopy.t_tien_nt - voucherCopy.t_ck_nt;

			// await setVoucher(voucherCopy);
			handleBillChange(details);
			setProgressStatus(false);
		}
	};
	// handle chiet khau change
	const handleChietkhauChange = async (evouchers) => {
		try {
			let v = await handleSavePBL(evouchers);
			setVoucher(v);
			setIndexVoucher(indexVoucher);
		} catch (e) {
			context.alert(e || context.apis.getLabel('Không thể kết nối với máy chủ'));
		}
	};
	// handle evoucher change
	const handleEvouchersChange = async (evouchers) => {
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.evouchers = [...evouchers];
		voucherCopy.tien_evoucher = voucherCopy.evouchers
			.map((e) => {
				let so_tien = e.ty_le ? Math.roundBy((voucherCopy.t_tien_nt * e.ty_le) / 100, 0) : e.so_tien;
				if (e.so_tien_max && so_tien > e.so_tien_max) so_tien = e.so_tien_max;
				e.so_tien_giam = so_tien;
				return so_tien;
			})
			.reduce((a, b) => a + b, 0);
		try {
			let v = await handleSavePBL(voucherCopy);
			setVoucher(v);
			setIndexVoucher(indexVoucher);
		} catch (e) {
			context.alert(e || context.apis.getLabel('Không thể kết nối với máy chủ'));
		}
	};

	const handleSavePayment = async (voucher, loai_bill) => {
		context.setProgressStatus(true);
		try {
			// this.isPrinting = true;
			const v = await handleSavePBL(voucher);
			await getVoucher();
			if (v.trang_thai !== '9') {
				print(
					loai_bill,
					() => {
						tables();
					},
					() => {
						tables();
					}
				);
			} else {
				tables();
			}
			context.setProgressStatus(false);
		} catch (e) {
			context.alert(e || 'Không thể kết nối với máy chủ');
		}
	};
	const handleReturnedBillChange = async (returnedBill) => {
		try {
			setProgressStatus(true);
			await asyncPostList(token, 'hd7', returnedBill);
			getVoucher();
		} catch (error) {
			contextAlert(error);
		} finally {
			setProgressStatus(false);
		}
	};
	useEffect(() => {
		if (ma_kho && ma_ban) {
			getVoucher();
		}
	}, [ma_kho, ma_ban]);
	useEffect(() => {
		setVoucher(vouchers[indexVoucher]);
	}, [vouchers, indexVoucher]);
	// useEffect(() => {
	// 	getVoucher();
	// }, [indexVoucher]);

	return (
		<>
			<CssBaseline />
			{PrintFrame}
			<SaleContainerStyled theme={theme}>
				<SaleContext.Provider
					value={{
						vouchers,
						indexVoucher,
						voucher,
						kho,
						ma_ban,
						ma_kho,
						load,
						saleMode,
						search,
						billMode,
						returnedVouchers,
						// function
						setReturnedVouchers,
						setBillMode,
						setSearch,
						setSaleMode,
						setVouchers,
						setIndexVoucher,
						setVoucher,
						setLoad,
						getVoucher,
						add,
						handleReturnedBillChange,
						handleBillChange,
						handleEvouchersChange,
						handleChietkhauChange,
						handleSavePBL,
						handleSavePayment,
						print,
					}}
				>
					<SaleHeader />
					{voucher?.ma_ct?.toLowerCase() === 'hd7' ? <ReturnedSaleMain /> : <SaleMain />}
					{voucher?.ma_ct?.toLowerCase() !== 'hd7' && <SaleFooter />}
				</SaleContext.Provider>
			</SaleContainerStyled>
		</>
	);
}

export default SaleContainer;
