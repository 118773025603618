import React from 'react';
import TabContainer from '../../../components/tab/TabContainer';
import TabCpmh from '../Dialog/TabItemsVoucher/TabCpmh';
import TabDetail from '../Dialog/TabItemsVoucher/TabDetail';
import TabVat from '../Dialog/TabItemsVoucher/TabVat';

function VoucherDetail({
	data,
	readonly,
	handleGetDataDetail,
	handleGetDataVat,
	handleGetDataCtcpmhs,
	handleAllotmentPn1,
	getAllotmentPn1,
	setValue,
	view,
	selectRow,
	isFieldNt,
}) {
	return (
		data && (
			<div className="dialog__content_edit_voucher__body box_detail_voucher_detail">
				<TabContainer
					tabs={[
						{
							label: 'Chi tiết',
							component: (
								<TabDetail
									data={data}
									readonly={readonly}
									handleGetDataDetail={handleGetDataDetail}
									getAllotmentPn1={getAllotmentPn1}
									view={view} // hidden toolbar
									selectRow={selectRow}
									isFieldNt={isFieldNt}
									setValue={setValue}
								/>
							),
						},
						{
							label: 'CP mua hàng',
							component: (
								<TabCpmh
									data={data}
									readonly={readonly}
									handleGetDataCtcpmhs={handleGetDataCtcpmhs}
									handleAllotmentPn1={handleAllotmentPn1}
									view={view}
									selectRow={selectRow}
									isFieldNt={isFieldNt}
									setValue={setValue}
								/>
							),
						},
						{
							label: 'Thuế',
							component: (
								<TabVat
									data={data}
									readonly={readonly}
									handleGetDataVat={handleGetDataVat}
									view={view}
									selectRow={selectRow}
									setValue={setValue}
									isFieldNt={isFieldNt}
								/>
							),
						},
					]}
				/>
			</div>
		)
	);
}

export default VoucherDetail;
