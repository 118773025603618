import { Tooltip } from '@material-ui/core';
import { AddRounded, DeleteRounded, FilterListRounded } from '@material-ui/icons';
import React from 'react';
import ListTable from '../components/table/ListTable/ListTable';

export default function ListBaseData({
	columns,
	data,
	getData,
	api,
	handleSubmit,
	handleDelete,
	handleDeleteList,
	q,
	title,
	tableProps,
	setOpenFilter,
	openFilter,
	FormDialog,
	importCode,
	exportCode,
}) {
	const [open, setOpen] = React.useState(false);
	const [formData, setFormData] = React.useState();
	const openFormDialog = React.useCallback((data) => {
		setFormData(data);
		setOpen(true);
	}, []);
	const closeFormDialog = React.useCallback(() => {
		setFormData();
		setOpen(false);
	}, []);

	const handleRowEdit = React.useCallback((data) => openFormDialog(data), [openFormDialog]);
	const handleRowCopy = React.useCallback((data) => openFormDialog({ ...data, _id: undefined }), [openFormDialog]);
	const handleRowDelete = React.useCallback((data) => handleDelete(data._id), [handleDelete]);

	// Selected Rows
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
	const handleRowSelected = (state) => {
		setSelectedRows(state.selectedRows);
	};
	const handleDeleteRowsSelected = () =>
		handleDeleteList(selectedRows.map((item) => item._id)).then(() => {
			setSelectedRows([]);
			setToggleCleared(!toggleCleared);
		});

	const actions = [
		<Tooltip title="Thêm mới" placement="top">
			<button onClick={() => openFormDialog()} className="btn-list">
				<AddRounded />
			</button>
		</Tooltip>,
	];

	if (selectedRows.length > 0) {
		actions.unshift(
			<button style={{ background: '#f44336' }} onClick={handleDeleteRowsSelected}>
				<DeleteRounded />
			</button>
		);
	}

	return (
		<>
			{FormDialog && (
				<FormDialog
					open={open}
					onClose={closeFormDialog}
					onSubmit={handleSubmit}
					data={formData}
					setData={setFormData}
				/>
			)}

			<ListTable
				importProps={{ getData, api, code: importCode, params: { ma_ct: api } }}
				exportProps={{ code: exportCode, params: { q, limit: tableProps.totalRows } }}
				columns={columns}
				data={data}
				actions={actions}
				title={
					<div className="table-title">
						<Tooltip title="Bộ lọc" placement="top">
							<button className="btn btn-list" onClick={() => setOpenFilter(!openFilter)}>
								<FilterListRounded />
							</button>
						</Tooltip>
						{title}
					</div>
				}
				onRowClick={handleRowEdit}
				onRowCopy={handleRowCopy}
				onRowDelete={handleRowDelete}
				selectableRows
				onSelectedRowsChange={handleRowSelected}
				toggleCleared={toggleCleared}
				{...tableProps}
			/>
		</>
	);
}
