import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const useStyles = makeStyles({
	customTooltip: {
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		color: '#111',
		borderRadius: '4px',
		border: '1px solid #111',
		padding: '8px',
		maxWidth: '200px',
		textAlign: 'center',
	},
});

const FieldBox = ({ title, check, tooltip, children, errorMsg, titleProps, contentProps, colWidthTitle = 4 }) => {
	const classes = useStyles();
	return (
		<>
			<Grid container spacing={2} className="field_input">
				{title && (
					<Grid item xs={colWidthTitle} className="field_input_title" {...titleProps}>
						{title}
						{tooltip && (
							<Tooltip
								title={tooltip}
								classes={{ tooltip: classes.customTooltip }}
								placement="right"
								arrow
							>
								<ErrorIcon
									style={{
										marginLeft: 5,
									}}
								/>
							</Tooltip>
						)}
						{check && <span className="start_check_dialog_field">*</span>}
					</Grid>
				)}
				<Grid item xs className="field_input_content" {...contentProps}>
					{children}
				</Grid>
			</Grid>

			{errorMsg && (
				<Grid container spacing={2} className="field_input" style={{ marginTop: 4 }}>
					{title && <Grid item xs={4} className="field_input_title" />}

					<Grid item xs className="field_input_content">
						<p className="field_input_error">{errorMsg}</p>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default FieldBox;
