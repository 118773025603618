import React from "react";
import {
  Icon,
  IconOption,
  Number,
  ProductInfo,
  ProductName,
} from "./barcodeStyled";
import { MdDelete } from "react-icons/md";
import { InputNumber } from "antd";
import { cloneDeep } from "lodash";
import CustomDisplayPopup from "./CustomDisplayPopup";
import { CgDisplayFullwidth } from "react-icons/cg";
import { Tooltip } from "@material-ui/core";

function ProductInfoLine({ product, handleDeleProduct, setSelectedProducts }) {
  const [openCustomDisplay, setOpenCustomDisplay] = React.useState(false);
  // handle change number
  const handleNumberChange = (number) => {
    setSelectedProducts((prev) => {
      const prevClone = cloneDeep(prev);
      const index = prevClone.findIndex((item) => item._id === product._id);
      if (prevClone[index].sl_xuat) {
        prevClone[index].sl_xuat = number;
      } else if (prevClone[index].sl_nhap) {
        prevClone[index].sl_nhap = number;
      }
      return prevClone;
    });
  };
  return (
    <>
      <CustomDisplayPopup
        product={product}
        setSelectedProducts={setSelectedProducts}
        open={openCustomDisplay}
        handleClose={() => setOpenCustomDisplay(false)}
      />
      <ProductInfo>
        <Icon onClick={() => handleDeleProduct(product)}>
          <MdDelete fontSize="14px" />
        </Icon>
        <ProductName>{product.ten_vt}</ProductName>
        {product.sl_in && (
          <Tooltip arrow placement="top" title="Số lượng in">
            <Number>{product.sl_in}</Number>
          </Tooltip>
        )}
        <Tooltip
          arrow
          placement="top"
          title="Tùy chọn hiển thị"
          style={{ fontSize: "13px" }}
        >
          <IconOption onClick={() => setOpenCustomDisplay(true)}>
            <CgDisplayFullwidth fontSize="14px" />
          </IconOption>
        </Tooltip>
        <InputNumber
          min={1}
          max={100}
          value={product.sl_xuat || product.sl_nhap}
          onChange={handleNumberChange}
          type="number"
        />
      </ProductInfo>
    </>
  );
}

export default ProductInfoLine;
