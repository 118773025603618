import { Dropdown, Radio, Select } from 'antd';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { getFirstDayOfMonth, getFirstDayOfWeek, getLastDayOfMonth, getLastDayOfWeek } from '../../../utils/helpers';
import Container from '../Container';
import LayoutContainer from '../components/LayoutContainer';
import './Task.css';
import TaskDialogAdd from './dialog/TaskDialogAdd';
import TaskDialogAddFull from './dialog/TaskDialogAddFull';
import TaskDialogEditMultiRow from './dialog/TaskDialogEditMultiRow';
import CalendarView from './views/CalendarView';
import KanbanView from './views/KanbanView';
import TableView from './views/TableView';
import { useCore } from '../../../hooks';

export const TaskContext = createContext();

const items = [
	{
		key: 'import-task',
		label: <p onClick={() => alert('Import Tasks')}>Import Tasks</p>,
	},
	{
		key: 'export-task',
		label: <p onClick={() => alert('Export Tasks')}>Export Tasks</p>,
	},
];

function TaskContent() {
	const [view, setView] = useState('table');
	const [q, setQ] = useState(undefined);
	const [filter, setFilter] = useState('all');

	const [openDialogAdd, setOpenDialogAdd] = useState(false);
	const [openDialogAddFull, setOpenDialogAddFull] = useState(false);
	const [openDialogEditMultiRow, setOpenDialogEditMultiRow] = useState(false);

	const [rowCheck, setRowCheck] = useState(false);
	const [dataTaskSelected, setDataTaskSelected] = useState();
	const [toggleCleared, setToggleCleared] = useState(false);

	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
	});

	const [dataTask, setDataTask] = useState([]);
	const [dataTaskDetail, setDataTaskDetail] = useState();

	const context = useContext(DefaultContext);
	const core = useCore();

	// Get data task
	const getDataTask = useCallback(async () => {
		core.setProgressStatus(true);

		try {
			const [data, count] = await Promise.all([
				core.apis.asyncGetList(core.token, 'task', {
					...params,
					q,
				}),
				core.apis.asyncGetList(core.token, 'task', {
					count: 1,
					q,
				}),
			]);

			// Set total pages
			const totalItem = count.rows_number;
			setTotalRows(totalItem);
			setDataTask(data);
		} catch (error) {
			console.log('getDataProduct() error', error);
		} finally {
			core.setProgressStatus(false);
		}
	}, [context, params, q]);

	// delete 1 data task
	const deleteDataTask = useCallback(
		async (id) => {
			core.contextAlert('Bạn có chắc chắn muốn xóa không?', async (isConfirm) => {
				if (!isConfirm) return;

				core.setProgressStatus(true);

				try {
					await core.asyncDeleteList(core.token, 'task', id);
					await getDataTask();

					toast.success('Xoá thành công 1 công việc');
				} catch (error) {
					core.contextAlert(error);
				} finally {
					core.setProgressStatus(false);
				}
			});
		},
		[context, getDataTask]
	);

	// delete data task list
	const deleteDataTaskList = useCallback(
		async (ids) => {
			core.contextAlert(`Bạn có chắc chắn muốn xóa ${ids.length} dòng không?`, async (isConfirm) => {
				if (!isConfirm) return;
				core.setProgressStatus(true);
				try {
					const errors = [];
					await Promise.all(
						ids.map((id) =>
							core.asyncDeleteList(core.token, 'task', id).catch((error) => errors.push(error))
						)
					);

					if (errors.length) {
						core.contextAlert(
							<>
								<p>{`Có ${errors.length}/${ids.length} dòng không xóa được`}</p>
								{errors.map((e, index) => (
									<div key={index}>{e.error || e}</div>
								))}
							</>
						);
					}

					toast.success(`Xoá thành công ${ids.length} công việc`);
				} catch (error) {
					core.contextAlert(error);
				} finally {
					await getDataTask();
					core.setProgressStatus(false);
				}
			});
		},
		[context, getDataTask]
	);

	const handleDeleteTaskList = async () => {
		console.log('dataTaskSelected', dataTaskSelected);

		await deleteDataTaskList(dataTaskSelected.map((item) => item._id));

		setToggleCleared(!toggleCleared);
		setDataTaskSelected(null);
	};

	// edit data task list
	const editDataTaskList = useCallback(
		async (ids, data) => {
			core.setProgressStatus(true);
			console.log('ids: ', ids);

			try {
				await Promise.all(
					ids.map((id) => {
						const dataUpdate = { ...data, _id: id };

						core.apis.asyncPostList(core.token, 'task', dataUpdate);

						console.log('dataUpdate', dataUpdate);
					})
				);

				await getDataTask();

				toast.success('Cập nhật thành công');
			} catch (error) {
				toast.success(error);
			} finally {
				await getDataTask();
				core.setProgressStatus(false);
			}
		},
		[context, getDataTask]
	);

	const handleEditTaskList = async (data) => {
		await editDataTaskList(
			dataTaskSelected.map((item) => item._id),
			data
		);

		setToggleCleared(!toggleCleared);
		setDataTaskSelected(null);
	};

	const handleChangePage = (page) => {
		setParams({
			...params,
			page,
		});
	};

	const handleChangeLimit = (limit) => {
		setParams({
			...params,
			limit,
		});
	};

	const handleChangeFilter = (value) => {
		setFilter(value);

		switch (value) {
			case 'all':
				setQ(undefined);
				return;
			case 'my':
				setQ({ phu_trach: core.email });
				return;
			case 'week':
				setQ({
					$and: [{ due_date: { $gte: getFirstDayOfWeek() } }, { due_date: { $lte: getLastDayOfWeek() } }],
				});
				return;
			case 'month':
				setQ({
					$and: [{ due_date: { $gte: getFirstDayOfMonth() } }, { due_date: { $lte: getLastDayOfMonth() } }],
				});
				return;
			default:
				return;
		}
	};

	const handleClickBtnEdit = () => {
		if (dataTaskSelected?.length > 1) {
			handleOpenDialogEditMultiRow();
		} else {
			toast.error(`Cần chọn tối thiểu 2 dòng để thực hiện chức năng này`);
		}
	};

	const handleOpenDialogAdd = () => {
		setOpenDialogAdd(true);
	};

	const handleCloseDialogAdd = () => {
		setOpenDialogAdd(false);
	};

	const handleOpenDialogAddFull = () => {
		setOpenDialogAddFull(true);
	};

	const handleCloseDialogAddFull = () => {
		setOpenDialogAddFull(false);
	};

	const handleOpenDialogEditMultiRow = () => {
		setOpenDialogEditMultiRow(true);
	};

	const handleCloseDialogEditMultiRow = () => {
		setOpenDialogEditMultiRow(false);
	};

	useEffect(() => {
		if (dataTaskSelected?.length > 0) {
			setRowCheck(true);
		} else {
			setRowCheck(false);
		}
	}, [dataTaskSelected, setRowCheck]);

	useEffect(() => {
		getDataTask();
	}, [params, getDataTask]);

	return (
		<TaskContext.Provider
			value={{
				getDataTask,
			}}
		>
			<LayoutContainer>
				<div className="box_task">
					<div className="box_header">
						{rowCheck ? (
							<div className="box_task__tool" style={{ justifyContent: 'start' }}>
								<div className="action_container">
									<i title="Sửa" className="far fa-pencil-alt" onClick={handleClickBtnEdit}></i>

									<i
										title="Xóa"
										className="far fa-trash-alt"
										style={{ color: '#F85C75' }}
										onClick={handleDeleteTaskList}
									></i>
								</div>

								<div className="selected_container">
									<p>
										{dataTaskSelected?.length} <span>dòng đã được chọn</span>
									</p>
								</div>
							</div>
						) : (
							<div className="box_task__tool">
								<div className="btn-filter">
									<Select
										defaultValue={filter}
										onChange={handleChangeFilter}
										// suffixIcon={<i className="fas fa-chevron-down"></i>}
										suffixIcon={<i className="far fa-filter"></i>}
										style={{
											width: 'auto',
											minWidth: 160,
											textAlign: 'center',
										}}
										options={[
											{
												value: 'all',
												label: 'Tất cả công việc',
											},
											{
												value: 'my',
												label: 'Công việc của tôi',
											},
											{
												value: 'week',
												label: 'Công việc hết hạn trong tuần',
											},
											{
												value: 'month',
												label: 'Công việc hết hạn trong tháng',
											},
										]}
									/>
								</div>

								<div className="flex-row">
									<button
										className="btn btn-add"
										onClick={() => {
											handleOpenDialogAdd();
										}}
									>
										<i className="far fa-plus me-1"></i> Thêm công việc
									</button>
									<Dropdown
										menu={{
											items,
										}}
										placement="bottom"
									>
										<span className="btn-import" onClick={(e) => e.preventDefault()}>
											<i className="fas fa-caret-down"></i>
										</span>
									</Dropdown>

									<div className="ms-1 ttt">
										<Radio.Group
											defaultValue="table"
											buttonStyle="solid"
											onChange={(e) => {
												setView(e.target.value);
											}}
										>
											<Radio.Button value="table">
												<i className="far fa-th-list"></i>
											</Radio.Button>
											<Radio.Button value="kanban">
												<i className="fab fa-trello"></i>
											</Radio.Button>
											<Radio.Button value="calendar">
												<i className="far fa-calendar"></i>
											</Radio.Button>
										</Radio.Group>
									</div>

									<button className="btn btn-white ms-1">
										<i className="far fa-sync me-1"></i> Sync
									</button>

									<button className="btn btn-white ms-1">
										<i className="far fa-cogs"></i>
									</button>
								</div>
							</div>
						)}
					</div>

					{view === 'table' ? (
						<TableView
							data={dataTask}
							params={params}
							totalRows={totalRows}
							toggleCleared={toggleCleared}
							handleChangePage={handleChangePage}
							handleChangeLimit={handleChangeLimit}
							setDataTaskSelected={setDataTaskSelected}
							deleteDataTask={deleteDataTask}
						/>
					) : view === 'kanban' ? (
						<div>
							<KanbanView data={dataTask} />
						</div>
					) : (
						<CalendarView data={dataTask} />
					)}

					<TaskDialogAdd
						openDialog={openDialogAdd}
						handleCloseDialog={handleCloseDialogAdd}
						handleOpenDialogFull={handleOpenDialogAddFull}
					/>

					<TaskDialogAddFull
						openDialogFull={openDialogAddFull}
						handleCloseDialogFull={handleCloseDialogAddFull}
						className={'dialog_item'}
					/>

					<TaskDialogEditMultiRow
						open={openDialogEditMultiRow}
						handleClose={handleCloseDialogEditMultiRow}
						handleEditTaskList={handleEditTaskList}
					/>
				</div>
			</LayoutContainer>
		</TaskContext.Provider>
	);
}

export default function Task(props) {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<TaskContent />
		</Container>
	);
}
