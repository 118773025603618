import React from 'react';
import '../components/filter/Filter.css';
import FilterListRatio from '../components/filter/FilterListRatio';
import FilterSearch from '../components/filter/FilterSearch';

const goodsGroupTypes = {
	all: {
		title: 'Tất cả',
		filter: {},
	},
	goods: {
		title: 'Nhóm hàng hóa',
		filter: { service_yn: { $ne: true } },
	},
	service: {
		title: 'Nhóm dịch vụ',
		filter: { service_yn: true },
	},
};

const searchFields = ['ten_nvt'];
export default function GoodsGroupFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		search: '',
		type: 'all',
	});

	React.useEffect(() => {
		const q = { $and: [] };

		if (filter.search)
			q.$and.push({ $or: searchFields.map((field) => ({ [field]: { $regex: filter.search, $options: 'i' } })) });

		if (filter.type) q.$and.push(goodsGroupTypes[filter.type].filter);

		onChange(q);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const onValueChange = React.useCallback((field) => (value) => setFilter({ ...filter, [field]: value }), [filter]);

	return (
		<div className='product__filter'>
			{/* Search box */}
			<FilterSearch onSearch={onValueChange('search')} />

			<FilterListRatio
				title='Loại nhóm'
				items={Object.keys(goodsGroupTypes).map((key) => ({
					label: goodsGroupTypes[key].title,
					value: key,
				}))}
				defaultValue={filter.type}
				onChange={onValueChange('type')}
			/>
		</div>
	);
}
