import { ImportExportRounded, MoreVertRounded, PrintRounded } from '@material-ui/icons';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import MenuPopup from '../../MenuPopup';
import TableContainer from '../TableContainer';
import ImportDialog from './dialog/import/ImportDialog';
import ExportDialog from './dialog/export/ExportDialog';

export default function ListTable({ importProps, exportProps, ...tableProps }) {
	// Add default list action
	const [openMenu, setOpenMenu] = React.useState('');
	const handleOpenMenu = (menu) => () => setOpenMenu(menu);
	const handleCloseMenu = () => setOpenMenu('');

	// Import Export Menu
	const importExportMenuAnchorRef = React.useRef(null);

	// Other Menu
	const otherMenuAnchorRef = React.useRef(null);

	// Abstract Dialog
	const [openDialog, setOpenDialog] = React.useState(false);
	const handleOpenDialog = (name) => () => setOpenDialog(name);
	const handleCloseDialog = () => setOpenDialog(false);

	tableProps.actions = [
		...tableProps.actions,

		<Tooltip title='Nhập/Xuất dữ liệu' placement='top'>
			<button ref={importExportMenuAnchorRef} onClick={handleOpenMenu('importExport')} className='btn-list'>
				<ImportExportRounded />
			</button>
		</Tooltip>,
		<Tooltip title='In các dòng đã chọn' placement='top'>
			<button className='btn-list'>
				<PrintRounded />
			</button>
		</Tooltip>,
		<Tooltip title='Chức năng khác' placement='top'>
			<button ref={otherMenuAnchorRef} onClick={handleOpenMenu('other')} className='btn-list'>
				<MoreVertRounded />
			</button>
		</Tooltip>,
	];

	return (
		<>
			{/* Import/Export */}
			<MenuPopup
				anchorRef={importExportMenuAnchorRef}
				open={openMenu === 'importExport'}
				onClose={handleCloseMenu}
				menuItems={[
					{
						label: 'Nhập dữ liệu Excel',
						onClick: handleOpenDialog('import'),
					},
					{
						label: 'Xuất dữ liệu Excel',
						onClick: handleOpenDialog('export'),
					},
				]}
			/>

			{/* Other */}
			<MenuPopup
				anchorRef={otherMenuAnchorRef}
				open={openMenu === 'other'}
				onClose={handleCloseMenu}
				menuItems={[
					{
						label: 'Kiểm tra trùng dữ liệu',
						onClick: () => console.log('Kiểm tra trùng dữ liệu'),
					},
					{
						label: 'Ghi lại sổ cách',
						onClick: () => console.log('Ghi lại sổ cách'),
					},
					{
						label: 'Tuỳ chọn',
						onClick: () => console.log('Tuỳ chọn'),
					},
					{
						label: 'Hiển thị dạng lưới',
						onClick: () => console.log('Hiển thị dạng lưới'),
					},
					{
						label: 'Hướng dẫn',
						onClick: () => console.log('Hướng dẫn'),
					},
				]}
			/>

			<ImportDialog open={openDialog === 'import'} handleClose={handleCloseDialog} {...importProps} />

			<ExportDialog open={openDialog === 'export'} handleClose={handleCloseDialog} {...exportProps} />

			{/* Table */}
			<TableContainer {...tableProps} />
		</>
	);
}
