import { addHours, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import dayjs from 'dayjs';
import numeral from 'numeral';

export const formatCurrencyDisplay = (number, subfix = '') => {
	if (!number && number !== 0) return;
	const formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return formattedNumber + subfix;
};
numeral.register('locale', 'vi', {
	delimiters: {
		thousands: ' ',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'Nghìn',
		million: 'Triệu',
		billion: 'Tỷ',
		trillion: 'Nghìn Tỷ',
	},
	currency: {
		symbol: '₫',
	},
});
numeral.locale('vi');
export { numeral as numeralCustom };

export const formatDateDisplay = (date, format = 'DD/MM/YYYY') => {
	if (!date) return;
	return dayjs(date).format(format);
};
export const formatDateValue = (date, format = 'YYYY-MM-DD') => formatDateDisplay(date, format);

export const capitalize = (str, all = false) => {
	if (!str) return '';
	if (all)
		return str
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');

	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertBytesToSize = (bytes) => {
	if (!bytes) return '0 Bytes';
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const typeOf = (obj) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

export const isObject = (obj) => typeOf(obj) === 'object';
export const isArray = (obj) => typeOf(obj) === 'array';
export const isString = (obj) => typeOf(obj) === 'string';
export const isNumber = (obj) => typeOf(obj) === 'number';
export const isBoolean = (obj) => typeOf(obj) === 'boolean';

export const cleanData = (data, { defaultValues = {} } = {}) => {
	for (const key in data) {
		if (isObject(data[key])) data[key] = {};
		else if (isArray(data[key])) data[key] = [];
		else if (isString(data[key])) data[key] = '';
		else if (isNumber(data[key])) data[key] = 0;
		else if (isBoolean(data[key])) data[key] = false;
	}
	return { ...defaultValues, ...data }; // add default values
};

export const copyData = (data, resetFields = []) => {
	const newData = JSON.parse(JSON.stringify(data));
	delete newData._id;

	resetFields.forEach((field) => {
		newData[field] = '';
	});

	return newData;
};

export const isImage = (fileName = '') => {
	const ext = fileName.split('.').pop();
	return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(ext);
};

//Hàm xác định ngày bắt đầu và kết thúc của tuần hiện tại
// Lấy ngày đầu tiên của tuần hiện tại (thứ Hai)
export const getFirstDayOfWeek = () => {
	const currentDate = new Date();

	const firstDayOfWeek = addHours(startOfWeek(currentDate, { weekStartsOn: 1 }), 7);

	return firstDayOfWeek;
};

// Lấy ngày cuối cùng của tuần hiện tại (Chủ Nhật)
export const getLastDayOfWeek = () => {
	const currentDate = new Date();

	const lastDayOfWeek = addHours(endOfWeek(currentDate, { weekStartsOn: 1 }), 7);

	return lastDayOfWeek;
};

//Hàm xác định ngày bắt đầu và kết thúc của tháng hiện tại
// Lấy ngày đầu tiên của tháng hiện tại (ngày 1)
export const getFirstDayOfMonth = () => {
	const currentDate = new Date();

	const firstDayOfMonth = addHours(startOfMonth(currentDate), 7);

	return firstDayOfMonth;
};

// Lấy ngày cuối cùng của tháng hiện tại (ngày cuối cùng của tháng)
export const getLastDayOfMonth = () => {
	const currentDate = new Date();

	const lastDayOfMonth = addHours(endOfMonth(currentDate), 7);

	return lastDayOfMonth;
};
