import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import SaleChannelFilter from './SaleChannelFilter';
import FormSaleChannelDialog from './dialog/FormSaleChannelDialog';

const ListSaleChannel = () => {
	const columns = [
		{
			name: 'Mã kênh',
			selector: (row) => row.ma_kenh,
			width: '200px',
		},
		{
			name: 'Tên kênh',
			selector: (row) => row.ten_kenh,
		},
	];

	return (
		<ListBase
			api="dmkenhbanhang"
			title="Danh sách kênh bán hàng"
			columns={columns}
			FilterComponent={SaleChannelFilter}
			FormDialog={FormSaleChannelDialog}
		/>
	);
};

export default withContainer(ListSaleChannel);
