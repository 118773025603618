import React from 'react';
import SelectApi from '../../input/SelectApi';

function SelectEditor({
	value,
	readonly,
	api,
	valueField,
	labelField,
	onChange,
	onFocus,
	getLabel,
	q,
	prefix,
	filter,
	componentAdd,
	defaultValue,
}) {
	return readonly ? (
		<div
			style={{
				width: '100%',
				display: 'flex',
			}}
		>
			<input
				readOnly={true}
				value={value}
				style={{
					fontSize: '13px',
					padding: '0px 10px',
					width: '100%',
					height: '100%',
				}}
			/>
		</div>
	) : (
		<div
			style={{
				width: '100%',
			}}
		>
			<SelectApi
				api={api}
				defaultValue={
					defaultValue || {
						value: value,
						label: value,
					}
				}
				valueField={valueField}
				labelField={labelField}
				placeholder={'...'}
				q={q}
				prefix={prefix}
				filter={filter}
				onChange={(newValue) => {
					onChange(newValue);
				}}
				onFocus={onFocus}
				getLabel={getLabel}
				classNamePrefix="field-select-api-voucher"
				components={componentAdd}
			/>
		</div>
	);
}

export default SelectEditor;
