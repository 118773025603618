import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'react-slideshow-image/dist/styles.css';
import { useCore } from '../../../../../hooks/useCore';
import { cleanData, copyData } from '../../../../../utils/helpers';
import Dialog from '../../../components/Dialog/Dialog';
import TabContainer from '../../../components/tab/TabContainer';
import HistoryTab from './tabs/HistoryTab';
import InfoTab from './tabs/InfoTab';
import MoreTab from './tabs/MoreTab';
import AuthorizationTab from './tabs/AuthorizationTab';

export default function FormCustomerDialog({ open, onClose, data, onSubmit, isSupplier = false, subfix }) {
	const defaultValues = { status: true, isseller: isSupplier };

	const hookForm = useForm({
		defaultValues,
	});

	const {
		handleSubmit,
		reset,
		formState: { isSubmitting },
		watch,
	} = hookForm;

	useEffect(() => {
		if (open) reset(data || defaultValues);
	}, [data, open]);

	const core = useCore();
	const preSubmit = async (data) => {
		if (data.imageFile) {
			const file = await core.uploadFile(data.imageFile, { folder: 'customer' });
			data.hinh_anh = file.fileUrl;
			delete data.imageFile;
			reset(data);
		}

		return data;
	};

	const onSave = (data) =>
		preSubmit(data)
			.then(onSubmit)
			.then(() => onClose());

	const onSaveAndNew = (data) =>
		preSubmit(data)
			.then(onSubmit)
			.then(() => reset(cleanData(data, { defaultValues })));

	const onSaveAndCopy = (data) =>
		preSubmit(data)
			.then(onSubmit)
			.then(() => reset(copyData(data)));

	let tabs = [
		{
			label: 'Thông tin',
			component: <InfoTab hookForm={hookForm} subfix={subfix} isSupplier={isSupplier} />,
		},
		{
			label: 'Thông tin khác',
			component: <MoreTab />,
		},
		{
			label: 'Phân quyền',
			component: <AuthorizationTab hookForm={hookForm} />,
		},
	];

	// if (!isSupplier) {
	// 	tabs = [
	// 		...tabs,
	// 		{
	// 			label: 'Lịch sử',
	// 			component: <HistoryTab />,
	// 		},
	// 	];
	// }

	return (
		<Dialog
			width={1000}
			open={open}
			handleClose={onClose}
			title={watch('_id') ? `Chỉnh sửa ${subfix}` : `Thêm ${subfix}`}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(onSave)} disabled={isSubmitting}>
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndNew)} disabled={isSubmitting}>
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndCopy)} disabled={isSubmitting}>
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					Hủy
				</button>,
			]}
		>
			<TabContainer tabs={tabs} />
		</Dialog>
	);
}
