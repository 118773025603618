import React from 'react';
import FilterListRatio from '../components/filter/FilterListRatio';
import FilterSearch from '../components/filter/FilterSearch';

const customerGroupTypes = {
	all: {
		title: 'Tất cả',
		filter: {},
	},
	customer: {
		title: 'Nhóm khách hàng',
		filter: { isseller: { $ne: true } },
	},
	supplier: {
		title: 'Nhóm nhà cung cấp',
		filter: { isseller: true },
	},
};

const searchFields = ['group_name'];
export default function CustomerGroupFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		search: '',
		type: 'all',
	});

	React.useEffect(() => {
		const q = { $and: [] };

		if (filter.search)
			q.$and.push({ $or: searchFields.map((field) => ({ [field]: { $regex: filter.search, $options: 'i' } })) });

		if (filter.type) q.$and.push(customerGroupTypes[filter.type].filter);

		onChange(q);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const onValueChange = React.useCallback((field) => (value) => setFilter({ ...filter, [field]: value }), [filter]);

	return (
		<div className='product__filter'>
			{/* Search box */}
			<FilterSearch onSearch={onValueChange('search')} />

			<FilterListRatio
				title='Loại nhóm'
				items={Object.keys(customerGroupTypes).map((key) => ({
					label: customerGroupTypes[key].title,
					value: key,
				}))}
				defaultValue={filter.type}
				onChange={onValueChange('type')}
			/>
		</div>
	);
}
