import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldInput from '../../../../components/Dialog/FieldInput';
import FieldNumberInput from '../../../../components/Dialog/FieldNumberInput';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import FieldTagInput from '../../../../components/Dialog/FieldTagInput';

const AccountSelect = ({ hookForm, name, title, placeholder, check }) => {
	const {
		control,
		getValues,
		setValue,
		formState: { errors },
	} = hookForm;

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: check ? `Vui lòng nhập ${title}` : false }}
			render={({ field: { value, onChange } }) => {
				const label = `${getValues(name)} - ${getValues(`ten_${name}`)}`;
				const item = value ? { value, label } : null;
				const handleChange = (item) => {
					onChange(item?.value || null);
					setValue(`ten_${name}`, item?.ten_tk || null);
				};

				return (
					<FieldSelectApi
						title={title}
						placeholder={placeholder}
						api="account"
						q={{ loai_tk: 1 }}
						valueField="tk"
						labelField="ten_tk"
						getLabel={(item) => `${item.tk} - ${item.ten_tk}`}
						value={item}
						onChange={handleChange}
						check={check}
						errorMsg={errors?.[name]?.message}
					/>
				);
			}}
		/>
	);
};

export default function AccountingTab({ hookForm }) {
	const { register, control, watch } = hookForm;
	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<AccountSelect
					hookForm={hookForm}
					name="tk_vt"
					title="TK vật tư"
					placeholder="Nhập TK vật tư"
					check={watch('tg_tk')}
				/>
			</Grid>

			<Grid item xs={6}>
				<AccountSelect hookForm={hookForm} name="tk_dt" title="TK doanh thu" placeholder="Nhập TK doanh thu" />
			</Grid>

			<Grid item xs={6}>
				<AccountSelect hookForm={hookForm} name="tk_gv" title="TK giá vốn" placeholder="Nhập TK giá vốn" />
			</Grid>

			<Grid item xs={6}>
				<AccountSelect hookForm={hookForm} name="tk_tl" title="TK trả lại" placeholder="Nhập TK trả lại" />
			</Grid>

			<Grid item xs={6}>
				<AccountSelect hookForm={hookForm} name="tk_dl" title="TK đại lý" placeholder="Nhập TK đại lý" />
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="ton_kho_mac_dinh"
					control={control}
					render={({ field }) => (
						<FieldNumberInput title="Tồn kho mặc định" placeholder="Nhập tồn kho mặc định" {...field} />
					)}
				/>
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="gia_ban_le"
					control={control}
					render={({ field }) => (
						<FieldNumberInput title="Giá mua mặc định" placeholder="Nhập giá mua mặc định" {...field} />
					)}
				/>
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="gia_xuat"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldSelect
							title="PP tính giá xuất"
							items={[
								{
									value: '1',
									label: 'Trung bình tháng',
								},
								{
									value: '2',
									label: 'Nhập trước xuất trước',
								},
								{
									value: '3',
									label: 'Đích danh',
								},
							]}
							disableClearable
							defaultValue={value}
							onChange={({ value }) => onChange(value)}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Nhà cung cấp" placeholder="Nhập nhà cung cấp" inputProps={{ ...register('ncc') }} />
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="nhan"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldTagInput title="Nhãn" placeholder="Nhập nhãn" value={value} onChange={onChange} />
					)}
				/>
			</Grid>
			<Grid item xs={3}>
				<Controller
					name="sua_tk"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size="small" />}
							label="Sửa tk vật tư"
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={3}>
				<Controller
					name="ma_tt1"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size="small" />}
							label="Theo dõi theo tt 1"
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={3}>
				<Controller
					name="ma_tt3"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size="small" />}
							label="Theo dõi theo tt 2"
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={2}>
				<Controller
					name="ma_tt3"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size="small" />}
							label="Theo dõi theo tt 3"
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
}
