import React from 'react';
import MenuBase from './MenuBase';
import { Box, Menu, MenuItem, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { unicode2Ascii } from 'flexbiz-core/utils';
import { SaleContext } from '../../SaleContainer';
import { cloneDeep } from 'lodash';
import { CiSearch } from 'react-icons/ci';

const MenuStyled = styled(Menu)`
	& .MuiList-root {
		padding: 10px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		width: 400px;
	}
`;
const MenuSearch = styled(Box)`
	height: 42px;
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 6px;
	&:focus-within {
		border-color: #4bac4d;
	}
`;
const SearchIcon = styled(Box)`
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
`;
const SearchInput = styled.input`
	flex: 1;
	height: 34px;
	outline: none;
	border: none;
	padding: 5px;
	font-size: 13px;
`;
const MenuList = styled(Box)`
	width: 100%;
	height: auto;
	max-height: 40vh;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
const MenuItemStyled = styled(MenuItem)`
	font-size: 13px;
	flex-shrink: 0;
`;

function MenuNhanVien() {
	const context = React.useContext(DefaultContext);
	const { voucher, handleSavePBL, getVoucher } = React.useContext(SaleContext);
	const timerRef = React.useRef();

	const [anchor, setAnchor] = React.useState(null);
	const [staffs, setStaffs] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [searchText, setSearchText] = React.useState('');
	const [page, setPage] = React.useState(1);

	// handle select taff
	const handleSelectStaff = async (nhanvien) => {
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.ong_ba = nhanvien.ten_nv;
		voucherCopy.details.forEach((detail) => {
			detail.ma_nv = nhanvien.ma_nv;
		});
		await handleSavePBL(voucherCopy);
		getVoucher();
		setAnchor(null);
		setSearchText('');
		setSearch('');
	};
	const loadStaff = async () => {
		try {
			let condition = {};
			if (search) {
				condition = {
					$or: [],
				};
				//
				condition.$or.push({
					ma_nv: { $regex: search, $options: 'i' },
				});
				condition.$or.push({
					ten_nv: { $regex: search.split(' ').join('.*'), $options: 'i' },
				});
			}
			const resp = await context.apis.asyncGetList(context.userInfo.token, 'dmnv', {
				page,
				limit: 500,
				condition,
			});
			setStaffs(resp);
		} catch (error) {
			context.alert(error);
		}
	};
	React.useEffect(() => {
		loadStaff();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, context]);
	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
	}, [searchText]);

	return (
		<MenuBase
			displayText={voucher?.ong_ba || 'Nhân viên'}
			tooltipText={voucher?.ong_ba ? `Nhân viên: ${voucher?.ong_ba}` : 'Nhân viên'}
			onClick={(e) => setAnchor(e.currentTarget)}
		>
			<MenuStyled anchorEl={anchor} open={!!anchor} onClose={() => setAnchor(null)}>
				<MenuSearch>
					<SearchIcon>
						<CiSearch fontSize="20px" />
					</SearchIcon>
					<SearchInput
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder="Tìm nhân viên"
					/>
				</MenuSearch>
				<MenuList>
					{staffs.length > 0 ? (
						<>
							<MenuItemStyled onClick={() => handleSelectStaff({ ten_nv: '', ma_nv: '' })}>
								--
							</MenuItemStyled>
							{staffs.map((item) => (
								<MenuItemStyled key={item._id} onClick={() => handleSelectStaff(item)}>
									{item.ten_nv} {item.email ? `- ${item.email}` : ''}
								</MenuItemStyled>
							))}
						</>
					) : (
						<MenuItemStyled style={{ textAlign: 'center' }}>Không có nhân viên</MenuItemStyled>
					)}
				</MenuList>
			</MenuStyled>
		</MenuBase>
	);
}

export default MenuNhanVien;
