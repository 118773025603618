import React from 'react';
import InputContainer from '../../components/Dialog/InputContainer';
import styles from '../AppConfig.module.css';
import HtmlParser from 'react-html-parser';
import { Controller } from 'react-hook-form';

export default function OtherTab({ hookForm }) {
	const { control } = hookForm;
	return (
		<div className={styles.otherTab}>
			<InputContainer title='Điều khoản sử dụng' defaultShow>
				<Controller
					name='dieu_khoan_su_dung'
					control={control}
					render={({ field: { value } }) => HtmlParser(value)}
				/>
			</InputContainer>

			<InputContainer title='Điều khoản cộng tác viên' defaultShow>
				<Controller
					name='dieu_khoan_congtacvien'
					control={control}
					render={({ field: { value } }) => HtmlParser(value)}
				/>
			</InputContainer>

			<InputContainer title='Giới thiệu về công ty' defaultShow>
				<Controller name='gioi_thieu' control={control} render={({ field: { value } }) => HtmlParser(value)} />
			</InputContainer>

			<InputContainer title='Ngành nghề' defaultShow>
				<Controller name='nganh_nghe' control={control} render={({ field: { value } }) => HtmlParser(value)} />
			</InputContainer>
		</div>
	);
}
