import { Chip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Dropdown } from 'antd';
import React, { useState } from 'react';
import { TbColumnInsertRight } from 'react-icons/tb';
import { formatDateTimeValue } from '../../Utils/MyUtils';
import TableContainer from '../../components/table/TableContainer';
import TableManager from '../../components/tableManager/TableManager';
import TaskDialogDetailApi from '../dialog/TaskDialogDetailApi';
import TaskDialogEdit from '../dialog/TaskDialogEdit';
import './TableView.css';

export default function TableView({
	data,
	params,
	totalRows,
	toggleCleared,
	handleChangePage,
	handleChangeLimit,
	setDataTaskSelected,
	deleteDataTask,
}) {
	const [index, setIndex] = useState();
	const [rowClick, setRowClick] = useState();
	const [rowHover, setRowHover] = useState();

	const [openDialogDetail, setOpenDialogDetail] = useState(false);
	const [openDialogEdit, setOpenDialogEdit] = useState(false);

	const [openModalSelect, setOpenModalSelect] = useState(false);

	// const [dataTaskSelect, setDataTaskSelect] = useState();

	const [show, setShow] = useState({
		all: false,
		ten_cv: false,
		ten_phu_trach: false,
		start_date: false,
		due_date: false,
		ten_trang_thai: false,
		ten_bp: false,
		ten_dt: false,
		ten_kh: false,
	});

	const onSelectedRowsChange = (row) => {
		setOpenDialogDetail(false);
		setDataTaskSelected(row);
	};

	const handleSelectFieldShow = () => {
		setOpenModalSelect(!openModalSelect);
	};

	const handleOpenDialogDetail = (row) => {
		setOpenDialogDetail(true);
		setIndex(data.indexOf(row));
		setRowClick(row);
	};

	const handleOpenDialogEdit = () => {
		setOpenDialogEdit(true);
	};

	const handleCloseDialogEdit = () => {
		setOpenDialogEdit(false);
	};

	const items = [
		{
			key: 'edit',
			label: <p onClick={handleOpenDialogEdit}>Sửa</p>,
		},
		{
			key: 'delete',
			label: <p onClick={() => deleteDataTask(rowHover._id)}>Xóa</p>,
		},
	];

	// const handleCloseDialogDetail = () => {
	//   setOpenDialogDetail(false);
	// };

	const handleRowHover = (row) => {
		setRowHover(row);
	};

	const handleRowLeave = (row) => {
		// setRowHover(null);
	};

	const handleChangeFieldShow = (e) => {
		const val = e.target.value;

		switch (val) {
			case 'all':
				setShow((prevState) => ({
					all: !prevState.all,
					ten_cv: !prevState.all,
					ten_phu_trach: !prevState.all,
					start_date: !prevState.all,
					due_date: !prevState.all,
					ten_trang_thai: !prevState.all,
					ten_bp: !prevState.all,
					ten_dt: !prevState.all,
					ten_kh: !prevState.all,
				}));
				return;

			case 'ten_cv':
				setShow((prevState) => ({
					...prevState,
					ten_cv: !prevState.ten_cv,
					all:
						!prevState.ten_phu_trach &&
						!prevState.start_date &&
						!prevState.due_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_bp &&
						!prevState.ten_dt &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'ten_phu_trach':
				setShow((prevState) => ({
					...prevState,
					ten_phu_trach: !prevState.ten_phu_trach,
					all:
						!prevState.ten_cv &&
						!prevState.start_date &&
						!prevState.due_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_bp &&
						!prevState.ten_dt &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'start_date':
				setShow((prevState) => ({
					...prevState,
					start_date: !prevState.start_date,
					all:
						!prevState.ten_cv &&
						!prevState.ten_phu_trach &&
						!prevState.due_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_bp &&
						!prevState.ten_dt &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'due_date':
				setShow((prevState) => ({
					...prevState,
					due_date: !prevState.due_date,
					all:
						!prevState.ten_cv &&
						!prevState.ten_phu_trach &&
						!prevState.start_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_bp &&
						!prevState.ten_dt &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'ten_trang_thai':
				setShow((prevState) => ({
					...prevState,
					ten_trang_thai: !prevState.ten_trang_thai,
					all:
						!prevState.ten_cv &&
						!prevState.ten_phu_trach &&
						!prevState.start_date &&
						!prevState.due_date &&
						!prevState.ten_bp &&
						!prevState.ten_dt &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'ten_bp':
				setShow((prevState) => ({
					...prevState,
					ten_bp: !prevState.ten_bp,
					all:
						!prevState.ten_cv &&
						!prevState.ten_phu_trach &&
						!prevState.start_date &&
						!prevState.due_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_dt &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'ten_dt':
				setShow((prevState) => ({
					...prevState,
					ten_dt: !prevState.ten_dt,
					all:
						!prevState.ten_cv &&
						!prevState.ten_phu_trach &&
						!prevState.start_date &&
						!prevState.due_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_bp &&
						!prevState.ten_kh
							? !prevState.all
							: prevState.all,
				}));
				return;

			case 'ten_kh':
				setShow((prevState) => ({
					...prevState,
					ten_kh: !prevState.ten_kh,
					all:
						!prevState.ten_cv &&
						!prevState.ten_phu_trach &&
						!prevState.start_date &&
						!prevState.due_date &&
						!prevState.ten_trang_thai &&
						!prevState.ten_bp &&
						!prevState.ten_dt
							? !prevState.all
							: prevState.all,
				}));
				return;

			default:
				return;
		}
	};

	const columns = [
		// {
		// 	name: (
		// 		<TbColumnInsertRight
		// 			title="Tùy chọn các trường được hiển thị"
		// 			className="btn-modal-select"
		// 			style={{ fontSize: '40px' }}
		// 			onClick={handleSelectFieldShow}
		// 		/>
		// 	),
		// 	cell: (row) =>
		// 		row.follow ? (
		// 			<button className="btn-unfollow">
		// 				<i className="fas fa-star" title="Click to unfollow"></i>
		// 			</button>
		// 		) : (
		// 			<button className="btn-follow">
		// 				<i className="far fa-star" title="Click to follow"></i>
		// 			</button>
		// 		),
		// 	width: '50px',
		// 	sortable: false,
		// 	reorder: false,
		// 	center: true,
		// },
		// {
		// 	Header: 'Action',
		// 	cell: (row) => (
		// 		<Dropdown
		// 			menu={{
		// 				items,
		// 			}}
		// 			placement="bottom"
		// 		>
		// 			<span className="btn-action" onClick={(e) => e.preventDefault()}>
		// 				<i className="far fa-ellipsis-v"></i>
		// 			</span>
		// 		</Dropdown>
		// 	),

		// 	width: '80px',
		// 	sortable: false,
		// 	reorder: false,
		// 	center: true,
		// },
		{
			Header: 'Tên công việc',
			accessor: 'ten_cv',
			width: 180,
		},
		{
			Header: 'Phụ trách',
			accessor: 'ten_phu_trach',
			width: 180,
		},
		{
			Header: 'Ngày bắt đầu',
			accessor: 'start_date',
			width: 180,
			Cell: ({ row }) => {
				return <input value={formatDateTimeValue(row.original.start_date)} />;
			},
		},
		{
			Header: 'Ngày kết thúc',
			accessor: 'due_date',
			width: 180,
			Cell: ({ row }) => {
				return <input value={formatDateTimeValue(row.original.due_date)} />;
			},
		},
		{
			Header: 'Trạng thái',
			accessor: 'ten_trang_thai',
			Cell: ({ row }) => {
				return (
					<div>
						<Chip
							size="small"
							label={row.original.ten_trang_thai}
							style={{ backgroundColor: row.original.color, color: 'white' }}
						/>
					</div>
				);
			},
		},
		{
			Header: 'Người tạo',
			accessor: 'name_user_created',
			width: 180,
		},
		{
			Header: 'Bộ phận',
			accessor: 'ten_bp',
			width: 180,
		},
		{
			Header: 'Dự án',
			accessor: 'ten_dt',
			width: 180,
		},
		{
			Header: 'Khách hàng',
			accessor: 'ten_kh',
			width: 180,
		},
	];

	return (
		<div className="box_task__table">
			{/* <TableContainer
				columns={columns}
				data={data}
				pagination={true}
				totalRows={totalRows}
				limit={params.limit}
				handlePaginationChange={handleChangePage}
				handleLimitChange={handleChangeLimit}
				customHeader={true}
				onRowClick={handleOpenDialogDetail}
				selectableRows={true}
				onSelectedRowsChange={onSelectedRowsChange}
				onRowMouseEnter={handleRowHover}
				onRowMouseLeave={handleRowLeave}
				toggleCleared={toggleCleared}
				fixedHeader
				fixedHeaderScrollHeight="590px"
			/> */}

			<TableManager
				columns={columns}
				data={data}
				// updateMyData={updateMyData}
				// skipReset={skipResetRef.current}
				selectRow={true}
				onSelectRowClick={onSelectedRowsChange}
				readonly={true}
				onRowClick={handleOpenDialogDetail}
				fixedHeaderScrollHeight={525}
				pagination={true}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handleChangePage}
			/>

			{openDialogDetail ? (
				<TaskDialogDetailApi
					data={data}
					idx={index}
					rowClick={rowClick}
					open={openDialogDetail}
					setOpenDialogDetail={setOpenDialogDetail}
				/>
			) : null}

			{openDialogEdit ? (
				<TaskDialogEdit open={openDialogEdit} handleClose={handleCloseDialogEdit} data={rowHover} />
			) : null}

			{openModalSelect ? (
				<>
					<div className="task__modal_select" onClick={() => setOpenModalSelect(false)}></div>

					<FormGroup className="task__modal_select__content">
						<h5>Tùy chọn hiển thị:</h5>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.all}
									onChange={handleChangeFieldShow}
									value={'all'}
								/>
							}
							label="Tất cả"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.ten_cv}
									onChange={handleChangeFieldShow}
									value={'ten_cv'}
								/>
							}
							label="Tên công việc"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.ten_phu_trach}
									onChange={handleChangeFieldShow}
									value={'ten_phu_trach'}
								/>
							}
							label="Phụ trách"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.start_date}
									onChange={handleChangeFieldShow}
									value={'start_date'}
								/>
							}
							label="Ngày bắt đầu"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.due_date}
									onChange={handleChangeFieldShow}
									value={'due_date'}
								/>
							}
							label="Ngày kết thúc"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.ten_trang_thai}
									onChange={handleChangeFieldShow}
									value={'ten_trang_thai'}
								/>
							}
							label="Trạng thái"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.ten_bp}
									onChange={handleChangeFieldShow}
									value={'ten_bp'}
								/>
							}
							label="Bộ phận"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.ten_dt}
									onChange={handleChangeFieldShow}
									value={'ten_dt'}
								/>
							}
							label="Dự án"
						/>

						<FormControlLabel
							control={
								<Checkbox
									size="small"
									checked={!show.ten_kh}
									onChange={handleChangeFieldShow}
									value={'ten_kh'}
								/>
							}
							label="Khách hàng"
						/>
					</FormGroup>
				</>
			) : null}
		</div>
	);
}
