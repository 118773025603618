import { DialogActions, DialogContent, DialogTitle, Dialog as MuiDialog, makeStyles } from '@material-ui/core';
import React from 'react';
import './Dialog.css';
import { GrClose } from 'react-icons/gr';
import { Spin } from 'antd';
import { Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
	dialog: {
		width: (props) => props.width,
		minHeight: '50px',
		maxWidth: '100%',
		maxHeight: '90vh',
		overflow: 'visible',
		'--primary-color': theme.palette.primary.main,
		'--secondary-color': theme.palette.secondary.main,

		'& *': {
			boxSizing: 'border-box',
		},

		'& .MuiFormControlLabel-label': {
			fontSize: '13px',
		},
	},
	actions: {
		padding: '14px 24px 24px',
	},
	content: {
		overflow: 'auto',
		position: 'relative',
	},
	root: {
		zIndex: '1200 !important',
	},
	loading: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 10000,
		background: 'white',
	},
}));

const Dialog = ({
	open,
	handleClose,
	title,
	actions,
	children,
	className,
	classNameContent,
	loading,
	width = 'fit-content',
}) => {
	actions = actions?.map((action, index) => {
		return React.cloneElement(action, {
			key: index,
		});
	});
	const classes = useStyles({ width });

	return (
		<MuiDialog
			classes={{ paper: classes.dialog + ' ' + className, root: classes.root }}
			fullWidth
			open={open}
			onClose={handleClose}
			keepMounted={false}
		>
			<DialogTitle
				style={{
					padding: 0,
				}}
			>
				<div className="box_header_dialog">
					<span className="header_add_dialog">{title}</span>
					<span className="btn_close_dialog" onClick={handleClose}>
						<GrClose />
					</span>
				</div>
			</DialogTitle>
			<DialogContent classes={{ root: classes.content + ' ' + classNameContent }}>
				{children}
				{loading && (
					<div
						className={classes.loading}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<Spin size="large" />
					</div>
				)}
			</DialogContent>
			{actions?.length > 0 && <DialogActions className={classes.actions}>{actions}</DialogActions>}
		</MuiDialog>
	);
};

export default Dialog;
