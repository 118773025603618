import React from 'react';
import { SaleContext } from '../SaleContainer';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { cloneDeep } from 'lodash';
import { Box } from '@material-ui/core';
import {
	ButtonCheckout,
	ButtonPrint,
	PaymentBottom,
	PaymentContainer,
	PaymentContent,
	PaymentTop,
	PriceLine,
	PriceText,
	PriceInput,
	ContentWrapper,
} from './paymentComponent';
import { numeralCustom } from '../../../../../utils/helpers';
import MenuChannel from '../components/menu/MenuChannel';
import MenuNhanVien from '../components/menu/MenuNhanVien';
import { useTheme } from '@material-ui/core/styles';
import SearchCustomer from '../main/products/SearchCustomer';
import BoardPrice from '../main/products/BoardPrice';
import { Typography } from 'antd';

function ReturnedPayment({ handleClose = () => {} }) {
	const theme = useTheme();
	const context = React.useContext(DefaultContext);
	const { voucher, handleSavePBL, handleReturnedBillChange, print, getVoucher } = React.useContext(SaleContext);
	const [currentVoucher, setCurrentVoucher] = React.useState(null);
	const [customer, setCustomer] = React.useState({ ten_kh: 'Khách lẻ' });

	// calcConNo
	const calcConNo = (voucher) => {
		voucher.tien_evoucher = (voucher.evouchers || [])
			.map((e) => {
				let so_tien = e.ty_le ? Math.roundBy((voucher.t_tien_nt * e.ty_le) / 100, 0) : e.so_tien;
				if (e.so_tien_max && so_tien > e.so_tien_max) so_tien = e.so_tien_max;
				e.so_tien_giam = so_tien;
				return so_tien;
			})
			.reduce((a, b) => a + b, 0);
		voucher.t_ck_nt =
			voucher.details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
			(voucher.tien_ck_hd || 0) +
			(voucher.tien_evoucher || 0);
		voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
		voucher.con_no = voucher.t_tt_nt - voucher.tien_thu - voucher.tien_thu2 - (voucher.tien_paymentByPoints || 0);
		if (voucher.con_no < 0) {
			voucher.phai_tra = Math.abs(voucher.con_no);
			voucher.con_no = 0;
		} else {
			voucher.phai_tra = 0;
		}
	};
	// handle chietkhau change
	const handleChietKhauChange = async (value) => {
		const voucherCopy = cloneDeep(currentVoucher);
		voucherCopy.t_ck_nt = value;
		await handleReturnedBillChange(voucherCopy);
		getVoucher();
	};

	// payment
	const confirm = async () => {
		const voucherCopy = cloneDeep(currentVoucher);
		voucherCopy.trang_thai = 'DUYET';
		handleReturnedBillChange(voucherCopy);
		handleClose();
	};

	// get customer
	const getCustomer = async () => {
		try {
			const resp = await context.apis.asyncGetData(context.userInfo.token, 'customer', { ma_kh: voucher.ma_kh });
			if (resp) {
				setCustomer(resp);
			} else {
				setCustomer({ ten_kh: 'Khách lẻ' });
			}
		} catch (error) {
			context.alert(error || 'Không thể kết nối với máy chủ');
		}
	};

	React.useEffect(() => {
		if (voucher) {
			getCustomer();
			const voucherCopy = cloneDeep(voucher);
			voucherCopy.tien_thu = 0;
			voucherCopy.tien_thu2 = 0;
			calcConNo(voucherCopy);
			voucherCopy.phai_tra = 0;
			setCurrentVoucher(voucherCopy);
		}
	}, [voucher]);

	return (
		<PaymentContainer>
			<PaymentTop>
				<MenuNhanVien />
				<MenuChannel />
				<BoardPrice />
			</PaymentTop>
			<PaymentContent className="hidden-scroll-y">
				<ContentWrapper
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: '10px',
						alignItems: 'center',
					}}
				>
					<SearchCustomer voucher={voucher} handleSavePBL={handleSavePBL} />
				</ContentWrapper>
				<ContentWrapper>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Typography style={{ color: 'green', fontSize: '18px', fontWeight: '550' }}>
							Trả hàng
						</Typography>
					</Box>
				</ContentWrapper>
				<ContentWrapper>
					<PriceLine>
						<PriceText>Tổng tiền hàng trả</PriceText>
						<PriceInput value={numeralCustom(currentVoucher?.t_tien_hang).format()} />
					</PriceLine>
					<PriceLine>
						<PriceText>Tổng tiền hàng đổi</PriceText>
						<PriceInput value={numeralCustom(currentVoucher?.t_tien_doi).format()} />
					</PriceLine>
					<PriceLine>
						<PriceText>Giảm giá</PriceText>
						<PriceInput
							border
							value={numeralCustom(currentVoucher?.t_ck_nt).format()}
							onChange={(e) =>
								setCurrentVoucher({
									...currentVoucher,
									t_ck: numeralCustom(e.target.value).value(),
									t_ck_nt: numeralCustom(e.target.value).value(),
								})
							}
							onBlur={() => handleChietKhauChange(currentVoucher.t_ck_nt)}
						/>
					</PriceLine>
					<PriceLine>
						<PriceText active>Cần trả khách</PriceText>
						<PriceInput active value={numeralCustom(currentVoucher?.t_tt).format()} />
					</PriceLine>
				</ContentWrapper>
			</PaymentContent>
			<PaymentBottom>
				<ButtonPrint onClick={() => print('2')}>IN</ButtonPrint>
				<ButtonCheckout
					disabled={currentVoucher?.details.length === 0}
					onClick={confirm}
					bgColor={theme.palette.primary.main}
				>
					TRẢ HÀNG
				</ButtonCheckout>
			</PaymentBottom>
		</PaymentContainer>
	);
}

export default ReturnedPayment;
