import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldInput from '../../../components/Dialog/FieldInput';
import FieldSelect from '../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../components/Dialog/FieldSelectApi';
import { progressData } from '../../project.data';

export default function InfoTab({ hookForm }) {
	const {
		register,
		formState: { errors },
		control,
		watch,
	} = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<FieldInput
					title='Mã vụ việc'
					placeholder='Nhập mã vụ việc'
					inputProps={{
						...register('ma_dt', {
							required: 'Vui lòng nhập mã vụ việc',
						}),
					}}
					check
					errorMsg={errors?.ma_dt?.message}
				/>
			</Grid>

			<Grid item xs={12}>
				<FieldInput
					title='Tên vụ việc, dự án'
					placeholder='Nhập tên vụ việc, dự án'
					inputProps={{
						...register('ten_dt', {
							required: 'Vui lòng nhập tên vụ việc, dự án',
						}),
					}}
					check
					errorMsg={errors?.ten_dt?.message}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					name='phu_trach'
					control={control}
					rules={{ required: 'Vui lòng chọn người phụ trách' }}
					render={({ field: { onChange, value } }) => (
						<FieldSelectApi
							title='Phụ trách'
							placeholder='Chọn người phụ trách'
							api='participant'
							valueField='email'
							labelField='email'
							onChange={(item) => onChange(item?.email)}
							value={{ value, label: value }}
							check
							errorMsg={errors?.phu_trach?.message}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					name='progress'
					control={control}
					rules={{ required: 'Vui lòng chọn tiến độ' }}
					render={({ field: { onChange, value } }) => (
						<FieldSelect
							title='Tiến độ'
							placeholder='Chọn tiến độ'
							items={progressData}
							onChange={(item) => onChange(item.value)}
							defaultValue={value}
							check
							errorMsg={errors?.progress?.message}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='visible_to'
					render={({ field: { onChange, value } }) => (
						<FieldSelect
							title='Có giá trị với'
							placeholder='Chọn giá trị'
							items={[
								{
									label: 'Mọi người',
									value: 0,
								},
								{
									label: 'Chỉ người tạo',
									value: 1,
								},
								{
									label: 'Những người sau',
									value: 2,
								},
								{
									label: 'Nhóm người dùng',
									value: 5,
								},
							]}
							onChange={(item) => onChange(item.value)}
							defaultValue={value || 0}
							disableClearable
						/>
					)}
				/>

				{watch('visible_to') === 2 && (
					<Controller
						name='visible_to_users'
						control={control}
						rules={{
							validate: (value) => value?.length > 0 || 'Vui lòng chọn người được phép xem',
						}}
						render={({ field: { value, onChange } }) => {
							const items = value?.map((item) => ({ value: item, label: item, email: item })) || [];
							const handleChange = (items) => onChange(items?.map((item) => item.email) || []);

							return (
								<FieldSelectApi
									placeholder='Nhập người được phép xem'
									api='participant'
									multi
									valueField='email'
									labelField='email'
									onChange={handleChange}
									value={items}
									check
									errorMsg={errors?.visible_to_users?.message}
								/>
							);
						}}
					/>
				)}

				{watch('visible_to') === 5 && (
					<Controller
						name='visible_to_usergroups'
						control={control}
						rules={{
							validate: (value) => value?.length > 0 || 'Vui lòng chọn nhóm người được phép xem',
						}}
						render={({ field: { value, onChange } }) => (
							<FieldSelectApi
								placeholder='Nhập nhóm người được phép xem'
								api='usergroup'
								multi
								valueField='_id'
								labelField='group_name'
								onChange={onChange}
								value={value}
								check
								errorMsg={errors?.visible_to_groups?.message}
							/>
						)}
					/>
				)}
			</Grid>

			<Grid item xs={12}>
				<Controller
					name='status'
					control={control}
					render={({ field: { onChange, value } }) => (
						<FormControlLabel
							label='Còn sử dụng'
							control={<Checkbox size='small' />}
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
}
