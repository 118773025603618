import React from 'react';
import styled from 'styled-components';
import { Box, Typography, TextField, Avatar, Tooltip } from '@material-ui/core';
import { CiSearch } from 'react-icons/ci';
import { MdAdsClick } from 'react-icons/md';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { cloneDeep } from 'lodash';
import { SaleContext } from '../SaleContainer';
import { Autocomplete } from '@material-ui/lab';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ButtonBase from '../components/button/ButtonBase';
import { BASE_URL } from '../../../../../utils/constants';

const Icon = styled(Box)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ChooseButton = styled(Box)`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: #ededed;
	border-radius: 6px;
`;

const SearchStyled = styled(Autocomplete)`
	width: 300px;
	height: 34px;
	border-radius: 6px;
	background-color: #fff;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #000;
		}
	}
`;
const ProductItem = styled(Box)`
	padding: 5px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 5px;
`;
const ProductImg = styled(Avatar)`
	width: 30px;
	height: 30px;
	border-radius: 6px;
`;
const ProductName = styled(Typography)`
	font-size: 13px;
`;
const NoOptionText = styled(Typography)`
	font-size: 13px;
	font-style: italic;
	text-align: center;
`;
const NumberOrder = styled.input`
	width: 40px;
	height: 100%;
	border: 1px solid #ccc;
	outline: none;
	border-radius: 6px;
	text-align: center;
	font-size: 13px;
	margin-left: 10px;
`;
const NoOptionComponent = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
const NoOptionButtons = styled(Box)`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
`;

function SearchProductFast({ onChooseProduct, placeholder }) {
	const context = React.useContext(DefaultContext);
	const { voucher, setVoucher, kho, ma_ban, ma_kho, ma_kh } = React.useContext(SaleContext);
	const timerRef = React.useRef();
	const searchRef = React.useRef();

	const [products, setProducts] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [searchText, setSearchText] = React.useState('');
	const [product, setProduct] = React.useState(null);
	const [number, setNumber] = React.useState(1);
	const [load, setLoad] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [page, setPage] = React.useState(1);
	const [count, setCount] = React.useState(0);

	// handle save PBL
	const handleSavePBL = async (data) => {
		let _data_for_save = cloneDeep(data);
		// context.setProgressStatus(true);
		// this.timeSave = new Date().getTime();
		if (!_data_for_save._id) {
			let condition = {
				trang_thai: '1',
				ma_ban: _data_for_save.ma_ban,
				ma_kho: _data_for_save.ma_kho,
			};
			let voucher = await context.apis.asyncGetData(context.userInfo.token, 'pbl', condition);
			if (voucher) {
				let details = voucher.details;
				let vs_merge = [_data_for_save];
				vs_merge
					.map((v) => v.details)
					.reduce((a, b) => [...a, ...b], [])
					.forEach((p) => {
						let item = details.find((d) => d.ma_vt === p.ma_vt && d.ty_le_ck === p.ty_le_ck);
						if (!item) {
							item = { ...p };
							details.push(item);
						} else {
							item.sl_xuat += p.sl_xuat;
							item.sl_order += p.sl_order;
							item.sl_gui_bep += p.sl_gui_bep;
							item.sl_gui_bartender += p.sl_gui_bartender;
							item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
							item.tien_ck_nt += item.tien_ck_nt;
							item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
						}
					});
				voucher.tien_ck_hd =
					(voucher.tien_ck_hd || 0) + vs_merge.map((v) => v.tien_ck_hd || 0).reduce((a, b) => a + b, 0);
				voucher.tien_evoucher =
					(voucher.tien_evoucher || 0) + vs_merge.map((v) => v.tien_evoucher || 0).reduce((a, b) => a + b, 0);
				voucher.evouchers = [
					...voucher.evouchers,
					...vs_merge.map((v) => v.evouchers || []).reduce((a, b) => a.concat(b), []),
				];

				voucher.t_tien_nt = details.map((d) => d.tien_hang_nt).reduce((a, b) => a + b, 0);
				voucher.t_ck_nt =
					details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
					(voucher.tien_ck_hd || 0) +
					(voucher.tien_evoucher || 0);
				voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
				_data_for_save = voucher;
			}
		}
		let rs = await context.apis.asyncPostList(context.userInfo.token, 'pbl', _data_for_save);
		// context.setProgressStatus(false);
		return rs;
	};
	// handle bill change
	const handleBillChange = async (details, _voucher) => {
		let voucher_for_save = cloneDeep(voucher);
		if (_voucher) {
			voucher_for_save = { ...voucher_for_save, ..._voucher };
		}
		if (details) {
			voucher_for_save.details = details;
		}
		try {
			// cần hàm savePBL
			let voucher_saved = await handleSavePBL(voucher_for_save);
			setVoucher(voucher_saved);
			if (voucher_saved.ma_ban !== voucher_for_save.ma_ban && kho.theo_doi_sl_ht) {
				//gui thong bao doi ban cho bep va batender
				// context.alert(
				//   context.apis.getLabel("Bạn có muốn in phiếu đổi bàn không?"),
				//   () => {
				//     //cần ham print
				//     print(
				//       "5",
				//       () => {},
				//       (error) => {
				//         context.alert(error);
				//       },
				//       false
				//     );
				//   },
				//   "blue"
				// );
			}
		} catch (e) {
			context.alert(e || context.apis.getLabel('Không thể kết nối với máy chủ'));
		}
	};
	// handle select product
	// const add = async (p, sl = 1) => {
	// 	let voucherCopy = cloneDeep(voucher);
	// 	let details = voucherCopy.details;
	// 	let item;

	// 	if (p.km_yn_for) {
	// 		item = details.find((d) => d.ma_vt === p.ma_vt && d.km_yn_for == p.km_yn_for);
	// 	} else {
	// 		if (kho && kho.theo_doi_sl_ht) {
	// 			item = details.find((d) => d.ma_vt === p.ma_vt && d.line === p.line && !d.km_yn_for);
	// 		} else {
	// 			item = details.find((d) => d.ma_vt === p.ma_vt && !d.km_yn_for);
	// 		}
	// 		if (details.length > 0 && !item) {
	// 			details.map((items) => {
	// 				if (items.ma_vt == p.ma_vt && !items.km_yn_for) {
	// 					item = items;
	// 				}
	// 			});
	// 		}
	// 	}
	// 	if (!item) {
	// 		item = { ...p, sl_xuat: 0 };
	// 		item.gia_ban_nt = item.gia_ban_le || 0;
	// 		item.order_time = new Date();
	// 		item.sl_ht = 0;
	// 		item.sl_gui_bep = 0;
	// 		item.sl_gui_bartender = 0;
	// 		item.sl_order = 0;
	// 		item.line = new Date().getTime();
	// 		item.ma_tt1 = p.ma_nvt;
	// 		item.ghi_chu = p.ma_nvt2;
	// 		details.push(item);
	// 	}
	// 	item.sl_order = item.sl_order || 0;
	// 	if (sl !== 1 && sl !== -1) {
	// 		item.sl_order = sl;
	// 	} else {
	// 		item.sl_order += sl;
	// 	}
	// 	//fix old voucher
	// 	item.sl_gui_bartender = item.sl_gui_bartender || 0;
	// 	item.sl_gui_bep = item.sl_gui_bep || 0;
	// 	//neu khong theo doi sl ht thi sl_xuat luon bang sl_order
	// 	if (kho || kho.theo_doi_sl_ht || (!item.sp_yeu_cau_pha_che && !item.sp_yeu_cau_che_bien)) {
	// 		item.sl_xuat = item.sl_order;
	// 	}
	// 	//tinh chiet khau
	// 	item.ty_le_ck = item.ty_le_ck || 0;
	// 	item.tien_ck_nt = item.tien_ck = item.tien_ck_nt || 0;
	// 	//
	// 	if (item.sl_xuat >= item.sl_order) {
	// 		if (!item.finish_time || sl) item.finish_time = new Date();
	// 	} else {
	// 		item.finish_time = null;
	// 	}
	// 	//
	// 	if (!item.sp_yeu_cau_che_bien && !item.sp_yeu_cau_pha_che) {
	// 		item.finish_time = item.order_time;
	// 	}
	// 	if (item.sl_order > 0) {
	// 		item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
	// 		if (item.ty_le_ck) {
	// 			item.tien_ck_nt = Math.round((item.tien_hang_nt * item.ty_le_ck) / 100);
	// 		}
	// 		item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
	// 	} else {
	// 		if (kho && kho.theo_doi_sl_ht) {
	// 			details = details.filter((vt) => !(vt.ma_vt === item.ma_vt && vt.line === item.line));
	// 		} else {
	// 			details = details.filter((vt) => vt.ma_vt !== p.ma_vt);
	// 		}
	// 	}
	// 	voucherCopy.details = details;
	// 	voucherCopy.exfields.dmckhd = [];
	// 	voucherCopy.t_tien_nt = details.map((d) => d.tien_hang_nt).reduce((a, b) => a + b, 0);
	// 	voucherCopy.t_ck_nt =
	// 		details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
	// 		(voucherCopy.tien_ck_hd || 0) +
	// 		(voucherCopy.tien_evoucher || 0);
	// 	voucherCopy.t_tt_nt = voucherCopy.t_tien_nt - voucherCopy.t_ck_nt;

	// 	await setVoucher(voucherCopy);
	// 	handleBillChange(details);
	// };

	const handleSelectProduct = async (p) => {
		setProduct(p);
	};
	const handleChooseProduct = async () => {
		if (!product) {
			return;
		}
		onChooseProduct(product, number);
		setProduct(null);
		setNumber(1);
		setLoad(load + 1);
		setSearch('');
		setSearchText('');
		searchRef.current.focus();
	};
	// handle number change
	const handleNumberChange = (e) => {
		const value = Number(e.target.value);
		if (isNaN(value)) {
			return;
		}
		setNumber(Number(value));
	};

	// get customer
	const getProducts = async (search, page, oldProducts) => {
		if (!search) {
			setProducts([]);
			return;
		}
		setLoading(true);
		let condition = { status: true, $or: [] };
		//

		condition.$or.push({ ma_vt: { $regex: search, $options: 'i' } });
		condition.$or.push({ ten_vt: { $regex: search.split(' ').join('.*'), $options: 'i' } });

		const resp = await context.apis.asyncGetList(context.userInfo.token, 'dmvt', {
			condition: condition,
			limit: 20,
			page: page,
			buildUrl: (url) => {
				if (ma_ban) {
					url = `${url}&ma_ban=${ma_ban}`;
				}
				if (ma_kho) {
					url = `${url}&ma_kho=${ma_kho}`;
				}
				if (ma_kh) {
					url = `${url}&ma_kh=${ma_kh}`;
				}
				return url;
			},
		});
		const { rows_number } = await context.apis.asyncGetList(context.userInfo.token, 'dmvt', {
			condition: condition,
			count: 1,
			buildUrl: (url) => {
				if (ma_ban) {
					url = `${url}&ma_ban=${ma_ban}`;
				}
				if (ma_kho) {
					url = `${url}&ma_kho=${ma_kho}`;
				}
				if (ma_kh) {
					url = `${url}&ma_kh=${ma_kh}`;
				}
				return url;
			},
		});
		setCount(rows_number);
		if (resp) {
			setProducts([...oldProducts, ...resp]);
		} else {
			setProducts([]);
		}
		if (oldProducts.length === 0) {
			setPage(1);
		}
		setLoading(false);
	};
	// handle clear
	const handleClear = () => {
		setSearch('');
		setSearchText('');
		setProducts([]);
		setProduct(null);
		setLoad(load + 1);
	};
	// handle blur
	const handleBlur = () => {
		if (products.length > 0 && !product) {
			setSearchText('');
			setProducts([]);
			setLoad(load + 1);
		}
	};
	// handle list box scroll
	const handleListBoxScroll = (e) => {
		if (products.length === count || loading === true) {
			e.preventDefault();
			return;
		}
		const { scrollHeight, scrollTop } = e.target;
		const height = e.target.clientHeight;
		const subtract = scrollHeight - scrollTop - height;
		if (subtract <= 10) {
			setPage(page + 1);
		}
	};

	React.useEffect(() => {
		if (search && page > 1) {
			getProducts(search, page, products);
		}
	}, [page]);
	// set search khi search text thay doi
	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);
	// get customer khi search thay doi
	React.useEffect(() => {
		// getProducts();
		if (search) {
			getProducts(search, 1, []);
		} else {
			setProducts([]);
		}
	}, [search]);
	React.useEffect(() => {
		searchRef.current?.focus();
	}, []);

	return (
		<>
			<SearchStyled
				key={load}
				noOptionsText={
					<NoOptionComponent>
						<NoOptionText>Không tìm thấy sản phẩm</NoOptionText>
						<NoOptionButtons>
							<ButtonBase variant="outline" onClick={handleClear}>
								Hủy bỏ
							</ButtonBase>
							<ButtonBase variant="contained">Thêm '{searchText}'</ButtonBase>
						</NoOptionButtons>
					</NoOptionComponent>
				}
				ListboxProps={{ index: 1, onScroll: handleListBoxScroll }}
				onBlur={handleBlur}
				onChange={(event, newValue) => handleSelectProduct(newValue)}
				filterOptions={(options) => options}
				forcePopupIcon={false}
				clearOnBlur={false}
				closeIcon={null}
				options={products}
				getOptionLabel={(option) => `${option.ten_vt} ${option.ma_vt}`}
				open={!!searchText}
				renderOption={(option) => {
					return (
						<ProductItem>
							<ProductImg src={`${BASE_URL}${option.picture}?access_token=${context.userInfo.token}`} />
							<ProductName>
								{option.ten_vt} - ({option.ma_vt})
							</ProductName>
						</ProductItem>
					);
				}}
				renderInput={(params) => (
					<TextField
						inputProps={{ tabIndex: 1 }}
						inputRef={searchRef}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={placeholder || 'Tìm tên, mã sản phẩm'}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleChooseProduct();
							}
						}}
						{...params}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							startAdornment: (
								<>
									{loading ? (
										<Icon className="round-loading">
											<AiOutlineLoading3Quarters fontSize="14px" />
										</Icon>
									) : (
										<Icon>
											<CiSearch fontSize="20px" />
										</Icon>
									)}
								</>
							),
							endAdornment:
								product && number > 0 ? (
									<Tooltip arrow placement="top" title="chọn">
										<ChooseButton onClick={handleChooseProduct}>
											<MdAdsClick fontSize="18px" />
										</ChooseButton>
									</Tooltip>
								) : null,
						}}
						variant="outlined"
					/>
				)}
			/>
			{product && (
				<NumberOrder
					tabIndex={0}
					value={number}
					onChange={handleNumberChange}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							handleChooseProduct();
						}
					}}
				/>
			)}
		</>
	);
}

export default SearchProductFast;
