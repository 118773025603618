import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";

const FilterRow = styled(Box)`
  width: 100%;
  height: auto;
  min-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
const IconCollapse = styled(Box)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const FilterText = styled(Typography)`
  font-size: 13px;
`;
const SubFilter = styled(Box)`
  width: 100%;
  padding-left: 48px;
`;

function FilterProduct({ group, groups, checkedItems, handleChecked }) {
  const [collapse, setCollapse] = React.useState(false);

  const childrenGroups = React.useMemo(() => {
    return groups.filter((g) => g.nh_me === group._id);
  }, [groups]);
  React.useEffect(() => {
    if (checkedItems.length > 0) {
      childrenGroups.forEach((g) => {
        if (checkedItems.includes(g._id)) {
          setCollapse(true);
          return;
        }
      });
    }
  }, [childrenGroups]);

  return (
    <>
      <FilterRow>
        {childrenGroups.length > 0 ? (
          <IconCollapse onClick={() => setCollapse(!collapse)}>
            {collapse ? (
              <VscChevronDown fontSize="14px" />
            ) : (
              <VscChevronRight fontSize="14px" />
            )}
          </IconCollapse>
        ) : (
          <IconCollapse />
        )}
        <input
          checked={checkedItems.includes(group._id)}
          type="checkbox"
          value={group._id}
          onChange={handleChecked}
        />
        <FilterText>{group.ten_nvt}</FilterText>
      </FilterRow>
      {childrenGroups?.length > 0 && collapse && (
        <SubFilter>
          {childrenGroups.length > 0 &&
            childrenGroups.map((g) => (
              <FilterRow key={g._id}>
                <input
                  checked={checkedItems.includes(g._id)}
                  type="checkbox"
                  value={g._id}
                  onChange={handleChecked}
                />
                <FilterText>{g.ten_nvt}</FilterText>
              </FilterRow>
            ))}
        </SubFilter>
      )}
    </>
  );
}

export default FilterProduct;
