import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useCore } from '../../../../../../../hooks/useCore';
import { convertBytesToSize } from '../../../../../../../utils/helpers';
import Dialog from '../../../../Dialog/Dialog';
import DownloadTemplateDialog from './DownloadTemplateDialog';
import RenderImportError, { isImportError } from './RenderImportError';
import excelIcon from './excel-icon.png';

export default function ImportDialog({ open, handleClose, getData, api, code, params }) {
	const core = useCore();

	const [loading, setLoading] = React.useState(false);
	const [update, setUpdate] = React.useState(true);
	const [file, setFile] = React.useState();

	const inputRef = React.useRef(null);

	React.useEffect(() => {
		setFile(); // reset files
	}, [open]);

	const classes = useStyles();

	const handleInputFile = () => {
		inputRef.current.click();
	};

	const onFileChange = (e) => {
		const file = e.target.files[0];

		setFile(file);
	};

	const handleImport = async () => {
		if (!file) return core.contextAlert('Vui lòng chọn file excel!');

		setLoading(true);
		try {
			// create form data
			const formData = new FormData();
			formData.append('xlsx', file);

			// call api
			const { data } = await core.apiCall({
				method: 'POST',
				endpoint: `${api}/import/excel`,
				data: formData,
				params: {
					update,
					...params,
				},
			});

			// show alert
			core.contextAlert(`Đã nhập thành công ${data?.rows_imported} dòng!`);

			// close dialog
			handleClose();
		} catch (error) {
			if (isImportError(error)) core.contextAlert(<RenderImportError error={error} />);
			else core.contextAlert(error);
		}
		// get data
		getData();

		setLoading(false);
	};

	const [openDialog, setOpenDialog] = React.useState('');
	const handleDownloadTemplate = () => setOpenDialog('downloadTemplate');

	return (
		<Dialog
			open={open}
			loading={loading}
			handleClose={handleClose}
			title="Nhập dữ liệu từ file excel"
			actions={[
				<button className="btn btn-fill" onClick={handleImport} disabled={!file || loading}>
					<span>Nhập dữ liệu</span>
				</button>,
				<button className="btn btn-fill" onClick={handleDownloadTemplate} disabled={loading}>
					<span>Tải file mẫu</span>
				</button>,
				<button className="btn btn-grey" onClick={handleClose} disabled={loading}>
					<span>Hủy</span>
				</button>,
			]}
		>
			<DownloadTemplateDialog
				code={code || api} // default code is api (if not provided)
				open={openDialog === 'downloadTemplate'}
				handleClose={() => setOpenDialog('')}
			/>

			<div className={classes.fileContainer}>
				<div className={classes.file}>
					{file ? (
						<>
							<img src={excelIcon} alt={file.name} className={classes.fileIcon} />
							<p className={classes.fileName}>{file.name}</p>
							<p className={classes.fileSize}>{convertBytesToSize(file.size)}</p>
						</>
					) : (
						<p className={classes.empty}>Chưa chọn file</p>
					)}
				</div>

				<button className="btn btn-fill" type="button" onClick={handleInputFile}>
					{file ? (
						<>
							<span>Chọn file khác</span>
						</>
					) : (
						<>
							<span>Thêm file</span>
						</>
					)}
				</button>

				<FormControlLabel
					label="Ghi đè dữ liệu"
					control={<Checkbox checked={update} onChange={(e) => setUpdate(e.target.checked)} />}
				/>

				<input type="file" accept=".xlsx, .xls, .csv" onChange={onFileChange} hidden ref={inputRef} />
			</div>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	fileContainer: {
		margin: 24,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 16,
	},
	file: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 8,
	},
	fileIcon: {
		width: 100,
		height: 100,
		objectFit: 'contain',
	},
	fileName: {
		fontSize: 14,
		fontWeight: 600,
	},
	fileSize: {
		fontSize: 12,
	},
	empty: {
		fontSize: 14,
		fontWeight: 600,
		color: '#ccc',
	},
}));
