import React from 'react';
import ListBase from '../ListBase/ListBase';
import { formatDateDisplay, numeralCustom } from '../../../utils/helpers';
import withContainer from '../withContainer';
import PromotionFilter from './PromotionFilter';
import PromotionForm from './PromotionForm';

const columns = [
	{
		name: 'Chi nhánh',
		selector: (row) => row.ten_kho,
		sortable: true,
		minWidth: '120px',
	},
	{
		name: 'Sản phẩm',
		selector: (row) => row.ten_vt,
		sortable: true,
		minWidth: '170px',
	},
	{
		name: 'Nhóm sản phẩm',
		selector: (row) => row.ten_vt,
		sortable: true,
		minWidth: '170px',
	},
	{
		name: 'Khách hàng',
		selector: (row) => row.ten_kh,
		sortable: true,
		center: true,
		minWidth: '115px',
	},
	{
		name: 'Nhóm khách hàng',
		selector: (row) => row.nh_kh,
		sortable: true,
		center: true,
		minWidth: '150px',
	},
	{
		name: 'Hiệu lực từ',
		selector: (row) => row.hieu_luc_tu,
		sortable: true,
		format: (row) => formatDateDisplay(row.hieu_luc_tu),
		minWidth: '150px',
	},
	{
		name: 'Hiệu lực đến',
		selector: (row) => row.hieu_luc_den,
		sortable: true,
		format: (row) => formatDateDisplay(row.hieu_luc_den),
		minWidth: '150px',
	},
];

function ListPromotion(props) {
	return (
		<ListBase
			api="dmkhuyenmai"
			title="Danh sách khuyến mãi"
			columns={columns}
			FilterComponent={PromotionFilter}
			FormDialog={PromotionForm}
		/>
	);
}

export default withContainer(ListPromotion);
