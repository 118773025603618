import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DefaultContex from 'flexbiz-core/components/auth/Context';

import { Box, Paper, Typography, Avatar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CiFilter } from 'react-icons/ci';
import { SaleContext } from '../../SaleContainer';
import { formatCurrencyDisplay } from '../../../../../../utils/helpers';
import SearchCustomer from './SearchCustomer';
import DrawerFilterProduct from './DrawerFilerProduct';
import { BASE_URL } from '../../../../../../utils/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { unicode2Ascii } from 'flexbiz-core/utils';
import { Pagination, Skeleton } from '@material-ui/lab';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import MenuChannel from '../../components/menu/MenuChannel';
import BoardPrice from './BoardPrice';

const ProductsSaleStyled = styled(Paper)`
	width: 100%;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const ProductSaleTop = styled(Box)`
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
`;

const ProductActions = styled(Box)`
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: auto;
`;
const ActionFilter = styled(Box)`
	position: relative;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid #ccc;
	transition: all linear 0.1s;
	&:hover {
		background-color: #ededed;
	}
`;
const FilterDot = styled(Box)`
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	background-color: ${(props) => props.bgColor};
	width: 8px;
	height: 8px;
	border-radius: 50%;
`;
const ProductList = styled(Box)`
	width: 100%;
	height: calc(100vh - 50px - 20px - 42px - 20px - 42px - 20px - 20px - 34px - 34px - 34px - 10px);
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
`;
const ProductWrapper = styled(Box)`
	width: 25%;
	height: auto;
	cursor: pointer;
	border-radius: 6px;
	transition: all linear 0.1s;
	padding: 5px;
	&:hover {
		background-color: #ededed;
	}
`;
const Product = styled(Box)`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
const ProductImage = styled(Box)`
	position: relative;
	width: 100%;
	padding-top: 100%;
`;
const Image = styled(Avatar)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	object-fit: cover;
`;
const ProductName = styled(Typography)`
	font-size: 12px;
	font-weight: 400;
	text-align: center;
`;
const ProductPrice = styled(Typography)`
	text-align: center;
	font-size: 12px;
	border-radius: 4px 4px 0 0;
	padding: 1px;
	color: ${(props) => props.textColor};
`;
const ProductSaleBottom = styled(Box)`
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ProductTypeItem = styled(Paper)`
	box-sizing: border-box;
	flex-shrink: 0;
	width: fit-content;
	padding: 5px 12px;
	border-radius: 20px;
	height: 24px;
	background-color: ${(props) => (props.active ? props.bgColor : '#fff')};
	color: ${(props) => (props.active ? '#fff' : '#000')};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all linear 0.1s;
	cursor: pointer;
	font-size: 13px;
	&:hover {
		background-color: #ededed;
	}
`;

const itemsPerPage = 12;
const productTypes = [
	{ id: 0, name: 'Tất cả', q: {} },
	{ id: 1, name: 'Hàng hóa', q: { is_service: { $ne: true } } },
	{ id: 2, name: 'Dịch vụ', q: { is_service: true } },
	{
		id: 3,
		name: 'Gói dịch vụ',
		q: { is_package: true },
	},
	{ id: 4, name: 'Thẻ', q: { is_card: true } },
	{ id: 5, name: 'Combo', q: { is_combo: true } },
];

function ProductsSale({ voucher, handleSelectProduct, handleSavePBL }) {
	const theme = useTheme();
	const context = useContext(DefaultContex);
	const { ma_ban, ma_kho, search } = useContext(SaleContext);

	const [products, setProducts] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPage, setTotalPage] = useState();
	const [openFilter, setOpenFilter] = useState(false);
	const [maNvt, setMaNvt] = useState('');
	const [type, setType] = useState(productTypes[0]);
	const [loading, setLoading] = useState(false);

	// handle open filter
	const handleOpenFilter = () => {
		setOpenFilter(true);
	};
	// handle close filter
	const handleCloseFilter = () => {
		setOpenFilter(false);
	};

	// load product
	const loadProducts = async (ma_nvt, ma_ban, ma_kho, ma_kh, type) => {
		// context.setProgressStatus(true);
		setLoading(true);
		try {
			let condition = { status: true, $or: [] };
			if (ma_nvt) {
				if (typeof ma_nvt === 'string') {
					condition.ma_nvt = ma_nvt;
				} else if (Array.isArray(ma_nvt)) {
					condition.ma_nvt = { $in: ma_nvt };
				}
			}
			if (type) {
				condition = { ...condition, ...type.q };
			}
			if (search) {
				condition.$or.push({ ma_vt: { $regex: search, $options: 'i' } });
				condition.$or.push({ ten_vt: { $regex: search.split(' ').join('.*'), $options: 'i' } });
			}
			let product = await context.apis.asyncGetList(context.userInfo.token, 'dmvt', {
				condition: condition,
				limit: itemsPerPage,
				page: page,
				buildUrl: (url) => {
					if (ma_ban) {
						url = `${url}&ma_ban=${ma_ban}`;
					}
					if (ma_kho) {
						url = `${url}&ma_kho=${ma_kho}`;
					}
					if (ma_kh) {
						url = `${url}&ma_kh=${ma_kh}`;
					}
					return url;
				},
			});
			product.map((i) => {
				if (i.discount) {
					i.discount.map((row) => {
						if (row.exfields) {
							if (row.exfields.tong_hd) {
								if (row.exfields.tong_hd == true) {
									i.ty_le_ck = 0;
									i.tien_ck = 0;
								}
							}
						}
					});
				}
			});
			setProducts(product);
			if (!product || product.length === 0) {
				setPage(0);
			}
			// context.setProgressStatus(false);
			setLoading(false);
		} catch (e) {
			context.alert(e.message);
		}
	};
	useEffect(() => {
		loadProducts(maNvt, ma_ban, ma_kho, voucher?.ma_kh, type);
	}, [maNvt, ma_ban, ma_kho, voucher?.ma_kh, page, type, search]);
	useEffect(() => {
		const getCount = async () => {
			let condition = { status: true, $or: [] };
			if (maNvt) {
				if (typeof maNvt === 'string') {
					condition.ma_nvt = maNvt;
				} else if (Array.isArray(maNvt)) {
					condition.ma_nvt = { $in: maNvt };
				}
			}
			if (type) {
				condition = { ...condition, ...type.q };
			}
			if (search) {
				if (search) {
					condition.$or.push({ ma_vt: { $regex: search, $options: 'i' } });
					condition.$or.push({ ten_vt: { $regex: search, $options: 'i' } });
					let $search = await unicode2Ascii(search);
					condition.$or.push({ $text: { $search: search + ' ' + $search } });
				}
			}
			const resp = await context.apis.asyncGetList(context.userInfo.token, 'dmvt', {
				count: 1,
				condition,
			});
			const totalItem = resp.rows_number;
			let totalPage = Math.floor(totalItem / itemsPerPage);
			if (totalItem % itemsPerPage > 0) {
				totalPage += 1;
			}
			setTotalPage(totalPage);
			setPage(1);
		};
		getCount();
	}, [context.userInfo.token, maNvt, type, search]);

	return (
		<>
			<DrawerFilterProduct
				anchor="left"
				open={openFilter}
				handleClose={handleCloseFilter}
				setMaNvt={setMaNvt}
				maNvt={maNvt}
			/>
			<ProductsSaleStyled>
				<ProductSaleTop>
					<SearchCustomer voucher={voucher} handleSavePBL={handleSavePBL} />
					<BoardPrice />
					<MenuChannel />
					<ProductActions>
						<ActionFilter onClick={handleOpenFilter}>
							<CiFilter fontSize="18px" />
							{maNvt && maNvt.length > 0 && <FilterDot bgColor={theme.palette.primary.main} />}
						</ActionFilter>
					</ProductActions>
				</ProductSaleTop>
				<Swiper
					style={{ width: '100%', height: '34px', userSelect: 'none' }}
					slidesPerView={'auto'}
					spaceBetween={10}
					grabCursor={true}
					freeMode={true}
					modules={[FreeMode]}
					className="mySwiper"
				>
					{productTypes.map((item) => (
						<SwiperSlide
							onClick={() => setType(item)}
							style={{
								width: 'fit-content',
								pointerEvents: item.id === type?.id ? 'none' : '',
							}}
							key={item.id}
						>
							<ProductTypeItem active={item.id === type?.id} bgColor={theme.palette.primary.main}>
								{item.name}
							</ProductTypeItem>
						</SwiperSlide>
					))}
				</Swiper>
				<ProductList className="custom-scroll-y">
					{loading ? (
						<ProductList>
							{new Array(12).fill(0).map((item, index) => (
								<Box sx={{ width: '25%', paddingTop: '25%', position: 'relative' }}>
									<Box
										sx={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											padding: '5px',
										}}
									>
										<Skeleton
											key={index}
											style={{ borderRadius: '6px' }}
											variant="rect"
											width="100%"
											height="100%"
											animation="wave"
										/>
									</Box>
								</Box>
							))}
						</ProductList>
					) : (
						<>
							{products.length === 0 ? (
								<Typography
									style={{
										width: '100%',
										fontSize: '13px',
										fontStyle: 'italic',
										textAlign: 'center',
										padding: '10px',
									}}
								>
									Không có sản phẩm
								</Typography>
							) : (
								products.map((product) => (
									<ProductWrapper key={product._id} onClick={() => handleSelectProduct(product)}>
										<Product>
											<ProductImage>
												<Image
													src={`${BASE_URL}${product.picture}?access_token=${context.userInfo.token}`}
												/>
											</ProductImage>
											<ProductPrice textColor={theme.palette.primary.main}>
												{formatCurrencyDisplay(product.gia_ban_le)}
											</ProductPrice>
											<ProductName>{product.ten_vt}</ProductName>
										</Product>
									</ProductWrapper>
								))
							)}
						</>
					)}
				</ProductList>
				<ProductSaleBottom>
					<Pagination
						showFirstButton
						showLastButton
						size="small"
						count={totalPage}
						page={page}
						onChange={(e, value) => setPage(value)}
					/>
				</ProductSaleBottom>
			</ProductsSaleStyled>
		</>
	);
}

export default ProductsSale;
