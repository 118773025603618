import React from 'react';
import { toast } from 'react-hot-toast';
import { GrAddCircle } from 'react-icons/gr';
import { useCore } from '../../../hooks';
import { capitalize } from '../../../utils/helpers';
import FormGoodsGroupDialog from '../ListGoodsGroup/Dialog/FormGoodsGroupDialog';
import '../components/filter/Filter.css';
import FilterListCheckbox from '../components/filter/FilterListCheckbox';
import FilterListRatioDnD from '../components/filter/FilterListRatioDnD';
import FilterSearch from '../components/filter/FilterSearch';
import { goodsType } from './data';
import { findTreeItem, getTreeGroups } from './goods.util';

const searchFields = ['ma_vt', 'ten_vt'];

export default function GoodsFilter({ onFilter }) {
	const [filter, setFilter] = React.useState({
		search: '',
		types: [],
	});

	React.useEffect(() => {
		// defind query
		let query = {
			$and: [],
		};

		// search
		if (filter.search)
			query.$and.push({
				$or: searchFields.map((field) => ({ [field]: { $regex: filter.search, $options: 'i' } })),
			});

		// filter by type
		if (filter.types.length) query.$and.push({ $or: filter.types.map((type) => goodsType[type].filter) });

		// filter by group
		if (filter.group) query.$and.push({ ma_nvt: filter.group });

		// call onFilter
		onFilter(query);
	}, [filter]);

	const handleFilter = (field, value) =>
		setFilter({
			...filter,
			[field]: value,
		});

	const typeOfGoods = Object.keys(goodsType).map((key) => ({
		...goodsType[key],
		label: capitalize(goodsType[key].label),
	}));

	const [groups, setGroups] = React.useState([]);

	const handleFilterGroup = (value) => {
		const group = findTreeItem(groups, value);

		if (!group) handleFilter('group', null); // clear filter
		else handleFilter('group', { $in: group.filterValues });
	};

	const core = useCore();
	const getGroups = async () => {
		const data = await core.asyncGetList(core.token, 'dmnvt', {
			limit: 500, // Get all
		});

		setGroups(getTreeGroups(data)); // Get tree groups
	};
	React.useEffect(() => {
		getGroups();
	}, []);

	const [modal, setModal] = React.useState({ name: '', data: {} });
	const openModal = (name, data) => setModal({ name, data });
	const closeModal = () => setModal({ name: '', data: {} });

	const handleSubmitGroup = async (data) => {
		try {
			await core.asyncPostList(core.token, 'dmnvt', data);
			getGroups();
		} catch (error) {
			toast.error(error);
		}
	};

	return (
		<div className="product__filter">
			{/* Search box */}
			<FilterSearch onSearch={(value) => handleFilter('search', value)} />
			{/* End of Search box */}

			{/* Filter by type */}
			<FilterListCheckbox
				title="Loại hàng"
				items={typeOfGoods}
				onChange={(value) => handleFilter('types', value)}
				collapsable
			/>
			{/* End of Filter by type */}

			{/* Filter by group */}
			<FormGoodsGroupDialog
				open={modal.name === 'group'}
				onClose={closeModal}
				data={modal.data}
				onSubmit={handleSubmitGroup}
			/>
			<FilterListRatioDnD
				title="Nhóm sản phẩm"
				items={groups}
				actions={[<GrAddCircle key="add" onClick={() => openModal('group', {})} />]}
				onChange={handleFilterGroup}
				collapsable
				editable
				onEdit={(item) => {
					console.log('Edit', item);
					openModal('group', item);
				}}
				searchable
			/>
			{/* End of Filter by group */}
		</div>
	);
}
