import dayjs from 'dayjs';
import React, { forwardRef } from 'react';
import FieldBox from './FieldBox';

const FieldInput = (
	{
		id,
		title,
		tooltip,
		check,
		type = 'text',
		value,
		onChange,
		defaultValue,
		placeholder,
		textAlign = 'left',
		icon,
		actions,
		inputProps,
		classCus,
		errorMsg,
		fieldBoxProps,
		readOnly,
	},
	ref
) => {
	const defaultClass = 'field_input_content_input';

	if (type === 'date' && defaultValue) defaultValue = dayjs(defaultValue).format('YYYY-MM-DD');

	return (
		<FieldBox
			title={title}
			tooltip={tooltip}
			check={check}
			errorMsg={errorMsg}
			{...fieldBoxProps}
			// eslint-disable-next-line react/no-children-prop
			children={
				<div className="field_input_container">
					{icon && icon}
					<input
						id={id}
						ref={ref}
						style={{ textAlign }}
						value={value}
						onChange={onChange}
						className={defaultClass + ' ' + classCus}
						type={type}
						placeholder={placeholder}
						min={type === 'number' ? 0 : undefined}
						{...inputProps}
						defaultValue={defaultValue}
						readonly={readOnly}
					/>
					{actions && actions}
				</div>
			}
		/>
	);
};

export default forwardRef(FieldInput);
