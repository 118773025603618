import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import KhoItem from './KhoItem';

const TonKhoWrapper = styled(Box)`
	width: 30vw;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	border-radius: 6px 6px 0 0;
`;
const Top = styled(Typography)`
	width: 100%;
	background-color: ${(props) => props.bgColor};
	color: #fff;
	text-align: right;
	padding: 10px;
	border-radius: 6px 6px 0 0;
`;

const TextDisplay = styled(Typography)`
	font-size: 13px;
	font-weight: ${(props) => (props.bold ? 550 : 400)};
`;

function TonKhoDialog({ product }) {
	const theme = useTheme();
	const context = React.useContext(DefaultContext);
	const [khos, setKhos] = React.useState([]);

	// lay tat ca cac kho
	React.useEffect(() => {
		const getKho = async () => {
			const resp = await context.apis.asyncGetList(context.userInfo.token, 'dmkho', {});
			setKhos(resp);
		};
		getKho();
	}, [context]);

	return (
		<TonKhoWrapper>
			<Top bgColor={theme.palette.primary.main}>
				<TextDisplay>Tồn</TextDisplay>
			</Top>
			{/* <Row>
        <TextDisplay bold>Tổng cộng</TextDisplay>
        <TextDisplay bold>{tonKho}</TextDisplay>
      </Row> */}
			{khos && khos.length > 0 && khos.map((kho) => <KhoItem key={kho._id} kho={kho} product={product} />)}
		</TonKhoWrapper>
	);
}

export default TonKhoDialog;
