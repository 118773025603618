import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const data = `
<table style="width: 100%; padding: 20px;">
<tbody>
    <tr>
        <td colspan="1">
            <img style="width: 100%; height: 100px;"
                src="https://api.goodapp.vn//getfile/products/60936baeac969b4078487d25_1657178253635_splash.png?access_token=flex.public.token"
                alt="">
        </td>
        <td style="font-family: monospace, cursive; font-size: 12pt; font-weight: 550; text-align: center; line-break: normal; word-break: break-all;"
            colspan="3">
            The Golf House
        </td>
    </tr>
    <tr>
        <td colspan="4"
            style="padding-top: 25px; font-family: monospace, cursive; font-size: 16pt; font-weight: 550; text-align: center;">
            ĐƠN
            ĐẶT HÀNG</td>
    </tr>
    <tr>
        <td colspan="4" style=" font-family: monospace, cursive; font-size: 9pt; text-align: center;">
            Số hóa đơn: {{so_ct}}
        </td>
    </tr>
    <tr>
        <td colspan="4" style=" font-family: monospace, cursive; font-size: 9pt; text-align: center;">
            Ngày: {{ngay_ct|date:'dd/MM/yyyy'}}
        </td>
    </tr>
    <tr>
        <td colspan="3"
            style="padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Khách hàng: {{ten_kh}}
        </td>
        <td colspan="3" style=" font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            SĐT: {{dien_thoai_kh}}
        </td>
    </tr>
    <tr>
        <td colspan="4"
            style="padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; font-weight: 550;">
            Ghi chú: {{dien_giai}}
        </td>
    </tr>
    <tr>
        <td colspan="4" style="padding-top: 10px; font-family: monospace, cursive; font-size: 9pt;">
            Thanh toán: {{ten_pt_thanh_toan}}
        </td>
    </tr>
    <tr>
        <td colspan="4" style="padding-top: 10px; font-family: monospace, cursive; font-size: 9pt;">
            NVBH: {{ten_nv}}
        </td>
    </tr>
    <tr>
        <td colspan="4">
            <hr>
        </td>
    </tr>
    <tr>
        <td
            style="width: 25%; font-family: monospace, cursive; font-size: 9pt; font-weight: 550; text-align: left;">
            Số lượng
        </td>
        <td
            style="width: 25%; font-family: monospace, cursive; font-size: 9pt; font-weight: 550; text-align: center;">
            Đơn giá</td>
        <td
            style="width: 25%; font-family: monospace, cursive; font-size: 9pt; font-weight: 550; text-align: center;">
            Chiết khấu</td>
        <td
            style="width: 25%; font-family: monospace, cursive; font-size: 9pt; font-weight: 550; text-align: right;">
            Thành tiền
        </td>
    </tr>
    <tr>
        <td colspan="4">
            <hr>
        </td>
    </tr>
    <tr>
        <td style="width: 25%; border-bottom: 1px dashed #000; padding: 10px 0; font-family: monospace, cursive; font-size: 9pt; text-align: left;"
            colspan="4">
            <p style="margin: 0;">{{tb:details.ten_vt}} - ({{tb:details.ma_dvt}}) - {{tb:details.ma_vt}} <span
                    style="font-weight: 550;">{{tb:details.ghi_chu}}</span> </p>
            <p style="margin: 0; margin-top: 5px; display: flex; justify-content: space-between;">
                <span style="display: inline; width: 25%; text-align: left;">{{tb:details.sl_xuat}}</span>
                <span style="display: inline; width: 25%; text-align: center;">{{tb:details.gia_ban|
                    number:0}}</span>
                <span style="display: inline; width: 25%; text-align: center;">{{tb:details.tien_ck_nt|
                    number:0}}</span>
                <span style="display: inline; width: 25%; text-align: right;">{{tb:details.tien_nt|
                    number:0}}</span>
            </p>
        </td>
    </tr>
    <tr>
        <td colspan="4" style="padding-top: 20px;"></td>
    </tr>
    <tr>
        <td colspan="3"
            style="font-weight: 550; padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Tổng cộng
        </td>
        <td colspan="1"
            style="font-weight: 550; font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            {{t_tien_nt| number:0}}
        </td>
    </tr>
    <tr>
        <td colspan="3"
            style="font-weight: 550; padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Chiết khấu hóa đơn
        </td>
        <td colspan="1"
            style="font-weight: 550;  font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            {{tien_ck_hd| number:0}}
        </td>
    </tr>
    <tr>
        <td colspan="3"
            style="font-weight: 550; padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Tổng tiền chiết khấu
        </td>
        <td colspan="1"
            style="font-weight: 550;  font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            {{t_ck_nt| number:0}}
        </td>
    </tr>
    <tr>
        <td colspan="3"
            style="font-weight: 550; padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Tổng thanh toán
        </td>
        <td colspan="1"
            style="font-weight: 550;  font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            {{t_tt_nt| number:0}}
        </td>
    </tr>
    <tr>
        <td colspan="3"
            style="font-weight: 550; padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Tiền thu
        </td>
        <td colspan="1"
            style="font-weight: 550;  font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            {{tien_thu| number:0}}
        </td>
    </tr>
    <tr>
        <td colspan="3"
            style="font-weight: 550; padding-top: 10px; font-family: monospace, cursive; font-size: 9pt; text-align: left;">
            Phải trả
        </td>
        <td colspan="1"
            style="font-weight: 550;  font-family: monospace, cursive; font-size: 9pt; text-align: right;">
            {{phai_tra| number:0}}
        </td>
    </tr>
    <tr>
        <td colspan="4"
            style="padding-top: 20px; font-weight: 400; font-style: italic; font-family: monospace, cursive; font-size: 9pt; text-align: center;">
            (Bằng chữ: {{t_tt_nt_text}})
        </td>
    </tr>
    <tr>
        <td colspan="4">
            <hr>
        </td>
    </tr>
    <tr>
        <td colspan="4"
            style="font-family: monospace, cursive; font-size: 9pt; font-weight: 550; text-align: center;">
            HÀNG ĐÃ MUA VUI LÒNG KHÔNG ĐỔI HOẶC TRẢ LẠI
        </td>
    </tr>
    <tr>
        <td colspan="4">
            <hr>
        </td>
    </tr>
    <tr>
        <td colspan="4"
            style="font-family: monospace, cursive; font-size: 9pt; font-weight: 550; text-align: center;">
            Xin cảm ơn !!
        </td>
    </tr>
</tbody>
</table>
`;

function PrintTemplate() {
  return (
    <div style={{ width: "400px" }}>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log({ event, editor, data });
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
    </div>
  );
}

export default PrintTemplate;
