import React from 'react';
import withContainer from '../withContainer';
import { useCore } from '../../../hooks';
import styles from './AppConfig.module.css';
import TabContainer from '../components/tab/TabContainer';
import config from '../../../../config';
import InfoTab from './tabs/InfoTab';
import ConfigTab from './tabs/ConfigTab';
import EmailTab from './tabs/EmailTab';
import ViettelContractTab from './tabs/ViettelContractTab';
import OtherTab from './tabs/OtherTab';
import { Controller, useForm } from 'react-hook-form';
import lodash from 'lodash';
import { toast } from 'react-hot-toast';
import AppConfigEditDialog from './dialog/edit/AppConfigEditDialog';
import CopyUpdateTab from './tabs/CopyUpdateTab';
import { Spin } from 'antd';

function AppConfig() {
	const core = useCore();
	const hookForm = useForm();
	const { reset, watch, getValues } = hookForm;

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await core.apiCall({
					withIdApp: false,
					endpoint: `app/${core.id_app}`,
				});
				reset(res.data);
			} catch (error) {
				toast.error(error.toString());
			}
			setLoading(false);
		};
		fetchData();
	}, []);

	const firstRef = React.useRef(true);
	const onChange = lodash.debounce(async (data) => {
		const isFirst = firstRef.current;
		// ignore first change
		if (!isFirst) {
			try {
				await core.apiCall({
					withIdApp: false,
					endpoint: `app/${core.id_app}`,
					method: 'PUT',
					data,
				});
			} catch (error) {
				toast.error(error);
			}
		} else firstRef.current = false;
	}, 500);

	React.useEffect(() => {
		const subscription = watch(onChange);
		return () => subscription.unsubscribe();
	}, [watch]);

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleSubmit = async (data) => {
		try {
			// call api
			const res = await core.apiCall({
				withIdApp: false,
				endpoint: `app/${core.id_app}`,
				method: 'PUT',
				data,
			});

			// reset form
			reset(res.data);

			// close dialog
			handleClose();

			// show toast
			toast.success('Lưu thành công');
		} catch (error) {
			// show toast
			toast.error(error);
		}
	};

	if (loading)
		return (
			<div className={styles.loadingContainer}>
				<Spin size='large' />
			</div>
		);

	const tabs = [
		{ label: 'Thông tin', component: <InfoTab hookForm={hookForm} /> },
		{ label: 'Tùy chọn', component: <ConfigTab hookForm={hookForm} /> },
		{ label: 'Tài khoản email công ty', component: <EmailTab hookForm={hookForm} /> },
		{ label: 'HĐ điện tử Viettel', component: <ViettelContractTab hookForm={hookForm} /> },
		{ label: 'Thông tin khác', component: <OtherTab hookForm={hookForm} /> },
		{ label: 'Sao chép/Cập nhật dữ liệu', component: <CopyUpdateTab hookForm={hookForm} /> },
	];

	return (
		<div
			style={{
				'--primary-color': config.primaryColor,
				'--secondary-color': config.secondaryColor,
			}}
		>
			<AppConfigEditDialog getData={getValues} open={open} onClose={handleClose} onSubmit={handleSubmit} />

			{/* Header */}
			<div className={styles.headerContainer}>
				<Controller
					name='name'
					control={hookForm.control}
					render={({ field: { value } }) => (
						<div className={styles.headerTitle}>
							Cấu hình công ty: <strong>{value}</strong>
						</div>
					)}
				/>
				<button className='btn btn-edit' onClick={handleOpen}>
					Sửa
				</button>
			</div>

			{/* Tabs */}
			<div className={styles.tabsContainer}>
				<TabContainer tabs={tabs} />
			</div>
		</div>
	);
}

export default withContainer(AppConfig);
