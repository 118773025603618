import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useState, useRef, forwardRef } from 'react';

const TabPanel = forwardRef(({ children, value, index, ...other }, ref) => {
	return (
		<div
			ref={ref}
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			<Box>{children}</Box>
		</div>
	);
});

const AntTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const AntTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(2),
		padding: 0,
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:hover': {
			color: '#111',
			opacity: 1,
		},
		'&$selected': {
			color: '#111',
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:focus': {
			color: '#111',
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	tabs: {
		borderBottom: '1px solid #e8e8e8',
		marginBottom: 8,
	},
}));

function TabContainer({ tabs }) {
	const tabDetailRef = useRef(null);
	const classes = useStyles();
	const [value, setValue] = useState(0);
	const [isKeyDown, setIsKeyDown] = useState(false);

	const handleChange = (event, newValue) => {
		if (!isKeyDown) {
			setValue(newValue);
		}
	};

	useEffect(() => {
		function handleKeyDown(e) {
			if (e.ctrlKey && e.altKey) {
				setIsKeyDown(true);
				if (value < tabs.length - 1) {
					setValue(value + 1);
				} else {
					setValue(0);
				}

				// focus on the tab content
				tabDetailRef.current.focus();
			}
		}

		window.addEventListener('keydown', handleKeyDown);

		// Cleanup function to remove the event listener
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [value]);

	return (
		<React.Fragment>
			<div className={classes.root} id="tab-container">
				<div className={classes.demo1}>
					{/* Tab header */}
					<AntTabs
						onClick={() => setIsKeyDown(false)}
						value={value}
						onChange={handleChange}
						className={classes.tabs}
						variant="scrollable"
						scrollButtons="auto"
					>
						{tabs.map((item, index) => (
							<AntTab label={item.label} key={index} />
						))}
					</AntTabs>

					{/* Tab content */}
					{tabs.map((item, index) => (
						<TabPanel value={value} index={index} key={index} ref={tabDetailRef}>
							{item.component}
						</TabPanel>
					))}
				</div>
			</div>
		</React.Fragment>
	);
}

export default TabContainer;
