import { Menu, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { app_title, menu2 } from '../../../config';
const SidebarData = menu2.map((item) => {
	return {
		...item,
		icon: <i className={`fas fa-${item.icon.toLowerCase()}`}></i>,
	};
});

const Sidebar = () => {
	const [sidebar, setSidebar] = useState(false);
	const [openKeys, setOpenKeys] = useState([]);
	const history = useHistory();
	const location = useLocation();
	const showSidebar = () => {
		setSidebar(!sidebar);
	};
	const rootSubmenuKeys = menu2
		.map((item) => {
			if (item?.children) {
				return item.key;
			}
		})
		.filter((x) => x !== undefined);
	const onOpenChange = (items) => {
		const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(items);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};
	useEffect(() => {
		if (sidebar) {
			setOpenKeys([]);
		}
	}, [sidebar]);
	return (
		<div id="wrapper">
			<Tooltip placement="bottom" title={`${sidebar ? 'Ẩn' : 'Hiện'} Điều Hướng`}>
				<div
					style={{
						left: sidebar ? 290 : 115,
					}}
					id="icon-bar"
					onClick={showSidebar}
				>
					<span>
						<div className="line-1"></div>
						<div className="line-2"></div>
						<div className="line-3"></div>
						<div className="line-4"></div>
					</span>
				</div>
			</Tooltip>
			
			<div
				id="left-menu"
				style={{
					width: !sidebar ? 80 : 'calc(20% - 10px)',
				}}
			>
				<Link
					to="/"
					className="logo"
					style={{
						justifyContent: sidebar ? 'flex-start' : 'center',
						paddingLeft: sidebar ? 24 : 0,
					}}
				>
					<img src="./images/logo.png" alt="" /> {sidebar && <h3 style={{ marginLeft: 12 }}> {app_title}</h3>}
				</Link>
				<Menu
					selectedKeys={[location.pathname === '/' ? '/' : location.pathname]}
					mode="inline"
					onClick={({ key }) => {
						location.pathname='/'
						history.push(key)
					}}
					style={{
						width: '100%',
					}}
					onOpenChange={onOpenChange}
					openKeys={openKeys}
					inlineCollapsed={!sidebar}
					items={SidebarData}
				/>
			</div>
		</div>
	);
};

export default Sidebar;
