import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import ParcelFilter from './ParcelFilter';
import FormParcelDialog from './dialog/FormParcelDialog';
import { formatDateDisplay } from '../../../utils/helpers';

const ListParcel = () => {
	const columns = [
		{
			name: 'Mã lô',
			selector: (row) => row.ma_lo,
			width: '100px',
		},
		{
			name: 'Tên lô',
			selector: (row) => row.ten_lo,
			grow: 1,
			wrap: true,
		},
		{
			name: 'Vật tư',
			selector: (row) => [row.ma_vt, row.ten_vt].join(' - '),
			grow: 2,
			wrap: true,
		},
		{
			name: 'Hạn sử dụng',
			selector: (row) => formatDateDisplay(row.han_sd),
			width: '120px',
			right: true,
		},
	];

	return (
		<ListBase
			api="dmlo"
			title="Danh mục lô"
			columns={columns}
			FilterComponent={ParcelFilter}
			FormDialog={FormParcelDialog}
		/>
	);
};

export default withContainer(ListParcel);
