import React from 'react';
import styles from '../../../AppConfig.module.css';
import InputContainer from '../../../../components/Dialog/InputContainer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Controller } from 'react-hook-form';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const inputList = [
	{
		title: 'Điều khoản sử dụng dịch vụ của công ty',
		name: 'dieu_khoan_su_dung',
	},
	{
		title: 'Hướng dẫn mua hàng và thanh toán',
		name: 'exfields.hd_muahang_va_thanhtoan',
	},
	{
		title: 'Chính sách vận chuyển giao hàng',
		name: 'exfields.cs_vanchuyen_giaohang',
	},
	{
		title: 'Chính sách đổi trả hàng hoá',
		name: 'exfields.cs_doi_tra',
	},
	{
		title: 'Chính sách bảo mật thông tin',
		name: 'exfields.cs_bao_mat',
	},
	{
		title: 'Yêu cầu đổi trả hàng hoá',
		name: 'exfields.yc_doi_tra',
	},
	{
		title: 'Quy chế hoạt động',
		name: 'exfields.quy_che_hoat_dong',
	},
	{
		title: 'Điều khoản cộng tác viên',
		name: 'dieu_khoan_congtacvien',
	},
	{
		title: 'Giới thiệu công ty',
		name: 'gioi_thieu',
	},
	{
		title: 'Ngành nghề',
		name: 'nganh_nghe',
	},
];

export default function OtherTab({ hookForm }) {
	const { control } = hookForm;

	return (
		<div className={styles.otherTab}>
			{inputList.map(({ name, title }, index) => (
				<InputContainer key={name} title={title} defaultShow={index === 0}>
					<Controller
						name={name}
						control={control}
						render={({ field: { value, onChange } }) => (
							<CKEditor
								editor={ClassicEditor}
								data={value || ''}
								onChange={(_event, editor) => {
									const data = editor.getData();
									onChange(data);
								}}
							/>
						)}
					/>
				</InputContainer>
			))}
		</div>
	);
}
