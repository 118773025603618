import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useState } from 'react';
import Layout from '../../components/LayoutContainer';
import Container from '../../Container';
import { formatCurrency, formatDateValue } from '../../Utils/MyUtils';
import SaleOrderListData from './SaleOrderListData';
import SaleOrderListFilter from './SaleOrderListFilter';
function SaleOrderListContent({ props }) {
    const context = React.useContext(DefaultContext);
    console.log('context', context.config.id_app);
    const [data, setData] = useState([
        {
            ma_kh: 'KH001',
            ten_kh: 'Nguyễn Văn A',
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ma_kh: 'KH002',
            ten_kh: 'Nguyễn Văn B',
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ma_kh: 'KH003',
            ten_kh: 'Nguyễn Văn C',
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ma_kh: 'KH004',
            ten_kh: 'Nguyễn Văn D',
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
    ]);
    const onFilter = (data) => {
        console.log(data);
    };

    const columns = [
        {
            name: 'Khách hàng',
            selector: (row) => row.ma_kh,
            sortable: true,
        },
        {
            name: 'Tên khách hàng',
            selector: (row) => row.ten_kh,
            sortable: true,
        },
        {
            name: 'Ngày chứng từ',
            selector: (row) => formatDateValue(row.ngay_ct),
            sortable: true,
        },
        {
            name: 'Số chứng từ',
            selector: (row) => row.so_ct,
            sortable: true,
        },
        {
            name: 'Tiền hàng (VNĐ)',
            selector: (row) => formatCurrency(row.t_tien),
        },
        {
            name: 'Giảm giá (VNĐ)',
            selector: (row) => formatCurrency(row.t_ck),
        },
        {
            name: 'Doanh thu (VNĐ)',
            selector: (row) => formatCurrency(row.t_tt),
        },
        {
            name: 'Tiền mặt (VNĐ)',
            selector: (row) => formatCurrency(row.tien_mat),
        },
        {
            name: 'Chuyển khoảng  (VNĐ)',
            selector: (row) => formatCurrency(row.tien_ck),
        },
        {
            name: 'Ví momo  (VNĐ)',
            selector: (row) => formatCurrency(row.vi_momo),
        },
        {
            name: 'Vn pay (VNĐ)',
            selector: (row) => formatCurrency(row.vn_pay),
        },
        {
            name: 'Tổng thanh toán (VNĐ)',
            selector: (row) =>
                formatCurrency(
                    Number(row.tien_mat) +
                        Number(row.tien_ck) +
                        Number(row.vi_momo) +
                        Number(row.vn_pay)
                ),
        },
    ];
    return (
        <Layout FilterLeft={<SaleOrderListFilter onFilter={onFilter} />}>
            <SaleOrderListData data={data} columns={columns} />
        </Layout>
    );
}

const SaleOrderList = (props) => {
    return (
        // <LayoutContainer
        //   FilterLeft={
        //     <div className="roommap__filter">
        //       <Fab
        //         onClick={handleSubmit(onSubmit)}
        //         sx={{
        //           backgroundColor: "#013659",
        //           textTransform: "none",
        //           color: "#fff",
        //           "&:hover": {
        //             color: "#013659",
        //           },
        //           fontSize: "12px",
        //           fontWeight: "bold",
        //           padding: "5px 10px",
        //           margin: "8px",
        //           borderRadius: "5px",
        //           height: "30px",
        //         }}
        //         variant="extended"
        //       >
        //         Lọc
        //       </Fab>

        //       <FilterBox title="Tìm kiếm">
        //         <input
        //           type="text"
        //           placeholder="Tìm kiếm"
        //           className="shadow__box__search"
        //           {...register("so_ct")}
        //         />
        //       </FilterBox>
        //       <FilterSelectTimeFromTo
        //         control={control}
        //         fieldStartDay={"ngay_bat_dau"}
        //         fieldEndDay={"ngay_ket_thuc"}
        //       />
        //     </div>
        //   }
        //   children={
        //     <>
        //       <TableContainer
        //         columns={columns}
        //         title={"Báo cáo cuối ngày bán hàng"}
        //         data={data}
        //         actions={[
        //           <button>
        //             <PublishIcon />
        //             <span className="item_title">Xuất file</span>
        //           </button>,
        //         ]}
        //       />
        //     </>
        //   }
        //   SaleByTimeContent
        // />
        <Container {...props} showDrawerIfIsDesktop={false}>
            <SaleOrderListContent />
        </Container>
    );
};

export default SaleOrderList;
