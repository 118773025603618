import { UserOutlined } from '@ant-design/icons';
import { Avatar, Popconfirm } from 'antd';
import React from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import { HiOutlinePencil, HiOutlinePhoneArrowUpRight, HiOutlineTrash, HiPlus } from 'react-icons/hi2';
import { useCore } from '../../../hooks';
import { TRANG_THAI_KH } from '../../../utils/constants';
import VButton from '../VComponent/VButton';
import VTable from '../VComponent/VTable';
import withContainer from '../withContainer';
import LeadFilter from './LeadFilter';
import styles from './ListLead.module.css';
import ModalLeadBasic from './Modal/ModalLeadBasic';
import ModalLeadFull from './Modal/ModalLeadFull';

function ListLead() {
	const core = useCore();

	const [loading, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [params, setParams] = React.useState({});

	const fetchDebounceTime = 500; // wait 500ms after last render to fetch data (prevent fetch too many times)
	const fetchIdRef = React.useRef(0);
	const fetchData = React.useCallback(
		async (newParams) => {
			const mergeParams = { ...params, ...newParams };
			setLoading(true);

			// Update fetchIdRef.current
			const fetchId = ++fetchIdRef.current;

			setTimeout(async () => {
				// Fetch data from API
				try {
					// Update data if fetchIdRef.current is the same
					if (fetchId === fetchIdRef.current) {
						const [{ data }, { data: count }] = await Promise.all([
							core.apiCall({ endpoint: 'customer', params: mergeParams }),
							core.apiCall({ endpoint: 'customer', params: { ...mergeParams, count: 1 } }),
						]);
						setData(data);
						setParams(mergeParams); // Save params to state (for use in another function)
						setTotalRows(count?.rows_number || 0);
						setLoading(false);
					}
				} catch (error) {
					console.log('getData error', error);
				}
			}, fetchDebounceTime);
		},
		[params]
	);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Avatar',
				accessor: 'hinh_anh',
				disableRowClick: true,
				Cell: ({ value: avatar }) => {
					const src = core.getFileUrl(avatar);
					return <Avatar src={src} icon={<UserOutlined />} />;
				},
				width: 80,
			},
			{
				Header: 'ID',
				accessor: 'ma_kh',
			},
			{
				Header: 'Name',
				accessor: 'ten_kh',
			},
			{
				Header: 'Email',
				accessor: 'email',
				disableRowClick: true,
				Cell: ({ value: email }) =>
					email ? (
						<a href={`mailto:${email}`} className="contact_cell">
							<HiOutlineMail />

							{email}
						</a>
					) : (
						<div className="empty-text">(Trống)</div>
					),
				width: 240,
				minWidth: 240,
			},
			{
				Header: 'Phone',
				accessor: 'dien_thoai',
				disableRowClick: true,
				Cell: ({ value: phone }) =>
					phone ? (
						<a href={`tel:${phone}`} className="contact_cell">
							<HiOutlinePhoneArrowUpRight />

							{phone}
						</a>
					) : (
						<div className="empty-text">(Trống)</div>
					),
				width: 240,
				minWidth: 240,
			},
		],
		[]
	);

	const [openModal, setOpenModal] = React.useState();
	const [dataModal, setDataModal] = React.useState();
	const closeModal = () => {
		setDataModal();
		setOpenModal();
	};

	// Basic Modal
	const openBasicModal = ({ data }) => {
		setDataModal(data);
		setOpenModal('basic');
	};

	// Full Modal
	const openFullModal = ({ data }) => {
		setDataModal(data);
		setOpenModal('full');
	};

	const handleSubmit = async (data) => {
		// Post data to API
		data.trang_thai = TRANG_THAI_KH.TIEM_NANG.value; // Set status
		await core.asyncPostList(core.token, 'customer', data);

		// Refetch data
		fetchData(params);

		// Close modal
		closeModal();
	};

	const handleDelete = async (id) => {
		try {
			await core.asyncDeleteList(core.token, 'customer', id);
			fetchData(params);
		} catch (error) {
			core.contextAlert(error);
		}
	};

	const handleFilter = (filter) => {
		const newParams = { ...params };

		// Update params
		newParams.page = 1;
		newParams.q = filter;
		setParams(newParams);

		// Refetch data
		fetchData(newParams);
	};

	return (
		<div className={styles.container}>
			<ModalLeadBasic
				data={dataModal}
				open={openModal === 'basic'}
				onClose={closeModal}
				onSubmit={handleSubmit}
				openFullForm={openFullModal}
			/>
			<ModalLeadFull data={dataModal} open={openModal === 'full'} onClose={closeModal} onSubmit={handleSubmit} />

			<VTable
				// Toolbar
				toolbar
				toolbarProps={{
					leftActions: [
						<VButton.Filter title="All">
							<LeadFilter onFilter={handleFilter} />
						</VButton.Filter>,
					],
					rightActions: [
						<VButton.Dropdown
							icon={<HiPlus />}
							title="Add"
							onClick={openBasicModal}
							menu={{
								items: [
									{
										label: 'Import Leads',
										key: 'import',
									},
								],
							}}
						/>,
					],
				}}
				// Table
				columns={columns}
				data={data}
				totalRows={totalRows}
				loading={loading}
				fetchData={fetchData}
				onRowClick={(row) => console.log(row)}
				rowActionsRender={(row) => [
					<VButton onClick={() => console.log('Mail to', row.email)} icon={<HiOutlineMail />} />,
					<VButton
						onClick={() => console.log('Phone to', row.phone)}
						icon={<HiOutlinePhoneArrowUpRight />}
					/>,
					<VButton onClick={() => openFullModal({ data: row })} icon={<HiOutlinePencil />} />,
					<Popconfirm
						title="Delete this line?"
						description="Are you sure to delete this line?"
						onConfirm={() => handleDelete(row._id)}
						okText="Yes"
						cancelText="No"
					>
						<VButton icon={<HiOutlineTrash />} />
					</Popconfirm>,
				]}
			/>
		</div>
	);
}

export default withContainer(ListLead);
