import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from '../../components/datePicker/DatePicker';
import FilterBox from '../../components/filter/FilterBox';
import FilterButton from '../../components/filter/FilterButton';
import SelectApi from '../../components/input/SelectApi';
import { formatDateValue } from '../../Utils/MyUtils';

const RevenueSummaryBySuppliesFilter = ({ onFilter }) => {
    // hookform
    const { handleSubmit, control } = useForm({
        mode: 'onBlur',
        defaultValues: {
            searchText: '',
            startDay: new Date(),
            endDay: new Date(),
        },
    });
    const onSubmit = (data) => {
        data.startDay = formatDateValue(data.startDay);
        data.endDay = formatDateValue(data.endDay);
        onFilter(data);
    };

    return (
        <div>
            <div className="roommap__filter " id="filter__left">
                <FilterBox title="Thời gian">
                    <Controller
                        control={control}
                        name="startDay"
                        render={({ field: { value, onChange } }) => (
                            <DatePicker
                                label="Từ ngày"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                    <div
                        style={{
                            marginBottom: '10px',
                        }}
                    ></div>
                    <Controller
                        control={control}
                        name="endDay"
                        render={({ field: { value, onChange } }) => (
                            <DatePicker
                                label="Đến ngày"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </FilterBox>

                <FilterBox title="Loại vật tư">
                    <Controller
                        control={control}
                        name="loai_vt"
                        render={({ field: { value, onChange } }) => (
                            <SelectApi
                                value={value}
                                onChange={onChange}
                                api="dmnvt"
                                placeholder="Chọn loại vật tư"
                                valueField="_id"
                                labelField="ten_nvt"
                            />
                        )}
                    />
                </FilterBox>

                <FilterBox title="Nhóm vật tư">
                    <Controller
                        control={control}
                        name="nhom_vt"
                        render={({ field: { value, onChange } }) => (
                            <SelectApi
                                value={value}
                                onChange={onChange}
                                api="dmnvt"
                                placeholder="Chọn nhóm vật tư"
                                valueField="_id"
                                labelField="ten_nvt"
                            />
                        )}
                    />
                </FilterBox>

                <FilterBox title="Vật tư">
                    <Controller
                        control={control}
                        name="vt"
                        render={({ field: { value, onChange } }) => (
                            <SelectApi
                                value={value}
                                onChange={onChange}
                                api="dmnvt"
                                placeholder="Chọn vật tư"
                                valueField="_id"
                                labelField="dmnvt"
                            />
                        )}
                    />
                </FilterBox>

                <FilterButton onClick={handleSubmit(onSubmit)} />
            </div>
        </div>
    );
};

export default RevenueSummaryBySuppliesFilter;
