import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import VSelect from '../../../VComponent/VSelect';
import axios from 'axios';
import VTagInput from '../../../VComponent/VTagInput';

export default function InformationInput() {
	const form = Form.useFormInstance();
	const [provinces, setProvinces] = React.useState([]);
	const [districts, setDistricts] = React.useState([]);
	const [wards, setWards] = React.useState([]);

	React.useEffect(() => {
		const fetchProvinces = async () => {
			try {
				const res = await axios.get('https://provinces.open-api.vn/api/p/');
				setProvinces(res.data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchProvinces();
	}, []);

	const province = Form.useWatch('tinh_thanh', form);
	React.useEffect(() => {
		const provinceCode = provinces.find((p) => p.name === province)?.code;
		// fetch districts
		const fetchDistrict = async () => {
			try {
				const {
					data: { districts },
				} = await axios.get(`https://provinces.open-api.vn/api/p/${provinceCode}?depth=2`);
				setDistricts(districts);
			} catch (error) {
				console.log(error);
			}
		};
		if (provinceCode) fetchDistrict();
		else setDistricts([]);
	}, [province, provinces]);

	const district = Form.useWatch('khu_vuc', form);
	React.useEffect(() => {
		const districtCode = districts.find((d) => d.name === district)?.code;
		// fetch wards
		const fetchWard = async () => {
			try {
				const {
					data: { wards },
				} = await axios.get(`https://provinces.open-api.vn/api/d/${districtCode}?depth=2`);
				setWards(wards);
			} catch (error) {
				console.log(error);
			}
		};
		if (districtCode) fetchWard();
		else setWards([]);
	}, [district, districts]);

	return (
		<Row>
			<Col span={12}>
				<Form.Item name="ma_kh" label="ID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input placeholder="(Auto Generate)" />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					name="ten_kh"
					label="Full Name"
					rules={[{ required: true, message: 'Full Name is required' }]}
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
				>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					label="Group"
					name="nh_kh"
					rules={[
						{
							required: true,
							message: 'Group is required!',
						},
					]}
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
				>
					<VSelect.API api="dmnhkh" field={{ value: '_id', label: 'group_name' }} allowClear />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item label="Assigned To" name="phu_trach" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<VSelect.API
						api="participant"
						field={{ value: 'email', getLabel: (p) => `${p.name} - ${p.email}` }}
						allowClear
					/>
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item label="Tax ID" name="ma_so_thue" wrapperCol={{ span: 9 }} labelCol={{ span: 3 }}>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name="dia_chi" label="Address" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name="tinh_thanh" label="Province" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<VSelect
						options={provinces.map((p) => ({ value: p.name, label: p.name }))}
						showSearch
						allowClear
						onChange={() => {
							// clear fields
							form.setFields([
								{ name: 'khu_vuc', value: '' },
								{ name: 'xa_phuong', value: '' },
							]);

							// clear districts, wards
							setDistricts([]);
							setWards([]);
						}}
					/>
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					name="khu_vuc"
					label="District"
					help={!province && 'Please select a province first!'}
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
				>
					<VSelect
						options={districts.map((d) => ({ value: d.name, label: d.name }))}
						showSearch
						disabled={!province}
						allowClear
						onChange={() => {
							// clear fields
							form.setFields([{ name: 'xa_phuong', value: '' }]);

							// clear wards
							setWards([]);
						}}
					/>
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					name="xa_phuong"
					label="Ward"
					help={!district && 'Please select a district first!'}
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
				>
					<VSelect
						options={wards.map((w) => ({ value: w.name, label: w.name }))}
						showSearch
						allowClear
						disabled={!district}
					/>
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name="dien_thoai" label="Phone" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name="fax" label="Fax" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					name="email"
					label="Email"
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
					rules={[
						{
							type: 'email',
							message: 'The input is not valid E-mail!',
						},
					]}
				>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					name="website"
					label="Website"
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
					rules={[
						{
							type: 'url',
							message: 'The input is not valid URL!',
						},
					]}
				>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name="dien_giai" label="Note" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input.TextArea autoSize />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name={['exfields', 'tags']} label="Tags" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<VTagInput />
				</Form.Item>
			</Col>
		</Row>
	);
}
