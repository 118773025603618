import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldInput from '../../../../components/Dialog/FieldInput';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';

export default function InfoTab({ hookForm }) {
	const {
		control,
		register,
		formState: { errors },
	} = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<FieldInput
					title='Mã kho'
					placeholder='Nhập mã kho'
					inputProps={{
						...register('ma_kho', {
							required: 'Vui lòng nhập mã kho',
						}),
					}}
					check
					errorMsg={errors.ma_kho?.message}
				/>
			</Grid>

			<Grid item xs={12}>
				<FieldInput
					title='Tên kho'
					placeholder='Nhập tên kho'
					inputProps={{
						...register('ten_kho', {
							required: 'Vui lòng nhập tên kho',
						}),
					}}
					check
					errorMsg={errors.ten_kho?.message}
				/>
			</Grid>

			<Grid item xs={12}>
				<FieldInput
					title='Địa chỉ'
					placeholder='Nhập địa chỉ'
					inputProps={{ ...register('exfields.dia_chi') }}
				/>
			</Grid>

			<Grid item xs={12}>
				<FieldInput
					title='Điện thoại'
					placeholder='Nhập số điện thoại'
					inputProps={{ ...register('exfields.dien_thoai') }}
				/>
			</Grid>

			<Grid item xs={12}>
				<FieldInput title='Email' placeholder='Nhập email' inputProps={{ ...register('exfields.email') }} />
			</Grid>

			<Grid item xs={12}>
				<Controller
					name='user_quan_ly'
					control={control}
					render={({ field: { value, onChange } }) => {
						const item = value ? { value, label: value, email: value } : null;
						const handleChange = (item) => onChange(item?.email || null);

						return (
							<FieldSelectApi
								title='Người quản lý'
								placeholder='Nhập người quản lý'
								api='participant'
								valueField='email'
								labelField='email'
								value={item}
								onChange={handleChange}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={3}>
				<Controller
					name='la_cua_hang'
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size='small' />}
							label='Là cửa hàng'
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={3}>
				<Controller
					name='status'
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size='small' />}
							label='Còn hoạt động'
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={3}>
				<Controller
					name='kho_dc'
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size='small' />}
							label='Kho điều chuyển'
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={3}>
				<Controller
					name='kho_dl'
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={<Checkbox size='small' />}
							label='Kho đại lý'
							checked={!!value}
							onChange={onChange}
						/>
					)}
				/>
			</Grid>

			{/* <Grid item xs={4}>
				<Controller
					name='theo_doi_sl_ht'
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							control={
								<Checkbox checked={!!value} onChange={(e) => onChange(e.target.checked)} size='small' />
							}
							label='Theo dõi số lượng hoàn thành'
						/>
					)}
				/>
			</Grid> */}
		</Grid>
	);
}
