import React from "react";
import FilterAutocomplete from "../components/filter/FilterAutocomplete";
import { Typography } from "@material-ui/core";
import FilterSelectTimeFromTo from "../components/filter/FilterSelectTimeFromTo";
import moment from "moment";

function PromotionFilter({ onChange }) {
  const [filter, setFilter] = React.useState({
    product: null,
    startDate: null,
    endDate: moment(),
  });

  // handle select product
  const handleSelectProduct = (product) => {
    setFilter({ ...filter, product });
  };
  // handle start date change
  const handleStartDatechange = (value) => {
    setFilter({ ...filter, startDate: moment(value) });
  };
  // handle end date change
  const handleEndDateChange = (value) => {
    setFilter({ ...filter, endDate: moment(value) });
  };

  React.useEffect(() => {
    const q = {};
    if (filter.product) {
      q.ma_vt = { $regex: filter.product.ma_vt, $options: "i" };
    }
    if (filter.startDate && filter.endDate) {
      q.hieu_luc_tu = { $gte: filter.startDate };
      q.hieu_luc_den = { $lte: filter.endDate };
    }
    onChange(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div>
      <FilterAutocomplete
        title="Sản phẩm"
        apiCode="dmvt"
        selectedValue={filter.product}
        onSelect={handleSelectProduct}
        getOptionLabel={(option) => option.ten_vt}
        searchFileds={["ma_vt", "ten_vt"]}
        renderOption={(option) => {
          return (
            <Typography style={{ fontSize: "13px" }}>
              {option.ten_vt} - {option.ma_vt}
            </Typography>
          );
        }}
      />
      <FilterSelectTimeFromTo
        startDay={filter.startDate}
        endDay={filter.endDate}
        setStartDay={handleStartDatechange}
        setEndDay={handleEndDateChange}
      />
    </div>
  );
}

export default PromotionFilter;
