import axios from 'axios';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import queryString from 'query-string';
import React from 'react';

const convertParams = (params) => {
	const newParams = {};

	Object.keys(params).forEach((key) => {
		if (params[key] !== undefined && params[key] !== null) {
			if (typeof params[key] === 'object') {
				newParams[key] = JSON.stringify(params[key]);
			} else {
				newParams[key] = params[key];
			}
		}
	});

	return newParams;
};

export function useCore() {
	const context = React.useContext(DefaultContext);

	const apiCall = ({
		method = 'GET',
		endpoint,
		data,
		params = {},
		withIdApp = true,
		responseType = 'json',
		headers,
	}) => {
		const baseUrlArr = [context.config.server_url, 'api'];
		if (withIdApp) baseUrlArr.push(context.config.id_app);

		const baseUrl = baseUrlArr.join('/');

		return axios({
			method,
			baseURL: baseUrl,
			url: endpoint,
			data,
			params: {
				...convertParams(params),
				access_token: context.userInfo.token,
			},
			responseType,
			headers: {
				...headers,
			},
		}).catch((error) => {
			throw error.response?.data?.error || error.response?.data || error.response || error;
		});
	};

	// upload file to server
	const uploadFile = async (file, params = { folder: '' }) => {
		// append file to form data
		const formData = new FormData();
		formData.append('fileupload', file);
		formData.append('return', 'JSON');

		// upload file
		const res = await core.apiCall({
			method: 'POST',
			endpoint: '/uploadfile',
			withIdApp: false,
			params: { json: 1, ...params },
			data: formData,
		});

		// return file info
		return res.data;
	};

	// get file url
	const getFileUrl = (fileRelativePath, size = 'S') => {
		let fileUrlBase = core.server_url;

		// check relative path
		if (!fileRelativePath) return '';
		if (!fileRelativePath.startsWith('http')) {
			// get file url base
			if (fileRelativePath.startsWith('/')) fileUrlBase += fileRelativePath;
			else fileUrlBase += `/${fileRelativePath}`;
		} else fileUrlBase = fileRelativePath;

		// query params
		const queryParams = { access_token: core.token };
		if (size) queryParams.size = size;

		// return file url
		const fileUrl = `${fileUrlBase}?${queryString.stringify(queryParams)}`;
		return fileUrl;
	};

	const core = {
		context,
		token: context.userInfo.token,
		apis: context.apis,
		asyncGetData: context.apis.asyncGetData,
		asyncGetList: context.apis.asyncGetList,
		asyncDeleteList: context.apis.asyncDeleteList,
		asyncPostList: context.apis.asyncPostList,
		contextAlert: context.alert,
		setProgressStatus: context.setProgressStatus,
		apiCall,
		id_app: context.config.id_app,
		server_url: context.config.server_url,
		userInfo: context.userInfo,
		uploadFile,
		getFileUrl,
	};

	return core;
}
