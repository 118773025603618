import React from "react";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import { numeralCustom } from "../../../utils/helpers";
import {
  BarcodeMain,
  BarcodeWrapper,
  ContentRight,
  ImageBarcodeWrapper,
  ProductName,
  ProductPrice,
} from "./barcodeStyled";
import { v4 } from "uuid";

function BarcodeComponent(
  {
    details = [],
    numberColumn = 4,
    formatBarcode = "CODE39",
    options,
    handleOptionsChange,
  },
  ref
) {
  const printRef = React.useRef();

  const barcodes = React.useMemo(() => {
    const result = [];
    if (details.length > 0) {
      details.forEach((detail) => {
        const sl = detail.sl_xuat || detail.sl_nhap;
        for (let i = 1; i <= sl; i++) {
          result.push(detail);
        }
      });
    }
    return result;
  }, [details]);

  return (
    <ContentRight
      id="print-barcode"
      style={{ width: "100%", margin: "0 auto" }}
      ref={printRef}
    >
      <ReactToPrint
        trigger={() => (
          <button hidden ref={ref}>
            print
          </button>
        )}
        content={() => printRef.current}
        pageStyle="print"
        copyCount={3}
      />
      {formatBarcode &&
        barcodes?.length > 0 &&
        barcodes.map((item) => (
          <BarcodeWrapper
            key={v4()}
            style={{ width: `${100 / numberColumn}%`, height: "30mm" }}
          >
            <BarcodeMain>
              {(item.display?.top || []).map((display_field) => {
                if (isNaN(display_field)) {
                  return (
                    <ProductName
                      key={v4()}
                      style={{ textAlign: "center", flex: "unset" }}
                    >
                      {item[display_field]}
                    </ProductName>
                  );
                } else {
                  return (
                    <ProductPrice key={v4()} style={{ textAlign: "center" }}>
                      {numeralCustom(Number(item[display_field])).format(
                        "0,0[.]00"
                      )}
                    </ProductPrice>
                  );
                }
              })}
              <ImageBarcodeWrapper
                style={{
                  height: `calc(100% - ${item.display.top.length * 14}px - ${
                    item.display.bottom.length * 14
                  }px)`,
                }}
              >
                <Barcode
                  // displayValue={options.displayValue}
                  // fontSize={`${options.fontSize}px`}
                  // width={`${options.width}mm`}
                  // height={`${options.height}mm`}
                  // textAlign={options.textAlign}
                  // textPosition={options.textPosition}
                  // textMargin={options.textMargin}
                  // margin={options.margin}
                  // marginTop={options.marginTop}
                  {...options}
                  value={item.ma_vt}
                  format={formatBarcode}
                />
              </ImageBarcodeWrapper>
              {(item.display?.bottom || []).map((display_field) => {
                if (isNaN(item[display_field])) {
                  return (
                    <ProductName
                      key={v4()}
                      style={{ textAlign: "center", flex: "unset" }}
                    >
                      {item[display_field]}
                    </ProductName>
                  );
                } else {
                  return (
                    <ProductPrice key={v4()} style={{ textAlign: "center" }}>
                      {numeralCustom(item[display_field]).format("0,0[.]00")}
                    </ProductPrice>
                  );
                }
              })}
            </BarcodeMain>
          </BarcodeWrapper>
        ))}
    </ContentRight>
  );
}

export default React.forwardRef(BarcodeComponent);
