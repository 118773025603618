import { Button, Collapse, Form, Menu } from 'antd';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { HiChevronDown } from 'react-icons/hi2';
import { useCore } from '../../../../../hooks';
import VModal from '../../../VComponent/VModal';
import TabCpmhDefault from './TabItemsVoucher/Default/TabCpmhDefault';
import TabDetailDefault from './TabItemsVoucher/Default/TabDetailDefault';
import TabInfomation from './TabItemsVoucher/Default/TabInfomation';
import TabTotal from './TabItemsVoucher/Default/TabTotal';
import TabVatDefault from './TabItemsVoucher/Default/TabVatDefault';
import TabCpmh from './TabItemsVoucher/TabCpmh';
import TabDetail from './TabItemsVoucher/TabDetail';
import TabVat from './TabItemsVoucher/TabVat';

const defaultValues = { status: true, ma_nt: 'VND', ty_gia: 1 };
function AddVoucherDialog({ open, handleClose, title, api }) {
	const core = useCore();
	const [isLoading, setIsLoading] = useState(false);
	const [isDataVoucherDetail, setIsDataVoucherDetail] = useState();
	const [isDataVoucherVat, setIsDataVoucherVat] = useState();
	const [isDataVoucherCtcpmhs, setIsDataVoucherCtcpmhs] = useState();
	const [isFieldNt, setIsFieldNt] = useState({
		ma_nt: 'VND',
		ty_gia: 1,
	});
	const [isDataDefaultTab, setIsDataDefaultTab] = useState({});
	const [isTotalVoucher, setIsTotalVoucher] = useState({
		t_sl: 0,
		t_tien_hang: 0,
		t_ck: 0,
		t_cp_cpb: 0,
		t_thue_vao: 0,
	});

	const [isAllotmentPn1, setIsAllotmentPn1] = useState();
	const context = useContext(DefaultContext);

	const hookForm = useForm({
		defaultValues,
	});

	// khi mở dialog thì reset lại form
	useEffect(() => {
		if (open) {
			hookForm.reset(defaultValues);
			setIsDataVoucherDetail([]);
			setIsDataVoucherVat([]);
		}
	}, [open]);

	const {
		getValues,
		setValue,
		register,
		formState: { errors },
		control,
		handleSubmit,
		reset,
		// formState: { isSubmitting },
	} = hookForm;

	const onSubmit = async (data) => {
		setIsLoading(true);
		data.so_ct === '' && delete data.so_ct;
		!data.ngay_ct && (data.ngay_ct = new Date());
		const isDataVoucherDetailNew = isDataVoucherDetail?.filter((item) => item.ma_vt !== '');
		// xoá các trường line trong isDataVoucherDetailNew
		isDataVoucherDetailNew &&
			isDataVoucherDetailNew.forEach((item) => {
				item.line = '';
			});

		const isDataVoucherVatNew = isDataVoucherVat?.filter((item) => item.so_hd !== '');

		isDataVoucherVatNew &&
			isDataVoucherVatNew.forEach((item) => {
				item.line = '';
				!item.ngay_hd && (item.ngay_hd = new Date());
			});

		const isDataVoucherCtcpmhsNew = isDataVoucherCtcpmhs?.filter((item) => item.ma_cp !== '');
		isDataVoucherCtcpmhsNew &&
			isDataVoucherCtcpmhsNew.forEach((item) => {
				item.line = '';
			});

		// eslint-disable-next-line no-useless-catch
		try {
			await context.apis.asyncPostList(context.userInfo.token, api, {
				...data,
				details: isDataVoucherDetailNew,
				vatvaos: isDataVoucherVatNew,
				ctcpmhs: isDataVoucherCtcpmhsNew,
			});
			toast.success('Tạo chứng từ thành công');
			// eslint-disable-next-line no-useless-catch
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const onSave = (data) =>
		onSubmit(data)
			.then(() => {
				handleClose();
			})
			.catch((error) => {
				core.contextAlert(error);
			});

	const onSaveAndCopy = (data) =>
		onSubmit(data)
			.then(() => {
				delete data._id;
				data.so_ct = '';
				data.ngay_ct = new Date();

				reset(data);
			})
			.catch((error) => {
				core.contextAlert(error);
			});

	const handleGetDataDetail = (data) => {
		setIsTotalVoucher({
			...isTotalVoucher,
			t_sl: data?.reduce((a, b) => a + b.sl_nhap, 0),
			t_tien_hang: data?.reduce((a, b) => a + b.tien_hang, 0),
			t_ck: data?.reduce((a, b) => a + b.tien_ck, 0),
		});
		setIsDataVoucherDetail(data);
	};

	const handleGetDataVat = (data) => {
		setIsTotalVoucher({
			...isTotalVoucher,
			t_thue_vao: data?.reduce((a, b) => a + b.t_thue, 0),
		});
		setIsDataVoucherVat(data);
	};
	const handleGetDataCtcpmhs = (data) => {
		setIsTotalVoucher({
			...isTotalVoucher,
			t_cp_cpb: data?.reduce((a, b) => a + b.tien_cp, 0),
		});
		setIsDataVoucherCtcpmhs(data);
	};
	const handleDefaultTab = (data) => {
		setIsDataDefaultTab({
			...isDataDefaultTab,
			tk_co: data.tk_co,
		});
	};
	const handleAllotmentPn1 = (data) => {
		setIsAllotmentPn1(data);
	};

	const getAllotmentPn1 = () => {
		return isAllotmentPn1;
	};

	const scrollTo = (id) => {
		const el = document.getElementById(id);
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	const formSections = [
		{
			id: 'information',
			title: 'Thông tin',
			content: (
				<TabInfomation
					getValues={getValues}
					register={register}
					setValue={setValue}
					errors={errors}
					control={control}
					setIsFieldNt={setIsFieldNt}
					handleDefaultTab={handleDefaultTab}
				/>
			),
		},
		{
			id: 'detail',
			title: 'Chi tiết',
			content: (
				<TabDetail
					readonly={false}
					handleGetDataDetail={handleGetDataDetail}
					getAllotmentPn1={getAllotmentPn1}
					view={false}
					isFieldNt={isFieldNt}
					selectRow={true}
				/>
			),
		},
		{
			id: 'ctcpmhs',
			title: 'Chi phí mua hàng',
			content: (
				<TabCpmh
					readonly={false}
					handleGetDataCtcpmhs={handleGetDataCtcpmhs}
					handleAllotmentPn1={handleAllotmentPn1}
					view={false}
					isFieldNt={isFieldNt}
					selectRow={true}
				/>
			),
		},
		{
			id: 'vatvaos',
			title: 'Thuế vào',
			content: (
				<TabVat
					readonly={false}
					handleGetDataVat={handleGetDataVat}
					view={false}
					isFieldNt={isFieldNt}
					isDataDefaultTab={isDataDefaultTab}
					isTotalVoucher={isTotalVoucher}
					selectRow={true}
				/>
			),
		},
		{
			id: 'voucher',
			title: 'Tổng',
			content: <TabTotal isTotalVoucher={isTotalVoucher} />,
		},
	];

	return (
		<VModal
			open={open}
			onClose={handleClose}
			destroyOnClose
			confirmLoading
			title={`${title} - tạo chứng từ mới`}
			width="98vw"
			height="98vh"
			footer={[
				<Button
					key="submit"
					type="primary"
					form="lead-form-full"
					htmlType="submit"
					onClick={handleSubmit(onSave)}
					id="btnSave"
				>
					Lưu
				</Button>,
				// <Button
				// 	key="submit"
				// 	type="primary"
				// 	form="lead-form-full"
				// 	htmlType="submit"
				// 	onClick={handleSubmit(onSaveAndCopy)}
				// 	id="btnSave"
				// >
				// 	Lưu & sao chép
				// </Button>,
				<Button key="reset" type="dashed" danger onClick={handleClose}>
					Bỏ qua
				</Button>,
			]}
			centered
		>
			<div className="mdf_container">
				<Menu
					defaultSelectedKeys={[formSections[0].id]}
					mode="vertical"
					items={formSections.map(({ id, title }) => ({
						key: id,
						label: title,
						title: title,
					}))}
					onClick={({ key }) => scrollTo(key)}
					className="w_menu_modal"
				/>

				<Form
					id="lead-form-full"
					// form={form}
					// onFinish={handleSubmit}
					// onError={handleError}
					onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} // prevent submit on enter
					className="mdf_content"
					labelWrap
				>
					{formSections.map(({ id, title, content }) => (
						<Collapse
							key={id}
							defaultActiveKey={['1']}
							size="small"
							expandIcon={({ isActive }) => (
								<HiChevronDown
									style={{
										transition: 'all 0.3s ease-in-out',
										transform: `rotate(${isActive ? 0 : -90}deg)`,
									}}
								/>
							)}
						>
							<Collapse.Panel header={title} key="1" id={id}>
								{content}
							</Collapse.Panel>
						</Collapse>
					))}

					{/* Placeholder */}
					<div style={{ minHeight: '20%' }} />
				</Form>
			</div>
		</VModal>
	);
}

export default AddVoucherDialog;
