import dayjs from 'dayjs';
import { utils, writeFileXLSX } from 'xlsx';

export const formatNumber = (value) => {
	if (!value || isNaN(value)) return '0';

	// return value = value.toLocaleString('vi-VN');
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatCurrency = (value, unit = '') => {
	value = formatNumber(value);

	if (unit) value += ' ' + unit;

	return value;
};

// Format display date using dayjs
export const formatDateValue = (date) => {
	return date && dayjs(date).format('DD/MM/YYYY');
};

// Format display datetime using dayjs
export const formatDateTimeValue = (date) => {
	return date && dayjs(date).subtract(7, 'hour').format('DD/MM/YYYY  HH:mm A');
};

// Export excel
export const handleDownloadExcel = (dataSource, sheetName, fileName) => {
	const ws = utils.json_to_sheet(dataSource);
	const wb = utils.book_new();
	utils.book_append_sheet(wb, ws, sheetName);
	writeFileXLSX(wb, `${fileName}.xlsx`);
};
