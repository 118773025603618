import React from 'react';
import Dialog from '../../../Dialog/Dialog';
import styled from 'styled-components';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IoIosClose } from 'react-icons/io';
import { useTheme } from '@material-ui/core/styles';
import ButtonBase from '../../components/button/ButtonBase';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useCore } from '../../../../../../hooks';

const SelectLoWrapper = styled(Box)`
	width: 100%;
`;
const LoItem = styled(Box)`
	width: fit-content;
	height: 20px;
	border-radius: 2px;
	background-color: ${(props) => props.bgColor};
	color: #fff;
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 12px;
	padding: 5px;
	margin-top: 20px;
`;
const LoItemIcon = styled(Box)`
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	cursor: pointer;
	transition: all linear 0.01s;
	border-radius: 50%;
	&:hover {
		background-color: #fff;
		color: ${(props) => props.color};
	}
`;
const AutoCompleteStyled = styled(Autocomplete)`
	width: 100%;
	height: 34px;
	border-radius: 6px;
	background-color: #fff;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #000;
		}
	}
`;

function SelectLoDialog({ open, handleClose, consignments, consignment, handleSaveConsignment }) {
	const theme = useTheme();
	const [currentConsignment, setCurrentConsignment] = React.useState(consignment);
	const { contextAlert } = useCore();

	// handle clear option
	const handleClearOption = () => {
		setCurrentConsignment(null);
	};
	const handleSave = async () => {
		try {
			handleSaveConsignment(currentConsignment);
			handleClose();
		} catch (error) {
			contextAlert(error);
		}
	};

	return (
		<Dialog
			title="Chọn lô"
			width="600px"
			open={open}
			handleClose={handleClose}
			actions={[
				<ButtonBase variant="outline" onClick={handleClose}>
					Hủy
				</ButtonBase>,
				<ButtonBase onClick={handleSave}>Lưu</ButtonBase>,
			]}
		>
			<SelectLoWrapper>
				<Grid container>
					<Grid item xs={12}>
						<AutoCompleteStyled
							forcePopupIcon={false}
							closeIcon={null}
							defaultValue={currentConsignment}
							options={consignments}
							getOptionLabel={(option) => option?.ma_lo || 'Mã lô'}
							onChange={(e, newOption) => {
								setCurrentConsignment(newOption);
							}}
							value={currentConsignment}
							renderOption={(option) => (
								<Typography style={{ fontSize: '13px' }}>
									{option?.ma_lo || 'Mã lô'} -{' '}
									{option?.han_sd ? moment(option?.han_sd).format('DD/MM/YYYY') : 'hạn sử dụng'} -{' '}
									{option?.ton_cuoi || 'Tồn'}
								</Typography>
							)}
							renderInput={(params) => <TextField {...params} variant="outlined" />}
						/>
					</Grid>
				</Grid>
				{currentConsignment && (
					<LoItem bgcolor={theme.palette.primary.main}>
						<span>
							{currentConsignment?.ma_lo || 'Mã lô'} -{' '}
							{currentConsignment?.han_sd
								? moment(currentConsignment?.han_sd).format('DD/MM/YYYY')
								: 'hạn sử dụng'}{' '}
							- {currentConsignment?.ton_cuoi || 'Tồn'}
						</span>
						<LoItemIcon color={theme.palette.primary.main} onClick={handleClearOption}>
							<IoIosClose fontSize="20px" />
						</LoItemIcon>
					</LoItem>
				)}
			</SelectLoWrapper>
		</Dialog>
	);
}

export default SelectLoDialog;
