import { Checkbox } from '@material-ui/core';
import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import FormInventoryDialog from './dialog/form/FormInventoryDialog';
import InventoryFilter from './InventoryFilter';

const ListInventory = () => {
	const columns = [
		{
			name: 'Mã kho',
			selector: (row) => row.ma_kho,
			sortable: true,
		},
		{
			name: 'Tên kho',
			selector: (row) => row.ten_kho,
			sortable: true,
		},
		{
			name: 'Người quản lý',
			selector: (row) => row.user_quan_ly,
			sortable: true,
		},
		{
			name: 'Là cửa hàng',
			selector: (row) => <Checkbox checked={row.la_cua_hang} size='small' disabled />,
			sortable: true,
			center: true,
		},
	];

	return (
		<ListBase
			api='dmkho'
			title='Danh sách kho'
			columns={columns}
			FilterComponent={InventoryFilter}
			FormDialog={FormInventoryDialog}
		/>
	);
};

export default withContainer(ListInventory);
