import React from 'react';
import styled from 'styled-components';
import { Box, TextField, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { cloneDeep } from 'lodash';
import ButtonBase from '../../components/button/ButtonBase';

const schema = yup.object({
	ghi_chu: yup.string().required('Vui lòng thêm ghi chú'),
});

const FormNote = styled(Box)`
	width: 40vw;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
`;
const Title = styled(Typography)`
	font-size: 13px;
	font-weight: 550;
`;
const Actions = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
`;

function NoteDialog({ product, details, handleBillChange, handleClose }) {
	const theme = useTheme();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { ghi_chu: product.ghi_chu },
	});

	// on submit
	const onSubmit = async (values) => {
		const detailsCopy = cloneDeep(details);
		const productCopy = cloneDeep(product);
		productCopy.ghi_chu = values.ghi_chu;
		const index = detailsCopy.findIndex((d) => d._id === productCopy._id);
		detailsCopy.splice(index, 1, productCopy);
		await handleBillChange(detailsCopy);
		handleClose();
	};
	// xoa ghi chu
	const handleDeleteNote = async () => {
		const detailsCopy = cloneDeep(details);
		const productCopy = cloneDeep(product);
		productCopy.ghi_chu = '';
		const index = detailsCopy.findIndex((d) => d._id === productCopy._id);
		detailsCopy.splice(index, 1, productCopy);
		await handleBillChange(detailsCopy);
		handleClose();
	};

	return (
		<FormNote onSubmit={handleSubmit(onSubmit)} component="form">
			<Title>{product?.ten_vt}</Title>
			<TextField {...register('ghi_chu')} multiline variant="outlined" />
			{errors?.ghi_chu && <FormHelperText error>{errors.ghi_chu.message}</FormHelperText>}
			<Actions>
				<ButtonBase variant="outline" onClick={handleDeleteNote}>
					Xóa ghi chú
				</ButtonBase>
				<ButtonBase variant="contained" type="submit">
					Thêm ghi chú
				</ButtonBase>
			</Actions>
		</FormNote>
	);
}

export default NoteDialog;
