import React from 'react';
import { formatNumberWithSpaces } from '../../../Utils';

export default function TabTotal({ isTotalVoucher }) {
	return (
		<div className="dialog__content_edit_voucher__body_checkbox">
			<div className="group_checkbox_voucher"></div>
			<div className="flex-top">
				<div className="item_price_voucher">
					<div className="flex-between">
						<span>T.toán KH:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_tien) : '0'} {'VND'}
						</span>
					</div>

					<div className="flex-between">
						<span>T.toán HĐ:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_tien_hd) : '0'} {'VND'}
						</span>
					</div>

					<div
						className="flex-between"
						style={{
							fontWeight: 'bold',
						}}
					>
						<span>Tổng tiền:</span>
						<span>
							{isTotalVoucher
								? formatNumberWithSpaces(isTotalVoucher?.t_tien_hd + isTotalVoucher?.t_tien)
								: '0'}{' '}
							{'VND'}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
