import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useState } from 'react';
import Layout from '../../components/LayoutContainer';
import Container from '../../Container';
import { formatCurrency } from '../../Utils/MyUtils';
import RevenueSummaryBySuppliesData from './RevenueSummaryBySuppliesData';
import RevenueSummaryBySuppliesFilter from './RevenueSummaryBySuppliesFilter';

function RevenueSummaryBySuppliesContent({ props }) {
    const context = React.useContext(DefaultContext);
    console.log('context', context.config.id_app);
    const [data, setData] = useState([
        {
            id: 1,
            ma_vt: 'VT0000001',
            ten_vt: 'Bàn gỗ',
            dvt: 'VNĐ',
            sl_order: 1,
            t_tien_tb: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            loai_mh: 'Bàn',
            nhom_hang: 'Bàn gỗ',
        },
        {
            id: 2,
            ma_vt: 'VT0000001',
            ten_vt: 'Bàn gỗ',
            dvt: 'VNĐ',
            sl_order: 1,
            t_tien_tb: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            loai_mh: 'Bàn',
            nhom_hang: 'Bàn gỗ',
        },
        {
            id: 3,
            ma_vt: 'VT0000001',
            ten_vt: 'Bàn gỗ',
            dvt: 'VNĐ',
            sl_order: 1,
            t_tien_tb: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            loai_mh: 'Bàn',
            nhom_hang: 'Bàn gỗ',
        },
        {
            id: 4,
            ma_vt: 'VT0000001',
            ten_vt: 'Bàn gỗ',
            dvt: 'VNĐ',
            sl_order: 1,
            t_tien_tb: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            loai_mh: 'Bàn',
            nhom_hang: 'Bàn gỗ',
        },
        {
            id: 5,
            ma_vt: 'VT0000001',
            ten_vt: 'Bàn gỗ',
            dvt: 'VNĐ',
            sl_order: 1,
            t_tien_tb: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            loai_mh: 'Bàn',
            nhom_hang: 'Bàn gỗ',
        },
    ]);
    const onFilter = (data) => {
        console.log(data);
    };

    const columns = [
        {
            name: 'Mã vật tư',
            selector: (row) => row.ma_vt,
            sortable: true,
        },
        {
            name: 'Tên vật tư',
            selector: (row) => row.ten_vt,
            sortable: true,
        },
        {
            name: 'Đơn vị tính',
            selector: (row) => row.dvt,
            sortable: true,
        },
        {
            name: 'Số lượng bán',
            selector: (row) => row.sl_order,
            sortable: true,
        },
        {
            name: 'Đơn giá trung bình (VNĐ)',
            selector: (row) => formatCurrency(row.t_tien_tb),
        },
        {
            name: 'Tiền hàng (VNĐ)',
            selector: (row) => formatCurrency(row.t_tien),
        },
        {
            name: 'Giảm giá (VNĐ)',
            selector: (row) => formatCurrency(row.t_ck),
        },
        {
            name: 'Doanh thu (VNĐ)',
            selector: (row) => formatCurrency(row.t_tt),
        },
        {
            name: 'Loại mặt hàng',
            selector: (row) => row.loai_mh,
            sortable: true,
        },
        {
            name: 'Nhóm hàng',
            selector: (row) => row.nhom_hang,
            sortable: true,
        },
    ];
    return (
        <Layout
            FilterLeft={<RevenueSummaryBySuppliesFilter onFilter={onFilter} />}
        >
            <RevenueSummaryBySuppliesData data={data} columns={columns} />
        </Layout>
    );
}

const RevenueSummaryBySupplies = (props) => {
    return (
        <Container {...props} showDrawerIfIsDesktop={false}>
            <RevenueSummaryBySuppliesContent />
        </Container>
    );
};

export default RevenueSummaryBySupplies;
