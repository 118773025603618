import React from "react";
import DrawerBase from "../../drawer/DrawerBase";
import { Box } from "@material-ui/core";
import Payment from "./Payment";

function DrawerPayment({ open, handleClose }) {
  return (
    <>
      <DrawerBase anchor="left" open={open} handleClose={handleClose}>
        <Box sx={{ width: "40vw", height: "100%" }}>
          <Payment handleClose={handleClose} />
        </Box>
      </DrawerBase>
    </>
  );
}

export default DrawerPayment;
