import { Grid, IconButton } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Pagination } from "@material-ui/lab";
import DefaultContext from "flexbiz-core/components/auth/Context";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SelectAntd, { components } from "react-select";
import { SaleContextSpa } from "../SpaSales";
import SpaItemsProduct from "./Bill/SpaItemsProduct";
import SpaCardService from "./Card/SpaCardService";
import SpaDrawers from "./Drawers/SpaDrawers";
import SpaDrawersBill from "./Drawers/SpaDrawersBill";
import SpaDrawersFilter from "./Drawers/SpaDrawersFilter";
import SearchCustomer from "./SearchCustomer";
import { cloneDeep } from "lodash";
import SpaListItemBills from "./Bill/SpaListItemBills";

const leftBoxStyle = {
	height: "100%",
	borderRight: "1px dashed #ccc",
};
const rightBoxStyle = {
	height: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
};
const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: "transparent",
		boxShadow: "none",
		borderRadius: 5,
		minWidth: 120,
		border: "none",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#e0e0e0",
		},
	}),
};
const colourStylesStaff = {
	control: (styles) => ({
		...styles,
		backgroundColor: "transparent",
		boxShadow: "none",
		borderRadius: 5,
		minWidth: 150,
		border: "none",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#e0e0e0",
		},
	}),
};
const optionsStaff = [
	{
		label: "Nguyen Nguyen Nguyen Nguyen",
		value: "thuy",
	},
	{
		label: "Tran Thanh Nga",
		value: "nga",
	},
	{
		label: "Tran Thanh Duy",
		value: "duy",
	},
];
const optionsPrice = [
	{
		label: "100000",
		value: "vip1",
	},
	{
		label: "200000",
		value: "vip2",
	},
	{
		label: "300000",
		value: "vip3",
	},
];

const productTypes = [
	{ id: 0, name: "Tất cả", q: {} },
	{ id: 1, name: "Sản phẩm", q: { is_goods: true } },
	{ id: 2, name: "Dịch vụ", q: { is_service: true } },
	{
		id: 3,
		name: "Gói dịch vụ",
		q: { is_package: true },
	},
	{ id: 4, name: "Thẻ tài khoản", q: { is_card: true } },
	{
		id: 5,
		name: "Combo",
		q: { is_combo: true },
	},
];

function SpaMain() {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openDrawerBill, setOpenDrawerBill] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState([]);
	const [selectedPrice, setSelectedPrice] = useState([]);
	const [selectedSocial, setSelectedSocial] = useState();

	const [dataBill, setDataBill] = useState([]);

	const [isActive, setIsActive] = useState(0);
	const [type, setType] = useState(productTypes[0]);
	const [dataProduct, setDataProduct] = useState([]);
	const [totalPages, setTotalPages] = useState();
	const [params, setParams] = useState({
		limit: 12,
		page: 1,
	});
	const [currentChannel, setCurrentChannel] = useState();
	const [channels, setChannels] = useState([]);

	const context = useContext(DefaultContext);

	const { voucher, kho, setVoucher } = useContext(SaleContextSpa);

	const handleActive = (id) => {
		setIsActive(id);
	};
	const getOptionLabel = (option) => (
		<span
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			{option.image && (
				<img
					style={{
						marginRight: "5px",
					}}
					src={option.image}
					alt={option.label}
				/>
			)}
			{option.label}
		</span>
	);
	const DropdownOption = (props) => (
		<components.Option {...props}>
			<span
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				{props.data.image && (
					<img
						style={{
							marginRight: "5px",
						}}
						src={props.data.image}
						alt={props.data.label}
					/>
				)}
				{props.data.label}
			</span>
		</components.Option>
	);

	const DropdownValue = (props) => (
		<components.SingleValue {...props}>
			<span
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				{props.data.image && (
					<img
						style={{
							marginRight: "5px",
						}}
						src={props.data.image}
						alt={props.data.label}
					/>
				)}
				{props.data.label}
			</span>
		</components.SingleValue>
	);

	const handleOpenDrawer = () => {
		setOpenDrawer(true);
	};
	const handleCloseDrawer = () => {
		setOpenDrawer(false);
	};

	const handleOpenDrawerBill = () => {
		setOpenDrawerBill(true);
	};

	const handleCloseDrawerBill = () => {
		setOpenDrawerBill(false);
	};

	// Get data product
	const getDataProduct = useCallback(async () => {
		context.setProgressStatus(true);

		try {
			let condition = { status: true };
			if (type) {
				condition = { ...condition, ...type.q };
			}
			const [data, count] = await Promise.all([
				context.apis.asyncGetList(context.userInfo.token, "dmvt", {
					condition: condition,
					...params,
				}),
				context.apis.asyncGetList(context.userInfo.token, "dmvt", {
					count: 1,
					condition: condition,
				}),
			]);

			// set total pages
			const totalItem = count.rows_number;
			const totalPage = Math.ceil(totalItem / params.limit);
			setTotalPages(totalPage);
			setDataProduct(data);
		} catch (error) {
			console.log("getDataProduct error", error);
		} finally {
			context.setProgressStatus(false);
		}
	}, [context, params, type]);

	useEffect(() => {
		getDataProduct();
	}, [params.page, params.limit, getDataProduct]);

	const handleChangePage = (page) => {
		setParams({
			...params,
			page,
		});
	};

	// Get data bill

	useEffect(() => {
		if (voucher) {
			setDataBill(voucher.details);
		}
	}, [voucher]);

	// handle save PBL
	const handleSavePBL = async (data) => {
		let _data_for_save = cloneDeep(data);
		// context.setProgressStatus(true);
		// this.timeSave = new Date().getTime();
		if (!_data_for_save._id) {
			let condition = {
				trang_thai: "1",
				ma_ban: _data_for_save.ma_ban,
				ma_kho: _data_for_save.ma_kho,
			};
			let voucher = await context.apis.asyncGetData(
				context.userInfo.token,
				"pbl",
				condition
			);
			if (voucher) {
				let details = voucher.details;
				let vs_merge = [_data_for_save];
				vs_merge
					.map((v) => v.details)
					.reduce((a, b) => [...a, ...b], [])
					.forEach((p) => {
						let item = details.find(
							(d) =>
								d.ma_vt === p.ma_vt && d.ty_le_ck === p.ty_le_ck
						);
						if (!item) {
							item = { ...p };
							details.push(item);
						} else {
							item.sl_xuat += p.sl_xuat;
							item.sl_order += p.sl_order;
							item.sl_gui_bep += p.sl_gui_bep;
							item.sl_gui_bartender += p.sl_gui_bartender;
							item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
							item.tien_ck_nt += item.tien_ck_nt;
							item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
						}
					});
				voucher.tien_ck_hd =
					(voucher.tien_ck_hd || 0) +
					vs_merge
						.map((v) => v.tien_ck_hd || 0)
						.reduce((a, b) => a + b, 0);
				voucher.tien_evoucher =
					(voucher.tien_evoucher || 0) +
					vs_merge
						.map((v) => v.tien_evoucher || 0)
						.reduce((a, b) => a + b, 0);
				voucher.evouchers = [
					...voucher.evouchers,
					...vs_merge
						.map((v) => v.evouchers || [])
						.reduce((a, b) => a.concat(b), []),
				];

				voucher.t_tien_nt = details
					.map((d) => d.tien_hang_nt)
					.reduce((a, b) => a + b, 0);
				voucher.t_ck_nt =
					details
						.map((d) => d.tien_ck_nt)
						.reduce((a, b) => a + b, 0) +
					(voucher.tien_ck_hd || 0) +
					(voucher.tien_evoucher || 0);
				voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
				_data_for_save = voucher;
			}
		}
		let rs = await context.apis.asyncPostList(
			context.userInfo.token,
			"pbl",
			_data_for_save
		);
		// context.setProgressStatus(false);
		return rs;
	};
	// handle bill change
	const handleBillChange = async (details, _voucher) => {
		let voucher_for_save = cloneDeep(voucher);
		if (_voucher) {
			voucher_for_save = { ...voucher_for_save, ..._voucher };
		}
		if (details) {
			voucher_for_save.details = details;
		}
		try {
			// cần hàm savePBL
			let voucher_saved = await handleSavePBL(voucher_for_save);
			setVoucher(voucher_saved);
			if (
				voucher_saved.ma_ban !== voucher_for_save.ma_ban &&
				kho.theo_doi_sl_ht
			) {
				//gui thong bao doi ban cho bep va batender
				// context.alert(
				//   context.apis.getLabel("Bạn có muốn in phiếu đổi bàn không?"),
				//   () => {
				//     //cần ham print
				//     print(
				//       "5",
				//       () => {},
				//       (error) => {
				//         context.alert(error);
				//       },
				//       false
				//     );
				//   },
				//   "blue"
				// );
			}
		} catch (e) {
			context.alert(
				e || context.apis.getLabel("Không thể kết nối với máy chủ")
			);
		}
	};
	// handle select product
	const add = async (p, sl = 1, plus = true) => {
		let voucherCopy = cloneDeep(voucher);
		let details = voucherCopy.details;
		let item;

		if (p.km_yn_for) {
			item = details.find(
				(d) => d.ma_vt === p.ma_vt && d.km_yn_for === p.km_yn_for
			);
		} else {
			if (kho && kho.theo_doi_sl_ht) {
				item = details.find(
					(d) =>
						d.ma_vt === p.ma_vt && d.line === p.line && !d.km_yn_for
				);
			} else {
				item = details.find((d) => d.ma_vt === p.ma_vt && !d.km_yn_for);
			}
			if (details.length > 0 && !item) {
				// eslint-disable-next-line array-callback-return
				details.map((items) => {
					if (items.ma_vt === p.ma_vt && !items.km_yn_for) {
						item = items;
					}
				});
			}
		}
		if (!item) {
			item = { ...p, sl_xuat: 0 };
			item.gia_ban_nt = item.gia_ban_le || 0;
			item.order_time = new Date();
			item.sl_ht = 0;
			item.sl_gui_bep = 0;
			item.sl_gui_bartender = 0;
			item.sl_order = 0;
			item.line = new Date().getTime();
			item.ma_dvt = p.ma_dvt || "Bộ";
			item.ma_tt1 = p.ma_nvt;
			item.ghi_chu = p.ma_nvt2;
			details.push(item);
		}
		item.sl_order = item.sl_order || 0;
		if (plus) {
			item.sl_order += sl;
		} else {
			item.sl_order = sl;
		}
		// if (sl !== 1 && sl !== -1) {
		//   item.sl_order = sl;
		// } else {
		//   item.sl_order += sl;
		// }
		//fix old voucher
		item.sl_gui_bartender = item.sl_gui_bartender || 0;
		item.sl_gui_bep = item.sl_gui_bep || 0;
		//neu khong theo doi sl ht thi sl_xuat luon bang sl_order
		if (
			kho ||
			kho.theo_doi_sl_ht ||
			(!item.sp_yeu_cau_pha_che && !item.sp_yeu_cau_che_bien)
		) {
			item.sl_xuat = item.sl_order;
		}
		//tinh chiet khau
		item.ty_le_ck = item.ty_le_ck || 0;
		item.tien_ck_nt = item.tien_ck = item.tien_ck_nt || 0;
		//
		if (item.sl_xuat >= item.sl_order) {
			if (!item.finish_time || sl) item.finish_time = new Date();
		} else {
			item.finish_time = null;
		}
		//
		if (!item.sp_yeu_cau_che_bien && !item.sp_yeu_cau_pha_che) {
			item.finish_time = item.order_time;
		}
		if (item.sl_order > 0) {
			item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
			if (item.ty_le_ck) {
				item.tien_ck_nt = Math.round(
					(item.tien_hang_nt * item.ty_le_ck) / 100
				);
			}
			item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
		} else {
			if (kho && kho.theo_doi_sl_ht) {
				details = details.filter(
					(vt) => !(vt.ma_vt === item.ma_vt && vt.line === item.line)
				);
			} else {
				details = details.filter((vt) => vt.ma_vt !== p.ma_vt);
			}
		}
		voucherCopy.details = details;
		voucherCopy.exfields.dmckhd = [];
		voucherCopy.t_tien_nt = details
			.map((d) => d.tien_hang_nt)
			.reduce((a, b) => a + b, 0);
		voucherCopy.t_ck_nt =
			details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
			(voucherCopy.tien_ck_hd || 0) +
			(voucherCopy.tien_evoucher || 0);
		voucherCopy.t_tt_nt = voucherCopy.t_tien_nt - voucherCopy.t_ck_nt;

		await setVoucher(voucherCopy);
		handleBillChange(details);
	};

	// get current channel
	const getCurrentChannel = async (ma_kenh) => {
		try {
			const resp = await context.apis.asyncGetData(
				context.userInfo.token,
				"dmkenhbanhang",
				{ ma_kenh }
			);
			if (resp) {
				setCurrentChannel(resp);
			} else {
				setCurrentChannel(null);
			}
		} catch (error) {
			context.alert(error);
		}
	};

	// handle select channel
	const handleSelectChannel = async (channel) => {
		try {
			const voucherClone = cloneDeep(voucher);
			const exfields = cloneDeep(voucherClone.exfields || {});
			exfields.kenh_ban = channel.ma_kenh;
			voucherClone.exfields = exfields;
			await handleSavePBL(voucherClone);
			setCurrentChannel(channel);
		} catch (error) {
			context.alert(error);
		}
	};

	useEffect(() => {
		getCurrentChannel(voucher?.exfields?.kenh_ban || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [voucher]);

	// load channel
	const loadChannel = async () => {
		try {
			const resp = await context.apis.asyncGetList(
				context.userInfo.token,
				"dmkenhbanhang"
			);
			if (resp) {
				// thêm lable và value cho channel
				const channels = resp.map((item) => {
					return {
						...item,
						label: item.ten_kenh,
						value: item.ma_kenh,
					};
				});
				setChannels(channels);
			}
		} catch (error) {
			context.alert(error);
		}
	};

	useEffect(() => {
		loadChannel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context]);

	return (
		<>
			<Grid
				spacing={2}
				container
				style={{
					height: "calc(100vh - 162px)",
					flexWrap: "inherit",
				}}
			>
				<Grid
					item
					style={{
						minWidth: "350px",
						width: "35%",
						height: "100%",
					}}
				>
					<div style={leftBoxStyle}>
						<div className="header_left_main">
							<div className="group_button">
								{productTypes.map((item, index) => {
									return (
										<button
											key={index}
											className={
												isActive === item.id
													? "btn btn-edit"
													: "btn btn-white"
											}
											onClick={() => {
												handleActive(item.id);
												setType(item);
												handleChangePage(1);
											}}
										>
											{item.name}
										</button>
									);
								})}
							</div>
							<div className="group_filter">
								<IconButton onClick={handleOpenDrawer}>
									<FilterListIcon />
								</IconButton>
							</div>
						</div>
						<div className="contain_left_main">
							{dataProduct.map((item, index) => {
								return (
									<div key={index} className="item_left_main">
										<SpaCardService
											handleSelectProduct={add}
											data={item}
										/>
										{/* {isActive === 1 && (
											<SpaCardService data={item} />
										)}
										{isActive === 2 && (
											<SpaCardServicePack data={item} />
										)}
										{isActive === 3 && (
											<SpaCardProduct data={item} />
										)} */}
									</div>
								);
							})}
						</div>
						<Pagination
							className="flex-center"
							count={totalPages}
							size="small"
							page={params.page}
							showFirstButton
							showLastButton
							onChange={(event, value) => {
								handleChangePage(value);
							}}
						/>
					</div>
				</Grid>
				<Grid
					item
					xs
					style={{
						maxWidth: "calc(100% - 350px)",
					}}
				>
					<div style={rightBoxStyle}>
						<div className="box_right_main">
							<div className="header_right_main">
								<div className="header_right_main_input">
									<SearchCustomer
										voucher={voucher}
										handleSavePBL={handleSavePBL}
									/>
								</div>
								<div className="header_right_main_select">
									<SelectAntd
										placeholder="Chọn nhân viên"
										options={optionsStaff}
										value={selectedStaff}
										onChange={setSelectedStaff}
										labelledBy="Select"
										searchable={true}
										styles={colourStylesStaff} // bật tính năng tìm kiếm
									/>
									<SelectAntd
										placeholder="Bảng giá"
										options={optionsPrice}
										value={selectedPrice}
										onChange={setSelectedPrice}
										labelledBy="Select"
										searchable={true}
										styles={colourStyles} // bật tính năng tìm kiếm
									/>
									<SelectAntd
										placeholder={
											currentChannel?.ten || "Kênh bán"
										}
										options={channels}
										components={{
											Option: DropdownOption,
											SingleValue: DropdownValue,
										}}
										getOptionLabel={getOptionLabel}
										value={currentChannel?.ten}
										onChange={handleSelectChannel}
										labelledBy="Select"
										searchable={true}
										styles={colourStyles} // bật tính năng tìm kiếm
									/>
								</div>
							</div>
							<div className="header_right_main_item_bill">
								{dataBill.map((item, index) => {
									return (
										<SpaListItemBills
											data={item}
											key={index}
											add={add}
											index={index}
										/>
									);
								})}
							</div>
						</div>

						<div className="header_right_main_item_pay">
							<button
								onClick={handleOpenDrawerBill}
								className="btn btn-edit"
							>
								Thanh toán
							</button>
						</div>
					</div>
				</Grid>
			</Grid>
			<SpaDrawers
				open={openDrawer}
				onClose={handleCloseDrawer}
				anchor={"left"}
			>
				<SpaDrawersFilter onClose={handleCloseDrawer} />
			</SpaDrawers>
			<SpaDrawers
				open={openDrawerBill}
				onClose={handleCloseDrawerBill}
				anchor={"right"}
			>
				<SpaDrawersBill onClose={handleCloseDrawerBill} />
			</SpaDrawers>
		</>
	);
}

export default React.forwardRef(SpaMain);
