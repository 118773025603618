import { InputNumber, Skeleton } from 'antd';
import React from 'react';
import { HiChevronDown, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { IoSettingsOutline, IoSync } from 'react-icons/io5';
import { MdOutlineDisplaySettings } from 'react-icons/md';
import ModalColSettings from './ModalColSettings';
import VButton from './VButton';
import styles from './VComponent.module.css';
import VSelect from './VSelect';

const VToolBar = ({ rTableHook, syncData, loading, leftActions, rightActions }) => {
	// Columns settings
	const { allColumns, setColumnOrder, pageCount } = rTableHook;
	const [openColSettings, setOpenColSettings] = React.useState(false);
	const showColSettings = () => setOpenColSettings(true);
	const hideColSettings = () => setOpenColSettings(false);

	// Pagination
	const isEmpty = pageCount === 0 && !loading;
	loading = loading && pageCount === 0;

	return (
		<div className={styles.toolbar_container}>
			{/* Left */}
			<div className={styles.toolbar_left}>
				{leftActions?.map((action, index) => (
					<div key={index}>{action}</div>
				))}
			</div>

			{/* Right */}
			<div className={styles.toolbar_right}>
				{rightActions?.map((action, index) => (
					<div key={index}>{action}</div>
				))}

				{syncData && <VButton icon={<IoSync />} title="Sync" onClick={syncData} />}

				<VButton icon={<IoSettingsOutline />} title="Settings" />

				<ModalColSettings
					open={openColSettings}
					onClose={hideColSettings}
					allColumns={allColumns}
					setColumnOrder={setColumnOrder}
				/>
				<VButton
					icon={<MdOutlineDisplaySettings />}
					className={styles.v_button}
					title="Columns config"
					onClick={showColSettings}
				/>

				{!isEmpty && <Pagination rTableHook={rTableHook} loading={loading} />}
			</div>
		</div>
	);
};

const Pagination = ({ rTableHook, loading }) => {
	const {
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		gotoPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = rTableHook;

	const pageSizeOptions = [20, 50, 100];

	return (
		<>
			<div className={styles.toolbar_pagination}>
				<VButton
					icon={<HiChevronLeft />}
					className={styles.v_button}
					onClick={previousPage}
					disabled={!canPreviousPage}
					loading={loading}
				/>

				{loading ? (
					<Skeleton.Input active size="small" />
				) : (
					// <Input value={`${pageIndex + 1}/${pageCount}`} readOnly className={styles.pagination_label} />
					<InputNumber
						value={pageIndex + 1}
						addonAfter={`of ${pageCount}`}
						onChange={(value) => gotoPage(value - 1)}
						className={styles.pagination_label}
						min={1}
						max={pageCount}
					/>
				)}

				<VButton
					icon={<HiChevronRight />}
					className={styles.v_button}
					onClick={nextPage}
					disabled={!canNextPage}
					loading={loading}
				/>
			</div>

			<VSelect
				value={pageSize}
				suffixIcon={<HiChevronDown />}
				onChange={setPageSize}
				options={pageSizeOptions.map((option) => ({
					value: option,
					label: `${option} rows`,
				}))}
			/>
		</>
	);
};

export default VToolBar;
