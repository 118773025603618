import React from 'react';
import styled from 'styled-components';
import { Box, Typography, TextField, Avatar } from '@material-ui/core';
import { CiSearch } from 'react-icons/ci';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { unicode2Ascii } from 'flexbiz-core/utils';
import { Autocomplete } from '@material-ui/lab';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BASE_URL } from '../../../utils/constants';
import { cloneDeep } from 'lodash';

const Icon = styled(Box)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SearchStyled = styled(Autocomplete)`
	width: 300px;
	height: 34px;
	border-radius: 6px;
	background-color: #fff;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #000;
		}
	}
`;
const ProductItem = styled(Typography)`
	padding: 5px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 5px;
`;
const ProductImg = styled(Avatar)`
	width: 30px;
	height: 30px;
	border-radius: 6px;
`;
const ProductName = styled(Typography)`
	font-size: 13px;
`;
const NoOptionText = styled(Typography)`
	font-size: 13px;
	font-style: italic;
	text-align: center;
`;

const NoOptionComponent = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
function SearchProduct({ setSelectedProducts }) {
	const context = React.useContext(DefaultContext);
	const timerRef = React.useRef();
	const searchRef = React.useRef();

	const [products, setProducts] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [searchText, setSearchText] = React.useState('');
	const [load, setLoad] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [page, setPage] = React.useState(1);
	const [count, setCount] = React.useState(0);

	const handleSelectProduct = async (p) => {
		setSelectedProducts((prev) => {
			const prevClone = cloneDeep(prev);
			const index = prevClone.findIndex((item) => item._id === p._id);
			if (index >= 0) {
				if (prevClone[index].sl_xuat) {
					prevClone[index].sl_xuat += 1;
				} else if (prevClone[index].sl_nhap) {
					prevClone[index].sl_nhap += 1;
				}
			} else {
				p.display = {
					top: ['ten_vt'],
					bottom: ['gia_ban_le'],
				};
				p.sl_nhap = 1;
				p.sl_xuat = 1;
				prevClone.push(p);
			}
			return prevClone;
		});
		setProducts([]);
		setSearchText('');
		setLoad(load + 1);
	};

	// get customer
	const getProducts = async (search, page, oldProducts) => {
		if (!search) {
			setProducts([]);
			return;
		}
		setLoading(true);
		let condition = { status: true, $or: [] };
		//
		condition.$or.push({ ma_vt: { $regex: search, $options: 'i' } });
		condition.$or.push({ ten_vt: { $regex: search, $options: 'i' } });
		let $search = await unicode2Ascii(search);
		condition.$or.push({ $text: { $search: search + ' ' + $search } });

		const resp = await context.apis.asyncGetList(context.userInfo.token, 'dmvt', {
			condition: condition,
			limit: 20,
			page,
		});
		const { rows_number } = await context.apis.asyncGetList(context.userInfo.token, 'dmvt', {
			condition: condition,
			count: 1,
		});
		setCount(rows_number);
		if (resp) {
			setProducts([...oldProducts, ...resp]);
		} else {
			setProducts([]);
		}
		if (oldProducts.length === 0) {
			setPage(1);
		}
		setLoading(false);
	};

	// handle list box scroll
	const handleListBoxScroll = (e) => {
		if (products.length === count || loading === true) {
			e.preventDefault();
			return;
		}
		const { scrollHeight, scrollTop } = e.target;
		const height = e.target.clientHeight;
		const subtract = scrollHeight - scrollTop - height;
		if (subtract <= 10) {
			setPage(page + 1);
		}
	};

	// set search khi search text thay doi
	React.useEffect(() => {
		searchRef.current?.focus();
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);
	React.useEffect(() => {
		if (search && page > 1) {
			getProducts(search, page, products);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);
	// get customer khi search thay doi
	React.useEffect(() => {
		if (search) {
			getProducts(search, 1, []);
		} else {
			setProducts([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);
	React.useEffect(() => {
		searchRef.current?.focus();
	}, []);
	console.log({ products, count });

	return (
		<>
			<SearchStyled
				key={load}
				noOptionsText={
					<NoOptionComponent>
						<NoOptionText>Không tìm thấy sản phẩm</NoOptionText>
					</NoOptionComponent>
				}
				ListboxProps={{ index: 1, onScroll: handleListBoxScroll }}
				onChange={(event, newValue) => handleSelectProduct(newValue)}
				filterOptions={(options) => options}
				forcePopupIcon={false}
				clearOnBlur={false}
				closeIcon={null}
				options={products}
				getOptionLabel={(option) => `${option.ten_vt} ${option.ma_vt}`}
				open={!!searchText}
				renderOption={(option) => {
					return (
						<ProductItem>
							<ProductImg src={`${BASE_URL}${option.picture}?access_token=${context.userInfo.token}`} />
							<ProductName>
								{option.ten_vt} - ({option.ma_vt})
							</ProductName>
						</ProductItem>
					);
				}}
				renderInput={(params) => (
					<TextField
						inputProps={{ tabIndex: 1 }}
						inputRef={searchRef}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={'Tìm tên, mã sản phẩm'}
						{...params}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							startAdornment: (
								<>
									{loading ? (
										<Icon className="round-loading">
											<AiOutlineLoading3Quarters fontSize="14px" />
										</Icon>
									) : (
										<Icon>
											<CiSearch fontSize="20px" />
										</Icon>
									)}
								</>
							),
						}}
						variant="outlined"
					/>
				)}
			/>
		</>
	);
}

export default SearchProduct;
