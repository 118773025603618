import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Collapse, Radio, RadioGroup, TextField } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import React, { useState } from 'react';
import { GrDrag, GrFormUp } from 'react-icons/gr';
import { TbPencil } from 'react-icons/tb';
import FilterBox from './FilterBox';

const FilterListRatioDndContext = React.createContext();

export default function FilterListRatioDnD({
	title,
	items: initItems,
	labelField = 'label',
	valueField = 'value',
	subField = 'sub',
	onChange,
	collapsable,
	actions,
	editable,
	onEdit,
	searchable,
	onSearch,
	isSetCollapsed,
}) {
	const [items, setItems] = useState(initItems);
	React.useEffect(() => {
		setItems(initItems);
	}, [initItems]);

	const [selectedValue, setSelectedValue] = useState('');
	const [searchText, setSearchText] = useState('');
	const onChangeText = (e) => {
		const value = e.target.value;

		const searchItem = (acc, item) => {
			const label = item[labelField];
			const sub = item[subField];

			if (label.toLowerCase().includes(value.toLowerCase())) {
				return [...acc, item];
			}

			if (sub) {
				const filteredSub = sub.reduce(searchItem, []);

				if (filteredSub.length) {
					return [...acc, { ...item, sub: filteredSub }];
				}
			}

			return acc;
		};

		const filteredItems = initItems.reduce(searchItem, []);

		setItems(filteredItems);

		setSearchText(value);
		// eslint-disable-next-line no-unused-expressions
		onSearch?.(value);
	};

	const onSelect = (e) => {
		const value = e.target.value;

		setSelectedValue(value);
		// eslint-disable-next-line no-unused-expressions
		onChange?.(value);
	};

	const handleChange = (value) => {
		setItems(value);
		// eslint-disable-next-line no-unused-expressions
	};

	return (
		<FilterBox title={title} collapsable={collapsable} actions={actions} isSetCollapsed={isSetCollapsed}>
			{searchable && (
				<TextField
					value={searchText}
					onChange={onChangeText}
					fullWidth
					InputProps={{
						startAdornment: (
							<SearchRoundedIcon
								style={{
									color: '#9e9e9e',
									marginRight: 8,
								}}
							/>
						),
						style: {
							marginBottom: 8,
							fontSize: 12,
						},
					}}
				/>
			)}
			<RadioGroup value={selectedValue} onChange={onSelect}>
				<div className="filter__check__list-item">
					<Radio
						size="small"
						style={{
							padding: 0,
						}}
						value=""
					/>

					<label style={{ marginLeft: 8 }}>Tất cả</label>
				</div>

				<div style={{ maxHeight: 400, overflow: 'hidden auto' }}>
					<FilterListRatioDndContext.Provider
						value={{
							labelField,
							valueField,
							subField,
							editable,
							onEdit,
						}}
					>
						<ListRadio
							items={items}
							selectedValue={selectedValue}
							onSelect={onSelect}
							onChange={handleChange}
						/>
					</FilterListRatioDndContext.Provider>
				</div>
			</RadioGroup>
		</FilterBox>
	);
}

function ListRadio({ items, deep = 0, onChange }) {
	const handleDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const newItems = Array.from(items);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);

		// eslint-disable-next-line no-unused-expressions
		onChange?.(newItems);
	};

	const handleSubChange = (value, index) => {
		const newItems = [...items];
		newItems[index].sub = value;

		// eslint-disable-next-line no-unused-expressions
		onChange?.(newItems);
	};

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={`list-radio-${deep}`}>
				{(provided) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						{...provided.dropHandleProps}
						className={'filter__check__list ' + (deep > 0 ? 'filter__check__list--sub' : '')}
					>
						{items.map((item, index) => (
							<RadioRow index={index} item={item} deep={deep} handleSubChange={handleSubChange} />
						))}

						{provided.placeholder}

						{/* Empty */}
						{items.length === 0 && (
							<div className="filter__check__list-item">
								<label className="empty">Không có dữ liệu</label>
							</div>
						)}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

function RadioRow({ item, index, deep, handleSubChange }) {
	const { editable, onEdit, labelField, valueField, subField } = React.useContext(FilterListRatioDndContext);
	const value = item[valueField];
	const label = item[labelField];
	const sub = item[subField];

	const [isCollapsed, setIsCollapsed] = useState(false);
	const toggleCollapse = () => setIsCollapsed(!isCollapsed);
	const collapsable = Boolean(sub?.length);

	return (
		<Draggable index={index} draggableId={value}>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
					<div className="filter__check__list-item">
						<GrDrag className="filter__check__list-item-drag" />

						<Radio size="small" style={{ padding: 0 }} value={value} />

						<label>
							{label}
							{editable && (
								<TbPencil
									className="shadow__box__action-item edit-action"
									onClick={() => onEdit(item)}
								/>
							)}
							{collapsable && (
								<GrFormUp
									className="shadow__box__action-item"
									style={{
										transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
										transition: 'all 0.3s',
									}}
									onClick={toggleCollapse}
								/>
							)}
						</label>
					</div>

					{collapsable && (
						<Collapse in={isCollapsed}>
							<ListRadio
								items={sub}
								deep={deep + 1}
								onChange={(value) => handleSubChange(value, index)}
							/>
						</Collapse>
					)}
				</div>
			)}
		</Draggable>
	);
}
