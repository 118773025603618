export const bookingList = [
    {
        id: 1,
        bookingId: 'B-001',
        start: new Date(2023, 1, 26, 10, 0, 0), // new Date(year, month, day, hours, minutes, seconds, milliseconds
        end: new Date(2023, 1, 26, 11, 0, 0),
        title: 'Booking 1',
        editable: true,
    },
    {
        id: 2,
        bookingId: 'B-002',
        start: new Date(2023, 1, 27, 10, 0, 0),
        end: new Date(2023, 1, 27, 11, 0, 0),
        title: 'Booking 2',
        editable: true,
    },
    {
        id: 3,
        bookingId: 'B-003',
        start: new Date(2023, 1, 28, 10, 0, 0),
        end: new Date(2023, 1, 28, 11, 0, 0),
        title: 'Booking 3',
        editable: true,
    },
];
