import React from 'react';

function NumberRangeFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
	const [min, max] = React.useMemo(() => {
		let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
		let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
		preFilteredRows.forEach((row) => {
			min = Math.min(row.values[id], min);
			max = Math.max(row.values[id], max);
		});
		return [min, max];
	}, [id, preFilteredRows]);

	const handleMinChange = (e) => {
		const val = parseInt(e.target.value, 10);
		if (!isNaN(val) && (isNaN(filterValue[1]) || val <= filterValue[1])) {
			setFilter((old = []) => [val, old[1]]);
		} else if (e.target.value === '' || isNaN(e.target.value)) {
			setFilter((old = []) => [undefined, old[1]]);
		}
	};

	const handleMaxChange = (e) => {
		const val = parseInt(e.target.value, 10);
		if (!isNaN(val)) {
			setFilter((old = []) => [old[0], val]);
		} else if (e.target.value === '' || isNaN(e.target.value)) {
			setFilter((old = []) => [old[0], undefined]);
		}
	};

	return (
		<div className="flex-start">
			<input
				value={filterValue[0] || ''}
				type="number"
				onChange={handleMinChange}
				placeholder={`Min (${min || ''})`}
				style={{
					width: '70px',
					marginRight: '0.5rem',
				}}
			/>
			-
			<input
				value={filterValue[1] || ''}
				type="number"
				onChange={handleMaxChange}
				placeholder={`Max (${max || ''})`}
				style={{
					width: '70px',
					marginLeft: '0.5rem',
				}}
			/>
		</div>
	);
}

export default NumberRangeFilter;
