import config, {
	facebook_page_id,
	facebook_app_id,
	facebook_greeting,
	mainColor,
	GOOGLE_RECAPTCHA_SITE_KEY,
	USE_HASH_ROUTER,
} from './config.js';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import facebook from 'flexbiz-core/components/social/facebook';
import google from 'flexbiz-core/components/social/google';
import { BrowserRouter, HashRouter, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import SysForms from './src/apps/pos/components/SysForms';
import { Toaster } from 'react-hot-toast';
import Sidebar from './src/apps/pos/SideBar.js';
import routes from './routes.js';
import AuthContext from 'flexbiz-core/components/auth/Context';
import ListBooking from './src/apps/pos/ListBooking/ListBooking.jsx';
import ListCustomer from './src/apps/pos/ListCustomer/ListCustomer.jsx';
import SaleByEmployee from './src/apps/pos/ListReport/SaleByEmployee/SaleByEmployee.jsx';
import Home from './src/apps/pos/Home.js';
import App from 'flexbiz-core';
import { render } from 'preact/compat';

//disabled all console info
console.info = function () {};
//
//cachedData.data = window.__data__;
class ClientApp extends React.Component {
	render() {
		if (!routes) return null;
		let Router;
		if (USE_HASH_ROUTER) {
			Router = HashRouter;
		} else {
			Router = BrowserRouter;
		}
		return (
			<Router>
				<div
					style={{
						display: 'flex',
					}}
					id="wrapper"
				></div>
				<Toaster toastOptions={{ position: 'top-right' }} />
				<Sidebar />
				<App config={config} routes={routes} sysForms={SysForms} />
				{/* {!!facebook_app_id && <div id="fb-root"></div>}
				{facebook_page_id && facebook_app_id ? (
					<div
						className="fb-customerchat"
						attribution="setup_tool"
						page_id={facebook_page_id}
						theme_color={mainColor}
						logged_in_greeting={facebook_greeting}
						logged_out_greeting={facebook_greeting}
					></div>
				) : null} */}
			</Router>
		);
	}
}
ClientApp.contextType = AuthContext;

//ReactDOM.hydrate(<ClientApp />, document.getElementById('root'));
ReactDOM.render(<ClientApp />, document.getElementById('root'));
