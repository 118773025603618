import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, Tooltip, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AiFillPrinter, AiOutlinePlusCircle } from 'react-icons/ai';
import { TiArrowLeftThick } from 'react-icons/ti';
import { MdOutlineLocationOn } from 'react-icons/md';
import { BiCaretRightCircle, BiCaretLeftCircle } from 'react-icons/bi';
import { BsUpcScan, BsArrowRepeat } from 'react-icons/bs';
import { SaleContext } from '../SaleContainer';
import { cloneDeep } from 'lodash';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import SearchProductFast from './SearchProductFast';
import BillItem from './BillItem';
import Scanner from './Scanner';
import SearchProductNormal from './SearchProductNormal';
import ReturnDialog from './ReturnDialog';
import { useCore } from '../../../../../hooks';

const SaleHeaderStyled = styled(Paper)`
	width: 100%;
	height: 42px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	padding: 0 4px;
	flex-shrink: 0;
`;
const SearchWrapper = styled(Box)`
	height: 34px;
	border-radius: 6px;
	display: flex;
	align-items: center;
`;

const ScanQRCode = styled(Box)`
	margin-left: 10px;
	background-color: ${(props) => props.bgColor};
	color: #fff;
	width: 34px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	outline: ${(props) => (props.active ? '2px solid #000' : '')};
	cursor: pointer;
`;
const AddBill = styled(Box)`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.color};
	margin-left: 20px;
	cursor: pointer;
`;
const ButtonDirectBill = styled(Box)`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.color};
	cursor: pointer;
`;
const ListBillWrapper = styled(Box)`
	width: 310px;
	height: 34px;
	overflow: auto;
	margin-left: 5px;
`;
const ListBill = styled(Box)`
	position: relative;
	width: fit-content;
	height: 34px;
	display: flex;
	align-items: center;
	gap: 5px;
`;

const SaleActions = styled(Box)`
	margin-left: auto;
	height: 34px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SaleActionIcon = styled(Box)`
	width: 34px;
	height: 34px;
	border-radius: 6px;
	color: #333;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
const SaleAgency = styled(Box)`
	height: 34px;
	display: flex;
	align-items: center;
	gap: 2px;
	background-color: #ededed;
	border-radius: 6px;
	padding: 4px;
	cursor: pointer;
`;
const SaleButtonIcon = styled(Box)`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SaleButtonText = styled(Typography)`
	font-size: 13px;
`;

function SaleHeader() {
	const theme = useTheme();
	const {
		add,
		vouchers,
		indexVoucher,
		setIndexVoucher,
		kho,
		voucher,
		ma_kho,
		ma_ban,
		saleMode,
		print,
		handleSavePBL,
		getVoucher,
	} = useContext(SaleContext);
	const { context, asyncPostList, token } = useCore();
	const listBillRef = React.useRef();
	const [openScanner, setOpenScanner] = React.useState(false);
	const [openReturn, setOpenReturn] = React.useState(false);
	// handle add bill
	const handleAddBill = async () => {
		const voucher = {
			ma_kho: ma_kho,
			ma_ban: ma_ban,
			so_ct: 'PBL',
			trang_thai: '1',
			so_khach: 1,
			details: [],
			tien_ck_hd: 0,
			ty_le_ck_hd: 0,
			exfields: {
				dmckhd: [],
			},
		};
		let rs = await context.apis.asyncPostList(context.userInfo.token, 'pbl', voucher);
		await getVoucher();
		setIndexVoucher(0);
	};
	// handle select bill
	const handleSelectBill = (index) => {
		setIndexVoucher(index);
	};

	// handle delete bill
	const handleDeleteBill = async (index) => {
		// if (vouchers.length === 1) return;
		const voucherCopy = cloneDeep(voucher);
		if (voucherCopy.ma_ct.toLowerCase() === 'hd7') {
			voucherCopy.trang_thai = 'LOCK';
			await asyncPostList(token, 'hd7', voucherCopy);
			getVoucher();
		} else {
			voucherCopy.trang_thai = '9';
			await handleSavePBL(voucherCopy);

			if (index === 0) {
				setIndexVoucher(0);
			} else if (index === indexVoucher) {
				setIndexVoucher(index - 1);
			}
			getVoucher();
		}
	};

	// handle next bill
	const handleNextBill = () => {
		listBillRef.current.scrollLeft += 105;
	};
	// handle previous bill
	const handlePreviousBill = () => {
		listBillRef.current.scrollLeft -= 105;
	};
	// handle choose product
	const handleChooseProduct = async (product, number) => {
		await add(product, Number(number));
		await getVoucher();
	};

	return (
		<>
			{openReturn && <ReturnDialog open={openReturn} handleClose={() => setOpenReturn(false)} />}
			<SaleHeaderStyled bgColor={theme.palette.primary.main}>
				<SearchWrapper>
					{openScanner ? (
						<Scanner handleOpen={() => setOpenScanner(true)} handleClose={() => setOpenScanner(false)} />
					) : (
						<>
							{saleMode === 1 ? (
								<SearchProductNormal />
							) : (
								<SearchProductFast
									onChooseProduct={(product, number) => handleChooseProduct(product, number)}
								/>
							)}
						</>
					)}
					{/* {openScanner && <Scanner handleClose={() => setOpenScanner(false)} />} */}
					<ScanQRCode
						active={openScanner}
						onClick={() => setOpenScanner(true)}
						bgColor={theme.palette.primary.main}
					>
						<BsUpcScan fontSize="16px" />
					</ScanQRCode>
				</SearchWrapper>
				<Tooltip arrow placement="top" title="Thêm hóa đơn">
					<AddBill color={theme.palette.primary.main} onClick={handleAddBill}>
						<AiOutlinePlusCircle fontSize="18px" />
					</AddBill>
				</Tooltip>
				{vouchers.length > 3 && (
					<ButtonDirectBill color={theme.palette.primary.main} onClick={handlePreviousBill}>
						<BiCaretLeftCircle fontSize="18px" />
					</ButtonDirectBill>
				)}
				<ListBillWrapper ref={listBillRef} className="hidden-scroll-y">
					<ListBill>
						{vouchers &&
							vouchers.length > 0 &&
							vouchers.map((voucher, index) => (
								<BillItem
									key={voucher._id}
									index={index}
									textDisplay={`${voucher.ma_ct === 'HD7' ? 'Trả hàng' : 'Hóa đơn'} ${index + 1}`}
									onSelectBill={handleSelectBill}
									handleDeleteBill={handleDeleteBill}
								/>
							))}
					</ListBill>
				</ListBillWrapper>
				{vouchers.length > 3 && (
					<ButtonDirectBill color={theme.palette.primary.main} onClick={handleNextBill}>
						<BiCaretRightCircle fontSize="18px" />
					</ButtonDirectBill>
				)}
				<SaleActions>
					<SaleActionIcon onClick={() => setOpenReturn(true)}>
						<TiArrowLeftThick fontSize="18px" />
					</SaleActionIcon>
					<SaleActionIcon>
						<BsArrowRepeat fontSize="18px" />
					</SaleActionIcon>
					<SaleActionIcon onClick={() => print('2')}>
						<AiFillPrinter fontSize="18px" />
					</SaleActionIcon>
					<SaleAgency>
						<SaleButtonIcon>
							<MdOutlineLocationOn fontSize="16px" />
						</SaleButtonIcon>
						<SaleButtonText>{kho?.ten_kho}</SaleButtonText>
					</SaleAgency>
				</SaleActions>
			</SaleHeaderStyled>
		</>
	);
}

export default SaleHeader;
