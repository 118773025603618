import React, { useEffect, useState } from 'react';
import { useCore } from '../../../../../hooks';
import Dialog from '../../../components/Dialog/Dialog';
import TableManager from '../../../components/tableManager/TableManager';
import { formatDate } from '../../Utils';
import { useLocation } from "react-router-dom";
const columns = [
	{
		Header: 'Mã kho',
		accessor: 'ma_kho',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Mã vật tư',
		accessor: 'ma_vt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tên vật tư',
		accessor: 'ten_vt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Đơn vị tính',
		accessor: 'ma_dvt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Mã ngoại tệ',
		accessor: 'ma_nt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tỷ giá',
		accessor: 'ty_gia',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Số lượng nhập',
		accessor: 'sl_nhap_qd',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tiền nhập',
		accessor: 'tien_nhap_nt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Số lượng xuất',
		accessor: 'sl_xuat_qd',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tiền xuất',
		accessor: 'tien_xuat_nt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Mã khách/ncc',
		accessor: 'ma_kh',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Bộ phận',
		accessor: 'ma_bp',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Vụ việc',
		accessor: 'ma_dt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Hợp đồng',
		accessor: 'ma_hd',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Doanh thu/Chi phí',
		accessor: 'ma_cp',
		width: 250,
		minWidth: 200,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Mã sản phẩm',
		accessor: 'ma_sp',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
];

function ViewSoKho({ dataVoucher, open, handleClose }) {
	const core = useCore();
	const [isLoading, setIsLoading] = useState(false);
	const [isData, setIsData] = useState([]);
	const location = useLocation()
	const [path,setPath]=useState("")
	useEffect(() => {
		if (open) {
			getDataSoKho();
		}
		let pathname = location.pathname
		let index = pathname.lastIndexOf("/");
		setPath(pathname.slice(index+1, pathname.length))
	}, [open]);
	const skipResetRef = React.useRef(false);

	const getDataSoKho = async () => {
		try {
			setIsLoading(true);
			const [data] = await Promise.all([
				core.apiCall({
					endpoint: `${path}/sokho/${dataVoucher._id}`,
					// ...params,
					header: {
						'X-Access-Token': core.token,
					},
				}),
			]);
			setIsData(data);
		} catch (error) {
			console.log('getDataProduct error', error);
		} finally {
			core.setProgressStatus(false);
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			loading={isLoading}
			title={`Xem Sổ kho phiếu nhập ${dataVoucher.so_ct} ngày ${formatDate(new Date(dataVoucher.ngay_ct))}`}
			position="center"
			classNameContent={'dialog__content_edit_voucher_body'}
			width={'90vw'}
		>
			{isData.data && isData.data.length > 0 ? (
				<TableManager
					idTable={'tableSoCaiPN'}
					columns={columns}
					data={isData.data || []}
					skipReset={skipResetRef.current}
					settingRow={false}
					selectRow={false}
					readonly={true}
					fixedHeadercrollHeight={255}
					pagination={false}
				/>
			) : (
				<div>Không có dữ liệu</div>
			)}
		</Dialog>
	);
}

export default ViewSoKho;
