import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { GrFormUp } from 'react-icons/gr';

const useStyles = makeStyles(() => ({
	root: {
		borderRadius: 8,
		border: '1px solid #e0e0e0',
		fontSize: 14,
	},
	header: {
		fontWeight: 'bold',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: '#f8f9fa',
		padding: 8,
		borderRadius: 8,

		'&:hover': {
			cursor: 'pointer',
		},
	},
	content: {
		padding: 8,
	},
}));

export default function InputContainer({ title, children, collapsable = true, defaultShow = false, style }) {
	const classes = useStyles();
	const [isShow, setIsShow] = useState(defaultShow);
	const toggleShow = () => (collapsable ? setIsShow(!isShow) : null);

	return (
		<div className={classes.root} style={style}>
			<div
				className={classes.header}
				onClick={toggleShow}
				style={{
					transition: 'all 0.3s',
					borderBottomLeftRadius: isShow ? 0 : 8,
					borderBottomRightRadius: isShow ? 0 : 8,
					borderBottom: isShow ? '1px solid #e0e0e0' : 'none',
				}}
			>
				<p>{title}</p>

				{collapsable && (
					<GrFormUp
						style={{
							transition: 'all 0.3s',
							transform: isShow ? 'rotate(0deg)' : 'rotate(180deg)',
						}}
					/>
				)}
			</div>

			{collapsable ? (
				<Collapse in={isShow} mountOnEnter>
					<div className={classes.content}>{children}</div>
				</Collapse>
			) : (
				<div className={classes.content}>{children}</div>
			)}
		</div>
	);
}
