import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import React from 'react';
import { HiOutlineArrowPath } from 'react-icons/hi2';
import VButton from '../../VComponent/VButton';

const searchFields = ['ma_kh', 'ten_kh', 'so_ct', 'dien_giai'];
export default function VoucherFilter({ onFilter, title }) {
	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		const q = { $or: [] };

		// search
		const search = values.search?.trim() || '';
		searchFields.forEach((field) => {
			q.$or.push({ [field]: { $regex: search, $options: 'i' } });
		});

		// handle filter
		onFilter(q);
	};

	const handleReset = () => {
		form.resetFields();
		form.submit();
	};

	return (
		<div className="filter_container">
			<div className="filter_title">
				<span>{title}</span>

				<Tooltip title="Reset filter">
					<VButton type="text" icon={<HiOutlineArrowPath />} onClick={handleReset} />
				</Tooltip>
			</div>

			{/* Form */}
			<Form layout="vertical" form={form} onFinish={handleSubmit}>
				<Form.Item label="Search" name="search">
					<Input placeholder="Search by id, name, email, phone,..." prefix={<SearchOutlined />} />
				</Form.Item>
			</Form>
		</div>
	);
}
