import React from 'react';
import FilterSearch from '../components/filter/FilterSearch';

const searchFields = ['ma_vt', 'ten_vt', 'ma_lo', 'ten_lo'];
export default function ParcelFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		search: '',
	});

	React.useEffect(() => {
		const q = { $and: [] };

		if (filter.search)
			q.$and.push({ $or: searchFields.map((field) => ({ [field]: { $regex: filter.search, $options: 'i' } })) });

		onChange(q);
	}, [filter]);

	const onValueChange = React.useCallback((field) => (value) => setFilter({ ...filter, [field]: value }), [filter]);

	return (
		<div className="product__filter">
			{/* Search box */}
			<FilterSearch onSearch={onValueChange('search')} />
		</div>
	);
}
