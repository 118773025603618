import React from "react";
import DrawerBase from "../../../drawer/DrawerBase";
import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DefaultContext from "flexbiz-core/components/auth/Context";
import FilterProduct from "./FilterProduct";
import ButtonBase from "../../components/button/ButtonBase";

const FilterWrapper = styled(Box)`
  width: 250px;
  height: 100%;
  border-radius: 10px 0 0 10px;
`;
const FilterBody = styled(Box)`
  height: calc(100% - 54px);
  overflow: auto;
  padding: 20px 10px;
`;
const TitleFilter = styled(Typography)`
  font-size: 14px;
  font-weight: 550;
  margin-bottom: 20px;
`;
const FilterBottom = styled(Box)`
  height: 54px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  padding-left: ${(props) => props.paddingLeft};
`;
const ButtonClear = styled(Box)`
  padding: 10px;
  width: fit-content;
  height: 34px;
  color: #fff;
  font-size: 13px;
  font-weight: 550;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 6px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

function DrawerFilerProduct({
  anchor,
  open,
  handleClose,
  maNvt,
  setMaNvt,
  ...props
}) {
  const context = React.useContext(DefaultContext);
  const theme = useTheme();
  const [groups, setGroups] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState([]);

  const handleValueChange = (items) => {
    if (items.length === 0) {
      setMaNvt("");
    } else {
      setMaNvt(items);
    }
  };
  // handle checked
  const handleChecked = (e) => {
    const value = e.target.value;
    let checkedItemsCopy = [...checkedItems];
    const isExist = checkedItemsCopy.includes(value);
    if (isExist) {
      checkedItemsCopy = checkedItemsCopy.filter((item) => item !== value);
    } else {
      checkedItemsCopy.push(value);
    }
    setCheckedItems(checkedItemsCopy);
  };
  const loadProductGroups = async () => {
    context.setProgressStatus(true);
    let condition = {};
    condition.status = true;
    condition.la_nhom_hang_hoa = true;
    try {
      let rows = await context.apis.asyncGetList(
        context.userInfo.token,
        "dmnvt",
        condition
      );
      context.setProgressStatus(false);
      setGroups(rows);
    } catch (e) {
      context.alert(e.message);
    }
  };
  // handle clear
  const handleClear = () => {
    if (!maNvt || maNvt.length === 0) {
      return;
    }
    handleValueChange([]);
    setCheckedItems([]);
    handleClose();
  };
  const parentGroups = React.useMemo(() => {
    if (groups.length > 0) {
      return groups.filter((g) => !g.nh_me);
    } else {
      return [];
    }
  }, [groups]);
  React.useEffect(() => {
    handleValueChange(checkedItems);
  }, [checkedItems]);
  React.useEffect(() => {
    loadProductGroups();
  }, [context]);

  return (
    <DrawerBase
      anchor={anchor}
      open={open}
      handleClose={handleClose}
      PaperProps={{ style: { borderRadius: "20px 0 0 20px" } }}
      {...props}
    >
      <FilterWrapper>
        <FilterBody>
          <TitleFilter>Nhóm sản phẩm</TitleFilter>
          {parentGroups &&
            parentGroups.length > 0 &&
            parentGroups.map((g) => (
              <FilterProduct
                key={g._id}
                group={g}
                groups={groups}
                checkedItems={checkedItems}
                handleChecked={handleChecked}
              />
            ))}
        </FilterBody>
        <FilterBottom>
          <ButtonBase
            disabled={!maNvt || maNvt.length === 0}
            onClick={handleClear}
            variant="contained"
          >
            Xóa bỏ
          </ButtonBase>
        </FilterBottom>
      </FilterWrapper>
    </DrawerBase>
  );
}

export default DrawerFilerProduct;
