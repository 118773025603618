import React, { useState } from 'react';
import Layout from '../../components/LayoutContainer';
import Container from '../../Container';
import { formatCurrency } from '../../Utils/MyUtils';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import SaleByEmployeeData from './SaleByEmployeeData';
import SaleByEmployeeFilter from './SaleByEmployeeFilter';

function SaleByEmployeeContent({ props }) {
    const context = React.useContext(DefaultContext);
    console.log('context', context.config.id_app);
    const [data, setData] = useState([
        {
            id: 1,
            ma_nv: 'NV0000001',
            ten_nv: 'Nguyễn Văn A',
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            t_pk: 0,
        },
        {
            id: 2,
            ma_nv: 'NV0000001',
            ten_nv: 'Nguyễn Văn B',
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            t_pk: 0,
        },
        {
            id: 2,
            ma_nv: 'NV0000001',
            ten_nv: 'Nguyễn Văn C',
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            t_pk: 0,
        },
        {
            id: 3,
            ma_nv: 'NV0000001',
            ten_nv: 'Nguyễn Văn D',
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            t_pk: 0,
        },
        {
            id: 4,
            ma_nv: 'NV0000001',
            ten_nv: 'Nguyễn Văn E',
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            t_pk: 0,
        },
    ]);
    const onFilter = (data) => {
        console.log(data);
    };

    const columns = [
        {
            name: 'Mã nhân viên',
            selector: (row) => row.ma_nv,
        },
        {
            name: 'Tên nhân viên',
            selector: (row) => row.ten_nv,
        },
        {
            name: 'Tiền hàng (VNĐ)',
            selector: (row) => formatCurrency(row.t_tien),
        },
        {
            name: 'Phí khác (VNĐ)',
            selector: (row) => formatCurrency(row.t_pk),
        },

        {
            name: 'Giảm giá (VNĐ)',
            selector: (row) => formatCurrency(row.t_ck),
        },
        {
            name: 'Doanh thu (VNĐ)',
            selector: (row) => formatCurrency(row.t_tt),
        },
    ];
    return (
        <Layout FilterLeft={<SaleByEmployeeFilter onFilter={onFilter} />}>
            <SaleByEmployeeData data={data} columns={columns} />
        </Layout>
    );
}

const SaleByEmployee = (props) => {
    return (
        <Container {...props} showDrawerIfIsDesktop={false}>
            <SaleByEmployeeContent />
        </Container>
    );
};

export default SaleByEmployee;
