import React from "react";
import styled from "styled-components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsInfoCircle, BsPencil } from "react-icons/bs";
import { Box, Menu, MenuItem } from "@material-ui/core";
import Dialog from "../../../Dialog/Dialog";
import NoteDialog from "./NoteDialog";
import DrawerBase from "../../../drawer/DrawerBase";
import DrawerDetailProduct from "./DrawerDetailProduct";

const ProductOptionsStyled = styled(Box)`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all linear 0.1s;
  margin-left: 10px;
  color: #999;
  &:hover {
    background-color: #ededed;
  }
`;
const MenuItemStyled = styled(MenuItem)`
  font-size: 13px;
`;

function ProductOption({
  product,
  details,
  handleBillChange,
  add,
  number,
  handleNumberChange,
  handleOrderChanged,
}) {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [openNote, setOpenNote] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);

  // handle close note
  const handleCloseNote = () => {
    setOpenNote(false);
  };
  // handle close detail
  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  return (
    <>
      {/* dialog note start */}
      {openNote && (
        <Dialog open={openNote} handleClose={handleCloseNote} title="Ghi chú">
          <NoteDialog
            details={details}
            handleBillChange={handleBillChange}
            product={product}
            handleClose={handleCloseNote}
          />
        </Dialog>
      )}
      {/* dialog note end */}
      {/* drawer detail start */}
      <DrawerDetailProduct
        open={openDetail}
        handleClose={handleCloseDetail}
        product={product}
        add={add}
        number={number}
        handleNumberChange={handleNumberChange}
        handleOrderChanged={handleOrderChanged}
      />
      {/* drawer detail end */}
      <DrawerBase></DrawerBase>
      <ProductOptionsStyled onClick={(e) => setAnchorMenu(e.currentTarget)}>
        <BiDotsVerticalRounded fontSize="16px" />
      </ProductOptionsStyled>
      <Menu
        disablePortal
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={() => setAnchorMenu(null)}
      >
        <MenuItemStyled onClick={() => setOpenNote(true)}>
          <BsPencil style={{ marginRight: "10px" }} fontSize="12px" /> Ghi chú
        </MenuItemStyled>
        <MenuItemStyled onClick={() => setOpenDetail(true)}>
          <BsInfoCircle style={{ marginRight: "10px" }} fontSize="12px" />
          Xem chi tiết
        </MenuItemStyled>
      </Menu>
    </>
  );
}

export default ProductOption;
