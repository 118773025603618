import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import UnitConversionFilter from './UnitConversionFilter';
import FormUnitConversionDialog from './dialog/FormUnitConversionDialog';

const ListUnit = () => {
	const columns = [
		{
			name: 'Vật tư',
			selector: (row) => [row.ma_vt, row.ten_vt].join(' - '),
		},
		{
			name: 'Đơn vị tính',
			selector: (row) => row.ten_dvt,
			width: '200px',
		},
		{
			name: 'Quy đổi',
			selector: (row) => [row.tu || 1, row.mau || 1].join('/'),
			width: '200px',
			center: true,
		},
	];

	return (
		<ListBase
			api="dmqddvt"
			title="Danh sách quy đổi đơn vị tính"
			columns={columns}
			FilterComponent={UnitConversionFilter}
			FormDialog={FormUnitConversionDialog}
		/>
	);
};

export default withContainer(ListUnit);
