import {
	IconButton,
	Menu,
	MenuItem,
	Select,
	withStyles,
} from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState, useRef } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoEllipsisVerticalCircle } from "react-icons/io5";
import { MdRemoveCircleOutline } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";
import SelectAntd from "react-select";
import DialogAddTime from "./BillSpaDialog/DialogAddTime";
import DialogNote from "./BillSpaDialog/DialogNote";
import Confirm from "../../../components/newSale/components/confirm/Confirm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 100,
		},
	},
};

const optionsRoom = [
	{
		label: "Phòng A",
		value: "a",
	},
	{
		label: "Phòng B",
		value: "b",
	},
	{
		label: "Phòng C",
		value: "c",
	},
	{
		label: "Phòng D",
		value: "d",
	},
	{
		label: "Phòng E",
		value: "e",
	},
	{
		label: "Phòng F",
		value: "f",
	},
];

const optionsStaff = [
	{
		label: "Thanh Thuy",
		value: "thuy",
	},
	{
		label: "Thanh Nga",
		value: "nga",
	},
	{
		label: "Thanh Duy",
		value: "duy",
	},
];

const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		boxShadow: "0px 0px 12px rgb(0 0 0 / 0%)",
		borderRadius: 5,
		border: "1px solid #ccc",
		cursor: "pointer",
		minWidth: 150,
		"&:hover": {
			border: "1px solid #279656",
			boxShadow: "0px 0px 12px rgb(39 150 86 / 40%)",
		},
	}),
};

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		fontSize: 13,
		color: "#000",
		backgroundColor: "white",
		"&:hover": {
			backgroundColor: "#e9f4ee",
			color: "#279656",
		},
	},
}))(MenuItem);

function SpaItemsService({ data, index, add }) {
	const confirmRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [isDialogNote, setIsDialogNote] = useState(false);
	const [isDialogAddTime, setIsDialogAddTime] = useState(false);
	const [selectedRoom, setSelectedRoom] = useState([]);
	const [selectedStaff, setSelectedStaff] = useState([]);
	const [isTime, setIsTime] = useState(data.time);
	const [startHour, setStartHour] = useState(data.startTime);
	const [endHour, setEndHour] = useState(data.endTime);
	const hours = [...Array(24).keys()].map((i) => (i < 10 ? `0${i}` : i));
	const minutes = ["00", "15", "30", "45"];

	const [isQuantity, setIsQuantity] = useState(data.sl_order);

	const handleStartHourChange = (event) => {
		const selectedHour = event.target.value;
		setStartHour(selectedHour);
		if (selectedHour && endHour && selectedHour >= endHour) {
			setEndHour(null);
		}
	};

	const handleEndHourChange = (event) => {
		const selectedHour = event.target.value;
		setEndHour(selectedHour);
		if (selectedHour && startHour && selectedHour <= startHour) {
			setStartHour(null);
		}
	};

	const getHoursAfter = (hour, minute) => {
		let result = [];
		let currentHour = parseInt(hour, 10);
		let currentMinute = parseInt(minute, 10);
		while (currentHour < 24) {
			result.push(
				`${currentHour < 10 ? `0${currentHour}` : currentHour}:${
					currentMinute < 10 ? `0${currentMinute}` : currentMinute
				}`
			);
			currentMinute += 15;
			if (currentMinute === 60) {
				currentMinute = 0;
				currentHour += 1;
			}
		}
		return result;
	};

	const endHours = startHour
		? getHoursAfter(startHour.slice(0, 2), startHour.slice(3))
		: [];

	useEffect(() => {
		if (endHour && startHour && endHour <= startHour) {
			setEndHour(null);
			setIsTime(null);
		}

		if (startHour && endHour && startHour >= endHour) {
			setStartHour(null);
			setIsTime(null);
		}

		if (startHour && endHour && startHour < endHour) {
			// Tính thời gian giữa 2 khung giờ
			const startHourNumber = parseInt(startHour.slice(0, 2), 10);
			const startMinuteNumber = parseInt(startHour.slice(3), 10);
			const endHourNumber = parseInt(endHour.slice(0, 2), 10);
			const endMinuteNumber = parseInt(endHour.slice(3), 10);
			const totalMinutes =
				(endHourNumber - startHourNumber) * 60 +
				(endMinuteNumber - startMinuteNumber);

			const hours = Math.floor(totalMinutes / 60);
			const minutes = totalMinutes % 60;

			const formattedTime = `${hours
				.toString()
				.padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

			setIsTime(formattedTime);
		}
	}, [startHour, endHour]);

	const handleOpenDialogAddTime = () => {
		setIsDialogAddTime(true);
	};

	const handleCloseDialogAddTime = () => {
		setIsDialogAddTime(false);
	};

	const handleOpenDialogNote = () => {
		setIsDialogNote(true);
	};

	const handleCloseDialogNote = () => {
		setIsDialogNote(false);
	};

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const numberCompare = React.useMemo(() => {
		return data.sl_order;
	}, [data]);

	const setItem = (p, sl_order) => {
		add(p, sl_order - (p.sl_order || 0));
	};

	const handleNumberChange = (e) => {
		const value = e.target.value;
		if (isNaN(value)) {
			return;
		} else {
			setIsQuantity(Number(value));
		}
	};
	const handleOrderChanged = (product) => {
		if (numberCompare === isQuantity) return;
		let numberProduct = isQuantity;
		if (numberProduct === 0) {
			numberProduct = 1;
		}
		add(product, numberProduct, false);
		// numberCompare = number;
	};
	useEffect(() => {
		if (data) {
			setIsQuantity(data.sl_order);
		}
	}, [data]);

	return (
		<>
			<div className="box_item_bill">
				<div className="item_bill mb-10">
					<div className="detail_info_bill">
						<IconButton
							size="small"
							onClick={confirmRef?.current?.open}
						>
							<AiFillDelete />
						</IconButton>
						<div className="item_id pt-3">{index + 1}.</div>
						<div className="item_bill__name pt-3">
							<span className="bill__name">
								{data.ten_vt || "SP004504"}
							</span>
							<span className="bill_note">
								{data.note || "Ghi chú"}
							</span>
						</div>
					</div>
					<div className="detail_price_bill">
						<div className="gr_quantity pt-3">
							<button>
								<MdRemoveCircleOutline
									onClick={() => add(data, -1)}
								/>
							</button>
							<input
								min="1"
								type="number"
								value={isQuantity}
								onChange={(e) => handleNumberChange(e)}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										handleOrderChanged(data);
									}
								}}
								onBlur={() => handleOrderChanged(data)}
							/>
							<button>
								<IoMdAddCircleOutline
									onClick={() => add(data, 1)}
								/>
							</button>
						</div>
						<div className="item_bill__price pt-3">
							<span className="price">{data.gia_ban}</span>
							<span className="price_sell">-200</span>
						</div>
						<div className="item_bill__total_price pt-3">
							{data.gia_ban * isQuantity}
						</div>
						<IconButton size="small">
							<RiAddFill />
						</IconButton>
						<IconButton size="small">
							<IoEllipsisVerticalCircle onClick={handleOpen} />
							<StyledMenu
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<StyledMenuItem
									onClick={() => {
										handleClose();
										handleOpenDialogNote();
									}}
								>
									Ghi chú
								</StyledMenuItem>
								<StyledMenuItem onClick={handleClose}>
									Tư vấn bán
								</StyledMenuItem>
							</StyledMenu>
						</IconButton>
					</div>
				</div>
				<div className="add_time_item_bill">
					{data.time ? (
						<div className="group_add_time">
							<IconButton style={{ padding: "3px" }}>
								<CloseIcon />
							</IconButton>
							{/* chọn các khung giờ */}
							<div className="group_clock">
								<Select
									value={startHour}
									onChange={handleStartHourChange}
									MenuProps={MenuProps}
									className="box_clock"
									IconComponent={() => (
										<AccessTime
											style={{
												width: "18px",
											}}
										/>
									)}
								>
									{hours.map((hour) =>
										minutes.map((minute) => (
											<MenuItem
												key={`${hour}:${minute}`}
												value={`${hour}:${minute}`}
											>
												{hour}:{minute}
											</MenuItem>
										))
									)}
								</Select>
								<span>đến</span>
								<Select
									value={endHour}
									onChange={handleEndHourChange}
									MenuProps={MenuProps}
									className="box_clock"
									IconComponent={() => (
										<AccessTime
											style={{
												width: "18px",
											}}
										/>
									)}
								>
									{endHours.map((hour) => (
										<MenuItem key={hour} value={hour}>
											{hour}
										</MenuItem>
									))}
								</Select>
								<span>{isTime}</span>
							</div>
							<SelectAntd
								placeholder="Chọn vị trí"
								options={optionsRoom}
								value={selectedRoom}
								onChange={setSelectedRoom}
								labelledBy="Select"
								searchable={true}
								styles={colourStyles} // bật tính năng tìm kiếm
							/>
							<SelectAntd
								placeholder="Chọn nhân viên"
								options={optionsStaff}
								value={selectedStaff}
								onChange={setSelectedStaff}
								labelledBy="Select"
								searchable={true}
								styles={colourStyles} // bật tính năng tìm kiếm
							/>
						</div>
					) : (
						<>
							<button
								className="btn_add"
								onClick={handleOpenDialogAddTime}
							>
								<IoMdAddCircleOutline
									style={{
										paddingBottom: "2px",
									}}
									size={16}
								/>{" "}
								<span>Thời gian sử dụng</span>
							</button>
						</>
					)}
				</div>
			</div>
			<DialogAddTime
				open={isDialogAddTime}
				handleClose={handleCloseDialogAddTime}
			/>
			<DialogNote
				open={isDialogNote}
				handleClose={handleCloseDialogNote}
			/>
			<Confirm
				ref={confirmRef}
				content={`Xác nhận xóa '${data.ten_vt}'.`}
				onConfirm={() => setItem(data, 0)}
			/>
		</>
	);
}

export default SpaItemsService;
