import React from 'react';
import styled from 'styled-components';
import { Box, Grid, Paper } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import BillSale from './bill/BillSale';
import ProductsSale from './products/ProductsSale';
import { SaleContext } from '../SaleContainer';
import Payment from '../footer/Payment';

const SaleMainStyled = styled(Box)`
	width: 100%;
	flex-grow: 1;
`;

function SaleMain() {
	const { saleMode, voucher, add, handleSavePBL, handleBillChange } = React.useContext(SaleContext);
	const isSmallScreen = useMediaQuery('(max-width: 960px)');

	return (
		<>
			<SaleMainStyled>
				{saleMode === 1 ? (
					<>
						<Grid container direction={isSmallScreen ? 'row-reverse' : 'row'} style={{ height: '100%' }}>
							<Grid item xs={12} md={6}>
								<ProductsSale
									voucher={voucher}
									handleSelectProduct={add}
									handleSavePBL={handleSavePBL}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<BillSale
									voucher={voucher}
									add={add}
									handleBillChange={handleBillChange}
									handleSavePBL={handleSavePBL}
								/>
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Grid container style={{ height: '100%' }}>
							<Grid item xs={7}>
								<BillSale
									voucher={voucher}
									add={add}
									handleBillChange={handleBillChange}
									handleSavePBL={handleSavePBL}
								/>
							</Grid>
							<Grid item xs={5}>
								<Paper
									style={{
										width: '100%',
										height: 'calc(100vh - 50px - 20px - 20px - 42px - 42px)',
									}}
								>
									<Payment />
								</Paper>
							</Grid>
						</Grid>
					</>
				)}
			</SaleMainStyled>
		</>
	);
}

export default SaleMain;
