import React from "react";

export default function Tab1({ name }) {
  return (
    <div>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
      <h4>{name}</h4>
    </div>
  );
}
