import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Grid, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Menu, Select, Progress } from 'antd';
import dayjs from 'dayjs';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';
import InputContainer from '../../components/Dialog/InputContainer';
import QuickEdit from '../QuickEdit/QuickEdit';
import Tab1 from '../TabMenu/Tab1';
import Tab2 from '../TabMenu/Tab2';
import { TaskContext } from '../Task';
import './TaskDialogDetail.css';
import FieldInput from '../../components/Dialog/FieldInput';
import SaveIcon from '@material-ui/icons/Save';
import SubTask from '../../components/subTask/SubTask';

export default function TaskDialogDetailApi({ data, idx, rowClick, open, setOpenDialogDetail }) {
	const [info, setInfo] = useState(rowClick);
	const [index, setIndex] = useState(idx);
	const [hoverMenu, setHoverMenu] = useState(false);
	const [tabActive, setTabActive] = useState('1');
	const [newSubTask, setNewSubTask] = useState();
	const [todos, setTodos] = useState(info.todos || []);
	const [subTaskName, setSubTaskName] = useState('');

	const [progress, setProgress] = useState(0);

	const classes = useStyles();

	const context = useContext(DefaultContext);
	const { getDataTask } = useContext(TaskContext);

	const hookForm = useForm({});

	const {
		getValues,
		setValue,
		register,
		formState: { errors },
		control,
		handleSubmit,
		reset,
		// formState: { isSubmitting },
	} = hookForm;

	useEffect(() => {
		reset({
			...info,
		});
	}, [info, reset]);

	const onSubmit = async (data) => {
		try {
			await context.apis.asyncPostList(context.userInfo.token, 'task', {
				...data,
			});

			await getDataTask();

			toast.success('Cập nhật thành công');
		} catch (error) {
			console.log(error);
			toast.success(error);
		}
	};

	const onSave = (data) =>
		onSubmit(data).then(() => {
			handleClose();
		});

	const updateProgress = (todos) => {
		let count = 0;

		todos?.map((item) => {
			if (item.sel) {
				count += 1;
			}
		});

		setProgress(Math.ceil((count / todos?.length) * 100));
	};

	// const convertSubTask = (subTask) => {
	// 	let result = { all: [], done: [] };

	// 	subTask &&
	// 		subTask.map((item) => {
	// 			result.all.push({ value: item.line, label: item.title });

	// 			item.sel && result.done.push(item.line);
	// 		});

	// 	return result;
	// };

	const handleUpdateTodos = async (data, todos, alert) => {
		let dataUpdate = { ...data, todos: todos };

		try {
			await context.apis.asyncPostList(context.userInfo.token, 'task', {
				...dataUpdate,
			});

			await getDataTask();

			toast.success(alert);
		} catch (error) {
			console.log(error);
			toast.error(error);
		}
	};

	const handleAddSubTask = async (newSTask) => {
		const line = Math.floor(Math.random() * 10000) + 1;

		let add = newSTask ? { line: line, title: newSTask, sel: false } : null;

		let newTodos = todos || [];

		add && newTodos.push(add);

		setTodos(newTodos);
		setNewSubTask('');

		if (add) {
			handleUpdateTodos(info, newTodos, 'Thêm việc phải làm thành công');
		} else {
			toast.error('Tên việc phải làm không được để trống');
		}
	};

	const handleChangeCheck = (checkedValues) => {
		let todosUpdate = todos;
		const id = todos.findIndex((todo) => todo.line === checkedValues);

		todosUpdate[id].sel = !todosUpdate[id].sel;

		handleUpdateTodos(info, todosUpdate, 'Cập nhật tiến độ thành công');
	};

	const handleDeleteSubTask = (checkedValues) => {
		let todosUpdate = todos;

		const id = todos.findIndex((todo) => todo.line === checkedValues);

		if (todosUpdate[id].sel) {
			toast.error('Không thể xóa do đã hoàn thành');
			return;
		} else {
			todosUpdate = todosUpdate.filter((todo) => todo.line !== checkedValues);
		}

		handleUpdateTodos(info, todosUpdate, 'Xóa việc phải làm thành công');
	};

	const handleEditSubTaskName = (checkedValues, title) => {
		let todosUpdate = todos;

		const id = todos.findIndex((todo) => todo.line === checkedValues);

		if (todosUpdate[id].sel) {
			toast.error('Không thể đổi tên do đã hoàn thành');
			return;
		} else {
			todosUpdate[id].title = title;
		}

		handleUpdateTodos(info, todosUpdate, 'Đổi tên việc phải làm thành công');
	};

	const handleClose = () => {
		setOpenDialogDetail(false);

		// getDataTask();
	};

	const changeTabMenu = (e) => {
		setTabActive(e.key);
	};

	const getMenuItem = (label, key, icon) => {
		return {
			label,
			key,
			icon: <i className={`far fa-${icon.toLowerCase()}`}></i>,
		};
	};

	const items = [
		getMenuItem('One view', '1', 'street-view'),
		getMenuItem('Chi tiết', '2', 'info-square'),
		getMenuItem('Phân tích', '3', 'analytics'),
		getMenuItem('Tài liệu', '4', 'file-alt'),
		getMenuItem('Timelogs', '5', 'history'),
		getMenuItem('Related task', '6', 'tasks'),
		getMenuItem('Sự kiện', '7', 'calendar'),
	];

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();

			handleAddSubTask(newSubTask);
		}
	};

	useEffect(() => {
		setTodos(info.todos);
	}, [info]);

	useEffect(() => {
		updateProgress(info.todos);
		setNewSubTask('');
	}, [info]);

	useEffect(() => {
		setInfo(data[index]);
	}, [data, index]);

	return (
		<Dialog open={open} keepMounted onClose={handleClose} className="task_dialog_detail">
			<DialogTitle id="alert-dialog-slide-title">
				<div className="task_dialog_detail__title">
					<div className="task_dialog_detail__title__left">
						<i
							className="far fa-arrow-left pointer"
							style={{ color: '#60b1fc', marginRight: '20px' }}
							onClick={() => {
								setOpenDialogDetail(false);
							}}
						></i>

						<i className="far fa-tasks me-1"></i>

						<div className="task_name">
							<input type="text" {...register('ten_cv')} />
						</div>
					</div>

					<div className="task_dialog_detail__title__right">
						<div className="stage_change" style={{ '--color': `${info.color}` }}>
							<button className="btn_taskType">
								<i className="far fa-stream" title="Task type"></i> Tasks
							</button>

							<div className="btn_taskStage">
								<Controller
									name="ten_trang_thai"
									control={control}
									rules={{ required: 'Vui lòng chọn trạng thái' }}
									render={({ field: { onChange, value } }) => {
										const item = value ? { value, label: getValues('ten_trang_thai') } : null;
										const handleChange = (item) => {
											onChange(item?.ten_trang_thai || null);
											setValue('progress', item.ma_trang_thai || '');
										};

										const query = { ma_ct: 'TASK' };

										return (
											<FieldSelectApi
												api="trangthai"
												q={query}
												withIdApp={false}
												valueField="ma_trang_thai"
												labelField="ten_trang_thai"
												value={item}
												onChange={handleChange}
												check
												errorMsg={errors.ten_trang_thai?.message}
											/>
										);
									}}
								/>
							</div>
						</div>

						{/* <div className="action_change">
							<i className="far fa-envelope me-1"></i>
							<i className="far fa-pencil me-1"></i>
							<i className="far fa-ellipsis-v pointer"></i>
						</div> */}

						<button
							className="btn btn-fill"
							style={{ background: '#0e475a' }}
							onClick={handleSubmit(onSave)}
						>
							<SaveIcon style={{ marginRight: '10px' }} />
							<div>Lưu</div>
						</button>
					</div>
				</div>
			</DialogTitle>

			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					<div className="task_dialog_detail__content">
						<div className="task_dialog_detail__content__left">
							<div className="box-content">
								<div className="info">
									<div className="field">
										<Controller
											name="ten_phu_trach"
											control={control}
											rules={{ required: 'Vui lòng chọn người phụ trách' }}
											render={({ field: { onChange, value } }) => {
												const item = value
													? { value, label: getValues('ten_phu_trach') }
													: null;
												const handleChange = (item) => {
													onChange(item?.email || null);
													setValue('phu_trach', item.email || '');
													setValue('ten_phu_trach', item.name || '');
												};

												return (
													<FieldSelectApi
														title="Phụ trách"
														placeholder="Chọn người phụ trách"
														api="participant"
														valueField="email"
														labelField="name"
														value={item}
														onChange={handleChange}
														check
														errorMsg={errors.ten_phu_trach?.message}
													/>
												);
											}}
										/>
									</div>

									<div className="field">
										<Grid className="dateTimePickerDetail" spacing={2}>
											<Grid xs={4} style={{ marginTop: '-6px' }}>
												<label>Ngày bắt đầu</label>
											</Grid>

											<Controller
												control={control}
												name="start_date"
												render={({ field: { onChange, value } }) => (
													<TextField
														id="datetime-local"
														type="datetime-local"
														defaultValue={value && dayjs(value).format('YYYY-MM-DD HH:mm')}
														className="fieldDateTime"
														onChange={onChange}
													/>
												)}
											/>
										</Grid>
									</div>

									<div className="field">
										<Grid className="dateTimePickerDetail" spacing={2}>
											<Grid xs={4} style={{ marginTop: '-6px' }}>
												<label>Ngày kết thúc</label>
											</Grid>

											<Controller
												control={control}
												name="due_date"
												render={({ field: { onChange, value } }) => (
													<TextField
														id="datetime-local"
														type="datetime-local"
														defaultValue={value && dayjs(value).format('YYYY-MM-DD HH:mm')}
														className="fieldDateTime"
														onChange={onChange}
													/>
												)}
											/>
										</Grid>
									</div>

									<div className="field">
										<Controller
											name="ten_nh_cv"
											control={control}
											render={({ field: { onChange, value } }) => {
												const item = value ? { value, label: getValues('ten_nh_cv') } : null;
												const handleChange = (item) => {
													onChange(item?.group_name || null);
													setValue('ten_nh_cv', item.group_name || '');
												};

												return (
													<FieldSelectApi
														title="Nhóm công việc"
														placeholder="Chọn nhóm công việc"
														api="dmnhtask"
														valueField="group_name"
														labelField="group_name"
														value={item}
														onChange={handleChange}
													/>
												);
											}}
										/>
									</div>

									<div className="field">
										<Controller
											name="ten_dt"
											control={control}
											render={({ field: { onChange, value } }) => {
												const item = value ? { value, label: getValues('ten_dt') } : null;
												const handleChange = (item) => {
													onChange(item?.value || null);
													setValue('ten_dt', item.value || '');
													setValue('ten_dt', item.label || '');
												};

												return (
													<FieldSelectApi
														title="Dự án"
														placeholder="Chọn dự án"
														api="dmdt"
														valueField="ten_dt"
														labelField="ten_dt"
														value={item}
														onChange={handleChange}
													/>
												);
											}}
										/>
									</div>

									<div className="field">
										<Controller
											name="ten_kh"
											control={control}
											render={({ field: { onChange, value } }) => {
												const item = value ? { value, label: getValues('ten_kh') } : null;
												const handleChange = (item) => {
													onChange(item?.value || null);
													setValue('ten_kh', item.value || '');
													setValue('ten_kh', item.label || '');
												};

												return (
													<FieldSelectApi
														title="Khách hàng"
														placeholder="Chọn khách hàng"
														api="customer"
														valueField="ten_kh"
														labelField="ten_kh"
														value={item}
														onChange={handleChange}
													/>
												);
											}}
										/>
									</div>

									<div className="field">
										<div className="field_viewOnly">
											<p>Người tạo</p>

											<input type="text" {...register('name_user_created')} readOnly />
										</div>
									</div>
								</div>
							</div>

							<div className={classes.root}>
								<InputContainer title="Mô tả" defaultShow>
									<Controller
										name="mieu_ta"
										control={control}
										render={({ field: { value, onChange } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={value || ''}
												onChange={(_event, editor) => {
													const data = editor.getData();
													onChange(data);
												}}
											/>
										)}
									/>
								</InputContainer>
							</div>

							<div className={classes.root}>
								<InputContainer title="Subtasks" defaultShow>
									{todos?.length > 0 && <Progress percent={progress} status="active" />}

									<div>
										{todos?.map((item) => {
											return (
												<SubTask
													key={item.line}
													data={item}
													onChange={() => handleChangeCheck(item.line)}
													onEdit={(todo) => handleEditSubTaskName(item.line, todo.title)}
													onDelete={() => handleDeleteSubTask(item.line)}
												/>
											);
										})}
									</div>

									{/* <Controller
										name="todos"
										control={control}
										render={() => (
											// <Checkbox.Group
											// 	style={{ display: 'flex', flexDirection: 'column' }}
											// 	options={subTask.all}
											// 	value={subTask.done}
											// 	onChange={handleChangeCheck}
											// />
										)}
									/> */}

									<div className="container_add_subtask" onKeyDown={onKeyDown}>
										<input
											type="text"
											placeholder="Thêm việc phải làm"
											value={newSubTask}
											onChange={(e) => {
												setNewSubTask(e.target.value);
											}}
										/>

										<i
											title="Thêm việc phải làm"
											className="fas fa-plus-square"
											onClick={() => {
												handleAddSubTask(newSubTask);
											}}
										></i>
									</div>
								</InputContainer>
							</div>

							<div className={classes.root}>
								<InputContainer title="Hoạt động" defaultShow>
									<Controller
										name="mieu_ta"
										control={control}
										render={() => (
											<div className="activity">
												<p>Not found</p>
											</div>
										)}
									/>
								</InputContainer>
							</div>
						</div>

						<div className="task_dialog_detail__content__right">
							<div className="main_content">
								{tabActive === '1' ? (
									<Tab1 name={items[0].label} />
								) : tabActive > 1 && tabActive <= items.length ? (
									<Tab2 name={items[tabActive - 1].label} />
								) : (
									<h4>Not Found</h4>
								)}
							</div>
						</div>

						<div
							className="task_dialog_detail__content__menu"
							onMouseEnter={() => {
								setHoverMenu(true);
							}}
							onMouseLeave={() => {
								setHoverMenu(false);
							}}
						>
							<Menu
								items={items}
								mode="inline"
								defaultSelectedKeys={['1']}
								onClick={changeTabMenu}
								style={{
									width: hoverMenu ? '200px' : '50px',
								}}
							/>
						</div>
					</div>

					{index > 0 ? (
						<div
							className="task_dialog_detail__arrow__left"
							onClick={() => {
								if (index > 0) {
									setIndex(index - 1);
								}
							}}
						>
							<i className="far fa-chevron-left"></i>
						</div>
					) : (
						<div className="task_dialog_detail__arrow__left"></div>
					)}

					{index < Object.keys(data).length - 1 ? (
						<div
							className="task_dialog_detail__arrow__right"
							onClick={() => {
								if (index < Object.keys(data).length - 1) {
									setIndex(index + 1);
								}
							}}
						>
							<i className="far fa-chevron-right"></i>
						</div>
					) : (
						<div className="task_dialog_detail__arrow__right"></div>
					)}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}

const useStyles = makeStyles(() => ({
	root: {
		marginTop: 16,
	},
	textarea: {
		width: '100%',
		height: 160,
		border: '1px dashed #e0e0e0',
		borderRadius: 8,
		padding: 8,
		outline: 'none',
		resize: 'none',
	},
}));
