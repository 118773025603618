import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutlineRounded, DeleteRounded } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import FieldInput from '../../../../components/Dialog/FieldInput';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import { useCore } from '../../../../../../hooks/useCore';

export default function InfoTab({ hookForm, subfix, isSupplier }) {
	const core = useCore();
	const classes = useStyles();
	const {
		getValues,
		register,
		formState: { errors },
		control,
		setValue,
	} = hookForm;

	const [image, setImage] = useState(core.getFileUrl(getValues('hinh_anh')));
	const inputImageRef = useRef(null);

	const handleInputImage = async (e) => {
		const file = e.target.files[0];
		if (!file) return; // no file selected

		setValue('imageFile', file); // for upload
		setImage(URL.createObjectURL(file)); // for preview
	};

	const handleImageBtnClick = () => {
		if (image) {
			setImage(null); // remove image
			setValue('imageFile', null); // remove imageFile
			setValue('hinh_anh', null); // remove hinh_anh
		} else {
			inputImageRef.current.click(); // open file dialog
		}
	};

	return (
		<Grid container>
			<Grid item xs="auto" className={classes.imageContainer}>
				<input type="file" ref={inputImageRef} onChange={handleInputImage} hidden accept="image/*" />
				<div
					className={classes.image}
					style={{
						backgroundImage: `url(${image})`,
					}}
				/>

				<IconButton
					className={classes.imageAction}
					onClick={handleImageBtnClick}
					color={image ? 'secondary' : 'primary'}
				>
					{image ? <DeleteRounded /> : <AddCircleOutlineRounded />}
				</IconButton>
			</Grid>

			<Grid item xs>
				<Grid container spacing={2}>
					<Grid item xs={6} className={classes.leftInput}>
						<FieldInput
							title={`Mã ${subfix}`}
							placeholder={`Mã ${subfix} tự động`}
							inputProps={{ ...register('ma_kh') }}
						/>

						<FieldInput
							title={`Tên ${subfix}`}
							placeholder={`Tên ${subfix}`}
							inputProps={{
								...register('ten_kh', {
									required: `Vui lòng nhập tên ${subfix}`,
								}),
							}}
							check
							errorMsg={errors.ten_kh?.message}
						/>

						<FieldInput
							title="Số điện thoại"
							placeholder="Số điện thoại"
							inputProps={{ ...register('dien_thoai') }}
						/>

						<FieldInput
							title="Ngày sinh"
							type="date"
							placeholder="Ngày sinh"
							inputProps={{ ...register('ngay_sinh') }}
						/>

						<FieldInput title="Địa chỉ" placeholder="Địa chỉ" inputProps={{ ...register('address') }} />

						<FieldInput title="Khu vực" placeholder="Khu vực" inputProps={{ ...register('khu_vuc') }} />

						<FieldInput
							title="Phường/Xã"
							placeholder="Phường/Xã"
							inputProps={{ ...register('xa_phuong') }}
						/>
					</Grid>

					<Grid item xs={6}>
						{/* TODO: thay đổi tên trường */}
						<FieldSelect
							title={`Loại ${subfix}`}
							placeholder={`Loại ${subfix}`}
							items={[
								{
									value: 'personal',
									label: 'Cá nhân',
								},
								{
									value: 'enterprise',
									label: 'Doanh nghiệp',
								},
							]}
						/>

						<FieldInput title="Chi nhánh" placeholder="Chi nhánh" inputProps={{ ...register('ma_kho') }} />

						<FieldInput
							title="Mã số thuế"
							placeholder="Mã số thuế"
							inputProps={{ ...register('ma_so_thue') }}
						/>

						{/* TODO: thay đổi tên trường */}
						<Controller
							name="gioi_tinh"
							control={control}
							render={({ field: { onChange, value } }) => (
								<FieldSelect
									title="Giới tính"
									placeholder="Giới tính"
									items={[
										{
											value: 'Nam',
											label: 'Nam',
										},
										{
											value: 'Nam',
											label: 'Nữ',
										},
									]}
									defaultValue={value}
									onChange={(item) => onChange(item.value)}
								/>
							)}
						/>

						<FieldInput
							title="Email"
							placeholder="Email"
							inputProps={{
								...register('email'),
							}}
						/>

						<Controller
							control={control}
							name="nh_kh"
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_nh_kh') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_nh_kh', item?.label || '');
								};

								return (
									<FieldSelectApi
										api="dmnhkh"
										q={{ isseller: isSupplier ? true : { $ne: true } }}
										title={`Nhóm ${subfix}`}
										placeholder={`Nhóm ${subfix}`}
										valueField="_id"
										labelField="group_name"
										defaultValue={item}
										onChange={handleChange}
									/>
								);
							}}
						/>

						<FieldInput title="Ghi chú" placeholder="Ghi chú" inputProps={{ ...register('dien_giai') }} />
					</Grid>

					<Grid item xs={6}>
						<Controller
							name="status"
							control={control}
							render={({ field: { onChange, value } }) => (
								<FormControlLabel
									label="Không hiển thị"
									control={<Checkbox size="small" />}
									checked={!value}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={6}>
						{!isSupplier && ( // Hide for supplier
							<Controller
								name="exfields.vang_lai"
								control={control}
								render={({ field: { onChange, value } }) => (
									<FormControlLabel
										label="Vãng lai"
										control={<Checkbox size="small" />}
										checked={!!value}
										onChange={onChange}
									/>
								)}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles(() => ({
	leftInput: {
		paddingRight: 16,
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 16,
		paddingRight: 16,
	},
	image: {
		width: '100px',
		height: '100px',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		aspectRatio: '1/1',
		borderRadius: 8,
		border: '1px dashed #ccc',
	},
	imageAction: {
		marginTop: 8,
	},
}));
