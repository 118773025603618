import React from 'react';
import styled from 'styled-components';
import { Box, MenuItem, Typography, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { SaleContext } from '../../SaleContainer';
import { cloneDeep } from 'lodash';
import { unicode2Ascii } from 'flexbiz-core/utils';
import { FaUserTag } from 'react-icons/fa';
import Dialog from '../../../Dialog/Dialog';

const Icon = styled(Box)`
	display: none;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin-left: 5px;
	color: #888;
	cursor: pointer;
	transition: all linear 0.1s;
	&:hover {
		background-color: #ededed;
	}
`;
const StaffContainer = styled(Box)`
	width: 100%;
`;
const SearchWrapper = styled(Box)`
	width: 100%;
	height: 44px;
	display: flex;
`;
const SearchInput = styled.input`
	border: none;
	outline: none;
	border: 1px solid #ccc;
	border-radius: 6px;
	font-size: 13px;
	width: 100%;
	height: 34px;
	padding: 0 10px;
`;
const ListStaff = styled(Box)`
	width: 100%;
	height: auto;
	max-height: 200px;
	overflow: auto;
`;
const StaffDisplay = styled(Typography)`
	font-size: 13px;
	font-weight: 550;
	margin-bottom: 5px;
`;
const StaffItem = styled(MenuItem)`
	width: 100%;
	font-size: 13px;
`;
const NoOptionText = styled(Typography)`
	font-size: 13px;
	font-style: italic;
	text-align: center;
	padding: 5px 0;
`;
const StaffInfo = styled(Box)`
	display: flex;
	flex-direction: column;
`;
const StaffInfoText = styled(Typography)`
	font-size: 13px;
	color: #fff;
	text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`;
const Actions = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
`;
const ButtonClear = styled(Box)`
	width: fit-content;
	padding: 8px;
	border-radius: 6px;
	color: #fff;
	font-size: 13px;
	font-weight: 550;
	background-color: ${(props) => props.bgColor};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

function SearchStaff({ detail, details, handleBillChange }) {
	const context = React.useContext(DefaultContext);
	const { getVoucher } = React.useContext(SaleContext);
	const timerRef = React.useRef();
	const theme = useTheme();

	const [openDialog, setOpenDialog] = React.useState(false);
	const [staffs, setStaffs] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [staff, setStaff] = React.useState(null);
	const [search, setSearch] = React.useState('');
	const [searchText, setSearchText] = React.useState('');

	// handle close dialog
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};
	const handleSelectStaff = async (staff) => {
		context.setProgressStatus(true);
		const detailsCopy = cloneDeep(details);
		const detailCopy = cloneDeep(detail);
		detailCopy.ma_nv = staff.ma_nv;
		const index = detailsCopy.findIndex((d) => d._id === detailCopy._id);
		if (index >= 0) {
			detailsCopy.splice(index, 1, detailCopy);
			await handleBillChange(detailsCopy);
			await getVoucher();
			setSearch('');
			setSearchText('');
			handleCloseDialog();
		}
		context.setProgressStatus(false);
	};

	// handle delete staff
	const handleDeleteStaff = () => {
		handleSelectStaff({ ma_nv: '' });
	};
	// get staffs
	const getStaffs = async () => {
		try {
			let condition = {};
			if (search) {
				condition = {
					$or: [],
				};
				//
				condition.$or.push({
					ma_nv: { $regex: search, $options: 'i' },
				});
				condition.$or.push({
					ten_nv: { $regex: search, $options: 'i' },
				});
				let $search = await unicode2Ascii(search);
				condition.$or.push({
					$text: { $search: search + ' ' + $search },
				});
			}
			const resp = await context.apis.asyncGetList(context.userInfo.token, 'dmnv', {
				page,
				limit: 20,
				condition,
			});
			if (resp?.length > 0) {
				if (staff) {
					setStaffs(resp.filter((st) => st.ma_nv !== staff.ma_nv));
				} else {
					setStaffs(resp);
				}
			} else {
				setStaffs([]);
			}
		} catch (error) {
			context.alert(error);
		}
	};
	// get staff of detail
	const getStaffOfDetail = async () => {
		try {
			const condition = { ma_nv: detail.ma_nv };
			const resp = await context.apis.asyncGetData(context?.userInfo?.token, 'dmnv', condition);
			if (resp) {
				setStaff(resp);
			}
		} catch (error) {
			context.alert(error);
		}
	};

	React.useEffect(() => {
		if (context) {
			getStaffs();
		}
	}, [context, page, search]);
	React.useEffect(() => {
		if (detail?.ma_nv) {
			getStaffOfDetail();
		} else {
			setStaff(null);
		}
	}, [detail, context]);
	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);

	return (
		<>
			<Tooltip
				arrow
				placement="top"
				title={
					staff ? (
						<StaffInfo>
							{staff.ten_nv && <StaffInfoText>Tên: {staff.ten_nv}</StaffInfoText>}
							{staff.ma_nv && <StaffInfoText>Mã: {staff.ma_nv}</StaffInfoText>}
							{staff.email && <StaffInfoText>Emai: {staff.email}</StaffInfoText>}
						</StaffInfo>
					) : (
						'Chọn nhân viên'
					)
				}
			>
				<Icon onClick={() => setOpenDialog(true)} className="ton-kho-icon">
					<FaUserTag fontSize="18px" />
				</Icon>
			</Tooltip>
			{openDialog && (
				<Dialog
					title="Thêm nhân viên"
					open={openDialog}
					handleClose={handleCloseDialog}
					width="600px"
					actions={[
						<Actions>
							{staff && (
								<ButtonClear bgColor={theme.palette.primary.main} onClick={handleDeleteStaff}>
									Xóa Bỏ
								</ButtonClear>
							)}
						</Actions>,
					]}
				>
					<StaffContainer>
						{staff && (
							<StaffDisplay>
								{staff.ten_nv} {staff.email && `- ${staff.email}`}
							</StaffDisplay>
						)}
						<SearchWrapper>
							<SearchInput
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								placeholder="Tìm nhân viên"
							/>
						</SearchWrapper>

						<ListStaff>
							{staffs.length === 0 ? (
								<NoOptionText>Không có nhân viên</NoOptionText>
							) : (
								<>
									{staffs.map((st) => (
										<StaffItem key={st.ma_nv} onClick={() => handleSelectStaff(st)}>
											{st.ten_nv} {st.email && `- ${st.email}`}
										</StaffItem>
									))}
								</>
							)}
						</ListStaff>
					</StaffContainer>
				</Dialog>
			)}
		</>
	);
}

export default SearchStaff;
