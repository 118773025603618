import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldBox from '../../components/Dialog/FieldBox';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldNumberInput from '../../components/Dialog/FieldNumberInput';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';

export default function FormUnitConversionDialog({ open, onClose, data, onSubmit }) {
	const {
		handleSubmit,
		reset,
		formState: { errors },
		control,
		getValues,
		setValue,
		watch,
		register,
	} = useForm();

	useEffect(() => {
		if (open) reset(data || {});
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa ĐVT quy đổi' : 'Thêm ĐVT quy đổi'}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose}>
					Hủy
				</button>,
			]}
			width={600}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Controller
						control={control}
						name="ma_vt"
						rules={{ required: 'Vui lòng chọn vật tư' }}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_vt') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ten_vt', item?.label || null);
								setValue('ma_dvt_goc', item?.ma_dvt || null);
							};

							return (
								<FieldSelectApi
									api="dmvt"
									title="Hàng hóa/vật tư"
									placeholder="Chọn hàng hóa/vật tư"
									valueField="ma_vt"
									labelField="ten_vt"
									value={item}
									onChange={handleChange}
									check
									errorMsg={errors.ma_vt?.message}
								/>
							);
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldInput title="ĐVT tiêu chuẩn" inputProps={{ readOnly: true, ...register('ma_dvt_goc') }} />
				</Grid>

				<Grid item xs={12}>
					<Controller
						control={control}
						name="ma_dvt"
						rules={{ required: 'Vui lòng chọn đơn vị tính' }}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_dvt') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ten_dvt', item?.label || '');
							};

							return (
								<FieldSelectApi
									api="dmdvt"
									title="ĐVT quy đổi"
									placeholder="Chọn ĐVT quy đổi"
									valueField="ma_dvt"
									labelField="ten_dvt"
									value={item}
									onChange={handleChange}
									check
									errorMsg={errors.ma_dvt?.message}
								/>
							);
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldBox title="Tỷ lệ quy đổi">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Controller
								control={control}
								name="tu"
								render={({ field }) => <FieldNumberInput {...field} />}
							/>

							<div style={{ padding: '0 8px' }}>/</div>

							<Controller
								control={control}
								name="mau"
								render={({ field }) => <FieldNumberInput {...field} />}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								fontSize: 10,
								fontWeight: 500,
								color: '#999',
							}}
						>
							<div style={{ flex: 1 }}>ĐVT quy đổi</div>

							<div style={{ padding: '0 8px', visibility: 'hidden' }}>/</div>

							<div style={{ flex: 1 }}>ĐVT tiêu chuẩn</div>
						</div>
					</FieldBox>
				</Grid>
			</Grid>
		</Dialog>
	);
}
