import viLocale from '@fullcalendar/core/locales/vi';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

export default function BookingCalender({ data }) {
    const classes = useStyles();

    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            droppable
            locale={viLocale}
            initialView="timeGridWeek"
            viewClassNames={classes.root}
            headerToolbar={{
                left: 'prev,next today',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            nowIndicator={true}
            events={data}
            eventChange={({ event }) => {
                console.log(event);
            }}
        />
    );
}

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 16,
        backgroundColor: '#fff',
        boxShadow: 'rgb(0 0 0 / 5%) 0px 20px 27px 0px',
        borderRadius: 12,
        padding: 8,
    },
}));
