import React, { useState, useEffect, useRef } from 'react';
import SelectEditor from './SelectEditor';
import EditableCellNumber from './EditableCellNumber';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate, formatNumberWithSpaces } from '../../../VoucherManager/Utils';

function EditableCell({
	idTable,
	value: initialValue,
	row: { index },
	column: { id, type, selectObject, handleOnChange, handleOnFocus, options },
	updateMyData,
	readonly,
	tabIndex,
	handleOpenAddDetailItemVoucher,
	// tableRef,
}) {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = useState(initialValue);
	const [isIdFocus, setIsIdFocus] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const inputRef = useRef(null);
	const boxFocusRef = useRef(null);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const onFocus = () => {
		setIsIdFocus(true);
	};

	const onBlur = () => {
		updateMyData(index, id, value);
		setIsIdFocus(false);
	};

	useEffect(() => {
		readonly ? setIsFocused(false) : setIsFocused(isIdFocus);
	}, [isIdFocus]);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		readonly ? setIsFocused(false) : setIsFocused(isIdFocus);
	}, [isIdFocus, readonly]);

	useEffect(() => {
		if (isFocused && boxFocusRef.current) {
			boxFocusRef.current.classList.add('active_focus_input');
		} else if (boxFocusRef.current) {
			boxFocusRef.current.classList.remove('active_focus_input');
		}
	}, [isIdFocus, boxFocusRef.current]);

	function keyDownEnter(e) {
		e.preventDefault();
		const nextIndex = boxFocusRef.current.tabIndex + 1;
		const boxParent = document.getElementById(idTable);
		const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);

		if (nextElement) {
			const input = nextElement.querySelector('input');
			if (input) {
				input.focus();
			} else {
				nextElement.focus(); // focus vào cell
			}
		} else {
			const nextIndex = boxFocusRef.current.tabIndex + 2;
			const boxParent = document.getElementById(idTable);
			const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
			if (nextElement) {
				const input = nextElement.querySelector('input');
				if (input) {
					input.focus();
				} else {
					nextElement.focus(); // focus vào cell
				}
			} else {
				if (handleOpenAddDetailItemVoucher) {
					handleOpenAddDetailItemVoucher();
				} else {
					const nextElement = document.getElementById('btnSave');
					if (nextElement) {
						nextElement.focus();
					}
				}
			}
		}
	}
	function keyDownTab(e) {
		e.preventDefault();
		// focus đến tabindex tiếp theo nếu không có tiếp tục +1 nếu có thì focus
		const nextIndex = boxFocusRef.current.tabIndex + 1;
		const boxParent = document.getElementById(idTable);
		const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
		if (nextElement) {
			const input = nextElement.querySelector('input');
			if (input) {
				input.focus();
			} else {
				nextElement.focus(); // focus vào cell
			}
		} else {
			const nextIndex = boxFocusRef.current.tabIndex + 2;
			const boxParent = document.getElementById(idTable);
			const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
			if (nextElement) {
				const input = nextElement.querySelector('input');
				if (input) {
					input.focus();
				} else {
					nextElement.focus(); // focus vào cell
				}
			} else {
				const nextElement = document.getElementById('btnSave');
				if (nextElement) {
					nextElement.focus();
				}
			}
		}
	}

	const datePickerRef = useRef(null);

	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {
			// Thực hiện đóng datepicker khi nhấn tab
			datePickerRef.current.setOpen(false);
			keyDownTab(e);
		}
		if (e.key === 'Enter') {
			// Thực hiện đóng datepicker và focus vào input tiếp theo khi nhấn enter
			datePickerRef.current.setOpen(false);
			keyDownEnter(e);
		}
	};

	function handleKeyPress(e) {
		if (!readonly) {
			if (e.key === 'Enter') {
				keyDownEnter(e);
			} else if (e.key === 'Tab') {
				keyDownTab(e);
			} else if (e.key === 'ArrowLeft') {
				e.preventDefault();
				const nextIndex = tabIndex - 1;
				const boxParent = document.getElementById(idTable);
				const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
				if (nextElement) {
					const input = nextElement.querySelector('input');
					if (input) {
						input.focus();
					} else {
						nextElement.focus(); // focus vào cell
					}
				}
			} else if (e.key === 'ArrowRight') {
				e.preventDefault();
				const nextIndex = tabIndex + 1;
				const boxParent = document.getElementById(idTable);
				const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
				if (nextElement) {
					const input = nextElement.querySelector('input');
					if (input) {
						input.focus();
					} else {
						nextElement.focus(); // focus vào cell
					}
				}
			} else {
				const nextIndex = tabIndex;
				const boxParent = document.getElementById(idTable);
				const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
				if (nextElement) {
					const input = nextElement.querySelector('input');
					if (input) {
						input.focus();
					} else {
						nextElement.focus(); // focus vào cell
					}
				}
			}
		} else {
			if (e.key === 'ArrowUp') {
				e.preventDefault();
				const nextId = `${index - 1}_${id}`;
				const nextElement = document.getElementById(nextId);
				if (nextElement) {
					nextElement.focus();
				}
			} else if (e.key === 'ArrowDown') {
				e.preventDefault();
				const nextId = `${index + 1}_${id}`;
				const nextElement = document.getElementById(nextId);
				if (nextElement) {
					nextElement.focus();
				}
			}
			// if (e.key === "ArrowLeft") {
			// 	e.preventDefault();
			// 	const nextIndex = tabIndex - 1;
			// 	const boxParent = document.getElementById(idTable);
			// 	const nextElement = boxParent.querySelector(
			// 		`[tabindex="${nextIndex}"]`
			// 	);
			// 	if (nextElement) {
			// 		nextElement.focus();
			// 	}
			// }
			// if (e.key === "ArrowRight") {
			// 	e.preventDefault();
			// 	const nextIndex = tabIndex + 1;
			// 	const boxParent = document.getElementById(idTable);
			// 	const nextElement = boxParent.querySelector(
			// 		`[tabindex="${nextIndex}"]`
			// 	);
			// 	if (nextElement) {
			// 		nextElement.focus();
			// 	}
			// }
		}
	}

	const onKeyDown = (e) => {
		// if (e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
		// 	if (e.key === "Tab") {
		// 		keyDownTab(e);
		// 	}
		// 	if (e.key === "ArrowLeft") {
		// 		e.preventDefault();
		// 		const nextIndex = tabIndex - 1;
		// 		const boxParent = document.getElementById(idTable);
		// 		const nextElement = boxParent.querySelector(
		// 			`[tabindex="${nextIndex}"]`
		// 		);
		// 		if (nextElement) {
		// 			const input = nextElement.querySelector("input");
		// 			if (input) {
		// 				input.focus();
		// 			} else {
		// 				nextElement.focus(); // focus vào cell
		// 			}
		// 		}
		// 	}
		// 	if (e.key === "ArrowRight") {
		// 		e.preventDefault();
		// 		const nextIndex = tabIndex + 1;
		// 		const boxParent = document.getElementById(idTable);
		// 		const nextElement = boxParent.querySelector(
		// 			`[tabindex="${nextIndex}"]`
		// 		);
		// 		if (nextElement) {
		// 			const input = nextElement.querySelector("input");
		// 			if (input) {
		// 				input.focus();
		// 			} else {
		// 				nextElement.focus(); // focus vào cell
		// 			}
		// 		}
		// 	}
		// }
		if (e.key === 'Tab') {
			keyDownTab(e);
		}
		if (e.key === 'Enter') {
			keyDownEnter(e);
		}
	};
	const onKeyPress = (e) => {
		if (!readonly) {
			if (e.key === 'Enter') {
				keyDownEnter(e);
			} else {
				const nextIndex = tabIndex;
				const boxParent = document.getElementById(idTable);
				const nextElement = boxParent.querySelector(`[tabindex="${nextIndex}"]`);
				if (nextElement) {
					const input = nextElement.querySelector('input');
					if (input) {
						input.focus();
					} else {
						nextElement.focus(); // focus vào cell
					}
				}
			}
		} else {
			if (e.key === 'ArrowUp') {
				e.preventDefault();
				const nextId = `${index - 1}_${id}`;
				const nextElement = document.getElementById(nextId);
				if (nextElement) {
					nextElement.focus();
				}
			} else if (e.key === 'ArrowDown') {
				e.preventDefault();
				const nextId = `${index + 1}_${id}`;
				const nextElement = document.getElementById(nextId);
				if (nextElement) {
					nextElement.focus();
				}
			}
		}
	};

	function isDate(str) {
		const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
		return regex.test(str);
	}

	function formatValue(value) {
		if (typeof value === 'number') {
			return formatNumberWithSpaces(value);
		} else if (typeof value === 'string') {
			// const timestamp = Date.parse(value);
			if (isDate(value)) {
				return formatDate(new Date(value));
			}
			return formatString(value);
		} else {
			return value;
		}
	}

	function formatString(str) {
		// Xử lý định dạng chuỗi tùy ý ở đây
		return str;
	}

	function checkNumber(value) {
		if (typeof value === 'number') {
			return true;
		}
		return false;
	}
	return (
		<div
			className={`focus_input ${isFocused ? 'active_focus_input' : ''}`}
			onKeyPress={onKeyPress}
			onKeyDown={onKeyDown}
			ref={boxFocusRef}
			tabIndex={tabIndex}
		>
			{type === 'text' ? (
				<input
					title={value ? formatValue(value) : ''}
					readOnly={readonly ? readonly : !isFocused}
					ref={inputRef}
					value={formatValue(value)}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					style={{
						textAlign: checkNumber(value) ? 'right' : 'left',
					}}
					// onDoubleClick={onDoubleClick}
					// onClick={onClick}
					id={index + '_' + id}
				/>
			) : type === 'number' ? (
				<EditableCellNumber
					initialValue={initialValue}
					index={index}
					id={id}
					updateMyData={updateMyData}
					readonly={readonly}
					handleOnChange={(value) => {
						handleOnChange?.(index, value);
					}}
				/>
			) : type === 'select' ? (
				<SelectEditor
					value={initialValue}
					defaultValue={selectObject.defaultValue}
					valueField={selectObject.valueField}
					labelField={selectObject.labelField}
					api={selectObject.api}
					readonly={readonly}
					onChange={(item) => {
						selectObject.onChange(item, index);
					}}
					onFocus={() => {
						handleOnFocus?.(index);
					}}
					q={selectObject.q}
					prefix={selectObject?.prefix}
					getLabel={selectObject.getLabel}
					componentAdd={selectObject?.componentAdd}
				/>
			) : type === 'date' ? (
				<>
					{readonly ? (
						<div
							title={value ? formatValue(value) : ''}
							style={{
								fontSize: '13px',
								display: 'flex',
								width: '100%',
							}}
						>
							<input
								readOnly={readonly ? readonly : !isFocused}
								value={formatValue(value)}
								id={index + '_' + id}
								style={{
									textAlign: checkNumber(value) ? 'right' : 'left',
								}}
							/>
						</div>
					) : (
						<DatePicker
							ref={datePickerRef}
							onKeyDown={handleKeyDown}
							value={
								formatValue(initialValue)
									? formatValue(initialValue)
									: formatValue(new Date().toISOString())
							}
							onChange={(value) => {
								handleOnChange(index, value);
							}}
							onFocus={() => {
								handleOnFocus?.(index);
							}}
						/>
					)}
				</>
			) : type === 'option' ? (
				<div
					title={value ? formatValue(options[parseInt(value)]) : ''}
					style={{
						fontSize: '13px',
						display: 'flex',
						width: '100%',
					}}
				>
					<input
						readOnly={readonly ? readonly : !isFocused}
						value={formatValue(options[parseInt(value)])}
						id={index + '_' + id}
						style={{
							textAlign: checkNumber(options[parseInt(value)]) ? 'right' : 'left',
						}}
					/>
				</div>
			) : (
				// show value
				<div
					title={value ? formatValue(value) : ''}
					style={{
						fontSize: '13px',
						display: 'flex',
						width: '100%',
					}}
				>
					<input
						readOnly={readonly ? readonly : !isFocused}
						value={formatValue(value)}
						id={index + '_' + id}
						style={{
							textAlign: checkNumber(value) ? 'right' : 'left',
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default EditableCell;
