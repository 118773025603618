import { Button, Collapse, Form, Menu } from 'antd';
import React from 'react';
import { HiChevronDown } from 'react-icons/hi2';
import { useCore } from '../../../../hooks';
import VModal from '../../VComponent/VModal';
import styles from './ModalLead.module.css';
import InformationInput from './component/InformationInput';
import AccountInput from './component/AccountInput';
import AuthorizationInput from './component/AuthorizationInput';

const formSections = [
	{
		id: 'information',
		title: 'Information',
		content: <InformationInput />,
	},
	{
		id: 'account',
		title: 'Account',
		content: <AccountInput />,
	},
	{
		id: 'authorization',
		title: 'Authorization',
		content: <AuthorizationInput />,
	},
];

export default function ModalLeadFull({ data, open, onClose, onSubmit }) {
	const [form] = Form.useForm();
	const core = useCore();

	const isEdit = !!data?._id;

	const resetForm = () => {
		form.resetFields();
		if (data) form.setFieldsValue(data);
	};

	React.useEffect(() => {
		if (open) resetForm();
	}, [open]);

	const handleError = ({ errorFields }) => {
		const firstError = errorFields[0];
		const el = document.getElementById(firstError.name);
		el.focus();
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	const handleSubmit = (values) =>
		onSubmit({ ...data, ...values })
			.then(() => form.resetFields())
			.catch((err) => core.contextAlert(err));

	const scrollTo = (id) => {
		const el = document.getElementById(id);
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	return (
		<VModal
			open={open}
			onClose={onClose}
			destroyOnClose
			confirmLoading
			title={isEdit ? `Edit Lead: ${data?.ten_kh}` : 'New Lead'}
			width="98vw"
			height="98vh"
			footer={[
				<Button key="reset" type="dashed" onClick={resetForm} danger>
					Reset
				</Button>,
				<Button key="submit" type="primary" form="lead-form-full" htmlType="submit">
					Save
				</Button>,
			]}
			centered
		>
			<div className={styles.mdf_container}>
				<Menu
					defaultSelectedKeys={[formSections[0].id]}
					mode="vertical"
					style={{ width: 200 }}
					items={formSections.map(({ id, title }) => ({
						key: id,
						label: title,
					}))}
					onClick={({ key }) => scrollTo(key)}
				/>

				<Form
					id="lead-form-full"
					form={form}
					onFinish={handleSubmit}
					onError={handleError}
					onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} // prevent submit on enter
					className={styles.mdf_content}
					labelWrap
				>
					{formSections.map(({ id, title, content }) => (
						<Collapse
							key={id}
							defaultActiveKey={['1']}
							size="small"
							expandIcon={({ isActive }) => (
								<HiChevronDown
									style={{
										transition: 'all 0.3s ease-in-out',
										transform: `rotate(${isActive ? 0 : -90}deg)`,
									}}
								/>
							)}
						>
							<Collapse.Panel header={title} key="1" id={id}>
								{content}
							</Collapse.Panel>
						</Collapse>
					))}

					{/* Placeholder */}
					<div style={{ minHeight: '20%' }} />
				</Form>
			</div>
		</VModal>
	);
}
