import React, { useState, useContext, useEffect } from "react";
import SpaItemsProduct from "./SpaItemsProduct";
import DefaultContext from "flexbiz-core/components/auth/Context";
import SpaItemsServicePack from "./SpaItemsServicePack";
import SpaItemsService from "./SpaItemsService";

function SpaListItemBills({ data, add, index }) {
	const [isType, setIsType] = useState();
	const context = useContext(DefaultContext);

	const handleCheckTypeProduct = async () => {
		try {
			const resp = await context.apis.asyncGetData(
				context?.userInfo?.token,
				"dmvt",
				{ ma_vt: data?.ma_vt }
			);
			if (resp) {
				setIsType(resp);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (data) {
			handleCheckTypeProduct();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			{/* {isType?.is_goods && (
				<SpaItemsProduct
					data={data}
					key={index}
					add={add}
					index={index}
				/>
			)}
			{isType?.is_service && (
				<SpaItemsService
					data={data}
					key={index}
					add={add}
					index={index}
				/>
			)}
			{isType?.is_card && (
				<SpaItemsServicePack
					data={data}
					key={index}
					add={add}
					index={index}
				/>
			)}
			{isType?.is_combo && (
				<SpaItemsServicePack
					data={data}
					key={index}
					add={add}
					index={index}
				/>
			)}
			{isType?.is_package && (
				<SpaItemsServicePack
					data={data}
					key={index}
					add={add}
					index={index}
				/>
			)} */}
			<SpaItemsProduct data={data} key={index} add={add} index={index} />
		</>
	);
}

export default SpaListItemBills;
