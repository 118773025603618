import { Checkbox, FormControlLabel, Grid, IconButton, makeStyles } from '@material-ui/core';
import { AddCircleOutlineRounded, DeleteOutlineRounded } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCore } from '../../../../hooks/useCore';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldBox from '../../components/Dialog/FieldBox';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';

const defaultValues = { status: true, admin: false, auto_create_user: true };
export default function FormParticipantDialog({ open, onClose, data, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control,
		getValues,
		setValue,
		watch,
	} = useForm();

	const core = useCore();
	const [img, setImg] = React.useState(null);
	const imgSrc = img ? URL.createObjectURL(img) : core.getFileUrl(data?.signature_image);
	useEffect(() => {
		if (open) reset({ ...defaultValues, ...data });
		setImg(null); // reset image

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, open]);

	const inputRef = React.useRef(null);
	const handleAddImage = async (e) => inputRef.current.click();
	const handleDeleteImage = () => {
		// clear image and input
		setImg(null);
		setValue('signature_image', null);
		inputRef.current.value = '';
	};

	const handleInputChange = (e) => {
		// set file to state
		const file = e.target.files[0];
		if (file) setImg(file);
	};

	// upload image to server before submit
	const preSubmit = async (data) => {
		// if no image, return data
		if (!img) return data;

		// upload file to server
		const res = await core.uploadFile(img, { folder: 'participant' });

		// return data with image url
		return { ...data, signature_image: res.fileUrl };
	};

	const onSave = (data) => preSubmit(data).then(onSubmit).then(onClose);

	const onSaveAndNew = (data) =>
		preSubmit(data)
			.then(onSubmit)
			.then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) =>
		preSubmit(data)
			.then(onSubmit)
			.then(() => reset(copyData(data)));

	const classes = useStyles();
	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa người dùng' : 'Thêm người dùng'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className='btn btn-grey' onClick={onClose}>
					Hủy
				</button>,
			]}
			width={800}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FieldInput
						title='Tên đăng nhập'
						placeholder='Nhập tên đăng nhập'
						inputProps={{
							...register('email', {
								required: 'Vui lòng nhập tên đăng nhập',
							}),
						}}
						check
						errorMsg={errors?.email?.message}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldInput
						title='Họ và tên'
						placeholder='Nhập họ và tên'
						inputProps={{
							...register('name', {
								required: 'Vui lòng nhập họ và tên',
							}),
						}}
						check
						errorMsg={errors?.name?.message}
					/>
				</Grid>

				<Grid item xs={12}>
					<Controller
						control={control}
						name='group_id'
						render={({ field: { value, onChange } }) => {
							const item = value ? { value, label: getValues('group_name') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('group_name', item?.label || '');
							};

							return (
								<FieldSelectApi
									api='usergroup'
									title='Nhóm người dùng'
									placeholder='Nhóm người dùng'
									valueField='_id'
									labelField='group_name'
									value={item}
									onChange={handleChange}
								/>
							);
						}}
					/>
				</Grid>

				<Grid item xs={6}>
					<FieldBox
						title='Chữ ký'
						titleProps={{ style: { alignSelf: 'flex-start' } }}
						contentProps={{ style: { jusifySelf: 'flex-start' } }}
					>
						<input type='file' hidden accept='image/*' onChange={handleInputChange} ref={inputRef} />

						<div className={classes.imageContainer}>
							<IconButton className={classes.imageAction}>
								{!imgSrc ? (
									<AddCircleOutlineRounded color='primary' onClick={handleAddImage} />
								) : (
									<DeleteOutlineRounded color='error' onClick={handleDeleteImage} />
								)}
							</IconButton>

							<img
								className={classes.signatureImage}
								src={imgSrc || 'https://via.placeholder.com/120/FFF/000/?text=Thêm+ảnh'}
								alt='Ảnh chữ ký'
							/>
						</div>
					</FieldBox>
				</Grid>

				<Grid item xs={6}>
					<div className={classes.checkboxContainer}>
						<Controller
							name='admin'
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									label='Là người quản trị'
									control={<Checkbox size='small' />}
									checked={!!value}
									onChange={onChange}
								/>
							)}
						/>

						<Controller
							name='status'
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									label='Còn hoạt động'
									control={<Checkbox size='small' />}
									checked={!!value}
									onChange={onChange}
								/>
							)}
						/>

						{!watch('_id') && (
							<Controller
								name='auto_create_user'
								control={control}
								render={({ field: { value, onChange } }) => (
									<FormControlLabel
										label='Tạo tài khoản'
										control={<Checkbox size='small' />}
										checked={!!value}
										onChange={onChange}
									/>
								)}
							/>
						)}
					</div>
				</Grid>

				<Grid item xs={12}>
					{!watch('_id') && watch('auto_create_user') && (
						<FieldInput
							title='Mật khẩu mặc định'
							placeholder='Nhập mật khẩu mặc định'
							inputProps={{ ...register('password', { required: 'Vui lòng nhập mật khẩu' }) }}
							check
							errorMsg={errors?.password?.message}
						/>
					)}
				</Grid>
			</Grid>
		</Dialog>
	);
}

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		width: 120,
		height: 120,
		position: 'relative',

		// Hover show first child
		'&:hover': {
			'& > *:first-child': {
				display: 'block',

				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
	},
	imageAction: {
		zIndex: 1,
		display: 'none',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	signatureImage: {
		objectFit: 'contain',
		aspectRatio: '1/1',
		position: 'relative',
		borderRadius: '8px',
		width: '100%',
		height: '100%',
		transition: 'all 0.3s',
		border: '1px dashed #e0e0e0',
	},
	checkboxContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '4px',
	},
}));
