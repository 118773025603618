import React, { useState, useEffect, useContext, useMemo } from "react";
import "animate.css";
import {
  Box,
  IconButton,
  MenuItem,
  Divider,
  List,
  Typography,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@material-ui/core";
import DefautContext from "flexbiz-core/components/auth/Context";
import { BiCollapse, BiMinus } from "react-icons/bi";
import { BsDot, BsPlus } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";

function ListFilter({ code, handleClickDMNVT }) {
  const [collapse, setCollapse] = useState(true);
  const [filterWidth, setFilterWidth] = useState(30);
  const [countRender, setCountRender] = useState(0);
  const [dmnsp, setDmnsp] = useState([]);

  const context = useContext(DefautContext);

  const parentItems = useMemo(() => {
    return dmnsp.filter((item) => !item.nh_me);
  }, [dmnsp]);
  useEffect(() => {
    const getDMNVT = async () => {
      let param = "";
      if (code === "dmvtlio") {
        param = "dmnsp";
      } else if (code === "academy") {
        param = "dmndv";
      }
      const resp = await context.apis.asyncGetList(
        context.userInfo.token,
        param,
        { limit: 1000, condition: { status: true }, cache: true }
      );
      if (resp && resp.length > 0) {
        setDmnsp(resp);
      }
    };
    getDMNVT();
  }, [code, context]);
  // handle zoom out filter
  const handleZoomOutFilter = () => {
    setCollapse(true);
    if (countRender === 0) {
      setCountRender(1);
    }
    setTimeout(() => {
      setFilterWidth(30);
    }, 500);
  };

  // handle zoom in filter
  const handleZoomInFilter = () => {
    setCountRender(countRender + 1);
    setCollapse(false);
    setFilterWidth(300);
  };

  return (
    <Box
      sx={{
        position: "relative",
        boxSizing: "border-box",
        paddingLeft: "10px",
        flexShrink: "0",
      }}
    >
      <Tooltip title="Bộ lọc">
        <IconButton
          className={`animate__animated ${
            collapse ? "animate__backInLeft" : "animate__backOutLeft"
          }`}
          onClick={handleZoomInFilter}
          style={{
            position: "absolute",
            top: "0",
            left: "10px",
            width: "40px",
            height: "40px",
            backgroundColor: "#6C00FF",
            borderRadius: "6px 20px 20px 6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiFilter color="#fff" />
        </IconButton>
      </Tooltip>
      <Box
        className={`animate__animated  ${
          collapse === true ? "animate__backOutLeft" : ""
        } ${collapse === false ? "animate__backInLeft" : ""}`}
        sx={{
          width: filterWidth,
          height: "calc(100vh - 50px - 44px - 10px)",
          overflow: "auto",
          boxSizing: "border-box",
          border: "1px solid #ccc",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          padding: "10px",
        }}
      >
        {!!countRender && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Nhóm sản phẩm</Typography>
              <Tooltip title="Thu gọn">
                <IconButton onClick={handleZoomOutFilter} size="small">
                  <BiCollapse />
                </IconButton>
              </Tooltip>
            </Box>

            {/* start list */}
            <List sx={{ width: "100%" }} component="nav">
              <MenuItem onClick={() => handleClickDMNVT()}>
                <Typography>Tất cả</Typography>
              </MenuItem>
              <Divider />
              {parentItems &&
                parentItems.map((item) => (
                  <DrawerItem
                    key={item._id}
                    item={item}
                    items={dmnsp}
                    handleClickDMNVT={handleClickDMNVT}
                  />
                ))}
            </List>
            {/* end list */}
          </>
        )}
      </Box>
    </Box>
  );
}

export default ListFilter;

const DrawerItem = ({ item, items, handleClickDMNVT }) => {
  const [collapse, setCollapse] = useState(true);

  const children = useMemo(() => {
    return items.filter((i) => {
      return i.nh_me && i.nh_me === item.ma;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.ma, items]);
  const handleClickParent = (ma) => {
    if (children && children.length > 0) {
      if (collapse) {
        setCollapse(!collapse);
      } else {
        setCollapse(!collapse);
        handleClickDMNVT(ma);
      }
    } else {
      handleClickDMNVT(ma);
    }
  };

  return (
    <React.Fragment key={item._id}>
      <React.Fragment>
        <MenuItem onClick={() => handleClickParent(item.ma)}>
          <ListItem style={{ padding: "0px" }}>
            <ListItemText primary={item.ten} />
          </ListItem>
          {children && children.length > 0 ? (
            <>
              <ListItemIcon style={{ minWidth: "16px" }}>
                {collapse ? (
                  <BiMinus fontSize="16px" />
                ) : (
                  <BsPlus fontSize="16px" />
                )}
              </ListItemIcon>
            </>
          ) : (
            <></>
          )}
        </MenuItem>
        <Divider />
      </React.Fragment>
      <Collapse in={collapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((c) => (
            <React.Fragment key={c._id}>
              <MenuItem onClick={() => handleClickDMNVT(c.ma)}>
                <ListItemIcon style={{ minWidth: "16px" }}>
                  <BsDot fontSize="30px" />
                </ListItemIcon>
                <ListItem style={{ padding: "0px" }}>
                  <ListItemText primary={c.ten} />
                </ListItem>
              </MenuItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
