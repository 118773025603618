import React from 'react';
import InputContainer from '../../components/Dialog/InputContainer';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';
import FieldInput from '../../components/Dialog/FieldInput';
import styles from '../AppConfig.module.css';
import { Controller } from 'react-hook-form';

export default function ConfigTab({ hookForm }) {
	const { control, register } = hookForm;
	return (
		<div className={styles.configTab}>
			<InputContainer title='Tùy chọn hàng tồn kho' defaultShow>
				<Grid container spacing={2} direction='column' style={{ padding: 8 }}>
					<Controller
						name='options.khong_xuat_kho_am'
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								control={<Checkbox size='small' />}
								label={
									<p className={styles.fieldCheckbox}>
										Không cho phép xuất kho khi số lượng tồn trong kho không đủ
									</p>
								}
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
							/>
						)}
					/>

					<Controller
						name='options.sl_nhap_sl_hop_dong'
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								control={<Checkbox size='small' />}
								label={
									<p className={styles.fieldCheckbox}>
										Mặc định số lượng nhập mua bằng số lượng còn lại trên hợp đồng mua hàng
									</p>
								}
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
							/>
						)}
					/>

					<Controller
						name='options.sl_xuat_sl_hop_dong'
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								control={<Checkbox size='small' />}
								label={
									<p className={styles.fieldCheckbox}>
										Mặc định số lượng xuất bán bằng số lượng còn lại trên hợp đồng bán hàng
									</p>
								}
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
							/>
						)}
					/>

					<Controller
						name='options.sl_xuat_sl_dn'
						control={control}
						render={({ field: { value, onChange } }) => (
							<FormControlLabel
								control={<Checkbox size='small' />}
								className={styles.fieldCheckbox}
								label={
									<p className={styles.fieldCheckbox}>
										Mặc định số lượng xuất điều chuyển bằng số lượng còn lại trên phiếu đề nghị điều
										chuyển
									</p>
								}
								checked={!!value}
								onChange={(e) => onChange(e.target.checked)}
							/>
						)}
					/>
					<Controller
						name='options.ds_show_gia_von'
						control={control}
						render={({ field: { value, onChange } }) => {
							const items = value?.map((item) => ({ value: item, label: item })) || [];
							const handleChange = (items) => onChange(items?.map((item) => item.value) || []);

							return (
								<FieldSelectApi
									title='Người được thấy giá vốn'
									placeholder='Chọn người dùng'
									api='participant'
									multi
									valueField='email'
									labelField='name'
									getLabel={(item) => `${item.name} (${item.email})`}
									value={items}
									onChange={handleChange}
								/>
							);
						}}
					/>

					<FieldInput title='Tên loại nhóm 2' inputProps={{ ...register('options.ten_nvt2') }} />
					<FieldInput title='Tên loại nhóm 3' inputProps={{ ...register('options.ten_nvt3') }} />
					<FieldInput title='Tên loại nhóm 4' inputProps={{ ...register('options.ten_nvt4') }} />
				</Grid>
			</InputContainer>

			<InputContainer title='Tùy chọn số lẻ'>
				<Grid container spacing={2} direction='column' style={{ padding: 8 }}>
					<FieldInput title='Trường số lượng (f_sl)' inputProps={{ ...register('options.f_sl') }} />
					<FieldInput title='Trường giá (f_gia)' inputProps={{ ...register('options.f_gia') }} />
					<FieldInput
						title='Trường giá ngoại tệ (f_gia_nt)'
						inputProps={{ ...register('options.f_gia_nt') }}
					/>
					<FieldInput title='Trường tiền (f_tien)' inputProps={{ ...register('options.f_tien') }} />
					<FieldInput
						title='Trường tiền ngoại tệ (f_tien_nt)'
						inputProps={{ ...register('options.f_tien_nt') }}
					/>
					<FieldInput
						title='Trường tiền tỷ giá (f_ty_gia)'
						inputProps={{ ...register('options.f_ty_gia') }}
					/>
				</Grid>
			</InputContainer>
		</div>
	);
}
