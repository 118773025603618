export const typeOfReport = [
	{
		value: 'ma_vt',
		label: 'Vật tư',
	},
	{
		value: 'ma_kho',
		label: 'Kho',
	},
	{
		value: 'ma_lo',
		label: 'Lô',
	},
	{
		value: 'han_sd',
		label: 'Hạn sử dụng',
	},
	{
		value: 'ma_tt1',
		label: 'Thuộc tính 1',
	},
	{
		value: 'ma_tt2',
		label: 'Thuộc tính 2',
	},
	{
		value: 'ma_tt3',
		label: 'Thuộc tính 3',
	},
];
