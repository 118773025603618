import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';

export default function FormUnitDialog({ open, onClose, data, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm();

	useEffect(() => {
		if (open) reset(data || {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa đơn vị tính' : 'Thêm đơn vị tính'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className='btn btn-grey' onClick={onClose}>
					Hủy
				</button>,
			]}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FieldInput
						title='Mã đơn vị tính'
						placeholder='Nhập mã đơn vị tính'
						inputProps={{
							...register('ma_dvt', {
								required: 'Vui lòng nhập mã đơn vị tính',
							}),
						}}
						check
						errorMsg={errors?.ma_dvt?.message}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldInput
						title='Tên đơn vị tính'
						placeholder='Nhập tên đơn vị tính'
						inputProps={{
							...register('ten_dvt', {
								required: 'Vui lòng nhập tên đơn vị tính',
							}),
						}}
						check
						errorMsg={errors?.ten_dvt?.message}
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
}
