import { makeStyles } from '@material-ui/styles';
import React from 'react';

export default function FilterButton({ onClick, children = 'Lọc' }) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <button
                className="btn btn-edit"
                onClick={onClick}
                style={{
                    width: '100%',
                    margin: 0,
                }}
            >
                {children}
            </button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 8,
        fontSize: 12,
    },
}));
