import { Grid } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldSelectApi from '../../../../../components/Dialog/FieldSelectApi';
import InputContainer from '../../../../../components/Dialog/InputContainer';

const GroupSelect = ({ hookForm, index }) => {
	let valueField = 'ma_nvt';
	let labelField = 'ten_nvt';
	const props = {
		api: 'dmnvt',
		valueField: '_id',
		labelField: 'ten_nvt',
	};

	// other group
	if (index > 1) {
		valueField += index;
		labelField += index;
		props.api = 'group';
		props.q = { group_type: `DMVT${index}` };
		props.valueField = '_id';
		props.labelField = 'group_name';
	}

	const { getValues, setValue, control } = hookForm;

	return (
		<Controller
			control={control}
			name={valueField}
			render={({ field: { value, onChange } }) => {
				const label = getValues(labelField);
				const item = value ? { value, label } : null;

				const handleChange = (item) => {
					onChange(item?.value || null);
					setValue(labelField, item?.label || '');
				};
				return (
					<FieldSelectApi
						title={`Nhóm hàng ${index}`}
						placeholder={`Nhóm hàng ${index}`}
						value={item}
						onChange={handleChange}
						{...props}
					/>
				);
			}}
		/>
	);
};

export default function GroupInput({ hookForm }) {
	
	return (
		<InputContainer title="Phân loại" defaultShow>
			<Grid container spacing={2}>
				{[1, 2, 3, 4].map((index) => (
					<Grid item xs={6} key={index}>
						<GroupSelect hookForm={hookForm} index={index} />
					</Grid>
				))}
			</Grid>
		</InputContainer>
	);
}
