import React from "react";
import MenuBase from "./MenuBase";
import { Menu, MenuItem } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { SaleContext } from "../../SaleContainer";
import DefaultContext from "flexbiz-core/components/auth/Context";

function MenuChannel() {
  const context = React.useContext(DefaultContext);
  const { voucher, handleSavePBL, getVoucher } = React.useContext(SaleContext);

  const [anchorChannel, setAnchorChannel] = React.useState(null);
  const [currentChannel, setCurrentChannel] = React.useState();
  const [channels, setChannels] = React.useState([]);

  // handle select channel
  const handleSelectChannel = async (channel) => {
    try {
      const voucherClone = cloneDeep(voucher);
      const exfields = cloneDeep(voucherClone.exfields || {});
      exfields.kenh_ban = channel.ma_kenh;
      voucherClone.exfields = exfields;
      await handleSavePBL(voucherClone);
      setCurrentChannel(channel);
      getVoucher();
    } catch (error) {
      context.alert(error);
    }
  };
  // get current channel
  const getCurrentChannel = async (ma_kenh) => {
    try {
      const resp = await context.apis.asyncGetData(
        context.userInfo.token,
        "dmkenhbanhang",
        { ma_kenh }
      );
      if (resp) {
        setCurrentChannel(resp);
      } else {
        setCurrentChannel(null);
      }
    } catch (error) {
      context.alert(error);
    }
  };
  // load channel
  const loadChannel = async () => {
    try {
      const resp = await context.apis.asyncGetList(
        context.userInfo.token,
        "dmkenhbanhang"
      );
      if (resp) {
        setChannels(resp);
      }
    } catch (error) {
      context.alert(error);
    }
  };
  React.useEffect(() => {
    getCurrentChannel(voucher?.exfields?.kenh_ban || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher]);
  React.useEffect(() => {
    loadChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return (
    <MenuBase
      onClick={(e) => setAnchorChannel(e.currentTarget)}
      displayText={currentChannel?.ten_kenh || "Kênh bán"}
      tooltipText={
        currentChannel?.ten_kenh ? `Kênh bán: ${currentChannel?.ten_kenh}` : "Kênh bán"
      }
    >
      <Menu
        anchorEl={anchorChannel}
        open={!!anchorChannel}
        onClose={() => setAnchorChannel(null)}
      >
        {channels?.length === 0 ? (
          <MenuItem style={{ fontSize: "13px" }}>Chưa có kênh bán</MenuItem>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                handleSelectChannel({ ma_kenh: "" });
                setAnchorChannel(null);
              }}
              style={{ fontSize: "13px" }}
            >
              --
            </MenuItem>
            {channels?.length > 0 &&
              channels.map((channel) => (
                <MenuItem
                  key={channel.ma_kenh}
                  style={
                    currentChannel?.ma_kenh === channel.ma_kenh
                      ? {
                          fontSize: "13px",
                          backgroundColor: "#ededed",
                          pointerEvents: "none",
                        }
                      : {
                          fontSize: "13px",
                        }
                  }
                  onClick={() => {
                    handleSelectChannel(channel);
                    setAnchorChannel(null);
                  }}
                >
                  {channel.ten_kenh}
                </MenuItem>
              ))}
          </>
        )}
      </Menu>
    </MenuBase>
  );
}

export default MenuChannel;
