import { Grid, TextField } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SaveIcon from '@material-ui/icons/Save';
import { Select } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';
import './TaskDialogEditMultiRow.css';

function TaskDialogEditMultiRow({ open, handleClose, handleEditTaskList }) {
	const [lstField, setLstField] = useState([]);

	const hookForm = useForm({});

	const {
		getValues,
		setValue,
		register,
		formState: { errors },
		control,
		handleSubmit,
		// reset,
		// formState: { isSubmitting },
	} = hookForm;

	const onSubmit = async (data) => {
		try {
			// await context.apis.asyncPostList(context.userInfo.token, "task", {
			//   ...data,
			// });

			// await getDataTask();

			console.log('DATA:', JSON.stringify(data));

			handleEditTaskList(data);

			toast.success('Cập nhật thành công');
		} catch (error) {
			console.log(error);
			toast.success(error);
		}
	};

	const onSave = (data) =>
		onSubmit(data).then(() => {
			handleClose();
			setLstField([]);
		});

	const handleChangeSelect = (value) => {
		setLstField(value);
	};

	const handleCloseAndResetField = () => {
		handleClose();
		setLstField([]);
	};

	return (
		<Dialog
			width={1000}
			open={open}
			handleClose={handleCloseAndResetField}
			title={'Chỉnh sửa trên nhiều dòng'}
			classNameContent={'dialog__content_edit_multi_body'}
			actions={[
				<button key="save" className="btn btn-fill" onClick={handleSubmit(onSave)}>
					<SaveIcon style={{ marginRight: '10px' }} />
					<div>Lưu</div>
				</button>,

				<button key="exit" className="btn btn-grey" onClick={handleCloseAndResetField}>
					<ErrorOutlineIcon style={{ marginRight: '10px' }} />
					<div>Bỏ qua</div>
				</button>,
			]}
		>
			<div className="dialog__content_edit_multi">
				<div className="dialog__content_edit_multi__detail">
					<div
						style={{
							width: '100%',
						}}
					>
						<span className="select_title">Chọn trường cần thay đổi</span>

						<Select
							mode="multiple"
							placeholder="Lựa chọn trường cần thay đổi"
							showArrow
							onChange={handleChangeSelect}
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							style={{
								width: 'auto',
								minWidth: 400,
								textAlign: 'left',
								zIndex: 1000,
							}}
							options={[
								{
									value: 'ten_cv',
									label: 'Tên công việc',
								},
								{
									value: 'ten_phu_trach',
									label: 'Người phụ trách',
								},
								{
									value: 'ten_trang_thai',
									label: 'Trạng thái',
								},
								{
									value: 'start_date',
									label: 'Ngày bắt đầu',
								},
								{
									value: 'due_date',
									label: 'Ngày kết thúc',
								},
								{
									value: 'ten_bp',
									label: 'Bộ phận',
								},
							]}
						/>
					</div>

					{lstField ? (
						<div className="edit_container">
							{Array(lstField)[0].map((field) => {
								switch (field) {
									case 'ten_cv':
										return (
											<div className="fieldEdit">
												<Controller
													control={control}
													name="ten_cv"
													rules={{ required: 'Vui lòng nhập tên công việc' }}
													render={() => (
														<FieldInput
															title="Tên công việc"
															inputProps={{
																...register('ten_cv'),
															}}
															check
															errorMsg={errors.ten_cv?.message}
														/>
													)}
												/>
											</div>
										);

									case 'ten_phu_trach':
										return (
											<div className="fieldEdit">
												<Controller
													name="phu_trach"
													control={control}
													rules={{ required: 'Vui lòng chọn người phụ trách' }}
													render={({ field: { onChange, value } }) => {
														const item = value
															? { value, label: getValues('ten_phu_trach') }
															: null;
														const handleChange = (item) => {
															onChange(item?.email || null);
															setValue('ten_phu_trach', item.name || '');
														};

														return (
															<FieldSelectApi
																title="Phụ trách"
																placeholder="Chọn người phụ trách"
																api="participant"
																valueField="email"
																labelField="name"
																value={item}
																onChange={handleChange}
																check
																errorMsg={errors.phu_trach?.message}
															/>
														);
													}}
												/>
											</div>
										);
									case 'ten_trang_thai':
										return (
											<div className="fieldEdit">
												<Controller
													name="ten_trang_thai"
													control={control}
													rules={{ required: 'Vui lòng chọn trạng thái' }}
													render={({ field: { onChange, value } }) => {
														const item = value
															? { value, label: getValues('ten_trang_thai') }
															: null;
														const handleChange = (item) => {
															onChange(item?.ten_trang_thai || null);
															setValue('progress', item.ma_trang_thai || '');
														};

														const query = { ma_ct: 'TASK' };

														return (
															<FieldSelectApi
																title="Trạng thái"
																placeholder="Chọn trạng thái"
																api="trangthai"
																q={query}
																withIdApp={false}
																valueField="ma_trang_thai"
																labelField="ten_trang_thai"
																value={item}
																onChange={handleChange}
																check
																errorMsg={errors.ten_trang_thai?.message}
															/>
														);
													}}
												/>
											</div>
										);

									case 'start_date':
										return (
											<div className="fieldEdit">
												<Grid className="dateTimePickerEdit">
													<Grid xs={4}>
														<label>Ngày bắt đầu</label>
													</Grid>

													<Controller
														control={control}
														name="start_date"
														rules={{ required: 'Vui lòng chọn ngày bắt đầu' }}
														render={({ field: { onChange, value } }) => (
															<TextField
																id="datetime-local"
																type="datetime-local"
																defaultValue={
																	value && dayjs(value).format('YYYY-MM-DD HH:mm')
																}
																className="fieldDateTime"
																onChange={onChange}
																check
																errorMsg={errors.start_date?.message}
															/>
														)}
													/>
												</Grid>
											</div>
										);

									case 'due_date':
										return (
											<div className="fieldEdit">
												<Grid className="dateTimePickerEdit" spacing={2}>
													<Grid xs={4}>
														<label>Ngày kết thúc</label>
													</Grid>

													<Controller
														control={control}
														name="due_date"
														rules={{ required: 'Vui lòng chọn ngày kết thúc' }}
														render={({ field: { onChange, value } }) => (
															<TextField
																id="datetime-local"
																type="datetime-local"
																defaultValue={
																	value && dayjs(value).format('YYYY-MM-DD HH:mm')
																}
																className="fieldDateTime"
																onChange={onChange}
																check
																errorMsg={errors.due_date?.message}
															/>
														)}
													/>
												</Grid>
											</div>
										);
									case 'ten_bp':
										return (
											<div className="fieldEdit">
												<Controller
													name="ten_bp"
													control={control}
													render={({ field: { onChange, value } }) => {
														const item = value
															? { value, label: getValues('ten_bp') }
															: null;
														const handleChange = (item) => {
															onChange(item?.value || null);
															setValue('ten_bp', item.value || '');
															setValue('ten_bp', item.label || '');
														};

														return (
															<FieldSelectApi
																title="Bộ phận"
																placeholder="Chọn bộ phận"
																api="dmbp"
																valueField="ten_bp"
																labelField="ten_bp"
																value={item}
																onChange={handleChange}
															/>
														);
													}}
												/>
											</div>
										);

									default:
										return null;
								}
							})}
						</div>
					) : null}
				</div>
			</div>
		</Dialog>
	);
}

export default TaskDialogEditMultiRow;
