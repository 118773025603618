import { Select } from 'antd';
import React from 'react';

const options = [
	{
		value: 'greater',
		label: '>',
	},
	{
		value: 'less',
		label: '<',
	},
	{
		value: 'equals',
		label: '=',
	},
	{
		value: 'greaterOrEqual',
		label: '≥',
	},
	{
		value: 'lessOrEqual',
		label: '≤',
	},
];

function NumberComparisonFilter({ column: { setFilter } }) {
	const [compareValue, setCompareValue] = React.useState('');
	const [compareOperator, setCompareOperator] = React.useState('greater');

	const handleCompareValueChange = (e) => {
		const val = parseInt(e.target.value, 10);
		if (!isNaN(val)) {
			setCompareValue(val);
			applyFilter(val, compareOperator);
		} else {
			setCompareValue('');
			setFilter([]);
		}
	};

	const handleCompareOperatorChange = (value) => {
		setCompareOperator(value);
		applyFilter(compareValue, value);
	};

	const applyFilter = (value, operator) => {
		let min, max;

		switch (operator) {
			case 'greater':
				min = value + 1;
				max = undefined;
				break;
			case 'less':
				min = undefined;
				max = value - 1;
				break;
			case 'equals':
				min = value;
				max = value;
				break;
			case 'greaterOrEqual':
				min = value;
				max = undefined;
				break;
			case 'lessOrEqual':
				min = undefined;
				max = value;
				break;
			default:
				min = undefined;
				max = undefined;
				break;
		}

		setFilter([min, max]);
	};

	return (
		<div className="flex-start style_numbercomparison">
			<Select
				value={compareOperator}
				onChange={handleCompareOperatorChange}
				options={options}
				suffixIcon={<></>} // Xoá mũi tên trong Select
				dropdownStyle={{
					minWidth: '45px',
				}}
			/>
			<input
				value={compareValue}
				type="number"
				onChange={handleCompareValueChange}
				placeholder="0"
				style={{
					width: '45px',
					marginLeft: '0.5rem',
				}}
			/>
		</div>
	);
}

export default NumberComparisonFilter;
