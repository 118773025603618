import React from "react";
import styled from "styled-components";
import { Box, Typography, Button, Tooltip } from "@material-ui/core";
import { RxDividerVertical } from "react-icons/rx";
import { FiChevronDown } from "react-icons/fi";

export const Select = styled(Box)`
  height: 34px;
  display: flex;
  align-items: center;
`;
export const SelectText = styled(Typography)`
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  width: auto;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DeviderIcon = styled(Box)`
  width: 20px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
`;
export const SelectIcon = styled(Box)`
  width: 15px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
`;

function MenuBase({
  displayText = "Menu",
  tooltipText = "",
  children,
  ...props
}) {
  return (
    <>
      <Tooltip arrow placement="top" title={tooltipText}>
        <Select {...props} component={Button}>
          <SelectText>{displayText}</SelectText>
          <DeviderIcon>
            <RxDividerVertical fontSize="16px" />
          </DeviderIcon>
          <SelectIcon>
            <FiChevronDown fontSize="16px" />
          </SelectIcon>
        </Select>
      </Tooltip>
      {children}
    </>
  );
}

export default MenuBase;
