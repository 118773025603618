/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';
import Dialog from '../components/Dialog/Dialog';
import { Box, Grid, CssBaseline } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import FieldSelectApi from '../components/Dialog/FieldSelectApi';
import { CancelRounded, SaveRounded } from '@material-ui/icons';
import FieldInput from '../components/Dialog/FieldInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { isEmpty } from 'lodash';

const PriceFormStyled = styled(Box)`
	padding: 10px 0;
`;
const CheckboxWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const schema = yup.object({
	vat_tu: yup.object().required('Vui lòng chọn sản phẩm'),
	hieu_luc_tu: yup.date().typeError('Ngày không đúng định dạng').required('Vui lòng chọn ngày bắt đầu'),
	hieu_luc_den: yup
		.date()
		.typeError('Ngày không đúng định dạng')
		.required('Vui lòng chọn ngày kết thúc')
		.min(yup.ref('hieu_luc_tu'), 'Ngày kết thúc phải sau ngày bắt đầu'),
	gia_ban_le: yup.number().typeError('Giá phải là số').required('Vui lòng nhập giá bán lẻ'),
});
/*
 - Thiếu các trường: đơn vị tính, ngành hàng, giá bán buôn hiện đang có 1 trường trong khi cần nhập 4 trường,
 tỷ lệ giảm giá, tiền giảm giá, 2 dấu check thuế gtgt và còn sử dụng
 */

function PriceForm({ open, onClose, data, setData, onSubmit }) {
	const theme = useTheme();
	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		formState: { errors, isSubmitting },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			vat_tu: null,
			don_vi_tinh: null,
			nhom_vat_tu: null,
			nganh_hang: null,
			khach_hang: null,
			nhom_khach_hang: null,
			kho: null,
			gia_ban_le: 0,
			gia_ban_buon: 0,
			gia_ban_buon2: 0,
			gia_ban_buon3: 0,
			gia_ban_buon4: 0,
			ty_le_ck: 0,
			tien_ck: 0,
			hieu_luc_tu: '',
			hieu_luc_den: '',
			bao_gom_thue_gtgt: false,
			con_su_dung: false,
		},
	});
	const [isEdit, setIsEdit] = React.useState(false);
	React.useEffect(() => {
		if (open) {
			if (!data || isEmpty(data)) {
				setIsEdit(false);
				reset({
					vat_tu: null,
					don_vi_tinh: null,
					nhom_vat_tu: null,
					nganh_hang: null,
					khach_hang: null,
					nhom_khach_hang: null,
					kho: null,
					gia_ban_le: 0,
					gia_ban_buon: 0,
					gia_ban_buon2: 0,
					gia_ban_buon3: 0,
					gia_ban_buon4: 0,
					ty_le_ck: 0,
					tien_ck: 0,
					hieu_luc_tu: '',
					hieu_luc_den: '',
					bao_gom_thue_gtgt: false,
					con_su_dung: false,
				});
			} else {
				setIsEdit(true);
				reset({
					vat_tu: data?.ma_vt ? { ma_vt: data.ma_vt, ten_vt: data.ten_vt } : null,
					don_vi_tinh: data?.ma_dvt ? { ma_dvt: data.ma_dvt, ten_dvt: data.ten_dvt } : null,
					nhom_vat_tu: data.ma_nvt ? { _id: data.ma_nvt, ten_nvt: data.ten_nvt } : null,
					nganh_hang: data?.ma_nganh ? { ma_nganh: data.ma_nganh, ten_nganh: data.ten_nganh } : null,
					khach_hang: data?.ma_kh ? { ma_kh: data.ma_kh, ten_kh: data.ten_kh } : null,
					nhom_khach_hang: data?.nh_kh ? { group_name: data.nh_kh } : null,
					kho: data?.ma_kho ? { ma_ko: data.ma_kho, ten_kho: data.ten_kho } : null,

					gia_ban_le: data.gia_ban_le,
					gia_ban_buon: data.gia_ban_buon,
					gia_ban_buon2: data.gia_ban_buon2,
					gia_ban_buon3: data.gia_ban_buon3,
					gia_ban_buon4: data.gia_ban_buon4,
					ty_le_ck: data.ty_le_ck,
					tien_ck: data.tien_ck,
					hieu_luc_tu: moment(data.hieu_luc_tu).format('YYYY-MM-DD'),
					hieu_luc_den: moment(data.hieu_luc_den).format('YYYY-MM-DD'),
					bao_gom_thue_gtgt: data.bao_gom_thue_gtgt,
					con_su_dung: data.status,
				});
			}
		}
	}, [data, open]);
	// genarate data post
	const generateDataPost = (values) => {
		return {
			...data,
			ma_vt: values?.vat_tu?.ma_vt,
			ten_vt: values?.vat_tu?.ten_vt,
			ma_dvt: values?.don_vi_tinh?.ma_dvt,
			ten_dvt: values?.don_vi_tinh?.ten_dvt,
			ma_nvt: values?.nhom_vat_tu?._id,
			ten_nvt: values?.nhom_vat_tu?.ten_nvt,
			ma_nganh: values?.nganh_hang?.ma_nganh,
			ten_nganh: values?.nganh_hang?.ten_nganh,
			ma_kh: values?.khach_hang?.ma_kh,
			ten_kh: values?.khach_hang?.ten_kh,
			nh_kh: values?.nhom_khach_hang?.group_name,
			ma_kho: values?.kho?.ma_kho,
			ten_kho: values?.kho?.ten_kho,
			gia_ban_le: values.gia_ban_le,
			gia_ban_buon: values.gia_ban_buon,
			gia_ban_buon2: values.gia_ban_buon2,
			gia_ban_buon3: values.gia_ban_buon3,
			gia_ban_buon4: values.gia_ban_buon4,
			hieu_luc_den: values.hieu_luc_den,
			hieu_luc_tu: values.hieu_luc_tu,
			ty_le_ck: values.ty_le_ck,
			tien_ck: values.tien_ck,
			bao_gom_thue_gtgt: values.bao_gom_thue_gtgt,
			status: values.con_su_dung,
		};
	};
	// save
	const handleSave = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost).then(onClose);
	};
	// handle save copy
	const handleSaveCopy = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost);
	};

	// save and add new
	const handleSaveAddNew = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost).then(() => {
			setIsEdit(false);
			setData({});
		});
	};

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={isEdit ? 'Chỉnh sửa giá bán' : 'Thêm giá bán'}
			loading={isSubmitting}
			width="824px"
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(handleSave)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveAddNew)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveCopy)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					<CancelRounded />
					Hủy
				</button>,
			]}
		>
			<CssBaseline />
			<PriceFormStyled>
				<Grid container alignContent="flex-start" spacing={2}>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="vat_tu"
							render={({ field: { onChange, value } }) => {
								return (
									<FieldSelectApi
										api="dmvt"
										title="Sản phẩm"
										placeholder="Sản phẩm"
										value={value ? { value: value.ma_vt, label: value.ten_vt } : null}
										onChange={onChange}
										valueField="ma_vt"
										labelField="ten_vt"
										errorMsg={errors?.vat_tu?.message}
									/>
								);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="don_vi_tinh"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmdvt"
									title="Đơn vị tính"
									placeholder="Mã đơn vị tính"
									value={value ? { value: value.ma_dvt, label: value.ten_dvt } : null}
									onChange={onChange}
									valueField="ma_dvt"
									labelField="ten_dvt"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="nhom_vat_tu"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmnvt"
									title="Nhóm sản phẩm"
									placeholder="Nhóm sản phẩm"
									value={value ? { value: value._id, label: value.ten_nvt } : null}
									onChange={onChange}
									valueField="_id"
									labelField="ten_nvt"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="nganh_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmnganhhang"
									title="Ngành hàng"
									placeholder="Ngành hàng"
									value={value ? { value: value.ma_nganh, label: value.ten_nganh } : null}
									onChange={onChange}
									valueField="ma_nganh"
									labelField="ten_nganh"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="khach_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="customer"
									title="Khách hàng"
									placeholder="Khách hàng"
									value={value ? { value: value.ma_kh, label: value.ten_kh } : null}
									onChange={onChange}
									valueField="ma_kh"
									labelField="ten_kh"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="nhom_khach_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="group"
									q={{ group_type: 'CUSTOMER' }}
									title="Nhóm khách hàng"
									placeholder="Nhóm khách hàng"
									value={value ? { value: value.group_name, label: value.group_name } : null}
									onChange={onChange}
									valueField="group_name"
									labelField="group_name"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="kho"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmkho"
									title="Kho/Chi nhánh"
									placeholder="Kho/Chi nhánh"
									value={value ? { value: value.ma_kho, label: value.ten_kho } : null}
									onChange={onChange}
									valueField="ma_kho"
									labelField="ten_kho"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Giá bán lẻ"
							placeholder="Giá bán lẻ"
							inputProps={{ min: 0, ...register('gia_ban_le') }}
							defaultValue={getValues('gia_ban_le')}
							type="number"
							errorMsg={errors?.gia_ban_le?.message}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Giá bán buôn 1"
							placeholder="Giá bán buôn 1"
							inputProps={{ min: 0, ...register('gia_ban_buon') }}
							defaultValue={getValues('gia_ban_buon')}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Giá bán buôn 2"
							placeholder="Giá bán buôn 2"
							inputProps={{ min: 0, ...register('gia_ban_buon2') }}
							defaultValue={getValues('gia_ban_buon2')}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Giá bán buôn 3"
							placeholder="Giá bán buôn 3"
							inputProps={{ min: 0, ...register('gia_ban_buon3') }}
							defaultValue={getValues('gia_ban_buon3')}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Giá bán buôn 4"
							placeholder="Giá bán buôn 4"
							inputProps={{ min: 0, ...register('gia_ban_buon4') }}
							defaultValue={getValues('gia_ban_buon4')}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Tỷ lệ giảm giá"
							placeholder="Tỷ lệ giảm giá"
							inputProps={{ min: 0, ...register('ty_le_ck') }}
							defaultValue={getValues('ty_le_ck')}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Tiền giảm giá"
							placeholder="Tiền giảm giá"
							inputProps={{ min: 0, ...register('tien_ck') }}
							defaultValue={getValues('tien_ck')}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							inputProps={{ ...register('hieu_luc_tu') }}
							defaultValue={getValues('hieu_luc_tu')}
							title="Hiệu lực từ"
							placeholder="Hiệu lực từ"
							type="date"
							errorMsg={errors?.hieu_luc_tu?.message}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							inputProps={{ ...register('hieu_luc_den') }}
							defaultValue={getValues('hieu_luc_den')}
							title="Hiệu lực đến"
							placeholder="Hiệu lực đến"
							type="date"
							errorMsg={errors?.hieu_luc_den?.message}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Kênh áp dụng" placeholder="Kênh áp dụng" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Nhóm chương trình" placeholder="Nhóm chương trình" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Hạng khách hàng" placeholder="Hạng khách hàng" />
					</Grid>
					<Grid item xs={12} sm={6}></Grid>
					<Grid item xs={12} sm={6}>
						<CheckboxWrapper>
							<input
								style={{ accentColor: theme.palette.primary.main }}
								type="checkbox"
								id="gtgt"
								{...register('bao_gom_thue_gtgt')}
							/>
							<label htmlFor="gtgt">Giá chưa gồm thuế GTGT</label>
						</CheckboxWrapper>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CheckboxWrapper>
							<input
								style={{ accentColor: theme.palette.primary.main }}
								type="checkbox"
								id="using"
								{...register('con_su_dung')}
							/>
							<label htmlFor="using">Còn sử dụng</label>
						</CheckboxWrapper>
					</Grid>
				</Grid>
			</PriceFormStyled>
		</Dialog>
	);
}

export default PriceForm;
