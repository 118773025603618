import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { CloudDownloadRounded, DeleteRounded } from '@material-ui/icons';
import { Input } from 'antd';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useCore } from '../../../../../hooks/useCore';
import { isImage } from '../../../../../utils/helpers';
import Loading from '../../../components/loading/Loading';
import styles from '../../ListProject.module.css';

export default function AttachmentsTab({ hookForm }) {
	const core = useCore();
	const _id = hookForm.watch('_id');

	const [fetching, setFetching] = React.useState(false);
	const [attachments, setAttachments] = React.useState([]);

	// Fetch attachments
	React.useEffect(() => {
		const fetchAttachments = async () => {
			setFetching(true);

			try {
				const res = await core.apiCall({
					endpoint: 'file',
					params: { t: 1, q: { id_link: _id } },
				});
				setAttachments(res.data);
			} catch (error) {
				core.contextAlert(error);
			}

			setFetching(false);
		};

		if (_id) fetchAttachments();
		else setAttachments([]);
	}, [_id]);

	// Handle file upload
	const inputRef = React.useRef(null);
	const handleUploadFile = async (e) => {
		// Get file
		const file = e.target.files[0];
		if (!file) return; // No file selected

		try {
			// Create form data
			const formData = new FormData();
			formData.append('file', file);
			formData.append('id_link', _id);
			formData.append('collection_link', 'dmdt');
			formData.append('return', 'JSON');

			// Upload file
			const res = await core.apiCall({
				method: 'POST',
				endpoint: 'file',
				data: formData,
			});
			const newAttachment = res.data;

			// Add new attachment to list
			setAttachments((prev) => [...prev, newAttachment]);

			// Reset input
			inputRef.current.value = '';
		} catch (error) {
			core.contextAlert(error);

			// Reset input
			inputRef.current.value = '';
		}
	};

	const handleDelete = async (id) => {
		const confirm = await new Promise((resolve) => {
			core.contextAlert('Bạn có chắc chắn muốn xóa tệp đính kèm này?', (confirm) => resolve(confirm));
		});
		if (!confirm) return;

		try {
			// Delete file
			await core.apiCall({
				method: 'DELETE',
				endpoint: `file/${id}`,
			});

			// Remove attachment from list
			setAttachments((prev) => prev.filter((a) => a._id !== id));
		} catch (error) {
			core.contextAlert(error);
		}
	};

	const handleUpdate = async (id, data) => {
		try {
			// Update file
			const res = await core.apiCall({
				method: 'PUT',
				endpoint: `file/${id}`,
				data: { ...data, _id: id },
			});
			const newAttachment = res.data;

			// Update attachment in list
			setAttachments((prev) => prev.map((a) => (a._id === id ? newAttachment : a)));
		} catch (error) {
			core.contextAlert(error);
		}
	};

	return (
		<Box display='flex' flexDirection='column' height='100%' gridGap={8}>
			{/* Header (Title, add button) */}
			<input type='file' hidden ref={inputRef} onChange={handleUploadFile} />
			<button
				type='button'
				onClick={() => inputRef.current.click()}
				className='btn btn-fill'
				style={{ width: 'fit-content' }}
			>
				<span>Thêm tệp đính kèm</span>
			</button>

			{/* List of attachments */}
			{attachments.map((a) => (
				<AttechmentItem key={a._id} attachment={a} onDelete={handleDelete} onUpdate={handleUpdate} />
			))}

			{/* Loading */}
			{fetching && <Loading />}

			{/* Empty */}
			{!fetching && attachments.length === 0 && (
				<div className={styles['empty-file']}>Không có tệp đính kèm nào</div>
			)}
		</Box>
	);
}

const AttechmentItem = ({ attachment, onDelete, onUpdate }) => {
	const core = useCore();

	const isImg = isImage(attachment.file.originalname);
	const fileUrl = core.getFileUrl(attachment.download_link);

	const handleCheckIsAvatar = (_e, checked) => onUpdate(attachment._id, { is_avatar: checked });

	const handleUpdateNote = (mieu_ta) => onUpdate(attachment._id, { mieu_ta });

	const handleNoteBlur = (e) => {
		const note = e.target.value;
		// check if note is equal to current note
		if (note === attachment.mieu_ta) return;

		// update note
		handleUpdateNote(note);
	};

	const handleDownload = () => saveAs(fileUrl, attachment.file.originalname);
	const handleDelete = () => onDelete(attachment._id);

	return (
		<Box className={styles['file-item']} display='flex' gridGap={16}>
			{/* Icon */}
			<Grid item xs='auto'>
				<div style={{ width: 100 }}>
					{isImg ? (
						<img
							src={fileUrl}
							alt={attachment.file.originalname}
							style={{ width: 100, aspectRatio: '5/6', objectFit: 'cover', borderRadius: 12 }}
						/>
					) : (
						<FileIcon extension={attachment.file.extension} {...defaultStyles[attachment.file.extension]} />
					)}
				</div>
			</Grid>

			{/* Info */}
			<Grid item xs style={{ width: '100%', overflow: 'hidden' }}>
				{/* Name */}
				<div className={styles['file-name']}>{attachment.file.originalname}</div>

				{/* Upload info */}
				<div className={styles['file-upload-info']}>
					Tải lên bởi <b>{attachment.user_created}</b>
					{dayjs(attachment.date_created).format(' [lúc] HH:mm [ngày] DD/MM/YYYY')}
				</div>

				{/* Is Avatar */}
				<FormControlLabel
					control={<Checkbox />}
					label='Là hình đại diện'
					disabled={!isImg}
					checked={attachment.is_avatar}
					onChange={handleCheckIsAvatar}
					color='primary'
				/>

				{/* Note */}
				<Input.TextArea
					placeholder='Ghi chú'
					autosize
					onBlur={handleNoteBlur}
					defaultValue={attachment.mieu_ta}
				/>
			</Grid>

			{/* Delete button */}
			<div className={styles['file-action']}>
				<button type='button' className='btn btn-fill' onClick={handleDownload}>
					<CloudDownloadRounded />
				</button>

				<button type='button' className='btn btn-delete' onClick={handleDelete}>
					<DeleteRounded />
				</button>
			</div>
		</Box>
	);
};
