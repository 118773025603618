import { Grid } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import React from 'react';
import { useCore } from '../../../../../../hooks';
import FieldInput from '../../../../components/Dialog/FieldInput';
import InputContainer from '../../../../components/Dialog/InputContainer';
import FieldNumberInput from '../../../../components/Dialog/FieldNumberInput';
import { Controller, useForm } from 'react-hook-form';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import Loading from '../../../../components/loading/Loading';

// const listGroupProperties = [
// 	{ name: 'Màu sắc', value: 'color' },
// 	{ name: 'Kích thước', value: 'size' },
// 	{ name: 'Kiểu dáng', value: 'style' },
// 	{ name: 'Chất liệu', value: 'material' },
// 	{ name: 'Xuất xứ', value: 'origin' },
// 	{ name: 'Thương hiệu', value: 'brand' },
// ];

export default function PropertiesTab({ hookForm }) {
	const { register, getValues, control, setValue } = hookForm;
	const core = useCore();
	const [groups, setGroups] = React.useState([]);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await core.asyncGetList(core.token, 'group', { q: { group_type: 'DMTT' } });
				setGroups(data);
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InputContainer title="Ngành dược">
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<FieldInput title="Số đăng ký" inputProps={{ ...register('exfields.so_dang_ky') }} />
						</Grid>

						<Grid item xs={6}>
							<FieldInput title="Cách đóng gói" inputProps={{ ...register('exfields.cach_dong_goi') }} />
						</Grid>

						<Grid item xs={6}>
							<FieldInput title="Hoạt chất" inputProps={{ ...register('exfields.hoat_chat') }} />
						</Grid>

						<Grid item xs={6}>
							<FieldInput title="Hàm lượng" inputProps={{ ...register('exfields.ham_luong') }} />
						</Grid>

						<Grid item xs={6}>
							{/* <FieldInput title="Hãng sản xuất" inputProps={{ ...register('exfields.hang_sx') }} /> */}
							<Controller
								control={control}
								name="exfields.hang_sx"
								render={({ field: { value, onChange } }) => {
									const item = value ? { value, label: getValues('exfields.ten_hang_sx') } : null;
									const handleChange = (item) => {
										onChange(item?._id || null);
										setValue('exfields.ten_hang_sx', item?.group_name || null);
									};
									return (
										<FieldSelectApi
											title="Hãng sản xuất"
											placeholder="Chọn hãng sản xuất"
											api="group"
											q={{ group_type: 'HANGSX' }}
											valueField="_id"
											labelField="group_name"
											value={item}
											onChange={handleChange}
										/>
									);
								}}
							/>
						</Grid>

						<Grid item xs={6}>
							{/* <FieldInput title="Nước sản xuất" inputProps={{ ...register('exfields.nuoc_sx') }} /> */}
							<Controller
								control={control}
								name="exfields.nuoc_sx"
								render={({ field: { value, onChange } }) => {
									const item = value ? { value, label: getValues('exfields.ten_nuoc_sx') } : null;
									const handleChange = (item) => {
										onChange(item?._id || null);
										setValue('exfields.ten_nuoc_sx', item?.group_name || null);
									};
									return (
										<FieldSelectApi
											title="Nước sản xuất"
											placeholder="Chọn nước sản xuất"
											api="group"
											q={{ group_type: 'QUOCGIA' }}
											valueField="_id"
											labelField="group_name"
											value={item}
											onChange={handleChange}
										/>
									);
								}}
							/>
						</Grid>
					</Grid>
				</InputContainer>
			</Grid>

			{groups.map((gr) => (
				<Grid item xs={12} key={gr._id}>
					<InputContainer title={gr.group_name}>
						<PropertyGroup group={gr} goods={getValues()} />
					</InputContainer>
				</Grid>
			))}
		</Grid>
	);
}

function PropertyGroup({ group, goods }) {
	const core = useCore();
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState([]);

	const fetchData = async () => {
		try {
			const data = await core.asyncGetList(core.token, 'dmtt', {
				q: JSON.stringify({ ma_vt: goods.ma_vt, nh_tt: group._id }),
			});
			setData(data);
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		setLoading(true);
		fetchData().then(() => setLoading(false));
	}, []);

	const handleAdd = () => {
		const newData = [...data];
		newData.push({ ten_tt: '', gia_ban_le: 0, nh_tt: group._id, ma_vt: goods.ma_vt });
		setData(newData);
	};

	const handleSave = async (item, index) => {
		const newItem = await core.asyncPostList(core.token, 'dmtt', item);
		const newData = [...data];
		newData[index] = newItem;
		setData(newData);
	};

	const handleDelete = async (index) => {
		// delete new item
		if (!data[index]._id) {
			const newData = [...data];
			newData.splice(index, 1);
			setData(newData);
		}

		// delete old item
		else {
			await core.asyncDeleteList(core.token, 'dmtt', data[index]._id);
			const newData = [...data];
			newData.splice(index, 1);
			setData(newData);
		}
	};

	return (
		<Grid container spacing={2} justifyContent="flex-end">
			{data?.map((item, index) => (
				<Grid item xs={12} key={item._id || index}>
					<PropertyLine
						value={item}
						onSave={(item) => handleSave(item, index)}
						onDelete={() => handleDelete(index)}
					/>
				</Grid>
			))}

			{!loading && data?.length === 0 && (
				<Grid item xs={12} style={{ textAlign: 'center' }} className="empty-text">
					Không có thuộc tính nào
				</Grid>
			)}

			{loading && (
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Loading />
				</Grid>
			)}

			<Grid item xs="auto">
				<button onClick={handleAdd} className="btn btn-outline">
					Thêm mới
				</button>
			</Grid>
		</Grid>
	);
}

function PropertyLine({ value, onSave, onDelete }) {
	const {
		register,
		watch,
		control,
		setError,
		formState: { errors },
		clearErrors,
	} = useForm({ defaultValues: value });
	console.log('errors', errors);

	const isValid = (data) => {
		if (!data.ma_tt) return false;
		if (!data.ten_tt) return false;
		return true;
	};

	const changeRef = React.useRef(false);
	const handleSave = (data) => {
		if (changeRef.current) clearTimeout(changeRef.current);
		changeRef.current = setTimeout(() => {
			if (isValid(data))
				onSave(data)
					.then(() => clearErrors()) // clear error when save success
					.catch(({ error }) => setError('all', { message: error })); // set error when save fail
		}, 1000);
	};

	React.useEffect(() => {
		const subscription = watch((value) => {
			handleSave(value);
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs>
				<FieldInput title="Mã thuộc tính" inputProps={{ ...register('ma_tt') }} />
			</Grid>

			<Grid item xs>
				<FieldInput title="Thuộc tính" inputProps={{ ...register('ten_tt') }} />
			</Grid>

			<Grid item xs>
				<Controller
					control={control}
					name="gia_ban_le"
					render={({ field: { value, onChange } }) => (
						<FieldNumberInput title="Giá bán lẻ" value={value} onChange={onChange} />
					)}
				/>
			</Grid>

			<Grid item xs="auto">
				<button className="btn btn-danger" onClick={onDelete}>
					<DeleteRounded />
				</button>
			</Grid>

			{errors.all && (
				<Grid item xs={12}>
					<div className="error-text">{errors.all.message}</div>
				</Grid>
			)}
		</Grid>
	);
}
