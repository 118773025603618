import { Grid } from '@material-ui/core';
import React from 'react';
import FieldInput from '../../components/Dialog/FieldInput';

export default function ViettelContractTab({ hookForm }) {
	const { register } = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<FieldInput title='Địa chỉ API' inputProps={{ ...register('viettel_url_service') }} />
				<FieldInput title='Viettel version' inputProps={{ ...register('viettel_version') }} />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title='Tên đăng nhập' inputProps={{ ...register('viettel_user') }} />
				<FieldInput title='Mật khẩu' inputProps={{ ...register('viettel_password'), type: 'password' }} />
			</Grid>
		</Grid>
	);
}
