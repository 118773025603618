import { Button, Collapse, Form, Menu } from 'antd';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { HiChevronDown } from 'react-icons/hi';
import { useCore } from '../../../../../hooks';
import VModal from '../../../VComponent/VModal';
import TabDetail from '../TabItems/TabDetail';
import TabInfomation from '../TabItems/TabInfomation';
import TabTotal from '../TabItems/TabTotal';
import TabVat from '../TabItems/TabVat';

const defaultValues = { status: true };
function CopyVoucherBN1({ open, handleClose, data, title, api }) {
	const [isLoading, setIsLoading] = useState(false);
	const [isDataVoucherDetail, setIsDataVoucherDetail] = useState();
	const [isDataVoucherVatVao, setIsDataVoucherVatVao] = useState();
	const context = useContext(DefaultContext);
	const core = useCore();
	const [isFieldNt, setIsFieldNt] = useState({
		checkEdit: false,
		ma_nt: data[0]?.ma_nt,
		ty_gia: data[0]?.ty_gia,
	});
	const [isTotalVoucher, setIsTotalVoucher] = useState({
		t_tien_hd: 0,
		t_tien: data[0]?.t_tien || 0,
		t_thue_vao: data[0]?.t_thue_vao || 0,
	});
	const hookForm = useForm({
		defaultValues,
	});

	const {
		getValues,
		setValue,
		register,
		formState: { errors },
		control,
		handleSubmit,
		reset,
		// formState: { isSubmitting },
	} = hookForm;

	useEffect(() => {
		reset({
			...defaultValues,
			...data[data.length - 1],
		});
	}, [data, reset]);

	const onSubmit = async (data) => {
		setIsLoading(true);
		const isDataVoucherDetailNew = isDataVoucherDetail.filter((item) => item.tk_no !== '');
		const isDataVoucherVatVaoNew = isDataVoucherVatVao?.filter((item) => item.so_hd !== '');
		isDataVoucherVatVaoNew &&
			isDataVoucherVatVaoNew.forEach((item) => {
				item.line = '';
				!item.ngay_hd && (item.ngay_hd = new Date());
			});
		try {
			await context.apis.asyncPostList(context.userInfo.token, api, {
				...data,
				details: isDataVoucherDetailNew,
				vatvaos: isDataVoucherVatVaoNew,
			});
			toast.success('Tạo chứng từ thành công');
			// eslint-disable-next-line no-useless-catch
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const onSave = (data) =>
		onSubmit(data)
			.then(() => {
				handleClose();
			})
			.catch((error) => {
				core.contextAlert(error);
			});

	const onSaveAndCopy = (data) =>
		onSubmit(data)
			.then(() => {
				delete data._id;
				data.so_ct = '';
				data.ngay_ct = new Date();

				reset(data);
			})
			.catch((error) => {
				core.contextAlert(error);
			});

	const handleGetDataDetail = (data) => {
		setIsTotalVoucher({
			...isTotalVoucher,
			t_tien: data?.reduce((a, b) => a + b.tien, 0),
		});
		setIsDataVoucherDetail(data);
	};

	const handleGetDataVatVao = (data) => {
		setIsTotalVoucher({
			...isTotalVoucher,
			t_thue_vao: data?.reduce((a, b) => a + b.t_thue, 0),
		});
		setIsDataVoucherVatVao(data);
	};

	const scrollTo = (id) => {
		const el = document.getElementById(id);
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	const formSections = [
		{
			id: 'information',
			title: 'Thông tin',
			content: (
				<TabInfomation
					getValues={getValues}
					register={register}
					setValue={setValue}
					errors={errors}
					control={control}
					setIsFieldNt={setIsFieldNt}
					api={api}
				/>
			),
		},
		{
			id: 'detail',
			title: 'Chi tiết',
			content: (
				<TabDetail
					data={data[0]}
					readonly={false}
					handleGetDataDetail={handleGetDataDetail}
					view={false}
					isFieldNt={isFieldNt}
				/>
			),
		},
		{
			id: 'vatvaos',
			title: 'Thuế',
			content: (
				<TabVat
					data={data[0]}
					readonly={false}
					handleGetDataVatVao={handleGetDataVatVao}
					view={false}
					isFieldNt={isFieldNt}
					isTotalVoucher={isTotalVoucher}
				/>
			),
		},
		{
			id: 'total',
			title: 'Tổng',
			content: <TabTotal isTotalVoucher={isTotalVoucher} />,
		},
	];

	return (
		<VModal
			open={open}
			onClose={handleClose}
			destroyOnClose
			confirmLoading
			title={`${title} - tạo chứng từ mới`}
			width="98vw"
			height="98vh"
			footer={[
				<Button
					key="submit"
					type="primary"
					form="lead-form-full"
					htmlType="submit"
					onClick={handleSubmit(onSave)}
					id="btnSave"
				>
					Lưu
				</Button>,
				// <Button
				// 	key="submit"
				// 	type="primary"
				// 	form="lead-form-full"
				// 	htmlType="submit"
				// 	onClick={handleSubmit(onSaveAndCopy)}
				// 	id="btnSave"
				// >
				// 	Lưu & sao chép
				// </Button>,
				<Button key="reset" type="dashed" danger onClick={handleClose}>
					Bỏ qua
				</Button>,
			]}
			centered
		>
			<div className="mdf_container">
				<Menu
					defaultSelectedKeys={[formSections[0].id]}
					mode="vertical"
					items={formSections.map(({ id, title }) => ({
						key: id,
						label: title,
						title: title,
					}))}
					onClick={({ key }) => scrollTo(key)}
					className="w_menu_modal"
				/>

				<Form
					id="lead-form-full"
					// form={form}
					// onFinish={handleSubmit}
					// onError={handleError}
					onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} // prevent submit on enter
					className="mdf_content"
					labelWrap
				>
					{formSections.map(({ id, title, content }) => (
						<Collapse
							key={id}
							defaultActiveKey={['1']}
							size="small"
							expandIcon={({ isActive }) => (
								<HiChevronDown
									style={{
										transition: 'all 0.3s ease-in-out',
										transform: `rotate(${isActive ? 0 : -90}deg)`,
									}}
								/>
							)}
						>
							<Collapse.Panel header={title} key="1" id={id}>
								{content}
							</Collapse.Panel>
						</Collapse>
					))}

					{/* Placeholder */}
					<div style={{ minHeight: '20%' }} />
				</Form>
			</div>
		</VModal>
	);
}

export default CopyVoucherBN1;
