import React from "react";
import styled from "styled-components";
import { Box, Button, Typography, Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { BsFillCaretDownFill } from "react-icons/bs";
import { IoIosTimer } from "react-icons/io";
import { MdOutlineLocationOn } from "react-icons/md";
import { SaleContextSpa } from "../SpaSales";

const SpaFooterStyled = styled(Paper)`
	width: 100%;
	height: 42px;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SaleModes = styled(Box)`
	height: 34px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SaleModeButton = styled(Button)`
	height: 34px;
	display: flex;
	align-items: center;
	padding: 5px;
	gap: 5px;
	text-transform: none;
	&.active {
		/* background-color: ${(props) => props.bgColor}; */
		background-color: #4caf50;
		color: #fff;
		&:hover {
			background-color: #3b8f3d;
			color: #fff;
		}
	}
	&:hover {
		background-color: #e0e0e0;
	}
`;
const SaleButtonIcon = styled(Box)`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SaleButtonText = styled(Typography)`
	font-size: 13px;
`;
const SaleInfo = styled(Box)`
	height: 34px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SaleAgency = styled(Box)`
	height: 34px;
	display: flex;
	align-items: center;
	gap: 2px;
	background-color: #ededed;
	border-radius: 6px;
	cursor: pointer;
`;
const PhoneNumber = styled(Typography)`
	font-size: 12px;
	color: ${(props) => props.color}; ;
`;

function SpaFooter() {
	const theme = useTheme();
	const { kho } = React.useContext(SaleContextSpa);

	return (
		<SpaFooterStyled>
			<SaleModes>
				<SaleModeButton bgColor={theme.palette.primary.main}>
					<SaleButtonIcon>
						<AiOutlineThunderbolt fontSize="16px" />
					</SaleButtonIcon>
					<SaleButtonText>Bán nhanh</SaleButtonText>
				</SaleModeButton>
				<SaleModeButton
					className="active"
					bgColor={theme.palette.primary.main}
				>
					<SaleButtonIcon>
						<IoIosTimer fontSize="16px" />
					</SaleButtonIcon>
					<SaleButtonText>Bán thường</SaleButtonText>
				</SaleModeButton>
			</SaleModes>
			<SaleInfo>
				<SaleAgency>
					<SaleButtonIcon>
						<MdOutlineLocationOn fontSize="16px" />
					</SaleButtonIcon>
					<SaleButtonText>{kho?.ten_kho}</SaleButtonText>
					<SaleButtonIcon>
						<BsFillCaretDownFill fontSize="16px" />
					</SaleButtonIcon>
				</SaleAgency>
				<PhoneNumber color={theme.palette.primary.main}>
					0286 6866 067
				</PhoneNumber>
			</SaleInfo>
		</SpaFooterStyled>
	);
}

export default SpaFooter;
