import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { useCore } from '../../../../../../hooks';

const Row = styled(Box)`
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	background-color: #ededed;
`;
const TextDisplay = styled(Typography)`
	font-size: 13px;
	font-weight: ${(props) => (props.bold ? 550 : 400)};
`;

function KhoItem({ kho, product }) {
	const { asyncGetData, token, contextAlert } = useCore();
	const [tonKho, setTonKho] = React.useState();

	React.useEffect(() => {
		const getTonKho = async () => {
			try {
				const resp = await asyncGetData(token, 'dmvt', { ma_vt: product.ma_vt, ma_kho: kho.ma_kho });
				if (resp) {
					setTonKho(resp.ton00 || 0);
				} else {
					setTonKho(0);
				}
			} catch (error) {
				contextAlert(error);
			}
		};
		getTonKho();
	}, []);

	return (
		<Row>
			<TextDisplay>{kho?.ten_kho}</TextDisplay>
			<TextDisplay>{tonKho || 0}</TextDisplay>
		</Row>
	);
}

export default KhoItem;
