import React from "react";
import { Col, Input, Row } from "antd";
import { IoIosAddCircle } from "react-icons/io";
import { NavLink } from "react-router-dom";
import "./Member.css";
function Left(props) {
  return (
    <Col className="gutter-row left" span={8}>
      <div>
        <h5>Quy đổi giá trị đơn hàng thành điểm khách hàng</h5>
        <Row className="apply">
          <Col className="gutter-row" span={8}>
            <p>Áp dụng</p>
          </Col>
          <Col
            className="gutter-row"
            span={16}
            style={{
              display: "flex",
            }}
          >
            <label htmlFor="apply1">
              <input type="radio" name="1" id="apply1" />
              <span>Bật</span>
            </label>
            <label htmlFor="apply2">
              <input type="radio" name="1" id="apply2" />
              <span>Tắt</span>
            </label>
          </Col>
        </Row>
        <Row className="exchange_title">
          <Col className="gutter-row" span={12}>
            <p>Số tiền đơn hàng quy đổi</p>
          </Col>
          <Col className="gutter-row" span={12}>
            <p>Số điểm nhận được</p>
          </Col>
        </Row>
        <Row className="exchange-input">
          <Col className="gutter-row" span={10}>
            <Input
              addonAfter={
                <p
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  đ
                </p>
              }
              type="text"
              defaultValue="100.000"
            />
          </Col>
          <Col className="icon">
            <p>=</p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input addonAfter={<p>điểm</p>} type="text" defaultValue="1" />
          </Col>
        </Row>
        <div className="link_add">
          <IoIosAddCircle size={20} color="#3b9cf7" />
          <span>Cài đặt công thức quy đổi theo từng hạng</span>
        </div>
        <Row className="checkmark">
          <Col className="gutter-row checkmark_content" span={12}>
            <h6>Tính điểm khi khách hàng mua:</h6>
            <label htmlFor="service">
              <span>Dịch vụ</span>
              <input type="checkbox" name="checkmark" id="service" />
            </label>
            <label htmlFor="product">
              <span>Sản Phẩm</span>
              <input type="checkbox" name="checkmark" id="product" />
            </label>
            <label htmlFor="membercard">
              <span>Thẻ thành viên</span>
              <input type="checkbox" name="checkmark" id="membercard" />
            </label>
          </Col>
        </Row>
        <Row className="choose">
          <Col className="gutter-row" span={12}>
            <h6>Lựa chọn đổi điểm cho thu ngân:</h6>
            <Row>
              <Col span={12}>
                <label htmlFor="choosemark1">
                  <input type="radio" name="choose" id="choosemark1" />
                  <span>Bật</span>
                </label>
              </Col>
              <Col span={12}>
                <label htmlFor="choosemark2">
                  <input type="radio" name="choose" id="choosemark2" />
                  <span>Tắt</span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export default Left;
