import React from 'react';
import './Report.css';
import ReportTHNXT from './ReportTHNXT/ReportTHNXT';

function ReportManager() {
	const url = window.location.href;
	const urlArr = url.split('/');
	const urlLast = urlArr[urlArr.length - 1];
	return urlLast === 'thnxt' ? (
		<ReportTHNXT title={'Tổng hợp NXT theo NCC'} api={'thnxt'} />
	) : (
		<ReportTHNXT title={'Tổng hợp'} api={'pt'} />
	);
}

export default ReportManager;
