import React, { useState } from 'react';
import Layout from '../../components/LayoutContainer';
import Container from '../../Container';
import { formatCurrency, formatDateValue } from '../../Utils/MyUtils';
import SaleEndOfDayData from './SaleEndOfDayData';
import SaleEndOfDayFilter from './SaleEndOfDayFilter';

function SaleEndOfDayContent({ props }) {
    const [data, setData] = useState([
        {
            so_ct: 1,
            ngay_ct: `${new Date()}`,
            SL_order: '10',
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            t_thu: '80000',
            ghi_no: '0',
        },
        {
            so_ct: 2,
            ngay_ct: `${new Date()}`,
            SL_order: '10',
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            t_thu: '80000',
            ghi_no: '0',
        },
        {
            so_ct: 2,
            ngay_ct: `${new Date()}`,
            SL_order: '10',
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            t_thu: '80000',
            ghi_no: '0',
        },
        {
            so_ct: 2,
            ngay_ct: `${new Date()}`,
            SL_order: '10',
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            t_thu: '80000',
            ghi_no: '0',
        },
        {
            so_ct: 2,
            ngay_ct: `${new Date()}`,
            SL_order: '10',
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            t_thu: '80000',
            ghi_no: '0',
        },
        {
            so_ct: 2,
            ngay_ct: `${new Date()}`,
            SL_order: '10',
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            t_thu: '80000',
            ghi_no: '0',
        },
    ]);
    const onFilter = (data) => {
        console.log(data);
    };

    const columns = [
        {
            name: 'Mã chứng từ',
            selector: (row) => row.so_ct,
        },
        {
            name: 'Ngày chứng từ',
            selector: (row) => formatDateValue(row.ngay_ct),
        },
        {
            name: 'Số lượng',
            selector: (row) => row.SL_order,
        },
        {
            name: 'Tổng tiền hàng',
            selector: (row) => formatCurrency(row.t_tien),
        },
        {
            name: 'Giảm giá',
            selector: (row) => formatCurrency(row.t_ck),
        },
        {
            name: 'Doanh thu',
            selector: (row) => formatCurrency(row.t_tt),
        },
        {
            name: 'Thực Thu',
            selector: (row) => formatCurrency(row.t_thu),
        },
        {
            name: 'Ghi nợ',
            selector: (row) => formatCurrency(row.ghi_no),
        },
    ];
    return (
        <Layout FilterLeft={<SaleEndOfDayFilter onFilter={onFilter} />}>
            <SaleEndOfDayData data={data} columns={columns} />
        </Layout>
    );
}

const SaleEndOfDay = (props) => {
    return (
        <Container {...props} showDrawerIfIsDesktop={false}>
            <SaleEndOfDayContent />
        </Container>
    );
};

export default SaleEndOfDay;
