import dayjs from 'dayjs';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AiFillPrinter } from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { TfiReload } from 'react-icons/tfi';
import { useCore } from '../../../../hooks';
import VButton from '../../VComponent/VButton';
import { formatNumberWithSpaces } from '../../VoucherManager/Utils';
import LayoutContainer from '../../components/LayoutContainer';
import TableManager from '../../components/tableManager/TableManager';
import NumberComparisonFilter from '../../components/tableManager/components/NumberComparisonFilter';
import withContainer from '../../withContainer';
import ReportFilter from '../ReportFilter';
export const VoucherContext = createContext();

const columns = [
	{
		Header: 'Mã',
		accessor: 'ma_vt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
	},
	{
		Header: 'Tên',
		accessor: 'ten_vt',
		width: 350,
		minWidth: 300,
		maxWidth: 400,
	},
	{
		Header: 'Đvt',
		accessor: 'ma_dvt',
		width: 100,
		minWidth: 80,
		maxWidth: 400,
	},
	{
		Header: 'Tồn đầu',
		accessor: 'ton_dau',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'Dư đầu',
		accessor: 'du_dau',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'SL nhập',
		accessor: 'sl_nhap',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'Tiền nhập',
		accessor: 'tien_nhap',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'SL xuất',
		accessor: 'sl_xuat',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'Tiền xuất',
		accessor: 'tien_xuat',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'Tồn cuối',
		accessor: 'ton_cuoi',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
	{
		Header: 'Dư cuối',
		accessor: 'du_cuoi',
		width: 120,
		minWidth: 80,
		maxWidth: 400,
		Filter: NumberComparisonFilter,
		filter: 'between',
	},
];

function ReportTHNXT({ title, api }) {
	const core = useCore();
	const MenuTabTable = [
		// {
		// 	id: 7,
		// 	name: 'In',
		// 	icon: <AiFillPrinter />,
		// },
		// {
		// 	id: 8,
		// 	name: 'Nhập',
		// 	icon: <BiImport />,
		// },
		// {
		// 	id: 9,
		// 	name: 'Xuất',
		// 	icon: <BiExport />,
		// },
	];
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
		tu_ngay: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
		den_ngay: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
		groupBy: ['ma_vt', 'ma_kho'],
	});
	const [dataReportTHNXT, setDataReportTHNXT] = useState([]);
	const [dataSummary, setDataSummary] = useState([]);
	const [dataVoucherDetail, setDataVoucherDetail] = useState('');
	const [dataDoubleClick, setDataDoubleClick] = useState('');
	const context = useContext(DefaultContext);

	const skipResetRef = React.useRef(false);
	const updateMyData = (rowIndex, columnId, value) => {
		skipResetRef.current = true;
		setDataReportTHNXT((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};

	const getDataVoucherDetail = (data) => {
		setDataVoucherDetail(data);
	};

	const getDataReportTHNXT = useCallback(async () => {
		core.setProgressStatus(true);

		try {
			let { data } = await core.apiCall({
				endpoint: api,
				params: {
					...params,
				},
			});
			if (data) {
				const updatedData = data?.slice(0, -1);
				setDataReportTHNXT(updatedData);
				setDataSummary(data?.slice(-1));
			}
		} catch (error) {
			core.contextAlert(error);
		} finally {
			context.setProgressStatus(false);
		}
	}, [context, params]);
	const handleFilter = (filter) => {
		const newParams = { ...params };

		// Update params
		newParams.page = 1;
		newParams.groupBy = filter.types;
		newParams.tu_ngay = dayjs(filter.date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		newParams.den_ngay = dayjs(filter.nextDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		setParams({ ...newParams, ...filter.group });
	};

	useEffect(() => {
		handleSumTotal(dataReportTHNXT);
	}, [dataReportTHNXT]);

	const handleSumTotal = (data) => {
		let sum = 0;
		data.forEach((item) => {
			sum += item.t_tt_nt;
		});
		return formatNumberWithSpaces(sum);
	};

	// handle pagination
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	// on row click
	const onRowClick = (row) => {
		getDataVoucherDetail(row);
	};

	// on row double click
	const onRowDoubleClick = (row) => {
		setDataDoubleClick([row]);
	};

	useEffect(() => {
		getDataReportTHNXT();
	}, [params, getDataReportTHNXT]);

	return (
		<React.Fragment>
			<VoucherContext.Provider
				value={{
					getDataReportTHNXT,
				}}
			>
				<LayoutContainer>
					<div className="box_new_voucher column-start">
						<div className="box_voucher column-start">
							<div className="column-start box_voucher__header">
								<div className="flex-start box_voucher__header__title">
									<div>
										<TfiReload
											size={18}
											color="#206c77"
											style={{
												cursor: 'pointer',
												marginRight: '10px',
												verticalAlign: 'middle',
											}}
											onClick={() => {
												setParams({
													tu_ngay: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
													den_ngay: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
													groupBy: ['ma_vt', 'ma_kho'],
												});
											}}
										/>
									</div>
									<span>{title}</span>
								</div>
							</div>
							<div className="column-start box_voucher__body">
								<div className="box_voucher__body__content">
									<TableManager
										idTable={'tableReportTHNXT'}
										columns={columns}
										data={dataReportTHNXT || []}
										updateMyData={updateMyData}
										skipReset={skipResetRef.current}
										numberColumn={true}
										readonly={true}
										onRowClick={onRowClick}
										onRowDoubleClick={onRowDoubleClick}
										TbodyMaxHeight={'calc(100vh - 300px)'}
										TbodyMinHeight={'calc(100vh - 300px)'}
										// pagination={true}
										// isPage={params.page}
										// isLimit={params.limit}
										// totalRows={totalRows}
										handlePaginationChange={handlePaginationChange}
										toolBar={
											<div className="flex-start">
												<div className="filter_report">
													<VButton.Filter title="Tìm">
														<ReportFilter
															onFilter={handleFilter}
															title={'Bộ lọc báo cáo'}
															defaultSelectedItems={['ma_vt', 'ma_kho']}
														/>
													</VButton.Filter>
												</div>
												<div className="flex-start box_voucher__body__header">
													{MenuTabTable?.map((item) => {
														return (
															<button key={item.id} onClick={item.handle}>
																{item.icon}{' '}
																<span
																	style={{
																		marginLeft: '3px',
																		fontSize: '13px',
																	}}
																>
																	{item.name}
																</span>
															</button>
														);
													})}
												</div>
											</div>
										}
									/>
									<TableManager
										idTable={'tableReportSumTHNXT'}
										showTHead={false}
										columns={columns}
										data={dataSummary || []}
										updateMyData={updateMyData}
										skipReset={skipResetRef.current}
										readonly={true}
										onRowClick={onRowClick}
										onRowDoubleClick={onRowDoubleClick}
									/>
								</div>
							</div>
						</div>
					</div>
				</LayoutContainer>
			</VoucherContext.Provider>
		</React.Fragment>
	);
}

export default withContainer(ReportTHNXT);
