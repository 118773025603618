import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { GrEdit } from 'react-icons/gr';

export default function SelectInput({
    items = [],
    labelField = 'label',
    valueField = 'value',
    placeholder,
    onChange,
    editable,
    onEdit,
    className,
    defaultValue,
    clearable = true,
}) {
    const [selected, setSelected] = useState(defaultValue);

    const handleChange = (_e, newOption) => {
        setSelected(newOption);
        // eslint-disable-next-line no-unused-expressions
        onChange?.(newOption);
    };

    return (
        <Autocomplete
            disableClearable={!clearable}
            value={selected}
            options={items}
            getOptionLabel={(option) => option[labelField]}
            getOptionSelected={(option, value) =>
                option[valueField] === value[valueField]
            }
            fullWidth
            noOptionsText="Không có dữ liệu"
            ListboxProps={{
                style: {
                    fontSize: 12,
                },
            }}
            onChange={handleChange}
            renderInput={(params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                fontSize: 12,
                                flex: 1,
                            },
                            className: className,
                        }}
                    />

                    {editable && (
                        <div className="shadow__box__action">
                            {selected && (
                                <GrEdit
                                    className="shadow__box__action-item filter__select__action-item"
                                    onClick={() => onEdit?.(selected)}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        />
    );
}
