import { Checkbox, Chip } from '@material-ui/core';
import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import ProjectFilter from './ProjectFilter';
import FormProjectDialog from './dialog/FormProjectDialog';
import { progressData } from './project.data';

const ListProject = () => {
	const columns = [
		{
			name: 'Mã vụ việc',
			selector: (row) => row.ma_dt,
			width: '140px',
		},
		{
			name: 'Tên vụ việc, dự án',
			selector: (row) => row.ten_dt,
			width: '200px',
			wrap: true,
		},
		{
			name: 'Còn sử dụng',
			cell: (row) => <Checkbox checked={row.status} disabled />,
		},
		{
			name: 'Phụ trách',
			selector: (row) => row.phu_trach,
			width: '200px',
		},
		{
			name: 'Tiến độ',
			cell: (row) => {
				const progress = progressData.find((item) => item.value === row.progress);
				return (
					<Chip
						size='small'
						label={progress?.label}
						style={{ backgroundColor: progress?.color, color: 'white' }}
					/>
				);
			},
		},
	];

	return (
		<ListBase
			api='dmdt'
			title='Danh sách vụ việc, dự án'
			columns={columns}
			FilterComponent={ProjectFilter}
			FormDialog={FormProjectDialog}
		/>
	);
};

export default withContainer(ListProject);
