import { Checkbox, Chip, FormControlLabel, Grid, styled } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import DataTable from 'react-data-table-component';
import { Controller } from 'react-hook-form';
import { useCore } from '../../../../../../hooks';
import { formatDateDisplay } from '../../../../../../utils/helpers';
import FieldInput from '../../../../components/Dialog/FieldInput';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import InputContainer from '../../../../components/Dialog/InputContainer';

export default function ParcelDateTab({ hookForm }) {
	const { control, register } = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Controller
					name="exfields.kieu_lo"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldSelect
							title="Kiểu lô"
							items={[
								{ value: 1, label: 'Tính theo ngày nhập' },
								{ value: 2, label: 'Ngày sử dụng' },
							]}
							defaultValue={value}
							onChange={(item) => onChange(item?.value || null)}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="exfields.cach_xuat"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldSelect
							title="Cách xuất"
							items={[
								{ value: 1, label: 'Theo hạn sử dụng' },
								{ value: 2, label: 'Nhập trước xuất trước' },
								{ value: 3, label: 'Liên tục' },
								{ value: 4, label: 'Nhập sau xuất trước' },
							]}
							defaultValue={value}
							onChange={(item) => onChange(item?.value || null)}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={6}>
				<FieldInput
					title="TG sử dụng (ngày)"
					type="number"
					inputProps={{ ...register('exfields.tg_su_dung', { valueAsNumber: true }) }}
				/>
			</Grid>

			<Grid item xs={6}>
				<FieldInput
					title="TG bảo hành (ngày)"
					type="number"
					inputProps={{ ...register('exfields.tg_bao_hanh', { valueAsNumber: true }) }}
				/>
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="exfields.tao_lo"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							checked={!!value}
							onChange={(e) => onChange(e.target.checked)}
							control={<Checkbox size="small" />}
							label="Tạo lô sau khi nhập"
						/>
					)}
				/>
			</Grid>

			<Grid item xs={6}>
				<Controller
					name="ma_lo_yn"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FormControlLabel
							checked={!!value}
							onChange={(e) => onChange(e.target.checked)}
							control={<Checkbox size="small" />}
							label="Theo dõi theo lô"
						/>
					)}
				/>
			</Grid>

			<Grid item xs={12}>
				<InputContainer title="Danh sách lô" defaultShow>
					<ParcelList hookForm={hookForm} />
				</InputContainer>
			</Grid>
		</Grid>
	);
}

const statusOptions = [
	{ value: 1, label: 'Còn hàng', style: { color: 'var(--success-color)', borderColor: 'var(--success-color)' } },
	{ value: 2, label: 'Hết hàng', style: { color: 'var(--warning-color)', borderColor: 'var(--warning-color)' } },
];

const getStatus = (value) => {
	if (value.ton_cuoi > 0) return statusOptions[0];
	return statusOptions[1];
};

const ExpireDate = styled(Chip)({
	backgroundColor: 'var(--info-color)',
	color: '#fff',
	borderRadius: 6,
	height: 'auto',
	padding: '8px 0',
	fontSize: 12,
	fontWeight: 500,
});

const Status = styled(Chip)({
	backgroundColor: '#fff',
	borderRadius: 16,
	height: 'auto',
	padding: '6px 0',
	fontSize: 12,
	fontWeight: 500,
	border: '1px solid',
});

const ParcelList = ({ hookForm }) => {
	const core = useCore();
	const [data, setData] = React.useState([]);
	const [total, setTotal] = React.useState();

	React.useEffect(() => {
		const getParcelList = async () => {
			try {
				const { data } = await core.apiCall({
					endpoint: 'thnxt',
					params: {
						tu_ngay: dayjs(hookForm.getValues('date_created')).format('YYYY-MM-DD'),
						ma_vt: hookForm.getValues('ma_vt'),
						groupBy: 'ma_lo,han_sd',
						den_ngay: dayjs().format('YYYY-MM-DD'),
					},
				});
				const total = data.pop();
				setData(data);
				setTotal(total);
			} catch (error) {
				core.contextAlert(error.message, 'error');
			}
		};
		getParcelList();
	}, []);

	const columns = [
		{
			name: 'Mã lô',
			cell: (row) => (row.ma_lo ? <ExpireDate label={row.ma_lo} /> : <div className="empty-text">(Trống)</div>),
			grow: 2,
		},
		{ name: 'Mã kho', selector: (row) => row.ma_kho },
		{ name: 'Số lượng', selector: (row) => row.ton_cuoi, right: true },
		{ name: 'Hạn sử dụng', selector: (row) => formatDateDisplay(row.han_sd), right: true },
		// {
		// 	name: 'Trạng thái',
		// 	cell: (row) => {
		// 		const status = getStatus(row);
		// 		return <Status label={status.label} style={status.style} />;
		// 	},
		// 	right: true,
		// },
	];

	const [filter, setFilter] = React.useState({ search: '', status: null });
	const onFilterChange = (field, value) => setFilter((prev) => ({ ...prev, [field]: value }));

	return (
		<Grid container spacing={1}>
			<Grid item xs={6}>
				<FieldInput
					title="Tìm kiếm"
					placeholder="Tìm kiếm theo mã lô"
					onChange={(e) => onFilterChange('search', e.target.value)}
				/>
			</Grid>

			<Grid item xs={3}>
				<FieldInput title="Số lượng" inputProps={{ readOnly: true }} value={total?.ton_cuoi || 0} />
			</Grid>

			<Grid item xs={3}>
				<FieldSelect
					title="Trạng thái"
					items={statusOptions}
					defaultValue={filter.status}
					onChange={(item) => onFilterChange('status', item?.value || null)}
				/>
			</Grid>

			<Grid item xs={12}>
				<DataTable
					columns={columns}
					data={data.filter((item) => {
						// filter search
						if (filter.search) {
							const isMatchSearch = item.ma_lo.toLowerCase().includes(filter.search.toLowerCase());
							if (!isMatchSearch) return false;
						}

						// filter status
						if (filter.status) {
							const isAvailable = item.ton_cuoi > 0;

							if (filter.status === 1 && !isAvailable) return false;

							if (filter.status === 2 && isAvailable) return false;
						}

						return true;
					})}
					persistTableHead
					noDataComponent={<div className="empty-data-table">Không có dữ liệu</div>}
				/>
			</Grid>
		</Grid>
	);
};
