import React from 'react';
import Dialog from '../../components/Dialog/Dialog';

function NotiDialog({ open, handleClose, title, content }) {
	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			title={title}
			classNameContent={'dialog__content_edit_voucher_body'}
			actions={[
				// eslint-disable-next-line react/jsx-key
				<button className="btn btn-fill" onClick={handleClose}>
					<div>Đóng</div>
				</button>,
			]}
		>
			<div
				style={{
					padding: '10px',
					fontSize: '14px',
					fontWeight: 'bold',
					textAlign: 'center',
					color: '#999',
				}}
			>
				{content}
			</div>
		</Dialog>
	);
}

export default NotiDialog;
