import styled from "styled-components";
import { Box, Paper } from "@material-ui/core";
import { Typography } from "antd";

export const BarcodeStyled = styled(Box)`
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;
export const BarcodeHeader = styled(Paper)`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
`;
export const SearchWrapper = styled(Box)`
  width: 300px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #ccc;
`;
export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 13px;
`;
export const ListInput = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
`;
export const InputGroup = styled(Box)`
  height: 34px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const InputLabel = styled.label`
  font-size: 13px;
  font-weight: 550;
  color: #000;
`;
export const Select = styled.select`
  width: 100px;
  height: 34px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #ccc;
  &:focus {
    border: none;
    outline: 2px solid #4bac4d;
  }
`;
// content
export const BarCodeContentStyled = styled(Box)`
  width: 100%;
  height: calc(100vh - 50px - 42px - 10px - 10px);
  padding-top: 10px;
  display: flex;
  gap: 10px;
`;
export const ContentLeft = styled(Paper)`
  width: 30%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  overflow: auto;
`;
export const ProductInfo = styled(Paper)`
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
`;
export const Icon = styled(Box)`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: red;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
`;
export const IconOption = styled(Box)`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ededed;
  }
`;
export const ProductName = styled(Typography)`
  font-size: 13px;
  line-height: 14px;
  flex: 1;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const InputNumber = styled.input`
  border: none;
  outline: none;
  width: 70px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
`;
export const ContentRight = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  overflow: auto;
`;
export const BarcodeWrapper = styled(Box)`
  padding: 5px;
`;
export const BarcodeMain = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const ImageBarcodeWrapper = styled(Box)`
  width: 100%;
  & svg {
    width: 100%;
    height: 100%;
  }
`;
export const ProductPrice = styled(Typography)`
  font-size: 14px;
  font-weight: 550;
  text-align: center;
  flex-shrink: 0;
  line-height: 14px;
`;
export const Number = styled(Box)`
  padding: 5px;
  border-radius: 4px;
  background-color: #ededed;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
`;
