import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import { vi } from 'date-fns/locale';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-hot-toast';
import { GrAddCircle } from 'react-icons/gr';
import { HiOutlineArrowPath } from 'react-icons/hi2';
import { useCore } from '../../../hooks';
import { findTreeItem, getTreeGroups } from '../ListGoods/goods.util';
import FormGoodsGroupDialog from '../ListGoodsGroup/Dialog/FormGoodsGroupDialog';
import VButton from '../VComponent/VButton';
import FilterListCheckbox from '../components/filter/FilterListCheckbox';
import FilterListRatioDnD from '../components/filter/FilterListRatioDnD';
import { typeOfReport } from './dataFilter';
import { BsArrowRightShort } from 'react-icons/bs';
import FieldSelectApi from '../components/Dialog/FieldSelectApi';

const searchFields = ['ma_kh', 'ten_kh', 'so_ct', 'dien_giai'];

export default function ReportFilter({ onFilter, title, defaultSelectedItems }) {
	const [form] = Form.useForm();
	const [params, setParams] = useState({
		types: defaultSelectedItems || [],
		date: new Date(),
		nexDate: new Date(),
	});
	const [groups, setGroups] = useState([]);
	useEffect(() => {
		getGroups();
	}, []);
	const core = useCore();
	const getGroups = async () => {
		const data = await core.asyncGetList(core.token, 'dmnvt', {
			limit: 500, // Get all
		});

		setGroups(getTreeGroups(data)); // Get tree groups
	};
	const [modal, setModal] = useState({ name: '', data: {} });
	const openModal = (name, data) => setModal({ name, data });
	const closeModal = () => setModal({ name: '', data: {} });
	const handleSubmitGroup = async (data) => {
		try {
			await core.asyncPostList(core.token, 'dmnvt', data);
			getGroups();
		} catch (error) {
			toast.error(error);
		}
	};

	useEffect(() => {
		onFilter({
			...params,
		});
	}, [params]);

	const handleSubmit = (values) => {
		const q = { $or: [] };

		// Tìm kiếm
		const search = values.search?.trim() || '';
		searchFields.forEach((field) => {
			q.$or.push({ [field]: { $regex: search, $options: 'i' } });
		});

		// Xử lý bộ lọc
		setParams({
			...params,
			...q,
		});
	};

	const handleReset = () => {
		form.resetFields();
		form.submit();
		form.setFieldsValue({
			types: defaultSelectedItems || [],
			date: new Date(),
			nexDate: new Date(),
			group: {
				ma_dvcs: undefined,
				ma_vt: undefined,
				ten_vt: undefined,
			},
		});
		setParams((prevParams) => ({
			...prevParams,
			types: defaultSelectedItems || [],
			date: new Date(),
			nexDate: new Date(),
			group: {
				ma_dvcs: undefined,
				ma_vt: undefined,
				ten_vt: undefined,
			},
		}));
	};

	const handleFilter = (field, value) => {
		form.setFieldsValue({ [field]: value });
		form.submit();
		setParams({
			...params,
			[field]: value,
		});
	};

	const handleFilterGroup = (value) => {
		const group = findTreeItem(groups, value);
		if (!group) {
			setParams({
				...params,
				group: {
					ma_nvt: undefined,
					ten_nvt: undefined,
				},
			}); // clear filter
		} else {
			setParams({
				...params,
				group: {
					ma_nvt: group._id,
					ten_nvt: group.ten_nvt,
				},
			});
		}
	};

	return (
		<div className="filter_container">
			<div className="filter_title">
				<span>{title}</span>
				{/* <Tooltip title="Đặt lại bộ lọc">
					<VButton
						type="text"
						icon={<HiOutlineArrowPath />}
						onClick={handleReset}
					/>
				</Tooltip> */}
			</div>
			<Form layout="vertical" form={form} onFinish={handleSubmit} className="form_filter_report">
				{/* <Form.Item label="Tìm kiếm" name="search">
					<Input placeholder="Tìm kiếm theo mã, tên, số chứng từ, diễn giải..." prefix={<SearchOutlined />} />
				</Form.Item> */}
				<div className="date_filter_report">
					<div
						style={{
							width: '60px',
						}}
					>
						<DatePicker
							placeholderText="Chọn ngày"
							className="date_picker_payment"
							selected={dayjs(params.date).toDate()}
							onChange={(date) => {
								setParams({
									...params,
									date: dayjs(date).toDate(),
								});
							}}
							dateFormat={'dd/MM/yyyy'}
							locale={vi}
						/>
					</div>
					<span className="flex-center">
						<BsArrowRightShort size={'20px'} />
					</span>
					<div
						style={{
							width: '60px',
						}}
					>
						<DatePicker
							placeholderText="Chọn ngày"
							className="date_picker_payment"
							selected={dayjs(params.nexDate).toDate()}
							onChange={(date) => {
								setParams({
									...params,
									nexDate: dayjs(date).toDate(),
								});
							}}
							dateFormat={'dd/MM/yyyy'}
							locale={vi}
						/>
					</div>
				</div>
				<FieldSelectApi
					title="Vật tư"
					placeholder="Nhập vật tư"
					api="dmvt"
					valueField="ma_vt"
					labelField="ten_vt"
					value={params.group?.dmvt}
					q={{ status: true }}
					t={1}
					onChange={(value) => {
						if (!value) {
							setParams({
								...params,
								group: {
									ma_vt: undefined,
									ten_vt: undefined,
								},
							}); // clear filter
						} else {
							setParams({
								...params,
								dmvt: value,
								group: {
									ma_vt: value.ma_vt,
									ten_vt: value.ten_vt,
								},
							});
						}
					}}
				/>
				<FieldSelectApi
					title="Đơn vị"
					placeholder="Nhập đơn vị"
					api="dvcs"
					valueField="ma_dvcs"
					labelField="ten_dvcs"
					value={params.group?.dvcs}
					q={{ status: true }}
					t={1}
					onChange={(value) => {
						if (!value) {
							setParams({
								...params,
								group: {
									ma_dvcs: undefined,
								},
							}); // clear filter
						} else {
							setParams({
								...params,
								dvcs: value,
								group: {
									ma_dvcs: value._id,
								},
							});
						}
					}}
				/>
				<FilterListCheckbox
					title="Báo cáo theo"
					items={typeOfReport}
					onChange={(value) => handleFilter('types', value)}
					collapsable
					defaultSelectedItems={defaultSelectedItems || []}
					isSetCollapsed={true}
				/>
				{/* Filter by group */}
				<FormGoodsGroupDialog
					open={modal.name === 'group'}
					onClose={closeModal}
					data={modal.data}
					onSubmit={handleSubmitGroup}
				/>
				<FilterListRatioDnD
					title="Nhóm sản phẩm"
					items={groups}
					actions={[<GrAddCircle key="add" onClick={() => openModal('group', {})} />]}
					onChange={handleFilterGroup}
					collapsable
					editable
					onEdit={(item) => {
						console.log('Edit', item);
						openModal('group', item);
					}}
					searchable
					isSetCollapsed={true}
				/>
			</Form>
		</div>
	);
}
