import { Radio, RadioGroup } from '@material-ui/core';
import React, { useState } from 'react';
import FilterBox from './FilterBox';

function RadioRow({ label, checked, value }) {
    return (
        <div className="filter__check__list-item">
            <Radio
                size="small"
                style={{
                    padding: 0,
                }}
                value={value}
                checked={checked}
            />

            <label>{label}</label>
        </div>
    );
}

export default function FilterListRatio({
    title,
    items,
    labelField = 'label',
    valueField = 'value',
    defaultValue,
    onChange,
    collapsable,
    actions,
}) {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const onSelect = (e) => {
        const value = e.target.value;

        setSelectedValue(value);
        // eslint-disable-next-line no-unused-expressions
        onChange?.(value);
    };

    return (
        <FilterBox title={title} collapsable={collapsable} actions={actions}>
            <RadioGroup
                value={selectedValue}
                onChange={onSelect}
                className="filter__check__list"
            >
                {items.map((item) => {
                    const value = item[valueField];
                    const label = item[labelField];
                    const isChecked = selectedValue === value;

                    return (
                        <RadioRow
                            isChecked={isChecked}
                            key={value}
                            label={label}
                            value={value}
                        />
                    );
                })}
            </RadioGroup>
        </FilterBox>
    );
}
