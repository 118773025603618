import React from 'react';
import NumberRangeFilter from './NumberRangeFilter';

function DefaultFilter({ column: { filterValue, preFilteredRows, setFilter, filter_type, id } }) {
	const count = preFilteredRows.length;
	return (
		<React.Fragment>
			{filter_type === 'number' ? (
				<NumberRangeFilter column={{ filterValue, preFilteredRows, setFilter, id }} />
			) : (
				<input
					tabIndex={-1}
					value={filterValue || ''}
					onChange={(e) => {
						setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
					}}
					placeholder={`Tìm kiếm ${count} dòng...`}
				/>
			)}
		</React.Fragment>
	);
}

export default DefaultFilter;
