import { Box, TextField, Tooltip, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import DefaultContext from "flexbiz-core/components/auth/Context";
import { unicode2Ascii } from "flexbiz-core/utils";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { GrFormAdd } from "react-icons/gr";
import styled from "styled-components";
import { SaleContextSpa } from "../SpaSales";
import SpaPackageDialog from "../spaSalesDialog/SpaPackageDialog";

const SearchIcon = styled(Box)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const SearchStyled = styled(Autocomplete)`
	width: 200px;
	height: 34px;
	border-radius: 6px;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #000;
		}
	}
`;
const CustomerItem = styled(Typography)`
	padding: 5px;
	font-size: 13px;
`;
const TextDisplay = styled(Typography)`
	font-size: 13px;
`;
const NoOptionComponent = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
const NoOptionText = styled(Typography)`
	text-align: center;
	font-size: 13px;
	font-style: italic;
`;

function SearchCustomer({ voucher, handleSavePBL }) {
	const theme = useTheme();
	const context = useContext(DefaultContext);
	const { getVoucher } = useContext(SaleContextSpa);
	const timerRef = useRef();
	const customerNameRef = useRef("");

	const [customers, setCustomers] = useState([]);
	const [isIdCustomer, setIsIdCustomer] = useState("");
	const [isDataPackage, setIsDataPackage] = useState([]);

	const [search, setSearch] = useState("");
	const [searchText, setSearchText] = useState("");
	const [load, setLoad] = useState(1);
	const [openDialogPackage, setOpenDialogPackage] = useState(false);

	const handleSelectCustomer = async (customer) => {
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.ma_kh = customer.ma_kh;
		await handleSavePBL(voucherCopy);
		getVoucher();
		setSearchText("");
		setLoad(load + 1);
	};

	// handle blur
	const handleBlur = () => {
		setSearch("");
	};
	// handle close dialog
	const handleCloseDialogPackage = () => {
		setOpenDialogPackage(false);
	};

	// handle open dialog
	const handleOpenDialogPackage = () => {
		setOpenDialogPackage(true);
	};

	// get customer
	const getCustomer = async () => {
		if (!search) {
			setCustomers([]);
			return;
		}
		let condition = {
			$or: [],
		};
		//
		condition.$or.push({ ma_kh: { $regex: search, $options: "i" } });
		condition.$or.push({ ten_kh: { $regex: search, $options: "i" } });
		let $search = await unicode2Ascii(search);
		condition.$or.push({ $text: { $search: search + " " + $search } });

		const resp = await context.apis.asyncGetList(
			context.userInfo.token,
			"customer",
			{ condition }
		);
		setCustomers(resp);
	};
	// set search khi search text thay doi
	useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);
	// get customer khi search thay doi
	useEffect(() => {
		getCustomer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);
	// get current customer
	useEffect(() => {
		if (voucher && voucher.ma_kh) {
			const getCustomerByMa = async () => {
				const resp = await context.apis.asyncGetData(
					context.userInfo.token,
					"customer",
					{ ma_kh: voucher.ma_kh }
				);
				customerNameRef.current = resp.ten_kh;
				setIsIdCustomer(resp.ma_kh);
				if (resp.ma_kh !== isIdCustomer) {
					setIsDataPackage([]);
				}
				const resPbl = await context.apis.asyncGetList(
					context.userInfo.token,
					"pbl",
					{ condition: { ma_kh: resp.ma_kh, trang_thai: 1 } }
				);
				resPbl.forEach((item) => {
					item.details.forEach(async (detail) => {
						const respPack = await context.apis.asyncGetData(
							context?.userInfo?.token,
							"dmvt",
							{ ma_vt: detail?.ma_vt, is_package: true }
						);

						// Thêm dữ liệu vào mảng servicePack và kiểm tra null
						if (respPack !== null) {
							// Kiểm tra xem mã khách hàng đã tồn tại trong mảng chưa
							const index = isDataPackage.findIndex(
								(item) => item.ma_kh === respPack.ma_kh
							);
							// Nếu chưa tồn tại thì thêm vào mảng
							if (index === -1) {
								setIsDataPackage([...isDataPackage, respPack]);
								console.log("isDataPackage", [...isDataPackage, respPack])
							}
						}
					});
				});
			};
			getCustomerByMa();
		} else {
			customerNameRef.current = "";
			setIsDataPackage([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [voucher]);

	return (
		<>
			<SearchStyled
				key={load}
				clearOnEscape
				onChange={(e, newValue) => handleSelectCustomer(newValue)}
				onBlur={handleBlur}
				forcePopupIcon={false}
				options={customers}
				getOptionLabel={(option) => option}
				filterOptions={(option) => option}
				renderOption={(option) => {
					return (
						<CustomerItem>
							<TextDisplay>{option.ten_kh}</TextDisplay>
							{option.dien_thoai && (
								<TextDisplay>{option.dien_thoai}</TextDisplay>
							)}
						</CustomerItem>
					);
				}}
				noOptionsText={
					<NoOptionComponent>
						<NoOptionText>Không có khách hàng</NoOptionText>
					</NoOptionComponent>
				}
				renderInput={(params) => (
					<TextField
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={
							customerNameRef.current
								? customerNameRef.current
								: "Khách lẻ"
						}
						{...params}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							startAdornment: (
								<SearchIcon>
									<CiSearch fontSize="20px" />
								</SearchIcon>
							),
							endAdornment: (
								<Tooltip
									arrow
									placement="top"
									title="Thêm khách hàng"
								>
									<SearchIcon
									// onClick={() => setOpenDialog(true)}
									>
										<GrFormAdd fontSize="20px" />
									</SearchIcon>
								</Tooltip>
							),
						}}
						variant="outlined"
					/>
				)}
			/>
			{isDataPackage.length > 0 && (
				<button
					onClick={handleOpenDialogPackage}
					className="btn btn-edit"
				>
					Gói DV đã mua
				</button>
			)}
			<SpaPackageDialog
				open={openDialogPackage}
				handleClose={handleCloseDialogPackage}
				data={isDataPackage}
			/>
		</>
	);
}

export default SearchCustomer;
