import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { Box, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { AiFillPrinter, AiOutlinePlusCircle } from "react-icons/ai";
import { TiArrowLeftThick } from "react-icons/ti";
import { VscClose } from "react-icons/vsc";
import { BiCaretRightCircle, BiCaretLeftCircle } from "react-icons/bi";
import { BsUpcScan, BsArrowRepeat } from "react-icons/bs";
import { cloneDeep } from "lodash";
import DefaultContext from "flexbiz-core/components/auth/Context";
import { SaleContextSpa } from "../SpaSales";
import SearchProduct from "./SearchProduct";
import Confirm from "../../components/newSale/components/confirm/Confirm";

const SpaHeaderStyled = styled(Box)`
	width: 100%;
	height: 42px;
	/* background-color: ${(props) => props.bgColor}; */
	background-color: #fff;
	border-radius: 6px;
	display: flex;
	align-items: center;
	padding: 6px 4px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;
const SearchWrapper = styled(Box)`
	height: 34px;
	border-radius: 6px;
	display: flex;
	align-items: center;
`;

const ScanQRCode = styled(Box)`
	margin-left: 10px;
	color: #000;
	width: 34px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
const AddBill = styled(Box)`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	margin-left: 20px;
	cursor: pointer;
`;
const ButtonDirectBill = styled(Box)`
	width: 25px;
	height: 25px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	cursor: pointer;
`;
const ListBillWrapper = styled(Box)`
	width: 310px;
	height: 34px;
	overflow: auto;
	margin-left: 5px;
`;
const ListBill = styled(Box)`
	position: relative;
	width: fit-content;
	height: 34px;
	display: flex;
	align-items: center;
	gap: 5px;
`;
const BillItem = styled(Box)`
	width: 100px;
	height: 80%;
	flex-shrink: 0;
	color: ${(props) => (props.active ? "#fff" : "#000")};
	background-color: ${(props) => (props.active ? "#4caf50" : "")};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
		0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
	cursor: pointer;
	&:hover {
		background-color: ${(props) => (props.active ? "#3b8f3d" : "#e0e0e0")};
		color: ${(props) => (props.active ? "#fff" : "#333")};
	}
`;
const BiilItemIcon = styled(Box)`
	display: ${(props) => (props.active ? "flex" : "none")};
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	border-radius: 50%;
	cursor: pointer;
`;
const SaleActions = styled(Box)`
	margin-left: auto;
	height: 34px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SaleActionIcon = styled(Box)`
	width: 34px;
	height: 34px;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

function SpaBillItem({ onSelectBill, handleDeleteBill, index }) {
	const confirmRef = React.useRef();
	const { indexVoucher } = React.useContext(SaleContextSpa);
	return (
		<>
			<Confirm
				ref={confirmRef}
				content={`Xác nhận xóa hóa đơn ${index + 1}`}
				onConfirm={() => {
					handleDeleteBill(index);
				}}
			/>
			<BillItem
				onClick={() => onSelectBill(index)}
				active={index === indexVoucher}
			>
				Hóa đơn {index + 1}
				<Tooltip arrow placement="top" title="Xóa hóa đơn">
					<BiilItemIcon
						active={index === indexVoucher}
						onClick={confirmRef?.current?.open}
					>
						<VscClose fontSize="14px" />
					</BiilItemIcon>
				</Tooltip>
			</BillItem>
		</>
	);
}

function SpaHeader({ getVoucher }) {
	const theme = useTheme();
	const context = useContext(DefaultContext);
	const {
		vouchers,
		indexVoucher,
		setIndexVoucher,
		voucher,
		ma_kho,
		ma_ban,
		handleDeletePBL,
	} = useContext(SaleContextSpa);
	const listBillRef = useRef();

	// handle add bill
	const handleAddBill = async () => {
		const voucher = {
			ma_kho: ma_kho,
			ma_ban: ma_ban,
			so_ct: "PBL",
			trang_thai: "1",
			so_khach: 1,
			details: [],
			tien_ck_hd: 0,
			ty_le_ck_hd: 0,
			exfields: {
				dmckhd: [],
			},
		};
		let rs = await context.apis.asyncPostList(
			context.userInfo.token,
			"pbl",
			voucher
		);
		await getVoucher();
		setIndexVoucher(0);
	};
	// handle select bill
	const handleSelectBill = (index) => {
		setIndexVoucher(index);
	};
	// handle delete bill
	const handleDeleteBill = async (index) => {
		if (vouchers.length === 1) return;
		const voucherCopy = cloneDeep(voucher);
		console.log("voucherCopy", voucherCopy);
		voucherCopy.trang_thai = "9";
		await handleDeletePBL(voucherCopy);

		if (index === 0) {
			setIndexVoucher(0);
		} else if (index === indexVoucher) {
			setIndexVoucher(index - 1);
		}
		getVoucher();
	};
	// handle next bill
	const handleNextBill = () => {
		listBillRef.current.scrollLeft += 105;
	};
	// handle previous bill
	const handlePreviousBill = () => {
		listBillRef.current.scrollLeft -= 105;
	};

	return (
		<>
			<SpaHeaderStyled bgColor={theme.palette.primary.main}>
				<SearchWrapper>
					<SearchProduct />
					<ScanQRCode>
						<BsUpcScan fontSize="16px" />
					</ScanQRCode>
				</SearchWrapper>
				<Tooltip arrow placement="top" title="Thêm hóa đơn">
					<AddBill onClick={handleAddBill}>
						<AiOutlinePlusCircle fontSize="18px" />
					</AddBill>
				</Tooltip>
				{vouchers.length > 3 && (
					<ButtonDirectBill onClick={handlePreviousBill}>
						<BiCaretLeftCircle fontSize="18px" />
					</ButtonDirectBill>
				)}
				<ListBillWrapper ref={listBillRef} className="hidden-scroll-y">
					<ListBill>
						{vouchers.map((voucher, index) => (
							<SpaBillItem
								key={voucher._id}
								index={index}
								onSelectBill={handleSelectBill}
								handleDeleteBill={handleDeleteBill}
							/>
						))}
					</ListBill>
				</ListBillWrapper>
				{vouchers.length > 3 && (
					<ButtonDirectBill onClick={handleNextBill}>
						<BiCaretRightCircle fontSize="18px" />
					</ButtonDirectBill>
				)}
				<SaleActions>
					<SaleActionIcon>
						<TiArrowLeftThick fontSize="18px" />
					</SaleActionIcon>
					<SaleActionIcon>
						<BsArrowRepeat fontSize="18px" />
					</SaleActionIcon>
					<SaleActionIcon>
						<AiFillPrinter fontSize="18px" />
					</SaleActionIcon>
				</SaleActions>
			</SpaHeaderStyled>
		</>
	);
}

export default SpaHeader;
