import React, { useState, useEffect, useRef } from 'react';

function formatNumber(number) {
	const parts = number?.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	if (parts[1]) {
		parts[1] = parts[1].slice(0, 2);
	}
	return parts.join('.');
}

function EditableCellNumber({ initialValue, index, id, updateMyData, readonly, handleOnChange }) {
	const [inputValue, setInputValue] = useState(formatNumber(initialValue?.toString()));
	const [isIdFocus, setIsIdFocus] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const inputRef = useRef(null);
	const boxFocusRef = useRef(null);

	const formatInputValue = (input) => {
		// bỏ số 0 đầu tiên
		if (input[0] === '0') {
			input = input.slice(1);
		}
		// eslint-disable-next-line no-useless-escape
		let formatted = input.replace(/[^\d\.]/g, '');
		const decimalIndex = formatted.indexOf('.');
		if (decimalIndex > -1) {
			formatted = formatted.slice(0, decimalIndex) + '.' + formatted.slice(decimalIndex + 1).slice(0, 2);
		}
		formatted = formatNumber(formatted);
		setInputValue(formatted);
	};

	const onChange = (e) => {
		const input = e.target;
		const oldValue = input.value;
		const newValue = e.target.value;

		const selectionStart = input.selectionStart;
		// const selectionEnd = input.selectionEnd;

		if (newValue.length <= 16) {
			// only allow up to 16 digits (including decimals)
			formatInputValue(newValue);
		}

		// set cursor position after formatting
		const formattedValue = formatNumber(newValue);
		const diff = formattedValue.length - oldValue.length;
		const newPosition = Math.max(0, selectionStart + diff);
		inputRef.current.setSelectionRange(newPosition, newPosition);

		// update parent component
		handleOnChange(newValue);
	};

	const onFocus = () => {
		setIsIdFocus(true);
		// move cursor to end of input value
		inputRef.current.selectionStart = inputRef.current.value.length;
		inputRef.current.selectionEnd = inputRef.current.value.length;
	};

	const onBlur = () => {
		// eslint-disable-next-line no-useless-escape
		let parsedValue = parseFloat(
			// eslint-disable-next-line no-useless-escape
			inputValue.replace(/[^\d\.]/g, '').replace(',', '.')
		);
		if (isNaN(parsedValue)) {
			parsedValue = 0;
		}
		updateMyData(index, id, parsedValue);
		setIsIdFocus(false);
	};

	useEffect(() => {
		readonly ? setIsFocused(false) : setIsFocused(isIdFocus);
	}, [isIdFocus, readonly]);

	useEffect(() => {
		formatInputValue(initialValue?.toString());
	}, [initialValue]);

	useEffect(() => {
		if (isFocused && boxFocusRef.current) {
			boxFocusRef.current.classList.add('active_focus_input');
		} else if (boxFocusRef.current) {
			boxFocusRef.current.classList.remove('active_focus_input');
		}
	}, [isFocused]);

	// const onKeyDown = (e) => {
	// 	if (e.key === 'Enter') {
	// 		e.preventDefault();
	// 		inputRef.current.focus();
	// 		setIsIdFocus(!isIdFocus);
	// 	}
	// };

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
			}}
		>
			{readonly ? (
				<input
					readOnly={true}
					value={inputValue}
					style={{
						// fontSize: "13px",
						padding: '0px 10px',
						width: '100%',
						height: '100%',
						textAlign: 'right',
					}}
				/>
			) : (
				<>
					<input
						placeholder="0"
						title={inputValue ? inputValue : ''}
						readOnly={readonly ? readonly : !isFocused}
						min={0}
						ref={inputRef}
						value={inputValue}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						style={{
							textAlign: 'right',
						}}
						id={index + '_' + id}
					/>
				</>
			)}
		</div>
	);
}

export default EditableCellNumber;
