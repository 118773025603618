import React from 'react';
import Select from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import FieldBox from '../Dialog/FieldBox';

function SelectBase({ title, isMulti = false, placeholder, options = [], fieldBoxProps, selectStyle = {} }) {
	const themeMui = useTheme();
	return (
		<FieldBox title={title} {...fieldBoxProps}>
			<Select
				placeholder={placeholder}
				isMulti={isMulti}
				name="colors"
				options={options}
				menuPortalTarget={document.body}
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: 9999 }),
					placeholder: (base) => ({ ...base, fontSize: '13px' }),
					control: (base) => ({
						...base,
						border: 'none',
						borderBottom: '1px solid #ccc',
						boxShadow: 'none',
					}),
					valueContainer: (base) => ({ ...base, padding: '0px' }),
					option: (base) => ({ ...base, fontSize: '13px' }),
					indicatorSeparator: (base) => ({ ...base, display: 'none' }),
					...selectStyle,
				}}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary: themeMui.palette.primary.main,
					},
				})}
			></Select>
		</FieldBox>
	);
}

export default SelectBase;
