import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCore } from '../../../../hooks';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';

// password regex 8-20 characters, at least one uppercase letter, one lowercase letter, one number and one special character
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
const passwordMsg =
	'Mật khẩu phải có ít nhất 8 ký tự, bao gồm ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt';

export default function ChangePasswordDialog({ open, onClose, data }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		getValues,
	} = useForm();

	useEffect(() => {
		if (open) reset({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const core = useCore();
	const onSave = async ({ newPassword, reNewPassword }) => {
		try {
			// call api
			await core.apiCall({
				method: 'POST',
				endpoint: 'changePasswordByAdmin',
				withIdApp: false,
				data: {
					email: data.email,
					newPassword,
					reNewPassword,
				},
			});

			// show toast
			core.contextAlert('Đổi mật khẩu thành công!');

			// close dialog
			onClose();
		} catch (error) {
			core.contextAlert(error);
		}
	};

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title='Đổi mật khẩu'
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className='btn btn-grey' onClick={onClose}>
					Hủy
				</button>,
			]}
			width={800}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FieldInput
						title='Mật khẩu mới'
						placeholder='Nhập mật khẩu mới'
						inputProps={{
							...register('newPassword', {
								required: 'Vui lòng nhập mật khẩu',
								pattern: { value: passwordRegex, message: passwordMsg },
							}),
							type: 'password',
						}}
						check
						errorMsg={errors?.newPassword?.message}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldInput
						title='Nhập lại mật khẩu mới'
						placeholder='Nhập lại mật khẩu mới'
						inputProps={{
							...register('reNewPassword', {
								required: 'Vui lòng nhập mật khẩu',
								pattern: { value: passwordRegex, message: passwordMsg },
								validate: (value) => value === getValues('newPassword') || 'Mật khẩu không khớp',
							}),
							type: 'password',
						}}
						check
						errorMsg={errors?.reNewPassword?.message}
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
}
