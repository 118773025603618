import { Checkbox, Col, Form, Input, Row } from 'antd';
import React from 'react';

export default function AccountInput() {
	return (
		<Row>
			<Col span={12}>
				<Form.Item name="of_user" label="ID" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item name="password" label="Password" wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
					<Input.Password />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					name="status"
					label="Is Active"
					valuePropName="checked"
					wrapperCol={{ span: 18 }}
					labelCol={{ span: 6 }}
				>
					<Checkbox />
				</Form.Item>
			</Col>
		</Row>
	);
}
