import React from 'react';
import FilterBox from './FilterBox';

export default function FilterSearch({ onSearch, title = 'Tìm kiếm' }) {
	const handleKeyUp = (e) => {
		// handle search when press enter
		if (e.key === 'Enter') {
			// eslint-disable-next-line no-unused-expressions
			onSearch?.(e.target.value);
			e.target.select();
		}
	};

	const handleFocus = (e) => e.target.select();

	return (
		<FilterBox title={title}>
			<input
				type='text'
				placeholder='Tìm kiếm'
				className='shadow__box__search'
				onKeyUp={handleKeyUp}
				onFocus={handleFocus}
			/>
		</FilterBox>
	);
}
