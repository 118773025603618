import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';

const defaultValues = {};
export default function FormUnitDialog({ open, onClose, data, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
	} = useForm({ defaultValues });

	useEffect(() => {
		if (open) reset(data || defaultValues);
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa kênh bán' : 'Thêm kênh bán'}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose}>
					Hủy
				</button>,
			]}
		>
			<Grid container spacing={2}>
				{/* <Grid item xs={12}>
					<FieldInput
						title="Mã kênh bán"
						placeholder="Nhập mã kênh bán"
						inputProps={{
							...register('ma_dvt', {
								required: 'Vui lòng nhập mã kênh bán',
							}),
						}}
						check
						errorMsg={errors?.ma_dvt?.message}
					/>
				</Grid> */}

				<Grid item xs={12}>
					<FieldInput
						title="Tên kênh bán"
						placeholder="Nhập tên kênh bán"
						inputProps={{
							...register('group_name', {
								required: 'Vui lòng nhập tên kênh bán',
							}),
						}}
						check
						errorMsg={errors?.group_name?.message}
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
}
