import React from 'react';
import styled from 'styled-components';
import { Box, Button, Typography, Paper } from '@material-ui/core';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { IoIosTimer } from 'react-icons/io';
import { SaleContext } from '../SaleContainer';
import DrawerPayment from './DrawerPayment';
import { useTheme } from '@material-ui/core/styles';
import ButtonBase from '../components/button/ButtonBase';
import OpenShiftDialog from './OpenShiftDialog';
import CloseShiftDialog from './CloseShiftDialog';

const SaleFooterStyled = styled(Paper)`
	flex-shrink: 0;
	width: 100%;
	height: 42px;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SaleModes = styled(Box)`
	height: 34px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const SaleModeButton = styled(Button)`
	height: 34px;
	display: flex;
	align-items: center;
	padding: 5px;
	gap: 5px;
	text-transform: none;
	background-color: ${(props) => (props.active ? props.bgColor : '')};
	color: ${(props) => (props.active ? '#fff' : '')};
	pointer-events: ${(props) => (props.active ? 'none' : 'all')};
`;
const SaleButtonIcon = styled(Box)`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const SaleButtonText = styled(Typography)`
	font-size: 13px;
`;
const SaleInfo = styled(Box)`
	height: 34px;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const PhoneNumber = styled(Typography)`
	font-size: 12px;
	color: ${(props) => props.color};
`;
const ButtonPrint = styled(Button)`
	font-size: 13px;
	font-weight: 550;
	padding: 10px 30px;
	height: 34px;
	background-color: #ededed;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	&:hover {
		background-color: #ededed;
	}
`;
const ButtonCheckout = styled(Button)`
	font-size: 13px;
	font-weight: 550;
	padding: 10px 30px;
	height: 34px;
	color: #fff;
	background-color: ${(props) => props.bgColor};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	&:hover {
		background-color: ${(props) => props.bgColor};
	}
`;

function SaleFooter() {
	const theme = useTheme();
	const { print, saleMode, setSaleMode } = React.useContext(SaleContext);
	const [openPayment, setOpenPayment] = React.useState(false);
	const [openShiftDialog, setOpenShiftDialog] = React.useState(false);
	const [opneCloseShiftDialog, setOpneCloseShiftDialog] = React.useState(false);

	// handle open
	const handleOpenPayment = () => {
		setOpenPayment(true);
	};
	// handle close
	const handleClosePayment = () => {
		setOpenPayment(false);
	};
	// handle sale mode change
	const handleSaleModeChange = (mode) => {
		localStorage.setItem('saleMode', mode);
		setSaleMode(mode);
	};

	return (
		<>
			{saleMode === 1 && (
				<DrawerPayment open={openPayment} handleClose={handleClosePayment} handleOpen={handleOpenPayment} />
			)}
			{openShiftDialog && <OpenShiftDialog open={openShiftDialog} onClose={() => setOpenShiftDialog(false)} />}
			{opneCloseShiftDialog && (
				<CloseShiftDialog open={opneCloseShiftDialog} onClose={() => setOpneCloseShiftDialog(false)} />
			)}
			<SaleFooterStyled>
				<SaleModes>
					<SaleModeButton
						active={saleMode === 2}
						onClick={() => handleSaleModeChange(2)}
						bgColor={theme.palette.primary.main}
					>
						<SaleButtonIcon>
							<AiOutlineThunderbolt fontSize="16px" />
						</SaleButtonIcon>
						<SaleButtonText>Bán nhanh</SaleButtonText>
					</SaleModeButton>
					<SaleModeButton
						onClick={() => handleSaleModeChange(1)}
						active={saleMode === 1}
						bgColor={theme.palette.primary.main}
					>
						<SaleButtonIcon>
							<IoIosTimer fontSize="16px" />
						</SaleButtonIcon>
						<SaleButtonText>Bán thường</SaleButtonText>
					</SaleModeButton>
				</SaleModes>
				<SaleInfo>
					{saleMode === 1 && (
						<>
							<ButtonPrint onClick={() => print('2')}>IN</ButtonPrint>
							<ButtonCheckout onClick={handleOpenPayment} bgColor={theme.palette.primary.main}>
								THANH TOÁN
							</ButtonCheckout>
						</>
					)}
					{/* <ButtonBase onClick={() => setOpenShiftDialog(true)}>Mở ca</ButtonBase>
					<ButtonBase onClick={() => setOpneCloseShiftDialog(true)}>Đóng ca</ButtonBase> */}
					<PhoneNumber color={theme.palette.primary.main}>028.668.660.67</PhoneNumber>
				</SaleInfo>
			</SaleFooterStyled>
		</>
	);
}

export default SaleFooter;
