import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldSelect from '../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';

export default function FormUnitDialog({ open, onClose, data, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		watch,
		control,
	} = useForm();

	useEffect(() => {
		if (open) reset(data || {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa đơn vị cơ sở' : 'Thêm đơn vị cơ sở'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className='btn btn-grey' onClick={onClose}>
					Hủy
				</button>,
			]}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FieldInput
						title='Mã đơn vị cơ sở'
						placeholder='Nhập mã đơn vị cơ sở'
						inputProps={{
							...register('ma_dvcs', {
								required: 'Vui lòng nhập mã đơn vị cơ sở',
							}),
						}}
						check
						errorMsg={errors?.ma_dvcs?.message}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldInput
						title='Tên đơn vị cơ sở'
						placeholder='Nhập tên đơn vị cơ sở'
						inputProps={{
							...register('ten_dvcs', {
								required: 'Vui lòng nhập tên đơn vị cơ sở',
							}),
						}}
						check
						errorMsg={errors?.ten_dvcs?.message}
					/>
				</Grid>

				<Grid item xs={12}>
					<Controller
						name='status'
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControlLabel
								label='Còn sử dụng'
								control={<Checkbox size='small' />}
								checked={!!value}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Controller
						control={control}
						name='visible_to'
						render={({ field: { onChange, value } }) => (
							<FieldSelect
								title='Có giá trị với'
								placeholder='Chọn giá trị'
								items={[
									{
										label: 'Mọi người',
										value: 0,
									},
									{
										label: 'Chỉ người tạo',
										value: 1,
									},
									{
										label: 'Những người sau',
										value: 2,
									},
									{
										label: 'Nhóm người dùng',
										value: 5,
									},
								]}
								onChange={(item) => onChange(item.value)}
								defaultValue={value || 0}
								disableClearable
							/>
						)}
					/>

					{watch('visible_to') === 2 && (
						<Controller
							name='visible_to_users'
							control={control}
							rules={{
								validate: (value) => value?.length > 0 || 'Vui lòng chọn người được phép xem',
							}}
							render={({ field: { value, onChange } }) => {
								const items = value?.map((item) => ({ value: item, label: item, email: item })) || [];
								const handleChange = (items) => onChange(items?.map((item) => item.email) || []);

								return (
									<FieldSelectApi
										placeholder='Nhập người được phép xem'
										api='participant'
										multi
										valueField='email'
										labelField='email'
										onChange={handleChange}
										value={items}
										check
										errorMsg={errors?.visible_to_users?.message}
									/>
								);
							}}
						/>
					)}

					{watch('visible_to') === 5 && (
						<Controller
							name='visible_to_usergroups'
							control={control}
							rules={{
								validate: (value) => value?.length > 0 || 'Vui lòng chọn nhóm người được phép xem',
							}}
							render={({ field: { value, onChange } }) => (
								<FieldSelectApi
									placeholder='Nhập nhóm người được phép xem'
									api='usergroup'
									multi
									valueField='_id'
									labelField='group_name'
									onChange={onChange}
									value={value}
									check
									errorMsg={errors?.visible_to_groups?.message}
								/>
							)}
						/>
					)}
				</Grid>
			</Grid>
		</Dialog>
	);
}
