import { BASE_URL } from '../../../utils/constants';
import { goodsType } from './data';

export const getImageField = (index) => {
	let imgField = 'picture';

	if (index) imgField += index + 1;

	const thumbField = `${imgField}_thumb`;

	return {
		imgField,
		thumbField,
	};
};

export const getListImageSrc = (goods, accessToken, size = 'S') => {
	const listImage = new Array(5).fill(null);
	for (let i = 0; i < 5; i++) {
		const { imgField, thumbField } = getImageField(i);

		if (!goods[imgField]) continue;

		let imageSrc = BASE_URL + goods[imgField] + '?size=' + size;
		let thumbnailSrc = goods[thumbField] ? BASE_URL + goods[thumbField] + '?size=' + size : imageSrc;

		if (accessToken) {
			imageSrc += '&access_token=' + accessToken;
			thumbnailSrc += '&access_token=' + accessToken;
		}

		listImage[i] = {
			image: imageSrc,
			imgField,
			thumbnail: thumbnailSrc,
			thumbField,
			index: i,
		};
	}

	return listImage;
};

export const getTypeGoods = (goods) => {
	if (!goods) return goodsType.GOODS.value; // default

	const typeGoods = Object.keys(goodsType).find((key) => {
		const filter = goodsType[key].filter;
		return Object.keys(filter).every((key) => goods[key] === filter[key]);
	});

	return typeGoods || goodsType.GOODS.value;
};

export const getTreeGroups = (data) => {
	const convertItem = (item) => {
		const sub = getSubGroups(data, item._id);
		const filterValues = [item._id];

		sub?.forEach((subItem) => filterValues.push(subItem.value));

		return {
			label: item.ten_nvt,
			sub,
			value: item._id,
			filterValues,
			...item,
		};
	};

	const getSubGroups = (data, parentId) => {
		const subGroups = data.filter((item) => item.nh_me === parentId).map(convertItem);
		return subGroups.length ? subGroups : null;
	};

	return data.filter((item) => !item.nh_me).map(convertItem);
};

export const findTreeItem = (tree, value) => {
	let result = null;
	for (const item of tree) {
		if (item.value === value) {
			result = item;
			break;
		}

		if (item.sub) {
			result = findTreeItem(item.sub, value);
			if (result) break;
		}
	}
	return result;
};
