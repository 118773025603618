import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./KanbanView.css";

const itemsFromBackend = [
  { id: "item-1", title: "Item 1", content: ["Content 1", "Content 1.2"] },
  { id: "item-2", title: "Item 2", content: "Content 2" },
  { id: "item-3", title: "Item 3", content: "Content 3" },
  { id: "item-4", title: "Item 4", content: "Content 4" },
  { id: "item-5", title: "Item 5", content: "Content 5" },
  { id: "item-6", title: "Item 6", content: "Content 6" },
  { id: "item-7", title: "Item 7", content: "Content 7" },
  { id: "item-8", title: "Item 8", content: "Content 8" },
  { id: "item-9", title: "Item 9", content: "Content 9" },
  { id: "item-10", title: "Item 10", content: "Content 10" },
  { id: "item-11", title: "Item 11", content: "Content 11" },
  { id: "item-12", title: "Item 12", content: "Content 12" },
];

const columnsFromBackend = {
  inProgress: {
    id: "in-progress",
    title: "In Progress",
    itemsIds: ["item-1", "item-2", "item-3", "item-4"],
  },
  completed: {
    id: "completed",
    title: "Completed",
    itemsIds: ["item-5", "item-6"],
  },
  pending: {
    id: "pending",
    title: "Pending",
    itemsIds: ["item-7", "item-8"],
  },
  testing: {
    id: "testing",
    title: "Testing",
    itemsIds: ["item-9", "item-10"],
  },
  skipped: {
    id: "skipped",
    title: "Skipped",
    itemsIds: [],
  },
  delivery: {
    id: "delivery",
    title: "Delivery",
    itemsIds: ["item-11", "item-12"],
  },
  delivery2: {
    id: "delivery2",
    title: "Delivery2",
    itemsIds: [],
  },
};

export default function KanbanView({ data }) {
  const [columns, setColumns] = useState(columnsFromBackend);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.itemsIds];
      const destItems = [...destColumn.itemsIds];
      const [removed] = sourceItems.splice(source.index, 1);

      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          itemsIds: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          itemsIds: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.itemsIds];
      const [removed] = copiedItems.splice(source.index, 1);

      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          itemsIds: copiedItems,
        },
      });
    }
  };

  return (
    <div className="kanbanView">
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column]) => {
          return (
            <div className="columnSection" key={columnId}>
              <div className="columnHeader">
                <p className="columnHeader__title">{column.title}</p>

                <p className="columnHeader__qty">{column.itemsIds.length}</p>
              </div>

              <div className="columnMain">
                <Droppable droppableId={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="columnMain__container"
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#CCD5AE"
                            : "#ebebee",
                        }}
                      >
                        {column.itemsIds.map((itemId, index) => {
                          const item = itemsFromBackend.find(
                            (item) => item.id === itemId
                          );
                          return (
                            <Draggable
                              key={itemId}
                              draggableId={itemId}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    className="columnMain__card"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      backgroundColor: snapshot.isDragging
                                        ? "#FEFAE0"
                                        : "#FFFFFF",
                                      boxShadow: snapshot.isDragging
                                        ? "3px 3px 10px 0px rgba(0, 0, 0, 0.25)"
                                        : "none",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {item.title}
                                    </p>

                                    <p
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item.content}
                                    </p>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
}
