import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'react-slideshow-image/dist/styles.css';
import { cleanData, copyData } from '../../../../../utils/helpers';
import Dialog from '../../../components/Dialog/Dialog';
import TabContainer from '../../../components/tab/TabContainer';
import { goodsType } from '../../data';
import { getListImageSrc, getTypeGoods } from '../../goods.util';
import AccountingTab from './tabs/AccountingTab';
import AuthorizationTab from './tabs/AuthorizationTab';
import DetailDescriptionTab from './tabs/DetailDescriptionTab';
import ImageTab from './tabs/ImageTab';
import InfoTab from './tabs/InfoTab';
import IngredientTab from './tabs/IngredientTab';
import PackageServicesTab from './tabs/PackageServicesTab';
import UnitConversionTab from './tabs/UnitConversionTab';
import ParcelDateTab from './tabs/ParcelDateTab';
import PropertiesTab from './tabs/PropertiesTab';

const defaultValues = { sold_on_pos: true, status: true, shared: true, gia_xuat: '1',trang_thai:"1",ma_vt:"dmvt" };
export default function FormGoodsDialog({ open, handleClose, data, getData }) {
	const type = getTypeGoods(data);
	const hookForm = useForm({ defaultValues });
	const context = useContext(DefaultContext);

	const {
		handleSubmit,
		reset,
		formState: { isSubmitting, errors },
		getValues,
		watch,
	} = hookForm;
	const [images, setImages] = React.useState([]);

	useEffect(() => {
		if (open) {
			reset({ ...defaultValues, ...data });
			setImages(getListImageSrc(getValues(), context.userInfo.token));
		}
	}, [data, open]);

	const isGoods = type === goodsType.GOODS.value;
	const isService = type === goodsType.SERVICE.value;
	const isPackage = type === goodsType.PACKAGE.value;

	const tabs = [
		{
			label: 'Thông tin',
			component: <InfoTab hookForm={hookForm} />,
		},
		{
			label: 'Mô tả',
			component: <DetailDescriptionTab hookForm={hookForm} />,
		},
		{
			label: 'Hình ảnh',
			component: <ImageTab hookForm={hookForm} images={images} setImages={setImages} />,
		},
	];
	if (isGoods) {
		tabs.push(
			{
				label: 'Quy đổi ĐVT',
				component: <UnitConversionTab hookForm={hookForm} images={images} setImages={setImages} />,
			},
			{
				label: 'Lô/Date',
				component: <ParcelDateTab hookForm={hookForm} images={images} setImages={setImages} />,
			},
			{
				label: 'Thuộc tính',
				component: <PropertiesTab hookForm={hookForm} />,
			}
		);
	}
	if (isService) {
		tabs.push({
			label: 'Nguyên liệu tiêu hao',
			component: <IngredientTab hookForm={hookForm} />,
		});
	}
	if (isPackage) {
		tabs.push({
			label: 'Dịch vụ trong gói',
			component: <PackageServicesTab hookForm={hookForm} />,
		});
	}

	tabs.push({
		label: 'Kế toán',
		component: <AccountingTab hookForm={hookForm} />,
	});
	tabs.push({
		label: 'Phân quyền',
		component: <AuthorizationTab hookForm={hookForm} />,
	});

	const onSubmit = async (data) => {
		if (data.visible_to_usergroups) data.visible_to_usergroups = data.visible_to_usergroups.map((item) => item._id);
		data.trang_thai="";
		data.ma_ct="dmvt";
		await context.apis.asyncPostList(context.userInfo.token, 'dmvt', data);
		await getData();
	};

	const onSave = (data) => onSubmit(data).then(handleClose).catch(context.alert);

	const onSaveAndNew = (data) =>
		onSubmit(data)
			.then(() => {
				// clear data
				data = cleanData(data);

				// set type to current type
				if (type === goodsType.GOODS.value) data.isGoods = true;
				else data = { ...goodsType[type].filter, ...data };

				// reset form
				reset({ ...defaultValues, ...data });

				// reset image list
				const images = getListImageSrc(data, context.userInfo.token);
				setImages(images);
			})
			.catch(context.alert);

	const onSaveAndCopy = (data) =>
		onSubmit(data)
			.then(() => reset(copyData(data)))
			.catch(context.alert);

	let title = watch('_id') ? 'Chỉnh sửa ' : 'Thêm mới ';
	title += goodsType[type].label;

	const submit = (func) => () =>
		handleSubmit(func)().then(() => {
			const errorsArr = Object.values(errors)?.map((item) => item.message) || [];
			const alertTitle = errorsArr.map((item, i) => <p key={i}>{item}</p>);
			if (errorsArr.length) {
				context.alert(<>{alertTitle}</>);
			}
		});

	return (
		<Dialog
			open={open}
			loading={isSubmitting}
			handleClose={handleClose}
			title={title}
			actions={[
				<button className="btn btn-fill" onClick={submit(onSave)} disabled={isSubmitting} type="button">
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={submit(onSaveAndNew)} disabled={isSubmitting} type="button">
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={submit(onSaveAndCopy)} disabled={isSubmitting} type="button">
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={handleClose} disabled={isSubmitting}>
					Hủy
				</button>,
			]}
		>
			<div style={{ width: 1000, maxWidth: '100%' }}>
				<TabContainer data={data} tabs={tabs} />
			</div>
		</Dialog>
	);
}
