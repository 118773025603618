import React from 'react';
import Dialog from '../../../Dialog/Dialog';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ButtonBase from '../button/ButtonBase';

const TextConfirm = styled(Typography)`
	text-align: center;
	font-size: 16px;
	padding: 10px;
`;

function Confirm({ content, cancelText = 'Hủy', confirmText = 'Xác nhận', onConfirm }, ref) {
	const [open, setOpen] = React.useState(false);

	// handle open
	const handleOpen = () => {
		setOpen(true);
	};
	// handle close
	const handleClose = () => {
		setOpen(false);
	};

	React.useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<Dialog
			title="Xác nhận"
			open={open}
			handleClose={handleClose}
			width="400px"
			actions={[
				<ButtonBase onClick={handleClose} variant="outline">
					{cancelText}
				</ButtonBase>,
				<ButtonBase onClick={onConfirm} variant="contained">
					{confirmText}
				</ButtonBase>,
			]}
		>
			<TextConfirm>{content || 'Bạn có chắc muốn xóa'}</TextConfirm>
		</Dialog>
	);
}

export default React.forwardRef(Confirm);
