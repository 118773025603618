import React from 'react';
import TabDetail from './TabItems/TabDetail';
import TabContainer from '../../components/tab/TabContainer';

function DetailVoucherPNK({ data, readonly, view, selectRow, handleGetDataDetail, isFieldNt }) {
	return (
		<div className="dialog__content_edit_voucher__body box_detail_voucher_detail">
			{(!view || data) && (
				<TabContainer
					tabs={[
						{
							label: 'Chi tiết',
							component: (
								<TabDetail
									data={data}
									readonly={readonly}
									handleGetDataDetail={handleGetDataDetail}
									view={view}
									selectRow={selectRow}
									isFieldNt={isFieldNt}
								/>
							),
						},
					]}
				/>
			)}
		</div>
	);
}

export default DetailVoucherPNK;
