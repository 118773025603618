import { makeStyles } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldNumberInput from '../../../../components/Dialog/FieldNumberInput';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import TableContainer from '../../../../components/table/TableContainer';

export default function IngredientTab({ hookForm }) {
	const classes = useStyles();
	const { watch, setValue, control } = hookForm;
	const [ingredients, setIngredients] = [
		watch('ingredients') || [],
		(ingredients) => setValue('ingredients', ingredients),
	];

	const handleDelete = (index) => {
		const newIngredients = [...ingredients];
		newIngredients.splice(index, 1);
		setIngredients(newIngredients);
	};

	const columns = [
		{
			name: 'STT',
			selector: (_row, index) => index + 1,
			sortable: true,
		},
		{
			name: 'Mã hàng',
			selector: (row) => row.code,
			sortable: true,
		},
		{
			name: 'Tên hàng',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Số lượng',
			right: true,
			selector: (row, index) => (
				<Controller
					name={`ingredients[${index}].quantity`}
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldNumberInput
							style={{ textAlign: 'right' }}
							value={value}
							onChange={(value) => {
								const total = value * row.cost;
								setValue(`ingredients[${index}].total`, total);

								onChange(value);
							}}
						/>
					)}
				/>
			),
			sortable: true,
		},
		{
			name: 'Giá vốn',
			selector: (row) =>
				row.cost.toLocaleString('vi-VN', {
					style: 'currency',
					currency: 'VND',
				}),
			sortable: true,
			right: true,
		},
		{
			name: 'Thành tiền',
			right: true,
			selector: (_row, index) =>
				(watch(`ingredients[${index}].total`) || 0).toLocaleString('vi-VN', {
					style: 'currency',
					currency: 'VND',
				}),
			sortable: true,
		},
		// Delete button
		{
			cell: (_row, index) => (
				<button className='btn btn-delete' onClick={() => handleDelete(index)}>
					<DeleteRounded />
				</button>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	return (
		<div>
			<FieldSelectApi
				title='Dịch vụ'
				placeholder='Nhập tên dịch vụ'
				api='dmvt'
				q={{
					$or: [
						{ is_goods: true },
						{
							$and: [
								{ is_service: { $ne: true } },
								{ is_package: { $ne: true } },
								{ is_card: { $ne: true } },
								{ is_combo: { $ne: true } },
							],
						},
					],
				}}
				value={null} // Clear value
				valueField='ma_vt'
				labelField='ten_vt'
			/>

			{ingredients?.length > 0 && (
				<div style={{ marginTop: 16 }}>
					<TableContainer data={ingredients} columns={columns} />

					<div className={classes.totalContainer}>
						<label>Tổng giá vốn: </label>
						<span>
							{ingredients
								.reduce((cost, ingredient) => cost + ingredient.cost, 0)
								.toLocaleString('vi-VN', {
									style: 'currency',
									currency: 'VND',
								})}
						</span>
					</div>

					<div className={classes.totalContainer}>
						<label>Tổng thành tiền: </label>
						<span>
							{ingredients
								.reduce((total, ingredient) => total + ingredient.total, 0)
								.toLocaleString('vi-VN', {
									style: 'currency',
									currency: 'VND',
								})}
						</span>
					</div>
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	totalContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		fontSize: 14,

		'& > label': {
			marginRight: theme.spacing(1),
			width: 150,
			fontWeight: 'bold',
		},

		'& > span': {
			width: 100,
			textAlign: 'right',
		},
	},
}));
