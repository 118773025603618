import { Box, Button, Typography, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const PaymentContainer = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 10px;
`;
// top
export const PaymentTop = styled(Box)`
	width: 100%;
	height: 42px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ededed;
`;

export const Time = styled(Typography)`
	font-size: 12px;
	margin-left: auto;
`;

// content
export const PaymentContent = styled(Box)`
	width: 100%;
	flex: 1;
	overflow: auto;
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const ContentWrapper = styled(Box)`
	/* margin-top: 20px; */
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
export const SectionTitle = styled(Box)`
	display: flex;
	align-items: center;
	gap: 5px;
`;
export const SectionTitleText = styled(Typography)`
	font-size: 13px;
	font-weight: 550;
	color: ${(props) => props.textColor};
`;
export const SectionIcon = styled(Box)`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.color};
`;
export const ListItem = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0 5px;
`;

export const ItemOption = styled(Paper)`
	padding: 5px 8px;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	border-radius: 20px;
	background-color: ${(props) => (props.active ? props.bgColor : '#fff')};
	color: ${(props) => (props.active ? '#fff' : '#000')};
	cursor: pointer;
	&:hover {
		filter: brightness(95%);
	}
`;
export const PriceLine = styled(Box)`
	width: 100%;
	height: 24px;
	display: flex;
	align-items: center;
`;
export const PriceNumber = styled(Box)`
	width: 20px;
	height: 20px;
	background-color: #ededed;
	border-radius: 50%;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
`;
export const PriceText = styled(Typography)`
	font-size: 13px;
	font-weight: ${(props) => (props.active ? '550' : '400')};
`;
export const PriceInput = styled.input`
	border: none;
	outline: none;
	font-size: 13px;
	width: 80px;
	border-bottom: ${(props) => (props.border ? '1px solid #ccc' : '')};
	font-weight: ${(props) => (props.active ? '550' : '400')};
	margin-left: auto;
	text-align: right;
`;
export const PriceModes = styled(Box)`
	display: flex;
	align-items: center;
	gap: 5px;
	height: 24px;
	margin-left: auto;
	margin-right: 10px;
`;
export const Mode = styled(Box)`
	width: fit-content;
	height: 100%;
	padding: 5px;
	border-radius: 6px;
	text-transform: unset;
	font-size: 13px;
	background-color: ${(props) => (props.active ? props.bgColor : '#ededed')};
	color: ${(props) => (props.active ? '#fff' : '#000')};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
export const CodeLineWrapper = styled(Box)`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;
export const CodeLineGroup = styled(Box)`
	width: 50%;
	padding: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 5px;
	opacity: ${(props) => (props.disabled ? 0.6 : 1)};
	pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
`;
export const CodeLine = styled(Box)`
	display: flex;
	height: 34px;
	align-items: center;
	gap: 10px;
`;
export const CodeLineText = styled(Typography)`
	font-size: 13px;
	font-weight: 550;
`;
export const CodeLineInput = styled.input`
	height: 34px;
	width: 65%;
	outline: none;
	border: 1px solid #ccc;
	border-radius: 6px;
	padding: 0 5px;
	&:focus {
		border-color: ${(props) => props.borderColor};
	}
`;

// bottom
export const PaymentBottom = styled(Box)`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
	border-top: 1px solid #ededed;
`;

export const ButtonPrint = styled(Button)`
	font-size: 13px;
	font-weight: 550;
	padding: 10px 30px;
	height: 34px;
	background-color: #ededed;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	&:hover {
		background-color: #ededed;
	}
`;
export const ButtonCheckout = styled(Button)`
	font-size: 13px;
	font-weight: 550;
	padding: 10px 30px;
	height: 34px;
	color: #fff;
	background-color: ${(props) => props.bgColor};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	&:hover {
		background-color: ${(props) => props.bgColor};
	}
	&:disabled {
		color: #fff;
		background-color: ${(props) => props.bgColor};
		opacity: 0.8;
		pointer-events: none;
	}
`;
