import { Checkbox } from '@material-ui/core';
import { Button } from 'antd';
import React from 'react';
import '../components/filter/Filter.css';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import FormParticipantDialog from './dialog/FormParticipantDialog';
import ParticipantFilter from './ParticipantFilter';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ChangePasswordDialog from './dialog/ChangePasswordDialog';

function ListParticipant() {
	// Default group from url
	const { search } = useLocation();
	const { group_id } = queryString.parse(search);

	// dialog
	const [dialog, setDialog] = React.useState();
	const [dialogData, setDialogData] = React.useState();

	// change password dialog
	const passwordDialog = dialog === 'changePassword';
	const openChangePasswordDialog = (data) => () => {
		setDialog('changePassword');
		setDialogData(data);
	};
	const closeChangePasswordDialog = () => {
		setDialog();
		setDialogData();
	};

	const columns = [
		{
			name: 'Tên đăng nhập',
			selector: (row) => row.email,
		},
		{
			name: 'Họ và tên',
			selector: (row) => row.name,
		},
		{
			name: 'Là người quản trị',
			selector: (row) => <Checkbox checked={row.admin} disabled />,
		},
		{
			name: 'Nhóm',
			selector: (row) => row.group_name,
		},
		{
			name: 'Đặt lại mật khẩu',
			cell: (row) => (
				<Button size='small' onClick={openChangePasswordDialog(row)}>
					Đặt lại mật khẩu
				</Button>
			),
			ignoreRowClick: true,
		},
	];

	return (
		<>
			<ChangePasswordDialog open={passwordDialog} data={dialogData} onClose={closeChangePasswordDialog} />
			<ListBase
				api='participant'
				title='Danh sách người dùng'
				columns={columns}
				FilterComponent={ParticipantFilter}
				FormDialog={FormParticipantDialog}
				initFilter={{ group_id }}
			/>
		</>
	);
}

export default withContainer(ListParticipant);
