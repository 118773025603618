import React from 'react';
import MenuBase from '../../components/menu/MenuBase';
import { Menu, MenuItem } from '@material-ui/core';

function BoardPrice() {
	const [anchorBoardPrice, setAnchorAnchorBoardPrice] = React.useState(null);

	return (
		<MenuBase
			style={{ marginLeft: '5px' }}
			onClick={(e) => setAnchorAnchorBoardPrice(e.currentTarget)}
			displayText="Bảng giá chung"
		>
			<Menu anchorEl={anchorBoardPrice} open={!!anchorBoardPrice} onClose={() => setAnchorAnchorBoardPrice(null)}>
				<MenuItem style={{ fontSize: '13px' }}>Bảng giá chung</MenuItem>
			</Menu>
		</MenuBase>
	);
}

export default BoardPrice;
