import { makeStyles } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import { formatCurrencyDisplay } from '../../../../../../utils/helpers';
import FieldNumberInput from '../../../../components/Dialog/FieldNumberInput';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import TableContainer from '../../../../components/table/TableContainer';

export default function PackageServicesTab({ hookForm }) {
	const classes = useStyles();
	const { watch, setValue, control } = hookForm;
	const services = watch('combo', []);
	const setServices = (value) => setValue('combo', value);

	const addService = (service) => {
		const newServices = [...services];
		const index = newServices.findIndex((item) => item.ma_vt === service.ma_vt);
		const isExist = index !== -1;

		if (isExist) {
			newServices[index] = {
				...services[index],
				so_luong: services[index].so_luong + 1,
				thanh_tien: (services[index].so_luong + 1) * services[index].gia_mua,
			};
		} else {
			newServices.push({
				ma_vt: service.ma_vt,
				ten_vt: service.ten_vt,
				gia_mua: 0,
				so_luong: 1,
				thanh_tien: 0,
				ma_dvt: service.ma_dvt,
				ten_dvt: service.ten_dvt,
			});
		}

		setServices(newServices);
	};

	const handleDelete = (index) => {
		const newServices = [...services];
		newServices.splice(index, 1);
		setServices(newServices);
	};

	const columns = [
		{
			name: 'STT',
			selector: (_row, index) => index + 1,
			sortable: true,
		},
		{
			name: 'Mã hàng',
			selector: (row) => row.ma_vt,
			sortable: true,
		},
		{
			name: 'Tên dịch vụ',
			selector: (row) => row.ten_vt,
			sortable: true,
		},
		{
			name: 'Số lượng',
			right: true,
			selector: (row, index) => (
				<Controller
					name={`combo[${index}].so_luong`}
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldNumberInput
							style={{ textAlign: 'right' }}
							value={value}
							onChange={(value) => {
								const thanh_tien = value * row.gia_mua;
								setValue(`combo[${index}].thanh_tien`, thanh_tien);

								onChange(value);
							}}
						/>
					)}
				/>
			),
			sortable: true,
		},
		{
			name: 'Đơn vị tính',
			selector: (row) => row.ten_dvt,
			sortable: true,
		},
		{
			name: 'Giá vốn',
			selector: (row, index) => (
				<Controller
					name={`combo[${index}].gia_mua`}
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldNumberInput
							style={{ textAlign: 'right' }}
							value={value}
							onChange={(value) => {
								const thanh_tien = value * row.gia_mua;
								setValue(`combo[${index}].thanh_tien`, thanh_tien);

								onChange(value);
							}}
						/>
					)}
				/>
			),
			sortable: true,
			right: true,
		},
		{
			name: 'Thành tiền',
			right: true,
			selector: (_row, index) => formatCurrencyDisplay(watch(`combo[${index}].thanh_tien`)),
			sortable: true,
		},
		// Delete button
		{
			cell: (_row, index) => (
				<button className='btn btn-delete btn-small' onClick={() => handleDelete(index)}>
					<DeleteRounded />
				</button>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	return (
		<div>
			<FieldSelectApi
				title='Dịch vụ'
				placeholder='Nhập tên dịch vụ'
				api='dmvt'
				q={{ is_service: true }}
				value={null} // Clear value
				valueField='ma_vt'
				labelField='ten_vt'
				onChange={addService}
			/>

			{services?.length > 0 && (
				<div style={{ marginTop: 16 }}>
					<TableContainer data={services} columns={columns} />

					<div className={classes.totalContainer}>
						<label>Tổng thành tiền: </label>
						<span>
							{services
								.reduce((thanh_tien, i) => thanh_tien + i.thanh_tien, 0)
								.toLocaleString('vi-VN', {
									style: 'currency',
									currency: 'VND',
								})}
						</span>
					</div>
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	totalContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		fontSize: 14,

		'& > label': {
			marginRight: theme.spacing(1),
			width: 150,
			fontWeight: 'bold',
		},

		'& > span': {
			width: 100,
			textAlign: 'right',
		},
	},
}));
