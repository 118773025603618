import { Checkbox, Grid } from '@material-ui/core';
import React from 'react';
import FieldBox from '../../components/Dialog/FieldBox';
import FieldInput from '../../components/Dialog/FieldInput';
import { Controller } from 'react-hook-form';

export default function EmailTab({ hookForm }) {
	const { register, control } = hookForm;
	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<FieldInput title='Host' inputProps={{ ...register('email_host') }} />
				<FieldInput title='Port' inputProps={{ ...register('email_port') }} />
				<FieldBox title='Secure'>
					<Controller
						name='email_secure'
						control={control}
						render={({ field: { value, onChange } }) => (
							<Checkbox size='small' checked={!!value} onChange={(e) => onChange(e.target.checked)} />
						)}
					/>
				</FieldBox>
			</Grid>

			<Grid item xs={6}>
				<FieldInput title='Username' inputProps={{ ...register('email_user') }} />
				<FieldInput title='Password' inputProps={{ ...register('email_pass'), type: 'password' }} />
			</Grid>
		</Grid>
	);
}
