import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SaveIcon from '@material-ui/icons/Save';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { TaskContext } from '../Task';
import { Controller, useForm } from 'react-hook-form';
import vi from 'date-fns/locale/vi';
import dayjs from 'dayjs';
import { toast } from 'react-hot-toast';
import { Grid, TextField } from '@material-ui/core';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';

function TaskDialogEdit({ open, handleClose, data }) {
	const [checkedFolow, setCheckedFolow] = useState(false);
	const [checkedEdit, setCheckedEdit] = useState(false);
	const context = useContext(DefaultContext);
	const { getDataTask } = useContext(TaskContext);

	const hookForm = useForm({});

	const {
		getValues,
		setValue,
		register,
		formState: { errors },
		control,
		handleSubmit,
		reset,
		// formState: { isSubmitting },
	} = hookForm;

	useEffect(() => {
		reset({
			...data,
		});
	}, [data, reset]);

	const onSubmit = async (data) => {
		try {
			await context.apis.asyncPostList(context.userInfo.token, 'task', {
				...data,
			});

			await getDataTask();

			toast.success('Cập nhật thành công');
		} catch (error) {
			console.log(error);
			toast.success(error);
		}
	};

	const onSave = (data) =>
		onSubmit(data).then(() => {
			handleClose();
		});

	// const onSaveAndNew = (data) =>
	// 	onSubmit(data).then(() => {
	// 		const type = data.type;

	// 		for (let key in data) {
	// 			data[key] = "";
	// 		}

	// 		data.type = type;

	// 		reset(data);
	// 	});

	// const onSaveAndCopy = (data) =>
	//   onSubmit(data).then(() => {
	//     delete data._id;
	//     data.so_ct = "";

	//     reset(data);
	//   });

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			title={'Chỉnh sửa'}
			classNameContent={'dialog__content_edit_voucher_body'}
			actions={[
				<button key="save" className="btn btn-fill" onClick={handleSubmit(onSave)}>
					<SaveIcon style={{ marginRight: '10px' }} />
					<div>Lưu</div>
				</button>,

				<button key="exit" className="btn btn-grey" onClick={handleClose}>
					<ErrorOutlineIcon style={{ marginRight: '10px' }} />
					<div>Bỏ qua</div>
				</button>,
			]}
		>
			<div className="dialog__content_edit_voucher column-start">
				<div className="dialog__content_edit_voucher__detail">
					<div
						style={{
							width: '50%',
						}}
						className="dialog__content_edit_voucher__detail_left column-start"
					>
						<FieldInput
							check
							title="Tên công việc"
							inputProps={{
								...register('ten_cv'),
							}}
						/>

						<Controller
							name="ten_phu_trach"
							control={control}
							rules={{
								required: 'Vui lòng chọn người phụ trách',
							}}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ten_phu_trach'),
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.email || null);
									setValue('phu_trach', item.email || '');
									setValue('ten_phu_trach', item.name || '');
								};

								return (
									<FieldSelectApi
										title="Phụ trách"
										placeholder="Chọn người phụ trách"
										api="participant"
										valueField="email"
										labelField="name"
										value={item}
										onChange={handleChange}
										check
										errorMsg={errors.ten_phu_trach?.message}
									/>
								);
							}}
						/>

						<Controller
							name="ten_trang_thai"
							control={control}
							rules={{ required: 'Vui lòng chọn trạng thái' }}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ten_trang_thai'),
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.ten_trang_thai || null);
									setValue('progress', item.ma_trang_thai || '');
								};

								const query = { ma_ct: 'TASK' };

								return (
									<FieldSelectApi
										title="Trạng thái"
										placeholder="Chọn trạng thái"
										api="trangthai"
										q={query}
										withIdApp={false}
										valueField="ma_trang_thai"
										labelField="ten_trang_thai"
										value={item}
										onChange={handleChange}
										check
										errorMsg={errors.ten_trang_thai?.message}
									/>
								);
							}}
						/>

						{/* <Controller
              name="ten_trang_thai"
              control={control}
              rules={{ required: "Vui lòng chọn trạng thái" }}
              render={({ field: { onChange, value } }) => {
                const item = value
                  ? { value, label: getValues("ten_trang_thai") }
                  : null;
                const handleChange = (item) => {
                  onChange(item?.value || null);
                  setValue("ma_trang_thai", item.value || "");
                  setValue("ten_trang_thai", item.label || "");
                };

                const query = { ma_ct: "TASK" };

                return (
                  <FieldSelectApi
                    title="Trạng thái"
                    placeholder="Chọn trạng thái"
                    api="trangthai"
                    q={query}
                    withIdApp={false}
                    valueField="ma_trang_thai"
                    labelField="ten_trang_thai"
                    value={item}
                    onChange={handleChange}
                    check
                    errorMsg={errors.ma_trang_thai?.message}
                  />
                );
              }}
            /> */}

						<Controller
							name="ten_nh_cv"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_nh_cv') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_nh_cv', item.value || '');
									setValue('ten_nh_cv', item.label || '');
								};

								return (
									<FieldSelectApi
										title="Nhóm công việc"
										placeholder="Chọn nhóm công việc"
										api="dmnhtask"
										valueField="ten_nh_cv"
										labelField="ten_nh_cv"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>

						<FieldInput
							title="Công ty"
							inputProps={{
								...register('cty'),
							}}
						/>
					</div>

					<div
						style={{
							width: '50%',
						}}
						className="dialog__content_edit_voucher__detail_right column-start"
					>
						<Grid className="dateTimePicker" spacing={2}>
							<Grid xs={4}>
								<label>Ngày bắt đầu</label>
							</Grid>

							<Controller
								control={control}
								name="start_date"
								render={({ field: { onChange, value } }) => (
									<TextField
										id="datetime-local"
										type="datetime-local"
										defaultValue={value && dayjs(value).format('YYYY-MM-DD HH:mm')}
										className="fieldDateTime"
										onChange={onChange}
									/>
								)}
							/>
						</Grid>

						<Grid className="dateTimePicker" spacing={2}>
							<Grid xs={4}>
								<label>Ngày kết thúc</label>
							</Grid>

							<Controller
								control={control}
								name="due_date"
								render={({ field: { onChange, value } }) => (
									<TextField
										id="datetime-local"
										type="datetime-local"
										defaultValue={value && dayjs(value).format('YYYY-MM-DD HH:mm')}
										className="fieldDateTime"
										onChange={onChange}
									/>
								)}
							/>
						</Grid>

						<Controller
							name="ten_dt"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_dt') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_dt', item.value || '');
									setValue('ten_dt', item.label || '');
								};

								return (
									<FieldSelectApi
										title="Dự án"
										placeholder="Chọn dự án"
										api="dmdt"
										valueField="ten_dt"
										labelField="ten_dt"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>

						<Controller
							name="ten_kh"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_kh') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_kh', item.value || '');
									setValue('ten_kh', item.label || '');
								};

								return (
									<FieldSelectApi
										title="Khách hàng"
										placeholder="Chọn khách hàng"
										api="customer"
										valueField="ten_kh"
										labelField="ten_kh"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default TaskDialogEdit;
