import { DeleteRounded } from '@material-ui/icons';
import React from 'react';
import DataTable from 'react-data-table-component';
import { IoCopy } from 'react-icons/io5';
import './Table.css';

const customStyles = {
	table: {
		style: {
			backgroundColor: '#dcf4fc',
		},
		headCells: {
			style: {
				fontSize: '14px',
				fontWeight: 'bold',
				color: '#000',
				backgroundColor: '#dcf4fc',
			},
		},
	},
};

export const TableTypes = {
	LIST: 'list',
	REPORT: 'report',
	VOUCHER: 'voucher',
};

function TableContainer({
	title,
	data,
	actions,
	columns,
	selectableRows,
	onRowClick,
	limit,
	page,
	handlePaginationChange,
	totalRows,
	reportData,
	onSelectedRowsChange,
	toggleCleared,
	selectableRowDisabled,
	fixedHeaderScrollHeight,
	onRowMouseEnter,
	onRowMouseLeave,
	onRowCopy,
	onRowDelete,
	ExpandComponent,
}) {
	// Add class cho các action
	actions = actions?.map((action, index) => {
		return React.cloneElement(action, {
			className: 'btn',
			key: index,
		});
	});

	// Auto có chức năng sắp xếp columns
	columns = columns?.map((column) => {
		return {
			sortable: true,
			reorder: true,
			...column,
		};
	});

	if (onRowCopy || onRowDelete)
		// Thêm cột action vào đầu table
		columns = [
			{
				name: '',
				cell: (row) => (
					<div className="row-action">
						<button className="btn btn-fill" onClick={() => onRowCopy(row)}>
							<IoCopy />
						</button>
						<button className="btn btn-danger" onClick={() => onRowDelete(row)}>
							<DeleteRounded />
						</button>
					</div>
				),
				width: 'fit-content',
				sortable: false,
				reorder: false,
				compact: true,
				ignoreRowClick: true, // Không cho click vào row khi click vào action
			},
			...columns,
		];

	// Add report row vào đầu table
	if (reportData) {
		data = [reportData, ...data];
	}

	const handleChangePage = (page) => handlePaginationChange(page, limit);

	const handleChangeLimit = (limit) => handlePaginationChange(1, limit);

	return (
		<DataTable
			fixedHeader
			columns={columns}
			data={data}
			keyField="_id" //
			title={title}
			actions={actions}
			highlightOnHover
			pointerOnHover
			customStyles={customStyles}
			onRowClicked={onRowClick}
			noDataComponent={<div className="empty-data-table">Không có dữ liệu</div>}
			persistTableHead
			// Pagination
			pagination={!!(handlePaginationChange && totalRows)}
			paginationServer
			paginationDefaultPage={page}
			paginationPerPage={limit}
			paginationRowsPerPageOptions={[20, 50, 100, 200]}
			onChangeRowsPerPage={handleChangeLimit}
			onChangePage={handleChangePage}
			paginationTotalRows={totalRows}
			paginationComponentOptions={{
				rowsPerPageText: 'Số dòng trên trang:',
				rangeSeparatorText: 'của',
				noRowsPerPage: false,
				selectAllRowsItem: false,
				selectAllRowsItemText: 'Tất cả',
			}}
			// Expandable
			expandableRows={!!ExpandComponent}
			expandableRowsComponent={ExpandComponent}
			// Row selection
			selectableRows={selectableRows}
			selectableRowDisabled={selectableRowDisabled}
			onSelectedRowsChange={onSelectedRowsChange}
			clearSelectedRows={toggleCleared}
			fixedHeaderScrollHeight={fixedHeaderScrollHeight}
			//Row hover
			onRowMouseEnter={onRowMouseEnter}
			onRowMouseLeave={onRowMouseLeave}
		/>
	);
}

export default TableContainer;
