import React from "react";
import SysForms from "flexbiz-core/components/auth/SysForms";
class CapSysForms extends SysForms {
  async signin(onSuccess, onError, options = { allow_cancel: true }) {
    //show form signin
    await new Promise((resolve) => {
      (async () => {
        if (!this.signinRef) {
          let { default: SigninForm } = await import("./SigninForm");
          this.setState(
            {
              SigninForm: (
                <SigninForm forwardedRef={(ref) => (this.signinRef = ref)} />
              ),
              load: this.state.load + 1,
            },
            () => {
              resolve();
            }
          );
        } else {
          resolve();
        }
      })();
    });
    this.signinRef.showForm(onSuccess, onError, options);
  }
  // async chooseApp(userInfo,onSuccess){

  //     await new Promise( (resolve)=>{
  //       (async ()=>{
  //         if(!this.appsFormRef){
  //           const {default:AppsForm} = await import("./AppsForm")
  //           this.setState({
  //             AppsForm:<AppsForm  forwardedRef={ref=>this.appsFormRef = ref}/>,
  //             load:this.state.load+1
  //           },()=>{
  //             resolve()
  //           })
  //         }else{
  //           resolve()
  //         }
  //       })();
  //     })
  //     this.appsFormRef.showForm(userInfo,onSuccess);
  //   }
    // async chooseApp(userInfo,onSuccess){
       
    //     await new Promise( (resolve)=>{
    //       (async ()=>{
    //         if(!this.appsFormRef){
    //           const {default:AppsForm} = await import("./AppsForm")
    //           this.setState({
    //             AppsForm:<AppsForm  forwardedRef={ref=>this.appsFormRef = ref}/>,
    //             load:this.state.load+1
    //           },()=>{
    //             resolve()
    //           })
    //         }else{
    //           resolve()
    //         }
    //       })();
    //     })
    //     this.appsFormRef.showForm(userInfo,onSuccess);
    //   }
}
export default CapSysForms;
