import React from 'react';
import Dialog from '../../../components/Dialog/Dialog';
import TabContainer from '../../../components/tab/TabContainer';
import InfoTab from './tabs/InfoTab';
import OtherTab from './tabs/OtherTab';
import { useForm } from 'react-hook-form';

// Dùng hàm getData để lấy dữ liệu từ component cha mỗi khi mở dialog
export default function AppConfigEditDialog({ getData, open, onClose, onSubmit }) {
	const hookForm = useForm();

	const {
		formState: { isSubmitting },
		handleSubmit,
		reset,
	} = hookForm;

	React.useEffect(() => {
		if (open) reset(getData());
	}, [open]);

	const tabs = [
		{ label: 'Thông tin', component: <InfoTab hookForm={hookForm} /> },
		{ label: 'Thông tin khác', component: <OtherTab hookForm={hookForm} /> },
	];

	return (
		<Dialog
			width={1000}
			open={open}
			handleClose={onClose}
			title='Chỉnh sửa thông tin công ty'
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
					Lưu
				</button>,
				<button className='btn btn-grey' onClick={onClose} disabled={isSubmitting}>
					Hủy
				</button>,
			]}
			loading={isSubmitting}
		>
			<TabContainer tabs={tabs} />
		</Dialog>
	);
}
