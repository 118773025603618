import React from 'react';
import styled from 'styled-components';
import { Box, Typography, TextField, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CiSearch } from 'react-icons/ci';
import { GrFormAdd } from 'react-icons/gr';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { unicode2Ascii } from 'flexbiz-core/utils';
import { cloneDeep } from 'lodash';
import { SaleContext } from '../../SaleContainer';
import { Autocomplete } from '@material-ui/lab';
import AddCustomerPopup from './AddCustomerPopup';

const SearchIcon = styled(Box)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const SearchStyled = styled(Autocomplete)`
	width: 200px;
	height: 34px;
	border-radius: 6px;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #111;
		}
	}
`;
const CustomerItem = styled(Box)`
	padding: 0 5px;
	display: flex;
	flex-direction: column;
`;
const TextDisplay = styled(Typography)`
	font-size: 13px;
`;
const NoOptionComponent = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
const NoOptionText = styled(Typography)`
	text-align: center;
	font-size: 13px;
	font-style: italic;
`;

function SearchCustomer({ voucher, handleSavePBL }) {
	const theme = useTheme();
	const context = React.useContext(DefaultContext);
	const { getVoucher } = React.useContext(SaleContext);
	const timerRef = React.useRef();
	const customerNameRef = React.useRef('');

	const [customers, setCustomers] = React.useState([{ ma_kh: '', ten_kh: '--' }]);
	const [search, setSearch] = React.useState('');
	const [searchText, setSearchText] = React.useState('');
	const [load, setLoad] = React.useState(1);
	const [openDialog, setOpenDialog] = React.useState(false);

	const handleSelectCustomer = async (customer) => {
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.ma_kh = customer.ma_kh;
		await handleSavePBL(voucherCopy);
		getVoucher();
		setSearchText('');
		setLoad(load + 1);
	};

	// handle blur
	const handleBlur = () => {
		setSearch('');
	};
	// handle close dialog
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	// get customer
	const getCustomer = async () => {
		if (!search) {
			// setCustomers([]);
			return;
		}
		let condition = {
			$or: [],
		};
		//
		condition.$or.push({ ma_kh: { $regex: search, $options: 'i' } });
		condition.$or.push({ ten_kh: { $regex: search.split(' ').join('.*'), $options: 'i' } });
		// let $search = await unicode2Ascii(search);
		// condition.$or.push({ $text: { $search: search + " " + $search } });

		const resp = await context.apis.asyncGetList(context.userInfo.token, 'customer', { condition });
		resp.unshift({ ma_kh: '', ten_kh: '--' });
		setCustomers(resp);
	};
	// set search khi search text thay doi
	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);
	// get customer khi search thay doi
	React.useEffect(() => {
		getCustomer();
	}, [search]);
	// get current customer
	React.useEffect(() => {
		if (voucher && voucher.ma_kh) {
			const getCustomerByMa = async () => {
				const resp = await context.apis.asyncGetData(context.userInfo.token, 'customer', {
					ma_kh: voucher.ma_kh,
				});
				customerNameRef.current = resp.ten_kh;
			};
			getCustomerByMa();
		} else {
			customerNameRef.current = '';
		}
	}, [voucher]);

	return (
		<>
			{openDialog && (
				<AddCustomerPopup
					open={openDialog}
					handleClose={handleCloseDialog}
					handleSelectCustomer={handleSelectCustomer}
					valueName={searchText}
				/>
			)}
			<SearchStyled
				key={load}
				clearOnEscape
				onChange={(e, newValue) => handleSelectCustomer(newValue)}
				onBlur={handleBlur}
				forcePopupIcon={false}
				options={customers}
				getOptionLabel={(option) => option}
				filterOptions={(option) => option}
				renderOption={(option) => {
					return (
						<CustomerItem>
							<TextDisplay>{option.ten_kh}</TextDisplay>
							{option.dien_thoai && <TextDisplay>{option.dien_thoai}</TextDisplay>}
						</CustomerItem>
					);
				}}
				noOptionsText={
					<NoOptionComponent>
						<NoOptionText>Không có khách hàng</NoOptionText>
					</NoOptionComponent>
				}
				renderInput={(params) => (
					<TextField
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={customerNameRef.current ? customerNameRef.current : 'Khách lẻ'}
						{...params}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							startAdornment: (
								<SearchIcon>
									<CiSearch fontSize="20px" />
								</SearchIcon>
							),
							endAdornment: (
								<Tooltip arrow placement="top" title="Thêm khách hàng">
									<SearchIcon onClick={() => setOpenDialog(true)}>
										<GrFormAdd fontSize="20px" />
									</SearchIcon>
								</Tooltip>
							),
						}}
						variant="outlined"
					/>
				)}
			/>
		</>
	);
}

export default SearchCustomer;
