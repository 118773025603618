import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AiFillCopy, AiFillDelete, AiFillEdit, AiFillFileAdd, AiFillPrinter, AiOutlineFundView } from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { MdRefresh } from 'react-icons/md';
import { RiFileSearchFill } from 'react-icons/ri';
import VoucherDetail from './VoucherDetail/VoucherDetail';
import EditVoucherPT1 from './Dialog/EditVoucherPT1';
import CopyVoucherPT1 from './Dialog/CopyVoucherPT1';
import AddVoucherPT1 from './Dialog/AddVoucherPT1';
import { formatNumberWithSpaces } from '../Utils';
import LayoutContainer from '../../components/LayoutContainer';
import TableManager from '../../components/tableManager/TableManager';
import NotiDialog from '../components/NotiDialog';
import withContainer from '../../withContainer';
import { useCore } from '../../../../hooks';
import { TfiReload } from 'react-icons/tfi';
import VButton from '../../VComponent/VButton';
import VoucherFilter from '../components/VoucherFilter';
export const VoucherContext = createContext();

const columns = [
	{
		Header: 'Ngày chứng từ',
		accessor: 'ngay_ct',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Mã CT',
		accessor: 'ma_ct',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
	},
	{
		Header: 'Số CT',
		accessor: 'so_ct',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Trạng thái CT',
		accessor: 'ten_trang_thai',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
	},
	{
		Header: 'Số tiền',
		accessor: 't_tien_nt',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		type: 'number',
		Filter: false,
	},
	{
		Header: 'Loại tiền',
		accessor: 'ma_nt',
		width: 100,
		minWidth: 90,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tỷ giá',
		accessor: 'ty_gia',
		width: 100,
		minWidth: 90,
		maxWidth: 400,
		type: 'number',
		Filter: false,
	},
	{
		Header: 'Đơn vị',
		accessor: 'ten_dvcs',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Diễn giải',
		accessor: 'dien_giai',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		disableSortBy: true,
		Filter: false,
	},
	{
		Header: 'TK nợ',
		accessor: 'tk_no',
		width: 100,
		minWidth: 90,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tên TK nợ',
		accessor: 'ten_tk_no',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
	},
	{
		Header: 'Mã khách hàng',
		accessor: 'ma_kh',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
		Filter: false,
	},
	{
		Header: 'Tên khách hàng',
		accessor: 'ten_kh',
		width: 150,
		minWidth: 100,
		maxWidth: 400,
	},
];

function VoucherPT1({ title, api }) {
	const core = useCore();
	const [openAdd, setOpenAdd] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [openCopy, setOpenCopy] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const MenuTabTable = [
		{
			id: 1,
			name: 'Mới',
			icon: <AiFillFileAdd />,
			handle: () => {
				setOpenAdd(true);
			},
		},
		{
			id: 2,
			name: 'Sửa',
			icon: <AiFillEdit />,
			handle: () => {
				setOpenEdit(true);
			},
		},
		{
			id: 3,
			name: 'Xoá',
			icon: <AiFillDelete />,
			handle: () => {
				handleDeleteVoucher();
			},
		},
		{
			id: 4,
			name: 'Chép dữ liệu',
			icon: <AiFillCopy />,
			handle: () => {
				setOpenCopy(true);
			},
		},
		// {
		// 	id: 5,
		// 	name: "Tìm",
		// 	icon: <RiFileSearchFill />,
		// },
		// {
		// 	id: 6,
		// 	name: "Xem",
		// 	icon: <AiOutlineFundView />,
		// 	handle: () => {
		// 		setOpenEdit(true);
		// 	},
		// },
		{
			id: 7,
			name: 'In',
			icon: <AiFillPrinter />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 8,
			name: 'Nhập',
			icon: <BiImport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 9,
			name: 'Xuất',
			icon: <BiExport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		// {
		// 	id: 10,
		// 	name: "Làm mới",
		// 	icon: <MdRefresh />,
		// },
	];
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
		t: 1,
	});
	const [dataVoucherPT1, setDataVoucherPT1] = useState([]);
	const [dataVoucherDetail, setDataVoucherDetail] = useState('');
	const [dataVoucherSelect, setDataVoucherSelect] = useState('');
	const [dataDoubleClick, setDataDoubleClick] = useState('');
	const context = useContext(DefaultContext);

	const skipResetRef = React.useRef(false);
	const updateMyData = (rowIndex, columnId, value) => {
		skipResetRef.current = true;
		setDataVoucherPT1((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};

	const handleCloseAdd = () => {
		getDataVoucherPT1();
		setOpenAdd(false);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
		setDataDoubleClick('');
	};

	// xử lý trả ra data khi click vào nút copy
	const handleDataCopyVoucher = (dataNew) => {
		const dataCopy = dataNew.map((item) => {
			return {
				...item,
				so_ct: '',
				_id: undefined,
				ngay_ct: new Date(),
			};
		});
		return dataCopy;
	};

	const handleCloseCopy = () => {
		getDataVoucherPT1();
		setOpenCopy(false);
	};

	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	const getDataVoucherDetail = (data) => {
		setDataVoucherDetail(data);
	};

	const handleSelectRow = (data) => {
		// console.log("data select", data);
		handleCloseEdit();
		setDataVoucherSelect(data);
	};

	// get data pt1 voucher
	const getDataVoucherPT1 = useCallback(async () => {
		core.setProgressStatus(true);

		try {
			const [data, count] = await Promise.all([
				core.asyncGetList(core.token, api, {
					...params,
				}),
				core.asyncGetList(core.token, api, {
					count: 1,
					q: params.q,
				}),
			]);

			// set total pages
			const totalItem = count.rows_number;
			setDataVoucherPT1(data);
			setTotalRows(totalItem);
			setDataVoucherDetail(data[0] || '');
			// console.log("data", data);
		} catch (error) {
			console.log('getDataProduct error', error);
		} finally {
			context.setProgressStatus(false);
		}
	}, [context, params]);

	// Xử lý tổng tiền pt1 voucher
	useEffect(() => {
		handleSumTotal(dataVoucherPT1);
	}, [dataVoucherPT1]);

	const handleSumTotal = (data) => {
		let sum = 0;
		data.forEach((item) => {
			sum += item.t_tt;
		});
		return formatNumberWithSpaces(sum);
	};

	// delete data pt1 voucher
	const deleteDataVoucherPT1 = useCallback(
		async (id) => {
			core.contextAlert('Bạn có chắc chắn muốn xóa không?', async (isConfirm) => {
				if (!isConfirm) return;

				core.setProgressStatus(true);

				try {
					await core.asyncDeleteList(core.token, api, id);
					await getDataVoucherPT1();
				} catch (error) {
					core.contextAlert(error);
				} finally {
					core.setProgressStatus(false);
				}
			});
		},
		[context, getDataVoucherPT1]
	);
	const deleteDataVoucherPT1List = useCallback(
		async (ids) => {
			core.contextAlert(`Bạn có chắc chắn muốn xóa ${ids.length} dòng không?`, async (isConfirm) => {
				if (!isConfirm) return;
				core.setProgressStatus(true);
				try {
					const errors = [];
					await Promise.all(
						ids.map((id) => core.asyncDeleteList(core.token, api, id).catch((error) => errors.push(error)))
					);
					if (errors.length) {
						core.contextAlert(
							<>
								<p>{`Có ${errors.length}/${ids.length} dòng không xóa được`}</p>
								{errors.map((e, index) => (
									<div key={index}>{e.error || e}</div>
								))}
							</>
						);
					}
				} catch (error) {
					core.contextAlert(error);
				} finally {
					await getDataVoucherPT1();
					core.setProgressStatus(false);
				}
			});
		},
		[context, getDataVoucherPT1]
	);

	// handle pagination
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	// on select row click
	const onSelectRowClick = (row) => {
		handleSelectRow(row);
	};

	// on row click
	const onRowClick = (row) => {
		getDataVoucherDetail(row);
	};

	// on row double click
	const onRowDoubleClick = (row) => {
		setDataDoubleClick([row]);
	};

	// // hàm xử lý nếu sau khi getDataVoucherPT1 thì table sẽ thay đổi data, tôi muốn set lại state của voucherDetail đúng với row đã click trước đó
	// useEffect(() => {
	// 	if (dataVoucherDetail) {
	// 		const data = dataVoucherPT1.find(
	// 			(item) => item._id === dataVoucherDetail._id
	// 		);
	// 		setDataVoucherDetail(data);
	// 	}
	// }, [dataVoucherDetail, dataVoucherPT1]);

	const handleDeleteVoucher = async () => {
		if (dataVoucherSelect.length === 1) {
			await deleteDataVoucherPT1(dataVoucherSelect[0]._id);
		} else if (dataVoucherSelect.length > 1) {
			await deleteDataVoucherPT1List(dataVoucherSelect.map((item) => item._id));
		} else {
			setOpenDelete(true);
		}
		// setDataVoucherSelect([]);
		// setDataVoucherDetail("");
	};

	const handleFilter = (filter) => {
		const newParams = { ...params };

		// Update params
		newParams.page = 1;
		newParams.q = filter;
		setParams(newParams);
	};

	useEffect(() => {
		getDataVoucherPT1();
	}, [params.page, params.limit, getDataVoucherPT1]);

	return (
		<React.Fragment>
			<VoucherContext.Provider
				value={{
					getDataVoucherPT1,
				}}
			>
				<LayoutContainer>
					<div className="box_new_voucher column-start">
						<div className="box_voucher column-start">
							<div className="column-start box_voucher__header">
								<div className="flex-start box_voucher__header__title">
									<div>
										<TfiReload
											size={18}
											color="#206c77"
											style={{
												cursor: 'pointer',
												marginRight: '10px',
												verticalAlign: 'middle',
											}}
											onClick={() => {
												setDataVoucherSelect([]);
												setDataVoucherDetail('');
												setParams({
													...params,
													page: 1,
													limit: 20,
													q: '',
												});
											}}
										/>
									</div>
									<span>Phiếu thu tiền mặt</span>
								</div>
							</div>
							<div className="column-start box_voucher__body">
								<div className="box_voucher__body__content">
									<TableManager
										idTable={'tableVoucherPT1'}
										columns={columns}
										data={dataVoucherPT1}
										updateMyData={updateMyData}
										skipReset={skipResetRef.current}
										settingRow={true}
										selectRow={true}
										onSelectRowClick={onSelectRowClick}
										readonly={true}
										onRowClick={onRowClick}
										onRowDoubleClick={onRowDoubleClick}
										TbodyMaxHeight={'37vh'}
										TbodyMinHeight={'37vh'}
										pagination={true}
										isPage={params.page}
										isLimit={params.limit}
										totalRows={totalRows}
										handlePaginationChange={handlePaginationChange}
										toolBar={
											<div className="flex-start">
												<div>
													<VButton.Filter title="Tìm">
														<VoucherFilter
															onFilter={handleFilter}
															title={'Filter Voucher'}
														/>
													</VButton.Filter>
												</div>
												<div className="flex-start box_voucher__body__header">
													{MenuTabTable.map((item) => {
														const shouldDisplayItem =
															(dataVoucherSelect.length === 1 ||
																(item.id !== 2 && item.id !== 4)) &&
															(dataVoucherSelect.length > 0 || item.id !== 3);

														if (shouldDisplayItem) {
															return (
																<button
																	key={item.id}
																	onClick={item.handle}
																	title={`${item.name}`}
																>
																	{item.icon}{' '}
																	<span className="tabname_tool_bar">
																		{item.name}
																	</span>
																</button>
															);
														}

														return null;
													})}
												</div>
											</div>
										}
									/>
								</div>
							</div>
						</div>
						<div className="box_voucher_detail column-start">
							<VoucherDetail
								data={dataVoucherDetail}
								readonly={true}
								view={true}
								selectRow={false}
								isFieldNt={{
									ma_nt: dataVoucherDetail?.ma_nt,
									ty_gia: dataVoucherDetail?.ty_gia,
								}}
							/>
						</div>
						<div className="total_price_voucher">
							<div>
								<span>Tổng số tiền: </span>
								<span>{handleSumTotal(dataVoucherPT1)}</span>
							</div>
						</div>
						{dataDoubleClick && (
							<EditVoucherPT1
								api={api}
								title={title}
								open={true}
								handleClose={handleCloseEdit}
								data={dataDoubleClick}
								getDataVoucherPT1={getDataVoucherPT1}
							/>
						)}
						{dataVoucherSelect && dataVoucherSelect.length > 0 ? (
							<>
								<EditVoucherPT1
									api={api}
									title={title}
									open={openEdit}
									handleClose={handleCloseEdit}
									data={dataVoucherSelect}
									getDataVoucherPT1={getDataVoucherPT1}
								/>
								<CopyVoucherPT1
									api={api}
									title={title}
									open={openCopy}
									handleClose={handleCloseCopy}
									data={handleDataCopyVoucher(dataVoucherSelect)}
									getDataVoucherPT1={getDataVoucherPT1}
								/>
							</>
						) : (
							<>
								<NotiDialog
									open={openEdit}
									handleClose={handleCloseEdit}
									title="Thông báo"
									content="Vui lòng chọn 1 dòng để thực hiện chức năng này"
								/>
								<NotiDialog
									open={openDelete}
									handleClose={handleCloseDelete}
									title="Thông báo"
									content="Vui lòng chọn ít nhất 1 dòng để thực hiện chức năng này"
								/>
								<NotiDialog
									open={openCopy}
									handleClose={handleCloseCopy}
									title="Thông báo"
									content="Vui lòng chọn ít nhất 1 dòng để thực hiện chức năng này"
								/>
							</>
						)}
						<AddVoucherPT1
							api={api}
							title={title}
							open={openAdd}
							handleClose={handleCloseAdd}
							getDataVoucherPT1={getDataVoucherPT1}
						/>
					</div>
				</LayoutContainer>
			</VoucherContext.Provider>
		</React.Fragment>
	);
}

export default withContainer(VoucherPT1);
