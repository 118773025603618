import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useState } from 'react';
import Container from '../Container';
import LayoutContainer from '../components/LayoutContainer';
import TableContainer from '../components/table/TableContainer';
import ReasonCancelFilter from './ReasonCancelFilter';
import ReasonCancelDialog from './Dialog/ReasonCancelDialog';
import ReasonCancelItemDialog from './Dialog/ReasonCancelItemDialog';

const columns = [
	{
		name: 'Mã lý do',
		selector: (row) => row.id,
		sortable: true,
	},
	{
		name: 'Tên lý do',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Ghi chú',
		selector: (row) => row.note,
		sortable: true,
	},
];

const data = [
	{
		id: 1,
		name: 'Khách hàng không đến',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 1',
			desc: 'Đây là nhân viên của phòng 1',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 2,
		name: 'Khách hàng không đồng ý',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 2',
			desc: 'Đây là nhân viên của phòng 2',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 3,
		name: 'Khách hàng không đồng ý',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 3',
			desc: 'Đây là nhân viên của phòng 3',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 4,
		name: 'Khách hàng không đồng ý',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 4',
			desc: 'Đây là nhân viên của phòng 4',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 5,
		name: 'Khách hàng không đồng ý',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 5',
			desc: 'Đây là nhân viên của phòng 5',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
];

function ReasonCancelContent() {
	const [open, setOpen] = useState(false);
	const [openDialogItem, setOpenDialogItem] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		console.log('close');
		setOpen(false);
	};

	const handleOpenDialogItem = () => {
		setOpenDialogItem(true);
	};

	const handleCloseDialogItem = () => {
		setOpenDialogItem(false);
	};
	return (
		<LayoutContainer
			FilterLeft={
				<>
					<ReasonCancelFilter />
				</>
			}
		>
			<>
				<TableContainer
					columns={columns}
					title={'Lý do huỷ lịch'}
					data={data}
					actions={[
						<button
							onClick={() => {
								handleOpen();
							}}
						>
							<AddIcon />
							<span className='item_title'>Thêm lý do</span>
						</button>,
						<button>
							<GetAppIcon />
							<span className='item_title'>Import</span>
						</button>,
						<button>
							<PublishIcon />
							<span className='item_title'>Xuất file</span>
						</button>,
					]}
				/>
				<ReasonCancelDialog open={open} handleClose={handleClose} handleOpenDialogItem={handleOpenDialogItem} />

				<ReasonCancelItemDialog
					open={openDialogItem}
					handleClose={handleCloseDialogItem}
					className={'dialog_item'}
				/>
			</>
		</LayoutContainer>
	);
}

const ReasonCancel = (props) => {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<ReasonCancelContent />
		</Container>
	);
};

export default ReasonCancel;
