import React from 'react';
import { formatNumberWithSpaces } from '../../Utils';

export default function TabTotal({ isTotalVoucher }) {
	return (
		<div className="dialog__content_edit_voucher__body_checkbox">
			<div className="group_checkbox_voucher"></div>
			<div className="flex-top">
				<div className="item_price_voucher">
					<div className="flex-between">
						<span>Tiền thuế:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_thue_vao) : '0'} {'VND'}
						</span>
					</div>

					<div className="flex-between">
						<span>Tiền hàng:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_tien) : '0'} {'VND'}
						</span>
					</div>

					<div
						className="flex-between"
						style={{
							fontWeight: 'bold',
						}}
					>
						<span>Tổng tiền:</span>
						<span>
							{isTotalVoucher
								? formatNumberWithSpaces(isTotalVoucher?.t_thue_vao + isTotalVoucher?.t_tien)
								: '0'}{' '}
							{'VND'}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
