import { Modal } from 'antd';
import React from 'react';
import { HiX } from 'react-icons/hi';
import VButton from './VButton';
import styles from './VComponent.module.css';
import './VComponent.css';

/**
 * Renders a custom Modal with a title, optional header actions, and custom content.
 * @param {import('antd').ModalProps} props - The component props.
 * @param {Boolean} props.open - Whether the modal is open or not.
 * @param {Function} props.onClose - The function to call when the modal closes.
 * @param {String} props.title - The title to display in the modal header.
 * @param {React.ReactNode[]} props.headerActions - An array of React nodes to display in the modal header.
 * @param {import('antd').ModalProps} rest - Any additional props to pass down to the antd Modal component.
 * @returns {JSX.Element} A React component that renders the custom Modal.
 */
export default function VModal({ open, onClose, title, headerActions, ...rest }) {
	return (
		<Modal
			open={open}
			onCancel={onClose}
			closable={false}
			title={
				<div className={styles.v_modal_title}>
					<span>{title}</span>

					<div className={styles.v_modal_actions}>
						{headerActions?.map((action, index) => (
							<div key={index}> {action} </div>
						))}

						<VButton type="text" onClick={onClose} icon={<HiX />} />
					</div>
				</div>
			}
			prefixCls="v-modal"
			{...rest}
		/>
	);
}
