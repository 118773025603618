import React, { useEffect, useState } from 'react';
import {
	AiFillCopy,
	AiFillDelete,
	AiFillFileAdd,
	AiFillPrinter,
	AiOutlineArrowDown,
	AiOutlineArrowUp,
} from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { IoDuplicateOutline } from 'react-icons/io5';
import { convertStringToNumber } from '../../Utils';
import TableManager from '../../../components/tableManager/TableManager';
import NotiDialog from '../../components/NotiDialog';

const defaultValues = {
	line: 0,
	ma_bp: '',
	ma_dt: '',
	ma_hd: '',
	ma_kh: '',
	ma_nv: '',
	ma_phi: '',
	ten_tk_co: '',
	tien: 0,
	tien_nt: 0,
	tk_co: '',
	dien_giai: '',
};

function TabDetail({ data, readonly, handleGetDataDetail, view, isFieldNt }) {
	useEffect(() => {
		if (view || !isFieldNt.checkEdit) {
			for (let i = 0; i < isDataVoucherDetail.length; i++) {
				updateMyData(i, 'tien', convertStringToNumber(isDataVoucherDetail[i].tien_nt) * isFieldNt?.ty_gia);
			}
		} else return;
	}, [isFieldNt]);

	const columns = [
		{
			Header: 'Tài khoản có',
			accessor: 'tk_co',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'tk_co', item.tk);
					updateMyData(index, 'ten_tk_co', item.ten_tk);
				},
				valueField: 'tk',
				labelField: 'ten_tk',
				api: 'account',
				q: { status: true },
				prefix: true,
				t: 1,
				getLabel: (item) => {
					return `${item.tk} - ${item.ten_tk}`;
				},
			},
		},
		{
			Header: `Tiền ${isFieldNt?.ma_nt}`,
			accessor: 'tien_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'number',
			handleOnChange: (index, value) => {
				updateMyData(index, 'tien', convertStringToNumber(value) * isFieldNt?.ty_gia);
			},
		},
		{
			Header: 'Tiền VND',
			accessor: 'tien',
			width: 200,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: 'Hợp đồng',
			accessor: 'ma_hd',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
			type: 'select',
			disableSortBy: true,
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_hd', item.so_hd);
					updateMyData(index, 'ten_hd', item.ten_hd);
				},
				valueField: 'so_hd',
				labelField: 'ten_hd',
				api: 'contract',
				q: { status: true },
				t: 1,
				getLabel: (item) => {
					return `${item.so_hd} - ${item.ten_hd}`;
				},
			},
		},
		{
			Header: 'Vụ việc',
			accessor: 'ten_dt',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
			Filter: false,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_dt', item.ma_dt);
					updateMyData(index, 'ten_dt', item.ten_dt);
				},
				valueField: 'ma_dt',
				labelField: 'ten_dt',
				api: 'dmdt',
				q: { status: true },
				t: 1,
				getLabel: (item) => {
					return `${item.ma_dt} - ${item.ten_dt}`;
				},
			},
		},
		{
			Header: 'Doanh thu/Chi phí',
			accessor: 'ten_phi',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_phi', item.ma_phi);
					updateMyData(index, 'ten_phi', item.ten_phi);
				},
				valueField: 'ma_phi',
				labelField: 'ten_phi',
				api: 'dmphi',
				q: { status: true },
				t: 1,
				getLabel: (item) => {
					return `${item.ma_phi} - ${item.ten_phi}`;
				},
			},
		},
		{
			Header: 'Tên khách hàng',
			accessor: 'ten_kh',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_kh', item.ma_kh);
					updateMyData(index, 'ten_kh', item.ten_kh);
				},
				valueField: 'ma_kh',
				labelField: 'ten_kh',
				api: 'customer',
				q: { status: true },
				getLabel: (item) => {
					return `${item.ten_kh}`;
				},
			},
		},
		{
			Header: 'Diễn giải',
			accessor: 'dien_giai',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			disableSortBy: true,
			type: 'text',
		},
	];
	const [isDataVoucherDetail, setIsDataVoucherDetail] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);

	const [openDelete, setOpenDelete] = useState(false);

	useEffect(() => {
		if (data === undefined || data?.details.length < 1) {
			const newData = [];
			for (let i = 0; i < 1; i++) {
				newData.push({ ...defaultValues, line: i + 1 });
			}
			setIsDataVoucherDetail(newData);
		} else {
			setIsDataVoucherDetail(data?.details);
		}
	}, []);

	const skipResetRef = React.useRef(false);
	const updateMyData = (rowIndex, columnId, value) => {
		skipResetRef.current = true;
		setIsDataVoucherDetail((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};
	useEffect(() => {
		skipResetRef.current = false;
		handleGetDataDetail && handleGetDataDetail(isDataVoucherDetail);
	}, [isDataVoucherDetail]);

	const menuTabDetail = [
		{
			id: 1,
			tabName: 'Thêm',
			icon: <AiFillFileAdd />,
			handle: () => {
				handleOpenAddDetailItemVoucher();
			},
		},
		// {
		// 	id: 2,
		// 	tabName: 'Chuyển lên',
		// 	icon: <AiOutlineArrowUp />,
		// 	handle: () => {
		// 		// handleMoveRowItemVoucher('up');
		// 	},
		// },
		// {
		// 	id: 3,
		// 	tabName: 'Chuyển xuống',
		// 	icon: <AiOutlineArrowDown />,
		// 	handle: () => {
		// 		// handleMoveRowItemVoucher('down');
		// 	},
		// },
		{
			id: 4,
			tabName: 'Nhân dòng',
			icon: <IoDuplicateOutline />,
			handle: () => {
				handleDuplicateRowItemVoucher();
			},
		},
		{
			id: 5,
			tabName: 'Xoá',
			icon: <AiFillDelete />,
			handle: () => {
				handleDeleteItemVoucher();
			},
		},
		// {
		// 	id: 6,
		// 	tabName: 'Lấy dữ liệu',
		// 	icon: <AiFillCopy />,
		// 	handle: () => {
		// 		console.log(isDataVoucherDetail);
		// 	},
		// },
		{
			id: 7,
			tabName: 'Nhập',
			icon: <BiImport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 8,
			tabName: 'Xuất',
			icon: <BiExport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 9,
			tabName: 'In',
			icon: <AiFillPrinter />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
	];

	const handleOpenAddDetailItemVoucher = () => {
		skipResetRef.current = true;
		setIsDataVoucherDetail((old) => [...old, { ...defaultValues, line: old.length }]);
	};

	//On select row
	const onSelectRowClick = (data) => {
		setDataSelected(data);
	};
	const handleDeleteItemVoucher = async () => {
		if (dataSelected.length <= 0) {
			setOpenDelete(true);
		} else {
			if (isDataVoucherDetail && dataSelected) {
				const newData = isDataVoucherDetail.filter((item) => {
					const index = dataSelected.findIndex((selectedItem) => selectedItem.line === item.line);
					return index === -1;
				});

				newData.forEach((item, index) => {
					item.line = index;
				});

				setIsDataVoucherDetail(newData);
				setDataSelected([]);
			}
		}
	};
	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	const handleMoveRowItemVoucher = async (type) => {
		if (data?.details && dataSelected) {
			// Kiểm tra đang ở vị trí nào trong mảng
			const index = dataSelected[0].line;

			if (type === 'up' && index !== 0) {
				// đổi chỗ với phần tử trước
				const temp = data?.details[index - 1];
				data.details[index - 1] = data?.details[index];
				data.details[index] = temp;
			} else if (type === 'down' && index !== data?.details?.length - 1) {
				// đổi chỗ với phần tử sau
				const temp = data?.details[index + 1];
				data.details[index + 1] = data?.details[index];
				data.details[index] = temp;
			}

			// Cập nhật lại line
			data.details.forEach((item, index) => {
				item.line = index;
			});

			try {
				// await context.apis.asyncPostList(context.userInfo.token, "pt1", {
				// 	...data,
				// });
				// await getDataVoucherPT();
				console.log('dataMove', data);
				setDataSelected([]);
			} catch (error) {
				console.log(error);
			}
		}
	};

	//Nhân dòng
	const handleDuplicateRowItemVoucher = async () => {
		skipResetRef.current = true;
		dataSelected.forEach((item) => {
			delete item._id;
		});
		//  vòng lặp để tạo ra các dòng mới
		const newData = dataSelected.map((item) => {
			return { ...item, line: isDataVoucherDetail.length };
		});

		setIsDataVoucherDetail((old) => [...old, ...newData]);
	};

	return (
		<div className="box_tab_detail_vouvher">
			<div className="box_tab_detail_vouvher__content">
				<TableManager
					idTable={view ? 'table-detail-voucher-view' : 'table-detail-voucher'}
					columns={columns}
					data={view ? data?.details : isDataVoucherDetail || []}
					updateMyData={updateMyData}
					skipReset={skipResetRef.current}
					selectRow={view ? false : true}
					onSelectRowClick={onSelectRowClick}
					readonly={readonly}
					TbodyMaxHeight={'11vh'}
					handleOpenAddDetailItemVoucher={handleOpenAddDetailItemVoucher}
					pagination={false}
					hiddenColumns={isFieldNt?.ma_nt === 'VND' ? ['tien'] : []}
					toolBar={
						<>
							{view ? (
								''
							) : (
								<div className="box_tab_detail_vouvher__header">
									{menuTabDetail.map((item) => {
										const shouldDisplayItem =
											dataSelected.length > 0 || (item.id !== 4 && item.id !== 5);
										if (shouldDisplayItem) {
											return (
												<div key={item.id} onClick={item.handle} title={`${item.tabName}`}>
													{item.icon} <span className="tabname_tool_bar">{item.tabName}</span>
												</div>
											);
										}
										return null;
									})}
								</div>
							)}
						</>
					}
				/>
				<NotiDialog
					open={openDelete}
					handleClose={handleCloseDelete}
					title="Thông báo"
					content="Vui lòng chọn ít nhất 1 dòng để thực hiện chức năng này"
				/>
			</div>
		</div>
	);
}

export default TabDetail;
