// export const authorizationOptions = [
// 	{
// 		value: 0,
// 		label: 'Mọi người',
// 	},
// 	{
// 		value: 1,
// 		label: 'Chỉ người tạo',
// 	},
// 	{
// 		value: 2,
// 		label: 'Những người sau',
// 	},
// 	{
// 		value: 5,
// 		label: 'Nhóm người dùng sau',
// 	},
// ];

export const authorizationOptions = [
	{
		value: 0,
		label: 'All',
	},
	{
		value: 1,
		label: 'User created',
	},
	{
		value: 2,
		label: 'List of user',
	},
	{
		value: 5,
		label: 'List of group',
	},
];
