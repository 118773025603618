import Tooltip from '@material-ui/core/Tooltip';
import { AddRounded, DeleteRounded, FilterListRounded } from '@material-ui/icons';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { useCore } from '../../../hooks';
import { formatCurrencyDisplay } from '../../../utils/helpers';
import '../components/filter/Filter.css';
import MenuPopup from '../components/MenuPopup';
import ListTable from '../components/table/ListTable/ListTable';
import FormGoodsDialog from './dialog/form/FormGoodsDialog';
import InventoryDialog from './dialog/inventory/InventoryDialog';

export default function GoodsData({
	data,
	getData,
	deleteGoods,
	deleteGoodsList,
	openFilter,
	setOpenFilter,
	tableProps,
	q,
}) {
	const core = useCore();
	const [openMenu, setOpenMenu] = useState('');
	const handleOpenMenu = (menu) => () => setOpenMenu(menu);
	const handleCloseMenu = () => setOpenMenu('');

	// Add Menu
	const addMenuAnchorRef = useRef(null);

	// Abstract Dialog
	const [openDialog, setOpenDialog] = useState(false);
	const [dialogData, setDialogData] = useState(null);

	const handleOpenDialog = (name, data) => () => {
		setDialogData(data);
		setOpenDialog(name);
	};

	const handleCloseDialog = () => {
		setDialogData(null);
		setOpenDialog(false);
	};

	const handleAddGoodsBtn = handleOpenDialog('form', {
		is_goods: true,
		tg_tk: true, // Mặc định theo dõi tồn kho
	});
	const handleAddServiceBtn = handleOpenDialog('form', {
		is_service: true,
		tg_tk: false, // Mặc định không theo dõi tồn kho
	});
	const handleAddPackageBtn = handleOpenDialog('form', {
		is_package: true,
		tg_tk: false, // Mặc định không theo dõi tồn kho
	});
	const handleAddCardBtn = handleOpenDialog('form', {
		is_card: true,
		tg_tk: false, // Mặc định không theo dõi tồn kho
	});

	const handleEditRow = async (data) => {
		const dataAPI = await core.asyncGetData(core.token, 'dmvt', {
			_id: data._id,
		});
		handleOpenDialog('form', dataAPI)();
	};

	const handleCopyRow = async (data) => {
		const dataAPI = await core.asyncGetData(core.token, 'dmvt', {
			_id: data._id,
		});
		const { _id, ma_vt, ...copyData } = dataAPI; // remove _id, ma_vt from dataAPI
		handleOpenDialog('form', copyData)();
	};

	const handleOpenInventory = (data) => handleOpenDialog('inventory', { ma_vt: data.ma_vt });

	// Selected Rows
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);
	const handleRowSelected = (state) => {
		setSelectedRows(state.selectedRows);
	};
	const handleDeleteRowsSelected = async () => {
		await deleteGoodsList(selectedRows.map((item) => item._id));
		setSelectedRows([]);
		setToggleCleared(!toggleCleared);
	};

	const actions = [
		<Tooltip title='Thêm mới' placement='top'>
			<button ref={addMenuAnchorRef} onClick={handleOpenMenu('add')} className='btn-list'>
				<AddRounded />
			</button>
		</Tooltip>,
	];

	if (selectedRows.length > 0) {
		actions.unshift(
			<Tooltip title='Xoá' placement='top'>
				<button onClick={handleDeleteRowsSelected} className='btn-delete'>
					<DeleteRounded />
				</button>
			</Tooltip>,
		);
	}

	const columns = [
		{
			name: 'Mã hàng hóa',
			selector: (row) => row.ma_vt,
			sortable: true,
		},
		{
			name: 'Tên hàng',
			selector: (row) => row.ten_vt,
			sortable: true,
			wrap: true,
			grow: 2,
		},
		{
			name: 'Giá bán',
			selector: (row) => formatCurrencyDisplay(row.gia_ban_le),
			sortable: true,
			right: true,
		},
		{
			name: 'Giá vốn',
			selector: (row) => formatCurrencyDisplay(row.gia_ban_le0),
			sortable: true,
			right: true,
		},
		{
			name: 'Tồn kho',
			selector: (row) => formatCurrencyDisplay(row.ton00),
			sortable: true,
			right: true,
		},
		{
			name: '',
			cell: (row) => (
				<Button size='small' onClick={handleOpenInventory(row)}>
					Tồn kho
				</Button>
			),
			ignoreRowClick: true,
			compact: true,
		},
	];

	return (
		<>
			{/* Add */}
			<MenuPopup
				anchorRef={addMenuAnchorRef}
				open={openMenu === 'add'}
				onClose={handleCloseMenu}
				menuItems={[
					{
						label: 'Thêm hàng hóa',
						onClick: handleAddGoodsBtn,
					},
					{
						label: 'Thêm dịch vụ',
						onClick: handleAddServiceBtn,
					},
					{
						label: 'Thêm gói dịch vụ',
						onClick: handleAddPackageBtn,
					},
					{
						label: 'Thêm thẻ tài khoản',
						onClick: handleAddCardBtn,
					},
				]}
			/>

			<FormGoodsDialog
				open={openDialog === 'form'}
				handleClose={handleCloseDialog}
				data={dialogData}
				getData={getData}
			/>

			<InventoryDialog open={openDialog === 'inventory'} handleClose={handleCloseDialog} data={dialogData} />

			<ListTable
				importProps={{ getData, api: 'dmvt', params: { ma_ct: 'dmvt' } }}
				exportProps={{ code: 'dmvt', params: { q, limit: tableProps.totalRows } }}
				columns={columns}
				data={data}
				actions={actions}
				title={
					<div className='table-title'>
						<button className='btn btn-list' onClick={() => setOpenFilter(!openFilter)}>
							<FilterListRounded />
						</button>
						Danh sách hàng hóa
					</div>
				}
				onRowClick={handleEditRow}
				selectableRows
				onSelectedRowsChange={handleRowSelected}
				toggleCleared={toggleCleared}
				onRowCopy={handleCopyRow}
				onRowDelete={(row) => deleteGoods(row._id)}
				{...tableProps}
			/>
		</>
	);
}
