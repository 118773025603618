import React from 'react';
import { Menu, Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { formatCurrencyDisplay, numeralCustom } from '../../../../../../utils/helpers';

const MenuWrapper = styled(Menu)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const RowMenu = styled(Box)`
	width: 250px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
`;
const TextDisplay = styled(Typography)`
	font-size: 13px;
	font-weight: 550;
`;
const Input = styled.input`
	border: none;
	outline: none;
	border-bottom: 1px solid #ccc;
	width: 80px;
	text-align: right;
`;
const Modes = styled(Box)`
	display: flex;
	align-items: center;
	gap: 5px;
`;
const ModeButton = styled(Box)`
	width: 34px;
	height: 24px;
	color: ${(props) => (props.active ? '#fff' : '#000')};
	background-color: ${(props) => (props.active ? props.bgColor : '#ededed')};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	border-radius: 6px;
	cursor: pointer;
`;
const ButtonAdd = styled.button`
	border: none;
	outline: none;
	border-radius: 6px;
	background-color: ${(props) => props.bgColor};
	color: #fff;
	width: 60px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	border-radius: 6px;
	cursor: pointer;
`;

function MenuChietKhau({ detail, details, handleBillChange, anchorEl, handleClose }) {
	const theme = useTheme();

	const [mode, setMode] = React.useState(1); // 1 la tien chiet khau; 2 la phan tram chiet khau
	const [giaTriChietKhau, setGiaTriChietKhau] = React.useState();

	React.useEffect(() => {
		if (mode === 1) {
			setGiaTriChietKhau(detail.tien_ck_nt / (detail.sl_order || detail.sl_xuat || 1));
		} else if (mode === 2) {
			setGiaTriChietKhau(Math.ceil(detail.ty_le_ck));
		}
	}, [mode]);

	const handleTyLeCkChanged = async (ty_le_ck) => {
		const detailCopy = cloneDeep(detail);
		const detailsCopy = cloneDeep(details);
		detailCopy.ty_le_ck = ty_le_ck;
		detailCopy.tien_ck_nt =
			(((detailCopy.gia_ban || detailCopy.gia_ban_nt) * detailCopy.ty_le_ck) / 100) *
			(detailCopy.sl_order || detailCopy.sl_xuat);
		const index = detailsCopy.findIndex((d) => d._id === detailCopy._id);
		if (index >= 0) {
			detailsCopy.splice(index, 1, detailCopy);
			await handleBillChange(detailsCopy);
		}
	};
	const handleTienCkChanged = async (tien_ck_nt) => {
		const detailCopy = cloneDeep(detail);
		const detailsCopy = cloneDeep(details);
		detailCopy.tien_ck_nt = tien_ck_nt * (detailCopy.sl_order || detailCopy.sl_xuat);

		const ty_le = Number((tien_ck_nt / (detailCopy.gia_ban || detailCopy.gia_ban_nt)) * 100);
		detailCopy.ty_le_ck = ty_le;

		const index = detailsCopy.findIndex((d) => d._id === detailCopy._id);
		if (index >= 0) {
			detailsCopy.splice(index, 1, detailCopy);
			await handleBillChange(detailsCopy);
		}
	};
	const handleChangeChietKhau = () => {
		if (mode === 1) {
			handleTienCkChanged(giaTriChietKhau);
		} else if (mode === 2) {
			handleTyLeCkChanged(giaTriChietKhau);
		}
		handleClose();
	};
	const handleGiaTriChietKhauChange = (e) => {
		const value = numeralCustom(e.target.value).value();
		if (!value) {
			setGiaTriChietKhau(0);
		} else {
			if (isNaN(value)) {
				return;
			} else {
				let data = Number(value);
				if (data < 0) return;
				if (mode === 1) {
					if (detail.gia_ban_le_goc) {
						if (data > detail?.gia_ban_le_goc) {
							data = Number(detail?.gia_ban_le_goc);
						}
					}
				} else if (mode === 2) {
					if (data > 100) {
						data = 100;
					}
				}
				setGiaTriChietKhau(data);
			}
		}
	};

	return (
		<MenuWrapper anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
			<RowMenu>
				<TextDisplay>Đơn giá</TextDisplay>
				<Input readOnly value={formatCurrencyDisplay(detail.gia_ban)} />
			</RowMenu>
			<RowMenu>
				<TextDisplay>Giảm giá</TextDisplay>
				<Modes>
					<ModeButton onClick={() => setMode(1)} active={mode === 1} bgColor={theme.palette.primary.main}>
						VND
					</ModeButton>
					<ModeButton onClick={() => setMode(2)} active={mode === 2} bgColor={theme.palette.primary.main}>
						%
					</ModeButton>
				</Modes>
				<Input
					type="text"
					value={giaTriChietKhau ? numeralCustom(giaTriChietKhau).format('0, 0') : ''}
					onChange={handleGiaTriChietKhauChange}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							handleChangeChietKhau();
						}
					}}
				/>
			</RowMenu>
			<RowMenu>
				<TextDisplay>Giá bán</TextDisplay>
				<Input
					readOnly
					value={formatCurrencyDisplay(
						(detail.gia_ban || detail.gia_ban_nt) - detail.tien_ck_nt / (detail.sl_order || detail.sl_xuat)
					)}
				/>
			</RowMenu>
			<Box style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
				<ButtonAdd onClick={handleChangeChietKhau} bgColor={theme.palette.primary.main}>
					Thêm
				</ButtonAdd>
			</Box>
		</MenuWrapper>
	);
}

export default MenuChietKhau;
