import dayjs from 'dayjs';
import React from 'react';
import { useCore } from '../../../../../hooks';
import Dialog from '../../../components/Dialog/Dialog';
import TabContainer from '../../../components/tab/TabContainer';
import InventorySheetTab from './tabs/InventorySheetTab';
import InventoryTab from './tabs/InventoryTab';

export default function InventoryDialog({ open, handleClose, data }) {
	const ma_vt = data?.ma_vt;
	const core = useCore();

	const [loading, setLoading] = React.useState(false);
	const [inventorySheetData, setInventorySheetData] = React.useState([]);
	const [inventoryData, setInventoryData] = React.useState([]);

	React.useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const [inventorySheetData, inventoryData] = await Promise.all([
				Promise.resolve([]), // TODO: get inventory sheet data
				core.apiCall({
					endpoint: 'ckvt',
					method: 'GET',
					params: {
						ma_vt,
						groupBy: 'ma_kho',
						den_ngay: dayjs().format('YYYY-MM-DD'),
					},
				}),
			]);

			setInventoryData(inventoryData.data);
			setInventorySheetData(inventorySheetData.data);

			setLoading(false);
		};

		if (ma_vt && open) fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ma_vt]);

	const tabs = [
		// {
		// 	label: 'Chi tiết',
		// 	component: <InventorySheetTab data={inventorySheetData} />,
		// },
		{
			label: 'Chi tiết',
			component: <InventoryTab data={inventoryData} />,
		},
	];
 console.log(inventorySheetData,"2",inventoryData)
	return (
		<Dialog open={open} handleClose={handleClose} title='Chi tiết tồn kho' loading={loading}>
			<div style={{ width: 1000, maxWidth: '100%' }}>
				<TabContainer tabs={tabs} />
			</div>
		</Dialog>
	);
}
