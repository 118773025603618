import { Grid } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';

export default function AuthorizationTab({ hookForm }) {
	const {
		control,
		watch,
		formState: { errors },
		getValues,
		setValue,
	} = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Controller
					control={control}
					name='ma_kh'
					render={({ field: { onChange, value } }) => {
						const item = value ? { value, label: getValues('ten_kh') } : null;
						const handleChange = (item) => {
							onChange(item?.value || null);
							setValue('ten_kh', item?.label || '');
						};

						return (
							<FieldSelectApi
								title='Ghi nhận công nợ cho'
								api='customer'
								q={{ status: true }}
								valueField='ma_kh'
								labelField='ten_kh'
								value={item}
								onChange={handleChange}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='tk_cn'
					render={({ field: { value, onChange } }) => {
						const label = value;
						const item = value ? { value, label } : null;
						const handleChange = (item) => onChange(item?.value || null);

						return (
							<FieldSelectApi
								title='Tài khoản công nợ'
								placeholder='Chọn tài khoản'
								api='account'
								q={{ loai_tk: 1 }}
								valueField='tk'
								labelField='ten_tk'
								getLabel={(item) => `${item.tk} - ${item.ten_tk}`}
								value={item}
								onChange={handleChange}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='visible_to'
					render={({ field: { onChange, value } }) => (
						<FieldSelect
							title='Có giá trị với'
							placeholder='Chọn giá trị'
							items={[
								{
									label: 'Mọi người',
									value: 0,
								},
								{
									label: 'Chỉ người tạo',
									value: 1,
								},
								{
									label: 'Những người sau',
									value: 2,
								},
							]}
							onChange={(item) => onChange(item.value)}
							defaultValue={value || 0}
							disableClearable
						/>
					)}
				/>

				{watch('visible_to') === 2 && (
					<Controller
						name='visible_to_users'
						control={control}
						rules={{
							validate: (value) => value?.length > 0 || 'Vui lòng chọn người được phép xem',
						}}
						render={({ field: { value, onChange } }) => {
							const items = value?.map((item) => ({ value: item, label: item, email: item })) || [];
							const handleChange = (items) => onChange(items?.map((item) => item.email) || []);

							return (
								<FieldSelectApi
									placeholder='Nhập người được phép xem'
									api='participant'
									multi
									valueField='email'
									labelField='email'
									value={items}
									onChange={handleChange}
									check
									errorMsg={errors?.visible_to_users?.message}
								/>
							);
						}}
					/>
				)}
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='dataview_to'
					render={({ field: { onChange, value } }) => (
						<FieldSelect
							title='Dữ liệu liên quan'
							placeholder='Chọn giá trị'
							items={[
								{
									label: 'Mọi người',
									value: 0,
								},
								{
									label: 'Chỉ người tạo',
									value: 1,
								},
								{
									label: 'Những người sau',
									value: 2,
								},
							]}
							onChange={(item) => onChange(item.value)}
							defaultValue={value || 0}
							disableClearable
						/>
					)}
				/>

				{watch('dataview_to') === 2 && (
					<Controller
						name='dataview_to_users'
						control={control}
						rules={{
							validate: (value) => value?.length > 0 || 'Vui lòng chọn người được phép xem',
						}}
						render={({ field: { value, onChange } }) => {
							const items = value?.map((item) => ({ value: item, label: item, email: item })) || [];
							const handleChange = (items) => onChange(items?.map((item) => item.email) || []);

							return (
								<FieldSelectApi
									placeholder='Nhập người được phép xem'
									api='participant'
									multi
									valueField='email'
									labelField='email'
									value={items}
									onChange={handleChange}
									check
									errorMsg={errors?.dataview_to_users?.message}
								/>
							);
						}}
					/>
				)}
			</Grid>
		</Grid>
	);
}
