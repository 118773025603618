import React from "react";
import { BarCodeContentStyled, ContentLeft } from "./barcodeStyled";
import { Typography } from "antd";
import ProductInfoLine from "./ProductInfoLine";
import Paper from "@material-ui/core/Paper";
import BarcodeComponent from "./BarcodeComponent";
import { useLocation } from "react-router-dom";
import DefaultContext from "flexbiz-core/components/auth/Context";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";

function BarcodeContent(
  {
    formatBarcode,
    selectedProducts,
    setSelectedProducts,
    numberColumn,
    options,
    handleOptionsChange,
  },
  ref
) {
  const context = React.useContext(DefaultContext);
  const location = useLocation();

  // handle delete product
  const handleDeleProduct = (selectedProduct) => {
    setSelectedProducts((prev) => {
      const newSelectedProducts = prev.filter(
        (item) => item._id !== selectedProduct._id
      );
      return newSelectedProducts;
    });
  };
  const addProduct = async ({ ma_vt, sl_xuat, sl_nhap }) => {
    try {
      const resp = await context.apis.asyncGetData(
        context.userInfo?.token,
        "dmvt",
        { ma_vt }
      );
      if (resp) {
        setSelectedProducts((prev) => {
          const prevClone = cloneDeep(prev);
          const index = prevClone.findIndex((item) => item._id === resp._id);
          if (index >= 0) {
            if (prevClone.sl_xuat) {
              prevClone[index].sl_xuat += 1;
            } else if (prevClone.sl_nhap) {
              prevClone[index].sl_nhap += 1;
            }
          } else {
            resp.display = {
              top: ["ten_vt"],
              bottom: ["gia_ban_le"],
            };
            resp.sl_xuat = 12 || 0;
            resp.sl_nhap = 12 || 0;
            resp.sl_in = sl_nhap || sl_xuat;
            prevClone.push(resp);
          }
          return prevClone;
        });
      }
    } catch (error) {
      context.alert(error);
    }
  };
  // get voucher
  const getVoucher = async (ma_ct, _id) => {
    try {
      const resp = await context.apis.asyncGetData(
        context?.userInfo?.token,
        ma_ct,
        { _id }
      );
      if (resp) {
        const details = cloneDeep(resp.details);
        details.forEach(async (detail) => {
          await addProduct(detail);
        });
      }
    } catch (error) {
      context.alert(error);
    }
  };

  React.useEffect(() => {
    const searchUrl = location.search;
    if (searchUrl) {
      const searchParams = searchUrl.split("&");
      const ma_ct = searchParams[0].split("=")[1];
      const _id = searchParams[1].split("=")[1];
      if (ma_ct && _id) {
        getVoucher(ma_ct, _id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, context]);

  return (
    <BarCodeContentStyled>
      <ContentLeft className="hidden-scroll-y">
        {selectedProducts?.length === 0 ? (
          <Typography
            style={{
              textAlign: "center",
              fontSize: "13px",
              fontStyle: "italic",
              color: "#999",
            }}
          >
            Chưa có vật tư
          </Typography>
        ) : (
          <>
            {selectedProducts?.length > 0 &&
              selectedProducts.map((item) => (
                <ProductInfoLine
                  key={v4()}
                  product={item}
                  handleDeleProduct={handleDeleProduct}
                  setSelectedProducts={setSelectedProducts}
                />
              ))}
          </>
        )}
      </ContentLeft>
      <Paper style={{ width: "70%", height: "100%" }}>
        <BarcodeComponent
          ref={ref}
          details={selectedProducts}
          numberColumn={numberColumn}
          formatBarcode={formatBarcode}
          options={options}
          handleOptionsChange={handleOptionsChange}
        />
      </Paper>
    </BarCodeContentStyled>
  );
}

export default React.forwardRef(BarcodeContent);
