import React from 'react';
import ListBase from '../ListBase/ListBase';
import { formatDateDisplay, numeralCustom } from '../../../utils/helpers';
import withContainer from '../withContainer';
import PriceFilter from './PriceFilter';
import PriceForm from './PriceForm';

const columns = [
	{
		name: 'Sản phẩm',
		selector: (row) => row.ten_vt,
		sortable: true,
		minWidth: '200px',
	},
	{
		name: 'Của hàng',
		selector: (row) => row.ten_kho,
		sortable: true,
	},
	{
		name: 'Tỷ lệ giảm giá',
		selector: (row) => row.ty_le_ck,
		sortable: true,
	},
	{
		name: 'Tiền giảm giá',
		selector: (row) => row.tien_ck,
		sortable: true,
	},
	{
		name: 'Hiệu lực từ',
		selector: (row) => row.hieu_luc_tu,
		sortable: true,
		format: (row) => formatDateDisplay(row.hieu_luc_tu),
	},
	{
		name: 'Hiệu lực đến',
		selector: (row) => row.hieu_luc_den,
		sortable: true,
		format: (row) => formatDateDisplay(row.hieu_luc_den),
	},
];

function ListPrice(props) {
	return (
		<ListBase
			api="dmgiaban"
			title="Danh sách giá bán"
			columns={columns}
			FilterComponent={PriceFilter}
			FormDialog={PriceForm}
		/>
	);
}

export default withContainer(ListPrice);
