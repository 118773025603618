import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useState } from 'react';
import Container from '../Container';
import LayoutContainer from '../components/LayoutContainer';
import TableContainer from '../components/table/TableContainer';
import ShiftFilter from './ShiftFilter';
import ShiftDialog from './Dialog/ShiftDialog';
import ShiftItemDialog from './Dialog/ShiftItemDialog';

const columns = [
	{
		name: 'Tên ca',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Bắt đầu',
		selector: (row) => row.datetimestart,
		sortable: true,
	},
	{
		name: 'Kết thúc',
		selector: (row) => row.datetimeend,
		sortable: true,
	},
];

const data = [
	{
		id: 1,
		name: 'Ca 1',
		datetimestart: '10:00:00',
		datetimeend: '20:00:00',
		description: {
			room: 'Phòng 1',
			desc: 'Đây là mô tả của phòng 1',
		},
		dataTable: [
			{
				id: 1,
				timedate: '10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 2,
		name: 'Ca 2',
		datetimestart: '10:00:00',
		datetimeend: '20:00:00',
		description: {
			room: 'Phòng 2',
			desc: 'Đây là mô tả của phòng 2',
		},
		dataTable: [
			{
				id: 1,
				timedate: '10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 3,
		name: 'Ca 3',
		datetimestart: '10:00:00',
		datetimeend: '20:00:00',
		description: {
			room: 'Phòng 3',
			desc: 'Đây là mô tả của phòng 3',
		},
		dataTable: [
			{
				id: 1,
				timedate: '10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 4,
		name: 'Ca 4',
		datetimestart: '10:00:00',
		datetimeend: '20:00:00',
		description: {
			room: 'Phòng 4',
			desc: 'Đây là mô tả của phòng 4',
		},
		dataTable: [
			{
				id: 1,
				timedate: '10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 5,
		name: 'Ca 5',
		datetimestart: '10:00:00',
		datetimeend: '20:00:00',
		description: {
			room: 'Phòng 5',
			desc: 'Đây là mô tả của phòng 5',
		},
		dataTable: [
			{
				id: 1,
				timedate: '10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
];

function ShiftContent() {
	const [open, setOpen] = useState(false);
	const [openDialogItem, setOpenDialogItem] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		console.log('close');
		setOpen(false);
	};

	const handleOpenDialogItem = () => {
		setOpenDialogItem(true);
	};

	const handleCloseDialogItem = () => {
		setOpenDialogItem(false);
	};

	return (
		<LayoutContainer
			FilterLeft={<ShiftFilter />}
			children={
				<>
					<TableContainer
						columns={columns}
						title={<div>Danh sách ca làm việc</div>}
						data={data}
						actions={[
							<button
								onClick={() => {
									handleOpen();
								}}
							>
								<AddIcon />
								<span className='item_title'>Thêm ca làm việc</span>
							</button>,
							<button>
								<GetAppIcon />
								<span className='item_title'>Import</span>
							</button>,
							<button>
								<PublishIcon />
								<span className='item_title'>Xuất file</span>
							</button>,
						]}
					/>

					<ShiftDialog open={open} handleClose={handleClose} handleOpenDialogItem={handleOpenDialogItem} />

					<ShiftItemDialog
						open={openDialogItem}
						handleClose={handleCloseDialogItem}
						className={'dialog_item'}
					/>
				</>
			}
		/>
	);
}

const Shift = (props) => {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<ShiftContent />
		</Container>
	);
};

export default Shift;
