import {
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import Dialog from '../../components/Dialog/Dialog';

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0 auto',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			maxWidth: '500px',
		},
	},
}));

export default function WorkShiftDialog({ open, handleClose, data }) {
	const classes = useStyles();
	const [workStatus, setWorkStatus] = useState('work');
	const [shift, setShift] = useState('');
	const [repeat, setRepeat] = useState('');
	const [content, setContent] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log(workStatus);
		console.log(shift);
		console.log(repeat);
		console.log(content);
		handleClose();
	};
	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			title={'Lịch làm việc'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit}>
					<div>Lưu</div>
				</button>,
				<button className='btn btn-grey'>
					<div>Xoá</div>
				</button>,
			]}
		>
			<form className={classes.form} onSubmit={handleSubmit}>
				<FormControl component='fieldset'>
					<FormLabel component='legend'>Trạng thái công việc</FormLabel>
					<RadioGroup
						row
						aria-label='work-status'
						name='work-status'
						value={workStatus}
						onChange={(event) => setWorkStatus(event.target.value)}
					>
						<FormControlLabel value='work' control={<Radio />} label='Ngày làm' />
						<FormControlLabel value='off' control={<Radio />} label='Ngày nghỉ' />
					</RadioGroup>
				</FormControl>
				<FormControl>
					<InputLabel id='shift-label'>Chọn ca làm việc</InputLabel>
					<Select
						labelId='shift-label'
						id='shift'
						value={shift}
						onChange={(event) => setShift(event.target.value)}
					>
						<MenuItem value='morning'>Ca sáng</MenuItem>
						<MenuItem value='afternoon'>Ca chiều</MenuItem>
						<MenuItem value='night'>Ca đêm</MenuItem>
					</Select>
				</FormControl>{' '}
				<FormControl>
					<InputLabel id='repeat-label'>Lặp lại</InputLabel>
					<Select
						labelId='repeat-label'
						id='repeat'
						value={repeat}
						onChange={(event) => setRepeat(event.target.value)}
					>
						<MenuItem value='once'>Chỉ một lần</MenuItem>
						<MenuItem value='daily'>Hàng ngày</MenuItem>
						<MenuItem value='weekly'>Hàng tuần</MenuItem>
						<MenuItem value='monthly'>Hàng tháng</MenuItem>
					</Select>
				</FormControl>
				<TextField
					id='content'
					label='Nội dung'
					value={content}
					onChange={(event) => setContent(event.target.value)}
					multiline
					rows={4}
					variant='outlined'
				/>
			</form>
		</Dialog>
	);
}
