import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Input } from 'antd';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { cleanData, copyData, formatDateValue } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldBox from '../../components/Dialog/FieldBox';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldNumberInput from '../../components/Dialog/FieldNumberInput';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';

export default function FormParcelDialog({ open, onClose, data, onSubmit }) {
	const {
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
		control,
		getValues,
		setValue,
		watch,
		register,
	} = useForm();

	useEffect(() => {
		if (open) reset(data || {});
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			loading={isSubmitting}
			title={watch('_id') ? 'Chỉnh sửa lô' : 'Thêm lô'}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(onSave)} disabled={isSubmitting}>
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndNew)} disabled={isSubmitting}>
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndCopy)} disabled={isSubmitting}>
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					Hủy
				</button>,
			]}
			width={1000}
		>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Controller
						control={control}
						name="ma_vt"
						rules={{ required: 'Vui lòng chọn vật tư' }}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_vt') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ten_vt', item?.label || null);
							};

							return (
								<FieldSelectApi
									api="dmvt"
									title="Hàng hóa/Vật tư"
									placeholder="Chọn hàng hóa/vật tư"
									valueField="ma_vt"
									labelField="ten_vt"
									value={item}
									onChange={handleChange}
									check
									errorMsg={errors.ma_vt?.message}
								/>
							);
						}}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						control={control}
						name="so_luong"
						rules={{ required: 'Vui lòng nhập số lượng' }}
						render={({ field: { onChange, value } }) => (
							<FieldNumberInput
								title="Số lượng"
								value={value}
								onChange={onChange}
								check
								errorMsg={errors.so_luong?.message}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						control={control}
						name="ma_kho"
						rules={{ required: 'Vui lòng chọn kho' }}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_kho') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ten_kho', item?.label || null);
							};

							return (
								<FieldSelectApi
									api="dmkho"
									title="Kho"
									placeholder="Chọn kho"
									valueField="ma_kho"
									labelField="ten_kho"
									value={item}
									onChange={handleChange}
									check
									errorMsg={errors.ma_kho?.message}
								/>
							);
						}}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name="status"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControlLabel
								control={<Checkbox size="small" />}
								label="Còn sử dụng"
								checked={!!value}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<FieldInput
						title="Mã lô"
						inputProps={{ ...register('ma_lo', { required: 'Vui lòng nhập mã lô' }) }}
						check
						errorMsg={errors.ma_lo?.message}
					/>
				</Grid>

				<Grid item xs={6}>
					<FieldInput
						title="Tên lô"
						inputProps={{ ...register('ten_lo', { required: 'Vui lòng nhập tên lô' }) }}
						check
						errorMsg={errors.ten_lo?.message}
					/>
				</Grid>

				<Grid item xs={6}>
					<FieldInput title="Mã phụ" inputProps={{ ...register('exfields.ma_phu') }} />
				</Grid>

				<Grid item xs={6}>
					<FieldInput title="Tên khác" inputProps={{ ...register('exfields.ten_khac') }} />
				</Grid>

				<Grid item xs={6}>
					<Controller
						control={control}
						name="ngay_nhap"
						render={({ field: { onChange, value } }) => (
							<FieldInput
								title="Ngày nhập"
								type="date"
								value={formatDateValue(value)}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						control={control}
						name="ngay_bh"
						render={({ field: { onChange, value } }) => (
							<FieldInput
								title="Ngày bảo hành"
								type="date"
								value={formatDateValue(value)}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						control={control}
						name="production_date"
						render={({ field: { onChange, value } }) => (
							<FieldInput
								title="Ngày sản xuất"
								type="date"
								value={formatDateValue(value)}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						control={control}
						name="han_sd"
						rules={{ required: 'Vui lòng nhập hạn sử dụng' }}
						render={({ field: { onChange, value } }) => (
							<FieldInput
								title="Hạn sử dụng"
								type="date"
								value={formatDateValue(value)}
								onChange={onChange}
								check
								errorMsg={errors.han_sd?.message}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldBox title="Ghi chú" titleProps={{ style: { alignSelf: 'flex-start' } }}>
						<div className="field_input_container">
							<Controller
								control={control}
								name="dien_giai"
								render={({ field }) => <Input.TextArea {...field} autoSize />}
							/>
						</div>
					</FieldBox>
				</Grid>
			</Grid>
		</Dialog>
	);
}
