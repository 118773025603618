import React from 'react';
import SelectApi from '../input/SelectApi';
import FieldBox from './FieldBox';

export default function FieldSelectApi({
	title,
	api,
	placeholder = 'Chọn',
	valueField = '_id',
	labelField = '_id',
	value,
	onChange,
	defaultValue,
	withIdApp,
	q,
	prefix,
	check,
	errorMsg,
	getLabel,
	multi,
	fieldBoxProps,
	isClearable = true,
	isDisabled = false,
}) {
	return (
		<FieldBox title={title} check={check} errorMsg={errorMsg} {...fieldBoxProps}>
			<SelectApi
				multi={multi}
				api={api}
				placeholder={placeholder}
				valueField={valueField}
				labelField={labelField}
				value={value}
				getLabel={getLabel}
				onChange={onChange}
				defaultValue={defaultValue}
				classNamePrefix="field-select-api"
				withIdApp={withIdApp}
				q={q}
				prefix={prefix}
				isClearable={isClearable}
				isDisabled={isDisabled}
			/>
		</FieldBox>
	);
}
