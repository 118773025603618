import React from 'react';
import styled from 'styled-components';
import Dialog from '../components/Dialog/Dialog';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import FieldSelectApi from '../components/Dialog/FieldSelectApi';
import ButtonBase from '../components/newSale/components/button/ButtonBase';
import { CancelRounded, SaveRounded } from '@material-ui/icons';
import { BsPlus, BsTrash } from 'react-icons/bs';
import FieldInput from '../components/Dialog/FieldInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { v4 } from 'uuid';
import { cloneDeep, isEmpty } from 'lodash';
import SelectBase from '../components/select/SelectBase';

const PromotionFormStyled = styled(Box)`
	padding: 10px 0;
`;
const CheckboxWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const schema = yup.object({
	vat_tu: yup.object().required('Vui lòng chọn sản phẩm'),
	hieu_luc_tu: yup.date().typeError('Ngày hiệu lực không đúng định dạng').required('Vui lòng chọn ngày bắt đầu'),
	hieu_luc_den: yup
		.date()
		.typeError('Ngày hiệu lực không đúng định dạng')
		.required('Vui lòng chọn ngày kết thúc')
		.min(yup.ref('hieu_luc_tu'), 'Ngày kết thúc phải sau ngày bắt đầu'),
});
/**
 * - Thiếu các trường: ngày trong tuần, nội dung, mức độ ưu tiên
 *
 *
 */

function PromotionForm({ open, onClose, data, setData, onSubmit }) {
	const theme = useTheme();
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors, isSubmitting },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			hieu_luc_tu: '',
			hieu_luc_den: '',
			time_from: '',
			time_to: '',
			khach_hang: null,
			nhom_khach_hang: null,
			kho: null,
			vat_tu: null,
			nhom_vat_tu: null,
			ap_dung_gio: true,
			status: true,
		},
	});
	const [detailsKm, setDetailsKm] = React.useState(data?.details_km || []);
	const [isEdit, setIsEdit] = React.useState(!!data || false);

	// handle delete detail
	const handleDeleteDetail = (index) => {
		setDetailsKm((prev) => {
			const prevClone = cloneDeep(prev);
			prevClone.splice(index, 1);
			return prevClone;
		});
	};
	// create data post
	const createDataPost = (values) => {
		// nếu detail có giá trị thì mới lưu
		const detailsData = detailsKm.filter((detail) => detail.ma_vt);
		let timeFrom = '',
			timeTo = '';
		if (values.time_from) {
			timeFrom = moment();
			const time = moment(values.time_from, 'HH:mm');
			timeFrom.hours(time.hours());
			timeFrom.minutes(time.minutes());
		}
		if (values.time_to) {
			timeTo = moment();
			const time = moment(values.time_to, 'HH:mm');
			timeTo.hours(time.hours());
			timeTo.minutes(time.minutes());
		}

		const dataPost = {
			...data,
			ma_vt: values?.vat_tu?.ma_vt,
			ten_vt: values?.vat_tu?.ten_vt,
			ma_nvt: values?.nhom_vat_tu?._id,
			ten_nvt: values?.nhom_vat_tu?.ten_nvt,
			ma_kh: values?.khach_hang?.ma_kh,
			ten_kh: values?.khach_hang?.ten_kh,
			nh_kh: values?.nhom_khach_hang?.group_name,
			ma_kho: values?.kho?.ma_kho,
			ten_kho: values?.kho?.ten_kho,
			hieu_luc_tu: values.hieu_luc_tu,
			hieu_luc_den: values.hieu_luc_den,
			time_from: timeFrom,
			time_to: timeTo,
			status: values.status,
		};
		dataPost.details_km = detailsData;
		return dataPost;
	};
	// save
	const handleSave = async (values) => {
		const dataPost = createDataPost(values);
		return onSubmit(dataPost).then(onClose);
	};
	// save and add new
	const handleSaveAndAddNew = (values) => {
		const dataPost = createDataPost(values);
		return onSubmit(dataPost).then(() => {
			setIsEdit(false);
			setData({});
		});
	};
	// handle save copy
	const handleSaveCopy = (values) => {
		const dataPost = createDataPost(values);
		return onSubmit(dataPost);
	};
	React.useEffect(() => {
		setDetailsKm(data?.details_km || []);
		if (open) {
			if (!data || isEmpty(data)) {
				setIsEdit(false);
				reset({
					hieu_luc_tu: '',
					hieu_luc_den: '',
					time_from: '',
					time_to: '',
					khach_hang: null,
					nhom_khach_hang: null,
					kho: null,
					vat_tu: null,
					nhom_vat_tu: null,
					ap_dung_gio: true,
					status: false,
				});
			} else {
				setIsEdit(true);
				reset({
					hieu_luc_tu: moment(data.hieu_luc_tu).format('YYYY-MM-DD'),
					hieu_luc_den: moment(data.hieu_luc_den).format('YYYY-MM-DD'),
					time_from: moment(data.time_from).format('HH:mm'),
					time_to: moment(data.time_to).format('HH:mm'),
					khach_hang: data.ma_kh ? { ma_kh: data.ma_kh, ten_kh: data.ten_kh } : null,
					nhom_khach_hang: data.nh_kh ? { group_name: data.nh_kh } : null,
					kho: data.ma_kho ? { ma_kho: data.ma_kho, ten_kho: data.ten_kho } : null,
					vat_tu: data.ma_vt ? { ma_vt: data.ma_vt, ten_vt: data.ten_vt } : null,
					nhom_vat_tu: data.ma_nvt ? { ma_nvt: data.ma_nvt, ten_nvt: data.ten_nvt } : null,
					ap_dung_gio: data.ap_dung_gio,
					status: data.status,
				});
			}
		}
	}, [data, open]);

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={isEdit ? 'Chỉnh sửa khuyến mãi' : 'Thêm khuyến mãi'}
			width="810px"
			loading={isSubmitting}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(handleSave)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveAndAddNew)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveCopy)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					<CancelRounded />
					Hủy
				</button>,
			]}
		>
			<PromotionFormStyled>
				<Grid container alignContent="flex-start" spacing={2}>
					<Grid item xs={12} md={6}>
						<FieldInput
							inputProps={{ ...register('hieu_luc_tu') }}
							title="Từ ngày"
							type="date"
							defaultValue={data?.hieu_luc_tu}
							errorMsg={errors?.hieu_luc_tu?.message}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							inputProps={{ ...register('hieu_luc_den') }}
							title="Đến ngày"
							type="date"
							defaultValue={data?.hieu_luc_den}
							errorMsg={errors?.hieu_luc_den?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<FieldInput title="Từ giờ" type="time" inputProps={{ ...register('time_from') }} />
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput title="Đến giờ" type="time" inputProps={{ ...register('time_to') }} />
					</Grid>
					<Grid item xs={12}>
						<SelectBase
							isMulti
							title="Ngày trong tuần"
							placeholder="Ngày trong tuần"
							fieldBoxProps={{ colWidthTitle: 2 }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="vat_tu"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmvt"
									title="Sản phẩm"
									placeholder="Sản phẩm"
									value={value ? { value: value.ma_vt, label: value.ten_vt } : null}
									onChange={onChange}
									valueField="ma_vt"
									labelField="ten_vt"
									errorMsg={errors?.vat_tu?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="nhom_vat_tu"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmnvt"
									title="Nhóm sản phẩm"
									placeholder="Nhóm sản phẩm"
									value={value ? { value: value.ma_nvt, label: value.ten_nvt } : null}
									onChange={onChange}
									valueField="ma_nvt"
									labelField="ten_nvt"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FieldInput title="Nội dung " placeholder="Nội dung" fieldBoxProps={{ colWidthTitle: 2 }} />
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="khach_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="customer"
									title="Khách hàng"
									placeholder="Khách hàng"
									value={value ? { value: value.ma_kh, label: value.ten_kh } : null}
									onChange={onChange}
									valueField="ma_kh"
									labelField="ten_kh"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="nhom_khach_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="group"
									q={{ group_type: 'CUSTOMER' }}
									title="Nhóm khách"
									placeholder="Nhóm khách"
									value={value ? { value: value.group_name, label: value.group_name } : null}
									onChange={onChange}
									valueField="group_name"
									labelField="group_name"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="kho"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmkho"
									title="Kho/Chi nhánh"
									placeholder="Kho/Chi nhánh"
									value={value ? { value: value.ma_kho, label: value.ten_kho } : null}
									onChange={onChange}
									valueField="ma_kho"
									labelField="ten_kho"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput title="Mức độ ưu tiên" placeholder="Độ ưu tiên" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Kênh áp dụng" placeholder="Kênh áp dụng" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Nhóm chương trình" placeholder="Nhóm chương trình" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Hạng khách hàng" placeholder="Hạng khách hàng" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<CheckboxWrapper>
							<input
								style={{ accentColor: theme.palette.primary.main }}
								type="checkbox"
								id="using"
								{...register('status')}
							/>
							<label htmlFor="using">Còn sử dụng</label>
						</CheckboxWrapper>
					</Grid>

					<Grid item xs={12} style={{ paddingTop: 20 }}>
						<Typography>Sản phẩm khuyến mãi</Typography>
						<Grid container spacing={1} style={{ paddingBottom: 20 }}>
							{detailsKm.length > 0 &&
								detailsKm.map((detail, index) => (
									<React.Fragment key={v4()}>
										<Grid xs={1} style={{ display: 'flex', alignItems: 'center' }}>
											<ButtonBase
												onClick={() => handleDeleteDetail(index)}
												startIcon={<BsTrash fontSize="14px" />}
												variant="outline"
											/>
										</Grid>
										<Grid item xs={5}>
											<FieldSelectApi
												api="dmvt"
												title="Sản phẩm"
												placeholder="Sản phẩm"
												defaultValue={{
													value: detail.ma_vt,
													label: detail.ten_vt,
												}}
												onChange={(item) => {
													setDetailsKm((prev) => {
														const prevClone = cloneDeep(prev);
														prevClone[index].ten_vt = item?.ten_vt || '';
														prevClone[index].ma_vt = item?.ma_vt || '';
														prevClone[index].ma_dvt = 'dvt';
														prevClone[index].sl_km = 1;
														return prevClone;
													});
												}}
												valueField="ma_vt"
												labelField="ten_vt"
												fieldBoxProps={{ colWidthTitle: 3 }}
											/>
										</Grid>
										<Grid item xs={4}>
											<FieldSelectApi
												api="dmdvt"
												title="Đơn vị tính"
												placeholder="Đơn vị tính"
												defaultValue={
													detail.ma_dvt
														? {
																value: detail.ma_dvt,
																label: detail.ten_dvt,
														  }
														: null
												}
												onChange={(item) => {
													setDetailsKm((prev) => {
														const prevClone = cloneDeep(prev);
														prevClone[index].ma_dvt = item.ma_dvt;
														prevClone[index].ten_dvt = item.ten_dvt;
														return prevClone;
													});
												}}
												valueField="ma_dvt"
												labelField="ten_dvt"
												fieldBoxProps={{ colWidthTitle: 4 }}
											/>
										</Grid>
										<Grid item xs={2}>
											<FieldInput
												value={detail.sl_km}
												onChange={(e) => {
													setDetailsKm((prev) => {
														const prevClone = cloneDeep(prev);
														prevClone[index].sl_km = Number(e.target.value);
														return prevClone;
													});
												}}
												type="number"
												inputProps={{ min: 1 }}
												title="Số lượng"
												placeholder="Số lượng"
												fieldBoxProps={{ colWidthTitle: 8 }}
											/>
										</Grid>
									</React.Fragment>
								))}
						</Grid>
						<ButtonBase
							onClick={() => setDetailsKm([...detailsKm, { ma_vt: '', ten_vt: '', sl_km: 1 }])}
							startIcon={<BsPlus fontSize="14px" />}
						>
							Thêm sản phẩm
						</ButtonBase>
					</Grid>
				</Grid>
			</PromotionFormStyled>
		</Dialog>
	);
}

export default PromotionForm;
