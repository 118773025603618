import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTheme } from '@material-ui/core/styles';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';
import * as yup from 'yup';
import Dialog from '../../../Dialog/Dialog';
import ButtonBase from '../../components/button/ButtonBase';
import { SaleContext } from '../../SaleContainer';

const AddCustomerWrapper = styled(Box)`
	height: 100%;
	margin-top: 20px;
`;
const TitleWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;
const Title = styled(Typography)`
	font-size: 14px;
	font-weight: 550;
	padding-right: 5px;
`;
const AgencyText = styled(Typography)`
	font-size: 13px;
	opacity: 0.8;
	padding: 0 5px;
	border-left: 1px solid #ccc;
`;
const Column = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;
const AvatarWrapper = styled(Box)`
	width: 90%;
	padding-top: 90%;
	position: relative;
	@media screen and (max-width: 960px) {
		width: 40%;
		padding-top: 40%;
	}
`;
const AvatarStyled = styled(Avatar)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
`;

const FormRow = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const LabelForm = styled.label`
	position: relative;
	font-size: 13px;
	font-weight: 550;
	&::before {
		content: '*';
		display: ${(props) => (props.required ? 'block' : 'none')};
		font-size: 10px;
		position: absolute;
		top: 0;
		left: calc(100% + 5px);
		color: red;
	}
`;
const FormInputWrapper = styled(Box)`
	width: 60%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media screen and (max-width: 960px) {
		width: 70%;
	}
`;
const FormInput = styled.input`
	width: 100%;
	height: 34px;
	padding: 0 10px;
	border-radius: 6px;
	border: none;
	outline: none;
	border: 1px solid #ccc;
	font-size: 13px;
	&:focus {
		border-color: ${(props) => props.borderColor};
	}
`;
const RadioWrapper = styled(Box)`
	width: 60%;
	display: flex;
	align-items: center;
	gap: 20px;
	@media screen and (max-width: 960px) {
		width: 70%;
	}
`;
const LabelControl = styled.label`
	display: inline-flex;
	align-items: center;
	gap: 10px;
`;
const RadioStyled = styled.input`
	&:checked {
		background-color: ${(props) => props.color};
	}
`;
const FormAction = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
`;

const schema = yup.object({
	ten_kh: yup.string().required('Vui lòng nhập tên khách hàng'),
	dien_thoai: yup
		.string()
		.required('Vui lòng nhập số điện thoại')
		.matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, 'Số điện thoại không hợp lệ'),
});

function AddCustomerPopup({ open, handleClose, valueName, handleSelectCustomer }) {
	const context = React.useContext(DefaultContext);
	const { kho } = React.useContext(SaleContext);
	const theme = useTheme();
	const fileRef = React.useRef();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: { ten_kh: valueName },
	});

	const [avatar, setAvatar] = React.useState();
	const [avatarPreview, setAvatarPreview] = React.useState(null);

	// on submit
	const onSubmit = async (values) => {
		try {
			const resp = await context.apis.asyncPostList(context.userInfo.token, 'customer', values);
			await handleSelectCustomer(resp);
			handleClose();
		} catch (error) {
			context.alert(error);
		}
	};
	// handle avatar change
	const handleAvatarChange = (e) => {
		const image = e.target.files[0];
		const urlPreview = URL.createObjectURL(image);
		setAvatarPreview(urlPreview);
		setAvatar(image);
	};
	React.useEffect(() => {
		return () => {
			URL.revokeObjectURL(avatarPreview);
		};
	}, [avatarPreview]);
	console.log('kho => ', kho);

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			width="900px"
			title={
				<TitleWrapper>
					<Title>Thêm khách hàng</Title>
					<AgencyText>Chi nhánh tạo: {kho?.ten_kho}</AgencyText>
				</TitleWrapper>
			}
		>
			<AddCustomerWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={4}>
					<Grid item md={2} xs={12}>
						<Column>
							<AvatarWrapper>
								<AvatarStyled src={avatarPreview} />
							</AvatarWrapper>
							<ButtonBase variant="outline" onClick={() => fileRef.current.click()}>
								Chọn ảnh
							</ButtonBase>
							<input onChange={handleAvatarChange} hidden ref={fileRef} type="file" accept="image/*" />
						</Column>
					</Grid>
					<Grid item md={5} xs={12}>
						<Column>
							<FormRow>
								<LabelForm>Mã khách hàng</LabelForm>
								<FormInputWrapper>
									<FormInput placeholder="Mã mặc định" borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm required>Tên khách hàng</LabelForm>
								<FormInputWrapper>
									<FormInput {...register('ten_kh')} borderColor={theme.palette.primary.main} />
									{errors?.ten_kh && <FormHelperText error>{errors.ten_kh.message}</FormHelperText>}
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm required>Điện thoại</LabelForm>
								<FormInputWrapper>
									<FormInput {...register('dien_thoai')} borderColor={theme.palette.primary.main} />
									{errors?.dien_thoai && (
										<FormHelperText error>{errors.dien_thoai.message}</FormHelperText>
									)}
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Địa chỉ</LabelForm>
								<FormInputWrapper>
									<FormInput borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Khu vực</LabelForm>
								<FormInputWrapper>
									<Select
										styles={{
											container: (baseStyles) => ({
												...baseStyles,
												width: '100%',
											}),
										}}
										placeholder="Chọn khu vực"
									/>
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Phường xã</LabelForm>
								<FormInputWrapper>
									<Select
										styles={{
											container: (baseStyles) => ({
												...baseStyles,
												width: '100%',
											}),
										}}
										placeholder="Chọn phường xã"
									/>
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Ngày sinh</LabelForm>
								<FormInputWrapper>
									<FormInput type="date" borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
						</Column>
					</Grid>
					<Grid item md={5} xs={12}>
						<Column>
							<FormRow>
								<LabelForm>Giới tính</LabelForm>
								<RadioWrapper>
									<LabelControl>
										<RadioStyled type="radio" name="gender" value={1} />
										Nam
									</LabelControl>
									<LabelControl>
										<RadioStyled type="radio" name="gender" value={0} />
										Nữ
									</LabelControl>
								</RadioWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Loại khách</LabelForm>
								<RadioWrapper>
									<LabelControl>
										<RadioStyled type="radio" name="customer" value={1} />
										Cá nhân
									</LabelControl>
									<LabelControl>
										<RadioStyled type="radio" name="customer" value={0} />
										Công ty
									</LabelControl>
								</RadioWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Mã số thuế</LabelForm>
								<FormInputWrapper>
									<FormInput borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Email</LabelForm>
								<FormInputWrapper>
									<FormInput borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Nhóm</LabelForm>
								<FormInputWrapper>
									<FormInput borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
							<FormRow>
								<LabelForm>Ghi chú</LabelForm>
								<FormInputWrapper>
									<FormInput borderColor={theme.palette.primary.main} />
								</FormInputWrapper>
							</FormRow>
						</Column>
					</Grid>
				</Grid>
				<FormAction>
					<ButtonBase variant="outline" onClick={handleClose}>
						Bỏ qua
					</ButtonBase>
					<ButtonBase type="submit" variant="contained">
						Lưu
					</ButtonBase>
				</FormAction>
			</AddCustomerWrapper>
		</Dialog>
	);
}

export default AddCustomerPopup;
