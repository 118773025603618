import React, { useContext } from "react";
import { BASE_URL } from "../../../../../utils/constants";
import DefaultContex from "flexbiz-core/components/auth/Context";
import { formatCurrencyDisplay } from "../../../../../utils/helpers";

function SpaCardService({ data, handleSelectProduct }) {
	const context = useContext(DefaultContex);
	return (
		<div
			xs={5}
			className="item_spa"
			onClick={() => handleSelectProduct(data)}
		>
			<img
				className="box_image"
				src={
					data.picture
						? `${BASE_URL}${data.picture}?access_token=${context.userInfo.token}`
						: "https://salon.kiotviet.vn/theanhspa/pos/assets/img/service-default-image.svg"
				}
				alt=""
			/>
			<div className="item_spa_box_info">
				<div className="item_spa_name">{data.ten_vt}</div>
				<div className="item_spa_price">
					{formatCurrencyDisplay(data.gia_ban_le)}
				</div>
			</div>
		</div>
	);
}

export default SpaCardService;
