import React from "react";
import styled from "styled-components";
import { Box, Typography, Tabs, Tab, Avatar } from "@material-ui/core";
import DrawerBase from "../../../drawer/DrawerBase";
import { BiMinus, BiPlus } from "react-icons/bi";
import { formatCurrencyDisplay } from "../../../../../../utils/helpers";
import { BASE_URL } from "../../../../../../utils/constants";
import DefaultContext from "flexbiz-core/components/auth/Context";
import { SaleContext } from "../../SaleContainer";

const DetailWrapper = styled(Box)`
  width: 40vw;
  height: 100%;
  border-radius: 10px 0 0 10px;
  padding: 20px;
`;
const DetailName = styled(Typography)`
  font-size: 14px;
  font-weight: 550;
`;
const TabButton = styled(Tab)`
  width: fit-content;
  min-width: 50px;
  font-size: 13px;
  text-transform: none;
  padding: 0 10px;
`;
const InfoTab = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`;
const InfoImageWrapper = styled.div`
  width: 30%;
`;
const InfoImage = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: #ededed;
  border-radius: 6px;
  overflow: hidden;
`;
const Image = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0;
`;
const InfoContentWrapper = styled.div`
  width: 70%;
  height: 300px;
`;
const InfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
`;
const InfoLabel = styled(Typography)`
  font-size: 13px;
  font-weight: 550;
`;
const InfoValue = styled(Typography)`
  font-size: 13px;
  margin-left: 20px;
`;
const InfoNumberWrapper = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
`;
const InputNumber = styled.input`
  width: 40px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #ededed;
  outline: none;
  font-size: 13px;
`;
const ButtonNumber = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.1s;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
`;

function DrawerDetailProduct({
  open,
  handleClose,
  product,
  add,
  number,
  handleNumberChange,
  handleOrderChanged,
}) {
  const context = React.useContext(DefaultContext);
  const { saleMode } = React.useContext(SaleContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DrawerBase
      anchor={saleMode === 1 ? "left" : "right"}
      open={open}
      handleClose={handleClose}
    >
      <DetailWrapper>
        <DetailName>
          {product?.ten_vt} - ({product?.ma_dvt})
        </DetailName>
        <Tabs
          style={{ marginTop: "10px" }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <TabButton label="Thông tin chung" />
          <TabButton label="Mô tả chi tiết" />
          <TabButton label="Tồn kho" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <InfoTab>
            <InfoImageWrapper>
              <InfoImage>
                <Image
                  src={`${BASE_URL}${product.picture}?access_token=${context.userInfo.token}`}
                />
              </InfoImage>
            </InfoImageWrapper>
            <InfoContentWrapper>
              <InfoRow>
                <InfoLabel>Giá bán</InfoLabel>
                <InfoValue>
                  {formatCurrencyDisplay(product?.gia_ban_le_goc)}
                </InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Số lượng</InfoLabel>
                <InfoNumberWrapper>
                  {product?.sl_order > 1 && (
                    <ButtonNumber onClick={() => add(product, -1)}>
                      <BiMinus fontSize="16px" />
                    </ButtonNumber>
                  )}
                  <InputNumber
                    value={number}
                    onChange={(e) => handleNumberChange(e)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleOrderChanged(product);
                      }
                    }}
                    onBlur={() => handleOrderChanged(product)}
                  />
                  <ButtonNumber onClick={() => add(product, 1)}>
                    <BiPlus fontSize="16px" />
                  </ButtonNumber>
                </InfoNumberWrapper>
                <InfoValue>Tồn: {product?.ton_kho}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Thương hiệu</InfoLabel>
                <InfoValue></InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Vị trí</InfoLabel>
                <InfoValue></InfoValue>
              </InfoRow>
            </InfoContentWrapper>
          </InfoTab>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {product?.mieu_ta_chi_tiet || "Không có dữ liệu"}
        </TabPanel>
        <TabPanel value={value} index={2}>
          Không có dữ liệu
        </TabPanel>
      </DetailWrapper>
    </DrawerBase>
  );
}

export default DrawerDetailProduct;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        height: "calc(100vh - 48px - 10px - 21px - 40px)",
        overflow: "auto",
        paddingTop: "10px",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
