import React from "react";
import { Col, Input, Row } from "antd";
import { IoIosAddCircle } from "react-icons/io";
import { NavLink } from "react-router-dom";
import "./Member.css";
import { FiChevronRight } from "react-icons/fi";
import { FaMedal } from "react-icons/fa";
function Right(props) {
  return (
    <Col className="gutter-row right" span={8}>
      <div>
        <h5>Phân loại khách hàng theo điểm tích lũy</h5>
        <Row className="exchange-right">
          <Col className="gutter-row" span={10}>
            <p>
              <i className="fal fa-medal"></i>
              Hạng Thường
            </p>
          </Col>
          <Col className="icon">
            <p>
              <i className="fal fa-chevron-right"></i>
            </p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input type="text" addonAfter={<p>điểm</p>} defaultValue="0" />
          </Col>
        </Row>
        <Row className="exchange-right">
          <Col className="gutter-row" span={10}>
            <p>
              <i className="fal fa-trophy"></i>
              Hạng Bạc
            </p>
          </Col>
          <Col className="icon">
            <p>
              <i className="fal fa-chevron-right"></i>
            </p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input type="text" addonAfter={<p>điểm</p>} defaultValue="5.00" />
          </Col>
        </Row>
        <Row className="exchange-right">
          <Col className="gutter-row" span={10}>
            <p>
              <i className="fal fa-trophy-alt"></i>
              Hạng Vàng
            </p>
          </Col>
          <Col className="icon">
            <p>
              <i className="fal fa-chevron-right"></i>
            </p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input type="text" addonAfter={<p>điểm</p>} defaultValue="1.000" />
          </Col>
        </Row>
        <Row className="exchange-right">
          <Col className="gutter-row" span={10}>
            <p>
              <i className="fal fa-diamond"></i>
              Hạng Bạch Kim
            </p>
          </Col>
          <Col className="icon">
            <p>
              <i className="fal fa-chevron-right"></i>
            </p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input type="text" addonAfter={<p>điểm</p>} defaultValue="1.500" />
          </Col>
        </Row>
        <Row className="exchange-right">
          <Col className="gutter-row" span={10}>
            <p>
              <i className="fal fa-gem"></i>
              Hạng Kim Cương
            </p>
          </Col>
          <Col className="icon">
            <p>
              <i className="fal fa-chevron-right"></i>
            </p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input type="text" addonAfter={<p>điểm</p>} defaultValue="2.000" />
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export default Right;
