import React from 'react';
import TabDetail from './TabItems/TabDetail';
import TabContainer from '../../components/tab/TabContainer';
import TabVatVao from './TabItems/TabVatVao';
import TabVatRa from './TabItems/TabVatRa';

function DetailVoucherPKT({
	data,
	readonly,
	view,
	selectRow,
	handleGetDataDetail,
	handleGetDataVatVao,
	handleGetDataVatRa,
	isFieldNt,
}) {
	return (
		<div className="dialog__content_edit_voucher__body box_detail_voucher_detail">
			{(!view || data) && (
				<TabContainer
					tabs={[
						{
							label: 'Chi tiết',
							component: (
								<TabDetail
									data={data}
									readonly={readonly}
									handleGetDataDetail={handleGetDataDetail}
									view={view}
									selectRow={selectRow}
									isFieldNt={isFieldNt}
								/>
							),
						},
						{
							label: 'Thuế vào',
							component: (
								<TabVatVao
									data={data}
									readonly={readonly}
									handleGetDataVatVao={handleGetDataVatVao}
									view={view}
									selectRow={selectRow}
									isFieldNt={isFieldNt}
								/>
							),
						},
						{
							label: 'Thuế ra',
							component: (
								<TabVatRa
									data={data}
									readonly={readonly}
									handleGetDataVatRa={handleGetDataVatRa}
									view={view}
									selectRow={selectRow}
									isFieldNt={isFieldNt}
								/>
							),
						},
					]}
				/>
			)}
		</div>
	);
}

export default DetailVoucherPKT;
