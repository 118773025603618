/* eslint-disable react/jsx-key */
import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { BsCaretDown, BsCaretUp } from 'react-icons/bs';
import {
	useBlockLayout,
	useExpanded,
	useFilters,
	useGroupBy,
	usePagination,
	useResizeColumns,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table';
import { Styles } from './Styles';
import DefaultFilter from './components/DefaultFilter';
import EditableCell from './components/EditableCell';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Popover } from 'antd';
import ViewSoCai from '../../VoucherManager/VoucherPN1/Dialog/ViewSoCai';
import ViewSoKho from '../../VoucherManager/VoucherPN1/Dialog/ViewSoKho';

function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
}

filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			resolvedRef.current.click();
		}
	};

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<div className="checkmark">
			<input type="checkbox" ref={resolvedRef} {...rest} onKeyDown={handleKeyDown} className="checkbox" />
		</div>
	);
});

const SettingRow = forwardRef(({ data, ...rest }, ref) => {
	const defaulSettingtRef = useRef();
	const resolvedSettingRef = ref || defaulSettingtRef;
	const [isOpenSoKho, setIsOpenSoKho] = useState(false);
	const [isOpenSoCai, setIsOpenSoCai] = useState(false);

	const handleCloseViewSoKho = () => {
		setIsOpenSoKho(false);
	};
	const handleOpenViewSoKho = () => {
		setIsOpenSoKho(true);
	};

	const handleCloseViewSoCai = () => {
		setIsOpenSoCai(false);
	};
	const handleOpenViewSoCai = () => {
		setIsOpenSoCai(true);
	};

	const text = <span>Xem thông tin</span>;
	const content = (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<button
				onClick={() => {
					handleOpenViewSoKho();
				}}
				className="btn btn-transparent"
			>
				Xem sổ kho
			</button>
			<button
				onClick={() => {
					handleOpenViewSoCai();
				}}
				className="btn btn-transparent"
			>
				Xem sổ cái
			</button>
		</div>
	);

	return (
		<React.Fragment>
			<Popover placement="right" title={text} content={content} trigger="click">
				<AiOutlineExclamationCircle
					ref={resolvedSettingRef}
					{...rest}
					// onClick={handleSettingRow}
				/>
			</Popover>
			<ViewSoCai open={isOpenSoCai} dataVoucher={data} handleClose={handleCloseViewSoCai} />
			<ViewSoKho open={isOpenSoKho} dataVoucher={data} handleClose={handleCloseViewSoKho} />
		</React.Fragment>
	);
});

function TableManager({
	idTable,
	columns,
	showTHead = true,
	data,
	updateMyData,
	skipReset,
	onRowClick,
	selectRow,
	settingRow,
	numberColumn,
	onSelectRowClick,
	TbodyMaxHeight,
	TbodyMinHeight,
	handleOpenAddDetailItemVoucher,
	onRowDoubleClick,

	//edit table
	readonly,

	//Pagination
	pagination,
	isPage,
	isLimit,
	totalRows,
	handlePaginationChange,
	//tooltable
	toolBar,
	headTotal,
	hiddenColumns,
}) {
	// const tableRef = useRef(null);
	//Pagination
	const [page, setPage] = useState(isPage || 1);
	const [pageSize, setPageSize] = useState(isLimit || 20);

	useEffect(() => {
		setPage(isPage);
		setPageSize(isLimit);
	}, [isPage, isLimit]);

	const handlePageChange = (newPage) => {
		setPage(newPage);
		handlePaginationChange(newPage, pageSize);
	};

	const handlePageSizeChange = (newPage, newPageSize) => {
		setPageSize(newPageSize);
		setPage(newPage);
		handlePaginationChange(newPage, newPageSize);
	};

	//Filter
	const filterTypes = React.useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			Filter: DefaultFilter,
			Cell: EditableCell,
		}),
		[]
	);

	const initialState = { hiddenColumns: hiddenColumns ? hiddenColumns : [] };

	const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = useTable(
		{
			idTable,
			columns,
			data,
			defaultColumn,
			filterTypes,
			updateMyData,
			readonly,
			handleOpenAddDetailItemVoucher,

			autoResetPage: !skipReset,
			autoResetSelectedRows: !skipReset,
			disableMultiSort: true,
			initialState,
		},
		useFilters,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination,
		useResizeColumns,
		useBlockLayout,
		useRowSelect,
		(hooks) => {
			settingRow &&
				hooks.visibleColumns.push((columns) => {
					return [
						{
							id: 'setting',
							width: 35,
							groupByBoundary: true,
							Cell: ({ row }) => (
								<div className={'box_input_checkbox_cell'}>
									<SettingRow {...row.getToggleRowSelectedProps()} data={row.original} />
								</div>
							),
							isResizing: false,
						},
						...columns,
					];
				});
			numberColumn &&
				hooks.visibleColumns.push((columns) => {
					return [
						{
							id: 'numberColumn',
							width: 45,
							groupByBoundary: true,
							Header: () => <div>STT</div>,
							Cell: ({ row }) => (
								<div className={`${numberColumn ? 'box_input_checkbox_cell' : ''}`}>
									{row.index + 1}
								</div>
							),
							isResizing: false,
						},
						...columns,
					];
				});
			selectRow &&
				hooks.visibleColumns.push((columns) => {
					return [
						{
							id: 'selection',
							width: 35,
							groupByBoundary: true,
							Header: ({ getToggleAllRowsSelectedProps }) => (
								<div className={`${selectRow ? 'box_input_checkbox_header' : ''}`}>
									<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
								</div>
							),
							Cell: ({ row }) => (
								<div className={`${selectRow ? 'box_input_checkbox_cell' : ''}`}>
									<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
								</div>
							),
							isResizing: false,
						},
						...columns,
					];
				});
		}
	);
	const [isSelectedRows, setIsSelectedRows] = useState();
	const selectedRows = selectedFlatRows.map((row) => row.original);
	if (JSON.stringify(selectedRows) !== JSON.stringify(isSelectedRows)) {
		setIsSelectedRows(selectedRows);
	}
	useEffect(() => {
		if (isSelectedRows.length >= 0) {
			onSelectRowClick && onSelectRowClick(isSelectedRows);
			// console.log("selectedRows", isSelectedRows);
		}
	}, [isSelectedRows]);

	let cellCount = 0;
	return (
		<Styles>
			<div className={`${pagination && pagination && 'header_tool_table'}`}>
				<div className="header_tool_table_left">{toolBar && toolBar}</div>
				{pagination && (
					<div className="header_pagination">
						<div className="pagination">
							<button
								onClick={() => {
									handlePageChange(1);
								}}
								disabled={page === 1}
								className={`btn_pagination ${page === 1 ? 'btn_pagination_disabled' : ''}`}
							>
								{'<<'}
							</button>{' '}
							<button
								onClick={() => {
									handlePageChange(page - 1);
								}}
								disabled={page === 1}
								className={`btn_pagination ${page === 1 ? 'btn_pagination_disabled' : ''}`}
							>
								{'<'}
							</button>{' '}
							<span>
								<input
									className="input_pagination"
									type="number"
									min={1}
									max={Math.ceil(totalRows / pageSize)}
									value={page}
									onChange={(e) => {
										const newPage = parseInt(e.target.value);
										handlePageChange(newPage > 0 ? newPage : 1);
									}}
								/>{' '}
								of {Math.ceil(totalRows / pageSize) || 1}
							</span>{' '}
							<button
								onClick={() => {
									handlePageChange(page + 1);
								}}
								disabled={page >= Math.ceil(totalRows / pageSize)}
								className={`btn_pagination ${
									page >= Math.ceil(totalRows / pageSize) ? 'btn_pagination_disabled' : ''
								}`}
							>
								{'>'}
							</button>{' '}
							<button
								onClick={() => {
									handlePageChange(Math.ceil(totalRows / pageSize));
								}}
								disabled={page >= Math.ceil(totalRows / pageSize)}
								className={`btn_pagination ${
									page >= Math.ceil(totalRows / pageSize) ? 'btn_pagination_disabled' : ''
								}`}
							>
								{'>>'}
							</button>{' '}
							<select
								value={pageSize}
								onChange={(e) => {
									handlePageSizeChange(1, Number(e.target.value));
								}}
							>
								{[10, 20, 30, 40, 50].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
			</div>
			<div className={`box_table_manager ${showTHead ? '' : 'flex_right'}`}>
				<table {...getTableProps()} id={idTable || 'table'}>
					<thead className={`${showTHead ? '' : 'hiddenTHead'}`}>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										<div>
											<div>
												{column.canGroupBy ? (
													<span {...column.getGroupByToggleProps()}></span>
												) : null}
												<span {...column.getSortByToggleProps()} className="sort_header_arrow">
													{column.render('Header')}
													{column.isSorted ? (
														column.isSortedDesc ? (
															<BsCaretDown />
														) : (
															<BsCaretUp />
														)
													) : (
														''
													)}
												</span>
											</div>
											{column.canFilter && (
												<div
													style={{
														minHeight: '21px',
													}}
													className={`${
														column.render('Filter') ? 'filter_header_input' : ''
													}`}
												>
													{column.canFilter ? column.render('Filter') : ''}
												</div>
											)}
										</div>
										<div
											{...column.getResizerProps()}
											className={`${column.render('isResizing') ? 'resizer isResizing' : ''}`}
										/>
									</th>
								))}
							</tr>
						))}
					</thead>
					{headTotal && <div className="head_total">{headTotal}</div>}
					<tbody
						{...getTableBodyProps()}
						style={{
							maxHeight: TbodyMaxHeight ? `${TbodyMaxHeight}` : '',
							minHeight: TbodyMinHeight ? `${TbodyMinHeight}` : '',
						}}
					>
						{rows.map((row) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									onClick={() => {
										onRowClick && onRowClick(row.original);
										// console.log('row.original', row.original);
									}}
									onDoubleClick={() => {
										onRowDoubleClick && onRowDoubleClick(row.original);
									}}
								>
									{row.cells.map((cell) => {
										cellCount++;
										return (
											<td {...cell.getCellProps()}>
												{cell.isGrouped ? (
													<>
														<span
															tabIndex={cellCount}
															{...row.getToggleRowExpandedProps()}
														></span>{' '}
														{cell.render('Cell', {
															editable: false,
														})}{' '}
														({row.subRows.length})
													</>
												) : cell.isAggregated ? (
													cell.render('Aggregated')
												) : cell.isPlaceholder ? null : (
													cell.render('Cell', {
														editable: true,
														tabIndex: readonly ? '' : cellCount,
													})
												)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Styles>
	);
}

export default TableManager;
