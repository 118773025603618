import React from 'react';
import FilterSearch from '../components/filter/FilterSearch';

const searchFields = ['ma_dvt', 'ten_dvt'];
export default function UnitFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		search: '',
		type: 'all',
	});

	React.useEffect(() => {
		const q = { $and: [] };

		if (filter.search)
			q.$and.push({ $or: searchFields.map((field) => ({ [field]: { $regex: filter.search, $options: 'i' } })) });

		onChange(q);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const onValueChange = React.useCallback((field) => (value) => setFilter({ ...filter, [field]: value }), [filter]);

	return (
		<div className='product__filter'>
			{/* Search box */}
			<FilterSearch onSearch={onValueChange('search')} />
		</div>
	);
}
