import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { GrAddCircle } from 'react-icons/gr';
import '../components/filter/Filter.css';
import FilterBox from '../components/filter/FilterBox';
import FilterListRatio from '../components/filter/FilterListRatio';
import FilterSearch from '../components/filter/FilterSearch';
import FilterSelect from '../components/filter/FilterSelect';

const fakeData = {
    type: [
        {
            value: '1',
            label: 'Loại 1',
        },
        {
            value: '2',
            label: 'Loại 2',
        },
        {
            value: '3',
            label: 'Loại 3',
        },
    ],
    group: [
        {
            value: '1',
            label: 'Nhóm 1',
            sub: [
                {
                    value: '1-1',
                    label: 'Nhóm 1.1',
                },
                {
                    value: '1-2',
                    label: 'Nhóm 1.2',
                },
                {
                    value: '1-3',
                    label: 'Nhóm 1.3',
                    sub: [
                        {
                            value: '1-3-1',
                            label: 'Nhóm 1.3.1',
                        },
                        {
                            value: '1-3-2',
                            label: 'Nhóm 1.3.2',
                        },
                    ],
                },
            ],
        },
        {
            value: '2',
            label: 'Nhóm 2',
        },
        {
            value: '3',
            label: 'Nhóm 3',
        },
    ],
    inventory: [
        {
            label: 'Tất cả',
            value: 'all',
        },
        {
            label: 'Dưới định mức tồn',
            value: 'below',
        },
        {
            label: 'Vượt định mức tồn',
            value: 'over',
        },
        {
            label: 'Còn hàng',
            value: 'in-stock',
        },
        {
            label: 'Hết hàng',
            value: 'out-stock',
        },
    ],
    status: [
        {
            label: 'Tất cả',
            value: 'all',
        },
        {
            label: 'Đang kinh doanh',
            value: 'active',
        },
        {
            label: 'Ngừng kinh doanh',
            value: 'inactive',
        },
    ],
    row: [
        {
            label: '15',
            value: 15,
        },
        {
            label: '30',
            value: 30,
        },
        {
            label: '45',
            value: 45,
        },
        {
            label: '60',
            value: 60,
        },
    ],
};

export default function ShiftFilter({ onFilter }) {
    return (
        <div className="Shift__filter">
            {/* Search box */}
            <FilterSearch onSearch={console.log} />
            {/* End of Search box */}

            <FilterSelect
                title="Nhóm"
                actions={[<GrAddCircle />]}
                items={fakeData.row}
                onChange={console.log}
                placeholder="--- Tất cả ---"
                editable
                onEdit={console.log}
            />

            {/* Filter by inventory */}
            <FilterListRatio
                title="Tồn kho"
                items={fakeData.inventory}
                onChange={console.log}
                collapsable
            />
            {/* End of Filter by inventory */}

            {/* Row per page */}
            <FilterBox
                title="Số hàng hiển thị"
                className="product__filter__row"
            >
                <Select className="product__filter__row-select">
                    {fakeData.row.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FilterBox>
        </div>
    );
}
