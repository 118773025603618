import { Grid } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';

export default function AuthorizationTab({ hookForm }) {
	const {
		control,
		watch,
		formState: { errors },
	} = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Controller
					control={control}
					name='visible_to'
					render={({ field: { onChange, value } }) => (
						<FieldSelect
							title='Có giá trị với'
							placeholder='Chọn giá trị'
							items={[
								{
									label: 'Mọi người',
									value: 0,
								},
								{
									label: 'Chỉ người tạo',
									value: 1,
								},
								{
									label: 'Những người sau',
									value: 2,
								},
								{
									label: 'Nhóm người dùng',
									value: 5,
								},
							]}
							onChange={(item) => onChange(item.value)}
							defaultValue={value || 0}
							disableClearable
						/>
					)}
				/>

				{watch('visible_to') === 2 && (
					<Controller
						name='visible_to_users'
						control={control}
						rules={{
							validate: (value) => value?.length > 0 || 'Vui lòng chọn người được phép xem',
						}}
						render={({ field: { value, onChange } }) => {
							const items = value?.map((item) => ({ value: item, label: item, email: item })) || [];
							const handleChange = (items) => onChange(items?.map((item) => item.email) || []);

							return (
								<FieldSelectApi
									placeholder='Nhập người được phép xem'
									api='participant'
									multi
									valueField='email'
									labelField='email'
									onChange={handleChange}
									value={items}
									check
									errorMsg={errors?.visible_to_users?.message}
								/>
							);
						}}
					/>
				)}

				{watch('visible_to') === 5 && (
					<Controller
						name='visible_to_usergroups'
						control={control}
						rules={{
							validate: (value) => value?.length > 0 || 'Vui lòng chọn nhóm người được phép xem',
						}}
						render={({ field: { value, onChange } }) => (
							<FieldSelectApi
								placeholder='Nhập nhóm người được phép xem'
								api='usergroup'
								multi
								valueField='_id'
								labelField='group_name'
								onChange={onChange}
								value={value}
								check
								errorMsg={errors?.visible_to_groups?.message}
							/>
						)}
					/>
				)}
			</Grid>
		</Grid>
	);
}
