import React from "react";
import CustomDatePicker from "../datePicker/DatePicker";
import FilterBox from "./FilterBox";

const FilterSelectTimeFromTo = ({
  startDay,
  endDay,
  setStartDay,
  setEndDay,
}) => {
  return (
    <FilterBox title="Thời gian" collapsable>
      <div
        style={{
          marginTop: "14px",
        }}
      >
        <CustomDatePicker
          label={"Từ ngày"}
          value={startDay}
          onChange={setStartDay}
        ></CustomDatePicker>

        <div
          style={{
            marginBottom: "10px",
          }}
        ></div>
        <CustomDatePicker
          label={"Đến ngày"}
          value={endDay}
          onChange={setEndDay}
        ></CustomDatePicker>
      </div>
    </FilterBox>
  );
};

export default FilterSelectTimeFromTo;
