import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import AuthContext from 'flexbiz-core/components/auth/Context';
import withMediaQuery from 'flexbiz-core/components/withMediaQuery';
import RequireLogin from 'flexbiz-core/components/RequireLogin';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Typography from '@material-ui/core/Typography';
import { Menu } from 'antd';
import { Grid } from '@material-ui/core';
import Header from './Header';
import { AiFillHome } from 'react-icons/ai';
import { menu2, primaryColor } from '../../../config';
Checkin.contextType = AuthContext;
// Checkin.propTypes = {
//   match: PropTypes.any,
//   history: PropTypes.any,
//   location: PropTypes.any,
//   mediaQueryMatches: PropTypes.bool,
// };
function Checkin(props) {
	const [clicked, setClicked] = useState(JSON.parse(localStorage.getItem('clicked')));

	useEffect(() => {
		window.addEventListener('load', () => {
			setClicked(false);
		});
		// localStorage.setItem('clicked', JSON.stringify(false));
	});
	useEffect(() => {
		const button = document.getElementById('icon-bar');
		button.addEventListener('click', function () {
			setClicked(!clicked);
		});
		const root = document.documentElement;
		root.style.setProperty('--active-menu', primaryColor);
	});
	localStorage.setItem('clicked', JSON.stringify(clicked));
	return (
		<RequireLogin>
			<div id="wrapper">
				<Header clicked={clicked} />
				<div
					style={{
						width: !clicked ? 'calc(100% - 110px)' : 'calc(80% - 30px)',
					}}
					className="right-content"
				>
					{props.children}
				</div>
			</div>
		</RequireLogin>
	);
}

export default withRouter(withMediaQuery('(max-width:480px)')(Checkin));
