import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TableContainer from '../components/table/TableContainer';

// Bắt đầu lúc, vị trí, khách hàng, dịch vụ, nhân viên thực hiện, ghi chú, trạng thái, thanh toán
const columns = [
    {
        name: 'Bắt đầu lúc',
        selector: (row) => row.start?.toString(),
        sortable: true,
    },
    {
        name: 'Vị trí',
        selector: (row) => row.location,
        sortable: true,
    },
    {
        name: 'Khách hàng',
        selector: (row) => row.customer,
        sortable: true,
    },
    {
        name: 'Dịch vụ',
        selector: (row) => row.services,
        format: (row) =>
            row.services?.map((service) => service.name).join(', '),
        sortable: true,
    },
    {
        name: 'Nhân viên thực hiện',
        selector: (row) => row.staff,
        sortable: true,
    },
    {
        name: 'Ghi chú',
        selector: (row) => row.note,
        sortable: true,
    },
    {
        name: 'Trạng thái',
        selector: (row) => row.status,
        sortable: true,
    },
    {
        name: 'Thanh toán',
        selector: (row) => row.payment,
        sortable: true,
    },
];

export default function BookingList({ data }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TableContainer columns={columns} data={data} />
        </div>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 16,
        boxSizing: 'border-box',
    },
}));
