import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tooltip, Paper, Box } from '@material-ui/core';
import { SaleContext } from '../SaleContainer';
import styled from 'styled-components';
import { VscClose } from 'react-icons/vsc';
import Confirm from '../components/confirm/Confirm';

const BillItemStyled = styled(Paper)`
	width: 100px;
	height: 90%;
	flex-shrink: 0;
	color: ${(props) => (props.active ? '#fff' : '')};
	background-color: ${(props) => (props.active ? props.bgColor : '')};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	cursor: pointer;
`;
const BiilItemIcon = styled(Box)`
	display: ${(props) => (props.active ? 'flex' : 'none')};
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	border-radius: 50%;
	cursor: pointer;
`;

function BillItem({ onSelectBill, handleDeleteBill, index, textDisplay }) {
	const confirmRef = React.useRef();
	const theme = useTheme();
	const { vouchers, indexVoucher } = React.useContext(SaleContext);

	return (
		<>
			<Confirm ref={confirmRef} content={`Xác nhận xóa `} onConfirm={() => handleDeleteBill(index)} />
			<BillItemStyled
				onClick={() => onSelectBill(index)}
				active={index === indexVoucher}
				bgColor={theme.palette.primary.main}
			>
				{textDisplay}
				<Tooltip arrow placement="top" title="Xóa hóa đơn">
					<BiilItemIcon
						active={vouchers?.length > 1 && index === indexVoucher}
						onClick={confirmRef?.current?.open}
						bgColor={theme.palette.primary.main}
					>
						<VscClose fontSize="14px" />
					</BiilItemIcon>
				</Tooltip>
			</BillItemStyled>
		</>
	);
}

export default BillItem;
