import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import React from 'react';
import '../components/filter/Filter.css';

export default function MenuPopup({ open, anchorRef, onClose, menuItems }) {
	return (
		<Popper
			open={open}
			anchorEl={anchorRef?.current}
			transition
			disablePortal
			placement='bottom-start'
			style={{ zIndex: 2 }}
			onClick={onClose}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
					}}
				>
					<Paper>
						<ClickAwayListener onClickAway={onClose}>
							<MenuList
								style={{
									fontSize: '14px !important',
								}}
							>
								{menuItems.map((item, index) => (
									<MenuItem
										onClick={item.onClick}
										style={{
											fontSize: '10px !important',
										}}
										key={index}
									>
										{item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}

										{item.label}
									</MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
}
