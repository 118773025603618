import { Checkbox, Divider, makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import AddRounded from '@material-ui/icons/AddRounded';
import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GrSearch } from 'react-icons/gr';
import SelectInput from '../components/input/SelectInput';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const staffs = [
    { label: 'Nhân viên 1', value: 'staff1' },
    { label: 'Nhân viên 2', value: 'staff2' },
    { label: 'Nhân viên 3', value: 'staff3' },
    { label: 'Nhân viên 4', value: 'staff4' },
    { label: 'Nhân viên 5', value: 'staff5' },
];

const locations = [
    { label: 'Vị trí 1', value: 'location1' },
    { label: 'Vị trí 2', value: 'location2' },
    { label: 'Vị trí 3', value: 'location3' },
];

const btnDisplayClassName = {
    Day: '.fc-timeGridDay-button',
    Week: '.fc-timeGridWeek-button',
    Month: '.fc-dayGridMonth-button',
};

export default function BookingFilter({ displayType, toggleDisplayType }) {
    const classes = useStyles();

    const [date, setDate] = useState(new Date());

    const [displayDuration, setDisplayDuration] = useState('Week');
    const btnDisplayDurationClassName = btnDisplayClassName[displayDuration];

    // Get the button element
    const btnDisplayDuration = useMemo(
        () => document.querySelector(btnDisplayDurationClassName),
        [btnDisplayDurationClassName]
    );

    const handleChangeDuration = (option) => {
        setDisplayDuration(option.value);
    };

    // Click the button when change display duration option or display type
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        btnDisplayDuration?.click();
    }, [btnDisplayDuration]);

    const handleTodayClick = () => {
        // Get the button element
        let button = document.querySelector('.fc-today-button');

        // Click the button
        // eslint-disable-next-line no-unused-expressions
        button?.click();
    };

    return (
        <Grid container justifyContent="space-between" className={classes.root}>
            <Grid item xs={12} lg={6} container spacing={1}>
                <Grid item xs={3} lg>
                    <div className={classes.shadowBox}>
                        <SelectInput
                            className={classes.selectInput}
                            placeholder="Chọn vị trí"
                            items={locations}
                        />
                    </div>
                </Grid>

                <Grid item xs={3} lg>
                    <div className={classes.shadowBox}>
                        <SelectInput
                            className={classes.selectInput}
                            placeholder="Chọn nhân viên"
                            items={staffs}
                        />
                    </div>
                </Grid>

                <Grid item xs lg>
                    <div className={classes.shadowBox}>
                        <GrSearch className={classes.searchIcon} />

                        <input
                            type="text"
                            placeholder="Tìm kiếm"
                            className={classes.searchInput}
                        />
                    </div>
                </Grid>

                <Grid item xs="auto">
                    <div className={classes.shadowBox}>
                        <PersonRoundedIcon className={classes.personIcon} />
                    </div>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
                container
                spacing={1}
                justifyContent="flex-end"
            >
                <Grid item xs lg="auto">
                    <div className={classes.shadowBox}>
                        <button
                            className="btn btn-text"
                            onClick={handleTodayClick}
                        >
                            Hôm nay
                        </button>
                    </div>
                </Grid>

                <Grid item xs lg="auto">
                    <div className={classes.shadowBox}>
                        <DatePicker
                            className={classes.datePicker}
                            showIcon
                            dateFormat="dd/MM/yyyy"
                            selected={date}
                            onChange={setDate}
                        />
                    </div>
                </Grid>

                <Grid item xs="auto">
                    <div className={classes.shadowBox}>
                        <SelectInput
                            clearable={false}
                            className={classes.selectInput}
                            items={[
                                { label: 'Ngày', value: 'Day' },
                                { label: 'Tuần', value: 'Week' },
                                { label: 'Tháng', value: 'Month' },
                            ]}
                            defaultValue={{ label: 'Tuần', value: 'Week' }}
                            onChange={handleChangeDuration}
                        />
                    </div>
                </Grid>

                <Grid item xs="auto">
                    <button className="btn btn-edit">
                        <AddRounded />
                        Thêm lịch
                    </button>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.checkBoxContainer}>
                <div className={classes.checkBoxContainer}>
                    <FormControlLabel
                        className={classes.checkBoxGrey}
                        control={<Checkbox />}
                        label="Chờ xác nhận"
                    />
                    <FormControlLabel
                        className={classes.checkBoxGrey}
                        control={<Checkbox />}
                        label="Chưa tới"
                    />
                    <FormControlLabel
                        className={classes.checkBoxWarning}
                        control={<Checkbox />}
                        label="Đang chờ"
                    />
                    <FormControlLabel
                        className={classes.checkBoxSuccess}
                        control={<Checkbox />}
                        label="Đang sử dụng"
                    />
                    <FormControlLabel
                        className={classes.checkBoxSuccess}
                        control={<Checkbox />}
                        label="Đã xong"
                    />
                </div>

                <Divider
                    orientation="vertical"
                    style={{
                        height: 24,
                    }}
                />

                <div className={classes.checkBoxContainer}>
                    <FormControlLabel
                        className={classes.checkBoxGrey}
                        control={<Checkbox />}
                        label="Chưa thanh toán"
                    />
                    <FormControlLabel
                        className={classes.checkBoxSuccess}
                        control={<Checkbox />}
                        label="Đã thanh toán"
                    />
                </div>

                <Divider
                    orientation="vertical"
                    style={{
                        height: 24,
                    }}
                />

                <ToggleButtonGroup
                    value={displayType}
                    exclusive
                    onChange={toggleDisplayType}
                    size="small"
                >
                    <ToggleButton value="grid">
                        <GridOnRoundedIcon />
                    </ToggleButton>

                    <ToggleButton value="list">
                        <ListRoundedIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        rowGap: 16,
    },
    shadowBox: {
        backgroundColor: '#fff',
        boxShadow: 'rgb(0 0 0 / 5%) 0px 20px 27px 0px',
        borderRadius: 8,
        fontSize: 12,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    selectInput: {
        '&:before': {
            display: 'none',
        },
    },
    searchInput: {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 12,
    },
    searchIcon: {
        fontSize: 28,
        paddingRight: 8,
    },
    personIcon: {
        fontSize: 28,
        padding: 0,
        aspectRatio: '1',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer',

        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    datePicker: {
        border: 'none',
        outline: 'none',
        fontSize: 12,
    },
    checkBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 16,

        '& span': {
            fontSize: 12,
            fontWeight: 500,
            padding: 0,
            marginRight: 4,
        },
        '& label': {
            margin: 0,
            marginRight: 8,
        },
    },
    checkBoxGrey: {
        color: '#9e9e9e',
        '& input + svg': {
            color: '#9e9e9e',
        },
    },
    checkBoxSuccess: {
        color: '#4caf50',
        '& input + svg': {
            color: '#4caf50',
        },
    },
    checkBoxWarning: {
        color: '#ff9800',
        '& input + svg': {
            color: '#ff9800',
        },
    },
    checkBoxDanger: {
        color: '#f44336',
        '& input + svg': {
            color: '#f44336',
        },
    },
}));
