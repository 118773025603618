import React from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";

const ButtonStyled = styled.button`
  border: none;
  outline: none;
  border: 1px solid;
  border-color: ${(props) => props.mainColor};
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 13px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  &:hover {
    filter: ${(props) => (props.disabled ? "" : "brightness(95%)")};
  }
  color: ${(props) => {
    if (props.variant === "outline") {
      return props.mainColor;
    } else {
      return "#fff";
    }
  }};
  background-color: ${(props) => {
    if (props.variant === "outline") {
      return "#fff";
    } else {
      return props.mainColor;
    }
  }};
`;

function ButtonBase({
  variant = "contained",
  children,
  type = "button",
  onClick = () => {},
  style = {},
  disabled = false,
  startIcon,
  endIcon,
  ...props
}) {
  const theme = useTheme();

  return (
    <ButtonStyled
      {...props}
      disabled={disabled}
      style={style}
      type={type}
      onClick={onClick}
      mainColor={theme.palette.primary.main}
      variant={variant}
    >
      {startIcon}
      {children}
      {endIcon}
    </ButtonStyled>
  );
}

export default ButtonBase;
