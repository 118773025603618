import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Controller } from 'react-hook-form';
import InputContainer from '../../../../components/Dialog/InputContainer';

export default function DetailDescriptionTab({ hookForm }) {
	const classes = useStyles();

	const { control, register } = hookForm;

	return (
		<div className={classes.root}>
			<InputContainer title='Mô tả ngắn' defaultShow>
				<Controller
					name='mieu_ta'
					control={control}
					render={({ field: { value, onChange } }) => (
						<CKEditor
							editor={ClassicEditor}
							data={value || ''}
							onChange={(_event, editor) => {
								const data = editor.getData();
								onChange(data);
							}}
						/>
					)}
				/>
			</InputContainer>

			<InputContainer title='Mô tả chi tiết' style={{ marginTop: 16 }}>
				<Controller
					name='mieu_ta_chi_tiet'
					control={control}
					render={({ field: { value, onChange } }) => (
						<CKEditor
							editor={ClassicEditor}
							data={value || ''}
							onChange={(_event, editor) => {
								const data = editor.getData();
								onChange(data);
							}}
						/>
					)}
				/>
			</InputContainer>

			<InputContainer title='Mô tả trên app' style={{ marginTop: 16 }}>
				<Controller
					name='exfields.mieu_ta_app'
					control={control}
					render={({ field: { value, onChange } }) => (
						<CKEditor
							editor={ClassicEditor}
							data={value || ''}
							onChange={(_event, editor) => {
								const data = editor.getData();
								onChange(data);
							}}
						/>
					)}
				/>
			</InputContainer>

			<InputContainer title='Mô tả trên web' style={{ marginTop: 16 }}>
				<Controller
					name='exfields.mieu_ta_web'
					control={control}
					render={({ field: { value, onChange } }) => (
						<CKEditor
							editor={ClassicEditor}
							data={value || ''}
							onChange={(_event, editor) => {
								const data = editor.getData();
								onChange(data);
							}}
						/>
					)}
				/>
			</InputContainer>

			<InputContainer title='Ghi chú' style={{ marginTop: 16 }}>
				<textarea placeholder='Nhập ghi chú' className={classes.textarea} {...register('exfields.note')} />
			</InputContainer>
		</div>
	);
}

const useStyles = makeStyles(() => ({
	root: {
		marginTop: 16,
	},
	textarea: {
		width: '100%',
		height: 160,
		border: '1px dashed #e0e0e0',
		borderRadius: 8,
		padding: 8,
		outline: 'none',
		resize: 'none',
	},
}));
