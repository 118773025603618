import { IconButton } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { makeStyles } from '@material-ui/styles';
import React, { useRef } from 'react';
import { useCore } from '../../../../../../hooks/useCore';
import { BASE_URL } from '../../../../../../utils/constants';
import { getImageField } from '../../../goods.util';

export default function ImageTab({ hookForm, images, setImages }) {
	const classes = useStyles();
	const core = useCore();

	const { setValue } = hookForm;

	const inputImageRef = useRef(null);

	const handleAddImage = async (e) => {
		const files = e.target.files;
		if (!files || files.length === 0) return;

		const newImages = [...images];
		const emptyCount = newImages.filter((i) => !i).length;

		await Promise.all(
			Array.from(files)
				.slice(0, emptyCount) // only upload empty images
				.map(async (file) => {
					// upload file to server
					const res = await core.uploadFile(file, { folder: 'dmvt' });
					const src = res.fileUrl;

					// get index of empty image
					const emptyIndex = newImages.findIndex((i) => !i);

					// set image to form
					const { imgField, thumbField } = getImageField(emptyIndex);
					setValue(imgField, src);
					setValue(thumbField, src);

					// set image to state
					newImages[emptyIndex] = {
						image: BASE_URL + src,
						imgField,
						thumb: BASE_URL + src,
						thumbField,
						emptyIndex,
					};
				}),
		);

		setImages(newImages);

		// reset input
		inputImageRef.current.value = '';
	};

	const SlideImages = () => (
		<div className={classes.listImageContainer}>
			{images?.map((image, index) => (
				<SlideImage key={index} data={image} index={index} />
			))}
		</div>
	);

	const SlideImage = ({ data, index }) => {
		const handleDeleteImage = () => {
			// delete image from server
			const { imgField, thumbField } = getImageField(index);
			setValue(imgField, '');
			setValue(thumbField, '');

			// delete image from state
			const newImages = [...images];
			newImages[index] = null;
			setImages(newImages);
		};

		const handleAddImage = () => {
			// eslint-disable-next-line no-unused-expressions
			inputImageRef.current?.click();
		};
		return (
			<div className={classes.imageContainer}>
				<IconButton className={classes.imageAction} onClick={!data ? handleAddImage : handleDeleteImage}>
					{!data ? (
						<AddCircleOutlineRoundedIcon color='primary' />
					) : (
						<DeleteOutlineRoundedIcon color='error' />
					)}
				</IconButton>

				<img
					className={classes.slideImage}
					src={data?.thumb || data?.image || 'https://via.placeholder.com/100/FFF/000/?text=Thêm+ảnh'}
					alt={data?.alt || `Ảnh ${index + 1}`}
				/>
			</div>
		);
	};
	return (
		<div className={classes.root}>
			<input
				type='file'
				ref={inputImageRef}
				onChange={handleAddImage}
				hidden
				accept='image/*'
				multiple
				max={images.filter((i) => !i).length}
			/>
			<SlideImages />
		</div>
	);
}

const useStyles = makeStyles(() => ({
	root: {
		marginTop: '16px',
	},
	imageContainer: {
		width: '100px',
		height: '100px',
		position: 'relative',

		// Hover show first child
		'&:hover': {
			'& > *:first-child': {
				display: 'block',

				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
	},
	imageAction: {
		zIndex: 1,
		display: 'none',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	slideImage: {
		objectFit: 'contain',
		aspectRatio: '1/1',
		position: 'relative',
		borderRadius: '8px',
		width: '100%',
		height: '100%',
		transition: 'all 0.3s',
		border: '1px dashed #e0e0e0',
	},
	listImageContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '8px',
		justifyContent: 'space-between',
	},
}));
