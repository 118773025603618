import React from "react";
import { BiGift } from "react-icons/bi";
import { BsCalculator } from "react-icons/bs";
import { TfiSave } from "react-icons/tfi";
import "./Member.css";

function Header(props) {
  return (
    <div className="header">
      <div className="title">
        <BiGift size={20} /> Điểm khách hàng
      </div>
      <div className="tool">
        <button className="btn_update">
          <div className="icon">
            <i className="fas fa-calculator"></i>
          </div>
          Cập nhật hạng
        </button>
        <button className="btn_save">
          <div className="icon">
            <i className="fas fa-save"></i>
          </div>
          Lưu thông tin
        </button>
      </div>
    </div>
  );
}

export default Header;
