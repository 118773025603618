/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';
import Dialog from '../components/Dialog/Dialog';
import { Box, Grid, CssBaseline } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { CancelRounded, SaveRounded } from '@material-ui/icons';
import FieldInput from '../components/Dialog/FieldInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import moment from 'moment';

const PriceFormStyled = styled(Box)`
	padding: 10px 0;
`;
const CheckboxWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const schema = yup.object({
	ma_ca: yup.string().required('Vui lòng nhập mã ca'),
	ten_ca: yup.string().required('Vui lòng nhập tên ca'),
});

function ShiftForm({ open, onClose, data, setData, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors, isSubmitting },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {},
	});
	const [isEdit, setIsEdit] = React.useState(false);
	const theme = useTheme();

	React.useEffect(() => {
		if (open) {
			if (!data || isEmpty(data)) {
				setIsEdit(false);
				reset({
					ma_ca: '',
					ten_ca: '',
					start_time: '',
					end_time: '',
					start_time_right: '',
					end_time_right: '',
					status: false,
				});
			} else {
				setIsEdit(true);
				reset({
					...data,
					start_time: data.start_time ? moment(data.start_time).format('HH:mm') : '',
					end_time: data.end_time ? moment(data.end_time).format('HH:mm') : '',
					start_time_right: data.start_time_right ? moment(data.start_time_right).format('HH:mm') : '',
					end_time_right: data.end_time_right ? moment(data.end_time_right).format('HH:mm') : '',
				});
			}
		}
	}, [data, open]);

	// genarate data post
	const generateDataPost = (values) => {
		const convertTimeToDate = (time) => {
			if (!time) return '';
			const day = moment();
			const momentTime = moment(time, 'HH:mm');
			day.hours(momentTime.hours());
			day.minutes(momentTime.minutes());
			return day;
		};
		values.start_time = convertTimeToDate(values.start_time);
		values.end_time = convertTimeToDate(values.end_time);
		values.start_time_right = convertTimeToDate(values.start_time_right);
		values.end_time_right = convertTimeToDate(values.end_time_right);
		return {
			...data,
			...values,
		};
	};
	// save
	const handleSave = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost).then(onClose);
	};
	// handle save copy
	const handleSaveCopy = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost);
	};

	// save and add new
	const handleSaveAddNew = (values) => {
		const dataPost = generateDataPost(values);
		return onSubmit(dataPost).then(() => {
			setIsEdit(false);
			setData({});
		});
	};

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={isEdit ? 'Chỉnh sửa ca làm việc' : 'Thêm ca làm việc'}
			loading={isSubmitting}
			width="824px"
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(handleSave)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveAddNew)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveCopy)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					<CancelRounded />
					Hủy
				</button>,
			]}
		>
			<CssBaseline />
			<PriceFormStyled>
				<Grid container alignContent="flex-start" spacing={2}>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Tên ca"
							placeholder="Tên ca"
							inputProps={{ min: 0, ...register('ten_ca') }}
							defaultValue={getValues('ten_ca')}
							type="text"
							errorMsg={errors?.ten_ca?.message}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Mã ca"
							placeholder="Mã ca"
							inputProps={{ min: 0, ...register('ma_ca') }}
							defaultValue={getValues('ma_ca')}
							type="text"
							errorMsg={errors?.ma_ca?.message}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Thời gian từ"
							placeholder="Thời gian từ"
							inputProps={{ min: 0, ...register('start_time') }}
							defaultValue={getValues('start_time')}
							type="time"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Thời gian đến"
							placeholder="Thời gian đến"
							inputProps={{ min: 0, ...register('end_time') }}
							defaultValue={getValues('end_time')}
							type="time"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Chấm vào từ"
							placeholder="Chấm vào từ"
							inputProps={{ min: 0, ...register('start_time_right') }}
							defaultValue={getValues('start_time_right')}
							type="time"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput
							title="Chấm ra đến"
							placeholder="Chấm ra đến"
							inputProps={{ min: 0, ...register('end_time_right') }}
							defaultValue={getValues('end_time_right')}
							type="time"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<CheckboxWrapper>
							<input
								style={{ accentColor: theme.palette.primary.main }}
								type="checkbox"
								id="using"
								{...register('status')}
							/>
							<label htmlFor="using">Còn sử dụng</label>
						</CheckboxWrapper>
					</Grid>
				</Grid>
			</PriceFormStyled>
		</Dialog>
	);
}

export default ShiftForm;
