import React from 'react';
import BarcodeContainer from './Barcode/BarcodeContainer';
import Container from './Container';

function Barcode(props) {
	return (
		<Container requireLogin {...props}>
			<BarcodeContainer />
		</Container>
	);
}

export default Barcode;
