import React, { useEffect, useState } from 'react';
import {
	AiFillCopy,
	AiFillDelete,
	AiFillFileAdd,
	AiFillPrinter,
	AiOutlineArrowDown,
	AiOutlineArrowUp,
} from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { IoAddOutline, IoDuplicateOutline } from 'react-icons/io5';
import { convertStringToNumber } from '../../Utils';
import TableManager from '../../../components/tableManager/TableManager';
import NotiDialog from '../../components/NotiDialog';
import { useCore } from '../../../../../hooks';
import FormParcelDialog from '../../../ListParcel/dialog/FormParcelDialog';

const defaultValues = {
	sl_nhap: 1,
	pn_gia_tb: true,
	gia_von: 0,
	gia_von_nt: 0,
	tien_nhap: 0,
	tien_nhap_nt: 0,
	gia_ban: 0,
	gia_ban_nt: 0,
	tien_hang: 0,
	tien_hang_nt: 0,
	ty_le_ck: 0,
	tien_ck: 0,
	tien_ck_nt: 0,
	tien: 0,
	tien_nt: 0,
	ma_kh: '',
	ma_bp: '',
	ma_phi: '',
	ma_hd: '',
	ma_dt: '',
	ma_nv: '',
	ma_lo: '',
	ma_tt1: '',
	ma_tt2: '',
	ma_tt3: '',
	line: 0,
	ma_vt: '',
	ma_dvt: '',
	tk_vt: '',
	tk_gv: '',
	ten_tk_vt: '',
	ten_tk_gv: '',
	ten_vt: '',
	picture: '',
	ten_dt: '',
	ten_hd: '',
};

function TabDetail({ data, readonly, handleGetDataDetail, view, isFieldNt }) {
	useEffect(() => {
		if (view || !isFieldNt.checkEdit) {
			for (let i = 0; i < isDataVoucherDetail.length; i++) {
				updateMyData(i, 'tien', convertStringToNumber(isDataVoucherDetail[i].tien_nt) * isFieldNt?.ty_gia);
			}
		} else return;
	}, [isFieldNt]);
	const core = useCore();
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const handleSubmit = async (values) => {
		try {
			await core.asyncPostList(core.token, 'dmlo', values);
		} catch (error) {
			core.contextAlert(error);
		}
	};
	const DropdownIndicator = () => (
		<button className="btn_select_create" onClick={() => setOpen(true)}>
			<IoAddOutline size={20} />
		</button>
	);

	const [isma_vt, setIsma_vt] = useState();
	const [isTen_vt, setIsTen_vt] = useState();
	const handleOnFocusMaVt = (index) => {
		setIsma_vt(isDataVoucherDetail[index].ma_vt);
		setIsTen_vt(isDataVoucherDetail[index].ten_vt);
	};
	const columns = [
		{
			Header: 'Mã hàng',
			accessor: 'ma_vt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ten_vt', item.ten_vt);
					updateMyData(index, 'ma_vt', item.ma_vt);
					updateMyData(index, 'tk_vt', item.tk_vt);
					updateMyData(index, 'ma_dvt', item.ma_dvt);
					updateMyData(index, 'ma_lo', '');
					updateMyData(index, 'han_sd', '');
				},
				valueField: 'ma_vt',
				labelField: 'ten_vt',
				api: 'dmvt',
				q: { status: true },
				filter: { ma_kh: '', ma_kho: '' },
				getLabel: (item) => {
					return `${item.ma_vt}`;
				},
			},
		},
		{
			Header: 'Mã lô',
			accessor: 'ma_lo',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			type: 'select',
			handleOnFocus: handleOnFocusMaVt,
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_lo', item.ma_lo);
					updateMyData(index, 'han_sd', item.han_sd);
				},
				valueField: 'ma_lo',
				labelField: 'ten_lo',
				api: 'dmlo',
				q: {
					status: true,
					ma_vt: isma_vt,
				},
				prefix: true,
				getLabel: (item) => {
					return `${item.ma_lo} - ${item.ten_lo}`;
				},
				componentAdd: { DropdownIndicator },
			},
		},
		{
			Header: 'Tên mặt hàng',
			accessor: 'ten_vt',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
		},
		{
			Header: 'Đvt',
			accessor: 'ma_dvt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
		},
		{
			Header: 'TK vật tư',
			accessor: 'tk_vt',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
		},
		{
			Header: 'Hạn sử dụng',
			accessor: 'han_sd',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
		},
		{
			Header: 'SL Nhập',
			accessor: 'sl_nhap',
			width: 100,
			minWidth: 70,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(
					index,
					'tien_hang_nt',
					convertStringToNumber(value) * isDataVoucherDetail[index].gia_von_nt
				);
				updateMyData(
					index,
					'tien_hang',
					convertStringToNumber(value) * isDataVoucherDetail[index].gia_von_nt * isFieldNt.ty_gia
				);
				updateMyData(
					index,
					'tien_nhap_nt',
					convertStringToNumber(value) * isDataVoucherDetail[index].gia_von_nt +
						isDataVoucherDetail[index].tien_ck_nt
				);
				updateMyData(
					index,
					'tien_nhap',
					(convertStringToNumber(value) * isDataVoucherDetail[index].gia_von_nt +
						isDataVoucherDetail[index].tien_ck_nt) *
						isFieldNt.ty_gia
				);
			},
		},
		{
			Header: `Giá vốn ${isFieldNt?.ma_nt}`,
			accessor: 'gia_von_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(index, 'tien_hang_nt', convertStringToNumber(value) * isDataVoucherDetail[index].sl_nhap);
				updateMyData(
					index,
					'tien_hang',
					convertStringToNumber(value) * isDataVoucherDetail[index].sl_nhap * isFieldNt.ty_gia
				);
				updateMyData(
					index,
					'tien_nhap_nt',
					convertStringToNumber(value) * isDataVoucherDetail[index].sl_nhap +
						isDataVoucherDetail[index].tien_ck_nt
				);
				updateMyData(
					index,
					'tien_nhap',
					(convertStringToNumber(value) * isDataVoucherDetail[index].sl_nhap +
						isDataVoucherDetail[index].tien_ck_nt) *
						isFieldNt.ty_gia
				);
				updateMyData(index, 'gia_von', convertStringToNumber(value) * isFieldNt.ty_gia);
			},
		},
		{
			Header: `Giá vốn VND`,
			accessor: 'gia_von',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: `Tiền hàng ${isFieldNt?.ma_nt}`,
			accessor: 'tien_hang_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: `Tiền hàng VND`,
			accessor: 'tien_hang',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: 'Tỷ lệ CK',
			accessor: 'ty_le_ck',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(
					index,
					'tien_ck_nt',
					convertStringToNumber(value) * 0.01 * isDataVoucherDetail[index].tien_hang_nt
				);
				updateMyData(
					index,
					'tien_ck',
					convertStringToNumber(value) * 0.01 * isDataVoucherDetail[index].tien_hang
				);
				updateMyData(
					index,
					'tien_nhap_nt',
					isDataVoucherDetail[index].tien_hang_nt +
						convertStringToNumber(value) * 0.01 * isDataVoucherDetail[index].tien_hang_nt
				);
				updateMyData(
					index,
					'tien_nhap',
					(isDataVoucherDetail[index].tien_hang_nt +
						convertStringToNumber(value) * 0.01 * isDataVoucherDetail[index].tien_hang_nt) *
						isFieldNt.ty_gia
				);
			},
		},
		{
			Header: `Tiền CK ${isFieldNt?.ma_nt}`,
			accessor: 'tien_ck_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(
					index,
					'tien_nhap_nt',
					isDataVoucherDetail[index].tien_hang_nt + convertStringToNumber(value)
				);
				updateMyData(
					index,
					'tien_nhap',
					(isDataVoucherDetail[index].tien_hang_nt + convertStringToNumber(value)) * isFieldNt.ty_gia
				);
				updateMyData(index, 'tien_ck', convertStringToNumber(value) * isFieldNt.ty_gia);
			},
		},
		{
			Header: `Tiền CK VND`,
			accessor: 'tien_ck',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: `Tiền nhập ${isFieldNt?.ma_nt}`,
			accessor: 'tien_nhap_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: `Tiền nhập VND`,
			accessor: 'tien_nhap',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
		{
			Header: 'Hợp đồng',
			accessor: 'ma_hd',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
			type: 'select',
			disableSortBy: true,
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_hd', item.so_hd);
					updateMyData(index, 'ten_hd', item.ten_hd);
				},
				valueField: 'so_hd',
				labelField: 'ten_hd',
				api: 'contract',
				q: { status: true },
				t: 1,
				getLabel: (item) => {
					return `${item.so_hd} - ${item.ten_hd}`;
				},
			},
		},
		{
			Header: 'Vụ việc',
			accessor: 'ten_dt',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
			Filter: false,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_dt', item.ma_dt);
					updateMyData(index, 'ten_dt', item.ten_dt);
				},
				valueField: 'ma_dt',
				labelField: 'ten_dt',
				api: 'dmdt',
				q: { status: true },
				t: 1,
				getLabel: (item) => {
					return `${item.ma_dt} - ${item.ten_dt}`;
				},
			},
		},
		{
			Header: 'Diễn giải',
			accessor: 'dien_giai',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			disableSortBy: true,
			type: 'text',
		},
	];
	const [isDataVoucherDetail, setIsDataVoucherDetail] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);

	const [openDelete, setOpenDelete] = useState(false);

	useEffect(() => {
		if (data === undefined || data?.details.length < 1) {
			const newData = [];
			for (let i = 0; i < 1; i++) {
				newData.push({ ...defaultValues, line: i + 1 });
			}
			setIsDataVoucherDetail(newData);
		} else {
			setIsDataVoucherDetail(data?.details);
		}
	}, []);

	const skipResetRef = React.useRef(false);
	const updateMyData = (rowIndex, columnId, value) => {
		skipResetRef.current = true;
		setIsDataVoucherDetail((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};
	useEffect(() => {
		skipResetRef.current = false;
		handleGetDataDetail && handleGetDataDetail(isDataVoucherDetail);
	}, [isDataVoucherDetail]);

	const menuTabDetail = [
		{
			id: 1,
			tabName: 'Thêm',
			icon: <AiFillFileAdd />,
			handle: () => {
				handleOpenAddDetailItemVoucher();
			},
		},
		{
			id: 2,
			tabName: 'Chuyển lên',
			icon: <AiOutlineArrowUp />,
			handle: () => {
				// handleMoveRowItemVoucher('up');
			},
		},
		{
			id: 3,
			tabName: 'Chuyển xuống',
			icon: <AiOutlineArrowDown />,
			handle: () => {
				// handleMoveRowItemVoucher('down');
			},
		},
		{
			id: 4,
			tabName: 'Nhân dòng',
			icon: <IoDuplicateOutline />,
			handle: () => {
				handleDuplicateRowItemVoucher();
			},
		},
		{
			id: 5,
			tabName: 'Xoá',
			icon: <AiFillDelete />,
			handle: () => {
				handleDeleteItemVoucher();
			},
		},
		{
			id: 6,
			tabName: 'Lấy dữ liệu',
			icon: <AiFillCopy />,
			handle: () => {
				console.log(isDataVoucherDetail);
			},
		},
		{
			id: 7,
			tabName: 'Nhập',
			icon: <BiImport />,
		},
		{
			id: 8,
			tabName: 'Xuất',
			icon: <BiExport />,
		},
		{
			id: 9,
			tabName: 'In',
			icon: <AiFillPrinter />,
		},
	];

	const handleOpenAddDetailItemVoucher = () => {
		skipResetRef.current = true;
		setIsDataVoucherDetail((old) => [...old, { ...defaultValues, line: old.length }]);
	};

	//On select row
	const onSelectRowClick = (data) => {
		setDataSelected(data);
	};
	const handleDeleteItemVoucher = async () => {
		if (dataSelected.length <= 0) {
			setOpenDelete(true);
		} else {
			if (isDataVoucherDetail && dataSelected) {
				const newData = isDataVoucherDetail.filter((item) => {
					const index = dataSelected.findIndex((selectedItem) => selectedItem.line === item.line);
					return index === -1;
				});

				newData.forEach((item, index) => {
					item.line = index;
				});

				setIsDataVoucherDetail(newData);
				setDataSelected([]);
			}
		}
	};
	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	const handleMoveRowItemVoucher = async (type) => {
		if (data?.details && dataSelected) {
			// Kiểm tra đang ở vị trí nào trong mảng
			const index = dataSelected[0].line;

			if (type === 'up' && index !== 0) {
				// đổi chỗ với phần tử trước
				const temp = data?.details[index - 1];
				data.details[index - 1] = data?.details[index];
				data.details[index] = temp;
			} else if (type === 'down' && index !== data?.details?.length - 1) {
				// đổi chỗ với phần tử sau
				const temp = data?.details[index + 1];
				data.details[index + 1] = data?.details[index];
				data.details[index] = temp;
			}

			// Cập nhật lại line
			data.details.forEach((item, index) => {
				item.line = index;
			});

			try {
				// await context.apis.asyncPostList(context.userInfo.token, "pt1", {
				// 	...data,
				// });
				// await getDataVoucherPT();
				console.log('dataMove', data);
				setDataSelected([]);
			} catch (error) {
				console.log(error);
			}
		}
	};

	//Nhân dòng
	const handleDuplicateRowItemVoucher = async () => {
		skipResetRef.current = true;
		dataSelected.forEach((item) => {
			delete item._id;
		});
		//  vòng lặp để tạo ra các dòng mới
		const newData = dataSelected.map((item) => {
			return { ...item, line: isDataVoucherDetail.length };
		});

		setIsDataVoucherDetail((old) => [...old, ...newData]);
	};

	return (
		<div className="box_tab_detail_vouvher">
			<div className="box_tab_detail_vouvher__content">
				<TableManager
					idTable={view ? 'table-detail-voucher-view' : 'table-detail-voucher'}
					columns={columns}
					data={view ? data?.details : isDataVoucherDetail || []}
					updateMyData={updateMyData}
					skipReset={skipResetRef.current}
					selectRow={view ? false : true}
					onSelectRowClick={onSelectRowClick}
					readonly={readonly}
					TbodyMaxHeight={'11vh'}
					handleOpenAddDetailItemVoucher={handleOpenAddDetailItemVoucher}
					pagination={false}
					hiddenColumns={isFieldNt.ma_nt === 'VND' ? ['gia_von', 'tien_hang', 'tien_ck', 'tien_nhap'] : []}
					toolBar={
						<>
							{view ? (
								''
							) : (
								<div className="box_tab_detail_vouvher__header">
									{menuTabDetail.map((item) => {
										const shouldDisplayItem =
											dataSelected.length > 0 || (item.id !== 4 && item.id !== 5);
										if (shouldDisplayItem) {
											return (
												<div key={item.id} onClick={item.handle} title={`${item.tabName}`}>
													{item.icon} <span className="tabname_tool_bar">{item.tabName}</span>
												</div>
											);
										}
										return null;
									})}
								</div>
							)}
						</>
					}
				/>
				<NotiDialog
					open={openDelete}
					handleClose={handleCloseDelete}
					title="Thông báo"
					content="Vui lòng chọn ít nhất 1 dòng để thực hiện chức năng này"
				/>
				<FormParcelDialog
					open={open}
					onClose={handleClose}
					onSubmit={handleSubmit}
					data={{
						ma_vt: isma_vt,
						ten_vt: isTen_vt,
					}}
				/>
			</div>
		</div>
	);
}

export default TabDetail;
