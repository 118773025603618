import React from 'react';
import { NumericFormat } from 'react-number-format';
import FieldBox from './FieldBox';

const FieldNumberInput = ({
	title,
	tooltip,
	check,
	value,
	onChange,
	placeholder,
	icon,
	actions,
	classCus,
	errorMsg,
	style,
	...props
}) => {
	const defaultClass = 'field_input_content_input';

	return (
		<FieldBox title={title} tooltip={tooltip} check={check} errorMsg={errorMsg}>
			<div className="field_input_container">
				{icon && icon}
				<NumericFormat
					style={style}
					value={value}
					thousandSeparator=" "
					decimalSeparator=","
					onValueChange={(v) => onChange?.(v.floatValue || 0)}
					placeholder={placeholder}
					className={defaultClass + ' ' + classCus}
					{...props}
				/>
				{actions && actions}
			</div>
		</FieldBox>
	);
};

export default FieldNumberInput;
