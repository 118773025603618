import { Grid } from '@material-ui/core';
import React from 'react';

export const isImportError = (error) => {
	// check if error is object
	if (typeof error !== 'object') return false;

	// check if error has rows_error
	if (!error.rows_error) return false;

	// check if rows_error is array
	if (!Array.isArray(error.rows_error)) return false;

	return true;
};

const RenderError = ({ item }) => {
	const getErrorMessage = (err) => {
		if (typeof err === 'string') return err;
		else if (typeof err === 'object') return `Mã lỗi: ${err.code} - ${err.error}`;
		else return '';
	};

	if (Array.isArray(item.error)) {
		return item.error?.map((err, index) => (
			<Grid item xs={12} key={index}>
				<p style={{ color: '#f44336' }}>{getErrorMessage(err)}</p>
			</Grid>
		));
	}

	return <p style={{ color: '#f44336' }}>{getErrorMessage(item.error)}</p>;
};

export default function RenderImportError({ error }) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<p style={{ fontWeight: 600, color: '#4bac4d' }}>
					{`Đã nhập thành công ${error.rows_imported || 0} dòng!`}
				</p>
			</Grid>

			<Grid item xs={12} style={{ marginTop: 16 }}>
				<p style={{ fontWeight: 600, color: '#f44336' }}>{`Có ${error.rows_error?.length || 0} dòng lỗi:`}</p>
			</Grid>

			{error.rows_error?.map((item, index) => (
				<Grid item xs={12} container spacing={2} key={index}>
					<Grid item xs={12}>
						<p style={{ fontWeight: 600 }}>
							{`${index} - Mã vật tư: ${item.row.ma_vt} - Tên vật tư: ${item.row.ten_vt}`}
						</p>
					</Grid>

					<Grid item>
						<span style={{ fontWeight: 600, color: '#f44336', marginLeft: 8 }}>Lỗi:</span>
					</Grid>
					<Grid item xs container={Array.isArray(item.error)} spacing={2}>
						<RenderError item={item} />
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}
