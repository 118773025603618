import { Checkbox } from '@material-ui/core';
import React, { useState } from 'react';
import FilterBox from './FilterBox';

function CheckboxRow({ label, checked, onChange }) {
	return (
		<div className="filter__check__list-item">
			<Checkbox
				color="primary"
				size="small"
				style={{
					padding: 0,
				}}
				checked={checked}
				onChange={onChange}
			/>

			<label>{label}</label>
		</div>
	);
}

export default function FilterListCheckbox({
	title,
	items,
	labelField = 'label',
	valueField = 'value',
	defaultSelectedItems = [],
	onChange,
	collapsable,
	isSetCollapsed,
	actions,
}) {
	const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);

	const onSelect = (value) => {
		let newSelectedItems = [...selectedItems];
		const isChecked = selectedItems.includes(value);

		// If checked, remove from list
		if (isChecked) {
			newSelectedItems = newSelectedItems.filter((x) => x !== value);
		}

		// If unchecked, add to list
		else {
			newSelectedItems = [...newSelectedItems, value];
		}

		// Update state and call onChange
		setSelectedItems(newSelectedItems);
		// eslint-disable-next-line no-unused-expressions
		onChange?.(newSelectedItems);
	};

	return (
		<FilterBox title={title} collapsable={collapsable} actions={actions} isSetCollapsed={isSetCollapsed}>
			<div className="filter__check__list">
				{items.map((item) => {
					const value = item[valueField];
					const label = item[labelField];
					const isChecked = selectedItems.includes(value);
					const handleChanged = () => onSelect(value);

					return <CheckboxRow key={value} checked={isChecked} onChange={handleChanged} label={label} />;
				})}
			</div>
		</FilterBox>
	);
}
