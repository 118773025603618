import SaveIcon from "@material-ui/icons/Save";
import DefaultContext from "flexbiz-core/components/auth/Context";
import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Dialog from "../../components/Dialog/Dialog";
import FieldInput from "../../components/Dialog/FieldInput";
import FieldSelectApi from "../../components/Dialog/FieldSelectApi";
import { TaskContext } from "../Task";

export default function TaskDialogAddFull({
  openDialogFull,
  handleCloseDialogFull,
  className,
  data,
}) {
  const context = useContext(DefaultContext);
  const { getDataTask } = useContext(TaskContext);

  const hookForm = useForm({});

  const {
    getValues,
    setValue,
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    // formState: { isSubmitting },
  } = hookForm;

  const onSubmit = async (data) => {
    try {
      // data.so_ct === "" && delete data.so_ct;
      // data.ngay_ct === undefined && (data.ngay_ct = dayjs(new Date()).toDate());

      // await context.apis.asyncPostList(context.userInfo.token, "task", {
      //   ...data,
      // });
      // await getDataTask();

      alert(JSON.stringify(data));
      toast.success("Tạo mới công việc thành công");
    } catch (error) {
      toast.error(error);
    }
  };

  const onSave = (data) =>
    onSubmit(data).then(() => {
      handleCloseDialogFull();
      reset();
    });

  return (
    <Dialog
      open={openDialogFull}
      handleClose={handleCloseDialogFull}
      title={"Creating Task"}
      className={className}
      actions={[
        <button className="btn btn-blue" onClick={handleSubmit(onSave)}>
          <SaveIcon style={{ marginRight: "10px" }} />
          <div>Lưu</div>
        </button>,
      ]}
    >
      <div>
        <FieldInput
          title="Tên công việc"
          check={true}
          inputProps={{
            ...register("ten_cv"),
          }}
        />

        <FieldInput
          title="Phụ trách"
          check={true}
          inputProps={{
            ...register("ten_phu_trach"),
          }}
        />

        {/* <Controller
          name="ten_trang_thai"
          control={control}
          render={({ field: { onChange, value } }) => {
            const item = value
              ? { value, label: getValues("ten_trang_thai") }
              : null;
            const handleChange = (item) => {
              onChange(item?.value || null);
              setValue("ma_trang_thai", item.value || "");
              setValue("ten_trang_thai", item.label || "");
            };

            const query = { ma_ct: "TASK" };

            return (
              <FieldSelectApi
                title="Trạng thái"
                placeholder="Chọn trạng thái"
                api="trangthai"
                q={query}
                valueField="ma_trang_thai"
                labelField="ten_trang_thai"
                value={item}
                onChange={handleChange}
              />
            );
          }}
        /> */}

        {/* <Controller
          name="ten_bp"
          control={control}
          render={({ field: { onChange, value } }) => {
            const item = value ? { value, label: getValues("ten_bp") } : null;
            const handleChange = (item) => {
              onChange(item?.value || null);
              setValue("_id", item.value || "");
              setValue("ten_bp", item.label || "");
            };

            return (
              <FieldSelectApi
                title="Bộ phận"
                placeholder="Chọn bộ phận"
                api="dmbp"
                valueField="_id"
                labelField="ten_bp"
                value={item}
                onChange={handleChange}
              />
            );
          }}
        />

        <Controller
          name="ten_dt"
          control={control}
          render={({ field: { onChange, value } }) => {
            const item = value ? { value, label: getValues("ten_dt") } : null;
            const handleChange = (item) => {
              onChange(item?.value || null);
              setValue("_id", item.value || "");
              setValue("ten_dt", item.label || "");
            };

            return (
              <FieldSelectApi
                title="Dự án"
                placeholder="Chọn dự án"
                api="dmdt"
                valueField="_id"
                labelField="ten_dt"
                value={item}
                onChange={handleChange}
              />
            );
          }}
        />

        <Controller
          name="ten_kh"
          control={control}
          render={({ field: { onChange, value } }) => {
            const item = value ? { value, label: getValues("ten_kh") } : null;
            const handleChange = (item) => {
              onChange(item?.value || null);
              setValue("_id", item.value || "");
              setValue("ten_kh", item.label || "");
            };

            return (
              <FieldSelectApi
                title="Khách hàng"
                placeholder="Chọn khách hàng"
                api="customer"
                valueField="_id"
                labelField="ten_kh"
                value={item}
                onChange={handleChange}
              />
            );
          }}
        />

        <Controller
          name="group_name"
          control={control}
          render={({ field: { onChange, value } }) => {
            const item = value
              ? { value, label: getValues("group_name") }
              : null;
            const handleChange = (item) => {
              onChange(item?.value || null);
              setValue("_id", item.value || "");
              setValue("group_name", item.label || "");
            };

            return (
              <FieldSelectApi
                title="Nhóm công việc"
                placeholder="Chọn nhóm công việc"
                api="dmnhtask"
                valueField="_id"
                labelField="group_name"
                value={item}
                onChange={handleChange}
              />
            );
          }}
        /> */}
      </div>
    </Dialog>
  );
}
