import { Box, CssBaseline } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import DefaultContext from "flexbiz-core/components/auth/Context";
import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	useRef,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Container from "../Container";
import SpaFooter from "./footer/SpaFooter";
import SpaHeader from "./header/SpaHeader";
import SpaMain from "./main/SpaMain";
import "./SpaSales.css";

export const SaleContextSpa = createContext();

const SaleContainerStyled = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	height: 100%;
	padding: 10px;
`;
const SpaFooterComponent = styled(Box)`
	bottom: 0;
	position: fixed;
	width: calc(100% - 130px);
	margin: 0 auto 10px;
`;
const SpaMainComponent = styled(Box)`
	height: calc(100% - 82px);
`;

function SpaSalesContain() {
	const theme = useTheme();
	const params = useParams();
	const context = useContext(DefaultContext);
	const saleSpaMainRef = useRef();

	const [vouchers, setVouchers] = useState([]);
	const [kho, setKho] = useState();
	const [load, setLoad] = useState(0);
	const [ma_ban, setMaBan] = useState();
	const [ma_kho, setMaKho] = useState();
	const [indexVoucher, setIndexVoucher] = useState(0);
	const [voucher, setVoucher] = React.useState(vouchers?.[indexVoucher]);

	useEffect(() => {
		if (params?.ma_ban) {
			setMaBan(params.ma_ban);
		}
		if (params?.ma_kho) {
			setMaKho(params.ma_kho);
		}
	}, [params]);

	const getVoucher = async () => {
		// lấy mã kho và mã bàn từ URL
		// const ma_kho = params.ma_kho.toUpperCase();
		// const ma_ban = params.ma_ban.toUpperCase();
		// khởi tạo voucher
		let vouchers = [];
		// điều kiện để lọc phiếu bán lẻ
		let condition = {
			trang_thai: { $in: ["1", "3"] },
			ma_ban: ma_ban,
			ma_kho: ma_kho,
		};
		context.setProgressStatus(true);
		try {
			// lấy ra kho hiện tại theo mã kho
			const kho = await context.apis.asyncGetData(
				context.userInfo.token,
				"dmkho",
				{ ma_kho: ma_kho },
				null,
				true
			);
			// lấy ra danh sách phiếu bán lẻ với điều kiện bên trên
			let rows = await context.apis.asyncGetList(
				context.userInfo.token,
				"pbl",
				{ condition: condition }
			);
			// nếu có phiếu bán lẻ từ api thì gán phiếu đó cho voucher đã khai báo trước đó
			if (rows.length > 0) {
				vouchers = rows;
			} else {
				// nếu không có pbl trong api thì tự tạo voucher với các trường cơ bản
				const voucher = {
					ma_kho: ma_kho,
					ma_ban: ma_ban,
					so_ct: "PBL",
					trang_thai: "1",
					so_khach: 1,
					details: [],
					tien_ck_hd: 0,
					ty_le_ck_hd: 0,
					exfields: {
						dmckhd: [],
					},
				};
				vouchers.push(voucher);
			}
			// lưu voucher, kho vào state để quản lý
			setVouchers(vouchers);
			if (!vouchers[indexVoucher]) {
				setIndexVoucher(indexVoucher - 1);
			}
			setKho(kho);
			setLoad(load + 1);
			context.setProgressStatus(false);
		} catch (e) {
			context.alert(e.message);
		}
	};
	useEffect(() => {
		if (ma_kho && ma_ban) {
			getVoucher();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indexVoucher, ma_kho, ma_ban]);
	useEffect(() => {
		setVoucher(vouchers[indexVoucher]);
	}, [vouchers, indexVoucher]);

	// delete pbl
	const handleDeletePBL = async (pbl) => {
		try {
			await context.apis.asyncPostList(
				context.userInfo.token,
				"pbl",
				pbl,
			);
		} catch (e) {
			context.alert(e.message);
		}
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<SaleContainerStyled theme={theme}>
				<SaleContextSpa.Provider
					value={{
						vouchers,
						setVouchers,
						indexVoucher,
						setIndexVoucher,
						voucher,
						setVoucher,
						getVoucher,
						kho,
						ma_ban,
						ma_kho,
						load,
						handleDeletePBL,
					}}
				>
					<SpaHeader
						print={saleSpaMainRef.current?.print}
						getVoucher={getVoucher}
					/>
					<SpaMainComponent>
						<SpaMain ref={saleSpaMainRef} />
					</SpaMainComponent>
					<SpaFooterComponent>
						<SpaFooter />
					</SpaFooterComponent>
				</SaleContextSpa.Provider>
			</SaleContainerStyled>
		</React.Fragment>
	);
}

function SpaSales(props) {
	return (
		<Container requireLogin {...props}>
			<SpaSalesContain />
		</Container>
	);
}

export default SpaSales;
