import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import dayjs from 'dayjs';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';
import { TaskContext } from '../Task';

export default function TaskDialogAdd({ openDialog, handleCloseDialog, handleOpenDialogFull }) {
	const context = useContext(DefaultContext);
	const { getDataTask } = useContext(TaskContext);

	const hookForm = useForm({});

	const {
		getValues,
		setValue,
		register,
		resetField,
		formState: { errors },
		control,
		handleSubmit,
	} = hookForm;

	const onSubmit = async (data) => {
		try {
			alert(JSON.stringify(data));

			await context.apis.asyncPostList(context.userInfo.token, 'task', data);

			await getDataTask();

			toast.success('Tạo mới công việc thành công');
		} catch (error) {
			toast.error(error);
		}
	};

	const onSave = (data) =>
		onSubmit(data).then(() => {
			resetField('ten_cv');
			resetField('start_date');
			resetField('due_date');
			handleCloseDialog();
		});

	const defaultStart = dayjs();
	const defaultEnd = defaultStart.add(1, 'hour');

	return (
		<Dialog
			open={openDialog}
			handleClose={handleCloseDialog}
			title={'Thêm công việc'}
			classNameContent={'dialog__content_edit_voucher_body'}
			actions={[
				// <button
				// 	key="fullForm"
				// 	className="btn btn-grey"
				// 	onClick={() => {
				// 		handleCloseDialog();
				// 		handleOpenDialogFull();
				// 	}}
				// >
				// 	<div>View full form</div>
				// </button>,
				<button key="save" className="btn btn-fill" onClick={handleSubmit(onSave)}>
					<SaveIcon style={{ marginRight: '10px' }} />
					<div>Lưu</div>
				</button>,
			]}
		>
			<div className="dialog__content_edit_voucher column-start">
				<div className="dialog__content_edit_voucher__detail">
					<div
						style={{
							width: '50%',
						}}
						className="dialog__content_edit_voucher__detail_left column-start"
					>
						<Controller
							control={control}
							name="ten_cv"
							rules={{ required: 'Vui lòng nhập tên công việc' }}
							render={() => (
								<FieldInput
									title="Tên công việc"
									defaultValue=""
									inputProps={{
										...register('ten_cv'),
									}}
									check
									errorMsg={errors.ten_cv?.message}
								/>
							)}
						/>

						<Controller
							name="ten_trang_thai"
							control={control}
							rules={{ required: 'Vui lòng chọn trạng thái' }}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ten_trang_thai'),
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.ten_trang_thai || null);
									setValue('progress', item.ma_trang_thai || '');
								};

								const query = { ma_ct: 'TASK' };

								return (
									<FieldSelectApi
										title="Trạng thái"
										placeholder="Chọn trạng thái"
										api="trangthai"
										q={query}
										withIdApp={false}
										valueField="ma_trang_thai"
										labelField="ten_trang_thai"
										value={item}
										onChange={handleChange}
										check
										errorMsg={errors.ten_trang_thai?.message}
									/>
								);
							}}
						/>

						<Controller
							name="phu_trach"
							control={control}
							rules={{
								required: 'Vui lòng chọn người phụ trách',
							}}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ten_phu_trach'),
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.email || null);
									setValue('ten_phu_trach', item.name || '');
								};

								return (
									<FieldSelectApi
										title="Phụ trách"
										placeholder="Chọn người phụ trách"
										api="participant"
										valueField="email"
										labelField="name"
										value={item}
										onChange={handleChange}
										check
										errorMsg={errors.phu_trach?.message}
									/>
								);
							}}
						/>

						<Controller
							name="ten_bp"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_bp') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_bp', item.value || '');
									setValue('ten_bp', item.label || '');
								};

								return (
									<FieldSelectApi
										title="Bộ phận"
										placeholder="Chọn bộ phận"
										api="dmbp"
										valueField="ten_bp"
										labelField="ten_bp"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>

						<Controller
							name="ten_nh_cv"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_nh_cv') } : null;
								const handleChange = (item) => {
									onChange(item?.group_name || null);
									setValue('ten_nh_cv', item.group_name || '');
								};

								return (
									<FieldSelectApi
										title="Nhóm công việc"
										placeholder="Chọn nhóm công việc"
										api="dmnhtask"
										valueField="group_name"
										labelField="group_name"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>
					</div>

					<div
						style={{
							width: '50%',
						}}
						className="dialog__content_edit_voucher__detail_right column-start"
					>
						<Grid className="dateTimePicker" spacing={2}>
							<Grid xs={4}>
								<label>Ngày bắt đầu</label>
							</Grid>

							<TextField
								id="datetime-local"
								type="datetime-local"
								defaultValue={defaultStart.format('YYYY-MM-DD HH:mm')}
								className="fieldDateTime"
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									...register('start_date'),
								}}
							/>
						</Grid>

						<Grid className="dateTimePicker" spacing={2}>
							<Grid xs={4}>
								<label>Ngày kết thúc</label>
							</Grid>

							<TextField
								id="datetime-local"
								type="datetime-local"
								defaultValue={defaultEnd.format('YYYY-MM-DD HH:mm')}
								className="fieldDateTime"
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									...register('due_date'),
								}}
							/>
						</Grid>

						<Controller
							name="ten_dt"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_dt') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_dt', item.value || '');
									setValue('ten_dt', item.label || '');
								};

								return (
									<FieldSelectApi
										title="Dự án"
										placeholder="Chọn dự án"
										api="dmdt"
										valueField="ten_dt"
										labelField="ten_dt"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>

						<Controller
							name="ten_kh"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_kh') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ten_kh', item.value || '');
									setValue('ten_kh', item.label || '');
								};

								return (
									<FieldSelectApi
										title="Khách hàng"
										placeholder="Chọn khách hàng"
										api="customer"
										valueField="ten_kh"
										labelField="ten_kh"
										value={item}
										onChange={handleChange}
									/>
								);
							}}
						/>
					</div>
				</div>
			</div>

			<div>
				<p className="field_input_title" style={{ marginBottom: '5px' }}>
					Mô tả
				</p>
				<Controller
					name="mieu_ta"
					control={control}
					render={({ field: { value, onChange } }) => (
						<CKEditor
							editor={ClassicEditor}
							data={value || ''}
							onChange={(_event, editor) => {
								const data = editor.getData();
								onChange(data);
							}}
						/>
					)}
				/>
			</div>
		</Dialog>
	);
}
