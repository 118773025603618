import { Grid } from '@material-ui/core';
import { DeleteRounded, SyncAltRounded } from '@material-ui/icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useCore } from '../../../../../../hooks';
import { formatDateDisplay, formatDateValue } from '../../../../../../utils/helpers';
import FieldBox from '../../../../components/Dialog/FieldBox';
import FieldInput from '../../../../components/Dialog/FieldInput';
import styles from '../../../AppConfig.module.css';

export default function InfoTab({ hookForm }) {
	const core = useCore();
	const { register, control } = hookForm;
	const inputRef = React.useRef(null);

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<FieldInput title='Tên công ty' inputProps={{ ...register('name') }} />
				<FieldInput title='Tên tiếng anh' inputProps={{ ...register('name_en') }} />
				<FieldInput title='Tên viết tắt' inputProps={{ ...register('short_name') }} />
				<FieldInput title='Địa chỉ' inputProps={{ ...register('address') }} />
				<FieldInput title='Địa chỉ tiếng anh' inputProps={{ ...register('address_en') }} />
				<FieldInput title='Điện thoại' inputProps={{ ...register('phone') }} />
				<FieldInput title='Email' inputProps={{ ...register('email') }} />
			</Grid>

			<Grid item xs={6}>
				<Controller
					name='ngay_dn'
					control={control}
					render={({ field: { value, onChange } }) => {
						value = formatDateValue(value);
						return (
							<FieldInput title='Ngày đầu năm tài chính' value={value} onChange={onChange} type='date' />
						);
					}}
				/>
				<Controller
					name='ngay_ky1'
					control={control}
					render={({ field: { value, onChange } }) => {
						value = formatDateValue(value);
						return (
							<FieldInput
								title='Ngày sử dụng chương trình'
								value={value}
								onChange={onChange}
								type='date'
							/>
						);
					}}
				/>
				<Controller
					name='ngay_ks'
					control={control}
					render={({ field: { value, onChange } }) => {
						value = formatDateValue(value);
						return <FieldInput title='Ngày khoá sổ' value={value} onChange={onChange} type='date' />;
					}}
				/>
				<FieldInput title='Mã số thuế' inputProps={{ ...register('ma_so_thue') }} />
				<FieldBox
					title='Logo'
					titleProps={{ style: { alignSelf: 'flex-start' } }}
					contentProps={{ style: { jusifySelf: 'flex-start' } }}
				>
					<Controller
						name='logo'
						control={control}
						render={({ field: { value, onChange } }) => {
							const logoSrc = core.getFileUrl(value);
							const handleDeleteLogo = () => onChange(null);
							const changeLogo = async (e) => {
								const file = e.target.files[0];
								if (!file) return; // user cancel

								const { fileUrl } = await core.uploadFile(file, { folder: 'logo' });
								onChange(fileUrl);
							};

							return (
								<div className={styles.logoContainer}>
									<input type='file' accept='image/*' onChange={changeLogo} hidden ref={inputRef} />
									{logoSrc && <img className={styles.logoImage} src={logoSrc} alt='Ảnh logo' />}

									<div className={styles.logoAction}>
										<button className='btn btn-fill' onClick={() => inputRef.current.click()}>
											{logoSrc ? <SyncAltRounded /> : 'Chọn ảnh'}
										</button>

										{logoSrc && (
											<button onClick={handleDeleteLogo} className='btn btn-danger'>
												<DeleteRounded />
											</button>
										)}
									</div>
								</div>
							);
						}}
					/>
				</FieldBox>
			</Grid>
		</Grid>
	);
}
