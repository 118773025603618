import React, { useState, useRef } from 'react';
import './SubTask.css';
import { toast } from 'react-hot-toast';

export default function SubTask({ data, onChange, onEdit, onDelete }) {
	const [show, setShow] = useState(false);
	const [todo, setTodo] = useState(data);
	const inputRef = useRef();

	const onKeyDown = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();

			onEdit(todo);

			inputRef.current.blur();
		}
	};

	const handleFocus = () => {
		if (!todo.sel) {
			inputRef.current.focus();
		} else {
			toast('Không thể đổi tên do đã hoàn thành');
		}
	};

	return (
		<div className="subtask_component" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
			<div className="subtask_component__content">
				<input
					type="checkbox"
					id={todo.line}
					name={todo.line}
					value={todo.line}
					checked={todo.sel}
					onChange={() => {
						setTodo({ ...todo, sel: !todo.sel });
						onChange();
					}}
				/>

				<input
					ref={inputRef}
					type="text"
					style={{ color: show ? '#60b1fc' : 'unset' }}
					value={todo.title}
					onChange={(e) => setTodo({ ...todo, title: e.target.value })}
					readOnly={todo.sel}
					onKeyDown={onKeyDown}
				/>
			</div>

			{show ? (
				<div className="subtask_component__action">
					<i className="fas fa-pencil-alt" title="Sửa" onClick={handleFocus}></i>
					<i className="fas fa-minus-circle" title="Xóa" onClick={onDelete}></i>
				</div>
			) : null}
		</div>
	);
}
