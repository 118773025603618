import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dsbook from './components/dsbook';
import { withRouter } from 'react-router-dom';
import AuthContext from 'flexbiz-core/components/auth/Context';
import withMediaQuery from 'flexbiz-core/components/withMediaQuery';
import RequireLogin from 'flexbiz-core/components/RequireLogin';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Typography from '@material-ui/core/Typography';
import Container from 'flexbiz-core/components/Container';
class Booking extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {}
	render() {
		return <Dsbook {...this.props} />;
	}
}
Booking.contextType = AuthContext;
Booking.propTypes = {
	match: PropTypes.any,
	history: PropTypes.any,
	location: PropTypes.any,
	mediaQueryMatches: PropTypes.bool,
};
class BookingPage extends React.PureComponent {
	render() {
		return (
			<Container {...this.props} showDrawerIfIsDesktop={false}>
				<Booking {...this.props} />
			</Container>
		);
	}
}
export default withRouter(withMediaQuery('(max-width:480px)')(BookingPage));
