export const progressData = [
	{
		label: 'Chưa bắt đầu',
		value: 0,
		color: '#9e9e9e',
	},
	{
		label: 'Đang thực hiện',
		value: 1,
		color: '#2196f3',
	},
	{
		label: 'Đã hoàn thành',
		value: 2,
		color: '#4caf50',
	},
	{
		label: 'Tạm dừng',
		value: 3,
		color: '#ff9800',
	},
	{
		label: 'Chờ',
		value: 4,
		color: '#ffeb3b',
	},
];
