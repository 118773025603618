import React from 'react';
import FilterAutocomplete from '../components/filter/FilterAutocomplete';
import { Typography } from '@material-ui/core';
import FilterSelectTimeFromTo from '../components/filter/FilterSelectTimeFromTo';
import moment from 'moment';
import FilterSearch from '../components/filter/FilterSearch';

function EvoucherFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		ten: '',
		ma: '',
		startDate: null,
		endDate: moment(),
	});
	// handle start date change
	const handleStartDatechange = (value) => {
		setFilter({ ...filter, startDate: moment(value) });
	};
	// handle end date change
	const handleEndDateChange = (value) => {
		setFilter({ ...filter, endDate: moment(value) });
	};

	React.useEffect(() => {
		const q = { $or: [] };
		if (filter.ten) {
			q.$or.push({ ten: { $regex: filter.ten, $options: 'i' } });
		}
		if (filter.ma) {
			q.$or.push({ ma: { $regex: filter.ma, $options: 'i' } });
		}
		if (filter.startDate && filter.endDate) {
			q.hieu_luc_tu = { $gte: filter.startDate };
			q.hieu_luc_den = { $lte: filter.endDate };
		}
		onChange(q);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	return (
		<div>
			<FilterSearch title="Mã evoucher" onSearch={(value) => setFilter({ ...filter, ma: value })} />
			<FilterSearch title="Tên evoucher" onSearch={(value) => setFilter({ ...filter, ten: value })} />
			<FilterSelectTimeFromTo
				startDay={filter.startDate}
				endDay={filter.endDate}
				setStartDay={handleStartDatechange}
				setEndDay={handleEndDateChange}
			/>
		</div>
	);
}

export default EvoucherFilter;
