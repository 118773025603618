import React from 'react';
import './Voucher.css';
import VoucherBC1 from './VoucherBC1/VoucherBC1';
import VoucherPT1 from './VoucherPT1/VoucherPT1';
import VoucherPN1 from './VoucherPN1/VoucherPN1';
import VoucherPC1 from './VoucherPC1/VoucherPC1';
import VoucherBN1 from './VoucherBN1/VoucherBN1';
import VoucherPKT from './VoucherPKT/VoucherPKT';
import VoucherPT5 from './VoucherPT5/VoucherPT5';
import VoucherPN2 from './VoucherPN2/VoucherPN2';
import VoucherPNK from './VoucherPNK/VoucherPNK';
import VoucherHD7 from './VoucherHD7/VoucherHD7';
import VoucherHD3 from './VoucherHD3/VoucherHD3';

function VoucherManager() {
	const url = window.location.href;
	const urlArr = url.split('/');
	const urlLast = urlArr[urlArr.length - 1];
	return urlLast === 'pt1' ? (
		<VoucherPT1 title={'Phiếu thu'} api={'pt1'} />
	) : urlLast === 'pt5' ? (
		<VoucherPT5 title={'Nộp tiền mặt vào ví'} api={'pt5'} />
	) : urlLast === 'pn1' ? (
		<VoucherPN1 title={'Phiếu nhập'} api={'pn1'} />
	) : urlLast === 'pn2' ? (
		<VoucherPN2 title={'Phiếu mua dịch vụ'} api={'pn2'} />
	) : urlLast === 'bc1' ? (
		<VoucherBC1 title={'Phiếu báo có'} api={'bc1'} />
	) : urlLast === 'pc1' ? (
		<VoucherPC1 title={'Phiếu chi'} api={'pc1'} />
	) : urlLast === 'bn1' ? (
		<VoucherBN1 title={'Phiếu báo nhập'} api={'bn1'} />
	) : urlLast === 'pkt' ? (
		<VoucherPKT title={'Phiếu kế toán'} api={'pkt'} />
	) : urlLast === 'pnk' ? (
		<VoucherPNK title={'Nhập kho nội bộ'} api={'pnk'} />
	) : urlLast === 'hd7' ? (
		<VoucherHD7 title={'Đổi trả hàng bán lẻ'} api={'hd7'} />
	) : urlLast === 'hd3' ? (
		<VoucherHD3 title={'Phiếu nhập hàng bán bị trả lại'} api={'hd3'} />
	) : (
		<VoucherPT1 title={'Chứng từ'} api={'pt'} />
	);
}

export default VoucherManager;
