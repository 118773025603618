import React from "react";
import { Drawer } from "@material-ui/core";

function DrawerBase({
  children,
  anchor = "right",
  open,
  handleClose,
  ...props
}) {
  let paperBorderRadius = "";
  switch (anchor) {
    case "right":
      paperBorderRadius = "20px 0 0 20px";
      break;
    case "left":
      paperBorderRadius = "0 20px 20px 0";
      break;
    case "top":
      paperBorderRadius = "0 0 20px 20px";
      break;
    case "bottom":
      paperBorderRadius = "20px 20px 0 0";
      break;

    default:
      break;
  }

  return (
    <Drawer
      {...props}
      PaperProps={{ style: { borderRadius: paperBorderRadius } }}
      anchor={anchor}
      open={open}
      onClose={handleClose}
    >
      {children}
    </Drawer>
  );
}

export default DrawerBase;
