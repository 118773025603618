import { CloudDownloadRounded } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import React from 'react';
import { useCore } from '../../../../../../../hooks/useCore';
import { BASE_URL } from '../../../../../../../utils/constants';
import Dialog from '../../../../Dialog/Dialog';
import TableContainer from '../../../TableContainer';

export default function DownloadTemplateDialog({ code, open, handleClose }) {
	const core = useCore();
	const [loading, setLoading] = React.useState(false);
	const [templates, setTemplates] = React.useState([]);

	// pagination
	const [page, setPage] = React.useState(1);
	const [limit, setLimit] = React.useState(20);

	const handlePaginationChange = (page, limit) => {
		setPage(page);
		setLimit(limit);
	};

	const [total, setTotal] = React.useState(0);

	// get templates
	const getTemplates = React.useCallback(async () => {
		setLoading(true);

		try {
			const [res, countRes] = await Promise.all([
				core.apiCall({
					withIdApp: false,
					endpoint: '/importexceltemplate',
					method: 'GET',
					params: { q: { code }, limit, page },
				}),
				core.apiCall({
					withIdApp: false,
					endpoint: '/importexceltemplate',
					method: 'GET',
					params: { q: { code }, count: 1 },
				}),
			]);

			setTemplates(res.data);
			setTotal(countRes.data.rows_number);
		} catch (error) {
			core.contextAlert(error);
		}

		setLoading(false);
	}, [limit, page]);

	React.useEffect(() => {
		if (open) getTemplates();
	}, [open, getTemplates]);

	const handleDownloadTemplate = (template) => saveAs(BASE_URL + template.file, template.title);

	const columns = [
		{
			name: 'STT',
			selector: (_row, index) => index + 1,
			width: '80px',
		},
		{
			name: 'Tên',
			selector: (row) => row.title,
		},
		{
			name: 'File mẫu',
			selector: (row) => (
				<button className="btn btn-fill" onClick={() => handleDownloadTemplate(row)}>
					<CloudDownloadRounded />
				</button>
			),
			right: true,
		},
	];

	return (
		<Dialog open={open} handleClose={handleClose} loading={loading} title="Tải file mẫu nhập excel">
			<TableContainer
				data={templates}
				columns={columns}
				onRowClick={handleDownloadTemplate}
				page={page}
				limit={limit}
				totalRows={total}
				handlePaginationChange={handlePaginationChange}
			/>
		</Dialog>
	);
}
