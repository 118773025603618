import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useState } from 'react';
import Container from '../Container';
import LayoutContainer from '../components/LayoutContainer';
import TableContainer from '../components/table/TableContainer';
import SalesChannelFilter from './SalesChannelFilter';
import SalesChannelDialog from './Dialog/SalesChannelDialog';
import SalesChannelItemDialog from './Dialog/SalesChannelItemDialog';

const columns = [
	{
		name: 'Mã kênh bán hàng',
		selector: (row) => row.id,
		sortable: true,
	},
	{
		name: 'Tên kênh bán hàng',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Tổng số đơn hàng',
		selector: (row) => row.total,
		sortable: true,
	},
	{
		name: 'Hình ảnh',
		selector: (row) => <img src={row.image} width='50%' height='50%' alt='MDN logo' />,
		sortable: true,
	},
	{
		name: 'Ghi chú',
		selector: (row) => row.note,
		sortable: true,
	},
];

const data = [
	{
		id: 1,
		name: 'Facebook',
		total: '100',
		image: 'https://waki.vn/wp-content/uploads/2020/03/Pasted-into-60-%E1%BA%A3nh-spa-%C4%91%E1%BA%B9p-nh%E1%BA%A5t-n%C4%83m-2020-9.png',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 1',
			desc: 'Đây là nhân viên của phòng 1',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 2,
		name: 'Tiktok',
		total: '100',
		image: 'https://waki.vn/wp-content/uploads/2020/03/Pasted-into-60-%E1%BA%A3nh-spa-%C4%91%E1%BA%B9p-nh%E1%BA%A5t-n%C4%83m-2020-9.png',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 2',
			desc: 'Đây là nhân viên của phòng 2',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 3,
		name: 'Instagram',
		total: '100',
		image: 'https://waki.vn/wp-content/uploads/2020/03/Pasted-into-60-%E1%BA%A3nh-spa-%C4%91%E1%BA%B9p-nh%E1%BA%A5t-n%C4%83m-2020-9.png',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 3',
			desc: 'Đây là nhân viên của phòng 3',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 4,
		name: 'Youtube',
		total: '100',
		image: 'https://waki.vn/wp-content/uploads/2020/03/Pasted-into-60-%E1%BA%A3nh-spa-%C4%91%E1%BA%B9p-nh%E1%BA%A5t-n%C4%83m-2020-9.png',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 4',
			desc: 'Đây là nhân viên của phòng 4',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 5,
		name: 'Shopee',
		total: '100',
		image: 'https://waki.vn/wp-content/uploads/2020/03/Pasted-into-60-%E1%BA%A3nh-spa-%C4%91%E1%BA%B9p-nh%E1%BA%A5t-n%C4%83m-2020-9.png',
		note: 'Ghi chú',
		description: {
			group: 'Nhóm 5',
			desc: 'Đây là nhân viên của phòng 5',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
];

function SalesChannelContent() {
	const [open, setOpen] = useState(false);
	const [openDialogItem, setOpenDialogItem] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		console.log('close');
		setOpen(false);
	};

	const handleOpenDialogItem = () => {
		setOpenDialogItem(true);
	};

	const handleCloseDialogItem = () => {
		setOpenDialogItem(false);
	};
	return (
		<LayoutContainer FilterLeft={<SalesChannelFilter />}>
			<>
				<TableContainer
					columns={columns}
					selectableRows={true}
					title={'Kênh bán hàng'}
					data={data}
					actions={[
						<button
							onClick={() => {
								handleOpen();
							}}
						>
							<AddIcon />
							<span className='item_title'>Thêm kênh bán hàng</span>
						</button>,
						<button>
							<GetAppIcon />
							<span className='item_title'>Import</span>
						</button>,
						<button>
							<PublishIcon />
							<span className='item_title'>Xuất file</span>
						</button>,
					]}
				/>
				<SalesChannelDialog open={open} handleClose={handleClose} handleOpenDialogItem={handleOpenDialogItem} />

				<SalesChannelItemDialog
					open={openDialogItem}
					handleClose={handleCloseDialogItem}
					className={'dialog_item'}
				/>
			</>
		</LayoutContainer>
	);
}

const SalesChannel = (props) => {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<SalesChannelContent />
		</Container>
	);
};

export default SalesChannel;
