import { CloudDownloadRounded } from '@material-ui/icons';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useCore } from '../../../../../../../hooks';
import { BASE_URL } from '../../../../../../../utils/constants';
import Dialog from '../../../../Dialog/Dialog';
import TableContainer from '../../../TableContainer';

export default function ExportDialog({ open, handleClose, code, params }) {
	const core = useCore();
	const [loading, setLoading] = React.useState(false);
	const [templates, setTemplates] = React.useState([]);

	// pagination
	const [page, setPage] = React.useState(1);
	const [limit, setLimit] = React.useState(20);

	const handlePaginationChange = (page, limit) => {
		setPage(page);
		setLimit(limit);
	};

	const [total, setTotal] = React.useState(0);

	// get templates
	const getTemplates = React.useCallback(async () => {
		setLoading(true);

		try {
			const [res, countRes] = await Promise.all([
				core.apiCall({
					withIdApp: false,
					endpoint: '/exportexceltemplate',
					method: 'GET',
					params: {
						q: { code },
						limit,
						page,
					},
				}),
				core.apiCall({
					withIdApp: false,
					endpoint: '/exportexceltemplate',
					method: 'GET',
					params: {
						q: { code },
						count: 1,
					},
				}),
			]);

			setTemplates(res.data);
			setTotal(countRes.data.rows_number);
		} catch (error) {
			core.contextAlert(error);
		}

		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [limit, page]);

	React.useEffect(() => {
		if (open) getTemplates();
	}, [open, getTemplates]);

	const handleDownloadTemplate = (template) => saveAs(BASE_URL + template.file, template.title);

	const hanldeExportExcel = async (template) => {
		handleClose();

		const toastId = toast.loading('Đang xuất file...');
		try {
			const res = await core.apiCall({
				endpoint: 'dmvt',
				method: 'GET',
				params: {
					type_data: 'xlsx',
					id_template: template._id,
					...params,
				},
				responseType: 'blob',
			});

			const fileName = `Danh mục vật tư ${dayjs().format('DD-MM-YYYY HH:mm:ss')}.xlsx`;

			saveAs(res.data, fileName);

			toast.success('Xuất file thành công', { id: toastId });
		} catch (error) {
			toast.error('Xuất file thất bại!', { id: toastId });
		}
	};

	const columns = [
		{
			name: 'STT',
			selector: (_row, index) => index + 1,
			width: '80px',
		},
		{
			name: 'Tên',
			selector: (row) => row.title,
		},
		{
			name: 'File mẫu',
			selector: (row) => (
				<button className='btn btn-fill' onClick={() => handleDownloadTemplate(row)}>
					<CloudDownloadRounded />
				</button>
			),
			right: true,
		},
		{
			name: 'Xuất excel',
			selector: (row) => (
				<button className='btn btn-fill' onClick={() => hanldeExportExcel(row)}>
					<CloudDownloadRounded />
				</button>
			),
			right: true,
		},
	];

	return (
		<Dialog open={open} handleClose={handleClose} loading={loading} title='Xuất excel'>
			<TableContainer
				data={templates}
				columns={columns}
				onRowClick={hanldeExportExcel}
				page={page}
				limit={limit}
				totalRows={total}
				handlePaginationChange={handlePaginationChange}
			/>
		</Dialog>
	);
}
