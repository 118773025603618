import React, { useRef } from 'react';
import FieldSelectApi from '../../../components/Dialog/FieldSelectApi';
import { Controller } from 'react-hook-form';
import FieldInput from '../../../components/Dialog/FieldInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { vi } from 'date-fns/locale';

export default function TabInfomation({ getValues, setValue, register, errors, control, setIsFieldNt }) {
	const datePickerRef = useRef(null);
	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {
			// Thực hiện đóng datepicker khi nhấn tab
			datePickerRef.current.setOpen(false);
		}
		if (e.key === 'Enter') {
			// Thực hiện đóng datepicker và focus vào input tiếp theo khi nhấn enter
			datePickerRef.current.setOpen(false);
			document.getElementById('ma_ht1').focus();
		}
	};
	return (
		<div className="dialog__content_edit_voucher column-start">
			<div className="dialog__content_edit_voucher__detail">
				<div className="dialog__content_edit_voucher__detail_left column-start">
					<FieldInput
						title="Số chứng từ"
						inputProps={{
							...register('so_ct'),
						}}
					/>
					{/* <FieldInput
						title="Loại chứng từ"
						check={true}
						inputProps={{
							...register("so_ct"),
						}}
					/> */}
					<div className="flex-start box_date_picker_payment">
						<div className="title_date_picker_payment">
							Ngày hạch toán
							<span className="start_check_dialog_field">*</span>
						</div>
						<Controller
							control={control}
							name="ngay_ct"
							render={({ field: { onChange, value } }) => (
								<DatePicker
									ref={datePickerRef}
									onKeyDown={handleKeyDown}
									placeholderText="Chọn ngày"
									className="date_picker_payment"
									selected={dayjs(value).toDate()}
									onChange={onChange}
									dateFormat={'dd/MM/yyyy'}
									locale={vi}
								/>
							)}
						/>
					</div>
					<FieldInput
						id={'ma_ht1'}
						title="Mã hạch toán 1"
						// check={true}
						// inputProps={{
						// 	...register("so_ct"),
						// }}
					/>
					{/* <FieldInput
						title="Mã hạch toán 2"
						check={true}
						inputProps={{
							...register("so_ct"),
						}}
					/> */}
					<Controller
						name="ma_nt"
						control={control}
						rules={{ required: 'Vui lòng chọn ngoại tệ' }}
						render={({ field: { onChange, value } }) => {
							const item = value
								? {
										value,
										label: getValues('ma_nt') + ' - (Tỷ giá: ' + getValues('ty_gia') + ')',
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  }
								: null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ma_nt', item?.ma_nt || '');
								setValue('ty_gia', item?.ty_gia || '');
								setIsFieldNt({
									ma_nt: item?.ma_nt || '',
									ty_gia: item?.ty_gia || '',
								});
							};

							return (
								<FieldSelectApi
									title="Ngoại tệ"
									placeholder="Chọn ngoại tệ"
									api="currency"
									check
									errorMsg={errors.ma_nt?.message}
									valueField="ma_nt"
									labelField="ty_gia"
									value={item}
									q={{ status: true }}
									onChange={handleChange}
									getLabel={(item) => {
										return `${item?.ma_nt} - (Tỷ giá: ${item?.ty_gia})`;
									}}
									isDisabled={getValues('_id') ? true : false}
								/>
							);
						}}
					/>
				</div>
				<div className="dialog__content_edit_voucher__detail_right column-start">
					<Controller
						name="ma_nv"
						control={control}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_nv') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ma_nv', item?.value || '');
								setValue('ten_nv', item?.label || '');
							};

							return (
								<FieldSelectApi
									title="Nhân viên"
									placeholder="Nhập nhân viên"
									api="dmnv"
									valueField="ma_nv"
									labelField="ten_nv"
									value={item}
									q={{ status: true }}
									t={1}
									onChange={handleChange}
								/>
							);
						}}
					/>
					<Controller
						name="ma_kh"
						control={control}
						rules={{ required: 'Vui lòng chọn nhà cung cấp' }}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_kh') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ma_kh', item?.ma_kh || '');
								setValue('ten_kh', item?.ten_kh || '');
							};

							return (
								<FieldSelectApi
									title="Nhà cung cấp"
									placeholder="Chọn nhà cung cấp"
									api="customer"
									errorMsg={errors.ma_kh?.message}
									check
									valueField="ma_kh"
									labelField="ten_kh"
									value={item}
									q={{ status: true }}
									t={1}
									onChange={handleChange}
								/>
							);
						}}
					/>
					<Controller
						name="ma_dvcs"
						control={control}
						rules={{ required: 'Vui lòng chọn đơn vị' }}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_dvcs') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ma_dvcs', item?._id || '');
								setValue('ten_dvcs', item?.ten_dvcs || '');
							};

							return (
								<FieldSelectApi
									title="Đơn vị"
									placeholder="Nhập đơn vị"
									errorMsg={errors.ma_dvcs?.message}
									check
									api="dvcs"
									valueField="ma_dvcs"
									labelField="ten_dvcs"
									value={item}
									q={{ status: true }}
									t={1}
									onChange={handleChange}
								/>
							);
						}}
					/>
					<Controller
						name="ma_kho"
						control={control}
						render={({ field: { onChange, value } }) => {
							const item = value
								? {
										value,
										label: getValues('ma_kho'),
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  }
								: null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ma_kho', item?.ma_kho || '');
								setValue('ten_kho', item?.ten_kho || '');
							};

							return (
								<FieldSelectApi
									title="Chi nhánh"
									placeholder="Nhập chi nhánh"
									api="dmkho"
									valueField="ma_kho"
									labelField="ma_kho"
									onChange={handleChange}
									value={item}
									q={{ status: true }}
									t={1}
									getLabel={(item) => `${item?.ma_kho} - ${item?.ten_kho}`}
								/>
							);
						}}
					/>
					<Controller
						name="tk_co"
						control={control}
						rules={{ required: 'Vui lòng chọn tài khoản có' }}
						render={({ field: { onChange, value } }) => {
							const item = value
								? {
										value,
										label: getValues('tk_co') + ' - ' + getValues('ten_tk_co'),
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  }
								: null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('tk_co', item?.tk || '');
								setValue('ten_tk_co', item?.ten_tk || '');
							};

							return (
								<FieldSelectApi
									title="Tài khoản có"
									placeholder="Chọn tài khoản có"
									check
									api="account"
									errorMsg={errors.tk_no?.message}
									valueField="tk"
									labelField="ten_tk"
									onChange={handleChange}
									value={item}
									q={{ status: true }}
									t={1}
									prefix={true}
									getLabel={(item) => {
										return `${item?.tk} - ${item?.ten_tk}`;
									}}
								/>
							);
						}}
					/>
					<FieldInput
						title="Diễn giải"
						inputProps={{
							...register('dien_giai'),
						}}
					/>
				</div>
			</div>
		</div>
	);
}
