import React from "react";
import FilterAutocomplete from "../components/filter/FilterAutocomplete";
import { Typography } from "@material-ui/core";
import FilterSelectTimeFromTo from "../components/filter/FilterSelectTimeFromTo";
import moment from "moment";

function PriceFilter({ onChange }) {
  const [filter, setFilter] = React.useState({
    product: null,
    kho: null,
    ban: null,
    startDate: null,
    endDate: moment(),
  });
  // handle select product
  const handleSelectProduct = (product) => {
    setFilter({ ...filter, product });
  };
  // handle select kho
  const handleSelectKho = (kho) => {
    setFilter({ ...filter, kho });
  };
  // handle select ban
  const handleSelectBan = (ban) => {
    setFilter({ ...filter, ban });
  };
  // handle start date change
  const handleStartDatechange = (value) => {
    setFilter({ ...filter, startDate: moment(value) });
  };
  // handle end date change
  const handleEndDateChange = (value) => {
    setFilter({ ...filter, endDate: moment(value) });
  };

  React.useEffect(() => {
    const q = {};
    if (filter.product) {
      q.ma_vt = { $regex: filter.product.ma_vt, $options: "i" };
    }
    if (filter.kho) {
      q.ma_kho = { $regex: filter.kho.ma_kho, $options: "i" };
    }
    if (filter.ban) {
      q.ma_ban = { $regex: filter.ban.ma_ban, $options: "i" };
    }
    if (filter.startDate && filter.endDate) {
      q.hieu_luc_tu = { $gte: filter.startDate, $lte: filter.endDate };
    }
    onChange(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div>
      <FilterAutocomplete
        title="Sản phẩm"
        apiCode="dmvt"
        selectedValue={filter.product}
        onSelect={handleSelectProduct}
        getOptionLabel={(option) => option.ten_vt}
        searchFileds={["ma_vt", "ten_vt"]}
        renderOption={(option) => {
          return (
            <Typography style={{ fontSize: "13px" }}>
              {option.ten_vt} - {option.ma_vt}
            </Typography>
          );
        }}
      />
      <FilterAutocomplete
        title="Cửa hàng"
        apiCode="dmkho"
        selectedValue={filter.kho}
        onSelect={handleSelectKho}
        getOptionLabel={(option) => option.ten_kho}
        searchFileds={["ma_kho", "ten_kho"]}
        renderOption={(option) => {
          return (
            <Typography style={{ fontSize: "13px" }}>
              {option.ten_kho} - {option.ma_kho}
            </Typography>
          );
        }}
      />
      <FilterAutocomplete
        title="Bàn"
        apiCode="dmban"
        selectedValue={filter.ban}
        onSelect={handleSelectBan}
        getOptionLabel={(option) => option.ten_ban}
        searchFileds={["ten_ban", "ma_ban"]}
        renderOption={(option) => {
          return (
            <Typography style={{ fontSize: "13px" }}>
              {option.ten_ban} - {option.ma_ban}
            </Typography>
          );
        }}
      />
      <FilterSelectTimeFromTo
        startDay={filter.startDate}
        endDay={filter.endDate}
        setStartDay={handleStartDatechange}
        setEndDay={handleEndDateChange}
      />
    </div>
  );
}

export default PriceFilter;
