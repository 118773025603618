import React from 'react';
import FilterAutocomplete from '../components/filter/FilterAutocomplete';
import { Typography } from '@material-ui/core';
import FilterSelectTimeFromTo from '../components/filter/FilterSelectTimeFromTo';
import moment from 'moment';
import FilterSearch from '../components/filter/FilterSearch';

function DiscountFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		ma_chietkhau: '',
		ten_chietkhau: '',
		product: null,
		customer: null,
		groupCustomer: null,
		startDate: null,
		endDate: moment(),
	});

	// handle ma chiet khau change
	const handleMaChietKhauChange = (value) => {
		setFilter({ ...filter, ma_chietkhau: value });
	};
	// handle ma chiet khau change
	const handleTenChietKhauChange = (value) => {
		setFilter({ ...filter, ten_chietkhau: value });
	};
	// handle selected product
	const handleSelectProduct = (value) => {
		setFilter({ ...filter, product: value });
	};
	// handle selected customer
	const handleSelectCustomer = (value) => {
		setFilter({ ...filter, customer: value });
	};
	// handle selected customer
	const handleSelectGroupCustomer = (value) => {
		setFilter({ ...filter, groupCustomer: value });
	};

	React.useEffect(() => {
		const q = {};
		if (filter.ma_chietkhau) {
			q.ma_chietkhau = { $regex: filter.ma_chietkhau, $options: 'i' };
		}
		if (filter.ten_chietkhau) {
			q.ten_chietkhau = { $regex: filter.ten_chietkhau, $options: 'i' };
		}
		if (filter.product) {
			q.ma_vt = { $regex: filter.product.ma_vt, $options: 'i' };
		}
		if (filter.customer) {
			q.ma_kh = { $regex: filter.customer.ma_kh, $options: 'i' };
		}
		if (filter.groupCustomer) {
			q.nh_kh = { $regex: filter.groupCustomer._id, $options: 'i' };
		}
		if (filter.startDate && filter.endDate) {
			q.hieu_luc_tu = { $gte: filter.startDate };
			q.hieu_luc_den = { $gte: filter.endDate };
		}
		onChange(q);
	}, [filter]);

	return (
		<div>
			<FilterSearch title="Mã chiết khấu" onSearch={handleMaChietKhauChange} />
			<FilterSearch title="Tên chiết khấu" onSearch={handleTenChietKhauChange} />
			<FilterAutocomplete
				title="Sản phẩm"
				apiCode="dmvt"
				selectedValue={filter.product}
				onSelect={handleSelectProduct}
				getOptionLabel={(option) => option.ten_vt}
				searchFileds={['ma_vt', 'ten_vt']}
				renderOption={(option) => {
					return (
						<Typography style={{ fontSize: '13px' }}>
							{option.ten_vt} - {option.ma_vt}
						</Typography>
					);
				}}
			/>
			<FilterAutocomplete
				title="Khách hàng"
				apiCode="customer"
				selectedValue={filter.customer}
				onSelect={handleSelectCustomer}
				getOptionLabel={(option) => option.ten_kh}
				searchFileds={['ma_kh', 'ten_kh']}
				renderOption={(option) => {
					return (
						<Typography style={{ fontSize: '13px' }}>
							{option.ten_kh} - {option.ma_kh}
						</Typography>
					);
				}}
			/>
			<FilterAutocomplete
				title="Nhóm khách hàng"
				apiCode="group"
				selectedValue={filter.groupCustomer}
				onSelect={handleSelectGroupCustomer}
				getOptionLabel={(option) => option.group_name}
				q={{ group_type: 'CUSTOMER' }}
				searchFileds={['group_name']}
				renderOption={(option) => {
					return <Typography style={{ fontSize: '13px' }}>{option.group_name}</Typography>;
				}}
			/>
			<FilterSelectTimeFromTo
				startDay={filter.startDate}
				endDay={filter.endDate}
				setStartDay={(value) => setFilter({ ...filter, startDate: value })}
				setEndDay={(value) => setFilter({ ...filter, endDate: value })}
			/>
		</div>
	);
}

export default DiscountFilter;
