import { Button, Dropdown } from 'antd';
import React from 'react';
import styles from './VComponent.module.css';
import { HiChevronDown, HiOutlineFilter } from 'react-icons/hi';
import { ClickAwayListener } from '@material-ui/core';

/**
 * Return a new button component with the given className and props.
 *
 * @param {import('antd').ButtonProps} props - The props to pass to the button component.
 * @returns {JSX.Element} A new button component.
 */
const VButton = (props) => <Button {...props} className={[styles.v_button, props.className].join(' ')} />;

/**
 * Renders a button with a dropdown menu. The button displays an icon and a title and triggers an onClick
 * event when clicked. The dropdown menu is opened on click and contains additional buttons rendered using
 * the `buttonsRender` function. Additional props can be passed to the component.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.icon - The icon to display in the button.
 * @param {string} props.title - The title to display in the button.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @param {import('antd/lib/dropdown').DropdownButtonProps} rest - Any additional props to pass down to the dropdown component.
 * @returns {ReactNode} - The rendered component.
 */
VButton.Dropdown = ({ icon, title, onClick, ...rest }) => (
	<Dropdown.Button
		trigger={['click']}
		type="primary"
		icon={<HiChevronDown />}
		buttonsRender={(btns) => [
			<VButton key="main" type="primary" onClick={onClick}>
				{icon}
				{title}
			</VButton>,
			React.cloneElement(btns[1], {
				key: 'dropdown',
				className: styles.v_button,
				style: { width: 'auto' },
			}),
		]}
		style={{ width: 'auto' }}
		{...rest}
	/>
);

/**
 * React component that renders a clickable filter button with a dropdown menu.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be displayed in the dropdown.
 * @param {string} props.title - The title to be displayed on the filter button.
 * @returns {JSX.Element} - The Filter component.
 */
const Filter = ({ children, title }) => {
	const [openFilter, setOpenFilter] = React.useState(false);
	const toggleFilter = () => setOpenFilter(!openFilter);
	const hideFilter = () => setOpenFilter(false);

	return (
		<ClickAwayListener onClickAway={hideFilter}>
			<div style={{ position: 'relative' }}>
				<VButton onClick={toggleFilter}>
					<HiOutlineFilter />
					{title}
					<HiChevronDown style={{ transform: `rotate(${openFilter ? 0 : 90}deg)` }} />
				</VButton>

				<div
					className={styles.filter_container}
					style={{
						transform: `translateX(${openFilter ? '0' : '-50%'})`,
						opacity: openFilter ? 1 : 0,
						zIndex: openFilter ? 99 : -1,
						pointerEvents: openFilter ? 'auto' : 'none',
					}}
				>
					{React.cloneElement(children, { onClose: hideFilter })}
				</div>
			</div>
		</ClickAwayListener>
	);
};
VButton.Filter = Filter;

export default VButton;
