import React from 'react';
import styled from 'styled-components';
import Dialog from '../components/Dialog/Dialog';
import { Box, FormControlLabel, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import FieldSelectApi from '../components/Dialog/FieldSelectApi';
import { CancelRounded, SaveRounded } from '@material-ui/icons';
import FieldInput from '../components/Dialog/FieldInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import moment from 'moment';
import SelectBase from '../components/select/SelectBase';

const PriceFormStyled = styled(Box)`
	padding: 10px 0;
`;
const CheckBox = styled.input`
	accent-color: ${(props) => props.color};
`;
/**
 * - Với master thì chưa có trường: từ giờ, đến giờ, ngày trong tuần
 * - Với chiết khấu mặt hàng thì chưa có: ngành hàng, đơn vị tính, cố định tiền giảm
 * - Với chiết khấu hóa đơn thì chưa có: nội dung in trên phiếu
 */
const schema = yup.object({
	ma_chietkhau: yup.string().required('Vui lòng nhập mã chiết khấu'),
	ten_chietkhau: yup.string().required('Vui lòng nhập tên chiết khấu'),
	hieu_luc_tu: yup.date().typeError('Ngày không đúng định dạng').required('Vui lòng chọn ngày bắt đâu'),
	hieu_luc_den: yup
		.date()
		.typeError('Ngày không đúng định dạng')
		.required('Vui lòng chọn ngày kết thúc')
		.min(yup.ref('hieu_luc_tu'), 'Ngày kết thúc phải sau ngày bắt đầu'),
});

function DiscountForm({ open, onClose, data, setData, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		control,
		formState: { errors, isSubmitting },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		defaultValues: {
			ma_chietkhau: '',
			hieu_luc_tu: '',
			hieu_luc_den: '',
			ten_chietkhau: '',
			khach_hang: null,
			nhom_khach_hang: null,
			kho: null,
			stt_uu_tien: '',
		},
	});
	const [isEdit, setIsEdit] = React.useState(!!data || false);
	const theme = useTheme();

	// create data post
	const createDataPost = (values) => {
		const dataPost = {
			...data,
			ma_chietkhau: values.ma_chietkhau,
			hieu_luc_tu: values.hieu_luc_tu,
			hieu_luc_den: values.hieu_luc_den,
			ten_chietkhau: values.ten_chietkhau,
			ma_kh: values?.khach_hang?.ma_kh,
			ten_kh: values?.khach_hang?.ten_kh,
			nh_kh: values?.nhom_khach_hang?.group_name,
			ma_kho: values?.kho?.ma_kho,
			ten_kho: values?.kho?.ten_kho,
			stt_uu_tien: values.stt_uu_tien,
			ty_le_ck: values.ty_le_ck,
			tien_ck: values.tien_ck,
			ma_nvt: values.nhom_vat_tu?._id,
			ten_nvt: values.nhom_vat_tu?.ten_nvt,
			ma_vt: values.vat_tu?.ma_vt,
			ten_vt: values.vat_tu?.ten_vt,
			sl_tu: values.sl_tu,
			sl_den: values.sl_den,
			gt_hd_tu: values.gt_hd_tu,
			gt_hd_den: values.gt_hd_den,
		};
		return dataPost;
	};

	// save
	const handleSave = async (values) => {
		const dataPost = { ...data, ...createDataPost(values) };
		return onSubmit(dataPost).then(onClose);
	};
	// save and add new
	const handleSaveAddNew = (values) => {
		const dataPost = createDataPost(values);
		return onSubmit(dataPost).then(() => {
			setIsEdit(false);
			setData({});
		});
	};
	// handle save copy
	const handleSaveCopy = (values) => {
		const dataPost = createDataPost(values);
		return onSubmit(dataPost);
	};
	React.useEffect(() => {
		if (open) {
			if (!data || isEmpty(data)) {
				setIsEdit(false);
				reset({
					hieu_luc_tu: '',
					hieu_luc_den: '',
					ma_chietkhau: '',
					ten_chietkhau: '',
					khach_hang: null,
					nhom_khach_hang: null,
					kho: null,
					stt_uu_tien: '',
					ty_le_ck: '',
					tien_ck: '',
					vat_tu: null,
					nhom_vat_tu: null,
					sl_tu: '',
					sl_den: '',
					gt_hd_tu: '',
					gt_hd_den: '',
				});
			} else {
				setIsEdit(true);
				let dataReset = {
					hieu_luc_tu: moment(data.hieu_luc_tu).format('YYYY-MM-DD'),
					hieu_luc_den: moment(data.hieu_luc_den).format('YYYY-MM-DD'),
					ma_chietkhau: data.ma_chietkhau,
					ten_chietkhau: data.ten_chietkhau,
					khach_hang: data.ma_kh ? { ma_kh: data.ma_kh, ten_kh: data.ten_kh } : null,
					nhom_khach_hang: data.nh_kh ? { group_name: data.nh_kh } : null,
					kho: data.ma_kho ? { ma_kho: data.ma_kho, ten_kho: data.ten_kho } : null,
					stt_uu_tien: data.stt_uu_tien,
					ty_le_ck: data.ty_le_ck,
					tien_ck: data.tien_ck,
					vat_tu: data.ma_vt ? { ma_vt: data.ma_vt, ten_vt: data.ten_vt } : null,
					nhom_vat_tu: data.ma_nvt ? { ma_nvt: data.ma_nvt, ten_nvt: data.ten_nvt } : null,
					sl_tu: data.sl_tu,
					sl_den: data.sl_den,
					gt_hd_tu: data.gt_hd_tu,
					gt_hd_den: data.gt_hd_den,
				};
				reset(dataReset);
			}
		}
	}, [data, open]);

	console.log({ values: getValues() });

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={isEdit ? 'Chỉnh sửa chiết khấu' : 'Thêm chiết khấu'}
			width="870px"
			loading={isSubmitting}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(handleSave)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveAddNew)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(handleSaveCopy)} disabled={isSubmitting}>
					<SaveRounded />
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					<CancelRounded />
					Hủy
				</button>,
			]}
		>
			<PriceFormStyled>
				<Grid container alignContent="flex-start" spacing={2}>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Mã chiết khấu"
							placeholder="Mã chiết khấu"
							inputProps={{ ...register('ma_chietkhau') }}
							errorMsg={errors?.ma_chietkhau?.message}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Nội dung "
							placeholder="Nội dung"
							inputProps={{ ...register('ten_chietkhau') }}
							errorMsg={errors?.ten_chietkhau?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<FieldInput
							inputProps={{ ...register('hieu_luc_tu') }}
							title="Từ ngày"
							type="date"
							defaultValue={data?.hieu_luc_tu}
							errorMsg={errors?.hieu_luc_tu?.message}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							inputProps={{ ...register('hieu_luc_den') }}
							title="Đến ngày"
							type="date"
							defaultValue={data?.hieu_luc_den}
							errorMsg={errors?.hieu_luc_den?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<FieldInput title="Từ giờ" type="time" />
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput title="Đến giờ" type="time" />
					</Grid>
					<Grid item xs={12}>
						<SelectBase
							isMulti
							title="Ngày trong tuần"
							placeholder="Ngày trong tuần"
							fieldBoxProps={{ colWidthTitle: 2 }}
							options={[
								{ value: 0, label: 'Chủ nhật' },
								{ value: 1, label: 'Thứ 2' },
								{ value: 2, label: 'Thứ 3' },
							]}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="khach_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="customer"
									title="Khách hàng"
									placeholder="Khách hàng"
									value={value ? { value: value.ma_kh, label: value.ten_kh } : null}
									onChange={onChange}
									valueField="ma_kh"
									labelField="ten_kh"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="nhom_khach_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="group"
									q={{ group_type: 'CUSTOMER' }}
									title="Nhóm khách"
									placeholder="Nhóm khách"
									value={value ? { value: value.group_name, label: value.group_name } : null}
									onChange={onChange}
									valueField="group_name"
									labelField="group_name"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="kho"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmkho"
									title="Kho/Chi nhánh"
									placeholder="Kho/Chi nhánh"
									value={value ? { value: value.ma_kho, label: value.ten_kho } : null}
									onChange={onChange}
									valueField="ma_kho"
									labelField="ten_kho"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Mức độ ưu tiên"
							placeholder="Độ ưu tiên"
							inputProps={{ ...register('stt_uu_tien') }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="nganh_hang"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmnganhhang"
									title="Ngành hàng"
									placeholder="Ngành hàng"
									value={
										value
											? {
													value: value.ma_nganh,
													label: value.ten_nganh,
											  }
											: null
									}
									onChange={onChange}
									valueField="ma_nganh"
									labelField="ten_nganh"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="nhom_vat_tu"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmnvt"
									title="Nhóm hàng"
									placeholder="Nhóm hàng"
									value={value ? { value: value._id, label: value.ten_nvt } : null}
									onChange={onChange}
									valueField="_id"
									labelField="ten_nvt"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="vat_tu"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmvt"
									title="Sản phẩm"
									placeholder="Sản phẩm"
									value={value ? { value: value.ma_vt, label: value.ten_vt } : null}
									onChange={onChange}
									valueField="ma_vt"
									labelField="ten_vt"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="don_vi_tinh"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									api="dmdvt"
									title="Đơn vị tính"
									placeholder="Đơn vị tính"
									value={value ? { value: value.ma_dvt, label: value.ten_dvt } : null}
									onChange={onChange}
									valueField="ma_dvt"
									labelField="ten_dvt"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Số lượng từ"
							type="number"
							placeholder="Số lượng từ"
							inputProps={{ ...register('sl_tu') }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Số lượng đến"
							type="number"
							placeholder="Số lượng đến"
							inputProps={{ ...register('sl_den') }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Phần trăm giảm giá"
							type="number"
							placeholder="Phần trăm giảm giá"
							inputProps={{ ...register('ty_le_ck') }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Tiền giảm giá"
							type="number"
							placeholder="Tiền giảm giá"
							inputProps={{ ...register('tien_ck') }}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<FieldInput
							title="Tổng hóa đơn từ"
							type="number"
							placeholder="Tổng hóa đơn từ"
							inputProps={{ ...register('gt_hd_tu') }}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FieldInput
							title="Tổng hóa đơn đến"
							type="number"
							placeholder="Tổng hóa đơn đến"
							inputProps={{ ...register('gt_hd_den') }}
						/>
					</Grid>
					<Grid item xs={12}>
						<FieldInput
							title="Nội dung in trên phiếu"
							placeholder="Nội dung in trên phiếu"
							fieldBoxProps={{ colWidthTitle: 2 }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Kênh áp dụng" placeholder="Kênh áp dụng" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Nhóm chương trình" placeholder="Nhóm chương trình" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<FieldInput title="Hạng khách hàng" placeholder="Hạng khách hàng" />
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControlLabel
							style={{
								margin: 0,
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
								paddingLeft: '10px',
							}}
							label="Cố định tiền giảm giá"
							control={<CheckBox type="checkbox" color={theme.palette.primary.main} />}
						/>
					</Grid>
				</Grid>
			</PriceFormStyled>
		</Dialog>
	);
}

export default DiscountForm;
