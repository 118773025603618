import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import ShiftFilter from './ShiftFilter';
import ShiftForm from './ShiftForm';
import moment from 'moment';

const columns = [
	{
		name: 'Mã ca',
		selector: (row) => row.ma_ca,
		sortable: true,
		minWidth: '120px',
	},
	{
		name: 'Tên ca',
		selector: (row) => row.ten_ca,
		sortable: true,
		minWidth: '120px',
	},
	{
		name: 'Giờ làm từ',
		selector: (row) => row.start_time,
		sortable: true,
		minWidth: '120px',
		format: (row) => (row.start_time ? moment(row.start_time).format('HH:mm') : ''),
		center: true,
	},
	{
		name: 'Giờ làm đến',
		selector: (row) => row.end_time,
		sortable: true,
		minWidth: '120px',
		format: (row) => (row.end_time ? moment(row.end_time).format('HH:mm') : ''),
		center: true,
	},
	{
		name: 'Chấm vào từ',
		selector: (row) => row.start_time_right,
		sortable: true,
		minWidth: '120px',
		format: (row) => (row.start_time_right ? moment(row.start_time_right).format('HH:mm') : ''),
		center: true,
	},
	{
		name: 'Chấm ra đến',
		selector: (row) => row.end_time_right,
		sortable: true,
		minWidth: '120px',
		format: (row) => (row.end_time_right ? moment(row.end_time_right).format('HH:mm') : ''),
		center: true,
	},
];

function ListShift() {
	return (
		<ListBase
			api="dmca"
			title="Danh sách ca làm việc"
			columns={columns}
			FilterComponent={ShiftFilter}
			FormDialog={ShiftForm}
		/>
	);
}

export default withContainer(ListShift);
