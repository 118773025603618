import { List, Modal } from 'antd';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './VComponent.module.css';

const ModalColSettings = ({ open, onClose, allColumns, setColumnOrder }) => {
	const columns = {
		visible: [],
		hidden: [],
	};

	// Get visible and invisible columns
	allColumns.forEach((column) => {
		if (!column.disableHiding) {
			if (column.isVisible) {
				columns.visible.push(column);
			} else {
				columns.hidden.push(column);
			}
		}
	});

	const onDragEnd = (result) => {
		const { source, destination } = result;
		if (!destination) return;

		const isMoved = source.droppableId !== destination.droppableId;
		const isReordered = isMoved || source.index !== destination.index;

		// Handle moving columns
		if (isMoved) {
			console.log('Moved', result);
			const item = columns[source.droppableId][source.index];
			item.toggleHidden();
		}

		// Handle reordering columns
		if (isReordered) {
			console.log('Reordered', result);

			// Get item to reorder
			const item = columns[source.droppableId][source.index];

			// Get ordered columns
			const orderedColumns = [...columns.visible.map(({ id }) => id), ...columns.hidden.map(({ id }) => id)];

			// Get index of item
			const srcIndex = orderedColumns.indexOf(item.id);
			console.log('srcIndex', srcIndex);

			// Get index of destination
			let desIndex = destination.index;
			if (destination.droppableId !== 'visible') {
				desIndex += columns.visible.length;
				if (isMoved) desIndex -= 1;
			}
			console.log('desIndex', desIndex);

			// remove item from src
			orderedColumns.splice(srcIndex, 1);
			// add item to des
			orderedColumns.splice(desIndex, 0, item.id);

			// set ordered columns
			console.log('orderedColumns', orderedColumns);
			setColumnOrder(['selection', ...orderedColumns]);
		}
	};

	const renderList = (provided, snapshot, { list, title }) => (
		<List
			header={<div className={styles.list_title}>{title}</div>}
			style={{ backgroundColor: snapshot.isDraggingOver ? '#f5f5f5' : '#fff' }}
			bordered
			dataSource={[...list]}
			className={styles.list_wrapper}
			{...provided.droppableProps}
		>
			<div ref={provided.innerRef} style={{ minHeight: '100px' }}>
				{list.map((col, index) => (
					<Draggable key={col.id} draggableId={col.id} index={index}>
						{(provided) => (
							<List.Item
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
							>
								{col.render('Header')}
							</List.Item>
						)}
					</Draggable>
				))}
				{provided.placeholder}
			</div>
		</List>
	);

	return (
		<Modal open={open} onCancel={onClose} title="Column settings" footer={null}>
			<DragDropContext onDragEnd={onDragEnd}>
				<div className={styles.col_settings_container}>
					{/* Visible List */}
					<Droppable droppableId="visible">
						{(provided, snapshot) =>
							renderList(provided, snapshot, { list: columns.visible, title: 'Visible' })
						}
					</Droppable>

					{/* Invisible List */}
					<Droppable droppableId="hidden">
						{(provided, snapshot) =>
							renderList(provided, snapshot, { list: columns.hidden, title: 'Hidden' })
						}
					</Droppable>
				</div>
			</DragDropContext>
		</Modal>
	);
};

export default ModalColSettings;
