import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import FieldBox from './FieldBox';
import { makeStyles } from '@material-ui/core/styles';

const FieldSelect = ({
	actions,
	tooltip,
	check,
	title,
	items = [],
	labelField = 'label',
	valueField = 'value',
	placeholder,
	onChange,
	defaultValue,
	disableClearable = false,
}) => {
	const classes = useStyles();
	const [selected, setSelected] = useState(items.find((item) => item[valueField] === defaultValue));

	useEffect(() => {
		const item = items.find((item) => item[valueField] === defaultValue);
		setSelected(item);
	}, [defaultValue, items, valueField]);

	const handleChange = (_e, newOption) => {
		setSelected(newOption);
		// eslint-disable-next-line no-unused-expressions
		onChange?.(newOption);
	};

	return (
		<FieldBox title={title} tooltip={tooltip} check={check}>
			<Autocomplete
				defaultValue={selected}
				value={selected}
				options={items}
				renderOption={(option) => (
					<span
						style={{
							fontSize: 12,
							backgroundColor: option.color,
							padding: '4px 8px',
							borderRadius: '8px',
						}}
					>
						{option[labelField]}
					</span>
				)}
				getOptionLabel={(option) => option[labelField]}
				getOptionSelected={(option, value) => option?.[valueField] === value?.[valueField]}
				fullWidth
				noOptionsText="Không có dữ liệu"
				disableClearable={disableClearable}
				ListboxProps={{
					style: {
						fontSize: 12,
					},
				}}
				onChange={handleChange}
				renderInput={(params) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							{...params}
							placeholder={placeholder}
							InputProps={{
								...params.InputProps,
								style: {
									fontSize: 12,
									flex: 1,
								},
							}}
							inputProps={{
								...params.inputProps,
								style: {
									fontSize: 12,
									flex: 1,
									height: 37,
									padding: 0,
								},
							}}
							className={classes.textField}
						/>
						{actions && actions}
					</div>
				)}
			/>
		</FieldBox>
	);
};

export default FieldSelect;

const useStyles = makeStyles(() => ({
	textField: {
		'& .MuiInputBase-root::before': {
			borderBottom: 'unset',
		},

		'& .MuiInputBase-root:hover::before': {
			borderBottom: 'unset',
		},
	},
}));
