import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "--primary-color": theme.palette.primary.main,
    "--secondary-color": theme.palette.secondary.main,
  },
}));

const LayoutContainer = ({ FilterLeft, children }) => {
  const classes = useStyles();

  const [openFilter, setOpenFilter] = React.useState(false);

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      style={{
        margin: "0 10px 10px",
        width: "auto",
        transition: "all 0.3s ease",
        height: "100%",
      }}
      wrap="nowrap"
    >
      {FilterLeft && (
        <Grid
          item
          style={{
            width: openFilter ? "300px" : "0px",
            overflowX: openFilter ? "visible" : "hidden",
            padding: 0,
            transition: "all 0.3s ease",
            height: "100%",
          }}
        >
          <div style={{ minWidth: 300 }}>{FilterLeft}</div>
        </Grid>
      )}

      <Grid
        item
        xs
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {React.cloneElement(children, {
          openFilter,
          setOpenFilter,
        })}
      </Grid>
    </Grid>
  );
};

export default LayoutContainer;
