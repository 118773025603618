import { Drawer } from "@material-ui/core";
import React from "react";

function SpaDrawers({ anchor, open, onClose, children }) {
	return (
		<React.Fragment>
			<Drawer
				PaperProps={{
					style: {
						minWidth: "450px",
						overflow: "hidden",
						borderTopRightRadius: anchor === "left" ? 16 : 0,
						borderBottomRightRadius: anchor === "left" ? 16 : 0,
						borderTopLeftRadius: anchor === "right" ? 16 : 0,
						borderBottomLeftRadius: anchor === "right" ? 16 : 0,
						padding: "10px 20px",
						width: anchor === "right" ? "1050px" : "",
					},
				}}
				anchor={anchor}
				open={open}
				onClose={onClose}
			>
				{children}
			</Drawer>
		</React.Fragment>
	);
}

export default SpaDrawers;
