import Grid from '@material-ui/core/Grid';
import { DeleteRounded, SaveRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Controller } from 'react-hook-form';
import 'react-slideshow-image/dist/styles.css';
import { useCore } from '../../../../../../hooks/useCore';
import FieldInput from '../../../../components/Dialog/FieldInput';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';

export default function UnitConversionTab({ hookForm }) {
	const classes = useStyles();

	const {
		getValues,
		setValue,
		control,
		formState: { errors },
	} = hookForm;

	const core = useCore();
	const [units, setUnits] = React.useState([]);
	React.useEffect(() => {
		const getUnits = async () => {
			try {
				const data = await core.asyncGetList(core.token, 'dmqddvt', { q: { ma_vt: getValues('ma_vt') } });
				setUnits(data);
			} catch (error) {
				core.contextAlert(error);
			}
		};
		getUnits();
	}, []);

	const handleAddUnit = () => {
		const unit = {
			ma_vt: getValues('ma_vt'),
			ma_dvt_goc: getValues('ma_dvt'),
			tu: 1,
			mau: 1,
			key: Date.now(),
		};
		setUnits([...units, unit]);
	};

	const handleDeleteUnit = (index) => {
		try {
			const unit = units[index];
			// if unit is exist in database
			if (unit._id) {
				core.asyncDeleteList(core.token, 'dmqddvt', unit._id);
			}
			const newValue = [...units];
			newValue.splice(index, 1);
			setUnits(newValue);
		} catch (error) {
			core.contextAlert(error);
		}
	};

	const isValidToSave = (unit, index) => {
		return !!(unit.ma_dvt && unit.tu && unit.mau && JSON.stringify(unit) !== JSON.stringify(units[index]));
	};

	const handleSaveUnit = async (index, unit) => {
		// Check if unit is valid to save
		if (!isValidToSave(unit, index)) return;

		// save unit
		const newUnits = [...units];
		try {
			const data = await core.asyncPostList(core.token, 'dmqddvt', unit);
			newUnits[index] = data;
			setUnits(newUnits);
		} catch (error) {
			// set local unit
			newUnits[index] = unit;
			setUnits(newUnits);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={8}>
				<Controller
					name="ma_dvt"
					control={control}
					rules={{ required: 'Vui lòng chọn ĐVT tiêu chuẩn' }}
					render={({ field: { onChange, value } }) => {
						const item = value ? { value, label: getValues('ten_dvt') } : null;
						const handleChange = (item) => {
							onChange(item?.value || null);
							setValue('ten_dvt', item?.label || '');
						};

						return (
							<FieldSelectApi
								title="ĐVT tiêu chuẩn"
								placeholder="Nhập ĐVT tiêu chuẩn"
								api="dmdvt"
								valueField="ma_dvt"
								labelField="ten_dvt"
								value={item}
								onChange={handleChange}
								isClearable={false}
								check
								errorMsg={errors.ma_dvt?.message}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<button onClick={handleAddUnit} className="btn btn-outline">
					Thêm ĐVT quy đổi
				</button>
			</Grid>

			<Grid item xs={12}>
				{units?.length > 0 && (
					<Grid container spacing={1}>
						<Grid item xs>
							<span className={classes.colLabel}>ĐVT quy đổi</span>
						</Grid>

						<Grid item xs>
							<span className={classes.colLabel}>Giá trị quy đổi</span>
						</Grid>

						{/* Placeholder */}
						<Grid item xs="auto">
							<button className="btn btn-fill" style={{ visibility: 'hidden' }}>
								<SaveRounded />
							</button>
						</Grid>
					</Grid>
				)}

				{units.map((item, index) => (
					<UnitConversionLine
						key={item._id || item.key}
						item={item}
						index={index}
						onDelete={handleDeleteUnit}
						onSave={handleSaveUnit}
						units={units}
					/>
				))}
			</Grid>
		</Grid>
	);
}

const UnitConversionLine = ({ item, index, onSave, onDelete, units }) => {
	const [unit, setUnit] = React.useState(item);
	const q = {
		ma_dvt: {
			$nin: units
				.filter((_, i) => i !== index) // remove current item
				.map((item) => item.ma_dvt) // get ma_dvt
				.concat(unit.ma_dvt_goc), // add ma_dvt_goc
		},
	};

	const onChangeField =
		(field, type = 'string') =>
		(e) => {
			let value = e?.target?.value ?? e;
			if (type === 'number') {
				if (isNaN(value)) return;

				value = Number(value);
			}

			if (value === unit[field]) return; // prevent not change

			setUnit({ ...unit, [field]: value });
		};
	const onChangeValue = (value) => setUnit({ ...unit, ...value });

	React.useEffect(() => {
		onSave(index, unit);
	}, [unit]);

	return (
		<Grid container spacing={1}>
			<Grid item xs>
				<FieldSelectApi
					api="dmdvt"
					q={q}
					valueField="ma_dvt"
					labelField="ten_dvt"
					placeholder="Chọn ĐVT quy đổi"
					value={unit.ma_dvt ? { value: unit.ma_dvt, label: unit.ten_dvt } : null}
					onChange={(item) =>
						onChangeValue({
							ma_dvt: item?.value || null,
							ten_dvt: item?.label || '',
						})
					}
					isClearable={false}
				/>
			</Grid>

			<Grid item xs>
				<FieldInput
					inputProps={{ onBlur: onChangeField('tu', 'number') }}
					defaultValue={unit.tu}
					type="number"
				/>
			</Grid>

			<Grid item xs="auto">
				<button className="btn btn-delete" onClick={() => onDelete(index)}>
					<DeleteRounded />
				</button>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles(() => ({
	root: {
		marginTop: 16,
	},
	colLabel: {
		fontWeight: 'bold',
		fontSize: 14,
	},
}));
