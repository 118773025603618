import React from 'react';
import { useCore } from '../../../hooks';
import LayoutContainer from '../components/LayoutContainer';
import ListBaseData from './ListBaseData';

export default function ListBase({
	api,
	title,
	columns,
	FilterComponent,
	FormDialog,
	initFilter,
	importCode,
	exportCode,
}) {
	// assign value to importCode and exportCode if not provided
	importCode ??= api;
	exportCode ??= api;

	const core = useCore();

	const [data, setData] = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [params, setParams] = React.useState({ limit: 20, page: 1, q: initFilter });

	const getData = React.useCallback(async () => {
		core.setProgressStatus(true);

		try {
			const [data, count] = await Promise.all([
				core.asyncGetList(core.token, api, params),
				core.asyncGetList(core.token, api, { ...params, count: 1 }),
			]);
			setData(data);
			setTotalRows(count?.rows_number || 0);
		} catch (error) {
			console.log('getData error', error);
		} finally {
			core.setProgressStatus(false);
		}
	}, [params]);

	React.useEffect(() => {
		getData();
	}, [getData]);

	const handleSubmit = React.useCallback(
		async (values) => {
			core.setProgressStatus(true);

			try {
				await core.asyncPostList(core.token, api, values);
				await getData();
			} catch (error) {
				core.contextAlert(error);
				throw error; // do not close dialog
			} finally {
				core.setProgressStatus(false);
			}
		},
		[getData, api]
	);

	const handleDelete = React.useCallback(
		(id) => {
			core.contextAlert('Bạn có chắc chắn muốn xóa không?', async (isConfirm) => {
				if (!isConfirm) return;

				core.setProgressStatus(true);

				try {
					await core.asyncDeleteList(core.token, api, id);
					await getData();
				} catch (error) {
					console.log('handleDelete error', error);
					core.contextAlert(error);
				} finally {
					core.setProgressStatus(false);
				}
			});
		},
		[getData, api]
	);

	const handleDeleteList = React.useCallback(
		async (ids) => {
			core.contextAlert(`Bạn có chắc chắn muốn xóa ${ids.length} dòng không?`, async (isConfirm) => {
				if (!isConfirm) return;
				core.setProgressStatus(true);

				try {
					const errors = [];
					await Promise.all(
						ids.map((id) => core.asyncDeleteList(core.token, api, id).catch((error) => errors.push(error)))
					);
					if (errors.length) {
						core.contextAlert(
							<>
								<p>{`Có ${errors.length}/${ids.length} dòng không xóa được`}</p>
								{errors.map((e, index) => (
									<div key={index}>{e.error || e}</div>
								))}
							</>
						);
					}
				} catch (error) {
					core.contextAlert(error);
				} finally {
					await getData();
					core.setProgressStatus(false);
				}
			});
		},
		[getData, api]
	);

	const handlePaginationChange = React.useCallback((page, limit) => setParams({ ...params, page, limit }), [params]);

	const handleFilterChange = React.useCallback((filter) => setParams({ ...params, q: filter }), [params]);

	return (
		<LayoutContainer
			FilterLeft={FilterComponent && <FilterComponent onChange={handleFilterChange} initFilter={initFilter} />}
		>
			<ListBaseData
				data={data}
				getData={getData}
				api={api}
				importCode={importCode}
				exportCode={exportCode}
				columns={columns}
				title={title}
				handleSubmit={handleSubmit}
				handleDelete={handleDelete}
				handleDeleteList={handleDeleteList}
				tableProps={{
					page: params.page,
					limit: params.limit,
					totalRows,
					handlePaginationChange,
				}}
				FormDialog={FormDialog}
			/>
		</LayoutContainer>
	);
}
