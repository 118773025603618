import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useState } from 'react';
import Layout from '../../components/LayoutContainer';
import Container from '../../Container';
import CTDTByChungTuVatTuData from './CTDTByChungTuVatTuData';
import CTDTByChungTuVatTuFilter from './CTDTByChungTuVatTuFilter';

function CTDTByChungTuVatTuContent({ props }) {
    const context = React.useContext(DefaultContext);
    const [data, setData] = useState([
        {
            ngay_ct: '2021-07-01T00:00:00',
            so_ct: 'CT0000001',
            ma_vt: 'VT0000001',
            dvt: 'Bàn gỗ',
            sl_order: 1,
            gia_ban: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            l_mh: 'Bàn',
            nh_h: 'Bàn gỗ',
            ma_kh: 'KH0000001',
            ten_kh: 'Khách hàng 1',
        },
        {
            ngay_ct: '2021-07-01T00:00:00',
            so_ct: 'CT0000001',
            ma_vt: 'VT0000001',
            dvt: 'Bàn gỗ',
            sl_order: 1,
            gia_ban: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            l_mh: 'Bàn',
            nh_h: 'Bàn gỗ',
            ma_kh: 'KH0000001',
            ten_kh: 'Khách hàng 1',
        },
        {
            ngay_ct: '2021-07-01T00:00:00',
            so_ct: 'CT0000001',
            ma_vt: 'VT0000001',
            dvt: 'Bàn gỗ',
            sl_order: 1,
            gia_ban: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            l_mh: 'Bàn',
            nh_h: 'Bàn gỗ',
            ma_kh: 'KH0000001',
            ten_kh: 'Khách hàng 1',
        },
        {
            ngay_ct: '2021-07-01T00:00:00',
            so_ct: 'CT0000001',
            ma_vt: 'VT0000001',
            dvt: 'Bàn gỗ',
            sl_order: 1,
            gia_ban: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            l_mh: 'Bàn',
            nh_h: 'Bàn gỗ',
            ma_kh: 'KH0000001',
            ten_kh: 'Khách hàng 1',
        },
        {
            ngay_ct: '2021-07-01T00:00:00',
            so_ct: 'CT0000001',
            ma_vt: 'VT0000001',
            dvt: 'Bàn gỗ',
            sl_order: 1,
            gia_ban: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            l_mh: 'Bàn',
            nh_h: 'Bàn gỗ',
            ma_kh: 'KH0000001',
            ten_kh: 'Khách hàng 1',
        },
        {
            ngay_ct: '2021-07-01T00:00:00',
            so_ct: 'CT0000001',
            ma_vt: 'VT0000001',
            dvt: 'Bàn gỗ',
            sl_order: 1,
            gia_ban: 1000000,
            t_tien: 1000000,
            t_ck: 0,
            t_tt: 1000000,
            l_mh: 'Bàn',
            nh_h: 'Bàn gỗ',
            ma_kh: 'KH0000001',
            ten_kh: 'Khách hàng 1',
        },
    ]);
    const onFilter = (data) => {
        console.log(data);
    };

    return (
        <Layout FilterLeft={<CTDTByChungTuVatTuFilter onFilter={onFilter} />}>
            <CTDTByChungTuVatTuData data={data} />
        </Layout>
    );
}

const CTDTByChungTuVatTu = (props) => {
    return (
        <Container {...props} showDrawerIfIsDesktop={false}>
            <CTDTByChungTuVatTuContent />
        </Container>
    );
};

export default CTDTByChungTuVatTu;
