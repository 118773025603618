import {
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
	makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import SelectAntd from "react-select";
import { FaRegCalendarAlt, FaRegClock, FaRegCreditCard } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";

const DataBill = [
	{
		id: 1,
		name: "Dịch vụ 1",
		price: 100000,
		quantity: 1,
		totalPrice: 100000,
		startTime: "10:00",
		note: "Ghi chú",
	},
	{
		id: 2,
		name: "Dịch vụ 2",
		price: 100000,
		quantity: 1,
		totalPrice: 100000,
		startTime: "10:00",
		note: "Ghi chú",
	},
	{
		id: 3,
		name: "Dịch vụ 3",
		price: 100000,
		quantity: 1,
		totalPrice: 100000,
		startTime: "10:00",
		note: "Ghi chú",
	},
];

const optionsStaff = [
	{
		label: "Thanh Thuy",
		value: "thuy",
	},
	{
		label: "Thanh Nga",
		value: "nga",
	},
	{
		label: "Thanh Duy",
		value: "duy",
	},
];

const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		boxShadow: "0px 0px 12px rgb(0 0 0 / 0%)",
		borderRadius: 5,
		border: "1px solid #ccc",
		cursor: "pointer",
		"&:hover": {
			border: "1px solid #279656",
			boxShadow: "0px 0px 12px rgb(39 150 86 / 40%)",
		},
	}),
};

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
	},
});

const SpaDrawersBill = ({ onClose }) => {
	const classes = useStyles();
	const [selectedStaff, setSelectedStaff] = useState([]);
	const [checkedA, setCheckedA] = React.useState(false);

	const handleChange = (event) => {
		setCheckedA(!checkedA);
	};
	return (
		<div className="box_bill_drawers">
			<div className="drawer_left_main_header">
				<span>Hoá đơn 3 - Khách lẻ</span>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</div>
			<div className="box_bill_drawers_content">
				<div className="box_bill_drawers_content_left">
					<div className="box_bill_drawers_content_left_header">
						<span className="w-50">Dịch vụ, Thẻ</span>
						<span>Số lượng</span>
						<span>Giá bán</span>
						<span>Thành tiền</span>
					</div>
					{DataBill.map((item, index) => {
						return (
							<div className="box_bill_drawers_content_left_item">
								<div className="w-50">
									<span>{item.id}.</span>
									<span className="box_note_bill">
										<span className="font-b">
											{item.name}
										</span>
										<span className="box_note_bill_detail">
											Trừ vào gói Massage
										</span>
										<span className="note_bill">
											{item.note}
										</span>
									</span>
								</div>
								<span>{item.quantity}</span>
								<span className="box_price_bill">
									<span>{item.price}</span>
									<span className="price_sale">-200,000</span>
								</span>
								<span>{item.totalPrice}</span>
							</div>
						);
					})}
				</div>
				<div className="box_bill_drawers_content_right">
					<div className="box_bill_drawers_content_right_detail">
						<div className="right_detail_header">
							<SelectAntd
								placeholder="Chọn nhân viên"
								options={optionsStaff}
								value={selectedStaff}
								onChange={setSelectedStaff}
								labelledBy="Select"
								searchable={true}
								styles={colourStyles} // bật tính năng tìm kiếm
							/>
							<div className="group_date_time_bill">
								<span>01/03/2023 10:00</span>
								<FaRegCalendarAlt />
								<FaRegClock />
							</div>
						</div>
						<div className="right_detail_content">
							<div className="right_detail_content__item_price">
								<div>
									<span className="d-flex">
										Tổng tiền{" "}
										<span className="total">2</span>
									</span>
									<span>300,000</span>
								</div>
								<div>
									<span>Giảm giá </span>
									<span>300,000</span>
								</div>
								<div>
									<span>Thu khác </span>
									<span>300,000</span>
								</div>
								<div>
									<span>Khách cần trả </span>
									<span>300,000</span>
								</div>
								<div>
									<span className="d-flex">
										Khách thanh toán{" "}
										<FaRegCreditCard
											style={{
												marginLeft: "5px",
											}}
										/>
									</span>
									<span>300,000</span>
								</div>
							</div>
							<div className="right_detail_content__group_radiobox">
								<RadioGroup
									row
									aria-label="position"
									name="position"
									defaultValue="top"
									className="custom-group-radio"
								>
									<FormControlLabel
										value="money"
										control={
											<Radio className="custom-radio" />
										}
										label={
											<span style={{ fontSize: "14px" }}>
												Tiền mặt
											</span>
										}
										style={{
											marginRight: "20px !important",
										}}
									/>
									<FormControlLabel
										value="card"
										control={
											<Radio className="custom-radio" />
										}
										label={
											<span style={{ fontSize: "14px" }}>
												Thẻ
											</span>
										}
										style={{
											marginRight: "20px !important",
										}}
									/>
									<FormControlLabel
										value="bank"
										control={
											<Radio className="custom-radio" />
										}
										label={
											<span style={{ fontSize: "14px" }}>
												Chuyển khoản
											</span>
										}
										style={{
											marginRight: "20px !important",
										}}
									/>
								</RadioGroup>
							</div>
							<div className="right_detail_content__money">
								<button className="btn btn-edit">
									400,000
								</button>
								<button className="btn btn-white">
									500,000
								</button>
								<button className="btn btn-white">
									600,000
								</button>
								<button className="btn btn-white">
									700,000
								</button>
							</div>
							<div className="right_detail_content__money_change">
								<span>Tiền thừa trả khách</span>
								<span className="text-green">0</span>
							</div>
						</div>
					</div>
					<div className="box_bill_drawers_content_right_btn_note">
						<TextField
							className={classes.root}
							variant="outlined"
							placeholder="Ghi chú đơn hàng ..."
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AiOutlineEdit />
									</InputAdornment>
								),
							}}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkedA}
									onChange={handleChange}
									name="checkedB"
									color="primary"
								/>
							}
							label="Đánh dấu tất cả đã xong dịch vụ"
						/>
						<button className="btn btn-edit">Hoàn thành</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpaDrawersBill;
