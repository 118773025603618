export const goodsType = {
	GOODS: {
		value: 'GOODS',
		label: 'hàng hóa',
		filter: {
			$or: [
				{ is_goods: true },
				{
					$and: [
						{ is_service: { $ne: true } },
						{ is_package: { $ne: true } },
						{ is_card: { $ne: true } },
						{ is_combo: { $ne: true } },
					],
				},
			],
		},
	},
	SERVICE: {
		value: 'SERVICE',
		label: 'dịch vụ',
		filter: { is_service: true },
	},
	PACKAGE: {
		value: 'PACKAGE',
		label: 'gói dịch vụ',
		filter: { is_package: true },
	},
	CARD: {
		value: 'CARD',
		label: 'thẻ tài khoản',
		filter: { is_card: true },
	},
};
