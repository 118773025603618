import React from 'react';
import { GrAdd, GrEdit } from 'react-icons/gr';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldSelect from '../../components/Dialog/FieldSelect';

export default function ListStaffDialog({ open, handleClose, handleOpenDialogItem, data }) {
	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			title={'Thêm nhân viên'}
			actions={[
				<button className='btn btn-fill' onClick={handleClose}>
					<div>Lưu</div>
				</button>,
				<button className='btn btn-grey'>
					<div>Bỏ qua</div>
				</button>,
			]}
		>
			<div>
				<FieldInput title='Mã phòng/Vị trí' onChange={console.log} check={true} />
				<FieldInput title='Tên phòng' onChange={console.log} check={true} />
				<FieldSelect
					title='Nhóm'
					items={[
						{
							label: '15',
							value: 15,
						},
						{
							label: '30',
							value: 30,
						},
						{
							label: '45',
							value: 45,
						},
						{
							label: '60',
							value: 60,
						},
					]}
					onChange={console.log}
					placeholder='--- Tất cả ---'
					actions={
						<GrAdd
							className='icon_add_field_select'
							onClick={() => {
								handleOpenDialogItem();
							}}
						/>
					}
				/>
				<FieldInput
					title='Số thứ tự'
					type={'number'}
					onChange={console.log}
					defaultValue={'0'}
					textAlign={'right'}
				/>
				<FieldInput title='Ghi chú' onChange={console.log} icon={<GrEdit style={{ margin: '0 4px' }} />} />
			</div>
		</Dialog>
	);
}
