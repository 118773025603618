import React from 'react';
import { capitalize, formatDateDisplay } from '../../../utils/helpers';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import CustomerFilter from './CustomerFilter';
import FormCustomerDialog from './Dialog/form/FormCustomerDialog';

export const customerTypes = {
	KH_TIEM_NANG: 'kh_tiem_nang',
	KH_CHINH_THUC: 'kh_chinh_thuc',
	ALL: 'customer',
};

export const customerTypesData = {
	kh_tiem_nang: {
		title: 'Khách hàng tiềm năng',
	},
	kh_chinh_thuc: {
		title: 'Khách hàng chính thức',
	},
	customer: {
		title: 'Tất cả khách hàng',
	},
};

function ListCustomer({ type: initType = customerTypes.ALL, isSupplier = false }) {
	const subfix = isSupplier ? 'nhà cung cấp' : 'khách hàng';

	const [type, setType] = React.useState(initType);

	const columns = [
		{
			name: `Mã ${subfix}`,
			selector: (row) => row.ma_kh,
			sortable: true,
		},
		{
			name: `Tên ${subfix}`,
			selector: (row) => row.ten_kh,
			sortable: true,
		},
		{
			name: 'Điện thoại',
			selector: (row) => row.dien_thoai,
			sortable: true,
		},
		{
			name: 'Ngày tạo',
			selector: (row) => formatDateDisplay(row.date_created),
			sortable: true,
		},
		{
			name: 'Nợ hiện tại',
			selector: (row) => row.currentDebt,
			sortable: true,
		},
		{
			name: 'Tổng bán',
			selector: (row) => row.totalSale,
			sortable: true,
		},
		{
			name: 'Tổng bán trừ trả hàng',
			selector: (row) => row.totalSaleMinusReturn,
			sortable: true,
		},
	];

	return (
		<ListBase
			api={type}
			initFilter={
				isSupplier ? { isseller: isSupplier } : { $or: [{ kh_yn: true }, { isseller: { $ne: isSupplier } }] }
			}
			title={capitalize(subfix)}
			columns={columns}
			FilterComponent={(props) => (
				<CustomerFilter {...props} isSupplier={isSupplier} initType={initType} type={type} setType={setType} />
			)}
			FormDialog={(props) => <FormCustomerDialog {...props} isSupplier={isSupplier} subfix={subfix} />}
		/>
	);
}

export default withContainer(ListCustomer);
