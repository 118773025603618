import React from 'react';
import styled from 'styled-components';
import { Box, Paper, Typography } from '@material-ui/core';
import LineProduct from './LineProduct';
import { HiOutlinePencil } from 'react-icons/hi';
import { formatCurrencyDisplay } from '../../../../../../utils/helpers';
import { cloneDeep } from 'lodash';
import { SaleContext } from '../../SaleContainer';
import { useCore } from '../../../../../../hooks/useCore';

const BillSaleStyled = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: ${(props) => (props.fastMode ? '0px' : '10px')};
`;
const BillInfo = styled(Paper)`
	width: calc(100% - 5px);
	height: 152px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
`;
const BillInfoTop = styled(Box)`
	width: 100%;
	height: 90px;
	display: flex;
	flex-direction: column;
	border-bottom: 1px dashed #ccc;
`;
const BillNote = styled(Box)`
	width: 100%;
	height: 42px;
	display: flex;
	align-items: center;
`;
const BillNoteFastMode = styled(Paper)`
	width: calc(100% - 5px);
	height: 42px;
	display: flex;
	align-items: center;
	overflow: hidden;
`;

const BillNoteInput = styled.input`
	flex: 1;
	height: 100%;
	border: none;
	outline: none;
	font-size: 13px;
`;
const BillNoteIcon = styled(Box)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const BillTotal = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
`;
const BillTotlalText = styled(Typography)`
	font-size: 13px;
`;
const BillTotalNumber = styled(Box)`
	width: 20px;
	height: 20px;
	font-size: 12px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	margin-left: 10px;
`;
const BillTotalPrice = styled(Typography)`
	font-size: 13px;
	font-weight: 550;
	margin-left: 30px;
	margin-left: auto;
`;

const ListLineProducts = styled(Box)`
	height: ${(props) =>
		props.fastMode
			? `calc(100vh - 50px - 20px - 20px - 42px - 42px - 42px - 10px)`
			: `calc(100vh - 50px - 20px - 20px - 152px - 42px - 42px - 10px)`};
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-right: 4px;
`;

function BillSale({ voucher, add, handleBillChange, handleSavePBL }) {
	const { saleMode, getVoucher } = React.useContext(SaleContext);
	const { contextAlert } = useCore();

	const [details, setDetails] = React.useState(voucher?.details);
	const [note, setNote] = React.useState('');

	// tinh so luong san pham chiet khau
	const soLuongChietKhau = React.useMemo(() => {
		return (details || []).reduce((number, detail) => {
			if (detail.tien_ck_nt) {
				return (number += 1);
			} else {
				return number;
			}
		}, 0);
	}, [details]);

	// handle note change
	const handleUpdateNote = async () => {
		try {
			if (voucher?.dien_giai !== note) {
				const voucherCopy = cloneDeep(voucher);
				voucherCopy.dien_giai = note;
				await handleSavePBL(voucherCopy);
				getVoucher();
			}
		} catch (error) {
			contextAlert(error);
		}
	};

	React.useEffect(() => {
		if (voucher) {
			setNote(voucher?.dien_giai);
			setDetails(voucher.details);
		}
	}, [voucher]);

	return (
		<BillSaleStyled fastMode={saleMode === 2}>
			<ListLineProducts fastMode={saleMode === 2} className="custom-scroll-y">
				{details?.length === 0 ? (
					<Typography
						style={{
							width: '100%',
							padding: '10px 0',
							textAlign: 'center',
							fontSize: '13px',
							fontStyle: 'italic',
						}}
					>
						Chưa có sản phẩm
					</Typography>
				) : (
					details?.map((detail, index) => (
						<LineProduct
							key={detail?._id}
							detail={detail}
							index={index}
							add={add}
							details={details}
							handleBillChange={handleBillChange}
						/>
					))
				)}
			</ListLineProducts>
			{saleMode === 1 ? (
				<BillInfo>
					<BillInfoTop>
						<BillTotal>
							<BillTotlalText>Tiền hàng</BillTotlalText>
							<BillTotalNumber>{details?.length || 0}</BillTotalNumber>
							<BillTotalPrice>{formatCurrencyDisplay(voucher?.t_tien_nt)}</BillTotalPrice>
						</BillTotal>
						<BillTotal>
							<BillTotlalText>Chiết khấu sản phẩm</BillTotlalText>
							<BillTotalNumber>{soLuongChietKhau}</BillTotalNumber>
							<BillTotalPrice>
								{formatCurrencyDisplay(voucher?.t_ck_nt - voucher?.tien_ck_hd)}
							</BillTotalPrice>
						</BillTotal>
						<BillTotal>
							<BillTotlalText>Thanh toán</BillTotlalText>
							<BillTotalNumber>{details?.length || 0}</BillTotalNumber>
							<BillTotalPrice>{formatCurrencyDisplay(voucher?.t_tt_nt)}</BillTotalPrice>
						</BillTotal>
					</BillInfoTop>
					<BillNote>
						<BillNoteIcon>
							<HiOutlinePencil fontSize="16px" />
						</BillNoteIcon>
						<BillNoteInput
							value={note}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									handleUpdateNote();
								}
							}}
							onChange={(e) => setNote(e.target.value)}
							onBlur={handleUpdateNote}
							placeholder="Ghi chú đơn hàng"
						/>
					</BillNote>
				</BillInfo>
			) : (
				<BillNoteFastMode>
					<BillNoteIcon>
						<HiOutlinePencil fontSize="16px" />
					</BillNoteIcon>
					<BillNoteInput
						value={note}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								handleUpdateNote();
							}
						}}
						onChange={(e) => setNote(e.target.value)}
						onBlur={handleUpdateNote}
						placeholder="Ghi chú đơn hàng"
					/>
				</BillNoteFastMode>
			)}
		</BillSaleStyled>
	);
}

export default BillSale;
