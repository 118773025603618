import { Checkbox, FormControlLabel } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import vi from 'date-fns/locale/vi';
import dayjs from 'dayjs';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import DetailVoucherHD3 from '../DetailVoucherHD3';
import { useCore } from '../../../../../hooks';
import Dialog from '../../../components/Dialog/Dialog';
import FieldInput from '../../../components/Dialog/FieldInput';
import FieldSelectApi from '../../../components/Dialog/FieldSelectApi';
import { formatNumberWithSpaces } from '../../Utils';

const defaultValues = { status: true };
function CopyVoucherHD3({ open, handleClose, data, title, api }) {
	const [checkedFolow, setCheckedFolow] = useState(false);
	const [checkedEdit, setCheckedEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDataVoucherDetail, setIsDataVoucherDetail] = useState();
	const [isDataVoucherDetailChange, setIsDataVoucherDetailChange] = useState();
	const context = useContext(DefaultContext);
	const core = useCore();
	const [isFieldNt, setIsFieldNt] = useState({
		checkEdit: false,
		ma_nt: data[0]?.ma_nt,
		ty_gia: data[0]?.ty_gia,
	});
	const hookForm = useForm({
		defaultValues,
	});
	const datePickerRef = useRef(null);

	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {
			// Thực hiện đóng datepicker khi nhấn tab
			datePickerRef.current.setOpen(false);
		}
		if (e.key === 'Enter') {
			// Thực hiện đóng datepicker và focus vào input tiếp theo khi nhấn enter
			datePickerRef.current.setOpen(false);
			document.getElementById('ma_ht1').focus();
		}
	};

	const {
		getValues,
		setValue,
		register,
		formState: { errors },
		control,
		handleSubmit,
		reset,
		// formState: { isSubmitting },
	} = hookForm;

	const handleChangeFolow = (event) => {
		setCheckedFolow(event.target.checked);
	};

	const handleChangeEdit = (event) => {
		setCheckedEdit(event.target.checked);
	};

	useEffect(() => {
		reset({
			...defaultValues,
			...data[data.length - 1],
		});
	}, [data, reset]);

	// useEffect(() => {
	// 	setIsDateVoucherItem(...data);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const onSubmit = async (data) => {
		const isDataVoucherDetailNew = isDataVoucherDetail.filter((item) => item.ma_vt !== '');
		const isDataVoucherDetailChangeNew = isDataVoucherDetailChange?.filter((item) => item.ma_vt !== '');
		// xoá các trường line trong isDataVoucherDetailNew
		isDataVoucherDetailNew.forEach((item) => {
			item.line = '';
		});
		isDataVoucherDetailChangeNew &&
			isDataVoucherDetailChangeNew.forEach((item) => {
				item.line = '';
			});
		// eslint-disable-next-line no-useless-catch
		// xoá _id để không bị trùng lặp
		delete data._id;
		setIsLoading(true);
		try {
			await context.apis.asyncPostList(context.userInfo.token, api, {
				...data,
				details: isDataVoucherDetailNew,
				details_doi: isDataVoucherDetailChangeNew,
			});
			toast.success('Tạo thành công');
			// eslint-disable-next-line no-useless-catch
		} catch (error) {
			throw error;
		} finally {
			setIsLoading(false);
		}
	};

	const onSave = (data) =>
		onSubmit(data)
			.then(() => {
				handleClose();
			})
			.catch((error) => {
				core.contextAlert(error);
			});

	const onSaveAndCopy = (data) =>
		onSubmit(data)
			.then(() => {
				delete data._id;
				data.so_ct = '';
				data.ngay_ct = new Date();

				reset(data);
			})
			.catch((error) => {
				core.contextAlert(error);
			});

	const handleGetDataDetail = (data) => {
		setIsDataVoucherDetail(data);
	};
	const handleGetDataDetailChange = (data) => {
		setIsDataVoucherDetailChange(data);
	};

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			loading={isLoading}
			title={`${title} - tạo chứng từ mới`}
			classNameContent={'dialog__content_edit_voucher_body'}
			actions={[
				// eslint-disable-next-line react/jsx-key
				<button className="btn btn-fill" onClick={handleSubmit(onSave)} id="btnSave">
					<SaveIcon style={{ marginRight: '10px' }} />
					<div>Lưu</div>
				</button>,
				// eslint-disable-next-line react/jsx-key
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndCopy)}>
					<FileCopyIcon style={{ marginRight: '10px' }} />
					<div>Lưu & Sao chép</div>
				</button>,
				// eslint-disable-next-line react/jsx-key
				<button className="btn btn-grey" onClick={handleClose}>
					<ErrorOutlineIcon style={{ marginRight: '10px' }} />
					<div>Bỏ qua</div>
				</button>,
			]}
		>
			<div className="dialog__content_edit_voucher column-start">
				<div className="dialog__content_edit_voucher__detail">
					<div className="dialog__content_edit_voucher__detail_left column-start">
						<FieldInput
							title="Số chứng từ"
							inputProps={{
								...register('so_ct'),
							}}
						/>
						<FieldInput
							title="Loại chứng từ"
							// check={true}
							// inputProps={{
							// 	...register("so_ct"),
							// }}
						/>
						<div className="flex-start box_date_picker_payment">
							<div className="title_date_picker_payment">
								Ngày hạch toán
								<span className="start_check_dialog_field">*</span>
							</div>
							<Controller
								control={control}
								name="ngay_ct"
								render={({ field: { onChange, value } }) => (
									<DatePicker
										ref={datePickerRef}
										onKeyDown={handleKeyDown}
										placeholderText="Chọn ngày"
										className="date_picker_payment"
										selected={dayjs(value).toDate()}
										onChange={onChange}
										dateFormat={'dd/MM/yyyy'}
										locale={vi}
									/>
								)}
							/>
						</div>
						<FieldInput
							id={'ma_ht1'}
							title="Mã hạch toán 1"
							// check={true}
							// inputProps={{
							// 	...register("so_ct"),
							// }}
						/>
						{/* <FieldInput
							title="Mã hạch toán 2"
							check={true}
							inputProps={{
								...register("so_ct"),
							}}
						/> */}
						<Controller
							name="ma_nt"
							control={control}
							rules={{ required: 'Vui lòng chọn ngoại tệ' }}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ma_nt') + ' - (Tỷ giá: ' + getValues('ty_gia') + ')',
											// eslint-disable-next-line no-mixed-spaces-and-tabs
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ma_nt', item?.ma_nt || '');
									setValue('ty_gia', item?.ty_gia || '');
									setIsFieldNt({
										ma_nt: item?.ma_nt || '',
										ty_gia: item?.ty_gia || '',
									});
								};

								return (
									<FieldSelectApi
										title="Ngoại tệ"
										placeholder="Chọn ngoại tệ"
										api="currency"
										check
										errorMsg={errors.ma_nt?.message}
										valueField="ma_nt"
										labelField="ty_gia"
										value={item}
										q={{ status: true }}
										onChange={handleChange}
										getLabel={(item) => {
											return `${item?.ma_nt} - (Tỷ giá: ${item?.ty_gia})`;
										}}
									/>
								);
							}}
						/>
						<FieldInput
							title="Người giao dịch"
							inputProps={{
								...register('ong_ba'),
							}}
						/>
					</div>
					<div className="dialog__content_edit_voucher__detail_right column-start">
						<Controller
							name="ma_kh"
							control={control}
							rules={{ required: 'Vui lòng chọn nhà cung cấp' }}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_kh') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ma_kh', item?.ma_kh || '');
									setValue('ten_kh', item?.ten_kh || '');
								};

								return (
									<FieldSelectApi
										title="Nhà cung cấp"
										placeholder="Chọn nhà cung cấp"
										api="customer"
										errorMsg={errors.ma_kh?.message}
										check
										valueField="ma_kh"
										labelField="ten_kh"
										value={item}
										q={{ status: true }}
										t={1}
										onChange={handleChange}
									/>
								);
							}}
						/>
						<Controller
							name="trang_thai"
							control={control}
							rules={{ required: 'Vui lòng chọn trạng thái' }}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ten_trang_thai'),
											// eslint-disable-next-line no-mixed-spaces-and-tabs
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.ma_trang_thai || null);
									setValue('ten_trang_thai', item?.ten_trang_thai || '');
								};

								return (
									<FieldSelectApi
										title="Trạng thái"
										placeholder="Chọn trạng thái"
										check
										api="trangthai"
										errorMsg={errors.trang_thai?.message}
										valueField="ma_trang_thai"
										labelField="ten_trang_thai"
										value={item}
										q={{ status: true, ma_ct: api }}
										onChange={handleChange}
										withIdApp={false}
									/>
								);
							}}
						/>
						<Controller
							name="tk_co"
							control={control}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('tk_co') + ' - ' + getValues('ten_tk_co'),
											// eslint-disable-next-line no-mixed-spaces-and-tabs
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('tk_co', item?.tk || '');
									setValue('ten_tk_co', item?.ten_tk || '');
								};

								return (
									<FieldSelectApi
										title="Tài khoản có"
										placeholder="Chọn tài khoản có"
										api="account"
										valueField="tk"
										labelField="ten_tk"
										onChange={handleChange}
										value={item}
										q={{ status: true }}
										t={1}
										prefix={true}
										getLabel={(item) => {
											return `${item?.tk} - ${item?.ten_tk}`;
										}}
									/>
								);
							}}
						/>
						<Controller
							name="ma_dvcs"
							control={control}
							rules={{ required: 'Vui lòng chọn đơn vị' }}
							render={({ field: { onChange, value } }) => {
								const item = value ? { value, label: getValues('ten_dvcs') } : null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ma_dvcs', item?._id || '');
									setValue('ten_dvcs', item?.ten_dvcs || '');
								};

								return (
									<FieldSelectApi
										title="Đơn vị"
										placeholder="Nhập đơn vị"
										errorMsg={errors.ma_dvcs?.message}
										check
										api="dvcs"
										valueField="ma_dvcs"
										labelField="ten_dvcs"
										value={item}
										q={{ status: true }}
										t={1}
										onChange={handleChange}
									/>
								);
							}}
						/>
						<Controller
							name="ma_kho"
							control={control}
							rules={{ required: 'Vui lòng chọn chi nhánh' }}
							render={({ field: { onChange, value } }) => {
								const item = value
									? {
											value,
											label: getValues('ma_kho'),
											// eslint-disable-next-line no-mixed-spaces-and-tabs
									  }
									: null;
								const handleChange = (item) => {
									onChange(item?.value || null);
									setValue('ma_kho', item?.ma_kho || '');
									setValue('ten_kho', item?.ten_kho || '');
								};

								return (
									<FieldSelectApi
										title="Chi nhánh"
										check
										placeholder="Nhập chi nhánh"
										errorMsg={errors.ma_kho?.message}
										api="dmkho"
										valueField="ma_kho"
										labelField="ma_kho"
										onChange={handleChange}
										value={item}
										q={{ status: true }}
										t={1}
										getLabel={(item) => `${item?.ma_kho} - ${item?.ten_kho}`}
									/>
								);
							}}
						/>
						<FieldInput
							title="Diễn giải"
							inputProps={{
								...register('dien_giai'),
							}}
						/>
					</div>
				</div>
				<DetailVoucherHD3
					data={data[data.length - 1]}
					readonly={false}
					handleGetDataDetail={handleGetDataDetail}
					handleGetDataDetailChange={handleGetDataDetailChange}
					view={false}
					selectRow={true}
					isFieldNt={isFieldNt}
				/>
				<div className="dialog__content_edit_voucher__body_checkbox flex-center">
					<div>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkedFolow}
									onChange={handleChangeFolow}
									name="checkedFolow"
									color="primary"
								/>
							}
							label="Theo dõi thanh toán"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkedEdit}
									onChange={handleChangeEdit}
									name="checkedEdit"
									color="primary"
								/>
							}
							label="Sửa tỉ giá ghi sổ"
						/>
					</div>
					<div className="flex-center">
						<span
							style={{
								marginRight: '10px',
							}}
						>
							Tổng tiền:{' '}
						</span>
						<span>
							{getValues('t_tt_nt') ? formatNumberWithSpaces(getValues('t_tt_nt')) + ' VND' : '0 VND'}
						</span>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default CopyVoucherHD3;
