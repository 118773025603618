import React from 'react';
import Dialog from '../../Dialog/Dialog';
import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import FieldSelectApi from '../../Dialog/FieldSelectApi';
import FieldInput from '../../Dialog/FieldInput';
import ButtonBase from '../components/button/ButtonBase';
import { v4 } from 'uuid';

const OpenShiftWrapper = styled(Box)`
	max-width: 90vw;
	padding-top: 20px;
`;
const CheckboxWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function OpenShiftDialog({ open, onClose }) {
	const theme = useTheme();
	const {
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			ca: null,
			start_time: '',
			end_time: '',
		},
	});

	return (
		<Dialog
			title="Mở ca làm việc"
			open={open}
			handleClose={onClose}
			width="600px"
			actions={[
				<ButtonBase key={v4()} variant="outline" onClick={onClose}>
					Hủy bỏ
				</ButtonBase>,
				<ButtonBase key={v4()}>Mở ca</ButtonBase>,
			]}
		>
			<OpenShiftWrapper>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Controller
							control={control}
							name="ca"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									fieldBoxProps={{ colWidthTitle: 3 }}
									api="dmca"
									title="Ca làm việc"
									placeholder="Ca làm việc"
									value={value ? { value: value.ma_ca, label: value.ten_ca } : null}
									onChange={onChange}
									valueField="ma_ca"
									labelField="ten_ca"
									errorMsg={errors?.ca?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FieldInput
							title="Từ"
							type="time"
							inputProps={{ disabled: true }}
							fieldBoxProps={{ colWidthTitle: 6 }}
						/>
					</Grid>
					<Grid item xs={6}>
						<FieldInput
							title="Đến"
							type="time"
							inputProps={{ disabled: true }}
							fieldBoxProps={{ colWidthTitle: 6 }}
						/>
					</Grid>
					<Grid item xs={12}>
						<CheckboxWrapper>
							<input type="checkbox" id="using" style={{ accentColor: theme.palette.primary.main }} />
							<label htmlFor="using">Lấy từ số bàn giao của ca trước</label>
						</CheckboxWrapper>
					</Grid>
					<Grid item xs={12}>
						<FieldInput title="Tiền quỹ đẫu ca" type="number" fieldBoxProps={{ colWidthTitle: 3 }} />
					</Grid>
					<Grid item xs={12}>
						<Controller
							control={control}
							name="ket"
							render={({ field: { onChange, value } }) => (
								<FieldSelectApi
									fieldBoxProps={{ colWidthTitle: 3 }}
									api="dmket"
									title="Két đựng tiền"
									placeholder="Két đựng tiền"
									value={value ? { value: value.ma_ket, label: value.ten_ket } : null}
									onChange={onChange}
									valueField="ma_ket"
									labelField="ten_ket"
								/>
							)}
						/>
					</Grid>
				</Grid>
			</OpenShiftWrapper>
		</Dialog>
	);
}

export default OpenShiftDialog;
