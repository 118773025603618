import React from 'react';
import styles from './Loading.module.css';

export default function Loading({ height = 48 }) {
	return (
		<div className={styles.loaderRectangle} style={{ height }}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}
