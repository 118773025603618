import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import Container from '../Container';
import BookingCalender from './BookingCalender';
import BookingFilter from './BookingFilter';
import BookingList from './BookingList';
import { bookingList } from './data';
import './ListBooking.css';

function ListBookingContent() {
    const classes = useStyles();

    const [displayType, setDisplayType] = useState('grid');
    const toggleDisplayType = () => {
        setDisplayType(displayType === 'grid' ? 'list' : 'grid');
    };

    return (
        <div className={classes.root}>
            {/* Filter */}
            <BookingFilter
                displayType={displayType}
                toggleDisplayType={toggleDisplayType}
            />

            {displayType === 'grid' ? (
                <BookingCalender data={bookingList} />
            ) : (
                <BookingList data={bookingList} />
            )}
        </div>
    );
}

export default function ListBooking(props) {
    return (
        <Container {...props} showDrawerIfIsDesktop={false}>
            <ListBookingContent />
        </Container>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        padding: 16,
        boxSizing: 'border-box',
    },
    shadowBox: {
        backgroundColor: '#fff',
        boxShadow: 'rgb(0 0 0 / 5%) 0px 20px 27px 0px',
        borderRadius: 8,
        fontSize: 12,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
    },
}));
