import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';
import FieldSelectApi from '../../components/Dialog/FieldSelectApi';

export default function FormGoodsGroupDialog({ open, onClose, data, onSubmit }) {
	const q = { bac: { $lt: 3 } }; // bac < 3

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { isSubmitting, errors },
		control,
		watch,
	} = useForm();

	useEffect(() => {
		if (open) reset(data || {});
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			loading={isSubmitting}
			title={watch('_id') ? 'Chỉnh sửa nhóm hàng hoá' : 'Thêm nhóm hàng hoá'}
			actions={[
				<button className="btn btn-fill" onClick={handleSubmit(onSave)} disabled={isSubmitting}>
					Lưu
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndNew)} disabled={isSubmitting}>
					Lưu và thêm mới
				</button>,
				<button className="btn btn-fill" onClick={handleSubmit(onSaveAndCopy)} disabled={isSubmitting}>
					Lưu và sao chép
				</button>,
				<button className="btn btn-grey" onClick={onClose} disabled={isSubmitting}>
					Hủy
				</button>,
			]}
			width={600}
		>
			<Grid container spacing={2}>
				{/* <Grid item xs={12}>
					<FieldInput
						title='Mã nhóm'
						placeholder='Nhập mã nhóm'
						inputProps={{
							...register('ma_nvt', { required: 'Vui lòng nhập mã nhóm' }),
						}}
						check
						error={errors.ma_nvt}
					/>
				</Grid> */}

				<Grid item xs>
					<FieldInput
						title="Tên nhóm"
						placeholder="Nhập tên nhóm"
						inputProps={{
							...register('ten_nvt', { required: 'Vui lòng nhập tên nhóm' }),
						}}
						check
						error={errors.ten_nvt}
					/>
				</Grid>

				<Grid item xs="auto">
					<Controller
						name="service_yn"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControlLabel
								control={<Checkbox size="small" />}
								label="Là nhóm dịch vụ"
								checked={!!value}
								onChange={(checked) => {
									onChange(checked);
									setValue('nh_me', ''); // reset nhóm mẹ
									setValue('ten_nh_me', ''); // reset tên nhóm mẹ
								}}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<Controller
						name="nh_me"
						control={control}
						render={({ field: { onChange, value } }) => {
							const item = value ? { value, label: getValues('ten_nh_me') } : null;
							const handleChange = (item) => {
								onChange(item?.value || null);
								setValue('ten_nh_me', item?.label || '');
							};

							// add query if edit
							const _id = watch('_id');
							if (_id) {
								q._id = { $ne: _id };
								q.nh_me = { $ne: _id };
							}

							return (
								<FieldSelectApi
									title="Nhóm mẹ"
									api="dmnvt"
									q={{ ...q, service_yn: watch('service_yn') || { $ne: true } }}
									valueField="_id"
									labelField="ten_nvt"
									placeholder="Chọn nhóm mẹ"
									value={item}
									onChange={handleChange}
								/>
							);
						}}
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
}
