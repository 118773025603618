import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import TableContainer from '../../../../components/table/TableContainer';

const columns = [
	{
		name: 'Kho',
		selector: (row) => row.ten_kho,
		sortable: true,
	},
	{
		name: 'Tồn kho',
		selector: (row) => row.ton,
		sortable: true,
		width: '100px',
		right: true,
	},
];

export default function InventoryTab({ data }) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<TableContainer columns={columns} data={data} />
		</div>
	);
}

const useStyles = makeStyles(() => ({
	root: {},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: 16,
		'& > button': {
			marginLeft: 8,
		},
	},
}));
