import { Box, Paper, Tooltip, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { AiFillCaretDown, AiFillCaretRight, AiOutlineInfoCircle } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { FiMinus, FiPlus } from 'react-icons/fi';
import styled from 'styled-components';
import { formatCurrencyDisplay } from '../../../../../../utils/helpers';
import Dialog from '../../../Dialog/Dialog';
import Confirm from '../../components/confirm/Confirm';
import MenuChietKhau from './MenuChietKhau';
import ProductOption from './ProductOption';
import SearchStaff from './SearchStaff';
import TonKhoDialog from './TonKhoDialog';
import { IoIosClose } from 'react-icons/io';
import { useCore } from '../../../../../../hooks';
import moment from 'moment';
import SelectLoDialog from './SelectLoDialog';
import { cloneDeep } from 'lodash';

const LineProductStyled = styled(Paper)`
	flex-shrink: 0;
	padding: 5px;
	&:hover {
		.ton-kho-icon {
			display: flex;
		}
	}
`;
const ProductTop = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ProductInfo = styled(Box)`
	display: flex;
	align-items: center;
	flex: 1;
	height: 34px;
`;
const ProductStt = styled(Typography)`
	font-size: 13px;
`;
const DeleteIcon = styled(Typography)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	margin-left: 10px;
	&:hover {
		background-color: #ededed;
	}
`;
const ProductMa = styled(Typography)`
	font-size: 13px;
	font-weight: 400;
	margin-left: 20px;
`;
const ProductName = styled(Typography)`
	font-size: 13px;
	font-weight: 400;
	margin-left: 20px;
`;
const Icon = styled(Box)`
	display: none;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin-left: 5px;
	color: #888;
	cursor: pointer;
	transition: all linear 0.1s;
	&:hover {
		background-color: #ededed;
	}
`;

const ProductActions = styled(Box)`
	display: flex;
	align-items: center;
	height: 34px;
`;
const ProductDonVi = styled(Typography)`
	font-size: 13px;
	padding: 2px;
	border-radius: 20px;
	min-width: 30px;
	text-align: center;
	color: ${(props) => props.color};
	background-color: #ededed;
`;

// product bottom
const ProductBottom = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	height: auto;
	min-height: 34px;
	gap: 10px;
`;
const CollapseIconWrapper = styled(Box)`
	width: 24px;
	height: 24px;
`;
const CollapseIcon = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all linear 0.1s;
	cursor: pointer;
	&:hover {
		background-color: #ededed;
	}
`;
const ProductNumber = styled(Box)`
	flex: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		& .btn-number {
			display: flex;
		}
	}
`;
const ProductNumberWrapper = styled(Box)`
	position: relative;
	width: 60px;
	height: 25px;
	border-bottom: 1px solid #ccc;
	&:focus-within {
		border-color: ${(props) => props.borderColor};
	}
`;
const InputNumber = styled.input`
	width: 100%;
	height: 100%;
	outline: none;
	text-align: center;
	font-size: 13px;
	border: none;
`;
const NumberButton = styled(Box)`
	display: none;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(-100%, -50%);
	width: 25px;
	height: 25px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all linear 0.1s;
	cursor: pointer;
	&:hover {
		background-color: #ededed;
	}
	&.plus {
		top: 50%;
		left: 100%;
		transform: translate(0, -50%);
	}
`;
const ProductPrice = styled(Box)`
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const InputPriceWrapper = styled(Box)`
	width: 80px;
	height: 24px;
	border-bottom: 1px solid #ccc;
	cursor: pointer;
`;
const InputPrice = styled.input`
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	text-align: right;
	font-size: 13px;
	background-color: transparent;
	pointer-events: none;

	width: 80px;
	height: 24px;
	border-bottom: 1px solid #ccc;
`;
const InputPrice2 = styled.input`
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	text-align: right;
	font-size: 13px;
	background-color: transparent;
	cursor: pointer;
`;
const SubtractText = styled(Typography)`
	font-size: 13px;
	width: 80px;
	height: 24px;
	text-align: right;
	color: red;
`;
const ProductTotalPrice = styled(Box)`
	flex: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 550;
`;
const ProductMoreInfo = styled(Box)`
	width: 100%;
	height: ${(props) => (props.active ? 'auto' : '0px')};
	font-size: 13px;
	overflow: hidden;
	transition: all linear 0.1s;
	transform-origin: center top;
`;
const SelectLo = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
`;
const LoItem = styled(Box)`
	height: 20px;
	border-radius: 2px;
	background-color: ${(props) => props.bgColor};
	color: #fff;
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 12px;
	padding: 5px;
`;
const LoItemIcon = styled(Box)`
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	cursor: pointer;
	transition: all linear 0.01s;
	border-radius: 50%;
	&:hover {
		background-color: #fff;
		color: ${(props) => props.color};
	}
`;
const ButtonLoDialog = styled(Typography)`
	font-size: 12px;
	color: ${(props) => props.color};
	cursor: pointer;
`;

function LineProductDoi({ detail, index, add, details, handleBillChange }) {
	const theme = useTheme();
	const { context, contextAlert, apiCall } = useCore();
	const confirmRef = React.useRef();
	const [number, setNumber] = React.useState(detail.sl_xuat);
	const [openTonKhoDialog, setOpenTonKhoDialog] = React.useState(false);
	const [anchorChietKhau, setAnchorChietKhau] = React.useState(null);
	const [collapse, setCollapse] = React.useState(false);
	const [originProduct, setOriginProduct] = React.useState();
	const [openSelectLo, setOpenSelectLo] = React.useState(false);
	const [consignments, setConsignments] = React.useState([]);
	const [consignment, setConsigment] = React.useState();

	const numberCompare = React.useMemo(() => {
		return detail.sl_xuat;
	}, [detail]);

	// set item
	const setItem = (p, sl_xuat) => {
		add(p, sl_xuat, false);
	};
	const handleNumberChange = (e) => {
		const value = e.target.value;
		if (isNaN(value)) {
			return;
		} else {
			setNumber(Number(value));
		}
	};
	const handleOrderChanged = (product) => {
		if (numberCompare === number) return;
		let numberProduct = number;
		if (numberProduct === 0) {
			numberProduct = 1;
		}
		add(product, numberProduct, false);
		// numberCompare = number;
	};
	const handleSaveConsignment = async (newConsignment) => {
		try {
			const detailClone = cloneDeep(detail);
			const detailsClone = cloneDeep(details);
			detailClone.ma_lo = newConsignment?.ma_lo || '';
			const index = detailsClone.findIndex((d) => d._id === detailClone._id);
			if (index >= 0) {
				detailsClone.splice(index, 1, detailClone);
			}
			await handleBillChange(detailsClone);
			setConsigment(newConsignment);
		} catch (error) {
			contextAlert(error);
		}
	};
	// get origin product
	const getOriginProduct = async () => {
		try {
			const resp = await context.apis.asyncGetData(context?.userInfo?.token, 'dmvt', { ma_vt: detail?.ma_vt });
			if (resp) {
				setOriginProduct(resp);
			}
		} catch (error) {
			contextAlert(error);
		}
	};
	// get lo
	const getLo = async () => {
		try {
			let { data } = await apiCall({
				endpoint: 'thnxt',
				params: {
					tu_ngay: moment(originProduct.date_created).format('YYYY-MM-DD'),
					ma_vt: originProduct.ma_vt,
					groupBy: 'ma_lo,han_sd',
					den_ngay: moment().format('YYYY-MM-DD'),
				},
			});
			if (data) {
				const dataToSave = data.filter((item) => item.ma_lo);
				const oldConsignment = dataToSave.find((item) => item?.ma_lo === detail?.ma_lo);
				setConsigment(oldConsignment);
				setConsignments(dataToSave);
			}
		} catch (error) {
			contextAlert(error);
		}
	};

	React.useEffect(() => {
		if (detail.ma_vt) {
			getOriginProduct();
		}
	}, [detail.ma_vt]);

	React.useEffect(() => {
		if (detail) {
			setNumber(detail.sl_xuat);
		}
	}, [detail]);
	React.useEffect(() => {
		if (originProduct) {
			getLo();
		}
	}, [originProduct]);

	return (
		<>
			{openTonKhoDialog && (
				<Dialog title="Tồn kho" open={openTonKhoDialog} handleClose={() => setOpenTonKhoDialog(false)}>
					<TonKhoDialog product={detail} />
				</Dialog>
			)}
			{openSelectLo && (
				<SelectLoDialog
					consignment={consignment}
					consignments={consignments || []}
					handleSaveConsignment={handleSaveConsignment}
					open={openSelectLo}
					handleClose={() => setOpenSelectLo(false)}
				/>
			)}
			<Confirm
				ref={confirmRef}
				content={`Xác nhận xóa '${detail.ten_vt}'.`}
				onConfirm={() => setItem(detail, 0)}
			/>
			<MenuChietKhau
				detail={detail}
				details={details}
				handleBillChange={handleBillChange}
				anchorEl={anchorChietKhau}
				handleClose={() => setAnchorChietKhau(null)}
			/>
			<LineProductStyled key={detail?._id}>
				<ProductTop>
					<ProductInfo>
						<ProductStt>{index + 1}.</ProductStt>
						<DeleteIcon onClick={confirmRef?.current?.open}>
							<BsTrash fontSize="16px" />
						</DeleteIcon>
						<ProductMa>{detail.ma_vt || 'SP004504'}</ProductMa>
						<ProductName>{detail.ten_vt || 'Orange juice'}</ProductName>
						<Tooltip arrow placement="top" title="Xem tồn kho">
							<Icon onClick={() => setOpenTonKhoDialog(true)} className="ton-kho-icon">
								<AiOutlineInfoCircle fontSize="18px" />
							</Icon>
						</Tooltip>
						<SearchStaff detail={detail} details={details} handleBillChange={handleBillChange} />
					</ProductInfo>
					<ProductActions>
						<ProductDonVi color={theme.palette.primary.main}>{detail.ma_dvt || 'DVT'}</ProductDonVi>
						<ProductOption
							details={details}
							handleBillChange={handleBillChange}
							product={detail}
							add={add}
							number={number}
							handleNumberChange={handleNumberChange}
							handleOrderChanged={handleOrderChanged}
						/>
					</ProductActions>
				</ProductTop>
				<ProductBottom>
					{/* {originProduct?.is_service ? (
						<Tooltip arrow placement="top" title={collapse ? 'Thu gọn' : 'Mở rộng'}>
							<CollapseIconWrapper>
								<CollapseIcon onClick={() => setCollapse(!collapse)}>
									{collapse ? (
										<AiFillCaretDown fontSize="14px" />
									) : (
										<AiFillCaretRight fontSize="14px" />
									)}
								</CollapseIcon>
							</CollapseIconWrapper>
						</Tooltip>
					) : (
						<CollapseIconWrapper />
					)} */}
					<ProductNumber>
						<ProductNumberWrapper borderColor={theme.palette.primary.main}>
							{detail.sl_xuat > 1 && (
								<NumberButton onClick={() => add(detail, -1)} className="btn-number">
									<FiMinus fontSize="14px" />
								</NumberButton>
							)}
							<InputNumber
								value={number}
								onChange={(e) => handleNumberChange(e)}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleOrderChanged(detail);
									}
								}}
								onBlur={() => handleOrderChanged(detail)}
							/>
							<NumberButton onClick={() => add(detail, 1)} className="btn-number plus">
								<FiPlus fontSize="14px" />
							</NumberButton>
						</ProductNumberWrapper>
					</ProductNumber>
					<ProductPrice>
						<InputPriceWrapper
							onClick={(e) => {
								setAnchorChietKhau(e.currentTarget);
							}}
							borderColor={theme.palette.primary.main}
						>
							<InputPrice
								disabled
								type="text"
								value={formatCurrencyDisplay(detail.gia_ban_nt - detail.tien_ck_nt / detail.sl_xuat)}
							/>
						</InputPriceWrapper>
						{detail.tien_ck_nt > 0 && (
							<SubtractText>-{formatCurrencyDisplay(detail.tien_ck_nt)}</SubtractText>
						)}
					</ProductPrice>
					<ProductTotalPrice>{formatCurrencyDisplay(detail.tien_nt - detail.tien_ck_nt)}</ProductTotalPrice>
				</ProductBottom>
				{consignments?.length > 0 && (
					<SelectLo>
						{consignment && (
							<LoItem bgColor={theme.palette.primary.main}>
								{consignment?.ma_lo || 'Mã lô'} -{' '}
								{consignment?.han_sd ? moment(consignment?.han_sd).format('DD/MM/YYYY') : 'hạn sử dụng'}{' '}
								- {consignment?.ton_cuoi || 'Tồn'}
								<LoItemIcon
									color={theme.palette.primary.main}
									onClick={() => handleSaveConsignment(null)}
								>
									<IoIosClose fontSize="20px" />
								</LoItemIcon>
							</LoItem>
						)}
						<ButtonLoDialog color={theme.palette.primary.main} onClick={() => setOpenSelectLo(true)}>
							Chọn lô
						</ButtonLoDialog>
					</SelectLo>
				)}
			</LineProductStyled>
		</>
	);
}

export default LineProductDoi;
