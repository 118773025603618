import React from 'react';
import { Box, Paper, Tooltip, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { BsTrash } from 'react-icons/bs';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { numeralCustom } from '../../../../../../utils/helpers';
import ProductOption from './ProductOption';
import { cloneDeep } from 'lodash';
import { SaleContext } from '../../SaleContainer';

const LineProductStyled = styled(Paper)`
	flex-shrink: 0;
	padding: 5px;
	&:hover {
		.ton-kho-icon {
			display: flex;
		}
	}
`;
const ProductTop = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ProductInfo = styled(Box)`
	display: flex;
	width: 50%;
	align-items: center;
	height: 34px;
`;
const ProductStt = styled(Typography)`
	font-size: 13px;
`;
const DeleteIcon = styled(Typography)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	margin-left: 10px;
	&:hover {
		background-color: #ededed;
	}
`;
const ProductMa = styled(Typography)`
	font-size: 13px;
	font-weight: 400;
	margin-left: 20px;
`;
const ProductName = styled(Typography)`
	font-size: 13px;
	font-weight: 400;
	margin-left: 20px;
`;
const Icon = styled(Box)`
	display: none;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	margin-left: 5px;
	color: #888;
	cursor: pointer;
	transition: all linear 0.1s;
	&:hover {
		background-color: #ededed;
	}
`;
const InputPriceWrapper = styled(Box)`
	width: 50px;
	height: 24px;
	border-bottom: 1px solid #ccc;
	cursor: pointer;
`;
const InputNumber = styled.input`
	width: 100%;
	height: 100%;
	outline: none;
	text-align: center;
	font-size: 13px;
	border: none;
`;
const ProductActions = styled(Box)`
	display: flex;
	align-items: center;
	height: 34px;
`;
const ProductDonVi = styled(Typography)`
	font-size: 13px;
	padding: 2px;
	border-radius: 20px;
	min-width: 30px;
	text-align: center;
	color: ${(props) => props.color};
	background-color: #ededed;
`;

function ReturnedLineProduct({ index, detail, handleReturnedBillChange }) {
	const theme = useTheme();
	const { voucher } = React.useContext(SaleContext);
	const [number, setNumber] = React.useState(detail.sl_nhap);

	// handle number change
	const handleNumberChange = (e) => {
		let value = e.target.value;
		if (value < 0) value = 0;
		setNumber(value);
	};
	// sl nhap change
	const handleSlNhapChange = (e) => {
		if (e.key === 'Enter' && Number(number) !== Number(detail.sl_nhap)) {
			const voucherCopy = cloneDeep(voucher);
			const indexDetail = voucherCopy.details.findIndex((item) => item?.ma_vt === detail?.ma_vt);
			const newDetail = voucherCopy.details[indexDetail];
			newDetail.sl_nhap = number;
			newDetail.tien_hang = (newDetail.gia_ban - newDetail.tien_ck) * newDetail.sl_nhap;
			handleReturnedBillChange(voucherCopy);
		}
	};
	// handle delete
	const handleDelete = () => {
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.details = voucherCopy.details.filter((item) => item.ma_vt !== detail.ma_vt);
		handleReturnedBillChange(voucherCopy);
	};

	return (
		<LineProductStyled>
			<ProductTop>
				<ProductInfo>
					<ProductStt>{index}.</ProductStt>
					<DeleteIcon onClick={handleDelete}>
						<BsTrash fontSize="16px" />
					</DeleteIcon>
					<ProductMa>{detail?.ma_vt}</ProductMa>
					<ProductName>{detail?.ten_vt}</ProductName>
				</ProductInfo>
				<InputPriceWrapper>
					<InputNumber
						type="number"
						value={number}
						onChange={handleNumberChange}
						onKeyUp={handleSlNhapChange}
						onBlur={() => handleSlNhapChange({ key: 'Enter' })}
					/>
				</InputPriceWrapper>
				<InputPriceWrapper>
					<InputNumber readOnly value={numeralCustom(detail.gia_ban - detail.tien_ck).format()} />
				</InputPriceWrapper>
				<InputPriceWrapper>
					<InputNumber
						readOnly
						style={{ fontWeight: 550 }}
						value={numeralCustom(detail.tien_hang).format()}
					/>
				</InputPriceWrapper>
				<ProductActions>
					<ProductDonVi color={theme.palette.primary.main}>{detail?.ma_dvt}</ProductDonVi>
					{/* <ProductOption
					details={details}
					handleBillChange={handleBillChange}
					product={detail}
					add={add}
					number={number}
					handleNumberChange={handleNumberChange}
					handleOrderChanged={handleOrderChanged}
					/> */}
				</ProductActions>
			</ProductTop>
		</LineProductStyled>
	);
}

export default ReturnedLineProduct;
