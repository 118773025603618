import { Grid } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldSelect from '../../../../components/Dialog/FieldSelect';
import FieldSelectApi from '../../../../components/Dialog/FieldSelectApi';
import FieldInput from '../../../../components/Dialog/FieldInput';
import dayjs from 'dayjs';

export default function TimeTab({ hookForm }) {
	const {
		control,
		watch,
		formState: { errors },
		getValues,
		setValue,
		register,
	} = hookForm;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Controller
					control={control}
					name='time_open'
					render={({ field: { onChange, value } }) => {
						const timeValue = value ? dayjs(value).format('HH:mm') : '';
						const handleChange = (e) => {
							const timeValue = e.target.value;
							const hour = timeValue.split(':')[0];
							const minute = timeValue.split(':')[1];

							const time = dayjs().hour(hour).minute(minute).second(0).millisecond(0).toDate();
							onChange(time);
						};

						return (
							<FieldInput
								title='Giờ mở cửa'
								value={timeValue}
								onChange={handleChange}
								inputProps={{ type: 'time' }}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='time_close'
					render={({ field: { onChange, value } }) => {
						const timeValue = value ? dayjs(value).format('HH:mm') : '';
						const handleChange = (e) => {
							const timeValue = e.target.value;
							const hour = timeValue.split(':')[0];
							const minute = timeValue.split(':')[1];

							const time = dayjs().hour(hour).minute(minute).second(0).millisecond(0).toDate();
							onChange(time);
						};

						return (
							<FieldInput
								title='Giờ đóng cửa'
								value={timeValue}
								onChange={handleChange}
								inputProps={{ type: 'time' }}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<FieldInput title='Thời gian delay (phút)' inputProps={{ ...register('delay_time'), type: 'number' }} />
			</Grid>

			<Grid item xs={12}>
				<FieldInput
					title='Khoảng cách thời gian (phút)'
					inputProps={{ ...register('minutes'), type: 'number' }}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='list_time_retail'
					render={({ field: { value, onChange } }) => {
						const items = value?.map((item) => ({ value: item, label: item, time: item })) || [];
						const handleChange = (items) => onChange(items?.map((item) => item.time) || []);

						return (
							<FieldSelectApi
								title='Khung giờ bán lẻ'
								api='timelist'
								q={{ status: true }}
								multi
								valueField='time'
								labelField='time'
								value={items}
								onChange={handleChange}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Controller
					control={control}
					name='list_time_off'
					render={({ field: { value, onChange } }) => {
						const items = value?.map((item) => ({ value: item, label: item, time: item })) || [];
						const handleChange = (items) => onChange(items?.map((item) => item.time) || []);

						return (
							<FieldSelectApi
								title='Khung giờ off mặc định'
								api='timelist'
								q={{ status: true }}
								multi
								valueField='time'
								labelField='time'
								value={items}
								onChange={handleChange}
							/>
						);
					}}
				/>
			</Grid>
		</Grid>
	);
}
