import React from 'react';
import ListBase from '../ListBase/ListBase';
import { formatDateDisplay, numeralCustom } from '../../../utils/helpers';
import EvoucherFilter from './EvoucherFilter';
import EvoucherForm from './EvoucherForm';
import withContainer from '../withContainer';

const columns = [
	{
		name: 'Mã thẻ',
		selector: (row) => row.ma,
		sortable: true,
		minWidth: '140px',
	},
	{
		name: 'Tên thẻ',
		selector: (row) => row.ten,
		sortable: true,
		minWidth: '140px',
		center: true,
	},
	{
		name: 'Tiền giảm giá',
		selector: (row) => row.so_tien,
		sortable: true,
		minWidth: '135px',
		center: true,
		format: (row) => numeralCustom(row.tien_ck).format(),
	},
	{
		name: 'Tỷ lệ giảm giá',
		selector: (row) => row.ty_le_ck,
		sortable: true,
		minWidth: '135px',
		center: true,
		format: (row) => numeralCustom(row.ty_le_ck).format(),
	},
	{
		name: 'Hiệu lực từ',
		selector: (row) => row.hieu_luc_tu,
		sortable: true,
		minWidth: '120px',
		format: (row) => formatDateDisplay(row.hieu_luc_tu),
	},
	{
		name: 'Hiệu lực đến',
		selector: (row) => row.hieu_luc_den,
		sortable: true,
		minWidth: '120px',
		format: (row) => formatDateDisplay(row.hieu_luc_den),
	},
	{
		name: 'Số lần sử dụng',
		selector: (row) => row.so_lan_sd,
		sortable: true,
		minWidth: '150px',
		center: true,
	},
];

function ListEvoucher() {
	return (
		<ListBase
			api="evoucher"
			title="Danh sách phiếu mua hàng"
			columns={columns}
			FilterComponent={EvoucherFilter}
			FormDialog={EvoucherForm}
		/>
	);
}

export default withContainer(ListEvoucher);
