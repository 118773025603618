import React from "react";
import {
  BarcodeHeader,
  BarcodeStyled,
  InputGroup,
  InputLabel,
  ListInput,
  Select,
} from "./barcodeStyled";
import { CssBaseline } from "@material-ui/core";
import SearchProduct from "./SearchProduct";
import ButtonBase from "../components/newSale/components/button/ButtonBase";
import BarcodeContent from "./BarcodeContent";
import DialogOption from "./DialogOption";

const barcodeOptions = [
  "CODE39",
  "CODE128",
  "CODE128A",
  "CODE128B",
  "CODE128C",
  "EAN",
  "EAN13",
  "EAN8",
  "EAN5",
  "EAN2",
  "UPC",
  "ITF",
  "ITF14",
  "MSI",
  "MSI10",
  "MSI11",
  "MSI1010",
  "MSI1110",
  "pharmacode",
  "codabar",
];

function BarcodeContainer() {
  const btnPrintRef = React.useRef();

  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [formatBarcode, setFormatBarcode] = React.useState(barcodeOptions[0]);
  const [numberColumn, setNumberColumn] = React.useState(4);
  const [showOption, setShowOption] = React.useState(false);
  const [options, setOptions] = React.useState({
    displayValue: true,
    fontSize: 14,
    width: 1,
    height: 50,
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    margin: 2,
    marginTop: 2,
    marginRight: 2,
    marginBottom: 2,
    marginLeft: 2,
  });

  // handle option change
  const handleOptionsChange = (key, value) => {
    setOptions({ ...options, [key]: value });
  };

  // handle print
  const handlePrint = () => {
    btnPrintRef.current?.click();
  };

  return (
    <>
      <CssBaseline />
      {showOption && (
        <DialogOption
          open={showOption}
          handleClose={() => setShowOption(false)}
          options={options}
          handleOptionsChange={handleOptionsChange}
        />
      )}
      <BarcodeStyled>
        <BarcodeHeader>
          <SearchProduct setSelectedProducts={setSelectedProducts} />
          <ListInput>
            <InputGroup>
              <InputLabel>Số cột</InputLabel>
              <Select
                value={numberColumn}
                onChange={(e) => setNumberColumn(Number(e.target.value))}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option selected value="4">
                  4
                </option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
              </Select>
            </InputGroup>
            <InputGroup>
              <InputLabel>Định dạng</InputLabel>
              <Select
                value={formatBarcode}
                onChange={(e) => setFormatBarcode(e.target.value)}
              >
                {barcodeOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </InputGroup>
            <ButtonBase onClick={() => setShowOption(true)} variant="outline">
              Tùy chọn
            </ButtonBase>
            <ButtonBase variant="contained" onClick={handlePrint}>
              IN
            </ButtonBase>
          </ListInput>
        </BarcodeHeader>
        <BarcodeContent
          ref={btnPrintRef}
          numberColumn={numberColumn}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          formatBarcode={formatBarcode}
          options={options}
          handleOptionsChange={handleOptionsChange}
        ></BarcodeContent>
      </BarcodeStyled>
    </>
  );
}

export default BarcodeContainer;
