import { Grid, MenuItem, Select, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import LayoutContainer from '../components/LayoutContainer';
import './WorkShift.css';

import 'react-datepicker/dist/react-datepicker.css';
import WorkShiftDialog from './Dialog/WorkShiftDialog';
import Container from '../Container';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		borderRadius: 8,
		backgroundColor: 'black',
	},
	select: {
		width: '100%',
		height: '37px',
		minWidth: '100px',
		border: '1px solid #ccc',
		borderRadius: 5,
		padding: 10,
		margin: '10px auto',
		boxShadow: '0 0 5px #ccc',
		cursor: 'pointer',
	},
}));

const names = ['Tran Van A', 'Tran Van B', 'Tran Van C', 'Tran Van D', 'Tran Van E'];

const calendarEvents = [
	{
		id: '1',
		title: 'Event 1',
		start: '2023-02-24T10:00:00',
		end: '2023-02-24T12:00:00',
		resourceId: 'a',
	},
	{
		id: '2',
		title: 'Event 2',
		start: '2023-02-25T13:00:00',
		end: '2023-02-25T15:00:00',
		resourceId: 'b',
	},
];

const calendarOptions = {
	plugins: [timeGridPlugin],
	initialView: 'timeGridWeek',
	// Hiển thị tiêu đề ngày trong header
	columnHeaderFormat: {
		weekday: 'long',
		month: 'numeric',
		day: 'numeric',
	},
	// Hiển thị tên người dùng bên trái và cột thời gian bên phải
	resourceAreaWidth: '25%',
	resourceLabelText: 'User',
	slotLabelFormat: {
		hour: 'numeric',
		minute: '2-digit',
		omitZeroMinute: true,
		meridiem: 'short',
	},
};

// Cài đặt tên người dùng
const calendarResources = [
	{ id: 'a', title: 'User A' },
	{ id: 'b', title: 'User B' },
];

function WorkShiftContent() {
	const [open, setOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const classes = useStyles();
	const [name, setName] = useState();

	function formatDate(dateString) {
		const date = new Date(dateString);

		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	}

	const handelFilter = () => {
		console.log(formatDate(startDate));
		console.log(formatDate(endDate));
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = (event) => {
		setName(event.target.value);
	};

	return (
		<LayoutContainer>
			<>
				<div className='box_workshift'>
					<div className='box_workshift__title'>Lịch làm việc</div>
					<div className='box_workshift__content'>
						<div className='box_workshift__content__action'>
							<Grid className='box_workshift__content__action__filter' container item spacing={2} xs={8}>
								<Grid item>
									{' '}
									<Select
										labelId='name-label'
										id='name'
										value={name}
										onChange={handleChange}
										className={classes.select}
									>
										{names.map((name) => (
											<MenuItem key={name} value={name}>
												{name}
											</MenuItem>
										))}
									</Select>
								</Grid>
								<Grid item>
									<DatePicker
										className='date_picker'
										selected={startDate}
										onChange={(date) => setStartDate(date)}
									/>
								</Grid>
								<Grid item>
									<DatePicker
										className='date_picker'
										selected={endDate}
										onChange={(date) => setEndDate(date)}
									/>
								</Grid>
								<Grid
									item
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<button className='btn btn-edit ml-0' onClick={handelFilter}>
										<FilterListIcon /> Lọc
									</button>
								</Grid>
							</Grid>
							<Grid className='box_workshift__content__action__setting' container spacing={2}>
								<Grid
									item
									style={{
										display: 'flex',
										justifyContent: 'left',
										alignItems: 'center',
									}}
								>
									<button onClick={handleOpen} className='btn btn-edit ml-0'>
										Cài đặt ca làm
									</button>
								</Grid>
								<Grid
									item
									style={{
										display: 'flex',
										justifyContent: 'left',
										alignItems: 'center',
									}}
								>
									<button className='btn btn-edit ml-0'>Cài đặt lịch nghỉ</button>
								</Grid>
							</Grid>
						</div>
						{/* 
							// TODO: view week and user select date
							
						*/}
						<div className='box_workshift__content__table'>
							<FullCalendar
								plugins={[timelinePlugin]}
								initialView='timelineWeek'
								schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
								slotDuration={'24:00:00'}
								options={{
									slotDuration: '24:00',
									slotLabelFormat: {
										hour: 'numeric',
										minute: '2-digit',
										omitZeroMinute: true,
										meridiem: 'short',
									},
									headerToolbar: {
										left: 'prev,next',
										center: 'title',
										right: 'today',
									},
								}}
								events={
									[
										// sự kiện
									]
								}
							/>
						</div>
					</div>
				</div>
				<WorkShiftDialog open={open} handleClose={handleClose} />
			</>
		</LayoutContainer>
	);
}

const WorkShift = (props) => {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<WorkShiftContent />
		</Container>
	);
};

export default WorkShift;
