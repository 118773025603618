import React, { useCallback, useEffect, useState } from 'react';
import { useCore } from '../../../hooks';
import LayoutContainer from '../components/LayoutContainer';
import withContainer from '../withContainer';
import GoodsData from './GoodsData';
import GoodsFilter from './GoodsFilter';

function ListGoods() {
	const [data, setData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
		fields: 'ma_vt,ten_vt,gia_ban_le,gia_ban_le0,ton_kho_mac_dinh,ton00',
	});
	const { token, asyncGetList, setProgressStatus, asyncDeleteList, contextAlert } = useCore();

	const getData = useCallback(async () => {
		setProgressStatus(true);

		try {
			const [data, count] = await Promise.all([
				asyncGetList(token, 'dmvt', params),
				asyncGetList(token, 'dmvt', {
					count: 1,
					...params,
				}),
			]);
			setData(data);
			setTotalRows(count?.rows_number || 0);
		} catch (error) {
			console.log('getData error', error);
		} finally {
			setProgressStatus(false);
		}
	}, [asyncGetList, params, setProgressStatus, token]);

	useEffect(() => {
		getData();
	}, [getData]);

	const deleteGoods = useCallback(
		(id) =>
			contextAlert('Bạn có chắc chắn muốn xóa không?', async (isConfirm) => {
				if (!isConfirm) return; // cancel

				setProgressStatus(true);

				try {
					await asyncDeleteList(token, 'dmvt', id);
					await getData();
				} catch (error) {
					console.log('deleteGoods error', error);
					contextAlert(error);
				} finally {
					setProgressStatus(false);
				}
			}),
		[asyncDeleteList, contextAlert, getData, setProgressStatus, token],
	);

	const deleteGoodsList = useCallback(
		(ids) =>
			contextAlert('Bạn có chắc chắn muốn xóa không?', async () => {
				setProgressStatus(true);

				try {
					await Promise.all(ids.map((id) => asyncDeleteList(token, 'dmvt', id)));
				} catch (error) {
					console.log('deleteGoodsList error', error);
					contextAlert(error);
				} finally {
					await getData();
					setProgressStatus(false);
				}
			}),
		[asyncDeleteList, contextAlert, getData, setProgressStatus, token],
	);

	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const onFilter = (filter) => {
		setParams({
			...params,
			q: filter,
			page: 1, // reset page
		});
	};

	return (
		<LayoutContainer FilterLeft={<GoodsFilter onFilter={onFilter} filter={params.q} />}>
			<GoodsData
				data={data}
				getData={getData}
				deleteGoods={deleteGoods}
				deleteGoodsList={deleteGoodsList}
				tableProps={{
					page: params.page,
					limit: params.limit,
					totalRows,
					handlePaginationChange,
				}}
				q={params.q}
			/>
		</LayoutContainer>
	);
}

export default withContainer(ListGoods);
