import React from 'react';
import TableContainer from '../../components/table/TableContainer';
import WebAssetRoundedIcon from '@material-ui/icons/WebAssetRounded';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import PublishIcon from '@material-ui/icons/Publish';
import { handleDownloadExcel } from '../../Utils/MyUtils';

const SaleByEmployeeData = ({ data, columns }) => {
    const downloadExcel = () => {
        handleDownloadExcel(data, 'SHEET_NAME', 'MY_FILENAME');
    };
    return (
        <TableContainer
            columns={columns}
            title={'Báo cáo bán hàng theo nhân viên'}
            data={data}
            actions={[
                <button onClick={downloadExcel}>
                    <WebAssetRoundedIcon />
                    <span className="item_title">Xem trước</span>
                </button>,
                <button onClick={downloadExcel}>
                    <PublishIcon />
                    <span className="item_title">Xuất file</span>
                </button>,
                <button onClick={downloadExcel}>
                    <PrintRoundedIcon />
                    <span className="item_title">In</span>
                </button>,
            ]}
        />
    );
};

export default SaleByEmployeeData;
