import { Button, Form, Input } from 'antd';
import React from 'react';
import { HiOutlineArrowTopRightOnSquare } from 'react-icons/hi2';
import VButton from '../../VComponent/VButton';
import VModal from '../../VComponent/VModal';
import VSelect from '../../VComponent/VSelect';
import { useCore } from '../../../../hooks';

export default function ModalLeadBasic({ data, open, onClose, onSubmit, openFullForm }) {
	const core = useCore();
	const [form] = Form.useForm();

	React.useEffect(() => {
		if (open) {
			if (data) form.setFieldsValue(data);
			else form.resetFields();
		}
	}, [open]);

	const handleError = ({ errorFields }) => {
		const firstError = errorFields[0];
		const el = document.getElementById(firstError.name);
		el.focus();
	};

	const handleSubmit = (values) =>
		onSubmit({ ...data, ...values })
			.then(() => form.resetFields())
			.catch((err) => core.contextAlert(err));

	const handleViewFullForm = () => openFullForm({ data: form.getFieldsValue() });

	return (
		<VModal
			open={open}
			onClose={onClose}
			destroyOnClose
			confirmLoading
			title="New Lead"
			headerActions={[
				<VButton type="text" onClick={handleViewFullForm} icon={<HiOutlineArrowTopRightOnSquare />} />,
			]}
			width={800}
			footer={[
				<Button key="full-form" onClick={handleViewFullForm}>
					View full form
				</Button>,
				<Button key="reset" type="dashed" onClick={() => form.resetFields()} danger>
					Reset
				</Button>,
				<Button key="submit" type="primary" form="lead-form-basic" htmlType="submit">
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 16 }}
				onFinish={handleSubmit}
				onFinishFailed={handleError}
				onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} // prevent submit on enter
				autoComplete="off"
				id="lead-form-basic"
			>
				<Form.Item
					label="Name"
					name="ten_kh"
					rules={[
						{
							required: true,
							message: 'Name is required!',
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item label="Email" name="email">
					<Input />
				</Form.Item>

				<Form.Item label="Phone" name="dien_thoai">
					<Input />
				</Form.Item>

				<Form.Item
					label="Group"
					name="nh_kh"
					rules={[
						{
							required: true,
							message: 'Group is required!',
						},
					]}
				>
					<VSelect.API api="dmnhkh" field={{ value: '_id', label: 'group_name' }} />
				</Form.Item>

				<Form.Item label="Assigned To" name="phu_trach">
					<VSelect.API
						api="participant"
						field={{ value: 'email', getLabel: (p) => `${p.name} - ${p.email}` }}
					/>
				</Form.Item>
			</Form>
		</VModal>
	);
}
