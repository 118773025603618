import React from "react";
import Dialog from "../components/Dialog/Dialog";
import { Box, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

const Content = styled(Box)`
  width: 60vw;
  margin-top: 20px;
`;
const InputWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
const Label = styled.label`
  font-size: 14px;
  font-weight: 550;
  white-space: nowrap;
`;
const Input = styled.input`
  width: 150px;
  height: 34px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0 10px;
  &:focus {
    border-color: ${(props) => props.borderColor};
  }
`;
const Select = styled.select`
  width: 150px;
  height: 34px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0 10px;
  &:focus {
    border-color: ${(props) => props.borderColor};
  }
`;
// width height fontsize displayvalue textalign textposition textmargin margintop marginbottom marginright marginleft
function DialogOption({ open, handleClose, options, handleOptionsChange }) {
  const theme = useTheme();

  return (
    <Dialog open={open} handleClose={handleClose} title="Tùy chọn">
      <Content>
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6}>
            <InputWrapper style={{ justifyContent: "flex-start" }}>
              <Label>Display value</Label>
              <input
                type="checkbox"
                checked={options.displayValue}
                onChange={() =>
                  handleOptionsChange("displayValue", !options.displayValue)
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Font size</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                min={1}
                value={options.fontSize}
                onChange={(e) =>
                  handleOptionsChange("fontSize", e.target.value)
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Width</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                min={1}
                value={options.width}
                onChange={(e) => handleOptionsChange("width", e.target.value)}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Height</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                min={1}
                value={options.height}
                onChange={(e) => handleOptionsChange("height", e.target.value)}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Text align</Label>
              <Select
                borderColor={theme.palette.primary.main}
                value={options.textAlign}
                onChange={(e) =>
                  handleOptionsChange("textAlign", e.target.value)
                }
              >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </Select>
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Text position</Label>
              <Select
                borderColor={theme.palette.primary.main}
                value={options.textPosition}
                onChange={(e) =>
                  handleOptionsChange("textPosition", e.target.value)
                }
              >
                <option value="top">Top</option>
                <option value="bottom">Bottom</option>
              </Select>
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Text margin</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                value={options.textMargin}
                onChange={(e) =>
                  handleOptionsChange("textMargin", e.target.value)
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Margin</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                value={options.margin}
                onChange={(e) => handleOptionsChange("margin", e.target.value)}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Margin top</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                value={options.marginTop}
                onChange={(e) =>
                  handleOptionsChange("marginTop", e.target.value)
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Margin right</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                value={options.marginRight}
                onChange={(e) =>
                  handleOptionsChange("marginRight", e.target.value)
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Margin bottom</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                value={options.marginBottom}
                onChange={(e) =>
                  handleOptionsChange("marginBottom", e.target.value)
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <Label>Margin left</Label>
              <Input
                borderColor={theme.palette.primary.main}
                type="number"
                value={options.marginLeft}
                onChange={(e) =>
                  handleOptionsChange("marginLeft", e.target.value)
                }
              />
            </InputWrapper>
          </Grid>
        </Grid>
      </Content>
    </Dialog>
  );
}

export default DialogOption;
