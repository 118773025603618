import { Checkbox, Empty, Skeleton } from 'antd';
import React from 'react';
import { useBlockLayout, useColumnOrder, usePagination, useResizeColumns, useRowSelect, useTable } from 'react-table';
import styles from './VComponent.module.css';
import VToolBar from './VToolBar';

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT = 20;
const VTable = ({
	onRowClick,
	rowActionsRender,
	columns,
	data,
	fetchData,
	loading,
	totalRows,
	toolbar,
	toolbarProps,
}) => {
	const defaultColumn = React.useMemo(
		() => ({
			minWidth: 60,
			width: 200,
			maxWidth: 400,
		}),
		[]
	);

	const [pageCount, setPageCount] = React.useState(Math.ceil(totalRows / DEFAULT_LIMIT));
	const rTableHook = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: { pageIndex: DEFAULT_PAGE, pageSize: DEFAULT_LIMIT },
			manualPagination: true,
			pageCount,
		},
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				{
					id: 'selection',
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div className={styles.checkbox_cell}>
							<Checkbox {...getToggleAllRowsSelectedProps({ title: 'Chọn tất cả' })} />
						</div>
					),
					Cell: ({ row }) => (
						<div className={styles.checkbox_cell} onClick={(e) => e.stopPropagation()}>
							<Checkbox
								{...row.getToggleRowSelectedProps({ title: 'Chọn 1 dòng' })}
								onClick={(e) => e.stopPropagation()}
							/>
						</div>
					),
					disableResizing: true,
					disableRowClick: true,
					disableHiding: true,
					width: 50,
				},
				...columns,
			]);
		},
		usePagination,
		useBlockLayout,
		useResizeColumns,
		useRowSelect,
		useColumnOrder
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		state: { pageIndex, pageSize },
	} = rTableHook;

	React.useEffect(() => {
		fetchData({ page: pageIndex + 1, limit: pageSize });
	}, [pageIndex, pageSize]);

	React.useEffect(() => {
		setPageCount(Math.ceil(totalRows / pageSize));
	}, [pageSize, totalRows]);

	return (
		<div className={styles.container}>
			{toolbar && <VToolBar rTableHook={rTableHook} loading={loading} {...toolbarProps} />}

			<div className={styles.table_container}>
				<div {...getTableProps()} style={{ width: 'fit-content', minWidth: '100%' }}>
					<div className={styles.header_row}>
						{headerGroups.map((headerGroup) => (
							<div {...headerGroup.getHeaderGroupProps()} className={styles.header_group_cell}>
								{headerGroup.headers.map((column, index) => (
									<div
										{...column.getHeaderProps()}
										className={[
											styles.header_cell,
											column.disableResizing ? '' : styles.resizeable,
										].join(' ')}
										key={index}
									>
										{column.render('Header')}
										{!column.disableResizing && (
											<div
												{...column.getResizerProps()}
												className={[
													styles.resizer,
													column.isResizing ? styles.resizing : '',
												].join(' ')}
											/>
										)}

										{/* Render the columns filter UI */}
										{column.canFilter && <div>{column.render('Filter')}</div>}
									</div>
								))}
							</div>
						))}
					</div>
					<div {...getTableBodyProps()} className={styles.body}>
						{loading ? (
							[...Array(pageSize)].map((_, index) => (
								<div className={styles.row} key={index}>
									<Skeleton.Input active block className={styles.cell} />
								</div>
							))
						) : rows.length ? (
							rows.map((row) => {
								prepareRow(row);
								const onClick = () => onRowClick?.(row.original);
								const style = {
									cursor: onRowClick ? 'pointer' : 'default',
								};
								return (
									<div {...row.getRowProps()} className={styles.row} onClick={onClick} style={style}>
										{row.cells.map((cell) => {
											const onClick = (e) =>
												cell.column.disableRowClick ? e.stopPropagation() : undefined;

											return (
												<div {...cell.getCellProps()} className={styles.cell}>
													<div onClick={onClick}>{cell.render('Cell')}</div>
												</div>
											);
										})}

										<div className={styles.row_actions} onClick={(e) => e.stopPropagation()}>
											{rowActionsRender(row.original).map((action, index) =>
												React.cloneElement(action, { key: index })
											)}
										</div>
									</div>
								);
							})
						) : (
							<div className={styles.row}>
								<Empty className={styles.cell} style={{ width: '100%' }} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default VTable;
