import React from "react";
import { Col, Input, Row } from "antd";
import { IoIosAddCircle } from "react-icons/io";
import { NavLink } from "react-router-dom";
import "./Member.css";
function Center(props) {
  return (
    <Col className="gutter-row center" span={8}>
      <div>
        <h5>Quy đổi điểm khách hàng thành tiền mua hàng</h5>
        <Row className="apply">
          <Col className="gutter-row" span={8}>
            <p>Áp dụng</p>
          </Col>
          <Col
            className="gutter-row"
            span={16}
            style={{
              display: "flex",
            }}
          >
            <label htmlFor="apply1">
              <input type="radio" name="1" id="apply1" />
              <span>Bật</span>
            </label>
            <label htmlFor="apply2">
              <input type="radio" name="1" id="apply2" />
              <span>Tắt</span>
            </label>
          </Col>
        </Row>
        <Row className="exchange_title">
          <Col className="gutter-row" span={12}>
            <p>Số tiền điểm quy đổi</p>
          </Col>
          <Col className="gutter-row" span={12}>
            <p>Số tiền được nhận</p>
          </Col>
        </Row>
        <Row className="exchange-input">
          <Col className="gutter-row" span={10}>
            <Input addonAfter={<p>điểm</p>} type="text" defaultValue="1" />
          </Col>
          <Col className="icon">
            <p>=</p>
          </Col>
          <Col className="gutter-row input-right" span={10}>
            <Input
              type="text"
              addonAfter={
                <p
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  đ
                </p>
              }
              defaultValue="10.000"
              style={{
                textAlign: "right",
              }}
            />
          </Col>
        </Row>
        <div className="link_add">
          <IoIosAddCircle size={20} color="#3b9cf7" />
          <span>Cài đặt công thức quy đổi theo từng hạng</span>
        </div>
      </div>
    </Col>
  );
}

export default Center;
