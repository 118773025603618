import React from 'react';
import { TagsInput } from 'react-tag-input-component';
import FieldBox from '../Dialog/FieldBox';

const FieldTagInput = ({
	title,
	tooltip,
	check,
	onChange,
	value = [],
	placeholder,
	icon,
	actions,
	inputProps,
	classCus,
	errorMsg,
}) => {
	const defaultClass = 'field_input_content_input';

	return (
		<FieldBox
			title={title}
			tooltip={tooltip}
			check={check}
			errorMsg={errorMsg}
			children={
				<div className="field_input_container">
					{icon && icon}
					<TagsInput
						value={value}
						onChange={onChange}
						placeholder={placeholder}
						classNames={{
							input: defaultClass + ' ' + classCus,
							tag: 'tag',
						}}
					/>
					{actions && actions}
				</div>
			}
		/>
	);
};

export default FieldTagInput;
