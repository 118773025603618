import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../../utils/helpers';
import Dialog from '../../../components/Dialog/Dialog';
import TabContainer from '../../../components/tab/TabContainer';
import AuthorizationTab from './tabs/AuthorizationTab';
import InfoTab from './tabs/InfoTab';
import TimeTab from './tabs/TimeTab';

export default function FormInventoryDialog({ open, onClose, data, onSubmit }) {
	const hookForm = useForm();

	const {
		handleSubmit,
		reset,
		formState: { isSubmitting },
		watch,
	} = hookForm;

	useEffect(() => {
		if (open) reset(data || {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data, ['ma_kho'])));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			loading={isSubmitting}
			title={watch('_id') ? 'Chỉnh sửa kho' : 'Thêm kho'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)} disabled={isSubmitting}>
					Lưu
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndNew)} disabled={isSubmitting}>
					Lưu và thêm mới
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndCopy)} disabled={isSubmitting}>
					Lưu và sao chép
				</button>,
				<button className='btn btn-grey' onClick={onClose} disabled={isSubmitting}>
					Hủy
				</button>,
			]}
			width={800}
		>
			<TabContainer
				tabs={[
					{
						label: 'Thông tin chung',
						component: <InfoTab hookForm={hookForm} />,
					},
					{
						label: 'Phân quyền',
						component: <AuthorizationTab hookForm={hookForm} />,
					},
					{
						label: 'Thời gian hoạt động',
						component: <TimeTab hookForm={hookForm} />,
					},
				]}
			/>
		</Dialog>
	);
}
