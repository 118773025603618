import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import ReturnedLineProduct from './ReturnedLineProduct';
import { SaleContext } from '../../SaleContainer';
import SearchProductFast from '../../header/SearchProductFast';
import { cloneDeep } from 'lodash';
import LineProductDoi from './LineProductDoi';

const Wrapper = styled(Box)`
	width: 100%;
	height: calc(100vh - 50px - 42px - 20px - 10px);
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
const Row = styled(Box)`
	width: 100%;
	height: 50%;
`;
const ListLineProducts = styled(Box)`
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow: auto;
`;
const SearchBar = styled(Paper)`
	width: 100%;
	height: 42px;
	/* background-color: ${(props) => props.bgcolor}; */
	display: flex;
	align-items: center;
	padding: 0 10px;
`;

function ReturnedBillSale() {
	const theme = useTheme();
	const { voucher, handleReturnedBillChange, getVoucher } = React.useContext(SaleContext);

	// handle select hang doi
	const handleSelectHangDoi = async (product, number) => {
		const newDetail = {
			gia_ban_nt: product.gia_ban_le,
			ma_dvt: product.ma_dvt,
			ma_vt: product.ma_vt,
			sl_xuat: number,
			ten_vt: product.ten_vt,
			tien: product.gia_ban_le * number,
			tien_ck: 0,
			tien_ck_nt: 0,
			tien_nt: product.gia_ban_le * number,
			ty_le_ck: 0,
		};
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.details_doi.push(newDetail);
		await handleReturnedBillChange(voucherCopy);
		getVoucher();
	};
	// handle add hang doi
	const handleAddHangDoi = async (product, sl_xuat, plus = true) => {
		const voucherCopy = cloneDeep(voucher);
		const index = voucherCopy.details_doi.findIndex((item) => item.ma_vt === product.ma_vt);
		const newDetail = voucherCopy.details_doi[index];
		if (plus) {
			newDetail.sl_xuat += sl_xuat;
		} else {
			newDetail.sl_xuat = sl_xuat;
		}
		if (newDetail.sl_xuat === 0) {
			voucherCopy.details_doi = voucherCopy.details_doi.filter((item) => item.ma_vt !== newDetail.ma_vt);
		} else {
			newDetail.tien = (newDetail.gia_ban_nt - newDetail.tien_ck_nt) * newDetail.sl_xuat;
			newDetail.tien_nt = (newDetail.gia_ban_nt - newDetail.tien_ck_nt) * newDetail.sl_xuat;
		}
		await handleReturnedBillChange(voucherCopy);
		getVoucher();
	};
	const handleDetailsDoiChange = async (newDetailsDoi) => {
		const voucherCopy = cloneDeep(voucher);
		voucherCopy.details_doi = newDetailsDoi;
		await handleReturnedBillChange(voucherCopy);
		getVoucher();
	};

	return (
		<Wrapper>
			<Row>
				<ListLineProducts>
					{voucher?.details?.length > 0 ? (
						<>
							{voucher.details.map((detail, index) => (
								<ReturnedLineProduct
									key={detail._id}
									index={index + 1}
									detail={detail}
									handleReturnedBillChange={handleReturnedBillChange}
								/>
							))}
						</>
					) : (
						<Typography style={{ fontSize: '12px', fontStyle: 'italic', textAlign: 'center' }}>
							Không có sản phẩm
						</Typography>
					)}
				</ListLineProducts>
			</Row>
			<Row>
				<SearchBar bgcolor={theme.palette.primary.main}>
					<SearchProductFast onChooseProduct={handleSelectHangDoi} placeholder="Tìm hàng đổi" />
				</SearchBar>
				<Box sx={{ height: 'calc(100% - 42px)', paddingTop: '10px', overflow: 'auto' }}>
					<ListLineProducts>
						{voucher.details_doi.map((detail_doi, index) => (
							<LineProductDoi
								key={detail_doi._id}
								index={index}
								detail={detail_doi}
								details={voucher.details_doi}
								add={handleAddHangDoi}
								handleBillChange={handleDetailsDoiChange}
							/>
						))}
					</ListLineProducts>
				</Box>
			</Row>
		</Wrapper>
	);
}

export default ReturnedBillSale;
