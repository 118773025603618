import React from 'react';
import FieldSelectApi from '../components/Dialog/FieldSelectApi';
import FilterBox from '../components/filter/FilterBox';
import FilterSearch from '../components/filter/FilterSearch';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function ParticipantFilter({ onChange, initFilter }) {
	// Default group from url
	const { search } = useLocation();
	const { group_id, group_name } = queryString.parse(search);
	const groupDefault = group_id && group_name ? { value: group_id, label: group_name } : null;

	const [filter, setFilter] = React.useState({
		email: '',
		name: '',
		group_id: '',
		...initFilter,
	});
	const group = filter.group_id && filter.group_name ? { value: filter.group_id, label: filter.group_name } : null;

	React.useEffect(() => {
		const q = {
			$and: [],
		};

		if (filter.email) q.$and.push({ email: { $regex: filter.email, $options: 'i' } });

		if (filter.name) q.$and.push({ name: { $regex: filter.name, $options: 'i' } });

		if (filter.group_id) q.$and.push({ group_id: filter.group_id });

		onChange(q);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const onValueChange = React.useCallback((field) => (value) => setFilter({ ...filter, [field]: value }), [filter]);

	return (
		<div className='product__filter'>
			<FilterSearch onSearch={onValueChange('email')} title='Tên đăng nhập' />

			<FilterSearch onSearch={onValueChange('name')} title='Họ tên' />

			<FilterBox title='Nhóm người dùng'>
				<FieldSelectApi
					api='usergroup'
					valueField='_id'
					labelField='group_name'
					value={group || groupDefault}
					onChange={(group) => setFilter({ ...filter, group_id: group?._id, group_name: group?.group_name })}
				/>
			</FilterBox>
		</div>
	);
}
