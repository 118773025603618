import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import FormGoodsGroupDialog from './Dialog/FormGoodsGroupDialog';
import GoodsGroupFilter from './GoodsGroupFilter';

const ListGoodsGroup = () => {
	const columns = [
		{
			name: 'Tên nhóm',
			selector: (row) => row.ten_nvt,
			sortable: true,
			wrap: true,
		},
		{
			name: 'Nhóm mẹ',
			selector: (row) => row.ten_nh_me,
			sortable: true,
			wrap: true,
		},
	];

	return (
		<ListBase
			api='dmnvt'
			title='Danh sách nhóm hàng hóa/dịch vụ'
			columns={columns}
			FilterComponent={GoodsGroupFilter}
			FormDialog={FormGoodsGroupDialog}
		/>
	);
};

export default withContainer(ListGoodsGroup);
