import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';

export default function DatePicker({ label, value, onChange }) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label={label}
                value={value}
                onChange={onChange}
                fullWidth
                size="small"
            />
        </MuiPickersUtilsProvider>
    );
}
