import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

const type = [
	{
		value: "1",
		label: "Dịch vụ",
	},
	{
		value: "2",
		label: "Sản phẩm",
	},
	{
		value: "3",
		label: "Gói dịch vụ",
	},
	{
		value: "4",
		label: "Thẻ dịch vụ",
	},
];

function SpaDrawersFilter({ onClose }) {
	const [searchValue, setSearchValue] = useState("");
	const [selectedOptions, setSelectedOptions] = useState([]);

	const handleSearchChange = (event) => {
		setSearchValue(event.target.value);
	};

	const handleOptionChange = (event) => {
		const value = event.target.value;
		setSelectedOptions((selectedOptions) => {
			if (selectedOptions.includes(value)) {
				return selectedOptions.filter((option) => option !== value);
			} else {
				return [...selectedOptions, value];
			}
		});
	};

	const handleResetFilter = () => {
		setSelectedOptions([]);
	};

	const filteredItems = type.filter((item) => {
		return item.label.toLowerCase().includes(searchValue.toLowerCase());
	});

	return (
		<div className="box_filter_drawers">
			<div className="drawer_left_main">
				<div className="drawer_left_main_header">
					<span>Nhóm hàng</span>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<div className="drawer_left_main_contain">
					<div className="drawer_left_main_contain_search">
						<TextField
							value={searchValue}
							onChange={handleSearchChange}
							variant="outlined"
							size="small"
							placeholder="Tìm kiếm nhóm hàng"
							fullWidth
						/>
					</div >
					{filteredItems.map((item, index) => {
						return (
							<FormControlLabel
								key={index}
								value={item.value}
								control={<Checkbox color="primary" />}
								label={item.label}
								labelPlacement="end"
								onChange={handleOptionChange}
								checked={selectedOptions.includes(item.value)}
							/>
						);
					})}
				</div>
			</div>
			<div className="drawer_left_group_btn">
				<button
					className="btn btn-edit"
					onClick={() => {
						console.log("selectedOptions: " + selectedOptions);
					}}
				>
					<span className="item_title">Lọc</span>
				</button>
				<button
					className="btn btn-white"
					onClick={() => {
						handleResetFilter();
					}}
				>
					<span className="item_title">Xóa bộ lọc</span>
				</button>
			</div>
		</div>
	);
}

export default SpaDrawersFilter;
