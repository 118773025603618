import React from 'react';
import Container from './Container';
import SaleContainer from './components/newSale/SaleContainer';

function NewSale(props) {
	return (
		<Container requireLogin {...props}>
			<SaleContainer />
		</Container>
	);
}

export default NewSale;
