import React from 'react';
import '../components/filter/Filter.css';
import FilterListRatio from '../components/filter/FilterListRatio';
import FilterSearch from '../components/filter/FilterSearch';
import { customerTypes, customerTypesData } from './ListCustomer';
import lodash from 'lodash';

const searchFields = ['ma_kh', 'ten_kh', 'of_user'];

export default function CustomerFilter({ onChange, type, setType, initType, initFilter, isSupplier }) {
	const [filter, setFilter] = React.useState({
		search: '',
		...initFilter,
	});

	React.useEffect(() => {
		const q = lodash.cloneDeep(initFilter);
		q.$and = q.$and || []; // create $and if not exists

		if (filter.search)
			q.$and.push({ $or: searchFields.map((field) => ({ [field]: { $regex: filter.search, $options: 'i' } })) });

		onChange(q);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const onValueChange = React.useCallback((field) => (value) => setFilter({ ...filter, [field]: value }), [filter]);

	return (
		<div className='product__filter'>
			{/* Search box */}
			<FilterSearch onSearch={onValueChange('search')} />

			{/* Filter by status */}
			{!isSupplier && initType === customerTypes.ALL && (
				<FilterListRatio
					title='Trạng thái'
					items={Object.keys(customerTypesData).map((key) => ({
						label: customerTypesData[key].title,
						value: key,
					}))}
					defaultValue={type}
					onChange={setType}
					collapsable
				/>
			)}
		</div>
	);
}
