import React from 'react';
import FilterSearch from '../components/filter/FilterSearch';

function ShiftFilter({ onChange }) {
	const [filter, setFilter] = React.useState({
		ma_ca: '',
		ten_ca: '',
	});

	// handle ma ca change
	const handleMacaChange = (value) => {
		setFilter({ ...filter, ma_ca: value });
	};
	// handle ten ca change
	const handleTencaChange = (value) => {
		setFilter({ ...filter, ten_ca: value });
	};

	React.useEffect(() => {
		const q = {};
		if (filter.ma_ca) {
			q.ma_ca = { $regex: filter.ma_ca.split(' ').join('.*'), $options: 'i' };
		}
		if (filter.ten_ca) {
			q.ten_ca = { $regex: filter.ten_ca.split(' ').join('.*'), $options: 'i' };
		}
		onChange(q);
	}, [filter]);

	return (
		<div>
			<FilterSearch title="Mã ca" onSearch={handleMacaChange} />
			<FilterSearch title="Tên ca" onSearch={handleTencaChange} />
		</div>
	);
}

export default ShiftFilter;
