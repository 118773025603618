import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import styled from 'styled-components';
import ReturnedPayment from '../footer/ReturnedPayment';
import ReturnedBillSale from './bill/ReturnedBillSale';

const ReturnedSaleMainStyled = styled(Box)`
	width: 100%;
	flex-grow: 1;
`;

function ReturnedSaleMain() {
	return (
		<ReturnedSaleMainStyled>
			<Grid container spacing={1} style={{ height: '100%' }}>
				<Grid item xs={12} md={7}>
					<ReturnedBillSale />
				</Grid>
				<Grid item xs={12} md={5}>
					<Paper
						style={{
							width: '100%',
							height: 'calc(100vh - 50px - 20px - 10px - 42px)',
						}}
					>
						<ReturnedPayment />
					</Paper>
				</Grid>
			</Grid>
		</ReturnedSaleMainStyled>
	);
}

export default ReturnedSaleMain;
