import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { cleanData, copyData } from '../../../../utils/helpers';
import Dialog from '../../components/Dialog/Dialog';
import FieldInput from '../../components/Dialog/FieldInput';

export default function FormCustomerGroupDialog({ open, onClose, data, onSubmit }) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control,
		watch,
	} = useForm();

	useEffect(() => {
		if (open) reset(data || {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, open]);

	const onSave = (data) => onSubmit(data).then(() => onClose());

	const onSaveAndNew = (data) => onSubmit(data).then(() => reset(cleanData(data)));

	const onSaveAndCopy = (data) => onSubmit(data).then(() => reset(copyData(data)));

	return (
		<Dialog
			open={open}
			handleClose={onClose}
			title={watch('_id') ? 'Chỉnh sửa nhóm khách hàng' : 'Thêm nhóm khách hàng'}
			actions={[
				<button className='btn btn-fill' onClick={handleSubmit(onSave)}>
					Lưu
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndNew)}>
					Lưu và thêm mới
				</button>,
				<button className='btn btn-fill' onClick={handleSubmit(onSaveAndCopy)}>
					Lưu và sao chép
				</button>,
				<button className='btn btn-grey' onClick={onClose}>
					Hủy
				</button>,
			]}
		>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<FieldInput
						title='Tên nhóm'
						placeholder='Nhập tên nhóm'
						inputProps={{
							...register('group_name', {
								required: 'Vui lòng nhập tên nhóm',
							}),
						}}
						check
						errorMsg={errors?.group_name?.message}
					/>
				</Grid>

				<Grid item xs={6}>
					<Controller
						name='isseller'
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControlLabel
								control={<Checkbox size='small' />}
								label='Là nhóm nhà cung cấp'
								checked={!!value}
								onChange={onChange}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</Dialog>
	);
}
