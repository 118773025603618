import { Avatar, Box, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DefaultContext from "flexbiz-core/components/auth/Context";
import { unicode2Ascii } from "flexbiz-core/utils";
import { cloneDeep } from "lodash";
import React, { useState, useRef, useContext } from "react";
import { CiSearch } from "react-icons/ci";
import { MdAdsClick } from "react-icons/md";
import styled from "styled-components";
import { SaleContextSpa } from "../SpaSales";

const SearchIcon = styled(Box)`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ChooseButton = styled(Box)`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: #ededed;
	border-radius: 6px;
`;

const SearchStyled = styled(Autocomplete)`
	width: 300px;
	height: 34px;
	border-radius: 6px;
	background-color: #fff;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #000;
		}
	}
`;
const ProductItem = styled(Typography)`
	padding: 5px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 5px;
`;
const ProductImg = styled(Avatar)`
	width: 30px;
	height: 30px;
	border-radius: 6px;
`;
const ProductName = styled(Typography)`
	font-size: 13px;
`;
const NoOptionText = styled(Typography)`
	font-size: 13px;
	font-style: italic;
	text-align: center;
`;
const NumberOrder = styled.input`
	width: 40px;
	height: 100%;
	border: none;
	outline: none;
	background-color: #fff;
	border-radius: 6px;
	text-align: center;
	font-size: 13px;
	margin-left: 10px;
`;

function SearchProduct() {
	const context = useContext(DefaultContext);
	const { getVoucher, voucher, setVoucher, kho, ma_ban, ma_kh } =
		useContext(SaleContextSpa);
	const timerRef = useRef();

	const [products, setProducts] = useState([]);
	const [search, setSearch] = useState("");
	const [searchText, setSearchText] = useState("");
	const [product, setProduct] = useState(null);
	const [number, setNumber] = useState(1);
	const [load, setLoad] = useState(0);

	// handle save PBL
	const handleSavePBL = async (data) => {
		let _data_for_save = cloneDeep(data);
		// context.setProgressStatus(true);
		// this.timeSave = new Date().getTime();
		if (!_data_for_save._id) {
			let condition = {
				trang_thai: "1",
				ma_ban: _data_for_save.ma_ban,
				ma_kho: _data_for_save.ma_kho,
			};
			let voucher = await context.apis.asyncGetData(
				context.userInfo.token,
				"pbl",
				condition
			);
			if (voucher) {
				let details = voucher.details;
				let vs_merge = [_data_for_save];
				vs_merge
					.map((v) => v.details)
					.reduce((a, b) => [...a, ...b], [])
					.forEach((p) => {
						let item = details.find(
							(d) =>
								d.ma_vt === p.ma_vt && d.ty_le_ck === p.ty_le_ck
						);
						if (!item) {
							item = { ...p };
							details.push(item);
						} else {
							item.sl_xuat += p.sl_xuat;
							item.sl_order += p.sl_order;
							item.sl_gui_bep += p.sl_gui_bep;
							item.sl_gui_bartender += p.sl_gui_bartender;
							item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
							item.tien_ck_nt += item.tien_ck_nt;
							item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
						}
					});
				voucher.tien_ck_hd =
					(voucher.tien_ck_hd || 0) +
					vs_merge
						.map((v) => v.tien_ck_hd || 0)
						.reduce((a, b) => a + b, 0);
				voucher.tien_evoucher =
					(voucher.tien_evoucher || 0) +
					vs_merge
						.map((v) => v.tien_evoucher || 0)
						.reduce((a, b) => a + b, 0);
				voucher.evouchers = [
					...voucher.evouchers,
					...vs_merge
						.map((v) => v.evouchers || [])
						.reduce((a, b) => a.concat(b), []),
				];

				voucher.t_tien_nt = details
					.map((d) => d.tien_hang_nt)
					.reduce((a, b) => a + b, 0);
				voucher.t_ck_nt =
					details
						.map((d) => d.tien_ck_nt)
						.reduce((a, b) => a + b, 0) +
					(voucher.tien_ck_hd || 0) +
					(voucher.tien_evoucher || 0);
				voucher.t_tt_nt = voucher.t_tien_nt - voucher.t_ck_nt;
				_data_for_save = voucher;
			}
		}
		let rs = await context.apis.asyncPostList(
			context.userInfo.token,
			"pbl",
			_data_for_save
		);
		// context.setProgressStatus(false);
		return rs;
	};
	// handle bill change
	const handleBillChange = async (details, _voucher) => {
		let voucher_for_save = cloneDeep(voucher);
		if (_voucher) {
			voucher_for_save = { ...voucher_for_save, ..._voucher };
		}
		if (details) {
			voucher_for_save.details = details;
		}
		try {
			// cần hàm savePBL
			let voucher_saved = await handleSavePBL(voucher_for_save);
			setVoucher(voucher_saved);
			if (
				voucher_saved.ma_ban !== voucher_for_save.ma_ban &&
				kho.theo_doi_sl_ht
			) {
				//gui thong bao doi ban cho bep va batender
				// context.alert(
				//   context.apis.getLabel("Bạn có muốn in phiếu đổi bàn không?"),
				//   () => {
				//     //cần ham print
				//     print(
				//       "5",
				//       () => {},
				//       (error) => {
				//         context.alert(error);
				//       },
				//       false
				//     );
				//   },
				//   "blue"
				// );
			}
		} catch (e) {
			context.alert(
				e || context.apis.getLabel("Không thể kết nối với máy chủ")
			);
		}
	};
	// handle select product
	const add = async (p, sl = 1) => {
		let voucherCopy = cloneDeep(voucher);
		let details = voucherCopy.details;
		let item;

		if (p.km_yn_for) {
			item = details.find(
				(d) => d.ma_vt === p.ma_vt && d.km_yn_for === p.km_yn_for
			);
		} else {
			if (kho && kho.theo_doi_sl_ht) {
				item = details.find(
					(d) =>
						d.ma_vt === p.ma_vt && d.line === p.line && !d.km_yn_for
				);
			} else {
				item = details.find((d) => d.ma_vt === p.ma_vt && !d.km_yn_for);
			}
			if (details.length > 0 && !item) {
				details.map((items) => {
					if (items.ma_vt === p.ma_vt && !items.km_yn_for) {
						item = items;
					}
				});
			}
		}
		if (!item) {
			item = { ...p, sl_xuat: 0 };
			item.gia_ban_nt = item.gia_ban_le || 0;
			item.order_time = new Date();
			item.sl_ht = 0;
			item.sl_gui_bep = 0;
			item.sl_gui_bartender = 0;
			item.sl_order = 0;
			item.line = new Date().getTime();
			item.ma_tt1 = p.ma_nvt;
			item.ghi_chu = p.ma_nvt2;
			details.push(item);
		}
		item.sl_order = item.sl_order || 0;
		if (sl !== 1 && sl !== -1) {
			item.sl_order = sl;
		} else {
			item.sl_order += sl;
		}
		//fix old voucher
		item.sl_gui_bartender = item.sl_gui_bartender || 0;
		item.sl_gui_bep = item.sl_gui_bep || 0;
		//neu khong theo doi sl ht thi sl_xuat luon bang sl_order
		if (
			kho ||
			kho.theo_doi_sl_ht ||
			(!item.sp_yeu_cau_pha_che && !item.sp_yeu_cau_che_bien)
		) {
			item.sl_xuat = item.sl_order;
		}
		//tinh chiet khau
		item.ty_le_ck = item.ty_le_ck || 0;
		item.tien_ck_nt = item.tien_ck = item.tien_ck_nt || 0;
		//
		if (item.sl_xuat >= item.sl_order) {
			if (!item.finish_time || sl) item.finish_time = new Date();
		} else {
			item.finish_time = null;
		}
		//
		if (!item.sp_yeu_cau_che_bien && !item.sp_yeu_cau_pha_che) {
			item.finish_time = item.order_time;
		}
		if (item.sl_order > 0) {
			item.tien_hang_nt = item.sl_xuat * item.gia_ban_nt;
			if (item.ty_le_ck) {
				item.tien_ck_nt = Math.round(
					(item.tien_hang_nt * item.ty_le_ck) / 100
				);
			}
			item.tien_nt = item.tien_hang_nt - item.tien_ck_nt;
		} else {
			if (kho && kho.theo_doi_sl_ht) {
				details = details.filter(
					(vt) => !(vt.ma_vt === item.ma_vt && vt.line === item.line)
				);
			} else {
				details = details.filter((vt) => vt.ma_vt !== p.ma_vt);
			}
		}
		voucherCopy.details = details;
		voucherCopy.exfields.dmckhd = [];
		voucherCopy.t_tien_nt = details
			.map((d) => d.tien_hang_nt)
			.reduce((a, b) => a + b, 0);
		voucherCopy.t_ck_nt =
			details.map((d) => d.tien_ck_nt).reduce((a, b) => a + b, 0) +
			(voucherCopy.tien_ck_hd || 0) +
			(voucherCopy.tien_evoucher || 0);
		voucherCopy.t_tt_nt = voucherCopy.t_tien_nt - voucherCopy.t_ck_nt;

		await setVoucher(voucherCopy);
		handleBillChange(details);
	};

	const handleSelectProduct = async (p) => {
		setProduct(p);
	};
	const handleChooseProduct = async () => {
		await add(product, Number(number));
		await getVoucher();
		setProduct(null);
		setNumber(1);
		setLoad(load + 1);
		setSearch("");
		setSearchText("");
	};
	// handle number change
	const handleNumberChange = (e) => {
		const value = Number(e.target.value);
		if (isNaN(value)) {
			return;
		}
		setNumber(Number(value));
	};

	// get customer
	const getProducts = async () => {
		if (!search) {
			setProducts([]);
			return;
		}
		let condition = {
			$or: [],
		};
		//
		condition.$or.push({ ma_vt: { $regex: search, $options: "i" } });
		condition.$or.push({ ten_vt: { $regex: search, $options: "i" } });
		let $search = await unicode2Ascii(search);
		condition.$or.push({ $text: { $search: search + " " + $search } });

		const resp = await context.apis.asyncGetList(
			context.userInfo?.token,
			"dmvt",
			{ condition, ma_kho: voucher.ma_kho, ma_kh, ma_ban }
		);
		setProducts(resp);
	};

	// set search khi search text thay doi
	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);
	// get customer khi search thay doi
	React.useEffect(() => {
		getProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	return (
		<>
			<SearchStyled
				key={load}
				noOptionsText={
					<NoOptionText>Không tìm thấy sản phẩm</NoOptionText>
				}
				forcePopupIcon={false}
				clearOnBlur
				closeIcon={null}
				options={products}
				getOptionLabel={(option) => option.ten_vt}
				renderOption={(option) => {
					return (
						<ProductItem
							onClick={() => handleSelectProduct(option)}
						>
							<ProductImg />
							<ProductName>{option.ten_vt}</ProductName>
						</ProductItem>
					);
				}}
				renderInput={(params) => (
					<TextField
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder={"Tìm tên, mã sản phẩm"}
						{...params}
						InputProps={{
							...params.InputProps,
							disableUnderline: true,
							startAdornment: (
								<SearchIcon>
									<CiSearch fontSize="20px" />
								</SearchIcon>
							),
							endAdornment:
								product && number > 0 ? (
									<Tooltip arrow placement="top" title="chọn">
										<ChooseButton
											onClick={handleChooseProduct}
										>
											<MdAdsClick fontSize="18px" />
										</ChooseButton>
									</Tooltip>
								) : null,
						}}
						variant="outlined"
					/>
				)}
			/>
			{product && (
				<NumberOrder
					value={number}
					onChange={handleNumberChange}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							handleChooseProduct();
						}
					}}
				/>
			)}
		</>
	);
}

export default SearchProduct;
