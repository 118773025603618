import React from 'react';
import AppConfig from './AppConfig/AppConfig';
import AppInfo from './AppInfo';
import Barcode from './Barcode';
import BartenderMonitor from './BartenderMonitor';
import BepMonitor from './BepMonitor';
import Booking from './Booking';
import Dashboard from './Dashboard';
import DashboardExt from './DashboardExt';
import Home from './Home';
import ImportData from './ImportData';
import Lead from './Lead/Lead';
import List from './List';
import ListBooking from './ListBooking/ListBooking';
import ListCustomer from './ListCustomer/ListCustomer';
import ListCustomerGroup from './ListCustomerGroup/ListCustomerGroup';
import ListDiscount from './ListDiscount/ListDiscount';
import ListEvoucher from './ListEvoucher/ListEvoucher';
import ListGoods from './ListGoods/ListGoods';
import ListGoodsGroup from './ListGoodsGroup/ListGoodsGroup';
import ListInventory from './ListInventory/ListInventory';
import ListParcel from './ListParcel/ListParcel';
import ListParticipant from './ListParticipant/ListParticipant';
import ListPrice from './ListPrice/ListPrice';
import ListProject from './ListProject/ListProject';
import ListPromotion from './ListPromotion/ListPromotion';
import CTDTByChungTuVatTu from './ListReport/CTDTByChungTuVatTu/CTDTByChungTuVatTu';
import RevenueSummaryBySupplies from './ListReport/RevenueSummaryBySupplies/RevenueSummaryBySupplies';
import SaleByEmployee from './ListReport/SaleByEmployee/SaleByEmployee';
import SaleByTime from './ListReport/SaleByTime/SaleByTime';
import SaleEndOfDay from './ListReport/SaleEndOfDay/SaleEndOfDay';
import SaleOrderList from './ListReport/SaleOrderList/SaleOrderList';
import ListSaleChannel from './ListSaleChannel/ListSaleChannel';
import ListShift from './ListShift/ListShift';
import ListStaff from './ListStaff/ListStaff';
import ListUnit from './ListUnit/ListUnit';
import ListUnitBase from './ListUnitBase/ListUnitBase';
import ListUnitConversion from './ListUnitConversion/ListUnitConversion';
import MemberShipRank from './MeberShipRank/MemberShipRank';
import NewSale from './NewSale';
import notfound from './NotFound';
import PaymentByPoints from './PaymentByPoints';
import Print from './Print';
import PrintTemplate from './PrintTemplate/PrintTemplate';
import ReasonCancel from './ReasonCancel/ReasonCancel';
import Report from './Report';
import RoomMap from './RoomMap/RoomMap';
import SalesChannel from './SalesChannel/SalesChannel';
import Shift from './Shift/Shift';
import SpaSales from './SpaSales/SpaSales';
import Tables from './Tables';
import Task from './Task/Task';
import Voucher from './Voucher';
import VoucherManager from './VoucherManager/VoucherManager';
import WorkShift from './WorkShift/WorkShift';
import ListLead from './ListLead/ListLead';
import ReportManager from './ReportManager/ReportManager';

const routes = [
	{
		id: 'POS',
		path: '/pos',
		component: Home,
		exact: true,
	},
	{
		id: 'home',
		path: '/',
		component: Dashboard,
		exact: true,
	},
	{
		id: 'dashboard',
		path: '/dashboard',
		component: Dashboard,
		exact: true,
	},
	{
		id: 'dashboardext',
		path: '/dashboard-ext',
		component: DashboardExt,
		exact: true,
	},
	{
		id: 'booking',
		path: '/booking',
		component: Booking,
	},
	{
		id: 'appinfo',
		path: '/system/appinfo',
		component: AppConfig,
	},
	{
		id: 'appInfo',
		path: '/system/appinfo',
		component: AppInfo,
	},
	{
		id: 'banhang',
		path: '/banhang',
		component: Home,
		exact: true,
	},
	{
		id: 'table',
		path: '/shop/:ma_kho/:ma_ban',
		component: NewSale,
	},
	{
		id: 'table2',
		path: '/spa/:ma_kho/:ma_ban',
		component: SpaSales,
	},
	{
		id: 'barcode',
		path: '/dmvt/barcode',
		component: Barcode,
	},
	{
		id: 'bepmonitor',
		path: '/kitchen-monitor',
		component: BepMonitor,
	},
	{
		id: 'bartendermonitor',
		path: '/bartender-monitor',
		component: BartenderMonitor,
	},
	{
		id: 'shop',
		path: '/shop/:ma_kho',
		component: Tables,
	},
	{
		id: 'shop2',
		path: '/spa/:ma_kho',
		component: Tables,
	},
	{
		id: 'print',
		path: '/print/:id_app/:token/:id',
		component: Print,
	},
	{
		id: 'print2',
		path: '/print/:id',
		component: Print,
	},
	{
		id: 'payment',
		path: '/payment/:id',
		component: PaymentByPoints,
	},
	{
		id: 'list2',
		path: '/list/goods',
		component: ListGoods,
	},
	{
		id: 'list2',
		path: '/list/dmnsp',
		component: ListGoodsGroup,
	},
	{
		id: 'customer',
		path: '/list/dmnhkh',
		component: ListCustomerGroup,
	},
	{
		id: 'participant',
		path: '/list/participant',
		component: ListParticipant,
	},
	{
		id: 'roommap',
		path: '/list/roommap',
		component: RoomMap,
	},
	{
		id: 'liststaff',
		path: '/list/staff',
		component: ListStaff,
	},
	{
		id: 'reasoncancel',
		path: '/list/reasoncancel',
		component: ReasonCancel,
	},
	{
		id: 'shift',
		path: '/list/shift',
		component: Shift,
	},
	{
		id: 'workshift',
		path: '/list/workshift',
		component: WorkShift,
	},
	{
		id: 'saleschannel',
		path: '/list/saleschannel',
		component: SalesChannel,
	},
	{
		id: 'reportsalesendofday',
		path: '/reports/salesendofday',
		component: SaleEndOfDay,
	},
	{
		id: 'reportsalebytime',
		path: '/reports/salebytime',
		component: SaleByTime,
	},
	{
		id: 'reportsaleorderlist',
		path: '/reports/saleorderlist',
		component: SaleOrderList,
	},
	{
		id: 'reportctdtbychungtuvattu',
		path: '/reports/ctdtbychungtuvattu',
		component: CTDTByChungTuVatTu,
	},
	{
		id: 'reportrevenuesummarybysupplies',
		path: '/reports/revenuesummarybysupplies',
		component: RevenueSummaryBySupplies,
	},
	{
		id: 'reportsalebyemployee',
		path: '/reports/salebyemployee',
		component: SaleByEmployee,
	},
	{
		id: 'booking',
		path: '/list/booking',
		component: ListBooking,
	},
	{
		id: 'customer',
		path: '/list/customer',
		component: ListCustomer,
	},
	{
		id: 'supplier',
		path: '/list/supplier',
		component: () => <ListCustomer isSupplier />,
	},
	{
		id: 'dmkho',
		path: '/list/dmkho',
		component: ListInventory,
	},
	{
		id: 'dmlo',
		path: '/list/dmlo',
		component: ListParcel,
	},
	{
		id: 'dmdvt',
		path: '/list/dmdvt',
		component: ListUnit,
	},
	{
		id: 'dmqddvt',
		path: '/list/dmqddvt',
		component: ListUnitConversion,
	},
	{
		id: 'dmdt',
		path: '/list/dmdt',
		component: ListProject,
	},
	{
		id: 'dvcs',
		path: '/list/dvcs',
		component: ListUnitBase,
	},
	{
		id: 'lead',
		path: '/list/lead',
		component: ListLead,
	},
	{
		id: 'task',
		path: '/list/task',
		component: Task,
	},
	{
		id: 'dmgiaban',
		path: '/list/dmgiaban',
		component: ListPrice,
	},
	{
		id: 'dmkenhban',
		path: '/list/dmkenhban',
		component: ListSaleChannel,
	},
	{
		id: 'dmchietkhau',
		path: '/list/dmchietkhau',
		component: ListDiscount,
	},
	{
		id: 'dmkhuyenmai',
		path: '/list/dmkhuyenmai',
		component: ListPromotion,
	},
	{
		id: 'evoucher',
		path: '/list/evoucher',
		component: ListEvoucher,
	},
	{
		id: 'shift',
		path: '/list/dmca',
		component: ListShift,
	},
	{
		id: 'list',
		path: '/list/:code',
		component: List,
	},
	{
		id: 'list',
		path: '/import/:code',
		component: ImportData,
	},
	{
		id: 'voucher',
		path: '/voucher/:code',
		component: Voucher,
	},
	{
		id: 'newvoucher',
		path: '/new-voucher/:code',
		component: VoucherManager,
	},
	{
		id: 'newreport',
		path: '/new-report/:code',
		component: ReportManager,
	},
	{
		id: 'report',
		path: '/report/:code',
		component: Report,
	},
	{
		id: 'mebership_setting',
		path: '/hang-thanh-vien/cau-hinh-tich-diem',
		component: MemberShipRank,
	},
	{
		id: 'print-template',
		path: '/print-template',
		component: PrintTemplate,
	},
	{
		id: '404',
		path: '/404',
		component: notfound,
		exact: true,
	},
	{
		id: 'dmgiaban',
		path: '/list2/dmgiaban',
		component: ListPrice,
	},
];

export default routes;
