import React from 'react';
import Dialog from '../../Dialog/Dialog';
import styled from 'styled-components';
import { Box, Grid, Typography, CssBaseline } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import ButtonBase from '../components/button/ButtonBase';
import { v4 } from 'uuid';
import { numeralCustom } from '../../../../../utils/helpers';

const CloseShiftWrapper = styled(Box)`
	padding-top: 20px;
`;
const CheckboxWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const Column = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	display: flex;
	align-items: center;
`;
const ColumnTitle = styled(Typography)`
	width: 100%;
	font-size: 14px;
	color: ${(props) => props.color};
	text-transform: uppercase;
`;
const Section = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const SectionContent = styled(Box)`
	width: 100%;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const SectionRow = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${(props) => props.bgColor};
`;
const SectionText = styled(Typography)`
	font-size: 13px;
	font-weight: ${(props) => (props.bold ? '600' : '400')};
	position: relative;
	&::before {
		content: '*';
		display: ${(props) => (props.required ? 'block' : 'none')};
		position: absolute;
		top: 0;
		right: -10px;
		color: red;
		font-size: 12px;
	}
`;
const Input = styled.input`
	border: none;
	outline: none;
	min-width: 50px;
	width: ${(props) => (props.width ? props.width : '100px')};
	border: 1px solid #ccc;
	font-size: 13px;
	border-radius: 2px;
	padding: 5px;
	&::placeholder {
		font-size: 12px;
	}
	&:focus {
		border-color: ${(props) => props.borderColor};
	}
`;
const TextErea = styled.textarea`
	border: none;
	outline: none;
	min-width: 100%;
	border: 1px solid #ccc;
	font-size: 13px;
	border-radius: 2px;
	padding: 5px;
	&::placeholder {
		font-size: 12px;
	}
	&:focus {
		border-color: ${(props) => props.borderColor};
	}
`;

const currencies = [500000, 200000, 100000, 50000, 20000, 10000, 5000, 2000, 1000];

function CloseShiftDialog({ open, onClose }) {
	const theme = useTheme();
	const {
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			ca: null,
			start_time: '',
			end_time: '',
		},
	});

	return (
		<Dialog
			title="Đóng ca làm việc"
			open={open}
			handleClose={onClose}
			width="850px"
			actions={[
				<ButtonBase key={v4()} variant="outline" onClick={onClose}>
					Hủy
				</ButtonBase>,
				<ButtonBase key={v4()}>Đóng ca</ButtonBase>,
			]}
		>
			<CloseShiftWrapper>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4} style={{ borderRight: '1px solid #ccc' }}>
						<Column>
							<ColumnTitle color={theme.palette.primary.main}>Giao dịch trong ca</ColumnTitle>
							<Section>
								<SectionRow>
									<SectionText bold>Bán hàng</SectionText>
									<SectionText>7.323.000</SectionText>
								</SectionRow>
								<SectionContent>
									<SectionRow>
										<SectionText>Tiền mặt</SectionText>
										<SectionText>2.855.000</SectionText>
									</SectionRow>
									<SectionRow>
										<SectionText>Thẻ</SectionText>
										<SectionText>2.855.000</SectionText>
									</SectionRow>
									<SectionRow>
										<SectionText>Voucher</SectionText>
										<SectionText>2.855.000</SectionText>
									</SectionRow>
									<SectionRow>
										<SectionText>Ghi nợ</SectionText>
										<SectionText>2.855.000</SectionText>
									</SectionRow>
								</SectionContent>
							</Section>
							<Section>
								<SectionRow>
									<SectionText bold>Thu nợ</SectionText>
									<SectionText>0</SectionText>
								</SectionRow>
								<SectionContent>
									<SectionRow>
										<SectionText>Tiền mặt</SectionText>
										<SectionText>0</SectionText>
									</SectionRow>
									<SectionRow>
										<SectionText>Thẻ</SectionText>
										<SectionText>0</SectionText>
									</SectionRow>
								</SectionContent>
							</Section>
							<Section>
								<SectionRow>
									<SectionText bold>Đặt cọc</SectionText>
									<SectionText>0</SectionText>
								</SectionRow>
								<SectionContent>
									<SectionRow>
										<SectionText>Tiền mặt</SectionText>
										<SectionText>0</SectionText>
									</SectionRow>
									<SectionRow>
										<SectionText>Thẻ</SectionText>
										<SectionText>0</SectionText>
									</SectionRow>
									<SectionRow>
										<SectionText>Chuyển khoản</SectionText>
										<SectionText>0</SectionText>
									</SectionRow>
								</SectionContent>
							</Section>
							<SectionRow>
								<SectionText bold>Tổng tiền thu</SectionText>
								<SectionText>0</SectionText>
							</SectionRow>
						</Column>
					</Grid>
					<Grid item xs={12} md={4} style={{ borderRight: '1px solid #ccc' }}>
						<Column>
							<ColumnTitle color={theme.palette.primary.main}>Bàn giao ca</ColumnTitle>
							<SectionRow>
								<SectionText bold>Tiền mặt</SectionText>
							</SectionRow>
							<SectionContent>
								<SectionRow>
									<SectionText>Đầu ca</SectionText>
									<SectionText>0</SectionText>
								</SectionRow>
								<SectionRow>
									<SectionText>Cuối ca</SectionText>
									<SectionText>2.855.000</SectionText>
								</SectionRow>
								<SectionRow>
									<SectionText required>Thực tế trong két</SectionText>
									<Input borderColor={theme.palette.primary.main} />
								</SectionRow>
								<SectionRow>
									<SectionText>Chênh lệch</SectionText>
									<SectionText>100</SectionText>
								</SectionRow>
								<SectionRow style={{ alignItems: 'flex-start' }}>
									<SectionText>Bàn giao theo</SectionText>
									<Box sx={{ display: 'flex', flexDirection: 'column', gap: '5p' }}>
										<CheckboxWrapper>
											<input
												defaultChecked
												type="radio"
												style={{ accentColor: theme.palette.primary.main }}
												name="bangiao"
												id="bangiao1"
											/>
											<label htmlFor="bangiao1">Thực tế trong két</label>
										</CheckboxWrapper>
										<CheckboxWrapper>
											<input
												type="radio"
												style={{ accentColor: theme.palette.primary.main }}
												name="bangiao"
												id="bangiao2"
											/>
											<label htmlFor="bangiao2">Trên số</label>
										</CheckboxWrapper>
									</Box>
								</SectionRow>
								<SectionRow>
									<SectionText>Tiền bàn giao</SectionText>
									<SectionText>2.855.000</SectionText>
								</SectionRow>
							</SectionContent>
							<SectionRow>
								<SectionText>Số lượng hóa đơn</SectionText>
								<Input borderColor={theme.palette.primary.main} />
							</SectionRow>
							<SectionRow>
								<SectionText>Số lượng cuống thẻ</SectionText>
								<Input borderColor={theme.palette.primary.main} />
							</SectionRow>
							<SectionRow>
								<SectionText>Số lượng voucher</SectionText>
								<Input borderColor={theme.palette.primary.main} />
							</SectionRow>
							<SectionRow>
								<Input
									width="100%"
									style={{ padding: '8px' }}
									borderColor={theme.palette.primary.main}
									placeholder="Người nhận bàn giao"
								/>
							</SectionRow>
							<SectionRow>
								<TextErea placeholder="Ghi chú" />
							</SectionRow>
						</Column>
					</Grid>
					<Grid item xs={12} md={4}>
						<Column>
							<ColumnTitle color={theme.palette.primary.main}>
								<span>Chi tiết kiểm đếm</span> <input type="checkbox" />
							</ColumnTitle>
							<SectionRow>
								<SectionText bold>Mệnh giá</SectionText>
								<SectionText bold>Số lượng</SectionText>
								<SectionText bold>Thành tiền</SectionText>
							</SectionRow>
							{currencies.map((currency) => (
								<SectionRow key={currency}>
									<SectionText style={{ width: '50px', textAlign: 'right' }}>
										{numeralCustom(currency).format()}
									</SectionText>
									<Input borderColor={theme.palette.primary.main} width="50px" />
									<SectionText style={{ width: '65px', textAlign: 'center' }}>
										{numeralCustom(1000000).format()}
									</SectionText>
								</SectionRow>
							))}
						</Column>
					</Grid>
				</Grid>
			</CloseShiftWrapper>
		</Dialog>
	);
}

export default CloseShiftDialog;
