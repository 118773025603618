import React from 'react';
import { Grid } from '@material-ui/core';
import FieldInput from '../../components/Dialog/FieldInput';
import styles from '../AppConfig.module.css';
import InputContainer from '../../components/Dialog/InputContainer';
import { Controller, useForm } from 'react-hook-form';
import { convertBytesToSize, formatDateDisplay, formatDateValue } from '../../../../utils/helpers';
import FieldBox from '../../components/Dialog/FieldBox';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { toast } from 'react-hot-toast';
import { useCore } from '../../../../hooks';
import { saveAs } from 'file-saver';

export default function CopyUpdateTab({ hookForm }) {
	const core = useCore();

	const copyForm = useForm();
	const onCopySubmit = async (data) => {
		const toastId = toast.loading('Đang sao chép dữ liệu...');
		try {
			data.dateFrom = formatDateValue(data.dateFrom);
			data.dateTo = formatDateValue(data.dateTo);

			const res = await core.apiCall({
				method: 'GET',
				withIdApp: false,
				endpoint: `/app/backup/${core.id_app}`,
				params: data,
				responseType: 'blob',
			});

			const dateFromStr = formatDateDisplay(data.dateFrom, 'DD-MM-YYYY');
			const dateToStr = formatDateDisplay(data.dateTo, 'DD-MM-YYYY');
			const appName = hookForm.getValues('name');

			const fileName = `[${appName}] Dữ liệu từ ${dateFromStr} đến ${dateToStr}.zip`;

			saveAs(res.data, fileName);

			toast.success('Sao chép dữ liệu thành công', { id: toastId });
		} catch (error) {
			console.log('error', error);
			toast.error('Sao chép dữ liệu thất bại!', { id: toastId });
		}
	};

	const inputRef = React.useRef(null);
	const updateForm = useForm();

	return (
		<Grid container spacing={2}>
			{/* Copy */}
			<Grid item xs={6}>
				<InputContainer title='Sao chép dữ liệu' defaultShow collapsable={false}>
					<div className={styles.copyContainer}>
						{/* Form */}
						<Controller
							name='dateFrom'
							control={copyForm.control}
							rules={{ required: 'Vui lòng nhập ngày' }}
							render={({ field: { value, onChange } }) => {
								value = formatDateValue(value);
								const handleChange = (e) => onChange(formatDateValue(e.target.value));

								return (
									<FieldInput
										title='Từ ngày'
										type='date'
										value={value}
										onChange={handleChange}
										check
										errorMsg={copyForm.formState.errors.dateFrom?.message}
									/>
								);
							}}
						/>

						<Controller
							name='dateTo'
							control={copyForm.control}
							rules={{ required: 'Vui lòng nhập ngày' }}
							render={({ field: { value, onChange } }) => {
								value = formatDateValue(value);
								const handleChange = (e) => onChange(formatDateValue(e.target.value));

								return (
									<FieldInput
										title='Đến ngày'
										type='date'
										value={value}
										onChange={handleChange}
										check
										errorMsg={copyForm.formState.errors.dateTo?.message}
									/>
								);
							}}
						/>

						{/* Button */}
						<button className='btn btn-fill' onClick={copyForm.handleSubmit(onCopySubmit)}>
							Sao chép
						</button>
					</div>
				</InputContainer>
			</Grid>

			{/* Update */}
			<Grid item xs={6}>
				<InputContainer title='Cập nhật dữ liệu' defaultShow collapsable={false}>
					<div className={styles.copyContainer}>
						{/* File input */}
						<Controller
							name='file'
							control={updateForm.control}
							rules={{ required: 'Vui lòng chọn file' }}
							render={({ field: { value, onChange } }) => {
								const handleChange = (e) => {
									const file = e.target.files[0];
									if (!file) return;

									onChange(file);
									e.target.value = ''; // Reset file input
								};
								const handleChooseFile = () => inputRef.current.click();

								const ext = value?.name?.split('.').pop();
								const name = value?.name;
								const size = convertBytesToSize(value?.size);
								console.log(value, `ext: ${ext}, name: ${name}, size: ${size}`);

								return (
									<FieldBox
										title='File dữ liệu'
										check
										errorMsg={updateForm.formState.errors.file?.message}
										titleProps={{ style: { alignSelf: 'flex-start' } }}
									>
										{value ? (
											<>
												<FileIcon extension={ext} {...defaultStyles[ext]} />
												<p className={styles.fileName}>{name}</p>
												<p className={styles.fileSize}>{size}</p>
											</>
										) : (
											<p className={styles.emptyFileText}>Chưa chọn file</p>
										)}
										<input type='file' hidden onChange={handleChange} ref={inputRef} />
										<button className='btn btn-outline' onClick={handleChooseFile}>
											Chọn file
										</button>
									</FieldBox>
								);
							}}
						/>

						{/* Button */}
						<button className='btn btn-fill'>Cập nhật</button>

						{/* Warning */}
						<p className={styles.warningText}>
							Chương trình sẽ <strong>ghi đè</strong> dữ liệu nếu trùng
						</p>
					</div>
				</InputContainer>
			</Grid>
		</Grid>
	);
}
