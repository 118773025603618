import { Col, Row } from "antd";
import React from "react";
import Container from "../Container";
import Center from "./Center";
import Header from "./Header";
import Left from "./Left";
import "./Member.css";
import Right from "./Right";

function MemberShipRank(props) {
  return (
    <Container>
      <div id="membership">
        <Header />
        <div className="content">
          <Row className="content_row">
            <Left />
            <Center />
            <Right />
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default MemberShipRank;
