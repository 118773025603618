import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FieldInput from '../../../../components/Dialog/FieldInput';

const useStyles = makeStyles((theme) => ({}));

export default function MoreTab() {
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<FieldInput title="Ngân hàng" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Tên tắt" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Số tài khoản" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Mã Swift" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Địa chỉ ngân hàng" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Số giấy phép" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Vốn điều lệ" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Ghi chú 1" />
			</Grid>

			<Grid item xs={6}>
				<FieldInput title="Ghi chú 2" />
			</Grid>
		</Grid>
	);
}
