import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useState } from 'react';
import Container from '../Container';
import LayoutContainer from '../components/LayoutContainer';
import TableContainer from '../components/table/TableContainer';
import ListStaffFilter from './ListStaffFilter';
import ListStaffDialog from './Dialog/ListStaffDialog';
import ListStaffItemDialog from './Dialog/ListStaffItemDialog';

const columns = [
	{
		name: 'Mã nhân viên',
		selector: (row) => row.id,
		sortable: true,
	},
	{
		name: 'Họ và tên',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Số điện thoại',
		selector: (row) => row.phone,
		sortable: true,
	},
	{
		name: 'Email',
		selector: (row) => row.email,
		sortable: true,
	},
	{
		name: 'Giới tính',
		selector: (row) => row.sex,
		sortable: true,
	},
	{
		name: 'Chức vụ',
		selector: (row) => row.position,
		sortable: true,
	},
	{
		name: 'Ngày vào làm',
		selector: (row) => row.dateStart,
		sortable: true,
	},
	{
		name: 'Địa chỉ',
		selector: (row) => row.address,
		sortable: true,
	},
	{
		name: 'CMND',
		selector: (row) => row.cmnd,
		sortable: true,
	},
	{
		name: 'Trạng thái',
		selector: (row) => row.status,
		sortable: true,
	},
];

const data = [
	{
		id: 1,
		name: 'Tran Van A',
		phone: '0123456789',
		email: 'tranvana@gmail.com',
		sex: 'Nam',
		position: 'Nhân viên',
		dateStart: '2021-08-01 10:00:00',
		address: 'Hà Nội',
		cmnd: '123456789',
		status: 'Đang làm việc',
		description: {
			group: 'Nhóm 1',
			desc: 'Đây là nhân viên của phòng 1',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 2,
		name: 'Tran Van B',
		phone: '0123456789',
		email: 'tranvanb@gmail.com',
		sex: 'Nam',
		position: 'Nhân viên',
		dateStart: '2021-08-01 10:00:00',
		address: 'Hà Nội',
		cmnd: '123456789',
		status: 'Đang làm việc',
		description: {
			group: 'Nhóm 2',
			desc: 'Đây là nhân viên của phòng 2',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 3,
		name: 'Tran Van C',
		phone: '0123456789',
		email: 'tranvanc@gmail.com',
		sex: 'Nam',
		position: 'Nhân viên',
		dateStart: '2021-08-01 10:00:00',
		address: 'Hà Nội',
		cmnd: '123456789',
		status: 'Đang làm việc',
		description: {
			group: 'Nhóm 3',
			desc: 'Đây là nhân viên của phòng 3',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 4,
		name: 'Tran Van D',
		phone: '0123456789',
		email: 'tranvand@gmail.com',
		sex: 'Nam',
		position: 'Nhân viên',
		dateStart: '2021-08-01 10:00:00',
		address: 'Hà Nội',
		cmnd: '123456789',
		status: 'Đang làm việc',
		description: {
			group: 'Nhóm 4',
			desc: 'Đây là nhân viên của phòng 4',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 5,
		name: 'Tran Van E',
		phone: '0123456789',
		email: 'tranvane@gmail.com',
		sex: 'Nam',
		position: 'Nhân viên',
		dateStart: '2021-08-01 10:00:00',
		address: 'Hà Nội',
		cmnd: '123456789',
		status: 'Đang làm việc',
		description: {
			group: 'Nhóm 5',
			desc: 'Đây là nhân viên của phòng 5',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
];

function ListStaffContent() {
	const [open, setOpen] = useState(false);
	const [openDialogItem, setOpenDialogItem] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		console.log('close');
		setOpen(false);
	};

	const handleOpenDialogItem = () => {
		setOpenDialogItem(true);
	};

	const handleCloseDialogItem = () => {
		setOpenDialogItem(false);
	};
	return (
		<LayoutContainer
			FilterLeft={
				<>
					<ListStaffFilter />
				</>
			}
			children={
				<>
					<TableContainer
						columns={columns}
						title={'Danh sách nhân viên'}
						data={data}
						actions={[
							<button
								onClick={() => {
									handleOpen();
								}}
							>
								<AddIcon />
								<span className='item_title'>Thêm nhân viên</span>
							</button>,
							<button>
								<GetAppIcon />
								<span className='item_title'>Import</span>
							</button>,
							<button>
								<PublishIcon />
								<span className='item_title'>Xuất file</span>
							</button>,
						]}
					/>
					<ListStaffDialog
						open={open}
						handleClose={handleClose}
						handleOpenDialogItem={handleOpenDialogItem}
					/>

					<ListStaffItemDialog
						open={openDialogItem}
						handleClose={handleCloseDialogItem}
						className={'dialog_item'}
					/>
				</>
			}
		/>
	);
}

const ListStaff = (props) => {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<ListStaffContent />
		</Container>
	);
};

export default ListStaff;
