import React from 'react';
import ListBase from '../ListBase/ListBase';
import withContainer from '../withContainer';
import CustomerGroupFilter from './CustomerGroupFilter';
import FormCustomerGroupDialog from './Dialog/FormCustomerGroupDialog';

const ListCustomerGroup = () => {
	const columns = [
		{
			name: 'Tên nhóm',
			selector: (row) => row.group_name,
			sortable: true,
			wrap: true,
		},
	];

	return (
		<ListBase
			api='dmnhkh'
			title='Danh sách nhóm khách hàng/nhà cung cấp'
			columns={columns}
			FilterComponent={CustomerGroupFilter}
			FormDialog={FormCustomerGroupDialog}
		/>
	);
};

export default withContainer(ListCustomerGroup);
