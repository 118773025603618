import React, { useState } from 'react';
import { Input, Tag } from 'antd';
import styles from './VComponent.module.css';

export default function VTagInput({ value = [], onChange, ...props }) {
	const [text, setText] = useState('');
	const [tags, setTags] = useState(value);

	const isExist = tags.includes(text);

	React.useEffect(() => {
		onChange(tags);
	}, [tags]);

	React.useEffect(() => {
		setTags(value);
	}, [value]);

	const handleTagClose = (removedTag) => {
		const newTags = tags.filter((tag) => tag !== removedTag);
		setTags(newTags);
	};

	const handleTagInputConfirm = (e) => {
		e.preventDefault();

		const newTag = text.trim();
		if (newTag && !tags.includes(newTag)) {
			const newTags = [...tags, newTag];
			setTags(newTags);
			setText('');
		}
	};

	return (
		<div className={styles.tag_input_container}>
			<Input
				type="text"
				placeholder="Add tag"
				onPressEnter={handleTagInputConfirm}
				onBlur={handleTagInputConfirm}
				status={isExist ? 'error' : undefined}
				{...props}
				style={{ width: '100%', ...props?.style }}
				value={text}
				onChange={(e) => setText(e.target.value?.trim())}
			/>

			{isExist && <p style={{ color: 'red', width: '100%' }}>This tag is already existed!</p>}

			{tags.map((tag) => (
				<Tag closable onClose={() => handleTagClose(tag)} key={tag} style={{ margin: 0 }}>
					{tag}
				</Tag>
			))}
		</div>
	);
}
