import React, { useEffect, useRef, useState } from 'react';
import { AiFillDelete, AiFillFileAdd, AiFillPrinter } from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { IoDuplicateOutline } from 'react-icons/io5';
import TableManager from '../../../components/tableManager/TableManager';
import { convertStringToNumber } from '../../Utils';
import NotiDialog from '../../components/NotiDialog';

const defaultValues = {
	ma_hoa_don: '',
	ky_hieu_hoa_don: '',
	ma_tc: '',
	so_hd: '',
	t_tien: 0,
	t_tien_nt: 0,
	thue_suat: 0,
	t_thue: 0,
	t_thue_nt: 0,
	t_hd: 0,
	t_hd_nt: 0,
	ma_kh: '',
	ma_kh_cn: '',
	ten_kh: '',
	dia_chi: '',
	ma_so_thue: '',
	ten_vt: '',
	dien_giai: '',
	ngay_hd: '',
	tk_du_thue: '',
	tk_thue_no: '',
	ma_thue: '',
	so_seri: '',
	line: 0,
};

function TabVat({ data, readonly, handleGetDataVatVao, view, isFieldNt, isTotalVoucher }) {
	const columns = [
		{
			Header: 'Mã tính chất',
			accessor: 'ma_tc',
			width: 250,
			minWidth: 200,
			maxWidth: 400,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_tc', item.ma_tc);
				},
				valueField: 'ma_tc',
				labelField: 'ten_tc',
				api: 'tc',
				q: { status: true },
				getLabel: (item) => {
					return `${item.ten_tc}`;
				},
			},
		},
		{
			Header: 'Số hoá đơn',
			accessor: 'so_hd',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			type: 'text',
		},
		{
			Header: 'Ngày hoá đơn',
			accessor: 'ngay_hd',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'date',
			handleOnChange: (index, value) => {
				updateMyData(index, 'ngay_hd', value.toISOString());
			},
		},
		{
			Header: 'TK thuế',
			accessor: 'tk_thue_no',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'tk_thue_no', item.tk);
				},
				valueField: 'tk',
				labelField: 'ten_tk',
				api: 'account',
				q: {
					status: true,
					loai_tk: 1,
				},
				prefix: true,
				getLabel: (item) => {
					return `${item.tk} - ${item.ten_tk}`;
				},
			},
		},
		{
			Header: 'TK đối ứng',
			accessor: 'tk_du_thue',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'tk_du_thue', item.tk);
				},
				valueField: 'tk',
				labelField: 'ten_tk',
				api: 'account',
				q: {
					status: true,
					loai_tk: 1,
				},
				prefix: true,
				getLabel: (item) => {
					return `${item.tk} - ${item.ten_tk}`;
				},
			},
		},
		{
			Header: `Tiền hàng ${isFieldNt?.ma_nt}`,
			accessor: 't_tien_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(index, 't_tien', convertStringToNumber(value) * isFieldNt.ty_gia);
				updateMyData(
					index,
					't_thue_nt',
					convertStringToNumber(value) * isDataVoucherVat[index].thue_suat * 0.01
				);
				updateMyData(
					index,
					't_thue',
					convertStringToNumber(value) * isDataVoucherVat[index].thue_suat * isFieldNt.ty_gia * 0.01
				);
			},
		},
		{
			Header: 'Tiền hàng VND',
			accessor: 't_tien',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
		},
		{
			Header: 'Thuế suất',
			accessor: 'thue_suat',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			Filter: false,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_thue', item.ma_thue);
					updateMyData(index, 'thue_suat', item.thue_suat);
					updateMyData(index, 'tk_thue_no', item.tk_thue_no);
					updateMyData(index, 't_thue_nt', item.thue_suat * isDataVoucherVat[index].t_tien_nt * 0.01);
					updateMyData(index, 't_thue', item.thue_suat * isDataVoucherVat[index].t_tien * 0.01);
				},
				valueField: 'ma_thue',
				labelField: 'ten_thue',
				api: 'vat',
				q: { status: true },
				getLabel: (item) => {
					return `${item.ten_thue}`;
				},
			},
		},
		{
			Header: `Tiền thuế ${isFieldNt?.ma_nt}`,
			accessor: 't_thue_nt',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(index, 't_thue', convertStringToNumber(value) * isFieldNt.ty_gia);
			},
		},
		{
			Header: `Tiền thuế VND`,
			accessor: 't_thue',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
		},
		{
			Header: 'Mã khách',
			accessor: 'ma_kh',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			Filter: false,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ma_kh', item.ma_kh);
					updateMyData(index, 'ten_kh', item.ten_kh);
					updateMyData(index, 'dia_chi', item.dia_chi);
					updateMyData(index, 'ma_so_thue', item.ma_so_thue);
				},
				valueField: 'ma_kh',
				labelField: 'ten_kh',
				api: 'customer',
				q: {
					status: true,
				},
				prefix: true,
				getLabel: (item) => {
					return `${item.ma_kh} - ${item.ten_kh}`;
				},
			},
		},
		{
			Header: 'Khách hàng',
			accessor: 'ten_kh',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
		},
		{
			Header: 'Địa chỉ',
			accessor: 'dia_chi',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			Filter: false,
			type: 'text',
		},
		{
			Header: 'Mã số thuế',
			accessor: 'ma_so_thue',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'text',
		},
		{
			Header: 'S.phẩm, dịch vụ',
			accessor: 'ten_vt',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			Filter: false,
			type: 'text',
		},
		{
			Header: 'Diễn giải',
			accessor: 'dien_giai',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'text',
		},
		{
			Header: 'Mã hoá đơn',
			accessor: 'ma_hoa_don',
			width: 200,
			minWidth: 150,
			maxWidth: 400,
			Filter: false,
			type: 'text',
		},
		{
			Header: 'K.hiệu số h.đơn',
			accessor: 'ky_hieu_hoa_don',
			width: 150,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
			type: 'text',
		},
	];
	const [isDataVoucherVat, setIsDataVoucherVat] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);

	const [openDelete, setOpenDelete] = useState(false);

	useEffect(() => {
		if (data === undefined || data?.vatvaos.length < 1) {
			const newData = [];
			for (let i = 0; i < 1; i++) {
				newData.push({
					...defaultValues,
					line: i + 1,
					t_tien: isTotalVoucher?.t_tien,
					t_tien_nt: isTotalVoucher?.t_tien / isFieldNt?.ty_gia,
				});
			}
			setIsDataVoucherVat(newData);
		} else {
			setIsDataVoucherVat(data?.vatvaos);
		}
	}, []);
	useEffect(() => {
		for (let i = 0; i < isDataVoucherVat.length; i++) {
			if (isDataVoucherVat[i]?.so_hd === '') {
				updateMyData(i, 't_tien', isTotalVoucher?.t_tien);
				updateMyData(i, 't_tien_nt', isTotalVoucher?.t_tien / isFieldNt?.ty_gia);
			}
		}
	}, [isTotalVoucher?.t_tien]);

	const skipResetRef = useRef(false);
	const updateMyData = (rowIndex, columnId, value) => {
		skipResetRef.current = true;
		setIsDataVoucherVat((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};
	useEffect(() => {
		skipResetRef.current = false;
		handleGetDataVatVao && handleGetDataVatVao(isDataVoucherVat);
	}, [isDataVoucherVat]);
	useEffect(() => {
		for (let i = 0; i < isDataVoucherVat.length; i++) {
			updateMyData(i, 't_tien', convertStringToNumber(isDataVoucherVat[i].t_tien_nt) * isFieldNt?.ty_gia);
			updateMyData(i, 't_thue', convertStringToNumber(isDataVoucherVat[i].t_thue_nt) * isFieldNt?.ty_gia);
		}
	}, [isFieldNt]);

	const menuTabVat = [
		{
			id: 1,
			tabName: 'Thêm',
			icon: <AiFillFileAdd />,
			handle: () => {
				handleOpenAddDetailItemVoucher();
			},
		},
		// {
		// 	id: 2,
		// 	tabName: 'Chuyển lên',
		// 	icon: <AiOutlineArrowUp />,
		// 	handle: () => {
		// 		// handleMoveRowItemVoucher('up');
		// 	},
		// },
		// {
		// 	id: 3,
		// 	tabName: 'Chuyển xuống',
		// 	icon: <AiOutlineArrowDown />,
		// 	handle: () => {
		// 		// handleMoveRowItemVoucher('down');
		// 	},
		// },
		{
			id: 4,
			tabName: 'Nhân dòng',
			icon: <IoDuplicateOutline />,
			handle: () => {
				handleDuplicateRowItemVoucher();
			},
		},
		{
			id: 5,
			tabName: 'Xoá',
			icon: <AiFillDelete />,
			handle: () => {
				handleDeleteItemVoucher();
			},
		},
		// {
		// 	id: 6,
		// 	tabName: 'Lấy dữ liệu',
		// 	icon: <AiFillCopy />,
		// 	handle: () => {
		// 		console.log(isDataVoucherVat);
		// 	},
		// },
		{
			id: 7,
			tabName: 'Nhập',
			icon: <BiImport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 8,
			tabName: 'Xuất',
			icon: <BiExport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 9,
			tabName: 'In',
			icon: <AiFillPrinter />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
	];

	const handleOpenAddDetailItemVoucher = () => {
		skipResetRef.current = true;
		setIsDataVoucherVat((old) => [
			...old,
			{
				...defaultValues,
				line: old.length,
				t_tien: isTotalVoucher?.t_tien,
				t_tien_nt: isTotalVoucher?.t_tien / isFieldNt?.ty_gia,
			},
		]);
	};

	//On select row
	const onSelectRowClick = (data) => {
		setDataSelected(data);
	};
	const handleDeleteItemVoucher = async () => {
		if (dataSelected.length <= 0) {
			setOpenDelete(true);
		} else {
			if (isDataVoucherVat && dataSelected) {
				const newData = isDataVoucherVat.filter((item) => {
					const index = dataSelected.findIndex((selectedItem) => selectedItem.line === item.line);
					return index === -1;
				});

				newData.forEach((item, index) => {
					item.line = index;
				});

				setIsDataVoucherVat(newData);
				setDataSelected([]);
			}
		}
	};
	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	//Nhân dòng
	const handleDuplicateRowItemVoucher = async () => {
		skipResetRef.current = true;
		dataSelected.forEach((item) => {
			delete item._id;
		});
		//  vòng lặp để tạo ra các dòng mới
		const newData = dataSelected.map((item) => {
			return { ...item, line: isDataVoucherVat.length };
		});

		setIsDataVoucherVat((old) => [...old, ...newData]);
	};

	return (
		<div className="box_tab_detail_vouvher">
			<div className="box_tab_detail_vouvher__content">
				<TableManager
					idTable={view ? 'table-vat-view' : 'table-vat'}
					columns={columns}
					data={view ? data?.vatvaos : isDataVoucherVat || []}
					updateMyData={updateMyData}
					skipReset={skipResetRef.current}
					selectRow={view ? false : true}
					onSelectRowClick={onSelectRowClick}
					readonly={readonly}
					TbodyMaxHeight={'11vh'}
					handleOpenAddDetailItemVoucher={handleOpenAddDetailItemVoucher}
					pagination={false}
					hiddenColumns={isFieldNt?.ma_nt === 'VND' ? ['t_tien', 't_thue'] : []}
					toolBar={
						<>
							{view ? (
								''
							) : (
								<div className="box_tab_detail_vouvher__header">
									{menuTabVat.map((item) => {
										return (
											<div key={item.id} onClick={item.handle} title={`${item.tabName}`}>
												{item.icon} <span className="tabname_tool_bar">{item.tabName}</span>
											</div>
										);
									})}
								</div>
							)}
						</>
					}
				/>
				<NotiDialog
					open={openDelete}
					handleClose={handleCloseDelete}
					title="Thông báo"
					content="Vui lòng chọn ít nhất 1 dòng để thực hiện chức năng này"
				/>
			</div>
		</div>
	);
}

export default TabVat;
