import React from 'react';
import Container from './Container';

export default function withContainer(Component) {
	const WrappedComponent = (props) => {
		return (
			<Container showDrawerIfIsDesktop={false}>
				<Component {...props} />
			</Container>
		);
	};

	return WrappedComponent;
}
