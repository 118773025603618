import { IconButton, Menu, MenuItem, withStyles } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { AiFillCaretDown, AiFillCaretUp, AiFillDelete } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoEllipsisVerticalCircle } from "react-icons/io5";
import { MdRemoveCircleOutline } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";
import Confirm from "../../../components/newSale/components/confirm/Confirm";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		fontSize: 13,
		color: "#000",
		backgroundColor: "white",
		"&:hover": {
			backgroundColor: "#e9f4ee",
			color: "#279656",
		},
	},
}))(MenuItem);

function SpaItemsServicePack({ data, index, add }) {
	const confirmRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [isQuantity, setIsQuantity] = useState(data.sl_order);
	const [isShowDetailService, setIsShowDetailService] = useState(false);

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const setItem = (p, sl_order) => {
		add(p, sl_order - (p.sl_order || 0));
	};

	const numberCompare = React.useMemo(() => {
		return data.sl_order;
	}, [data]);

	const handleNumberChange = (e) => {
		const value = e.target.value;
		if (isNaN(value)) {
			return;
		} else {
			setIsQuantity(Number(value));
		}
	};
	const handleOrderChanged = (product) => {
		if (numberCompare === isQuantity) return;
		let numberProduct = isQuantity;
		if (numberProduct === 0) {
			numberProduct = 1;
		}
		add(product, numberProduct, false);
		// numberCompare = number;
	};
	useEffect(() => {
		if (data) {
			setIsQuantity(data.sl_order);
		}
	}, [data]);

	return (
		<>
			<div className="box_item_bill">
				<div className="item_bill">
					<div className="detail_info_bill">
						<IconButton
							size="small"
							onClick={confirmRef?.current?.open}
						>
							<AiFillDelete />
						</IconButton>
						<div className="item_id pt-3">{index + 1}.</div>
						<div className="item_bill__name pt-3">
							<span className="bill__name">
								{data.ten_vt}
								<IconButton size="small">
									{isShowDetailService ? (
										<AiFillCaretUp
											onClick={() =>
												setIsShowDetailService(false)
											}
										/>
									) : (
										<AiFillCaretDown
											onClick={() =>
												setIsShowDetailService(true)
											}
										/>
									)}
								</IconButton>
							</span>
							<span className="bill_note">
								{data.note || "Ghi chú"}
							</span>
						</div>
					</div>
					<div className="detail_price_bill">
						<div className="gr_quantity pt-3">
							<button>
								<MdRemoveCircleOutline
									onClick={() => add(data, -1)}
								/>
							</button>
							<input
								min="1"
								type="number"
								value={isQuantity}
								onChange={(e) => handleNumberChange(e)}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										handleOrderChanged(data);
									}
								}}
								onBlur={() => handleOrderChanged(data)}
							/>
							<button>
								<IoMdAddCircleOutline
									onClick={() => add(data, 1)}
								/>
							</button>
						</div>
						<div className="item_bill__price pt-3">
							<span className="price">{data.gia_ban}</span>
							<span className="price_sell">-200</span>
						</div>
						<div className="item_bill__total_price pt-3">
							{data.gia_ban * isQuantity}
						</div>
						<IconButton size="small">
							<RiAddFill />
						</IconButton>
						<IconButton size="small">
							<IoEllipsisVerticalCircle onClick={handleOpen} />
							<StyledMenu
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<StyledMenuItem onClick={handleClose}>
									Ghi chú
								</StyledMenuItem>
								<StyledMenuItem onClick={handleClose}>
									Tư vấn bán
								</StyledMenuItem>
							</StyledMenu>
						</IconButton>
					</div>
				</div>
				{isShowDetailService && (
					<div className="detail_service">
						<div className="detail_service__item">
							<div className="detail_service__item__name">
								<span>Áp dụng: Dịch vụ, gói DV, hàng hoá</span>{" "}
								<br />
								<span>HSD: 02/03/2023</span>
							</div>
							<div className="detail_service__item__price">
								<span>Mệnh giá: 2,000,000 VNĐ</span>
							</div>
						</div>
					</div>
				)}
			</div>
			<Confirm
				ref={confirmRef}
				content={`Xác nhận xóa '${data.ten_vt}'.`}
				onConfirm={() => setItem(data, 0)}
			/>
		</>
	);
}

export default SpaItemsServicePack;
