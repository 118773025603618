import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { CiSearch } from 'react-icons/ci';
import { MdClose } from 'react-icons/md';
import { SaleContext } from '../SaleContainer';
import { useTheme } from '@material-ui/core/styles';

const SearchWrapper = styled(Box)`
	width: 300px;
	height: 34px;
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 6px;
	padding: 0 5px;
	&:focus-within {
		border-color: ${(props) => props.borderColor};
		border-width: 2px;
	}
`;
const SearchInput = styled.input`
	flex-grow: 1;
	height: 100%;
	border: none;
	outline: none;
	font-size: 13px;
	border-radius: 6px;
	padding: 0 5px;
	&::placeholder {
		color: #c4c4c4;
	}
`;
const Icon = styled(Box)`
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #999;
	cursor: ${(props) => (props.action ? 'pointer' : 'default')};
	border-radius: ${(props) => (props.action ? '50%' : '0px')};
	background-color: ${(props) => (props.action ? '#ededed' : '')};
	&:hover {
		filter: ${(props) => (props.action ? 'brightness(95%)' : '')};
	}
`;

function SearchProductNormal() {
	const theme = useTheme();
	const inputRef = React.useRef();
	const timerRef = React.useRef();
	const { setSearch } = React.useContext(SaleContext);

	const [searchText, setSearchText] = React.useState('');

	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 1000);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);
	React.useEffect(() => {
		inputRef.current?.focus();
		return () => {
			setSearch('');
		};
	}, []);
	return (
		<SearchWrapper borderColor={theme.palette.primary.main}>
			<Icon>
				<CiSearch fontSize="20px" />
			</Icon>
			<SearchInput
				ref={inputRef}
				value={searchText}
				onChange={(e) => setSearchText(e.target.value)}
				placeholder="Tìm tên, mã sản phẩm"
			/>
			{searchText && (
				<Icon action onClick={() => setSearchText('')}>
					<MdClose fontSize="14px" />
				</Icon>
			)}
		</SearchWrapper>
	);
}

export default SearchProductNormal;
