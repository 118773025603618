import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useCore } from '../../../../hooks';

export default function SelectApi({
	isDisabled,
	placeholder,
	valueField = 'id',
	labelField = 'name',
	multi = false,
	api,
	value,
	onChange,
	onFocus,
	limit = 10,
	isClearable,
	defaultValue,
	classNamePrefix,
	q,
	prefix,
	filter,
	getLabel,
	withIdApp,
	components,
}) {
	const core = useCore();
	const loadMoreOptions = async (search, prevOptions) => {
		const query = {
			...q,
		};

		if (search) {
			if (!query.$or) query.$or = [];

			query.$or.push({
				[labelField]: {
					$regex: search,
					$options: 'i',
				},
			});

			if (valueField !== '_id' && valueField !== labelField) {
				query.$or.push({
					[valueField]: {
						$regex: prefix ? `^${search}` : search,
						$options: 'i',
					},
				});
			}
		}

		if (!search) delete query.$or; // remove search field

		const { data } = await core.apiCall({
			endpoint: api,
			withIdApp: withIdApp,
			params: {
				page: prevOptions.length / limit + 1,
				limit,
				q: query,
				...filter,
			},
		});

		return {
			options: data.map((item) => ({
				...item,
				value: item[valueField],
				label: getLabel?.(item) || item[labelField],
			})),
			hasMore: data.length === limit,
		};
	};

	const customComponents = {
		...components,
	};
	return (
		<AsyncPaginate
			isMulti={multi}
			isClearable={isClearable}
			isDisabled={isDisabled}
			placeholder={placeholder}
			// {...selectProps}
			defaultValue={defaultValue}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			loadOptions={loadMoreOptions}
			components={customComponents}
			debounceTimeout={500}
			menuPlacement="auto"
			classNamePrefix={classNamePrefix}
			theme={(theme) => ({
				...theme,
				borderRadius: 0,
				colors: {
					...theme.colors,
					primary: '#99dbf8',
				},
			})}
			menuPortalTarget={document.body}
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
			cacheUniqs={[q]}
			noOptionsMessage={() => 'Không có dữ liệu'}
		/>
	);
}
