import React, { useEffect, useState } from 'react';
import { AiFillDelete, AiFillFileAdd, AiFillPrinter } from 'react-icons/ai';
import { BiExport, BiImport } from 'react-icons/bi';
import { IoDuplicateOutline } from 'react-icons/io5';
import TableManager from '../../../../components/tableManager/TableManager';
import NotiDialog from '../../../components/NotiDialog';

const defaultValues = {
	tien_cp_nt: 0,
	tien_cp: 0,
	kieu_pb: '',
	line: 0,
	ma_cp: '',
	ten_cp: '',
};
function TabCpmh({ data, readonly, handleGetDataCtcpmhs, handleAllotmentPn1, view, selectRow, isFieldNt }) {
	const convertStringToNumber = (str) => {
		if (typeof str !== 'string') return str;
		return Number(str.replace(/[^0-9.-]+/g, ''));
	};
	useEffect(() => {
		if (view || !isFieldNt.checkEdit) {
			for (let i = 0; i < isDataVoucherCpmh.length; i++) {
				updateMyData(i, 'tien_cp', convertStringToNumber(isDataVoucherCpmh[i].tien_cp_nt) * isFieldNt?.ty_gia);
				const total_tien_cp_nt = isDataVoucherCpmh.reduce(
					(total, item) => total + (item.ten_cp ? item.tien_cp_nt : 0),
					0
				);
				const total_tien_cp = isDataVoucherCpmh.reduce(
					(total, item) => total + (item.ten_cp ? isDataVoucherCpmh[i].tien_cp_nt * isFieldNt?.ty_gia : 0),
					0
				);
				handleAllotmentPn1?.({ total_tien_cp_nt, total_tien_cp });
			}
		} else return;
	}, [isFieldNt]);
	const columns = [
		{
			Header: 'Loại chi phí',
			accessor: 'ten_cp',
			width: 300,
			minWidth: 200,
			maxWidth: 400,
			type: 'select',
			selectObject: {
				onChange: (item, index) => {
					updateMyData(index, 'ten_cp', item.ten_cp);
					updateMyData(index, 'ma_cp', item.ma_cp);
				},
				valueField: 'ma_cp',
				labelField: 'ten_cp',
				api: 'dmcpmh',
				q: { status: true },
				getLabel: (item) => {
					return `${item.ten_cp}`;
				},
			},
		},
		{
			Header: `Tiền ${isFieldNt?.ma_nt}`,
			accessor: 'tien_cp_nt',
			width: 200,
			minWidth: 100,
			maxWidth: 400,
			type: 'number',
			Filter: false,
			handleOnChange: (index, value) => {
				updateMyData(index, 'tien_cp', convertStringToNumber(value) * isFieldNt?.ty_gia);
			},
		},
		{
			Header: 'Tiền VND',
			accessor: 'tien_cp',
			width: 200,
			minWidth: 100,
			maxWidth: 400,
			Filter: false,
		},
	];
	const [isDataVoucherCpmh, setIsDataVoucherCpmh] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);
	const [openDelete, setOpenDelete] = useState(false);

	useEffect(() => {
		if (data === undefined || data?.ctcpmhs.length < 1) {
			const newData = [];
			for (let i = 0; i < 1; i++) {
				newData.push({ ...defaultValues, line: i + 1 });
			}
			setIsDataVoucherCpmh(newData);
		} else {
			setIsDataVoucherCpmh(data?.ctcpmhs);
		}
	}, []);

	const skipResetRef = React.useRef(false);
	const updateMyData = (rowIndex, columnId, value) => {
		skipResetRef.current = true;
		setIsDataVoucherCpmh((old) =>
			old.map((row, index) => {
				if (index === rowIndex) {
					return {
						...row,
						[columnId]: value,
					};
				}
				return row;
			})
		);
	};
	useEffect(() => {
		skipResetRef.current = false;
		handleGetDataCtcpmhs && handleGetDataCtcpmhs(isDataVoucherCpmh);
	}, [isDataVoucherCpmh]);

	const handleAllotmentPn = () => {
		const total_tien_cp_nt = isDataVoucherCpmh.reduce(
			(total, item) => total + (item.ten_cp ? item.tien_cp_nt : 0),
			0
		);
		const total_tien_cp = isDataVoucherCpmh.reduce((total, item) => total + (item.ten_cp ? item.tien_cp : 0), 0);
		handleAllotmentPn1?.({ total_tien_cp_nt, total_tien_cp });
	};

	const menuTabCpmh = [
		{
			id: 1,
			tabName: 'Thêm',
			icon: <AiFillFileAdd />,
			handle: () => {
				handleOpenAddDetailItemVoucher();
			},
		},
		// {
		// 	id: 2,
		// 	tabName: 'Chuyển lên',
		// 	icon: <AiOutlineArrowUp />,
		// 	handle: () => {
		// 		// handleMoveRowItemVoucher("up");
		// 	},
		// },
		// {
		// 	id: 3,
		// 	tabName: 'Chuyển xuống',
		// 	icon: <AiOutlineArrowDown />,
		// 	handle: () => {
		// 		// handleMoveRowItemVoucher("down");
		// 	},
		// },
		{
			id: 4,
			tabName: 'Nhân dòng',
			icon: <IoDuplicateOutline />,
			handle: () => {
				handleDuplicateRowItemVoucher();
			},
		},
		{
			id: 5,
			tabName: 'Xoá',
			icon: <AiFillDelete />,
			handle: () => {
				handleDeleteItemVoucher();
			},
		},
		// {
		// 	id: 6,
		// 	tabName: 'Lấy dữ liệu',
		// 	icon: <AiFillCopy />,
		// 	handle: () => {
		// 		console.log(isDataVoucherCpmh);
		// 	},
		// },
		{
			id: 7,
			tabName: 'Nhập',
			icon: <BiImport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 8,
			tabName: 'Xuất',
			icon: <BiExport />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
		{
			id: 9,
			tabName: 'In',
			icon: <AiFillPrinter />,
			handle: () => {
				alert('Tính năng đang phát triển');
			},
		},
	];

	const handleOpenAddDetailItemVoucher = () => {
		skipResetRef.current = true;
		setIsDataVoucherCpmh((old) => [...old, { ...defaultValues, line: old.length }]);
	};

	//On select row
	const onSelectRowClick = (data) => {
		setDataSelected(data);
	};
	const handleDeleteItemVoucher = async () => {
		if (dataSelected.length <= 0) {
			setOpenDelete(true);
		} else {
			if (isDataVoucherCpmh && dataSelected) {
				const newData = isDataVoucherCpmh.filter((item) => {
					const index = dataSelected.findIndex((selectedItem) => selectedItem.line === item.line);
					return index === -1;
				});

				newData.forEach((item, index) => {
					item.line = index;
				});

				setIsDataVoucherCpmh(newData);
				setDataSelected([]);
			}
		}
	};
	const handleCloseDelete = () => {
		setOpenDelete(false);
	};

	//Nhân dòng
	const handleDuplicateRowItemVoucher = async () => {
		skipResetRef.current = true;
		dataSelected.forEach((item) => {
			delete item._id;
		});
		//  vòng lặp để tạo ra các dòng mới
		const newData = dataSelected.map((item) => {
			return { ...item, line: isDataVoucherCpmh.length };
		});

		setIsDataVoucherCpmh((old) => [...old, ...newData]);
	};

	return (
		<div className="box_tab_detail_vouvher">
			<div className="box_tab_detail_vouvher__content">
				<TableManager
					idTable={readonly ? 'cpmh_view' : 'cpmh'}
					columns={columns}
					data={isDataVoucherCpmh}
					updateMyData={updateMyData}
					skipReset={skipResetRef.current}
					selectRow={selectRow}
					onSelectRowClick={onSelectRowClick}
					readonly={readonly}
					TbodyMaxHeight={'11vh'}
					handleOpenAddDetailItemVoucher={handleOpenAddDetailItemVoucher}
					pagination={false}
					hiddenColumns={isFieldNt?.ma_nt === 'VND' ? ['tien_cp'] : []}
					toolBar={
						<>
							{view ? (
								''
							) : (
								<div className="box_tab_detail_vouvher__header">
									{menuTabCpmh.map((item) => {
										const shouldDisplayItem =
											dataSelected.length > 0 || (item.id !== 4 && item.id !== 5);
										if (shouldDisplayItem) {
											return (
												<div key={item.id} onClick={item.handle} title={`${item.tabName}`}>
													{item.icon} <span className="tabname_tool_bar">{item.tabName}</span>
												</div>
											);
										}
										return null;
									})}
								</div>
							)}
						</>
					}
				/>
				<NotiDialog
					open={openDelete}
					handleClose={handleCloseDelete}
					title="Thông báo"
					content="Vui lòng chọn ít nhất 1 dòng để thực hiện chức năng này"
				/>
			</div>
			{!readonly && (
				<div
					style={{
						marginTop: '10px',
					}}
				>
					<button
						onClick={() => {
							handleAllotmentPn();
						}}
						className="btn btn-fill"
					>
						{' '}
						Phân bổ{' '}
					</button>
				</div>
			)}
		</div>
	);
}

export default TabCpmh;
