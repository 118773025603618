import React from 'react';
import Dialog from '../../Dialog/Dialog';
import { Box, Grid } from '@material-ui/core';
import FilterSearch from '../../filter/FilterSearch';
import FilterSelectTimeFromTo from '../../filter/FilterSelectTimeFromTo';
import moment from 'moment';
import TableContainer from '../../table/TableContainer';
import { useCore } from '../../../../../hooks';
import { formatDateDisplay, numeralCustom } from '../../../../../utils/helpers';
import { SaleContext } from '../SaleContainer';

const columns = [
	{
		name: 'Mã hóa đơn',
		selector: (row) => row.so_ct,
		center: true,
	},
	{
		name: 'Ngày tạo',
		selector: (row) => row.ngay_ct,
		format: (row) => formatDateDisplay(row.ngay_ct),
		center: true,
	},
	{
		name: 'Nhân viên',
		selector: (row) => row.ong_ba,
		center: true,
	},
	{
		name: 'Khách hàng',
		selector: (row) => row.ten_kh,
		center: true,
	},
	{
		name: 'Tổng cộng',
		selector: (row) => row.t_tt_nt,
		format: (row) => numeralCustom(row.t_tt_nt).format(),
		center: true,
	},
];

function ReturnDialog({ open, handleClose }) {
	const { contextAlert, asyncGetList, asyncPostList, token } = useCore();
	const { ma_kho, getVoucher } = React.useContext(SaleContext);
	const [data, setData] = React.useState([]);
	const [totalRows, setTotalRows] = React.useState();
	const [filter, setFilter] = React.useState({
		so_ct: '',
		startDate: moment().subtract(1, 'days'),
		endDate: null,
		page: 1,
		limit: 20,
	});
	const handleFilterChange = (key, newValue) => {
		setFilter({ ...filter, [key]: newValue });
	};
	// handle select voucher
	const handleSelectVoucher = async (voucherSelected) => {
		const hd7 = {
			trang_thai: 'LCT',
			ma_kho,
			pt_thanh_toan: voucherSelected.pt_thanh_toan,
			ten_pt_thanh_toan: voucherSelected.ten_pt_thanh_toan,
			id_pbl: voucherSelected._id,
			details: voucherSelected.details.map((detail) => ({
				...detail,
				id_hd: voucherSelected._id,
				sl_nhap: 0,
			})),
		};
		await asyncPostList(token, 'hd7', hd7);
		await getVoucher();
		handleClose();
	};
	// get data
	const getData = () => {
		try {
			const condition = {
				$and: [{ trang_thai: '6' }],
			};
			if (filter.startDate) {
				condition.$and.push({ ngay_ct: { $gte: filter.startDate } });
			}
			if (filter.endDate) {
				condition.$and.push({ ngay_ct: { $lte: filter.endDate } });
			}
			if (filter.so_ct) {
				condition.$and.push({ so_ct: filter.so_ct });
			}
			Promise.all([
				asyncGetList(token, 'pbl', { page: filter.page, limit: filter.limit, condition }),
				asyncGetList(token, 'pbl', { condition, count: 1 }),
			]).then(([resp1, { rows_number }]) => {
				setData(resp1);
				setTotalRows(rows_number);
			});
		} catch (error) {
			contextAlert(error);
		}
	};
	React.useEffect(() => {
		getData();
	}, [filter]);

	return (
		<Dialog width="80vw" title="Chọn hóa đơn trả hàng" open={open} handleClose={handleClose}>
			<Box sx={{ width: '100%' }}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={3}>
						<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
							<FilterSearch title="Mã hóa đơn" onSearch={(value) => handleFilterChange('so_ct', value)} />
							<FilterSelectTimeFromTo
								startDay={filter.startDate}
								endDay={filter.endDate}
								setStartDay={(value) => handleFilterChange('startDate', value)}
								setEndDay={(value) => handleFilterChange('endDate', value)}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={9}>
						<TableContainer
							columns={columns}
							data={data}
							fixedHeaderScrollHeight="350px"
							totalRows={totalRows}
							handlePaginationChange={(page, limit) => setFilter({ ...filter, page, limit })}
							onRowClick={handleSelectVoucher}
							page={filter.page}
							limit={filter.limit}
						/>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
}

export default ReturnDialog;
