import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useState } from 'react';
import Container from '../Container';
import LayoutContainer from '../components/LayoutContainer';
import TableContainer from '../components/table/TableContainer';
import './RoomMap.css';
import RoomMapFilter from './RoomMapFilter';
import RoomMapDialog from './Dialog/RoomMapDialog';
import RoomMapItemDialog from './Dialog/RoomMapItemDialog';

const columns = [
	{
		name: 'Mã vị trí/phòng',
		selector: (row) => row.id,
		sortable: true,
	},
	{
		name: 'Tên phòng',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Nhóm',
		selector: (row) => row.group,
		sortable: true,
	},
	{
		name: 'Số thứ tự',
		selector: (row) => row.number,
		sortable: true,
	},
	{
		name: 'Ghi chú',
		selector: (row) => row.note,
		sortable: true,
	},
];

const data = [
	{
		id: 1,
		name: 'Phòng 1',
		group: 'Tầng 1',
		number: '1',
		note: 'Ghi chú',
		description: {
			room: 'Phòng 1',
			desc: 'Đây là mô tả của phòng 1',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 2,
		name: 'Phòng 2',
		group: 'Tầng 2',
		number: '2',
		note: 'Ghi chú',
		description: {
			room: 'Phòng 2',
			desc: 'Đây là mô tả của phòng 2',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 3,
		name: 'Phòng 3',
		group: 'Tầng 3',
		number: '3',
		note: 'Ghi chú',
		description: {
			room: 'Phòng 3',
			desc: 'Đây là mô tả của phòng 3',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 4,
		name: 'Phòng 4',
		group: 'Tầng 4',
		number: '4',
		note: 'Ghi chú',
		description: {
			room: 'Phòng 4',
			desc: 'Đây là mô tả của phòng 4',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
	{
		id: 5,
		name: 'Phòng 5',
		group: 'Tầng 5',
		number: '5',
		note: 'Ghi chú',
		description: {
			room: 'Phòng 5',
			desc: 'Đây là mô tả của phòng 5',
		},
		dataTable: [
			{
				id: 1,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van A',
				total: '1.000.000',
			},
			{
				id: 2,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van B',
				total: '1.000.000',
			},
			{
				id: 3,
				timedate: '2021-08-01 10:00:00',
				account: 'Tran Van C',
				total: '1.000.000',
			},
		],
	},
];

function RoomMapContent() {
	const [open, setOpen] = useState(false);
	const [openDialogItem, setOpenDialogItem] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		console.log('close');
		setOpen(false);
	};

	const handleOpenDialogItem = () => {
		setOpenDialogItem(true);
	};

	const handleCloseDialogItem = () => {
		setOpenDialogItem(false);
	};

	return (
		<LayoutContainer
			FilterLeft={<RoomMapFilter />}
			children={
				<>
					<TableContainer
						columns={columns}
						title={<div>Danh sách phòng</div>}
						data={data}
						actions={[
							<button
								onClick={() => {
									handleOpen();
								}}
							>
								<AddIcon />
								<span className='item_title'>Thêm vị trí/phòng</span>
							</button>,
							<button>
								<GetAppIcon />
								<span className='item_title'>Import</span>
							</button>,
							<button>
								<PublishIcon />
								<span className='item_title'>Xuất file</span>
							</button>,
						]}
					/>

					<RoomMapDialog open={open} handleClose={handleClose} handleOpenDialogItem={handleOpenDialogItem} />

					<RoomMapItemDialog
						open={openDialogItem}
						handleClose={handleCloseDialogItem}
						className={'dialog_item'}
					/>
				</>
			}
		/>
	);
}

export default function RoomMap(props) {
	return (
		<Container {...props} showDrawerIfIsDesktop={false}>
			<RoomMapContent />
		</Container>
	);
}
