import { Select } from 'antd';
import React from 'react';
import { HiChevronDown } from 'react-icons/hi2';
import { useCore } from '../../../hooks';

/**
 * Generates a Select component with the provided props.
 *
 * @param {import('antd').SelectProps} rest - An object containing the props to be passed to the Select component
 * @return {JSX.Element} A Select component with the provided props.
 */
function VSelect({ ...rest }) {
	return <Select {...rest} suffixIcon={<HiChevronDown />} />;
}

/**
 * A React component that renders a searchable dropdown list populated with data fetched from an API endpoint.
 *
 * @param {import('antd').SelectProps} props - The component props.
 * @param {string} props.api - The API endpoint to fetch the data from.
 * @param {Object} props.callApiProps - Additional parameters to pass to the API call.
 * @param {Object} [props.field={ value: '_id', label: '_id' }] - The field to use for the option values and labels.
 * @param {string} props.value - The current selected value.
 * @param {function} props.onChange - The function to call when the selected value changes.
 * @param {number} [props.rowsPerPage=10] - The number of rows to fetch per page.
 * @param {number} [props.scrollThreshold=100] - The number of pixels from the bottom of the list at which to fetch more data.
 * @return {JSX.Element} A React component that renders a searchable dropdown list populated with data fetched from an API endpoint.
 */
function VSelectApi({
	api,
	callApiProps,
	field = { value: '_id', label: '_id' },
	rowsPerPage = 10,
	scrollThreshold = 100,
	...rest
}) {
	const core = useCore();

	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [params, setParams] = React.useState({ limit: rowsPerPage });
	const [hasMore, setHasMore] = React.useState(true);
	const searchRef = React.useRef('');

	const fetchData = async (page) => {
		// Prepare params q
		const q = { ...callApiProps?.params?.q };
		const search = searchRef.current;
		if (search) {
			if (field.value !== '_id') q[field.value] = { $regex: search, $options: 'i' };
			if (field.label !== '_id') q[field.label] = { $regex: search, $options: 'i' };
		}

		// Prepare params
		const params = { page, ...params, ...callApiProps?.params, q };

		setLoading(true);
		try {
			const { data } = await core.apiCall({ endpoint: api, ...callApiProps, params });

			setData((prev) => (page === 1 ? data : [...prev, ...data]));
			setParams(params);

			if (data.length < rowsPerPage) setHasMore(false);
		} catch (error) {
			console.log('getData error', error);
		}
		setLoading(false);
	};

	React.useEffect(() => {
		fetchData(1); // Fetch first page
	}, []);

	const handeScroll = (e) => {
		const isBottom = e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < scrollThreshold;

		const needMore = isBottom && hasMore;

		if (needMore && !loading) fetchData(params.page + 1);
	};

	const handleSearch = (value) => {
		searchRef.current = value;
		fetchData(1);
		setHasMore(true);
	};

	return (
		<VSelect
			options={data.map((item) => ({
				value: item[field.value],
				label: field.getLabel?.(item) || item[field.label], // use getLabel function if provided
				...item,
			}))}
			loading={loading}
			onPopupScroll={handeScroll}
			onSearch={handleSearch}
			filterOption={false}
			showSearch
			{...rest}
		/>
	);
}
VSelect.API = VSelectApi;

export default VSelect;
