import React, { useState } from 'react';
import Layout from '../../components/LayoutContainer';
import Container from '../../Container';
import { formatCurrency, formatDateValue } from '../../Utils/MyUtils';
import SaleByTimeData from './SaleByTimeData';
import SaleByTimeFilter from './SaleByTimeFilter';

function SaleByTimeContent({ props }) {
    const [data, setData] = useState([
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
        {
            ngay_ct: `${new Date()}`,
            t_tien: '90000',
            t_ck: '10000',
            t_tt: '80000',
            tien_mat: '80000',
            tien_ck: '0',
            vi_momo: '0',
            vn_pay: '0',
        },
    ]);
    const onFilter = (data) => {
        console.log(data);
    };

    const columns = [
        {
            name: 'Ngày chứng từ',
            selector: (row) => formatDateValue(row.ngay_ct),
        },
        {
            name: 'Tiền hàng (VNĐ)',
            selector: (row) => formatCurrency(row.t_tien),
        },
        {
            name: 'Giảm giá (VNĐ)',
            selector: (row) => formatCurrency(row.t_ck),
        },
        {
            name: 'Doanh thu (VNĐ)',
            selector: (row) => formatCurrency(row.t_tt),
        },
        {
            name: 'Tiền mặt (VNĐ)',
            selector: (row) => formatCurrency(row.tien_mat),
        },
        {
            name: 'Chuyển khoảng  (VNĐ)',
            selector: (row) => formatCurrency(row.tien_ck),
        },
        {
            name: 'Ví momo  (VNĐ)',
            selector: (row) => formatCurrency(row.vi_momo),
        },
        {
            name: 'Vn pay (VNĐ)',
            selector: (row) => formatCurrency(row.vn_pay),
        },
    ];
    return (
        <Layout FilterLeft={<SaleByTimeFilter onFilter={onFilter} />}>
            <SaleByTimeData data={data} columns={columns} />
        </Layout>
    );
}

const SaleByTime = (props) => {
    return (
        // <LayoutContainer
        //   FilterLeft={
        //     <div className="roommap__filter">
        //       <Fab
        //         onClick={handleSubmit(onSubmit)}
        //         sx={{
        //           backgroundColor: "#013659",
        //           textTransform: "none",
        //           color: "#fff",
        //           "&:hover": {
        //             color: "#013659",
        //           },
        //           fontSize: "12px",
        //           fontWeight: "bold",
        //           padding: "5px 10px",
        //           margin: "8px",
        //           borderRadius: "5px",
        //           height: "30px",
        //         }}
        //         variant="extended"
        //       >
        //         Lọc
        //       </Fab>

        //       <FilterBox title="Tìm kiếm">
        //         <input
        //           type="text"
        //           placeholder="Tìm kiếm"
        //           className="shadow__box__search"
        //           {...register("so_ct")}
        //         />
        //       </FilterBox>
        //       <FilterSelectTimeFromTo
        //         control={control}
        //         fieldStartDay={"ngay_bat_dau"}
        //         fieldEndDay={"ngay_ket_thuc"}
        //       />
        //     </div>
        //   }
        //   children={
        //     <>
        //       <TableContainer
        //         columns={columns}
        //         title={"Báo cáo cuối ngày bán hàng"}
        //         data={data}
        //         actions={[
        //           <button>
        //             <PublishIcon />
        //             <span className="item_title">Xuất file</span>
        //           </button>,
        //         ]}
        //       />
        //     </>
        //   }
        //   SaleByTimeContent
        // />
        <Container {...props} showDrawerIfIsDesktop={false}>
            <SaleByTimeContent />
        </Container>
    );
};

export default SaleByTime;
