import React from 'react';
import TableContainer, {
    TableTypes,
} from '../../components/table/TableContainer';
import PublishIcon from '@material-ui/icons/Publish';
import {
    formatCurrency,
    formatDateValue,
    handleDownloadExcel,
} from '../../Utils/MyUtils';
import WebAssetRoundedIcon from '@material-ui/icons/WebAssetRounded';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';

const columns = [
    {
        name: 'Ngày chứng từ',
        selector: (row) => formatDateValue(row.ngay_ct),
        sortable: true,
    },
    {
        name: 'Số chứng từ',
        selector: (row) => row.so_ct,
        sortable: true,
    },
    {
        name: 'Mã vật tư',
        selector: (row) => row.ma_vt,
        sortable: true,
    },
    {
        name: 'Đơn vị tính',
        selector: (row) => row.dvt,
        sortable: true,
    },
    {
        name: 'Số lượng bán',
        selector: (row) => row.sl_order,
        sortable: true,
    },
    {
        name: 'Đơn giá (VNĐ)',
        selector: (row) => formatCurrency(row.gia_ban),
    },
    {
        name: 'Tiền hàng (VNĐ)',
        selector: (row) => formatCurrency(row.t_tien),
    },
    {
        name: 'Giảm giá (VNĐ)',
        selector: (row) => formatCurrency(row.t_ck),
    },
    {
        name: 'Doanh thu (VNĐ)',
        selector: (row) => formatCurrency(row.t_tt),
    },
    {
        name: 'Loại mặt hàng',
        selector: (row) => row.l_mh,
        sortable: true,
    },
    {
        name: 'Nhóm hàng',
        selector: (row) => row.nh_h,
        sortable: true,
    },
    {
        name: 'Mã khách hàng',
        selector: (row) => row.ma_kh,
        sortable: true,
    },
    {
        name: 'Tên khách hàng',
        selector: (row) => row.ten_kh,
        sortable: true,
    },
];

const CTDTByChungTuVatTuData = ({ data }) => {
    const downloadExcel = () => {
        handleDownloadExcel(data, 'SHEET_NAME', 'MY_FILENAME');
    };
    return (
        <TableContainer
            columns={columns}
            title={'CT DT theo chứng từ vật tư'}
            data={data}
            actions={[
                <button onClick={downloadExcel}>
                    <WebAssetRoundedIcon />
                    <span className="item_title">Xem trước</span>
                </button>,
                <button onClick={downloadExcel}>
                    <PublishIcon />
                    <span className="item_title">Xuất file</span>
                </button>,
                <button onClick={downloadExcel}>
                    <PrintRoundedIcon />
                    <span className="item_title">In</span>
                </button>,
            ]}
            type={TableTypes.REPORT}
            reportData={{
                ngay_ct: 'Tổng cộng',
                so_ct: '',
                ma_vt: '',
                dvt: '',
                sl_order: data.reduce((acc, cur) => acc + cur.sl_order, 0),
                gia_ban: '',
                t_tien: data.reduce((acc, cur) => acc + cur.t_tien, 0),
                t_ck: data.reduce((acc, cur) => acc + cur.t_ck, 0),
                t_tt: data.reduce((acc, cur) => acc + cur.t_tt, 0),
                l_mh: '',
                nh_h: '',
                ma_kh: '',
                ten_kh: '',
            }}
        />
    );
};

export default CTDTByChungTuVatTuData;
