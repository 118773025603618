import { Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import { GrFormUp } from 'react-icons/gr';

function FilterBoxContent({ children, collapsable, isCollapsed }) {
	if (collapsable) {
		return (
			<Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
				<div className="shadow__box__content">{children}</div>
			</Collapse>
		);
	}

	return <div className="shadow__box__content">{children}</div>;
}

function FilterBoxAction({ children, collapsable, isCollapsed, toggleCollapse }) {
	return (
		<div className="shadow__box__action">
			{children}
			{collapsable && (
				<GrFormUp
					className="shadow__box__action-item"
					onClick={toggleCollapse}
					style={{
						transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: 'all 0.3s',
					}}
				/>
			)}
		</div>
	);
}

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode[]} props.actions
 * @param {boolean} props.collapsable
 * @returns
 */
export default function FilterBox({ title, children, actions, collapsable, isSetCollapsed = false, className }) {
	const [isCollapsed, setIsCollapsed] = useState(isSetCollapsed);
	const toggleCollapse = () => setIsCollapsed(!isCollapsed);

	// Add className="shadow__box__content" to all actions
	actions = actions?.map((action, index) => {
		return React.cloneElement(action, {
			className: 'shadow__box__action-item',
			key: index,
		});
	});

	return (
		<div className={'product__search shadow__box' + (className ? ' ' + className : '')}>
			<div className="shadow__box__title">
				{title}

				<FilterBoxAction collapsable={collapsable} isCollapsed={isCollapsed} toggleCollapse={toggleCollapse}>
					{actions}
				</FilterBoxAction>
			</div>

			<FilterBoxContent collapsable={collapsable} isCollapsed={isCollapsed}>
				{children}
			</FilterBoxContent>
		</div>
	);
}
