import React from "react";
import { Box, Paper } from "@material-ui/core";
import styled from "styled-components";
import Dialog from "../components/Dialog/Dialog";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";

const FormWrapper = styled(Box)`
  width: 40vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
const InputWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const InputLabel = styled.label`
  font-size: 13px;
  font-weight: 550;
`;
const Options = styled(Paper)`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  align-content: flex-start;
`;
const OptionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const fields = [
  { key: "ma_vt", name: "Mã vật tư" },
  { key: "ten_vt", name: "Tên vật tư" },
  { key: "xuat_xu", name: "Xuất xứ" },
  { key: "nhan_hieu", name: "Nhãn hiệu" },
  { key: "nha_cung_cap", name: "Nhà cung cấp" },
  { key: "gia_ban_le", name: "Giá bán lẻ" },
];

function CustomDisplayPopup({
  open,
  handleClose,
  product,
  setSelectedProducts,
}) {
  // handleDisplayTopChange
  const handleDisplayChange = (e, key = "top") => {
    const value = e.target.value;
    let disPlayTop = product.display[key];
    if (disPlayTop.includes(value)) {
      disPlayTop = disPlayTop.filter((item) => item !== value);
    } else {
      disPlayTop.push(value);
    }
    setSelectedProducts((prev) => {
      const prevClone = cloneDeep(prev);
      const index = prevClone.findIndex((p) => p._id === product._id);
      if (index >= 0) {
        prevClone[index].display[key] = disPlayTop;
      }
      return prevClone;
    });
  };

  return (
    <Dialog title="Tùy chọn hiển thị" open={open} handleClose={handleClose}>
      <FormWrapper>
        <InputWrapper>
          <InputLabel>Trên mã vạch</InputLabel>
          <Options>
            {fields.map((item) => (
              <OptionWrapper key={v4()}>
                <input
                  type="checkbox"
                  value={item.key}
                  onChange={(e) => handleDisplayChange(e, "top")}
                  checked={product.display.top.includes(item.key)}
                />
                <InputLabel style={{ fontWeight: 400 }}>{item.name}</InputLabel>
              </OptionWrapper>
            ))}
          </Options>
        </InputWrapper>
        <InputWrapper>
          <InputLabel>Dưới mã vạch</InputLabel>
          <Options>
            {fields.map((item) => (
              <OptionWrapper key={v4()}>
                <input
                  type="checkbox"
                  value={item.key}
                  onChange={(e) => handleDisplayChange(e, "bottom")}
                  checked={product.display.bottom.includes(item.key)}
                />
                <InputLabel style={{ fontWeight: 400 }}>{item.name}</InputLabel>
              </OptionWrapper>
            ))}
          </Options>
        </InputWrapper>
      </FormWrapper>
    </Dialog>
  );
}

export default CustomDisplayPopup;
