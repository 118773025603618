import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Badge, Dropdown } from 'antd';
import AuthContext from 'flexbiz-core/components/auth/Context';
import withMediaQuery from 'flexbiz-core/components/withMediaQuery';
import React, { useState, useContext } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsBell, BsPlusCircle,BsDisplay } from 'react-icons/bs';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { FiInfo, FiLogOut, FiUsers } from 'react-icons/fi';
import DefaultContext from 'flexbiz-core/components/auth/Context';
import { NavLink, withRouter,useHistory } from 'react-router-dom';

import RequireLogin from 'flexbiz-core/components/RequireLogin';
Header.contextType = AuthContext;
function Header() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [notify, setNotify] = useState([]);
	const context = useContext(DefaultContext);
	const user = context.userInfo;
	const history =useHistory()
	const items = [
		{
			key: '1',
			label: (
				<NavLink
					className="dropdown-link"
					to=""
					onClick={(e) => {
						e.preventDefault();
						context.profile();
					}}
				>
					<FiUsers />
					<span>Thông tin tài khoản</span>
				</NavLink>
			),
		},
		{
			key: '2',
			label: (
				<NavLink
					to=""
					onClick={(e) => {
						e.preventDefault();
						context.changeApp();
					}}
					className="dropdown-link"
				>
					<CgArrowsExchangeAlt />
					<span>Công ty</span>
				</NavLink>
			),
		},
		{
			key: '3',
			label: (
				<NavLink
					to=""
					onClick={(e) => {
						e.preventDefault();
						context.aboutus();
					}}
					className="dropdown-link"
				>
					<FiInfo />
					<span>Về chúng tôi</span>
				</NavLink>
			),
		},
		{
			key: '4',
			label: (
				<NavLink
					to=""
					onClick={(e) => {
						context.signout();
					}}
					className="dropdown-link"
				>
					<FiLogOut />
					<span>Đăng xuất</span>
				</NavLink>
			),
		},
	];
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleViewNotify = (indexI) => {
		const newState = notify.map((obj, indexD) => {
			if (indexD === indexI) {
				return { ...obj, status: 'viewed' };
			}
			return obj;
		});
		setNotify(newState);
	};
	return (
		<RequireLogin>
			<div
				id="header"
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					borderRadius: 4,
					width: JSON.parse(localStorage.getItem('clicked')) ? 'calc(80% - 30px)' : 'calc(100% - 110px)',
				}}
			>
				<div
					// style={{
					// 	width: 'calc(80% - 10px)',
					// }}
					className="tool"
				>
					
					<div className="tool-right">
					<BsDisplay onClick={()=> history.push("/pos")} className="item" size={20}></BsDisplay>
						<BsPlusCircle className="item" size={20} />
						<Button
							id="demo-positioned-button"
							aria-controls={open ? 'demo-positioned-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
							className="item"
						>
							<Badge count={notify?.length} showZero>
								<BsBell size={20} />
							</Badge>
						</Button>
						<Menu
							id="demo-positioned-menu"
							aria-labelledby="demo-positioned-button"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
						>
							{notify?.length > 0 ? (
								notify?.map((item, index) => (
									<MenuItem
										onClick={() => handleViewNotify(index)}
										style={{
											background: item.status === 'viewed' ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
										}}
									>
										{item.notify}
									</MenuItem>
								))
							) : (
								<MenuItem onClick={handleClose}>Chưa có thông báo nào</MenuItem>
							)}
						</Menu>
						<Dropdown menu={{ items }} placement="bottomRight">
							<div className="profile">
								<div className="avatar">
									{user.picture ? (
										<img src={`https://api.goodapp.vn/${user.picture}`} alt="Avatar" />
									) : (
										<img src="./images/avatar.png" alt="Avatar" />
									)}
								</div>
								<span>{user.name}</span>
							</div>
						</Dropdown>
					</div>
				</div>
			</div>
		</RequireLogin>
	);
}

export default withRouter(withMediaQuery('(max-width:480px)')(Header));
