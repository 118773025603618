import viLocale from "@fullcalendar/core/locales/vi";
import dayGridPlugin from "@fullcalendar/daygrid";
import listGridPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useState } from "react";
import "./CalendarView.css";

export default function CalendarView({ data }) {
  // const timeZone = "Asia/Bangkok";
  const [events, setEvents] = useState();

  const convertDataToTask = (d) => {
    let lstTasks = [];

    d.map(({ _id, ten_cv, start_date, due_date, color }) => {
      const task = {
        id: _id,
        title: ten_cv,
        start: start_date,
        end: due_date,
        color: color,
      };

      lstTasks.push(task);
    });

    setEvents(lstTasks);
  };

  useEffect(() => {
    convertDataToTask(data);
  }, [data]);

  const handleEventClick = (e) => {
    alert(e.event.title);
  };

  return (
    <div className="calendarView">
      <FullCalendar
        locale={viLocale}
        // timeZone={timeZone}
        plugins={[dayGridPlugin, timeGridPlugin, listGridPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        fixedWeekCount={false}
        events={events}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        allDaySlot={false}
        eventResize={true}
        eventClick={handleEventClick}
        eventChange={true}
      />
    </div>
  );
}
