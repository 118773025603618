import React from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FilterBox from './FilterBox';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { VscLoading } from 'react-icons/vsc';
import { unicode2Ascii } from 'flexbiz-core/utils';
import { useCore } from '../../../../hooks/useCore';

const AutoCompleteStyled = styled(Autocomplete)`
	width: 100%;
	height: 34px;
	border-radius: 6px;
	background-color: #fff;
	& .MuiInputBase-root {
		height: 34px;
		padding: 0 !important;
		& input {
			font-size: 13px;
			padding: 0;
		}
		& input::placeholder {
			font-size: 13px;
			color: #000;
		}
	}
`;

function FilterAutocomplete({
	title,
	selectedValue,
	onSelect,
	apiCode = 'dmvt',
	getOptionLabel,
	filterOptions = (option) => option,
	renderOption,
	searchFileds = ['ma_vt', 'ten_vt'],
	q,
	autocompleteProps,
}) {
	const { token, asyncGetList, contextAlert } = useCore();

	const [searchText, setSearchText] = React.useState('');
	const [search, setSearch] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const [load, setLoad] = React.useState(1);
	const [page, setPage] = React.useState(1);
	const [count, setCount] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	const timerRef = React.useRef();

	// handle get data
	const handleGetData = async (search, page, oldOptions = []) => {
		try {
			setLoading(true);
			let condition = { $or: [] };
			if (q) {
				condition = { ...condition, ...q };
			}
			if (search) {
				searchFileds.forEach((searchFiled) => {
					condition.$or.push({
						[searchFiled]: { $regex: search, $options: 'i' },
					});
				});
				const $search = await unicode2Ascii(search);
				condition.$or.push({ $text: { $search: search + ' ' + $search } });
			}
			const [data, { rows_number }] = await Promise.all([
				asyncGetList(token, apiCode, {
					condition,
					limit: 20,
					page,
				}),
				asyncGetList(token, apiCode, {
					condition,
					count: 1,
				}),
			]);
			if (data) {
				setOptions([...oldOptions, ...data]);
				setCount(rows_number);
			} else {
				setOptions([]);
			}
			if (oldOptions.length === 0) {
				setPage(1);
			}
		} catch (error) {
			contextAlert(error);
		} finally {
			setLoading(false);
		}
	};
	// handle list box scroll
	const handleListBoxScroll = (e) => {
		if (options.length === count || loading === true) {
			e.preventDefault();
			return;
		}
		const { scrollHeight, scrollTop } = e.target;
		const height = e.target.clientHeight;
		const subtract = scrollHeight - scrollTop - height;
		if (subtract <= 10) {
			setPage(page + 1);
		}
	};
	React.useEffect(() => {
		if (search && page > 1) {
			handleGetData(search, page, options);
		}
	}, [page]);
	React.useEffect(() => {
		if (search) {
			handleGetData(search, 1, []);
		} else {
			setOptions([]);
		}
	}, [search]);
	React.useEffect(() => {
		timerRef.current = setTimeout(() => {
			setSearch(searchText);
		}, 500);
		return () => {
			clearTimeout(timerRef.current);
		};
	}, [searchText]);

	return (
		<FilterBox title={title || 'Tiêu đề'} collapsable>
			<AutoCompleteStyled
				key={load}
				open={!!searchText}
				forcePopupIcon={false}
				closeIcon={null}
				options={options}
				onBlur={() => setSearchText('')}
				onChange={(e, newValue) => onSelect(newValue)}
				ListboxProps={{
					onScroll: handleListBoxScroll,
				}}
				noOptionsText={
					<Typography
						style={{
							fontSize: '13px',
							fontStyle: 'italic',
							textAlign: 'center',
						}}
					>
						Không tìm thấy kết quả
					</Typography>
				}
				getOptionLabel={getOptionLabel}
				filterOptions={filterOptions}
				renderOption={renderOption}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						InputProps={{
							...params.InputProps,
							endAdornment: loading ? (
								<Box
									className="round-loading"
									sx={{
										width: '20px',
										height: '20px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										borderRadius: '50%',
										backgroundColor: '#ededed',
										marginRight: '5px',
										cursor: 'pointer',
										color: '#999',
									}}
								>
									<VscLoading fontSize="14px" />
								</Box>
							) : (
								<>
									{!!selectedValue ? (
										<Box
											onClick={() => {
												onSelect(null);
												setOptions([]);
												setLoad(load + 1);
											}}
											sx={{
												width: '20px',
												height: '20px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: '50%',
												backgroundColor: '#ededed',
												marginRight: '5px',
												cursor: 'pointer',
												color: '#999',
											}}
										>
											<MdClose fontSize="14px" />
										</Box>
									) : null}
								</>
							),
						}}
					/>
				)}
				{...autocompleteProps}
			/>
		</FilterBox>
	);
}

export default FilterAutocomplete;
