import React from 'react';
import { formatNumberWithSpaces } from '../../../../Utils';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export default function TabTotal({ handleChangeFolow, handleChangeEdit, checkedFolow, checkedEdit, isTotalVoucher }) {
	return (
		<div className="dialog__content_edit_voucher__body_checkbox">
			<div className="group_checkbox_voucher">
				{/* <FormControlLabel
					control={
						<Checkbox
							checked={checkedFolow}
							onChange={handleChangeFolow}
							name="checkedFolow"
							color="primary"
						/>
					}
					label="Theo dõi thanh toán"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={checkedEdit}
							onChange={handleChangeEdit}
							name="checkedEdit"
							color="primary"
						/>
					}
					label="Sửa tỉ giá ghi sổ"
				/> */}
			</div>
			<div className="flex-top">
				<div>
					<span
						style={{
							marginRight: '10px',
						}}
					>
						Tổng SL:
					</span>
					<span>{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_sl) : '0'} </span>
				</div>
				<div className="item_price_voucher">
					<div className="flex-between">
						<span>Tiền hàng:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_tien_hang) : '0'} {'VND'}
						</span>
					</div>

					<div className="flex-between">
						<span>Tiền CK:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_ck) : '0'} {'VND'}
						</span>
					</div>

					<div className="flex-between">
						<span>Chi phí:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_cp_cpb) : '0'} {'VND'}
						</span>
					</div>

					<div className="flex-between">
						<span>Tiền thuế:</span>
						<span>
							{isTotalVoucher ? formatNumberWithSpaces(isTotalVoucher?.t_thue_vao) : '0'} {'VND'}
						</span>
					</div>

					<div
						className="flex-between"
						style={{
							fontWeight: 'bold',
						}}
					>
						<span>Tổng tiền:</span>
						<span>
							{isTotalVoucher
								? formatNumberWithSpaces(
										isTotalVoucher?.t_tien_hang +
											isTotalVoucher?.t_cp_cpb +
											isTotalVoucher?.t_thue_vao -
											isTotalVoucher?.t_ck
								  )
								: '0'}{' '}
							{'VND'}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
