import styled from 'styled-components';

export const Styles = styled.div`
	.header_tool_table {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;
		.header_pagination {
			display: flex;
			justify-content: end;
			align-items: center;
			.input_pagination {
				color: #206c77;
				background-color: #fff;
				border: 1px solid #ddd;
				border-radius: 4px;
				padding: 3px 4px;
				&:hover {
					color: #206c77;
					background-color: #e5e5e5;
					border: 1px solid #206c77;
				}
				width: 30px;
				&:focus {
					outline: none;
				}
			}
			.pagination {
				padding: 0.5rem;
				justify-content: end;
				.btn_pagination {
					padding: 3px 4px !important;
					border: 1px solid #ddd;
					background-color: #fff;
					border-radius: 4px;
					cursor: pointer;
					height: 24px;
					max-height: 24px;
					&:hover {
						border: 1px solid #006799;
					}
				}
				.btn_pagination_disabled {
					cursor: not-allowed;
					&:hover {
						border: 1px solid #ddd;
					}
				}
			}
			select {
				color: #206c77;
				background-color: #fff;
				border: 1px solid #ddd;
				border-radius: 4px;
				padding: 3px 4px;
				&:hover {
					color: #206c77;
					background-color: #e5e5e5;
					border: 1px solid #206c77;
				}
			}
			option {
				color: #206c77;
				background-color: #fff;
				&:hover {
					color: #206c77;
					background-color: #e5e5e5;
				}
			}
		}
	}

	.box_table_manager {
		max-width: 100%;
		overflow-y: hidden;
		overflow-x: auto;
		table {
			border-spacing: 0;
			/* width: 100%; */
			thead {
				background: #fff;
				color: #333;
				font-weight: none;
				height: 50px;
				tr {
					border-radius: 8px 8px 0 0;
					height: inherit;
					th {
						font-weight: 600 !important;
						padding: 0px 10px;
						display: flex !important;
						justify-content: flex-start;
						align-items: center;
						.sort_header_arrow {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							height: 100%;
						}
						.filter_header_input {
							padding: 0 5px;
							border-radius: 4px;
							border: 1px solid transparent;
						}
						input {
							color: #206c77;
						}
					}
				}
				tr > *:last-child {
					/* border-right: 1px solid #999; */
				}
				th:hover {
					color: #206c77;
					background-color: #e5e5e5;
					.filter_header_input {
						border: 1px solid #ccc;
						background-color: #fff;
					}
				}
			}
			.head_total {
				background-color: #fff !important;
				height: fit-content;
				min-height: 50px !important;
			}
			tbody {
				display: block;
				overflow-x: hidden;
				overflow-y: auto !important;
				padding: 2px 0;
				tr {
					td {
						height: 42px;
					}
				}
				tr {
					:last-child {
						td {
							border-bottom: 0;
						}
					}
				}
			}
			tbody > * {
				/* border-right: 1px solid #ccc;
				border-left: 1px solid #ccc; */
			}
			tbody > *:last-child {
				/* border-bottom: 1px solid #ccc; */
			}
			tbody > *:first-child {
				/* border-top: 1px solid #ccc; */
			}
			tbody > *:nth-child(odd) {
				background-color: transparent !important;
			}
			tbody > *:nth-child(even) {
				background-color: #fff !important;
			}
			tbody > *:hover {
				background-color: #cfe3e9 !important;
				input {
					color: #206c77;
				}
			}
			th {
				input {
					font-size: 13px;
					padding: 0;
					margin: 0;
					border: 0;
					width: 100%;
					height: 100%;
					background: transparent;
					color: #000;
					&:focus {
						outline: none;
					}
					::placeholder {
						color: #e2e2e2;
					}
				}
			}
			td {
				margin: 0;
				/* border-bottom: 1px solid #ccc; */
				:last-child {
					border-right: 0;
				}

				&:focus-within {
					background: #cfe3e9;
					.focus_input {
						outline: none;
					}
				}
				.focus_input {
					height: 100%;
					padding: 0 10px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.active_focus_input {
					background-color: #fff !important;
					box-shadow: 0 0 0 2px #4abfd3 !important;
				}
				input {
					font-size: 13px;
					padding: 0;
					margin: 0;
					border: 0;
					width: 100%;
					height: 100%;
					background: transparent;
					&:focus {
						outline: none;
					}
					::placeholder {
						color: #e2e2e2;
					}
				}
				.resizer {
					display: inline-block;
					background: transparent;
					width: 1px;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					transform: translateX(50%);
					z-index: 1;
					${'' /* prevents from scrolling while dragging on touch devices */}
					touch-action:none;

					&.isResizing {
						background: #007aff;
					}
				}
				&:hover {
					.resizer {
						width: 3px;
					}
				}

				.box_input_checkbox_header {
					width: 100%;
					height: 100%;
					display: flex !important;
					flex-direction: column;
					justify-content: space-evenly;
				}
				.box_input_checkbox_cell {
					width: 100%;
					height: 100%;
					display: flex !important;
					justify-content: center;
					align-items: center;
				}
				.checkmark {
					height: 13px;
					width: 13px;
					position: relative;
					display: flex;
					.checkbox {
						appearance: none;
						border: 1px solid #999 !important;
						outline: none;
						transition-duration: 0.3s;
						background-color: #fff;
						cursor: pointer;
						&:checked {
							border: 1px solid #0080b7 !important;
							background-color: #206c77 !important;
						}
						&:active {
							border: 1px solid #0080b7 !important;
						}
						&:checked:after {
							content: '';
							position: absolute;
							top: 50%;
							left: 55%;
							width: 3px;
							height: 6px;
							border: solid #fff;
							border-width: 0 2px 2px 0;
							transform: translate(-50%, -50%) rotate(45deg);
						}
					}
				}
			}
		}
	}

	.hiddenTHead {
		display: none;
	}
	.flex_right {
		margin-left: 45px;
	}
	.style_numbercomparison {
		.ant-select-selector {
			height: 19px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 5px;
			margin: 1px 0;
		}
		.ant-select-selection-item {
			font-size: 20px;
			height: 34px;
			padding: 0;
		}
		.custom-dropdown .ant-select-dropdown {
			min-width: 150px !important;
		}
	}
`;
