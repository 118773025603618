import React, { useEffect, useContext, useState } from "react";
import Dialog from "../../components/Dialog/Dialog";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import DefaultContext from "flexbiz-core/components/auth/Context";

function PackageItem({ dataItem, index }) {
	const [product, setProduct] = useState("");
	const context = useContext(DefaultContext);
	useEffect(() => {
		if (dataItem) {
			const getProduct = async () => {
				const resp = await context.apis.asyncGetList(
					context?.userInfo?.token,
					"dmvt",
					{ ma_vt: dataItem?.ma_vt }
				);
				setProduct(...resp);
			};
			getProduct();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataItem]);
	return (
		<div className="spa_sales__package_item__detail__item">
			<div>{index + 1}</div>
			<div>{product.ten_vt}</div>
		</div>
	);
}

function SpaPackageDialog({ open, handleClose, data, add }) {
	const handleSubmit = () => {
		console.log("handleSubmit");
	};
	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			title={"Chọn đặt lịch trong gói đã mua"}
			actions={[
				<button className="btn btn-edit" onClick={handleSubmit}>
					<SaveIcon style={{ marginRight: "10px" }} />
					<div>Lưu</div>
				</button>,
				<button className="btn btn-grey">
					<DeleteIcon style={{ marginRight: "10px" }} />
					<div>Xoá</div>
				</button>,
			]}
		>
			<div className="box_dialog_spa_sales__package ">
				{data.map((item, index) => {
					return (
						<>
							<div
								key={index}
								className="spa_sales__package_item"
							>
								<div className="spa_sales__package_item__name">
									{item.ten_vt}
								</div>
								<div className="spa_sales__package_item__detail">
									{item.combo.map((item, index) => {
										return (
											<PackageItem
												key={index}
												dataItem={item}
												index={index}
											/>
										);
									})}
								</div>
							</div>
						</>
					);
				})}
			</div>
		</Dialog>
	);
}

export default SpaPackageDialog;
